import { Component } from 'react';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import _, { get } from 'lodash';
import { COLORS } from 'src/_shared/styles/colors.js';
import rank from '../../../_shared/assets/rank.png';
import fileIcon from '../../../_shared/assets/erin_lightgray.png';
import { ml, getSetErrorImageURL } from 'src/_shared/services/utils.js';

class BaseRankingCard extends Component {
	constructor() {
		super();
		this.state = {
			errorImageURL: '',
		};
	}

	async componentDidMount() {
		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(currentUser?.company?.errorImage?.key);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	getReferralColor(ranking, previousRanking) {
		if (ranking < previousRanking) {
			return COLORS.dashboardGreen;
		}

		if (ranking > previousRanking) {
			return COLORS.red;
		}

		return COLORS.dashboardLightOrange;
	}

	getReferralStatus(ranking, previousRanking) {
		if (ranking < previousRanking) {
			return <ArrowUpOutlined />;
		}

		if (ranking > previousRanking) {
			return <ArrowDownOutlined />;
		}

		return 'minus';
	}

	render() {
		const {
			allMultiLingualData,
			currentUser,
			referralTotal,
			prevReferralTotal,
			userDashboard,
		} = this.props;

		const whiteLabel = get(currentUser, 'company.whiteLabel', false);

		const { ranking, previousRanking } = userDashboard || {};
		const percentChange = Number.parseFloat(
			Math.abs(
				((referralTotal - prevReferralTotal) / prevReferralTotal) * 100
			).toFixed(0)
		);

		return (ranking && ranking > -2) || !isNaN(percentChange) ? (
			<div className="d-card-body vh-center">
				<div style={{ width: '100%' }}>
					<div className="row align-items-center text-center">
						{ranking > 0 && (
							<div className="col-md-6">
								<h3 className="d-number"># {ranking}</h3>
							</div>
						)}
						{ranking === -1 && (
							<>
								<div className="col-md-8">
									<p>
										{ml(
											'Your rank will be available after you make your first referral.',
											currentUser,
											allMultiLingualData
										)}
									</p>
								</div>
								<div className="col-md-4">
									<img alt="rank" width={60} src={rank} />
								</div>
							</>
						)}
						{ranking > 0 && (
							<div className="col-md-6">
								<span
									style={{
										width: 60,
										height: 40,
										borderRadius: 8,
										backgroundColor: this.getReferralColor(
											ranking,
											previousRanking
										),
										display: 'inline-flex',
										alignItems: 'center',
										justifyContent: 'center',
										color: '#fff',
										fontSize: 22,
									}}
								>
									{this.getReferralStatus(ranking, previousRanking)}
								</span>
							</div>
						)}

						{isNaN(percentChange) ? null : (
							<div style={{ fontWeight: '800', fontSize: '12px' }}>
								<Progress
									percent={percentChange}
									strokeColor={this.getReferralColor()}
									format={(percentChange) => `${percentChange}%`}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		) : (
			<div className="no-content">
				{whiteLabel ? (
					<img
						src={this.state.errorImageURL}
						alt="error image"
						className="no-content-icon ateet"
					/>
				) : (
					<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
				)}
				<p className="no-content-text">
					Your ranking will show once you've made a referral
				</p>
			</div>
		);
	}
}

export const RankingCard = BaseRankingCard;
