import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { GetCompanyByHost } from 'src/_shared/api/graphql/custom/company/';
import { downloadFromS3, getEnvironment } from 'src/_shared/services/utils.js';
import ErinLogo from '../_shared/assets/erinwhite.png';
import rightArrow from '../_shared/assets/right-arrow.svg';
import {
	ArrowIcon,
	Logo,
	MainContainer,
	appStoreBtn as appStoreButton,
	footer,
	footerText,
	largeText,
} from './mobileRedirectStyles.js';

function MobileRedirect(props) {
	const { operatingSystem } = props.history.location;
	let { host } = window.location;
	host = host.replace('www.', '');

	const [company, setData] = useState({ hits: [] });
	const whiteLabel = get(company, 'whiteLabel');
	const [logoSource, setLogoSource] = useState(
		whiteLabel
			? get(company, 'logo.key')
			: host === 'app.erinapp.com'
				? ErinLogo
				: null
	);

	const [containerCSS, setContainerCSS] = useState('');

	useEffect(() => {
		async function fetchData() {
			if (getEnvironment() !== 'prod') {
				try {
					const { data } = await props.client.query({
						query: GetCompanyByHost,
						variables: { host },
					});
					const result = {
						...data.getCompanyByHost,
					};
					setData(result);
				} catch (error) {
					console.log(error);
				}
			}
		}

		fetchData();
	}, []);


	useEffect(() => {
		const fetchLogo = async () => {
			if (company && whiteLabel) {
				const presignedURL = await downloadFromS3(logo);
				setLogoSource(presignedURL);
			}

			const background = await MainContainer(company);
			setContainerCSS(background);
		};

		fetchLogo();
	}, [company]);

	let url =
		operatingSystem === 'iOS'
			? 'https://itunes.apple.com/us/app/erin-employee-referrals/id1451343403?mt=8'
			: '#';
	if (get(company, 'whiteLabel')) {
		url = '';
	}

	const theme = JSON.parse(get(company, 'theme', '{}'));
	return (
		<div className={containerCSS}>
			{company && whiteLabel ? (
				<img className={Logo} src={logoSource} alt="logo" width="350px" />
			) : getEnvironment() !== 'other' && logoSource ? (
				<img className={Logo} src={logoSource} alt="Erin Logo" />
			) : (
				<div className={Logo} />
			)}
			<span className={largeText}>
				Get the best mobile experience with our app!{' '}
			</span>
			<a className={appStoreButton(theme)} href={`${url}`}>
				Download the App
				<img
					className={ArrowIcon}
					src={rightArrow}
					height={26}
					width={26}
					alt="right arrow"
				/>
			</a>
			{getEnvironment() !== 'other' && (
				<div className={footer}>
					<p className={footerText}>
						For full features login on a larger device at{' '}
					</p>
					<strong className={footerText}>app.erinapp.com</strong>
				</div>
			)}
		</div>
	);
}

export default withApollo(MobileRedirect);
