const getEnvironment = () => {
	let environment = 'other';
	const host = window.location.hostname;
	if (
		host === 'localhost' ||
		host === 'erinapp-dev.netlify.app' ||
		host === 'testing.erinapp.com' ||
		host === 'referralstest.aus.com' ||
		host === 'qa.referafriend.seaworldentertainment.com' ||
		host === 'erinapp-alpha.netlify.app'
	) {
		environment = 'dev';
	}

	if (host === 'erinapp-load-test.netlify.app') {
		environment = 'load-test';
	}

	if (host === 'app.erinapp.com') {
		environment = 'prod';
	}

	if (String(process.env.REACT_APP_FORCE_PROD).toLowerCase() == 'true')
		environment = 'prod';
	return environment;
};

const ForceProductionEnvironment = String(process.env.REACT_APP_FORCE_PROD).toLowerCase() == 'true';

export const configMode = getEnvironment() === 'dev' && !ForceProductionEnvironment ? 'DEV' : 'PROD';
console.log('ENV:', configMode);
