import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { queryDepartmentsByCompanyIdIndex } from 'src/_shared/api/graphql/custom/departments/';
import { queryUserGroupsByCompanyIdIndex } from 'src/_shared/api/graphql/custom/userGroups/';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { downloadFromS3, query } from '../_shared/services/utils';
import NewExtendedUserLinkForm from './new-user-items/NewExtendedUserLinkFormComponent.jsx';
import { Card, LandingPageContainer, Logo } from './newUserLandingStyles.js';

class NewExtendedUserLinkLandingPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accountClaim: get(props, 'location.state.accountClaim'),
			departments: null,
			company: get(
				props,
				'currentUser.company',
				get(
					props,
					'location.state.accountClaim.company',
					get(props, 'user.company', get(props, 'company'))
				)
			),
			loaded: false,
		};
	}

	async componentDidMount() {
		// If invite is already stored in cache
		const { id } = this.props.match.params;
		const user = await query({ id }, 'getUser');
		if (user) this.setState({ user });
		if (this.props.userInvite) {
			this.props.client
				.query({
					query: gql(queryDepartmentsByCompanyIdIndex),
					variables: {
						companyId: this.props.userInvite.companyId,
						first: 500,
					},
				})
				.then((data) => {
					this.setState({ departments: data });
				});
			this.props.client
				.query({
					query: gql(queryUserGroupsByCompanyIdIndex),
					variables: {
						companyId: this.props.userInvite.companyId,
						first: 500,
					},
				})
				.then((data) => {
					const userGroups = get(
						data,
						'data.queryUserGroupsByCompanyIdIndex.items',
						[]
					);
					const defaultUserGroupId = this.getUserGroupId(userGroups, 'Default');
					const noBonusUserGroupId = this.getUserGroupId(
						userGroups,
						'Ineligible'
					);
					this.setState({ userGroups, defaultUserGroupId, noBonusUserGroupId });
				});
		}

		const accountClaim = get(this.props, 'history.location.state.accountClaim');
		if (accountClaim) {
			this.setState({ accountClaim });
			this.props.client
				.query({
					query: gql(queryDepartmentsByCompanyIdIndex),
					variables: {
						companyId: get(accountClaim, 'companyId'),
						first: 500,
					},
				})
				.then((data) => {
					this.setState({ departments: data });
				});

			this.props.client
				.query({
					query: gql(queryUserGroupsByCompanyIdIndex),
					variables: {
						companyId: get(accountClaim, 'companyId'),
						first: 500,
					},
				})
				.then((data) => {
					const userGroups = get(
						data,
						'data.queryUserGroupsByCompanyIdIndex.items',
						[]
					);
					const defaultUserGroupId = this.getUserGroupId(userGroups, 'Default');
					const noBonusUserGroupId = this.getUserGroupId(
						userGroups,
						'Ineligible'
					);
					this.setState({ userGroups, defaultUserGroupId, noBonusUserGroupId });
				});
		}

		const logo = get(this.state, 'company.logo.key', false);
		const presignedURL = await downloadFromS3(logo);
		const styles = await LandingPageContainer(get(this.state, 'company'));
		this.setState({ logoSrc: presignedURL, styles });
	}

	async componentDidUpdate(prevProps) {
		// If invite is not yet stored in cache
		if (!this.state.user) {
			const { id } = this.props.match.params;
			const user = await query({ id }, 'getUser');
			if (user) this.setState({ user });
		}

		if (prevProps.userInvite !== this.props.userInvite) {
			this.props.client
				.query({
					query: gql(queryDepartmentsByCompanyIdIndex),
					variables: {
						companyId: this.props.userInvite.companyId,
						first: 500,
					},
				})
				.then((data) => {
					this.setState({ departments: data });
				});

			this.props.client
				.query({
					query: gql(queryUserGroupsByCompanyIdIndex),
					variables: {
						companyId: this.props.userInvite.companyId,
						first: 500,
					},
				})
				.then((data) => {
					const userGroups = get(
						data,
						'data.queryUserGroupsByCompanyIdIndex.items',
						[]
					);
					const defaultUserGroupId = this.getUserGroupId(userGroups, 'Default');
					const noBonusUserGroupId = this.getUserGroupId(
						userGroups,
						'Ineligible'
					);
					this.setState({ userGroups, defaultUserGroupId, noBonusUserGroupId });
				});
		}
	}

	async getAllDepartments(
		companyId,
		policy = 'network-only',
		nextToken = null,
		allDepartments = []
	) {
		const { client } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryDepartmentsByCompanyIdIndex),
				variables: {
					companyId,
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const departs = [...data.queryDepartmentsByCompanyIdIndex.items];
			const token = get(
				data,
				'queryDepartmentsByCompanyIdIndex.nextToken',
				null
			);
			allDepartments = [...allDepartments, ...departs];
			this.setState({
				departments: allDepartments,
			});
			if (token) {
				this.getAllDepartments(companyId, policy, token, allDepartments);
			} else {
				this.setState({ loaded: true });
			}
		} catch (error) {
			console.log(error);
		}
	}

	getUserGroupId = (userGroups, name) => {
		const userGroup = userGroups.find((group) => group.name === name);
		return get(userGroup, 'id');
	};

	render() {
		const { userInvite } = this.props;
		const {
			accountClaim,
			company,
			defaultUserGroupId,
			departments,
			logoSrc,
			noBonusUserGroupId,
			styles,
			user,
		} = this.state;

		const whiteLabel = get(company, 'whiteLabel');

		if (userInvite && userInvite.userId) {
			this.props.history.push('/login');
		}

		return (
			<div className={styles}>
				{whiteLabel ? (
					<img src={logoSrc} alt="logo" width="350px" />
				) : (
					<img className={Logo} src={ErinLogo} alt="Erin Logo" />
				)}
				<div className={Card}>
					<NewExtendedUserLinkForm
						whiteLabel={whiteLabel}
						accountClaim={accountClaim}
						defaultUserGroupId={defaultUserGroupId}
						noBonusUserGroupId={noBonusUserGroupId}
						userLink={user}
						userInvite={userInvite}
						{...this.props}
						departments={get(
							departments,
							'data.queryDepartmentsByCompanyIdIndex.items',
							[]
						)}
					/>
				</div>
			</div>
		);
	}
}

export default withApollo(NewExtendedUserLinkLandingPageComponent);
