import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Popconfirm, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { useEffect, useRef, useState } from 'react';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { USER_ROLES } from 'src/_shared/constants';
import {
	conditionalStatusStyle,
	desanitize,
	downloadFromS3,
	formatDate,
	getBonusAmount,
	lowerCase,
	mapReferralStatus,
	ml,
} from 'src/_shared/services/utils';
import { BonusValue } from 'src/referrals-admin-dashboard/referrals-admin-dashboard-components';
import { ReferralHiredModal } from '../referral-hired-modal/index.js';
import JobTitleComponent from './JobTitleComponent.jsx';

function ReferralsTable(props) {
	const [hiredModalVisible, setHiredModalVisible] = useState(false);
	const [status, setStatus] = useState('');
	const [selectedReferral, setSelectedReferral] = useState();
	const [dropdownVisibility, setDropdownVisibility] = useState({});
	const [referralsData, setReferralsData] = useState([...props.filteredData]);
	const processedRecordsRef = useRef(new Set());
	const [headers, setHeaders] = useState({});

	useEffect(() => {
		const fetchResumeURLs = async () => {
			const withContactResumeURLs = await Promise.all(
				props.filteredData.map(async (record) => {
					const prevRecord = processedRecordsRef.current[record.id];
					if (
						prevRecord &&
						prevRecord.customStatus === record.customStatus &&
						prevRecord.status === record.status
					) {
						return prevRecord;
					}

					const contactResume = record?.contactResume
						? await downloadFromS3(
								record.contactResume.key,
								record.contactResume.bucket
							)
						: null;
					const newRecord = {
						...record,
						contactResume,
					};
					// Add new record to cache-like ref to avoid unnecessary aws calls
					processedRecordsRef.current[record.id] = newRecord;

					return newRecord;
				})
			);

			setReferralsData(withContactResumeURLs);
		};

		fetchResumeURLs();
	}, [props.filteredData]);

	useEffect(() => {
		const { currentUser, allMultiLingualData } = props;
		setHeaders({
			candidate: ml('Candidate', currentUser, allMultiLingualData),
			info: ml('Info', currentUser, allMultiLingualData),
			job: ml('Job', currentUser, allMultiLingualData),
			date: ml('Date', currentUser, allMultiLingualData),
			bonus: ml('Bonus', currentUser, allMultiLingualData),
			referredBy: ml(
				`${mapReferralStatus('referred', company)} By`,
				currentUser,
				allMultiLingualData
			),
			type: ml('Type', currentUser, allMultiLingualData),
			status: ml('Status', currentUser, allMultiLingualData),
			dateHired: ml(
				`Date ${mapReferralStatus('hired', company)}`,
				currentUser,
				allMultiLingualData
			),
		});
	}, [props.currentUser, props.allMultiLingualData, props.company]);

	useEffect(() => {
		getExportData(referralsData);
	}, [
		referralsData,
		props.currentUser,
		props.allMultiLingualData,
		props.company,
		props.languageCode,
		headers,
	]);

	const getExportData = (data) => {
		const {
			allMultiLingualData,
			company,
			currentUser,
			setExportData,
			languageCode,
		} = props;
		const selfReferredText = ml(
			'Self Referred',
			currentUser,
			allMultiLingualData
		);

		const parseInfo = (record) =>
			`${record.message ? desanitize(record.message) : ''}`;
		const parseDate = (record) =>
			formatDate(
				record.referralDate,
				currentUser?.languageCode,
				currentUser?.dateFormat
			);
		const parseBonus = (record) =>
			record.referralType === 'self'
				? selfReferredText
				: getBonusAmount(record.job, currentUser);
		const parseReferredBy = (record) =>
			record.user ? `${record.user.firstName} ${record.user.lastName}` : '';
		const parseDateHired = (record) =>
			record.hireDate ? formatDate(record.hireDate, languageCode) : '';

		const exportData = data.map((record) => ({
			[headers.candidate]: `${record.contact.firstName} ${record.contact.lastName}`,
			[headers.info]: parseInfo(record),
			[headers.job]: `${record.job.title}`,
			[headers.date]: parseDate(record),
			[headers.bonus]: parseBonus(record),
			[headers.referredBy]: parseReferredBy(record),
			[headers.type]: `${parseReferralType(record.referralSource, record)}`,
			[headers.status]: `${mapReferralStatus(record, company)}`,
			[headers.dateHired]: parseDateHired(record),
		}));
		setExportData(exportData);
	};

	const renderReferredBy = (referredBy) => {
		if (props.displayAs === 'admin') {
			return (
				<Link className="table-link" to={`/employees/${referredBy.id}`}>
					{referredBy.firstName} {referredBy.lastName}
				</Link>
			);
		}

		return (
			<span>
				{referredBy.firstName} {referredBy.lastName}
			</span>
		);
	};

	const parseReferralType = (referralSource, referral) => {
		const referralType = referral?.referralType;
		const options = [
			{
				condition: referralSource === 'direct' && referralType === 'email',
				label: 'Email',
			},
			{ condition: referral?.type === 'gdprReferralCreated', label: 'Email' },
			{ condition: referralSource === 'network', label: 'Referral Network' },
			{
				condition: referralSource === 'direct' && referralType === 'text',
				label: 'Text',
			},
			{ condition: referralSource === 'share', label: 'Social' },
			{ condition: referralSource === 'general', label: 'General' },
			{ condition: referralSource === 'internal', label: 'Internal' },
			{ condition: referralType === 'self', label: 'Self' },
		];

		return options.find((option) => option.condition)?.label ?? '';
	};

	const renderReferralType = (referralSource, referral) => {
		return (
			<span style={{ wordBreak: 'normal' }}>
				{parseReferralType(referralSource, referral)}
			</span>
		);
	};

	const contactInfo = (contactResumeURL) => {
		return (
			<div style={{ cursor: 'pointer' }}>
				<Tooltip
					placement="top"
					title="Download Resume"
					style={{ fontWeight: 1000 }}
				>
					<a target="_blank" href={contactResumeURL} rel="noreferrer">
						<i className="icon-application" />
					</a>
				</Tooltip>
			</div>
		);
	};

	const messageInfo = (message) => {
		const desanitizedMessage = desanitize(message);
		return (
			<Tooltip placement="top" title={desanitizedMessage}>
				<i
					className="icon-message-outline"
					alt=""
					style={{ color: 'var(--silver-chalice)', fontSize: 18 }}
				/>
			</Tooltip>
		);
	};

	const requestIdHover = (jobTitleInfo, externalJobId) => {
		externalJobId &&= 'Req Id: ' + externalJobId;
		return (
			<Tooltip
				placement="top"
				title={externalJobId}
				style={{ fontWeight: 1000 }}
			>
				{jobTitleInfo}
			</Tooltip>
		);
	};

	const hideHiredModal = () => {
		setHiredModalVisible(false);
	};

	const handleHired = () => {
		setStatus('hired');
	};

	const handleTableChange = (event) => {
		if (event.action === 'sort') {
			getExportData(event.currentDataSource);
		}
	};

	const handleVisibleChange = (key, flag) => {
		setDropdownVisibility((prevState) => ({
			...prevState,
			[key]: flag,
		}));
	};

	const {
		allMultiLingualData,
		currentUser,
		sortByAlph,
		company,
		onUpdateReferral,
		updateReferralState,
	} = props;

	const handleUpdateStatus = async (referral, status) => {
		if (status === 'hired') {
			setSelectedReferral(referral);
			setHiredModalVisible(true);
		} else {
			setStatus(status);
			let input = {};
			const customStatuses = [
				'stage1',
				'stage2',
				'stage3',
				'stage4',
				'stage5',
				'stage6',
			];
			if (customStatuses.includes(status)) {
				const customStages = get(props.currentUser, 'company.stages');
				if (customStages) {
					const stages = JSON.parse(customStages);
					const stageObject = stages.find((value) => value[status]);
					const customStage = stageObject[status];
					input = {
						id: get(referral, 'id'),
						contactId: get(referral, 'contactId'),
						userId: get(referral, 'user.id'),
						jobId: get(referral, 'jobId'),
						status: 'interviewing',
						bonusStatus: 'ineligible',
						customStatus: customStage,
					};
				}
			} else {
				input = {
					id: get(referral, 'id'),
					contactId: get(referral, 'contactId'),
					userId: get(referral, 'user.id'),
					jobId: get(referral, 'jobId'),
					status,
					bonusStatus: 'ineligible',
				};
				if (status === 'interviewing') {
					input.customStatus = get(
						props.currentUser,
						'company.referralStatus',
						'Interviewing'
					);
				} else {
					// Explicitly set customStatus null or else the
					// referral retains the old customStatus
					input.customStatus = null;
				}
			}

			onUpdateReferral({ input });
			updateReferralState(input);
		}
	};

	let isManagerPermissionDisabled = get(
		props.currentUser,
		'company.disableManagerPermissions',
		false
	);
	// Preserves backwards compatibility and future compatibility with disableManagerPermissions
	const managerReferrals = currentUser.company?.disableManagerPermissionsByType
		? JSON.parse(currentUser.company.disableManagerPermissionsByType)
				.managerReferrals
		: isManagerPermissionDisabled
			? 'readOnly'
			: 'edit';
	isManagerPermissionDisabled = managerReferrals !== 'edit';
	const displayAs = get(props.currentUser, 'displayAs');
	const isStatusDropdownDisabled = Boolean(
		isManagerPermissionDisabled && displayAs === USER_ROLES.MANAGER
	);
	const confirmMessage =
		status === 'hired'
			? 'Confirm Updates? Associated bonuses will be deleted.'
			: 'Confirm Updates?';
	const languageCode = get(props, 'languageCode', 'US');

	let REFERRAL_STATUS = {
		referred: 'Referred',
		accepted: 'Accepted',
		interviewing: 'Interviewing',
	};
	const customStages = get(props.currentUser, 'company.stages');
	if (customStages) {
		const stages = JSON.parse(customStages);
		if (stages.length > 0) {
			REFERRAL_STATUS = Object.assign(REFERRAL_STATUS, ...stages);
		}
	}

	const remainingStatuses = {
		hired: 'Hired',
		ineligible: 'Ineligible',
		notHired: 'Not Hired',
		declined: 'Declined',
		transferred: 'Transferred',
		inactive: 'Inactive',
		noresponse: 'No Response',
	};
	REFERRAL_STATUS = { ...REFERRAL_STATUS, ...remainingStatuses };

	const columns = [
		{
			title: headers?.candidate,
			dataIndex: 'contact',
			key: 'name',
			showSorterTooltip: false,
			sorter: (a, b) =>
				sortByAlph(
					`${get(a, 'contact.firstName', '')} ${get(
						a,
						'contact.lastName',
						''
					)}`,
					`${get(b, 'contact.firstName', '')} ${get(b, 'contact.lastName', '')}`
				),
			render(record, data) {
				let result = null;
				if (get(data, 'type') === 'gdprReferralCreated') {
					result = (
						<span className="text-gray">
							{ml('Pending Acceptance', currentUser, allMultiLingualData)}
						</span>
					);
				} else {
					result = (
						<Link to={`/referrals/${data.id}`} className="table-link">
							{get(record, 'firstName')} {get(record, 'lastName')}
						</Link>
					);
				}

				return result;
			},
		},
		{
			title: headers?.info,
			width: '6%',
			key: 'info',
			render(text, record) {
				return (
					<div>
						{record.message !== null && (
							<div key="message">{messageInfo(record.message)}</div>
						)}

						<div key="resume">
							{' '}
							{get(record, 'contactResume')
								? contactInfo(record.contactResume)
								: null}
						</div>
					</div>
				);
			},
		},
		{
			title: headers?.job,
			dataIndex: 'job',
			width: '18%',
			key: 'job',
			showSorterTooltip: false,
			sorter(a, b) {
				const jobA = get(a, 'job.title', '');
				const jobB = get(b, 'job.title', '');

				return jobA.localeCompare(jobB, currentUser?.languageCode, {
					ignorePunctuation: true,
					sensitivity: 'base',
				});
			},
			render: (job) =>
				job === null
					? null
					: requestIdHover(
							<Link className="table-link" to={`/jobs/${job.id}`}>
								<JobTitleComponent
									jobTitle={job.title}
									currentUser={get(props, 'currentUser')}
								/>
							</Link>,
							job.externalJobId
						),
		},
		{
			title: headers?.date,
			dataIndex: 'referralDate',
			width: '10%',
			key: 'date',
			showSorterTooltip: false,
			sorter: (a, b) =>
				sortByAlph(dayjs(b.referralDate), dayjs(a.referralDate)),
			render: (referralDate) =>
				formatDate(
					referralDate,
					currentUser?.languageCode,
					currentUser?.dateFormat
				),
		},
		{
			title: headers?.bonus,
			dataIndex: 'job',
			width: '10%',
			key: 'bonus',
			render(job, referral) {
				const hasBonusTier =
					get(currentUser, 'company.enableRetroactiveBonuses') &&
					get(referral, 'tieredBonus.id')
						? get(
								referral,
								'tieredBonusId',
								get(referral, 'bonus.tieredBonusId')
							)
						: get(referral, 'bonus.tieredBonusId');
				const hasBonusAmount = get(referral, 'bonus.amount', null);
				const hasBonus = hasBonusTier || hasBonusAmount;

				const bonus =
					get(referral, 'status') === 'hired' && hasBonus ? (
						<Link
							className="table-link"
							to={{
								pathname: '/bonuses',
								state: {
									targetReferral: get(referral, 'id'),
								},
							}}
						>
							<BonusValue
								referral={referral}
								contactIncentiveBonus={props.contactIncentiveBonus}
								currentCurrencyRate={1}
								currentCurrencySymbol="$"
								currentUser={get(props, 'currentUser')}
							/>
						</Link>
					) : (
						<BonusValue
							referral={referral}
							contactIncentiveBonus={props.contactIncentiveBonus}
							currentCurrencyRate={1}
							currentCurrencySymbol="$"
							companyId={get(company, 'id')}
							currentUser={get(props, 'currentUser')}
						/>
					);
				return job !== null || false ? bonus : null;
			},
		},
		{
			title: headers?.referredBy,
			dataIndex: 'user',
			key: 'referredBy',
			showSorterTooltip: false,
			sorter: (a, b) =>
				sortByAlph(
					get(a, 'user.firstName', '') + get(a, 'user.lastName', ''),
					get(b, 'user.firstName', '') + get(b, 'user.lastName', '')
				),
			render: (referredBy) =>
				referredBy === null ? null : renderReferredBy(referredBy),
		},
		{
			title: headers?.type,
			dataIndex: 'referralSource',
			key: 'referralSource',
			showSorterTooltip: false,
			sorter: (a, b) => sortByAlph(a.referralSource, b.referralSource),
			render: (referralSource, referral) =>
				referral !== null && referral !== undefined
					? renderReferralType(referralSource, referral)
					: null,
		},
		{
			title: headers?.status,
			width: '10%',
			key: 'status',
			showSorterTooltip: false,
			sorter(a, b) {
				a =
					a?.customStatus && a.status === 'interviewing'
						? lowerCase(a.customStatus)
						: lowerCase(a.status);
				b =
					b?.customStatus && b.status === 'interviewing'
						? lowerCase(b.customStatus)
						: lowerCase(b.status);
				return sortByAlph(a, b);
			},
			render(referral) {
				const disabled = Boolean(
					isStatusDropdownDisabled ||
						get(referral, 'type') === 'gdprReferralCreated'
				);
				const menuItems = Object.keys(REFERRAL_STATUS).map((key) => ({
					key,
					label:
						key === 'hired' ? (
							<span onClick={() => handleUpdateStatus(referral, key)}>
								{mapReferralStatus(key, company)}
							</span>
						) : (
							<Popconfirm
								title={confirmMessage}
								placement="top"
								okText={ml('Yes', currentUser, allMultiLingualData)}
								cancelText={ml('No', currentUser, allMultiLingualData)}
								onConfirm={() => {
									handleVisibleChange(referral.id, false);
									handleUpdateStatus(referral, key);
								}}
							>
								{mapReferralStatus(key, company)}
							</Popconfirm>
						),
					style: conditionalStatusStyle(key),
				}));

				return (
					<Dropdown
						key={referral.id}
						trigger={['click']}
						menu={{ items: menuItems }}
						disabled={disabled}
						open={dropdownVisibility[referral.id]}
						onOpenChange={(flag) => handleVisibleChange(referral.id, flag)}
					>
						<Button type="link" onClick={(e) => e.stopPropagation()}>
							<span style={conditionalStatusStyle(referral.status)}>
								{mapReferralStatus(referral, company)}
							</span>
							<DownOutlined />
						</Button>
					</Dropdown>
				);
			},
		},
		{
			title: headers?.dateHired,
			dataIndex: 'hireDate',
			width: '10%',
			key: 'hiredate',
			showSorterTooltip: false,
			sorter: (a, b) => sortByAlph(b.hireDate, a.hireDate),
			render: (hireDate) => formatDate(hireDate, languageCode),
		},
	];

	const locale = {
		emptyText: <Spinner company={company} />,
	};

	return (
		<>
			{headers && (
				<>
					<Table
						pagination={{ pageSize: 25, showSizeChanger: false }}
						rowKey={(record) => record.id}
						dataSource={referralsData}
						columns={columns}
						locale={locale}
						scroll={{ x: 656 }}
						onChange={(_pagination, _filters, _sorter, extra) =>
							handleTableChange(extra)
						}
					/>
					<ReferralHiredModal
						updateReferralState={updateReferralState}
						referral={selectedReferral}
						visible={hiredModalVisible}
						handleCancel={hideHiredModal}
						handleHired={handleHired}
						onUpdateJob={props.onUpdateJob}
						onUpdateReferral={props.onUpdateReferral}
					/>
				</>
			)}
		</>
	);
}

export default withApollo(ReferralsTable);
