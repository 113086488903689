import { List, Modal } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { searchOpenSearchNetwork } from 'src/_shared/services/utils';
import uuid from 'uuid/v4';
import makingReferralGif from '../../../assets/makingreferral300.gif';
import { SearchComponent } from '../../search';
import * as JobsStyles from '../on-deck-modal/onDeckReferralModalWrapperStyles.js';
import ReferralModalWrapper from '../referral-modal/ReferralModalWrapper.jsx';

class OnDeckJobModalComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			autoCompleteResult: [],
			isSubmitting: false,
			newContact: true,
			error: false,
			loaded: true,
			searchQuery: '',
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			jobs: [],
			loadedJobs: false,
			uniqueId: uuid(),
		};
	}

	componentDidMount() {
		if (this.props.matchFound) {
			this.setState({ autoCompleteResult: [this.props.matchFound] });
		}

		if (this.props.contact) {
			this.setState({ autoCompleteResult: [this.props.contact] });
		}
	}

	async componentDidUpdate() {
		if (this.props.visible && !this.state.searchedJobs) {
			await this.setQueryToState(get(this.state, 'searchQuery', ''));
		}
	}

	setQueryToState = async (searchQuery = '') => {
		const {
			company,
			filteredDepartments = [],
			filteredDistance = 0,
			filteredBonuses = [],
			lat,
			lng,
		} = this.state;

		clearTimeout(this.timer);
		this.setState({ searchQuery });
		if (
			searchQuery === '' &&
			filteredDepartments.length <= 0 &&
			filteredDistance <= 0
		) {
			this.setState({
				loading: false,
				searchedJobs: get(this.state, 'allJobs', []),
			});
		}

		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			const parameters = {
				query: searchQuery,
				size: 300,
				coordinates: [{ lat, lng }],
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					bonuses: filteredBonuses,
					companies: [get(company, 'id')],
					departments: filteredDepartments,
					distance: filteredDistance,
					status: 'open',
				},
			};
			const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				const searchedJobs = get(response, 'data', []);
				this.setState({ loading: false, searchedJobs });
			}
		}, 700);
	};

	setSearch = (searchedJobs = []) => {
		let { allJobs = [] } = this.state;
		if (searchedJobs) {
			allJobs =
				searchedJobs.length > allJobs.length
					? [...searchedJobs, ...allJobs]
					: allJobs;
			this.setState({ searchedJobs, loading: false });
		}
	};

	filterJobs = (property, match, jobSet) => {
		return jobSet.filter((job) => job[property] === match);
	};

	handleContactChange = (value) => {
		let autoCompleteResult = [];
		if (value) {
			for (const record of this.props.contacts) {
				const { firstName, lastName, emailAddress } = record;
				const isFirstNameMatch =
					firstName && firstName.toLowerCase().includes(value.toLowerCase());
				const isLastNameMatch =
					lastName && lastName.toLowerCase().includes(value.toLowerCase());
				const isEmailMatch =
					emailAddress &&
					emailAddress.toLowerCase().includes(value.toLowerCase());

				if (isFirstNameMatch || isLastNameMatch || isEmailMatch) {
					autoCompleteResult.push(record);
				}
			}
		} else {
			autoCompleteResult = [];
		}

		this.setState({ autoCompleteResult });
	};

	handleError = () => {
		this.setState({ error: true });
	};

	handleNewContact = () => {
		this.setState((prevState) => ({ newContact: !prevState.newContact }));
	};

	toggleIsSubmitting = () => {
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
	};

	render() {
		const { data, visible, handleCancel } = this.props;
		const { company, isSubmitting, searchedJobs, searchQuery, uniqueId } =
			this.state;

		const firstName = get(data, 'firstName', '');
		const lastName = get(data, 'lastName', '');
		const fullName = firstName + ' ' + lastName;
		const name = fullName ? fullName : 'this candidate';
		return (
			<Modal
				width={1280}
				open={visible}
				footer={null}
				title="Select a Job"
				style={{ maxWidth: '96%' }}
				onCancel={handleCancel}
			>
				{isSubmitting ? (
					<img
						src={`${makingReferralGif}?loaded=${new Date()}`}
						alt="makingReferral"
					/>
				) : (
					<div className="referral-select-job-top">
						<p>
							Refer{' '}
							<a
								href={`/mycontacts/${get(this.props, ['data', 'id'], '')}`}
								target="_blank"
								rel="noreferrer"
							>
								{name}
							</a>{' '}
							by selecting a job below.
						</p>
						<SearchComponent
							searchQuery={searchQuery}
							setQueryToState={this.setQueryToState}
							placeholder="Search Jobs"
						/>
					</div>
				)}
				{searchedJobs ? (
					<>
						{searchedJobs.length > 0 ? (
							<List
								className="job-card-wrap"
								grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3 }}
								pagination={{ pageSize: 12, showSizeChanger: false }}
								dataSource={searchedJobs}
								renderItem={(job) => {
									return (
										<List.Item
											key={uniqueId + job.id}
											style={{ height: '100%', margin: 0 }}
										>
											<ReferralModalWrapper
												client={this.props.client}
												currentUser={this.props.currentUser}
												handleCancel={handleCancel}
												data={data}
												jobData={job}
												job={job}
												referrals={job.referrals}
												currentTieredBonus={this.props.currentTieredBonus}
												currentReferralCount={this.props.currentReferralCount}
												contacts={this.props.contacts}
												onCreateContact={this.props.ImportedCreateContact}
												onUpdateContactDeckStatus={
													this.props.onUpdateContactDeckStatus
												}
												onUpdateContact={this.props.onUpdateContact}
												onCreateReferral={this.props.onCreateReferral}
											/>
										</List.Item>
									);
								}}
							/>
						) : !searchedJobs && searchedJobs.length === 0 ? (
							<p className={JobsStyles.noJobs}>There are no open jobs.</p>
						) : (
							<div style={{ height: 493 }}>
								<Spinner translateY="25%" company={company} />
							</div>
						)}
					</>
				) : (
					<div style={{ height: 493 }}>
						<Spinner translateY="25%" company={company} />
					</div>
				)}
			</Modal>
		);
	}
}

export default OnDeckJobModalComponent;
