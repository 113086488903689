import { connect } from 'react-redux';
import { withCreateCampaign } from 'src/_shared/api/components/bonus-campaign/';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withQuerySubCompanyByCompanyIdIndex } from 'src/_shared/api/components/sub-company/';
import { withListTieredBonus } from 'src/_shared/api/components/tiered-bonuses/';
import { withListUserGroup } from 'src/_shared/api/components/userGroups/';
import { dashboardActions } from 'src/actions.js';
import { USER_ROLES } from '../_shared/constants';
import { compose } from '../_shared/services/utils';
import BonusCampaignsComponent from './BonusCampaignsComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
			role: { eq: USER_ROLES.EMPLOYEE },
		},
		nextToken: null,
		limit: 10_000,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const BonusCampaignWithApi = compose(
	withListUserGroup,
	withListDepartment,
	withQuerySubCompanyByCompanyIdIndex,
	withListTieredBonus,
	withCreateCampaign
)(BonusCampaignsComponent);

export const BonusCampaigns = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(BonusCampaignWithApi);
