import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { Component } from 'react';
import { formatDate } from '../../services/utils';

export default class MyReferralsTieredBonus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			referral: props.referral,
		};
	}

	componentDidUpdate(prevProps) {
		const { referral } = this.props;
		if (prevProps.referral !== referral) {
			this.setState({ referral });
		}
	}

	render() {
		const {
			currentTieredBonus,
			status,
			userGroup,
			currentCurrencyRate,
			currentCurrencySymbol,
		} = this.props;
		const { referral } = this.state;
		const symbol = getSymbolFromCurrency(
			get(this.props.currentUser, 'userGroup.currency')
		);
		const rate = currentCurrencyRate ? currentCurrencyRate : 1;
		let bonusAmount = 0;
		const referralStatus = get(referral, 'status');
		const bonuses = get(referral, 'bonuses', []).sort(function (a, b) {
			return a.earnedDate < b.earnedDate
				? -1
				: a.earnedDate > b.earnedDate
					? 1
					: 0;
		});
		let items = '';
		if (window.location.href.includes('/jobs/')) {
			items = bonuses.map((bonus) => {
				bonusAmount += Number.parseInt(get(bonus, 'amountDue', 0), 10);
				bonusAmount = `${bonusAmount}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
				const languageCode = get(this.props.currentUser, 'languageCode');
				const bonusStatus = get(bonus, 'bonusStatus');
				const earnedDate = formatDate(get(bonus, 'earnedDate'), languageCode);
				const showEmphasis = dayjs().isAfter(earnedDate);

				const item = referralStatus === 'hired' && (
					<li key={get(bonus, 'id')}>
						<span className="text-green">
							{`${symbol}`}
							{get(bonus, 'amountDue')
								.toString()
								.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</span>
						<span>{get(bonus, 'recipientType')}</span>
						<span>{earnedDate}</span>
						<span>{bonusStatus}</span>
					</li>
				);

				return item;
			});
		} else {
			items = bonuses.map((bonus) => {
				const recipientType = get(bonus, 'recipientType');
				if (recipientType === 'employee') {
					bonusAmount += Number.parseInt(get(bonus, 'amountDue', 0), 10);
					bonusAmount = `${bonusAmount}`.replaceAll(
						/\B(?=(\d{3})+(?!\d))/g,
						','
					);
				}

				const languageCode = get(this.props.currentUser, 'languageCode');
				const bonusStatus = get(bonus, 'bonusStatus');
				const earnedDate = formatDate(get(bonus, 'earnedDate'), languageCode);
				const showEmphasis = dayjs().isAfter(earnedDate);
				const item = referralStatus === 'hired' &&
					recipientType === 'employee' && (
						<li key={get(bonus, 'id')}>
							<span className="text-green">
								{`${symbol}`}
								{get(bonus, 'amountDue')
									.toString()
									.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
							</span>

							<span>{earnedDate}</span>

							<span>{bonusStatus}</span>
						</li>
					);

				return item;
			});
		}

		return <ul className="bonus-summary-list">{items}</ul>;
	}
}
