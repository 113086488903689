import React, { useRef } from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { getColumnSearchProps } from './filterSearch';
import "./ApprovalBonusList.scss";
import NoDataState from '../../RnRCommonComponent/noDataState/NoDataState.jsx';
import { addCommas } from '../../rewardsAndRecognitionHelper/utils.js';

const ApprovalBonusListPresentation = ({ approvalBonus, isLoading, approvalCount }) => {

    const searchInput = useRef(null);

    const SummaryStatBox = ({ color = '', statName, label, total, title }) => (
        <div className="color-card" style={{ width: '33.33333%', background: `var(${color})` }}>
            <h4>
                {' '}
                {title === ' Bonuses' && `$`} {addCommas(total)}
            </h4>
            <p>{label ? label : statName === 'Total Rewards' ? 'Total' : statName}</p>
        </div>
    );

    const statusContent = {
        Approved: "Approved",
        Declined: "Not Approved",
        Pending: "Pending Approval",
        null: "Pending Approval",
    }

    const statusTextFormatter = (status) => {
        return (
            <span style={{ fontWeight: "bolder", color: status === 'Approved' ? 'green' : status === 'Declined' ? 'red' : '#F8AE63' }}>
                {statusContent[status]}
            </span>
        )
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    }

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const columns = [
        {
            title: 'RECIPIENT',
            dataIndex: 'recipient',
            key: 'recipient',
            ...getColumnSearchProps('recipient', searchInput, handleSearch, handleReset, 'Recipient')
        },
        {
            title: 'BONUS',
            dataIndex: 'bonus',
            key: 'bonus',
            render: (bonus) => (
                <span style={{ color: bonus !== 0 ? 'green' : 'inherit' }}>
                    {bonus !== 0 ? "$" + addCommas(bonus) : 'None'}
                </span>
            )
        },
        {
            title: 'BADGE',
            dataIndex: 'reward',
            key: 'reward',
        },
        {
            title: 'CREATED',
            dataIndex: 'created',
            key: 'created',
            sorter: (a, b) => a.recipient.localeCompare(b.created),
            render: (record) => moment(record).format('MM/DD/YYYY')
        },
        {
            title: 'COMPLETED',
            dataIndex: 'completed',
            key: 'completed',
            render: (record, rawContent) => {
                return rawContent.status === "Pending" ? "-" : moment(record).format('MM/DD/YYYY')
            }
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            render: (status) => statusTextFormatter(status)
        },
    ];

    return (
        <div>
            <div className="contact-card-wrap">
                <div className="contact-card">
                    <div className="contact-card-inner">
                        <div className="contact-card-title">Recognition Approval Status</div>
                        <div className="contact-color-card">
                            {approvalCount?.approvalStatusCounts.length > 0 &&
                                approvalCount?.approvalStatusCounts.map((reward, index) => (
                                    <SummaryStatBox
                                        key={reward.id || index}
                                        color={reward.color}
                                        statName={reward.name}
                                        total={reward.totals}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
                <div className="contact-card">
                    <div className="contact-card-inner">
                        <div className="contact-card-title">Bonuses</div>

                        <div className="contact-color-card">
                            {approvalCount?.rewardPointsValues.length > 0 &&
                                approvalCount?.rewardPointsValues.map((reward, index) => (
                                    <SummaryStatBox
                                        key={reward.id || index}
                                        color={reward.color}
                                        statName={reward.name}
                                        total={`$${reward.totals}`}
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-title mt-3">
                <h2 className="page-heading">Approval Status</h2>
            </div>
            <div className='table-card'>
                <Table
                    columns={columns}
                    dataSource={approvalBonus.map((item, index) => ({ ...item, key: item.id || index }))}
                    isLoading={isLoading}
                    pagination={{ pageSize: 10, showSizeChanger: false }}
                    locale={{ emptyText: <NoDataState title={"No recognitions available."} /> }}
                />
            </div>

        </div>
    );
};

export default ApprovalBonusListPresentation;