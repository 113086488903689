import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import { configMode } from 'src/_shared/api/';
import { getUserPoints } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { lambda, parse } from 'src/_shared/services/utils';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import {
	EmployeeInfoCard,
	EmployeePointsInfo,
	EmployeeReferralInfo,
	ReferralsTable,
} from './employee-details-items';

class EmployeeDetailsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currencyRate: 1,
			currencySymbol: 'USD',
			id: props.id,
			referralBonuses: props.referralBonuses,
			referrals: get(props, 'referrals', []),
			selectedUser: props.user,
			symbol: get(props, 'currentUser.company.symbol'),
			userPoints: get(props, 'user.points', 0),
			visible: false,
		};
	}

	async componentDidMount() {
		await this.getUserPoints();
	}

	componentDidUpdate(prevProps) {
		const { gdprReferrals, referralBonuses, referrals, user } = this.props;
		const referralsNextToken = get(this.props, 'referralsNextToken');
		if (this.props.onLoadMoreReferrals && referralsNextToken) {
			if (prevProps.referrals.length === 0 && referrals.length > 0)
				this.setState({ referrals });
			this.props.onLoadMoreReferrals();
		} else if (prevProps.referrals !== referrals) {
			this.setState({ referrals });
		}

		if (prevProps.user !== user) this.setState({ selectedUser: user });

		if (prevProps.gdprReferrals !== gdprReferrals) {
			const referrals = get(user, 'referrals', []);
			if (gdprReferrals && gdprReferrals?.length > 0) {
				for (const gdprReference of gdprReferrals) {
					if (!referrals.find((ref) => ref.id === gdprReference.id)) {
						referrals.push(gdprReference);
					}
			}
			}

			const selectedUser = get(this.props, 'user', null);
			if (selectedUser) {
				selectedUser.referrals = referrals;
				this.setState({ selectedUser });
			}
		}

		if (prevProps.referralBonuses !== referralBonuses) {
			this.setState({ referralBonuses });
		}
	}

	getUserPoints = async (policy = 'network-only') => {
		const { client, id } = this.props;
		try {
			const { data } = await client.query({
				query: gql(getUserPoints),
				variables: {
					id,
				},
				fetchPolicy: policy,
			});
			let result = get(data, 'getUserPoints.points', 0);
			result ??= 0;
			this.setState({
				userPoints: result,
			});
		} catch (error) {
			console.log(error);
		}
	};

	handleCancel = () => {
		this.setState({ visible: false });
	};

	handleChangeRole = (value) => {
		this.setState((state, props) => ({
			selectedUser: {
				...state.selectedUser,
				role: value,
			},
		}));
		const { id } = this.state;
		this.props.onUpdate({
			input: {
				id,
				role: value,
			},
		});
	};

	handleError = () => {
		this.setState({ error: true });
	};

	handlePoints = (input) => {
		const host = window.location.hostname;
		let endpoint = '';
		endpoint =
			configMode === 'DEV'
				? 'manage-user-points-dev-app'
				: 'manage-user-points-prod-app';
		lambda({ endpoint, variables: { input } })
			.then(async () => {
				await this.getUserPoints();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	handleSupportCancel = () => {
		this.setState({ showSupportPasswordResetModal: false });
	};

	showModal = () => {
		this.setState({ visible: true });
	};

	sortByAlph = (a, b) => {
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	toggleDisabled = () => {
		const { id, selectedUser } = this.state;
		this.props.toggleDisableUser(id, selectedUser.disabled);
	};

	toggleResetPasswordModal = () => {
		this.setState({ showSupportPasswordResetModal: true });
	};

	render() {
		const {
			selectedUser,
			visible,
			currencyRate,
			currencySymbol,
			symbol,
			userPoints,
			referrals,
		} = this.state;
		const {
			onUpdate,
			onAddDepartment,
			onDeleteDepartment,
			currentUser,
			departments,
			userGroups,
			subCompanies,
			allMultiLingualData,
		} = this.props;
		let isPointsInfoVisible = false;
		const pointsSettings = get(selectedUser, 'company.pointsSettings');
		if (pointsSettings !== null && pointsSettings !== undefined) {
			const pointsSettingsData = parse(pointsSettings);
			isPointsInfoVisible = Boolean(pointsSettingsData.enabled);
		}

		if (!selectedUser || !departments) {
			return <Spinner symbol={symbol} />;
		}

		const supportAdmin = parse(get(this.props, 'currentUser.admin'));
		const supportAdminPermissions = get(supportAdmin, 'permissions') === '*';
		if (
			selectedUser &&
			selectedUser.companyId != currentUser.companyId &&
			!supportAdminPermissions
		) {
			return <Spinner symbol={symbol} />;
		}

		return (
			<main>
				<Link to="/employees" className="custom-back-btn">
					<i className="icon-arrow-left" />
					<span>Employees</span>
				</Link>
				<div className="employee-detail-wrap">
					<div className="ed-left">
						<EmployeeInfoCard
							allDepartments={departments}
							allSubCompanies={subCompanies}
							allUserGroups={userGroups}
							client={this.props.client}
							currentCurrencyRate={currencyRate}
							currentCurrencySymbol={currencySymbol}
							currentUser={currentUser}
							currentUserAdmin={get(this.props, 'currentUser.admin')}
							employee={selectedUser}
							handleCancel={this.handleCancel}
							handleChangeRole={this.handleChangeRole}
							handleSupportCancel={this.handleSupportCancel}
							setCurrentUser={this.props.setCurrentUser}
							showModal={this.showModal}
							showSupportPasswordResetModal={
								this.state.showSupportPasswordResetModal
							}
							toggleDisabled={this.toggleDisabled}
							toggleResetPasswordModal={this.toggleResetPasswordModal}
							visible={visible}
							allMultiLingualData={allMultiLingualData}
							onAddDepartment={onAddDepartment}
							onDeleteDepartment={onDeleteDepartment}
							onUpdate={onUpdate}
						/>
					</div>
					<div className="ed-right">
						<div className="ed-card">
							<EmployeeReferralInfo
								currentUser={this.props.user}
								referralBonuses={get(this.state, 'referralBonuses')}
								employeeReferrals={referrals}
								currentCurrencyRate={currencyRate}
								currentCurrencySymbol={currencySymbol}
								allMultiLingualData={allMultiLingualData}
							/>
						</div>
						{isPointsInfoVisible ? (
							<div className="ed-card">
								<EmployeePointsInfo
									currentUser={currentUser}
									companyId={currentUser.companyId}
									userId={currentUser.id}
									referralBonuses={get(this.state, 'referralBonuses')}
									employeeReferrals={referrals}
									currentCurrencyRate={currencyRate}
									currentCurrencySymbol={currencySymbol}
									points={userPoints}
									handlePoints={this.handlePoints}
									currentUserEmail={get(currentUser, 'emailAddress')}
									selectedUserId={get(selectedUser, 'id')}
								/>
							</div>
						) : null}
					</div>
				</div>
				<h4 className="ed-title">{selectedUser.firstName}&#39;s Referrals</h4>
				{referrals && referrals.length > 0 ? (
					<div className="table-card">
						<ReferralsTable
							sortByAlph={this.sortByAlph}
							gdprReferrals={get(this.props, 'gdprReferrals')}
							filteredData={referrals}
							currentCurrencyRate={currencyRate}
							currentCurrencySymbol={currencySymbol}
							currentUser={selectedUser}
							allMultiLingualData={allMultiLingualData}
						/>
					</div>
				) : (
					<div className="no-content">
						<img className="no-content-icon" alt="erin-logo" src={fileIcon} />
						<p className="no-content-text">
							{selectedUser.firstName} has not made any referrals.
						</p>
					</div>
				)}
			</main>
		);
	}
}

export default withApollo(EmployeeDetailsComponent);
