import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import fileIcon from '../../../_shared/assets/erin_lightgray.png';
import { getSetErrorImageURL, ml } from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors.js';

dayjs.extend(customParseFormat);

class BaseAnnouncementCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorImageURL: '',
		};
	}

	async componentDidMount() {
		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(currentUser?.company?.errorImage?.key);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	formatDate(date, languageCode, dateFormat) {
		if (!date) return;
		const isoDate = new Date(date);
		const month = isoDate.getMonth() + 1;
		const day = isoDate.getDate();
		const year = isoDate.getFullYear();


		if (
			(languageCode === 'FR') || languageCode === 'DE' || languageCode === 'ES' || languageCode === 'RU' ||
			dateFormat === 'European'
		) {
			return `${day}/${month}/${year}`;
		}

		return `${month}/${day}/${year}`;
	}

	render() {
		const { title, currentUser, allMultiLingualData, announcement } =
			this.props;
		const userGroupId = get(currentUser, 'userGroupId');
		const languageCode = get(currentUser, 'languageCode');
		const dateFormat = get(currentUser, 'dateFormat');
		const whiteLabel = get(currentUser, 'company.whiteLabel');
		const { errorImageURL } = this.state;

		let filteredAnnouncement = announcement.filter((announcement) => {
			const userGroups = JSON.parse(announcement.userGroups);
			return userGroups
				? userGroups.some((group) => group.key === userGroupId)
				: [];
		});

		const allAnnouncement = announcement.filter((announcement) => {
			return (
				!announcement.userGroups ||
				(announcement.userGroups &&
					JSON.parse(announcement.userGroups).length === 0)
			);
		});

		if (allAnnouncement && allAnnouncement.length > 0)
			filteredAnnouncement = filteredAnnouncement.concat(allAnnouncement);

		return (
			<div className="d-card height-equal" style={{ paddingBottom: 30 }}>
				<div className="d-card-head">
					<h4 className="d-title">{title}</h4>
				</div>
				{filteredAnnouncement.length > 0 && !filteredAnnouncement[0].hide ? (
					<>
						<div className="announcement-title-wrap">
							<h4 className="announcement-title">
								{filteredAnnouncement[0].title}
							</h4>
							<span style={{ fontSize: 12, color: COLORS.lightGray }}>
								{dayjs(new Date(filteredAnnouncement[0].dateCreated)).isValid
									? this.formatDate(
											filteredAnnouncement[0].dateCreated,
											languageCode,
											dateFormat
										)
									: this.formatDate(
											filteredAnnouncement[0].dateCreated,
											languageCode,
											dateFormat
										)}
							</span>
						</div>
						<div
							dangerouslySetInnerHTML={{
								__html: filteredAnnouncement[0].content,
							}}
							style={{ maxHeight: 208, overflowY: 'auto' }}
						/>

						<div className="view-more">
							<Link to="/announcements" className="more-less">
								View All Announcements
							</Link>
						</div>
					</>
				) : (
					<div className="no-content">
						{whiteLabel ? (
							<img
								src={errorImageURL}
								alt="error image"
								className="no-content-icon"
							/>
						) : (
							<img className="no-content-icon" alt="erin-logo" src={fileIcon} />
						)}
						<p className="no-content-text">
							{ml('No Announcements', currentUser, allMultiLingualData)}
						</p>
					</div>
				)}
			</div>
		);
	}
}

export const AnnouncementCard = BaseAnnouncementCard;
