import CompanyCard from './addCompanyCard/CompanyCardComponent.jsx';

export function SuperUser(props) {
	const {
		company,
		onCreateUser,
		onCreateCompany,
		onCreateDepartment,
		currentUser,
		onCreateUserGroup,
	} = props;

	return (
		<main>
			<div className="page-title">
				<h2 className="page-heading">Erin System Admin Console</h2>
			</div>
			<CompanyCard
				{...props}
				currentUser={currentUser}
				addCompany={onCreateCompany}
				addUser={onCreateUser}
				addDepartment={onCreateDepartment}
				addUserGroup={onCreateUserGroup}
			/>
		</main>
	);
}
