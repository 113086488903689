import { configMode } from 'src/_shared/api/';
import { lambda } from 'src/_shared/services/utils';

export const SET_ACTIVE_JOBS = 'SET_ACTIVE_JOBS';
export const GET_BUSINESS_NETWORK_DATA = 'GET_BUSINESS_NETWORK_DATA';
export const GET_REFERRALS = 'GET_REFERRALS';
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const INVITE_EMPLOYEES = 'INVITE_EMPLOYEES';
export const INVITE_EMPLOYEES_STARTED = 'INVITE_EMPLOYEES_STARTED';
export const INVITE_EMPLOYEES_SUBMITTING = 'INVITE_EMPLOYEES_SUBMITTING';
export const INVITE_EMPLOYEES_SUCCEEDED = 'INVITE_EMPLOYEES_SUCCEEDED';
export const INVITE_EMPLOYEES_FAILED = 'INVITE_EMPLOYEES_FAILED';
export const INVITE_EMPLOYEES_CANCELLED = 'INVITE_EMPLOYEES_CANCELLED';
export const BULK_REFERRAL_SUBMITTING = 'BULK_REFERRAL_SUBMITTING';

export const SET_MULTILINGUAL_DATA = 'SET_MULTILINGUAL_DATA';
export const SET_TRANSLATIONSBYLANGUAGECODE_DATA =
	'SET_TRANSLATIONSBYLANGUAGECODE_DATA ';
export const ACCOUNT_CLAIM_IMPORT_STARTED = 'ACCOUNT_CLAIM_IMPORT_STARTED';
export const ACCOUNT_CLAIM_IMPORT_CANCELLED = 'ACCOUNT_CLAIM_IMPORT_CANCELLED';
export const ACCOUNT_CLAIM_IMPORT_SUBMITTING =
	'ACCOUNT_CLAIM_IMPORT_SUBMITTING';
export const ACCOUNT_CLAIM_IMPORT_SUCCEEDED = 'ACCOUNT_CLAIM_IMPORT_SUCCEEDED';
export const ACCOUNT_CLAIM_IMPORT_FAILED = 'ACCOUNT_CLAIM_IMPORT_FAILED';

export const createSetActiveJobsAction = (activeJobs) => ({
	type: SET_ACTIVE_JOBS,
	payload: {
		activeJobs,
	},
});

export const createGetBusinessNetworkAction = (businessNetwork) => ({
	type: GET_BUSINESS_NETWORK_DATA,
	payload: {
		businessNetwork,
	},
});

export const createGetDepartmentsAction = (departments) => ({
	type: GET_DEPARTMENTS,
	payload: {
		departments,
	},
});

export const createGetReferralsAction = (referrals) => ({
	type: GET_REFERRALS,
	payload: {
		referrals,
	},
});

export const createGetEmployeesAction = (employees) => ({
	type: GET_EMPLOYEES,
	payload: {
		employees,
	},
});

export const createInviteEmployeesAction = (
	users,
	fileData,
	role,
	createdById,
	companyId,
	languageCode,
	callback
) => {
	return (dispatch) => {
		dispatch(createInviteEmployeesSubmittingAction());
		const promises = [];
		let endpoint = '';
		const host = window.location.hostname;
		endpoint = configMode === 'DEV' ? 'dev-invite' : 'invite';

		if (users && users.length > 0) {
			promises.push(
				lambda({
					endpoint,
					variables: {
						createdById,
						users,
						companyId,
						role,
						languageCode,
					},
				})
			);
		}

		if (fileData) {
			promises.push(lambda({ endpoint: 'batchInvite', variables: fileData }));
		}

		if ((users && users.length > 0) || fileData) {
			Promise.all(promises)
				.then((responses) =>
					responses.map((resp) => (resp.body ? resp.json() : {}))
				)
				.then((dataCollection) => {
					dispatch(createInviteEmployeesSucceededAction(dataCollection));
					if (callback) callback(true);
				})
				.catch((error) => {
					dispatch(createInviteEmployeesFailedAction(error));
					if (callback) callback(false);
				});
		} else {
			dispatch(createInviteEmployeesFailedAction('No inputs specified'));
		}
	};
};

export const createInviteEmployeesStartedAction = () => ({
	type: INVITE_EMPLOYEES_STARTED,
});

const createInviteEmployeesSubmittingAction = () => ({
	type: INVITE_EMPLOYEES_SUBMITTING,
});

const createBulkReferralActionSubmittingAction = () => ({
	type: BULK_REFERRAL_SUBMITTING,
});

const createInviteEmployeesSucceededAction = (inviteResults) => ({
	type: INVITE_EMPLOYEES_SUCCEEDED,
	payload: {
		inviteResults,
	},
});

export const createInviteEmployeesFailedAction = (error) => ({
	type: INVITE_EMPLOYEES_FAILED,
	payload: {
		error,
	},
});

export const createInviteEmployeesCancelledAction = () => ({
	type: INVITE_EMPLOYEES_CANCELLED,
});

export const createSetMultiLingualData = (multiLingualData) => ({
	type: SET_MULTILINGUAL_DATA,
	payload: {
		multiLingualData,
	},
});
export const createSetTranslationsByLanguageCode = (
	translationsByLanguageCode
) => ({
	type: SET_TRANSLATIONSBYLANGUAGECODE_DATA,
	payload: {
		translationsByLanguageCode,
	},
});

export const createAccountClaimImportStartedAction = () => ({
	type: ACCOUNT_CLAIM_IMPORT_STARTED,
});

export const createAccountClaimImportCancelledAction = () => ({
	type: ACCOUNT_CLAIM_IMPORT_CANCELLED,
});

const createAccountClaimImportSubmittingAction = () => ({
	type: ACCOUNT_CLAIM_IMPORT_SUBMITTING,
});

const createAccountClaimImportSucceededAction = (importResults) => ({
	type: ACCOUNT_CLAIM_IMPORT_SUCCEEDED,
	payload: {
		importResults,
	},
});

export const createAccountClaimImportFailedAction = (error) => ({
	type: ACCOUNT_CLAIM_IMPORT_FAILED,
	payload: {
		error,
	},
});

export const createAccountClaimImportAction = (fileData) => {
	return (dispatch) => {
		dispatch(createAccountClaimImportSubmittingAction());

		const promises = [];
		let url = '';
		const host = window.location.hostname;
		if (configMode === 'DEV') {
			url =
				'https://7ukh1rb8j2.execute-api.us-east-2.amazonaws.com/default/account-claim-import-dev';
		} else {
			url =
				'https://ldy6sv09j3.execute-api.us-east-2.amazonaws.com/default/account-claim-import-prod';
		}

		if (fileData) {
			promises.push(
				fetch(url, {
					method: 'POST',
					mode: 'no-cors',
					body: fileData,
				})
			);
		}

		if (fileData) {
			Promise.all(promises)
				.then((responses) =>
					responses.map((resp) => (resp.body ? resp.json() : {}))
				)
				.then((dataCollection) =>
					dispatch(createAccountClaimImportSucceededAction(dataCollection))
				)
				.catch((error) =>
					dispatch(createAccountClaimImportFailedAction(error))
				);
		} else {
			dispatch(createAccountClaimImportFailedAction('No inputs specified'));
		}
	};
};
