import React, { useState } from 'react';
import { Spin, Empty, Button, Tooltip } from 'antd';
import { GoStopwatch } from 'react-icons/go';
import WinnerListModalModal from './WinnerListModalModal';
import { CoreBadge, RewardShowCaseModal } from '../../../RnRCommonComponent';
import NominateEmployeeModalContainer from '../../container/NominateEmployeeModalContainer';
import { datediff } from '../../../nominatedRewards/utils.js';
import formatDateInTable from '../../../rewardsAndRecognitionHelper/CommonDate';
import NoDataState from '../../../RnRCommonComponent/noDataState/NoDataState.jsx';

import './style.scss';

const NomintaionRewardsList = (props) => {
	const {
		title,
		badgeList,
		isLoading,
		openNomination = false,
		nominatedAwardWinner = false,
	} = props;

	const [isViewAllISOpen, setIsViewAllISOpen] = useState(false);
	const initialState = {
		show: false,
		nominationConfigId: '',
		badge: {},
		endDate: '',
	};
	const [nominateModalConfig, setNominateModalConfig] = useState(initialState);
	const [showWinnerListModal, setShowWinnerListModal] = useState({
		show: false,
		winnerList: [],
		badge: {},
	});

	const openNominationModal = (nominationConfig, badge) => {
		setNominateModalConfig({
			show: true,
			nominationConfigId: nominationConfig.id,
			badge,
			endDate: nominationConfig.endDate,
		});
	};

	const closeNominationModal = () => {
		setNominateModalConfig(initialState);
	};

	return (
		<div className="d-card nomintaion-rewards-list height-equal">
			<div className="d-card-head">
				<h4 className="d-title">{title}</h4>
				{badgeList?.length > 3 ? (
					<Button
						onClick={() => {
							setIsViewAllISOpen(true);
						}}
						type="link"
					>
						View All
					</Button>
				) : null}
			</div>
			<div>
				{badgeList?.length ? (
					<div className="badge-list">
						{badgeList?.slice(0, 3)?.map((nominationConfig, index) => {
							const { badge } = nominationConfig;
							return (
								<div key={index} className="nominated-badges-row">
									<div className="badges-img" key={index}>
										<div className="badges-svg">
											<CoreBadge
												badgeType={badge?.badgeType?.code}
												badgeIcon={badge?.icon?.code}
												label={badge?.label}
												color={badge?.color}
											/>
										</div>
										{openNomination ? (
											<div className="text-center img-label mt-2">
												<div>
													<Button
														type="primary"
														style={{ width: '50%' }}
														onClick={() =>
															openNominationModal(nominationConfig, badge)
														}
													>
														Nominate
													</Button>
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														columnGap: '10px',
														marginTop: '20px',
													}}
												>
													<div>
														<GoStopwatch size={'30px'} color="#E75FA8" />
													</div>
													<div>
														<div style={{ fontWeight: 'bolder' }}>
															{datediff(
																new Date(),
																new Date(nominationConfig.endDate)
															)}{' '}
															Days Remaining
														</div>
														<div>
															Ends on{' '}
															{formatDateInTable(nominationConfig.endDate)}
														</div>
													</div>
												</div>
											</div>
										) : null}
										{nominatedAwardWinner ? (
											nominationConfig?.winnerDetails?.length ? (
												<div className="text-center img-label mt-2" key={index}>
													<div>
														<div>
															<Tooltip
																placement="top"
																trigger="hover"
																style={{
																	position: 'absolute',
																	fontWeight: 1000,
																	margin: 0,
																	padding: 0,
																	bottom: 0,
																}}
																title={
																	<>
																		{nominationConfig?.winnerDetails.map(
																			({ firstName, lastName }) => {
																				return (
																					<li key={index}>{`${firstName} ${lastName}`}</li>
																				);
																			}
																		)}
																	</>
																}
															>
																<div
																	style={{ fontWeight: 'bolder' }}
																>{`${nominationConfig?.winnerDetails[0]?.firstName || ''} ${nominationConfig?.winnerDetails[0]?.lastName || ''} ${nominationConfig?.winnerDetails?.length > 1 ? ',...' : ''}`}</div>
															</Tooltip>
															<div>
																{formatDateInTable(nominationConfig.updatedAt)}
															</div>
														</div>
														<div className="mt-3">
															<Button
																type="link"
																onClick={() => {
																	setShowWinnerListModal({
																		show: true,
																		winnerList: nominationConfig?.winnerDetails,
																		badge,
																	});
																}}
															>
																View
															</Button>
														</div>
													</div>
												</div>
											) : null
										) : null}
									</div>
								</div>
							);
						})}
					</div>
				) : isLoading ? (
					<Spin className="spinner" />
				) : (
					<div className="nomination-height">
						<NoDataState title={props.emptyString} />
					</div>
				)}
			</div>
			{isViewAllISOpen ? (
				<RewardShowCaseModal
					isViewAllISOpen={isViewAllISOpen}
					rewardsListData={badgeList}
					title={title}
					openNomination={openNomination}
					openNominationModal={openNominationModal}
					nominatedAwardWinner={nominatedAwardWinner}
					setShowWinnerListModal={setShowWinnerListModal}
					handleCloseModal={() => {
						setIsViewAllISOpen(false);
					}}
				/>
			) : null}
			{showWinnerListModal.show ? (
				<WinnerListModalModal
					showModal={showWinnerListModal.show}
					setShowModal={setShowWinnerListModal}
					winnerList={showWinnerListModal.winnerList}
					badgeDetails={showWinnerListModal.badge}
				/>
			) : null}
			{nominateModalConfig.show ? (
				<NominateEmployeeModalContainer
					showModal={nominateModalConfig.show}
					nominationConfigId={nominateModalConfig.nominationConfigId}
					setNominateModalConfig={setNominateModalConfig}
					closeModal={closeNominationModal}
					badgeDetails={nominateModalConfig.badge}
					endDate={nominateModalConfig.endDate}
				/>
			) : null}
		</div>
	);
};

export default NomintaionRewardsList;
