import React from "react";
import { Button, Modal, Tooltip } from "antd";
import { GoStopwatch } from "react-icons/go";

import CoreBadge from "../CoreBadge";

import { datediff } from "../../nominatedRewards/utils.js";
import formatDateInTable from "../../rewardsAndRecognitionHelper/CommonDate.js";

import './style.scss'


const RewardShowCaseModal = ({
  title,
  isViewAllISOpen,
  rewardsListData,
  handleCloseModal,
  openNomination,
  openNominationModal,
  nominatedAwardWinner,
  setShowWinnerListModal
}) => {

  return (
    <Modal
      width={800}
      className="reward-modal"
      open={isViewAllISOpen}
      title={title}
      footer={false}
      onOk={false}
      onCancel={handleCloseModal}
      destroyOnClose={true}
    >
      <div className='badges-list my-4'>
        {rewardsListData?.map((nominationConfig, index) => {
          const { badge: data } = nominationConfig;
          return (
            <div className='text-center badge-container' key={index}>
              <CoreBadge
                badgeType={data?.badgeType?.code}
                badgeIcon={data?.icon?.code}
                label={data?.label}
                color={data?.color} />
              <div className="text-center img-label mt-2">
                {openNomination ?
                  <>
                    <div>
                      <Button className="nominate-button" type='primary' onClick={() => openNominationModal(nominationConfig, data)}>Nominate</Button>
                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      columnGap: "10px",
                      marginTop: "20px"
                    }}>
                      <div>
                        <GoStopwatch size={"30px"} color='#E75FA8' />
                      </div>
                      <div>
                        <div>{datediff(new Date(), new Date(nominationConfig.endDate))} Days Remaining</div>
                        <div>Ends on {formatDateInTable(nominationConfig.endDate)}</div>
                      </div>
                    </div>
                  </> : null}
                {nominatedAwardWinner ?
                  <div className="text-center img-label mt-2">
                    <div>
                      <div>
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          style={{
                            position: 'absolute',
                            fontWeight: 1000,
                            margin: 0,
                            padding: 0,
                            bottom: 0,
                          }}
                          title={
                            <>
                              {nominationConfig?.winnerDetails.map(({ firstName, lastName }) => {
                                return (
                                  <li>{`${firstName} ${lastName}`}</li>
                                )
                              })}
                            </>
                          }
                        >
                          <div>{`${nominationConfig?.winnerDetails[0]?.firstName || ""} ${nominationConfig?.winnerDetails[0]?.lastName || ""} ${nominationConfig?.winnerDetails?.length > 1 ? ",..." : ""}`}</div>
                        </Tooltip>
                        <div>{formatDateInTable(nominationConfig.updatedAt)}</div>
                      </div>
                      <div className='mb-3'>
                        <Button type='link' onClick={() => {
                          setShowWinnerListModal({ show: true, winnerList: nominationConfig?.winnerDetails, badge: data })
                        }}>View</Button>
                      </div>
                    </div>
                  </div>
                  : null}
              </div>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default RewardShowCaseModal