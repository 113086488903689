import get from 'lodash/get';
import { Component } from 'react';
import JobCard from './ReferralJobCardComponent.jsx';
import ReferralModal from './ReferralModalComponent.jsx';

class ReferralModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			job: get(props, 'job'),
			contacts: get(props, 'contacts', []),
			contact: get(props, 'contact'),
			visible: false,
			referrals: get(props, 'referrals', []),
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.contacts !== this.props.contacts) {
			this.setState({ contacts: this.props.contacts });
		}
	}

	handleCancel = () => {
		this.setState({ visible: false });
	};

	handleCancelAll = () => {
		this.props.handleCancel();
		this.setState({ visible: false });
	};

	showModal = () => {
		this.setState({ visible: true });
	};

	render() {
		const { visible, contact } = this.state;
		const {
			job,
			contacts,
			data,
			currentTieredBonus,
			users,
			referralInformationText,
			createReferralForText,
			positiontext,
			selectEmployeeText,
			enterReferralInformationText,
			firstNameText,
			lastNameText,
			emailText,
			clickHereText,
			attachResumeText,
			noteText,
			referralAutomaticallyAcceptedText,
			submitReferralText,
			bonusText,
			theme,
			client,
			toggleLoading,
		} = this.props;
		return (
			<>
				<JobCard
					key={get(job, 'id')}
					client={client}
					job={job}
					contact={contact}
					data={data}
					currentTieredBonus={currentTieredBonus}
					bonusText={bonusText}
					currentUser={this.props.currentUser}
					onSelectJob={this.showModal}
				/>
				<ReferralModal
					history={this.props.history}
					client={client}
					currentUser={this.props.currentUser}
					currentTieredBonus={this.props.currentTieredBonus}
					contact={contact}
					contacts={contacts}
					visible={visible}
					updateReferrals={this.props.updateReferrals}
					handleCancel={this.handleCancel}
					handleCancelAll={this.handleCancelAll}
					referralInformationText={referralInformationText}
					createReferralForText={createReferralForText}
					positiontext={positiontext}
					selectEmployeeText={selectEmployeeText}
					enterReferralInformationText={enterReferralInformationText}
					firstNameText={firstNameText}
					lastNameText={lastNameText}
					emailText={emailText}
					clickHereText={clickHereText}
					attachResumeText={attachResumeText}
					noteText={noteText}
					referralAutomaticallyAcceptedText={referralAutomaticallyAcceptedText}
					submitReferralText={submitReferralText}
					users={users}
					toggleLoading={toggleLoading}
					theme={theme}
					job={job}
					onCreateContact={this.props.onCreateContact}
					onCreateReferral={this.props.onCreateReferral}
					onUpdateContact={this.props.onUpdateContact}
				/>
			</>
		);
	}
}
export default ReferralModalWrapper;
