import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ANALYTICS_CARD_TYPES } from 'src/_shared/constants/';
import AnalyticsCardComponent from './AnalyticsCardComponent.jsx';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { lambda } from 'src/_shared/services/utils.js';

function AdminAnalyticsComponent({
	analyticsCardInputValues,
	companyId,
	onUpdateCompany,
}) {
	const [annualHires, setAnnualHires] = useState(undefined);
	const [totalEmployees, setTotalEmployees] = useState(undefined);
	const [calculations, setCalculations] = useState({});

	useEffect(() => {
		if (analyticsCardInputValues) {
			const parsedValues = JSON.parse(analyticsCardInputValues);
			setAnnualHires(parsedValues.annualHires);
			setTotalEmployees(parsedValues.totalEmployees);
		}
	}, [analyticsCardInputValues]);

	useEffect(() => {
		setAnalyticsCalculations();
	}, [companyId]);

	const getAnalytics = async () => {
		let endpoint = 'calculateDashboardAnalytics';
		return await lambda({ endpoint, variables: { companyId: companyId } });
	};

	const setAnalyticsCalculations = async () => {
			const data = await getAnalytics();
			if (data?.message) {
				setCalculations(data.message);
			} else {
				console.log("Failed to retrieve analytics data.");
			}
	};

	const handleUpdateCompany = async (inputValue, cardType) => {
		const inputValues = {};

		if (cardType === 'hires-from-referrals') {
			setAnnualHires(inputValue);
			if (inputValue !== null) {
				inputValues.annualHires = inputValue;
			}

			if (totalEmployees || totalEmployees === 0) {
				inputValues.totalEmployees = totalEmployees;
			}
		} else if (cardType === 'employee-adoption') {
			setTotalEmployees(inputValue);
			if (inputValue !== null) {
				inputValues.totalEmployees = inputValue;
			}

			if (annualHires || annualHires === 0) {
				inputValues.annualHires = annualHires;
			}
		}

		const stringifiedValues =
			Object.keys(inputValues).length > 0 ? JSON.stringify(inputValues) : '{}';
		await onUpdateCompany({
			input: {
				id: companyId,
				analyticsCardInputValues: stringifiedValues,
			},
		});
		setAnalyticsCalculations();
	};

	const toolTipMessage = (
		<>
			<strong>What do these metrics mean?</strong>
			<ul>
				<li>
					% of Hires From Referrals - The percentage of total hires from employee referrals.
				</li>
				<li>
					Referred to Hired Ratio - Ratio of total referred candidates to total referral hires.
				</li>
				<li>
					Referral Sourcing Velocity - The days between the job posting date and the applied date for the referred candidate hired for the role.
				</li>
				<li>
					Referral Hiring Velocity - The days between the job posting date and the referred candidate hired date.
				</li>
				<li>
					% Adoption - Percentage of total employees that are using the platform.
				</li>
			</ul>
		</>
	);

	return (
		<div className="analytics-wrapper">
			<div className="analytics-title">
				<h3>PERFORMANCE</h3>
				<Tooltip
					placement="top"
					title={toolTipMessage}
					style={{ fontWeight: 1000 }}
				>
					<InfoCircleOutlined
						style={{
							cursor: 'pointer',
							color: '#1890ff',
						}}
					/>
					</Tooltip>
			</div>
			<div className="analytics-cards-container animate__animated animate__fadeInDown">
				<AnalyticsCardComponent
					handleUpdateCompany={handleUpdateCompany}
					inputValue={annualHires}
					cardCalculation={calculations?.percentHires}
					cardType={ANALYTICS_CARD_TYPES.HIRES}
					labelText="Estimated % Hires From Referrals"
					editHeaderText={` `}
					editQuestionText="Approximate number of hires per year:"
				/>
				<AnalyticsCardComponent
					cardCalculation={(calculations?.hireRatio && calculations?.hireRatio?.totalUniqueCandidates && calculations?.hireRatio?.totalHires) ? calculations?.hireRatio : null}
					cardType={ANALYTICS_CARD_TYPES.RATIO}
					labelText="Referred Candidates to Hire Ratio"
				/>
				<AnalyticsCardComponent
					cardCalculation={(calculations?.referralVelocity && calculations?.referralVelocity?.sourcingVelocity && calculations?.referralVelocity?.hiringVelocity) ? calculations?.referralVelocity : null}
					cardType={ANALYTICS_CARD_TYPES.VELOCITY}
					labelText="Referral Sourcing Velocity & Referral Hiring Velocity"
				/>
				<AnalyticsCardComponent
					handleUpdateCompany={handleUpdateCompany}
					cardCalculation={calculations?.employeeAdoption}
					cardType={ANALYTICS_CARD_TYPES.ADOPTION}
					labelText="Approximate Employee Adoption"
					editHeaderText={` `}
					editQuestionText="Total number of employees:"
					inputValue={totalEmployees}
				/>
			</div>
		</div>
	);
}

AdminAnalyticsComponent.propTypes = {
	analyticsCardInputValues: PropTypes.string,
	companyId: PropTypes.string.isRequired,
	onUpdateCompany: PropTypes.func.isRequired,
};

export default AdminAnalyticsComponent;
