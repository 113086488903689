import React, { useState } from "react";
import NomintaionRewardsListContainer from '../NomintaionRewardsListContainer';
import { RewardList as RewardListContainer } from "../../../RnRCommonComponent";
import './style.scss'
import { BADGE_TYPE } from "../../../RnRCommonComponent/CoreBadge/rewardsConstants.js";
import { Button } from 'antd';
import Automated from '../../../assets/svg/automated.svg'
import NoDataState from "../../../RnRCommonComponent/noDataState/NoDataState.jsx";

const AllRewardsListContainer = () => {

  const [shouldRefresh, setShouldRefresh] = useState(false);

  const nominatedRewards = [
    {
      title: "Open Nominations",
      openNomination: true,
      emptyString: "No Nominations found."
    }, {
      title: "Nomination Winners",
      nominatedAwardWinner: true,
      emptyString: "Winners not declared yet."
    }
  ]

  const allAward = [
    {
      title: "On-The-Spot Recognitions",
      badgeType: BADGE_TYPE.PEERTOPEER
    }, {
      title: "Milestones",
      badgeType: BADGE_TYPE.AUTOMATED
    }, {
      title: "Nominations",
      badgeType: BADGE_TYPE.NOMINATED
    }

  ]

  return <div className="all-rewards">
    <div className="main">
      <div className="page-title">
        <h2 className="page-heading">Nominations</h2>
      </div>
      <div className="row">
        {nominatedRewards?.map((props, index) =>
          <div className="col-lg-6 reward-winner" key={index}>
            <NomintaionRewardsListContainer
              {...props}
            />
          </div>)}
      </div>
      <div className="page-title mt-3">
        <h2 className="page-heading">All Recognitions & Milestones</h2>
      </div>
      <div className="row">
        <div className="all-award-container col-12">
          {allAward?.map((props, index) =>
            <RewardListContainer
              {...props}
              key={index}
              shouldRefresh={shouldRefresh}
              setShouldRefresh={setShouldRefresh}
              isDetailsShown={false}
              isPointsShown={true}
              isIssuedVisible={false}
            />)}
          <div className="d-card ">
            <div className="d-card-head">
              <h4 className="d-title-no-case">{"Automated Recognitions"}</h4>
            </div>
            <div style={{
              maxHeight: "220px",
              height: "100%",
              overflow: "unset",
            }}>
              <div className="badges-list-flex">
                <NoDataState title={"No automated recognitions generated yet."} />
                {/* <div
                  className='badges-container'
                  style={{ opacity: 1 }}>
                  <div className='badges-container-edit-target' style={{ cursor: 'pointer' }}>
                    <div className="badges-img">
                      {
                        <div className='badges-svg'>
                          <img src={Automated} alt='img' />
                        </div>
                      }
                      150 Points
                    </div>
                  </div>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "2px"
                  }}>
                    <Button
                      type='link'
                      className="text-center img-label">
                      Details
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default AllRewardsListContainer;
