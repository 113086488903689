import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Form, Input, Result, Row } from 'antd';
import { get, mapValues, upperFirst } from 'lodash';
import { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import ProgressButton from 'react-progress-button';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import LanguageSelect from 'src/_shared/components/language/LanguageSelectComponent.jsx';
import FormElements from 'src/form-builder/FormElements.jsx';
import uuid from 'uuid/v4';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { StringParser } from '../_shared/services';
import {
	downloadFromS3,
	filterPhoneNumber,
	ml,
	query,
	translation,
	uploadToS3Multipart,
} from '../_shared/services/utils';
import {
	contactButton,
	applyButton,
	buttonSpacing,
} from '../new-referral-landing-page/newReferralLandingStyles.js';
import {
	CardStyles,
	Department,
	DetailContainer,
	FormItemStyles,
	JobInfoContainer,
	NoteStyle,
	RemoveBottomMargin,
	candidateNameStyles,
	cardContainer,
	detailsTitle,
	fileWrap,
	headerBackground,
	logoContainer,
	outerCardContainer,
	refererName,
	sectionTitle,
	summaryValue,
	title,
	topSpacing,
} from './newInternalReferralStyles.js';

// Internal file

class NewInternalReferralCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accountClaim: this.props.referredUser?.accountClaim,
			allFormData: [],
			allMultiLingualData: [],
			atsIntegration: get(props, 'jobData.atsIntegration'),
			currentUser: this.props?.currentUser ?? {},
			buttonState: '',
			errors: [],
			filename: null,
			filetype: null,
			filteredQuestions: this.props.filteredQuestions,
			isError: false,
			isMore: false,
			isPhoneNumberEnabled: false,
			isResumeOptional: false,
			isResumeRequired: false,
			isSubmitting: false,
			originalFile: [],
			referralComplete: '',
			referralQuestions: this.props.referralQuestions,
			text: [],
			translatedDescription: this.props?.jobData?.jobOverview ?? '',
			translatedTitle: this.props?.jobData?.jobTitle ?? '',
			uniqueId: uuid(),
		};
	}

	async componentDidMount() {
		this.setResumeRequiredOptional();
		const emailAddress = this.props.referredUser?.emailAddress;
		const duplicate = false;

		// Find duplicate referrals here with new api and set to true if exists. Original query exposing too much data.

		if (duplicate) {
			this.setState({
				referralComplete: true,
			});
		}

		let subCompanyLogoSource = '';
		let companyLogoSource = '';
		if (this.props.jobData?.subCompanyLogo?.key) {
			subCompanyLogoSource = await downloadFromS3(
				this.props.jobData.subCompanyLogo.key
			);
		}

		if (this.props.jobData?.companyLogo?.key) {
			companyLogoSource = await downloadFromS3(
				this.props.jobData.companyLogo.key
			);
		}

		this.setState({
			companyLogoSrc: companyLogoSource,
			subCompanyLogoSrc: subCompanyLogoSource,
		});

		await this.getTranslatedData();
	}

	setResumeRequiredOptional = () => {
		const { resumeAttachData } = this.props;
		const resumeData = {};

		if (resumeAttachData && resumeAttachData.length > 0) {
			const acceptanceFormResumeData = resumeAttachData.filter(
				(item) => item.isInterested === true
			);
			if (acceptanceFormResumeData.length > 0) {
				Object.assign(resumeData, ...acceptanceFormResumeData);
				const resumeAttachmentData = JSON.parse(resumeData.questions);

				for (const key in resumeAttachmentData) {
					if (resumeAttachmentData.hasOwnProperty(key)) {
						if (key === 'Resume required') {
							this.setState({ isResumeRequired: resumeAttachmentData[key] });
						} else {
							this.setState({ isResumeOptional: resumeAttachmentData[key] });
						}
					}
				}
			}
		}
	};

	onDelete = () => {
		this.setState({
			originalFile: [],
			filename: null,
			filetype: null,
			errors: [],
		});
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const errors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files! ');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must smaller than 2MB!');
			}

			if (errors.length > 0) {
				this.setState({ filename: escape(e.target.files[0].name) });
				this.setState({ errors });
				return errors;
			}

			this.setState({
				filename: escape(e.target.files[0].name),
				filetype: e.target.files[0].type,
				originalFile: e.target.files[0],
			});

			this.setState({ errors: [] });
			return isDocOrDocsOrPdf && isLt2M;
		}
	};

	getTranslatedData = async () => {
		let languageCode = this.props?.jobData?.languageCode;
		if (languageCode === 'EN') {
			languageCode = 'US';
		}

		try {
			const allMultiLingualData = await query(
				{ module: 'NewReferral' },
				'queryMultiLingualByModuleIndex'
			);
			this.setState({
				allMultiLingualData,
				currentUser: { ...this.state.currentUser, languageCode },
			});
		} catch (error) {
			console.log(error);
		}
	};

	GetICIMSRedirectLink = (
		publicLink,
		firstName,
		lastName,
		emailAddress,
		source,
		sourceName
	) => {
		let redirectLink =
			publicLink +
			`?mode=prepopulate&iifn=${firstName}&iiln=${lastName}&iiem=${emailAddress}&iis=${source}&iisn=${sourceName}`;
		try {
			const atsIntegration = get(this.state, 'atsIntegration');
			if (atsIntegration) {
				const atsIntegrationJSON = JSON.parse(atsIntegration);
				const UseATSIdToSubmit = get(
					atsIntegrationJSON[0],
					'UseATSIdToSubmit',
					false
				);
				if (UseATSIdToSubmit) {
					const accountClaim = get(this.state, 'accountClaim');
					// Make sure we have an account claim and an ATS Id
					if (accountClaim && accountClaim != null && accountClaim.atsId) {
						redirectLink =
							publicLink +
							`?mode=prepopulate&iis=Referral&iispid=${accountClaim.atsId}`;
					}
				}
			}
		} catch {
			redirectLink =
				publicLink +
				`?mode=prepopulate&iifn=${firstName}&iiln=${lastName}&iiem=${emailAddress}&iis=${source}&iisn=${sourceName}`;
		}

		return redirectLink;
	};

	handleChange = (formData) => {
		this.setState((prevState) => {
			const { allFormData } = prevState;
			const currentIndex = allFormData.findIndex(
				(obj) => obj.name === formData.name
			);

			if (currentIndex > -1) {
				allFormData[currentIndex] = {
					...allFormData[currentIndex],
					...formData,
				};
			} else {
				allFormData.push(formData);
			}

			return { allFormData: [...allFormData] };
		});
	};

	getInputElement = (item) => {
		const Input = FormElements[item.element];
		return (
			item.text != 'Phone Number' && (
				<Form.Item
					key={item.id}
					name={item.id}
					style={{ marginBottom: 6 }}
					rules={[
						{
							required: true,
							message: ml(
								'Please fill out this required field.',
								this.state.currentUser,
								this.state.allMultiLingualData,
								'NewReferral'
							),
						},
					]}
				>
					<div key={`${item.id}`}>
						<Input
							key={`from_${item.id}`}
							mutable
							handleChange={(e) => this.handleChange(e)}
							data={item}
							read_only={this.props.read_only}
						/>
					</div>
				</Form.Item>
			)
		);
	};

	getSimpleElement = (item) => {
		const Element = FormElements[item.element];
		return <Element key={`${item.id}`} mutable data={item} />;
	};

	renderCustomFormElements = (parsedCustomFormArray) => {
		if (parsedCustomFormArray.length > 0) {
			parsedCustomFormArray?.some((question) => {
				if (question.text == 'Phone Number') {
					this.setState({ isPhoneNumberEnabled: true });
				}
			});

			const customItems = parsedCustomFormArray.map((item) => {
				switch (item.element) {
					case 'TextInput':
					case 'NumberInput':
					case 'TextArea':
					case 'Dropdown':
					case 'DatePicker':
					case 'RadioButtons':
					case 'Rating':
					case 'Tags':
					case 'Range': {
						return this.getInputElement(item);
					}

					default: {
						return this.getSimpleElement(item);
					}
				}
			});
			return <div style={{ marginTop: 10 }}>{customItems}</div>;
		}

		return null;
	};

	handleSubmit = async (values) => {
		this.setState({ buttonState: 'loading' });
		const {
			contacts,
			jobData,
			onUpdateContact,
			onCreateContact,
			referredUser,
		} = this.props;
		const { filename, isResumeRequired, allFormData } = this.state;
		const { companyId, jobId, jobUrl } = jobData;
		try {
			const { firstName, emailAddress, phoneNumber, lastName } = values;
			if (isResumeRequired && !filename) {
				throw 'Resume required.';
			}

			// Check if contact exists for user
			const matchingContact = contacts.find((contact) => {
				if (contact.emailAddress) {
					return (
						contact.emailAddress.toLowerCase() === emailAddress.toLowerCase()
					);
				}
			});
			const contactExists = Boolean(matchingContact);
			let contactId = matchingContact ? matchingContact.id : null;
			// Create contact if there isn't one
			if (!contactExists) {
				const d = new Date();
				const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;

				const contactInput = {
					input: {
						firstName,
						lastName,
						userId: referredUser.id,
						companyId,
						importMethod: emailAddress ? 'email' : null,
						disableSmartReferrals: false,
						emailAddress,
						confirmedEmail: emailAddress,
						phoneNumber,
					},
				};

				const createdContact = await onCreateContact(contactInput);
				const createdContactId = createdContact?.id;
				if (!createdContactId) {
					throw 'Error creating contact';
				}

				if (filename) {
					const resume = {
						bucket: 'erin-documents',
						key: `resumes/${createdContactId}/${dformat + '-' + filename}`,
						region: 'us-east-2',
					};
					onUpdateContact({
						id: createdContactId,
						contactResume: resume,
					});
					await this.updateS3ContactResume(resume);
				}

				contactId = createdContactId;
			}

			const referralSource = jobData?.isGeneralReferral ? 'general' : 'direct';
			// Create new referral payload
			const referralInput = {
				companyId,
				contactId,
				referralType: 'self',
				referralSource,
				referralDevice: 'web',
				userId: referredUser.id,
				jobId,
				status: 'accepted',
			};
			// Stringify/add custom questions if exists, allFormData has fallbacks to empty array
			const interestedQuestionsData =
				allFormData.length > 0 ? JSON.stringify(allFormData) : null;
			if (interestedQuestionsData)
				referralInput.interestedQuestionsData = interestedQuestionsData;
			await this.props.onCreateReferral(referralInput);

			// You can't improve on perfection
			this.setState({ buttonState: 'success' });
			await new Promise((resolve) => {
				setTimeout(() => resolve(), 2000);
			});
			this.setState({ referralComplete: true });
			if (jobUrl && jobUrl !== 'true') {
				setTimeout(() => {
					window.location.href = jobUrl;
				}, 2200);
			}
		} catch (error) {
			this.handleError(error);
		}
	};

	handleError = (error) => {
		console.log(error);
		this.setState({
			buttonState: 'error',
			isError: true,
			isSubmitting: false,
		});
	};

	selectLanguage = async (languageCode) => {
		const currentUser = this.props?.currentUser;
		let translatedTitle = this.props.jobData?.jobTitle;
		let desc = (this.props?.jobData?.jobOverview).toString('html');
		let translatedDescription = desc;
		if (languageCode !== 'US') {
			desc = `<h1>${translatedTitle}</h1>${desc}`;
			desc = desc.replaceAll('&nbsp;', '');
			desc = desc.replaceAll('&amp;', 'and');
			translatedDescription = await translation(desc, { languageCode });
			translatedTitle = translatedDescription.substring(
				translatedDescription.indexOf('<h1>') + 4,
				translatedDescription.lastIndexOf('</h1>')
			);
			translatedDescription = translatedDescription.slice(
				Math.max(0, translatedTitle.length + 9)
			);
		}

		this.props.setCurrentUser({ ...this.props.currentUser, languageCode });
		this.setState({
			translatedTitle,
			translatedDescription,
			currentUser: { ...currentUser, languageCode },
		});
	};

	updateS3ContactResume = async (resume) => {
		const { filename, originalFile } = this.state;
		if (filename) {
			try {
				await uploadToS3Multipart(originalFile, resume.key, resume.bucket);
			} catch (error) {
				console.error(error);
			}
		}
	};

	render() {
		const { jobData, referredUser } = this.props;
		const { firstName, lastName, emailAddress, company } = referredUser;
		const {
			companyLogoSrc,
			filename,
			allMultiLingualData,
			isResumeOptional,
			isResumeRequired,
			subCompanyLogoSrc,
			currentUser,
			uniqueId,
			referralQuestions,
			isPhoneNumberEnabled,
		} = this.state;
		const whiteLabel = company?.whiteLabel ?? jobData?.whiteLabel;
		const hideRecruiterInfo = jobData?.hideRecruiterInfo;
		const mail = `mailto:${jobData.hiringEmail}?subject=${jobData.position} referred by ${jobData.referrer}`;
		const referrerMail = `mailto:${jobData.referrerEmail}`;

		// Regex removes converts all string '$null' and 'null' values to falsey null so that they don't show on screen
		const validJobData = mapValues(jobData, (o) => {
			if (/^\$?null/.test(o)) {
				return null;
			}

			return o;
		});

		return (
			<section className="landing-background">
				<div
					className={headerBackground}
					style={{
						paddingTop: 10,
						backgroundColor: jobData.brandColor,
						paddingBottom: 10,
					}}
				>
					<Row type="flex" justify="center">
						<Col className="heading-col" xs={24}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginBottom: 10,
								}}
							>
								<LanguageSelect
									topRight
									referralLanguage
									onSelect={this.selectLanguage}
								/>
							</div>
						</Col>
						<Col className="heading-col" xs={24}>
							{validJobData.subCompanyLogo ? (
								<div className={logoContainer}>
									<img src={subCompanyLogoSrc} alt="subcompany logo" />
								</div>
							) : validJobData.companyLogo ? (
								<div className={logoContainer}>
									<img src={companyLogoSrc} alt="company logo" />
								</div>
							) : (
								<div className={logoContainer}>
									<img src={ErinLogo} alt="Erin Logo" />
								</div>
							)}
							<div className={title} style={{ paddingTop: 10 }}>
								{validJobData.subCompany
									? ` ${validJobData.subCompany}`
									: validJobData.company
										? ` ${validJobData.company}`
										: ''}{' '}
								{ml(
									`has invited you to apply for a position!`,
									currentUser,
									allMultiLingualData,
									'NewReferral'
								)}
							</div>
						</Col>
					</Row>
				</div>
				<div className={outerCardContainer}>
					<div className={cardContainer}>
						<Card
							headStyle={{ height: 100 }}
							className={CardStyles}
							bordered={false}
							style={{ boxShadow: 'none' }}
						>
							<div className={JobInfoContainer}>
								<div style={{ display: 'inline-block' }}>
									{validJobData.position ? (
										<h3 className={candidateNameStyles}>
											{this.state?.translatedTitle}
										</h3>
									) : null}

									<div className={detailsTitle}>
										{validJobData.department ? (
											<>
												<WebIcon name="folder" size={18} />
												<span className={Department}>
													{validJobData.department}
												</span>
											</>
										) : null}
										{validJobData.location ? (
											<>
												<WebIcon name="placeholder" size={18} />
												<span className={Department}>
													{validJobData.location}
												</span>
											</>
										) : null}
									</div>
								</div>
							</div>

							<div style={{ textAlign: 'center', paddingTop: '15px' }}>
								<Row type="flex" justify="center">
									<Col xs={23} m={24}>
										{validJobData.referrer ? (
											<p>
												{ml(
													'Referred By',
													currentUser,
													allMultiLingualData,
													'NewReferral'
												)}
												{': '}

												<a href={referrerMail} className={refererName}>
													{validJobData.referrer}
												</a>
											</p>
										) : null}
									</Col>
								</Row>
							</div>
							<div className={topSpacing}>
								{validJobData.jobType ? (
									<Row className={detailsTitle}>
										<span>
											<b>
												{ml(
													'Job Type',
													currentUser,
													allMultiLingualData,
													'NewReferral'
												)}
												:{' '}
											</b>
										</span>
										<span style={{ marginLeft: '4px' }}>
											{validJobData.jobType === 'fulltime'
												? 'Full-Time'
												: validJobData.jobType === 'parttime'
													? 'Part-Time'
													: upperFirst(validJobData.jobType)}
										</span>
									</Row>
								) : null}
								{validJobData.salary.includes('N/A') ? null : (
									<Row className={detailsTitle}>
										<span>
											<b>
												{ml(
													'Salary Range',
													currentUser,
													allMultiLingualData,
													'NewReferral'
												)}
												:{' '}
											</b>
										</span>
										<span>{validJobData.salary}</span>
									</Row>
								)}
							</div>
							{/* THIS IS WHERE THE LEFT ALIGNED TEXT STARTS */}

							<Row style={{ marginBottom: '15px', marginTop: '15px' }}>
								<span className={sectionTitle}>
									<b>
										{ml(
											'Job Responsibilities',
											currentUser,
											allMultiLingualData,
											'NewReferral'
										)}
										:{' '}
									</b>
								</span>
								<span className={summaryValue}>
									<StringParser
										showMoreText={ml(
											'Show More',
											currentUser,
											allMultiLingualData,
											'NewReferral'
										)}
										showLessText={ml(
											'Show Less',
											currentUser,
											allMultiLingualData,
											'NewReferral'
										)}
										text={this.state.translatedDescription}
										breakOn="3"
									/>
								</span>
							</Row>
							{jobData?.status === 'closed' ? (
								validJobData.isGeneralReferral ? null : (
									<div className="text-3xl pt-4 text-red-500 text-center mx-auto min-w-[280px] max-w-[50%] ">
										Thank you for your interest, but this job is no longer
										accepting new applications.
									</div>
								)
							) : this.state.referralComplete ? (
								<div>
									<Result
										status="success"
										title={
											ml(
												`Referral Accepted`,
												currentUser,
												allMultiLingualData,
												'NewReferral'
											) === undefined
												? `Referral Accepted!`
												: `${ml(
														`Referral Accepted`,
														currentUser,
														allMultiLingualData,
														'NewReferral'
													)}!`
										}
										subTitle={ml(
											`Your information has been successfully submitted.`,
											currentUser,
											allMultiLingualData,
											'NewReferral'
										)}
										extra={
											<div className={buttonSpacing}>
												{jobData && jobData.jobUrl ? (
													<Button
														style={{
															color: 'white',
															display: 'flex',
															textAlign: 'center',
														}}
														className={applyButton}
														size="large"
														href={jobData.jobUrl}
														target="_blank"
														rel="noopener noreferrer"
													>
														<WebIcon color="white" name="id" size={30} />
														&nbsp; View Job and Apply
													</Button>
												) : null}
												{!hideRecruiterInfo && (
													<Button
														className={contactButton}
														style={{
															display: 'flex',
															alignItems: 'center',
															padding: 0,
														}}
														size="large"
														href={mail}
													>
														<WebIcon
															color="white"
															name="email-outline"
															size={30}
														/>
														&nbsp;
														{ml(
															`Contact by Email`,
															currentUser,
															allMultiLingualData,
															'NewReferral'
														)}
													</Button>
												)}
											</div>
										}
									/>
								</div>
							) : (
								<div>
									<Form
										initialValues={{
											firstName: firstName ?? '',
											lastName: lastName ?? '',
											emailAddress: emailAddress ?? '',
										}}
										onFinish={this.handleSubmit}
										onFinishFailed={this.handleError}
									>
										<div style={{ height: 'auto', marginTop: '0px' }}>
											<p className={NoteStyle}>
												{ml(
													'To accept your referral and apply to the position, we need more information.',
													currentUser,
													allMultiLingualData,
													'NewReferral'
												)}
											</p>
										</div>
										<div className={DetailContainer}>
											<Row type="flex" justify="space-between" gutter={12}>
												<Col className="form-item-col" span={12}>
													<Form.Item
														className={FormItemStyles}
														name="firstName"
														rules={[
															{
																transform: (value) =>
																	value == undefined ? value : value.trim(),
															},
															{
																required: true,
																message: ml(
																	'Please enter your first name.',
																	currentUser,
																	allMultiLingualData,
																	'NewReferral'
																),
															},
														]}
													>
														<Input
															disabled
															className={RemoveBottomMargin}
															placeholder={ml(
																'First Name',
																currentUser,
																allMultiLingualData,
																'NewReferral'
															)}
														/>
													</Form.Item>
												</Col>
												<Col className="form-item-col" span={12}>
													<Form.Item
														className={FormItemStyles}
														name="lastName"
														rules={[
															{
																transform: (value) =>
																	value == undefined ? value : value.trim(),
															},
															{
																required: true,
																message: ml(
																	'Please enter your last name.',
																	currentUser,
																	allMultiLingualData,
																	'NewReferral'
																),
															},
														]}
													>
														<Input
															disabled
															className={RemoveBottomMargin}
															placeholder={ml(
																'Last Name',
																currentUser,
																allMultiLingualData,
																'NewReferral'
															)}
														/>
													</Form.Item>
												</Col>
												<Col className="form-item-col" span={24}>
													<Form.Item
														className={FormItemStyles}
														name="emailAddress"
														rules={[
															{
																type: 'email',
																message: ml(
																	'Enter a valid email.',
																	currentUser,
																	allMultiLingualData,
																	'NewReferral'
																),
															},
															{
																required: true,
																message: ml(
																	'Please enter an email address.',
																	currentUser,
																	allMultiLingualData,
																	'NewReferral'
																),
															},
														]}
													>
														<Input
															disabled
															className={RemoveBottomMargin}
															placeholder={ml(
																'Email',
																currentUser,
																allMultiLingualData,
																'NewReferral'
															)}
														/>
													</Form.Item>
												</Col>
											</Row>

											{isPhoneNumberEnabled && (
												<Row>
													<Col className="form-item-col" span={24}>
														<Form.Item
															className={FormItemStyles}
															name="phoneNumber"
															rules={[
																{
																	validator(rule, value) {
																		return new Promise((resolve, reject) => {
																			if (!value) {
																				return reject(
																					ml(
																						'Please enter a phone number.',
																						currentUser,
																						allMultiLingualData,
																						'NewReferral'
																					)
																				);
																			}

																			if (
																				!/^\+?\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,7}$/im.test(
																					filterPhoneNumber(value)
																				)
																			) {
																				return reject(
																					ml(
																						'Please enter a valid phone number.',
																						currentUser,
																						allMultiLingualData,
																						'NewReferral'
																					)
																				);
																			}

																			resolve();
																		});
																	},
																},
															]}
														>
															<PhoneInput
																inputStyle={{
																	border: '1px solid',
																	borderRadius: 4,
																	borderColor: 'rgba(217, 217, 217, 0.95)',
																	color: 'rgba(0, 0, 0, 0.65)',
																	font: '400 14px system-ui',
																	padding: '0px 0px 0px 50px',
																	height: 32,
																	width: '100%',
																}}
																dropdownStyle={{
																	lineHeight: 1,
																}}
																country={countryCode}
																masks={{ cn: '... .... ....' }}
															/>
														</Form.Item>
													</Col>
												</Row>
											)}

											{this.renderCustomFormElements(referralQuestions)}
											<div>
												{isResumeOptional ? (
													<div className="App" style={{ marginBottom: '30px' }}>
														<span className={fileWrap}>
															<label htmlFor={'file-' + uniqueId}>
																{ml(
																	'Click here',
																	currentUser,
																	allMultiLingualData,
																	'NewReferral'
																)}{' '}
															</label>
															<input
																ref={(ref) => {
																	this.uploadInput = ref;
																}}
																hidden
																type="file"
																accept=".doc,.docx,application/msword,.pdf,application/pdf"
																id={'file-' + uniqueId}
																name="file"
																onClick={() => {
																	this.setState({
																		filename: null,
																		filetype: null,
																		originalFile: [],
																	});
																	this.uploadInput = null;
																}}
																onChange={this.onFileInputChange}
															/>
														</span>{' '}
														{ml(
															'to attach a resume',
															currentUser,
															allMultiLingualData,
															'NewReferral'
														)}{' '}
														(
														{isResumeRequired
															? ml(
																	'required',
																	currentUser,
																	allMultiLingualData,
																	'NewReferral'
																)
															: ml(
																	'optional',
																	currentUser,
																	allMultiLingualData,
																	'NewReferral'
																)}
														){' '}
														{filename && (
															<p>
																{filename}
																<DeleteOutlined
																	style={{
																		margin: '0px 0px 4px 10px',
																		color: '#ef3c3e',
																	}}
																	onClick={this.onDelete}
																/>
															</p>
														)}
														<div style={{ marginTop: 10 }}>
															<div
																style={{
																	width: '100%',
																	marginLeft: 'auto',
																	marginRight: 'auto',
																	textAlign: 'center',
																}}
															>
																{this.state.errors[0] ? (
																	<Alert
																		type="error"
																		message={this.state.errors[0]}
																	/>
																) : null}
															</div>
														</div>
													</div>
												) : null}
											</div>
										</div>
										<div className={DetailContainer}>
											<ProgressButton
												controlled
												state={this.state.buttonState}
												type="submit"
												durationError={3000}
												durationSuccess={4500}
												onError={() => this.setState({ buttonState: '' })}
												onClick={() => this.setState({ isSubmitting: true })}
											>
												{ml(
													'Accept & Continue',
													currentUser,
													allMultiLingualData,
													'NewReferral'
												)}
											</ProgressButton>
										</div>
									</Form>
								</div>
							)}
						</Card>
					</div>
				</div>
				{!whiteLabel && (
					<div style={{ marginTop: 10 }} className={headerBackground}>
						<p className={sectionTitle}>powered by</p>
						<a href="https://erinapp.com/">
							<img alt="logo" style={{ width: 200 }} src={ErinLogo} />
						</a>
					</div>
				)}
			</section>
		);
	}
}

export default NewInternalReferralCard;
