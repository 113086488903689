import { connect } from 'react-redux';
import {
	withQueryBonusByCompanyId,
	withQueryMyBonusesByUserId,
} from 'src/_shared/api/components/bonuses/';
import { dashboardActions, manageJobsActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils';
import MyBonusesComponent from './MyBonusesComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const MyBonusesWithApi = compose(
	withQueryMyBonusesByUserId,
	withQueryBonusByCompanyId
)(MyBonusesComponent);

export const MyBonuses = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(MyBonusesWithApi);
