import { useState, useEffect } from 'react';
import { message } from 'antd';

import { useMyRewardsService } from '../service/MyRewards.service.jsx';
import { confirmedStatus } from '../presentation/actionItemCard/ActionItemCard.jsx';

const usePhysicalAward = () => {

    const [pendingPhysicalAward, setPendingPhysicalAward] = useState();
    const [isLoading, setLoader] = useState(false);

    const {
        getPendingPhysicalAwards,
        managePhysicalAwardConfirmationStatus,
    } = useMyRewardsService();

    const handleAddressError = (error, errorMessage = 'Failed to fetch address! Try again later.') => {
        message.error(error?.response?.data?.message || errorMessage);
    };

    const getPendingPhysicalAward = async () => {
        setLoader(true);
        try {
            const response = await getPendingPhysicalAwards();
            if (response?.code === 200 && response?.data) {
                setPendingPhysicalAward(response.data)
            }
        } catch (error) {
            handleAddressError(error);
        } finally {
            setLoader(false);
        }
    };

    const managePhysicalAwardsConfirmationStatus = async (status, awardId) => {
        setLoader(true);
        try {
            const response = await managePhysicalAwardConfirmationStatus({ id: awardId, confirmation: status });
            if (response?.code === 201) {
                if (confirmedStatus.Declined === status) {
                    message.warn("Award Declined...!!!")
                }
                getPendingPhysicalAward()
            }
        } catch (error) {
            handleAddressError(error);
        } finally {
            setLoader(false);
        }
    }

    useEffect(() => {
        getPendingPhysicalAward();
    }, []);

    return {
        isLoading,
        pendingPhysicalAward,
        managePhysicalAwardsConfirmationStatus
    };
};

export default usePhysicalAward;
