import { parse } from 'src/_shared/services/utils';

export const createAction =
	(type) =>
	(payload = {}) => ({ type, payload });

const progressTrackerTemplate = {
	hasAddedSkills: {
		ui: 'Add Skills',
		route: '/career',
		message: 'Add Skills to your Career Profile',
		value: false,
	},
	hasAddedExperience: {
		ui: 'Add Experience',
		route: '/career',
		message: 'Add Work History to your Career Profile',
		value: false,
	},
	isOpenToNewRoles: {
		ui: 'Open to New Roles',
		route: '/career',
		message: "Let us know you're open to a new role",
		value: false,
	},
	hasSelfReferred: {
		ui: 'Apply',
		route: '#recommended-jobs',
		message: 'Apply to an open position',
		value: false,
	},
};

/**
 * Determines career profile completion progress of current user
 * @param {User} currentUser the current user
 */
export const getUserCareerProfileCompletionProgress = (currentUser) => {
	if (!currentUser) return { ...progressTrackerTemplate };
	const {
		careerProfile = {},
		openToNewRole = false,
		selfReferrals = [],
	} = currentUser;

	let userProgressTracker = { ...progressTrackerTemplate };

	try {
		const parsedProfile = parse(careerProfile);

		if (parsedProfile?.skills?.length > 0) {
			userProgressTracker = {
				...userProgressTracker,
				hasAddedSkills: { ...userProgressTracker.hasAddedSkills, value: true },
			};
		}

		if (parsedProfile?.employment?.length > 0) {
			userProgressTracker = {
				...userProgressTracker,
				hasAddedExperience: {
					...userProgressTracker.hasAddedExperience,
					value: true,
				},
			};
		}

		if (openToNewRole) {
			userProgressTracker = {
				...userProgressTracker,
				isOpenToNewRoles: {
					...userProgressTracker.isOpenToNewRoles,
					value: true,
				},
			};
		}

		if (selfReferrals?.length > 0) {
			userProgressTracker = {
				...userProgressTracker,
				hasSelfReferred: {
					...userProgressTracker.hasSelfReferred,
					value: true,
				},
			};
		}

		return { ...userProgressTracker };
	} catch (error) {
		console.error(error);
		return { ...userProgressTracker };
	}
};

export const getCareerProfileCompletionPercent = (steps) => {
	// Determine percentComplete by dividing all entries with a true value by the total number of entries
	const progressRecords = Object.values(steps);

	const percentComplete = Math.round(
		(progressRecords.filter((record) => record.value === true)?.length /
			progressRecords?.length) *
			100
	);

	return percentComplete;
};

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
	condition ? wrapper(children) : children;
