import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Row, message } from 'antd';
import gql from 'graphql-tag';
import _ from 'lodash';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactDOM from 'react-dom';
import ProgressButton from 'react-progress-button';
import { updateContact } from 'src/_shared/api/graphql/custom/contacts/';
import { GetJob } from 'src/_shared/api/graphql/custom/jobs/';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import { ml, parse, uploadToS3Multipart } from 'src/_shared/services/utils';
import FormElements from 'src/form-builder/FormElements.jsx';
import uuid from 'uuid/v4';
import queryReferralsByUserIdReferralTypeIndex from '../../../my-applications/listReferralsByUserIdReferralTypeGraphql.js';
import * as ModalStyles from './imInterestedSendStyles.js';
import { checkCircle, linkStyles } from './imInterestedSendStyles.js';

class ImInterestedButtonWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			visibleApplied: false,
			isSubmitting: false,
			showCompleteModal: false,
			newContact: get(props, 'newContact', false),
			contactId: get(props, 'currentUser.id'),
			buttonState: '',
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			referralQuestions: [],
			resumeAttachData: '',
			interestedQuestionData: '',
			isError: false,
			filename: null,
			filetype: null,
			errors: [],
			originalFile: [],
			showAppliedModal: false,
			careerProfile: JSON.parse(get(props, 'currentUser.careerProfile', '{}')),
			selfReferrals: null,
			isAlreadyComplete: false,
			uniqueId: uuid(),
		};
	}

	async componentDidMount() {
		if (this.getCompletionPercentage() == '100%') {
			this.setState({ isAlreadyComplete: true });
		}
	}

	onDelete = () => {
		this.setState({ originalFile: [] });
		this.setState({ filename: null });
		this.setState({ filetype: null });
		this.setState({ errors: [] });
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const errors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files! ');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must smaller than 2MB!');
			}

			if (errors.length > 0) {
				this.setState({ filename: e.target.files[0].name });
				this.setState({ errors });
				return errors;
			}

			this.setState({
				filename: e.target.files[0].name,
				filetype: e.target.files[0].type,
				originalFile: e.target.files[0],
			});

			this.setState({ errors: [] });
			return isDocOrDocsOrPdf && isLt2M;
		}
	};

	onPhoneNumberChange = () => {};
	getInputElement(item) {
		const Input = FormElements[item.element];
		return (
			item.text != 'Phone Number' && (
				<div key={`${item.id}`}>
					<Input
						ref={(c) => (this.inputs[item.field_name] = c)}
						key={`from_${item.id}`}
						mutable
						handleChange={this.handleChange}
						data={item}
						read_only={this.props.read_only}
					/>
				</div>
			)
		);
	}

	async getReferralQuestions(
		policy = 'network-only',
		nextToken = null,
		allQuestions = []
	) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryReferralQuestionsByCompanyId),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const questions = [...data.queryReferralQuestionsByCompanyId.items];
			const token = get(data, 'queryJobsByCompanyIdDateIndex.nextToken', null);
			allQuestions = [...allQuestions, ...questions];
			let allQuestionsSort = _.sortBy(allQuestions, ['sortOrder']);
			const attachmentResumeQuestion = allQuestionsSort.filter(
				(item) => item.sortOrder === 0
			);
			const interestedQuestion = allQuestionsSort.filter(
				(item) => item.sortOrder === -1
			);
			allQuestionsSort = allQuestionsSort.filter(
				(item) => item.sortOrder !== 0 && item.sortOrder !== -1
			);
			const allQuestionsForInterested = allQuestionsSort.filter(
				(item) => item.isInterested === true
			);
			const questionsData = [];
			if (allQuestionsForInterested.length > 0) {
				allQuestionsForInterested.map((element) => {
					questionsData.push(JSON.parse(element.questions));
				});
			}

			this.setState({
				referralQuestions: questionsData,
				resumeAttachData: attachmentResumeQuestion,
				interestedQuestionData: interestedQuestion,
			});
			if (token) {
				this.getReferralQuestions(policy, token, allQuestions);
			}
		} catch (error) {
			console.log(error);
		}
	}

	getSimpleElement(item) {
		const Element = FormElements[item.element];
		return <Element key={`${item.id}`} mutable data={item} />;
	}

	_collect(item) {
		const errors = [];
		const itemData = { name: item.field_name };
		const ref = this.inputs[item.field_name];
		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			for (const option of item.options) {
				const $option = ReactDOM.findDOMNode(
					ref.options[`child_ref_${option.key}`]
				);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			}

			itemData.value = checked_options;
		} else {
			if (!ref) return null;
			itemData.value = this._getItemValue(item, ref).value;

			itemData.question = item.label;
			itemData.text =
				this._getItemValue(item, ref).text === undefined
					? ''
					: this._getItemValue(item, ref).text;
			if (itemData.value === '' && itemData.text === '') {
				errors.push(`${item.label} is required.`);
			}
		}

		const object = {
			itemData,
			errors,
		};
		return object;
	}

	_collectFormData(data) {
		const formData = [];
		let errors = '';
		for (const item of data) {
			const item_data =
				this._collect(item) === null ? null : this._collect(item).itemData;
			errors += this._collect(item) === null ? '' : this._collect(item).errors;
			if (item_data) {
				formData.push(item_data);
			}
		}

		const object = {
			formData,
			errors,
		};
		return object;
	}

	_getItemValue(item, ref) {
		let $item = {
			element: item.element,
			value: '',
			text: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;

				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;

				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;

				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';

				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
						if (item.element === 'Dropdown') {
							$item.text = $item.selectedOptions[0].innerText;
						}
					}
				}
			}
		}

		return $item;
	}

	checkIsAlreadyReferredToJob = async () => {
		try {
			const { client, currentUser, job } = this.props;
			const { data } = await client.query({
				query: GetJob,
				variables: {
					id: job.id,
				},
				fetchPolicy: 'network-only',
			});
			const Job = get(data, 'getJob', []);
			const isAlreadyReferredToJob = get(Job, 'referrals', []).find(
				(referral) =>
					get(referral, 'contact', false) &&
					get(referral, 'contact.emailAddress', false) ===
						get(currentUser, 'emailAddress', false)
			);
			return Boolean(isAlreadyReferredToJob);
		} catch (error) {
			console.log(error);
		}
	};

	getCompletionPercentage = (value) => {
		let percentage = 0;

		if (this.state?.selfReferrals?.length > 0 || value == 'submitting') {
			percentage += 25;
		}

		if (this.state.careerProfile?.skills?.length) {
			percentage += 25;
		}

		if (this.state.careerProfile?.employment?.length) {
			percentage += 25;
		}

		if (this.props.currentUser?.openToNewRole) {
			percentage += 25;
		}

		return `${percentage}%`;
	};

	async getReferralsOfCurrentUser(
		policy = 'network-only',
		nextToken = null,
		selfReferrals = []
	) {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: queryReferralsByUserIdReferralTypeIndex,
					variables: {
						userId,
						referralType: 'self',
						nextToken,
					},
					fetchPolicy: policy,
				});
				const referrals = [
					...data.queryReferralsByUserIdReferralTypeIndex.items,
				];
				const token = get(
					data,
					'queryReferralsByUserIdReferralTypeIndex.nextToken',
					null
				);
				selfReferrals = [...selfReferrals, ...referrals];
				let allSelfReferrals = [];

				if (token) {
					await this.getReferralsOfCurrentUser(policy, token, selfReferrals);
				}

				allSelfReferrals = _.sortBy(selfReferrals, ['referralDate']).reverse();
				this.setState({
					selfReferrals: allSelfReferrals.filter((referral) => referral.job),
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	handleCancel = () => {
		this.setState({
			visible: false,
			visibleApplied: false,
			showCompleteModal: false,
			buttonState: '',
			originalFile: [],
			filename: null,
			filetype: null,
			errors: [],
		});
	};

	handleSubmit = async (isResumeRequired = false) => {
		const { job, onCreateReferral, currentUser, internalJobLink } = this.props;
		const { filename, originalFile, referralQuestions } = this.state;
		const percentage = this.getCompletionPercentage('submitting');
		try {
			if (
				internalJobLink !== null &&
				internalJobLink !== undefined &&
				!referralQuestions
			) {
				this.setState({
					showCompleteModal: false,
					visible: false,
					buttonState: 'success',
				});
				this.redirectToInternalJob(internalJobLink);
			} else {
				const matchingContact = this.props.contacts.find((contact) => {
					if (contact.emailAddress) {
						return contact.emailAddress.toLowerCase() ===
							currentUser.emailAddress.toLowerCase();
					}
				});
				const contactExists = !!matchingContact;
				const contactId = matchingContact ? matchingContact.id : null;
				const interestedQuestionsData =
					this._collectFormData(referralQuestions).formData;
				const isEmptySelect = interestedQuestionsData.some(
					(item) =>
						item.name.includes('dropdown') &&
						item.text === 'Select' &&
						item.value === '0'
				);

				if (await this.checkIsAlreadyReferredToJob()) {
					if (internalJobLink !== null && internalJobLink !== undefined) {
						this.setState({
							visible: false,
							visibleApplied: false,
							showCompleteModal: false,
							buttonState: 'success',
							originalFile: [],
							filename: null,
							filetype: null,
							errors: [],
						});
						this.redirectToInternalJob(internalJobLink);
					} else {
						this.setState({
							visible: false,
							visibleApplied: false,
							showCompleteModal: false,
							buttonState: 'success',
							originalFile: [],
							filename: null,
							filetype: null,
							errors: [],
						});
					}
				} else {
					if (contactExists) {
						const quesErrors = this._collectFormData(referralQuestions).errors;
						this.setState({ buttonState: '' });
						if (quesErrors.length > 0 || isEmptySelect) {
							message.error('Please check all the required fields', 5);
							this.setState({ buttonState: 'error' });
							return;
						}

						if (isResumeRequired && !filename) {
							message.error('Please upload your resume.', 5);
							this.setState({ buttonState: 'error' });
							return;
						}

						this.toggleIsSubmitting();
						this.showAnimation().then(async () => {
							await onCreateReferral({
								input: {
									companyId: currentUser.companyId,
									contactId,
									userId: currentUser.id,
									jobId: job.id,
									status: 'accepted',
									note: null,
									message: null,
									referralSource: job?.isGeneralReferral
										? 'general'
										: 'direct',
									referralType: 'self',
									interestedQuestionsData: JSON.stringify(
										interestedQuestionsData
									),
								},
							});
							mixpanel.track('Internal Application Submitted', {
								'IM Profile Completion': percentage,
							});
							if (percentage == '100%' && !this.state.isAlreadyComplete) {
								mixpanel.track('IM Profile Completed');
								this.setState({ isAlreadyComplete: true });
							}

							if (internalJobLink !== null && internalJobLink !== undefined) {
								this.setState({
									showCompleteModal: false,
									visible: false,
									buttonState: '',
								});
							} else {
								this.setState({
									visible: false,
									buttonState: '',
								});
							}
						});
					} else {
						const quesErrors = this._collectFormData(referralQuestions).errors;
						this.setState({ buttonState: '' });
						if (quesErrors.length > 0 || isEmptySelect) {
							message.error('Please check all the required fields', 5);
							this.setState({ buttonState: 'error' });
							return;
						}

						if (isResumeRequired && !filename) {
							message.error('Please upload your resume.', 5);
							this.setState({ buttonState: 'error' });
							return;
						}

						this.toggleIsSubmitting();
						this.showAnimation().then(() => {
							this.props
								.ImportedCreateContact({
									input: {
										firstName: currentUser.firstName,
										lastName: currentUser.lastName,
										emailAddress: get(
											currentUser,
											'emailAddress',
											''
										).toLowerCase(),
										userId: currentUser.id,
										companyId: currentUser.companyId,
										importMethod: 'email',
									},
								})
								.then(async (response) => {
									const contactId = get(response, 'data.createContact.id');
									const d = new Date();
									const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
									if (filename) {
										const contactResume = {
											bucket: 'erin-documents',
											key: `resumes/${contactId}/${dformat + '-' + filename}`,
											region: 'us-east-2',
										};
										await uploadToS3Multipart(
											originalFile,
											contactResume.key,
											contactResume.bucket
										);
										await this.updateExistingContact({
											id: contactId,
											contactResume,
										});
									}

									if (
										internalJobLink !== null &&
										internalJobLink !== undefined
									) {
										this.setState({
											showCompleteModal: false,
											visible: false,
											buttonState: '',
										});
									} else {
										this.setState({
											visible: false,
											buttonState: '',
										});
									}

									await onCreateReferral({
										input: {
											companyId: currentUser.companyId,
											contactId,
											userId: currentUser.id,
											jobId: job.id,
											status: 'accepted',
											note: null,
											message: null,
											referralType: 'self',
											interestedQuestionsData: JSON.stringify(
												interestedQuestionsData
											),
										},
									});
									mixpanel.track('Internal Application Submitted', {
										'IM Profile Completion': percentage,
									});
									if (percentage == '100%' && !this.state.isAlreadyComplete) {
										mixpanel.track('IM Profile Completed');
										this.setState({ isAlreadyComplete: true });
									}
								});
						});
					}

					if (internalJobLink !== null && internalJobLink !== undefined) {
						this.setState({
							visible: false,
							visibleApplied: false,
							showCompleteModal: false,
							buttonState: 'success',
							originalFile: [],
							filename: null,
							filetype: null,
							errors: [],
						});
						this.redirectToInternalJob(internalJobLink);
					}
				}
			}
		} catch {}
	};

	inputs = {};

	redirectToInternalJob = (internalJobLink) => {
		window.open(internalJobLink, '_blank');
	};

	showAnimation = () => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					this.toggleIsSubmitting();
					this.handleCancel();
					resolve();
				} catch (error) {
					reject(error);
				}
			}, 2500);
		});
	};

	showModal = async () => {
		await this.getReferralsOfCurrentUser('');
		await this.getReferralQuestions();
		if ((await this.checkIsAlreadyReferredToJob()) || this.state.submitted) {
			this.setState({
				buttonState: '',
				visibleApplied: true,
			});
		} else {
			setTimeout(() => {
				this.setState({
					visible: true,
				});
			}, 250);
		}
	};

	toggleIsSubmitting = () => {
		this.setState({ buttonState: 'loading' });
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
		setTimeout(() => {
			this.setState({ buttonState: 'success' });
		}, 2000);
	};

	updateExistingContact = async (contact) => {
		await this.props.client.mutate({
			mutation: gql(updateContact),
			variables: { input: contact },
		});
	};

	render() {
		const {
			visible,
			visibleApplied,
			showCompleteModal,
			referralQuestions,
			resumeAttachData,
			filename,
			uniqueId,
		} = this.state;
		const { allMultiLingualData, currentUser, internalJobLink, job } =
			this.props;
		const companyId = get(job, 'company.id');
		let modalTxt = '';
		let applyText = '';
		if (internalJobLink !== null && internalJobLink !== undefined) {
			modalTxt =
				'You will be directed to our job site to apply for this position.';
			applyText = 'Apply On Job Site';
		} else {
			modalTxt =
				'Let us know that you are interested in this position and we will be in touch.';
			applyText = 'Submit Me For This Position';
		}

		const toggleClass = ModalStyles.ModalStyles;
		const disabled = get(this.props, 'disabled', false);
		const resumeData = {};
		let isResumeOptional = false;
		let isResumeRequired = false;
		let isUserEmail = false;
		if (resumeAttachData.length > 0) {
			const interestedFormResumeData = resumeAttachData.filter(
				(item) => item.isInterested === true && item.sortOrder === 0
			);
			if (interestedFormResumeData.length > 0) {
				Object.assign(resumeData, ...interestedFormResumeData);
				const resumeAttachmentData = JSON.parse(get(resumeData, 'questions'));
				for (const key in resumeAttachmentData) {
					if (resumeAttachmentData.hasOwnProperty(key)) {
						switch (key) {
							case 'Resume required': {
								isResumeRequired = resumeAttachmentData[key];

								break;
							}

							case 'Ask for resume attachment': {
								isResumeOptional = resumeAttachmentData[key];

								break;
							}

							case "Show User's Email Address": {
								isUserEmail = resumeAttachmentData[key];

								break;
							}
							// No default
						}
					}
				}
			}
		}

		const data_items = referralQuestions;
		let customItems = '';
		if (data_items.length > 0) {
			customItems = data_items.map((item) => {
				if (!item) return null;
				switch (item.element) {
					case 'TextInput':
					case 'NumberInput':
					case 'TextArea':
					case 'Dropdown':
					case 'DatePicker':
					case 'RadioButtons':
					case 'Rating':
					case 'Tags':
					case 'Range': {
						return this.getInputElement(item);
					}

					default: {
						return this.getSimpleElement(item);
					}
				}
			});
		}

		const jobTitle = get(job, 'title');
		const location = get(job, 'job.location', get(job, 'location'));

		let locations = parse(get(job, 'job.locations', get(job, 'locations', [])));
		locations = locations ? locations : [];
		const department = get(job, 'department');
		return (
			<>
				<Button
					block
					ghost
					disabled={disabled}
					type="primary"
					size="large"
					onClick={() => {
						this.showModal();
					}}
				>
					<i className=" icon-heart" />
					{disabled
						? ml(
								`I'm Interested Not Available`,
								currentUser,
								allMultiLingualData
							)
						: ml(`I'm Interested`, currentUser, allMultiLingualData)}
				</Button>

				<Modal
					centered
					open={visible}
					footer={null}
					width={600}
					title={ml('Interested?', currentUser, allMultiLingualData)}
					onCancel={this.handleCancel}
					destroyOnClose={true}
				>
					<p className="text-center">
						{ml(modalTxt, currentUser, allMultiLingualData)}
					</p>

					<div className="modal-job-info-wrap">
						<div className="mj-info-wrap">
							<h3 className="mj-info-name">{jobTitle}</h3>
						</div>
						<ul className="mj-info">
							<li>
								{location?.city ||
								location?.state ||
								location?.country ||
								location?.isRemote === true ? (
									<i className="icon-location" />
								) : null}
								<span>
									{locations?.length > 1
										? ml(
												'Multiple Locations',
												currentUser,
												allMultiLingualData
											)
										: location?.isRemote === true
											? ml('Remote', currentUser, allMultiLingualData)
											: [location?.city, location?.state]
													.filter(Boolean)
													.join(', ') ||
												location?.country ||
												''}
								</span>
							</li>
							{department && (
								<li>
									<i className="icon-folder" />
									<span>{department.name}</span>
								</li>
							)}
						</ul>
						{/* If company is ZT systems */}
						{companyId === 'e600195e-d1d9-4163-8b22-f0f250c4bcfd' &&
							get(job, 'displayReqId') !== true &&
							get(job, 'displayReqId') !== '' &&
							get(job, 'displayReqId') && (
								<p className="mj-info-text mb-0">
									ID#:
									<span>{get(job, 'displayReqId')}</span>
								</p>
							)}
						{companyId !== 'e600195e-d1d9-4163-8b22-f0f250c4bcfd' &&
							get(job, 'externalJobId') !== true &&
							get(job, 'externalJobId') !== '' &&
							get(job, 'externalJobId') && (
								<p className="mj-info-text mb-0">
									ID#:
									<span>{get(job, 'externalJobId')}</span>
								</p>
							)}
					</div>
					<>{customItems}</>
					{isResumeOptional ? (
						<>
							<p className="upload-link-text">
								<label
									className="upload-link"
									htmlFor={'uploadFile-' + uniqueId}
								>
									Click here
									<input
										ref={(ref) => {
											this.uploadInput = ref;
										}}
										hidden
										type="file"
										accept=".doc,.docx,application/msword,.pdf,application/pdf"
										id={'uploadFile-' + uniqueId}
										name="file"
										onClick={() => {
											this.setState({
												filename: null,
												filetype: null,
												originalFile: [],
											});
											this.uploadInput = null;
										}}
										onChange={this.onFileInputChange}
									/>
								</label>
								to attach a resume
								<span className="label-optional">
									({isResumeRequired ? 'required' : 'optional'})
								</span>
							</p>
							{filename && (
								<div className="upload-file-text">
									<p>
										{filename}
										<i
											className="icon-bin text-danger cursor-p"
											onClick={this.onDelete}
										/>
									</p>
								</div>
							)}
						</>
					) : null}
					{isUserEmail ? (
						<p className="email-withlabel">
							<span>You must apply using this email address:</span>
							{get(currentUser, 'emailAddress')}
						</p>
					) : (
						''
					)}
					<div className="modal-footer-btn">
						<ProgressButton
							durationSuccess={1500}
							state={this.state.buttonState}
							onClick={() => {
								this.handleSubmit(isResumeRequired);
							}}
						>
							{ml(applyText, currentUser, allMultiLingualData)}
						</ProgressButton>
					</div>
				</Modal>
				<Modal
					className={toggleClass}
					open={showCompleteModal}
					footer={null}
					width={620}
					onCancel={this.handleCancel}
					destroyOnClose={true}
				>
					<div>
						<Row style={{ textAlign: 'center' }}>
							<CheckCircleOutlined className={checkCircle} />
						</Row>
						<p className={ModalStyles.ModalText}>
							{ml(
								'Success. Your information has been submitted for this position.',
								currentUser,
								allMultiLingualData
							)}
						</p>

						<div className={ModalStyles.SubmitBtnContainer}>
							<Row style={{ marginTop: 8 }}>
								<span style={{ fontSize: 18 }}>
									<a
										className={linkStyles}
										onClick={() => {
											this.setState({ showCompleteModal: false });
										}}
									>
										{ml('Close This Window', currentUser, allMultiLingualData)}
									</a>
								</span>
							</Row>
						</div>
					</div>
				</Modal>
				<Modal
					centered
					open={visibleApplied}
					footer={null}
					width={720}
					title={ml("You've Applied!", currentUser, allMultiLingualData)}
					onCancel={this.handleCancel}
					destroyOnClose={true}
				>
					<>
						<p className="small text-center">
							{ml(
								"You've already applied to this position. Check My Referrals to track your status.",
								currentUser,
								allMultiLingualData
							)}
						</p>

						<div className="modal-footer-btn">
							<ProgressButton
								durationSuccess={1500}
								state={this.state.buttonState}
								onClick={() => {
									this.handleSubmit(isResumeRequired);
								}}
							>
								{ml('Got It', currentUser, allMultiLingualData)}
							</ProgressButton>
						</div>
					</>
				</Modal>
			</>
		);
	}
}

export default withApollo(ImInterestedButtonWrapper);
