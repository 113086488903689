import get from 'lodash/get';
import { Component } from 'react';
import { ml } from 'src/_shared/services/utils';
import { StringParser } from '../../../_shared/services';
import { AddJobsFooter } from '../footer';
import { notificationTypes } from './copy.js';

const normalizeDollar = (value) => {
	return value
		? value.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
		: null;
};

export class AddJobPageFour extends Component {
	constructor(props) {
		super(props);
		this.state = {
			theme: get(props, 'theme', {}),
		};
	}

	footerButtons = (page) => {
		const { allMultiLingualData, currentUser } = this.props;
		const { theme } = this.state;
		return (
			<AddJobsFooter
				allMultiLingualData={allMultiLingualData}
				currentUser={currentUser}
				isNew={this.props.isNew}
				page={page}
				theme={theme}
				handleNext={() => {}}
				handleBack={() => {
					this.props.handleBack(page);
				}}
				handleSubmit={() => {
					this.props.handleSubmit();
				}}
				buttonState={this.props.buttonState}
			/>
		);
	};

	safeSalary = (min, max) => {
		if (!min && !max) return null;
		if (!min || min === max) return `$${normalizeDollar(max)}`;
		if (!max) return `$${normalizeDollar(min)}`;
		return `$${normalizeDollar(min)} - $${normalizeDollar(max)}`;
	};

	render() {
		const {
			jobType,
			title,
			description,
			hiringManager,
			subCompanyId,
			location,
			salary,
			notificationType,
			department,
			publicLink,
			internalJobLink,
			externalJobId,
		} = this.props.currentJob;
		const { allMultiLingualData, currentUser, subCompanies } = this.props;

		const referralBonus =
			typeof get(this.props, 'currentJob.referralBonus') === 'string'
				? JSON.parse(get(this.props, 'currentJob.referralBonus'))
				: get(this.props, 'currentJob.referralBonus');
		const bonuses = get(this.props, 'tieredBonuses', []);
		const _salary = this.safeSalary(salary.from, salary.to);
		const footer = this.footerButtons(this.props.page);
		const foundBonus = bonuses.find(
			(b) => b.id === referralBonus.tieredBonusId
		);
		const tieredBonusName = get(foundBonus, 'name');

		const JobTypes = {
			fulltime: ml('Full-time', currentUser, allMultiLingualData),
			parttime: ml('Part-time', currentUser, allMultiLingualData),
			contract: ml('Contract', currentUser, allMultiLingualData),
			internship: ml('Internship', currentUser, allMultiLingualData),
			commission: ml('Commission', currentUser, allMultiLingualData),
		};

		const subCompany = subCompanies.find(
			(subCompany) => subCompany.id === subCompanyId
		);
		return (
			<>
				<h5 className="text-center">
					{ml(
						'Almost There. How does everything look?',
						currentUser,
						allMultiLingualData
					)}
				</h5>

				<ul className="label-data">
					<li>
						<label>{ml('Job Type', currentUser, allMultiLingualData)}:</label>
						{JobTypes[jobType]}
					</li>
					<li>
						<label>{ml('Salary', currentUser, allMultiLingualData)}:</label>
						{_salary ? _salary : ''}{' '}
						{salary && _salary && salary.interval ? salary.interval : null}
					</li>
					{!location.isRemote && (
						<li>
							<label>{ml('Location', currentUser, allMultiLingualData)}:</label>
							{location.city && (
								<>
									{location.city}, {location.state}
								</>
							)}
						</li>
					)}
					{location.isRemote && (
						<li>
							<label>{ml('Location', currentUser, allMultiLingualData)}:</label>
							{ml('Remote', currentUser, allMultiLingualData)}
						</li>
					)}
					<li>
						<label>{ml('Job Title', currentUser, allMultiLingualData)}:</label>
						{title}
					</li>
					<li>
						<label>{ml('Department', currentUser, allMultiLingualData)}:</label>
						{department.name}
					</li>
					<li>
						<label className="label-block">
							{ml('Job Description', currentUser, allMultiLingualData)}:{' '}
						</label>
						<StringParser
							text={description ? description.toString('html') : ''}
							breakOn="5"
						/>
					</li>
					<li>
						<label className="label-block">
							{ml('Req Id', currentUser, allMultiLingualData)}:{' '}
						</label>
						{externalJobId || ''}
					</li>
					<li>
						<label className="label-block">
							{ml('Public Job Posting Link', currentUser, allMultiLingualData)}:
						</label>
						{publicLink || ''}
					</li>
					<li>
						<label className="label-block">
							{ml('Internal Job Link', currentUser, allMultiLingualData)}:
						</label>
						{internalJobLink || ''}
					</li>
					<li>
						<label>{ml('Recruiter', currentUser, allMultiLingualData)}:</label>
						{hiringManager ? hiringManager.name : null}
					</li>
					<li>
						<label>{ml('Subcompany', currentUser, allMultiLingualData)}:</label>
						{subCompany ? subCompany.name : null}
					</li>
					<li>
						<label>
							{ml('Referral Bonus', currentUser, allMultiLingualData)}:
						</label>
						{tieredBonusName}
						<span className="text-green">
							{get(referralBonus, 'hasBonus') && get(referralBonus, 'amount')
								? `$${get(referralBonus, 'amount')}`.replaceAll(
										/\B(?=(\d{3})+(?!\d))/g,
										','
									)
								: null}
						</span>
					</li>
					<li>
						<label>
							{' '}
							{ml('Notification', currentUser, allMultiLingualData)}:{' '}
						</label>
						{notificationTypes[notificationType]}
					</li>
				</ul>

				{footer}
			</>
		);
	}
}
