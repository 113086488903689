import { connect } from 'react-redux';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import { withListTieredBonus } from 'src/_shared/api/components/tiered-bonuses/';
import { withListUserGroup } from 'src/_shared/api/components/userGroups/';
import {
	dashboardActions,
	manageJobsActions,
	userActions,
} from 'src/actions.js';
import { compose } from '../_shared/services/utils';
import SettingsComponent from './SettingsComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		currentJob: state.manageJobs.currentJob,
		id: currentUser.companyId,
		filter: { companyId: { eq: currentUser.companyId } },
		limit: 5000,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateCurrentUserCompany(company) {
			dispatch(userActions.updateUserCompany(company));
		},
		fetchJobs() {
			dispatch(manageJobsActions.fetchJobs());
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const SettingsWithApi = compose(
	withGetCompany,
	withListDepartment,
	withListUserGroup,
	withListTieredBonus
)(SettingsComponent);

export const Settings = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(SettingsWithApi);
