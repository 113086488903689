import { Select } from 'antd';
import _, { get } from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map';
import { ml } from '../_shared/services/utils';
import { ToggleOpenClosedNew } from '../_shared/components/toggleOpenClosedNew';

export function AdvanceFilters(props) {
	const {
		allMultiLingualData,
		currentUser,
		filterOpenStatus,
		jobFilterSettings,
		handleFilterOpenStatus,
		handleFilterSubCompany,
		handleRemoveSubCompanyFilter,
		handleFilterCompany,
		handleRemoveCompanyFilter,
		handleFilterJobLevel,
		handleRemoveJobLevelFilter,
		handleFilterAvailable,
		handleRemoveAvailableFilter,
		handleFilterBonus,
		handleRemoveBonusFilter,
		handleFilterDepartment,
		handleRemoveDepartmentFilter,
		handleFilterSalary,
		handleFilterDate,
		renderArrowIcon,
		isExtendedUser,
		subCompanyLabel,
		allowSelfReferralsInternalLink,
		filteredAvailables,
		filteredJobLevels,
		filteredDate,
		filteredDepartments,
		filteredBonuses,
		filteredSalary,
		filteredSubCompanies,
		hideBonusFilter,
		jobLevels = [],
		subCompanies = [],
		bonuses = [],
		departments = [],
		text,
	} = props;

	const filterOpenKey = 'open';
	const filterClosedKey = 'closed';

	const jobLevs = _.sortBy(jobLevels, ['name']);
	const filteredJobLevelIds = filteredJobLevels.map((level) => level.id);
	const jobLevelOptions = jobLevs.map((jobLevel) => {
		return (
			<Select.Option
				key={get(jobLevel, 'id')}
				value={get(jobLevel, 'id')}
				jobLevel={jobLevel}
			>
				{get(jobLevel, 'name')}
			</Select.Option>
		);
	});
	const extendedCompaniesData = get(currentUser, 'extendedCompaniesData', []);
	const extendedCompanies = extendedCompaniesData.filter(
		(company) => get(company, 'disableExtendedUserJobsView') !== true
	);
	const companies =
		extendedCompanies && get(currentUser, 'role') === 'extendedUser'
			? extendedCompanies
			: [get(currentUser, 'company')];
	const comps = _.sortBy(companies, ['name']);
	const companyOptions = comps.map((company) => {
		return (
			<Select.Option key={get(company, 'id')} value={get(company, 'id')}>
				{get(company, 'name')}
			</Select.Option>
		);
	});
	const subComps = _.sortBy(subCompanies, ['name']);
	const subCompanyOptions = subComps.map((subCompany) => {
		return (
			<Select.Option key={get(subCompany, 'id')} value={get(subCompany, 'id')}>
				{get(subCompany, 'name')}
			</Select.Option>
		);
	});
	const sortedDepartments = _.sortBy(departments, ['name']).filter(
		(department) => department.active
	);
	const options = sortedDepartments.map((department) => {
		return (
			<Select.Option key={department.id} value={department.id}>
				{department.name}
			</Select.Option>
		);
	});
	// Getting the total of bonus tiers for employees, and returning that sorted in options
	const bonusOptions = bonuses
		.map((bonus) => {
			const total = bonus.tiers
				.map((tierJSON) => JSON.parse(tierJSON))
				.filter((tier) => tier.recipientType === 'employee')
				.filter((tier) => tier.userGroup === currentUser.userGroupId)
				.reduce((sum, tier) => {
					return sum + Number.parseInt(tier.amount);
				}, 0);
			return { id: bonus.id, total };
		})
		.sort((a, b) => a.total - b.total)
		.map(({ id, total }) => {
			return (
				<Select.Option key={id} value={id}>
					{` ${getSymbolFromCurrency(
						get(currentUser, 'userGroup.currency', 'USD')
					)}${new Intl.NumberFormat().format(total)}`}
				</Select.Option>
			);
		});

	const lblDepartment = get(
		currentUser,
		'company.labelDepartment',
		'Department'
	);
	let filterbyDeptText = lblDepartment ? lblDepartment : 'Department';

	const availableOptions = [
		{ id: 'internal-only', name: "I'm Interested" },
		{ id: 'refer-exists', name: 'Refer Someone' },
		{ id: 'referral-and-internal', name: "All (Refer / I'm Interested)" },
	].map((company) => {
		return (
			<Select.Option key={get(company, 'id')} value={get(company, 'id')}>
				{get(company, 'name')}
			</Select.Option>
		);
	});

	return (
		<div className="custom-filter">
			<div className="filter-wrap">
				{!isExtendedUser && !jobFilterSettings.closedJobs?.hidden && (
					<ToggleOpenClosedNew
						currentUser={currentUser}
						allMultiLingualData={allMultiLingualData}
						filterOpenStatus={filterOpenStatus}
						openJobsKey={filterOpenKey}
						closedJobsKey={filterClosedKey}
						text={text}
						openJobs={ml('Open Jobs', currentUser, allMultiLingualData)}
						closedJobs={ml('Closed Jobs', currentUser, allMultiLingualData)}
						onClick={handleFilterOpenStatus}
					/>
				)}
				{subCompanyOptions && subCompanyOptions.length > 0 && (
					<Select
						showArrow
						filterOption={(input, option) => {
							return option.props.children
								.toLowerCase()
								.includes(input.toLowerCase());
						}}
						mode="multiple"
						value={get(props, 'filteredSubCompanies', [])}
						placeholder={ml(
							`${subCompanyLabel}`,
							currentUser,
							allMultiLingualData
						)}
						maxTagCount={1}
						maxTagTextLength={15}
						suffixIcon={renderArrowIcon(filteredSubCompanies.length)}
						onSelect={handleFilterSubCompany}
						onDeselect={(subCompany) =>
							handleRemoveSubCompanyFilter(subCompany)
						}
					>
						{subCompanyOptions}
					</Select>
				)}
				{isExtendedUser && companyOptions && companyOptions.length > 1 && (
					<Select
						filterOption={(input, option) => {
							return option.props.children
								.toLowerCase()
								.includes(input.toLowerCase());
						}}
						mode="multiple"
						placeholder={ml(
							`${subCompanyLabel}`,
							currentUser,
							allMultiLingualData
						)}
						onSelect={(company, { key }) => handleFilterCompany(company, key)}
						onDeselect={(company, { key }) =>
							handleRemoveCompanyFilter(company, key)
						}
					>
						{companyOptions}
					</Select>
				)}
				{jobLevelOptions && jobLevelOptions.length > 0 && (
					<Select
						showArrow
						filterOption={(input, option) => {
							return option.props.children
								.toLowerCase()
								.includes(input.toLowerCase());
						}}
						mode="multiple"
						value={filteredJobLevelIds}
						placeholder={ml('Job Level', currentUser, allMultiLingualData)}
						maxTagCount={1}
						maxTagTextLength={15}
						suffixIcon={renderArrowIcon(filteredJobLevels.length)}
						onSelect={(jobLevel, data) => handleFilterJobLevel(jobLevel, data)}
						onDeselect={(jobLevel) => handleRemoveJobLevelFilter(jobLevel)}
					>
						{jobLevelOptions}
					</Select>
				)}
				{allowSelfReferralsInternalLink && (
					<Select
						defaultValue="referral-and-internal"
						placeholder="Select Internal Available"
						disabled={!allowSelfReferralsInternalLink}
						suffixIcon={renderArrowIcon(filteredAvailables.length)}
						onSelect={(department, { key }) =>
							handleFilterAvailable(department, key)
						}
						onDeselect={(department, { key }) =>
							handleRemoveAvailableFilter(department, key)
						}
					>
						{availableOptions}
					</Select>
				)}
				{!isExtendedUser && !jobFilterSettings.payRate?.hidden && (
					<Select
						showArrow
						filterOption={(input, option) => {
							return option.props.children
								.toString()
								.toLowerCase()
								.includes(input.toString().toLowerCase());
						}}
						value={filteredSalary}
						mode="single"
						placeholder={ml('Min. Pay Rate', currentUser, allMultiLingualData)}
						maxTagCount={1}
						maxTagTextLength={15}
						suffixIcon={renderArrowIcon(filteredBonuses.length)}
						onSelect={handleFilterSalary}
					>
						<Select.Option key={0} value={0}>
							Any Min. Pay Rate
						</Select.Option>
						<Select.Option key={30_000} value={30_000}>
							$15/hr. / $30,000/yr.
						</Select.Option>
						<Select.Option key={40_000} value={40_000}>
							$20/hr. / $40,000/yr.
						</Select.Option>
						<Select.Option key={50_000} value={50_000}>
							$25/hr. / $50,000/yr.
						</Select.Option>
						<Select.Option key={60_000} value={60_000}>
							$30/hr. / $60,000/yr.
						</Select.Option>
						<Select.Option key={70_000} value={70_000}>
							$35/hr. / $70,000/yr.
						</Select.Option>
						<Select.Option key={80_000} value={80_000}>
							$40/hr. / $80,000/yr.
						</Select.Option>
						<Select.Option key={90_000} value={90_000}>
							$45/hr. / $90,000/yr.
						</Select.Option>
						<Select.Option key={100_000} value={100_000}>
							$50/hr. / $100,000/yr.
						</Select.Option>
						<Select.Option key={110_000} value={110_000}>
							$55/hr. / $110,000/yr.
						</Select.Option>
						<Select.Option key={120_000} value={120_000}>
							$60/hr. / $120,000/yr.
						</Select.Option>
						<Select.Option key={140_000} value={140_000}>
							$70/hr. / $140,000/yr.
						</Select.Option>
						<Select.Option key={160_000} value={160_000}>
							$80/hr. / $160,000/yr.
						</Select.Option>
						<Select.Option key={180_000} value={180_000}>
							$90/hr. / $180,000/yr.
						</Select.Option>
						<Select.Option key={200_000} value={200_000}>
							$100/hr. / $200,000/yr.
						</Select.Option>
						<Select.Option key={250_000} value={250_000}>
							$125/hr. / $250,000/yr.
						</Select.Option>
						<Select.Option key={300_000} value={300_000}>
							$150/hr. / $300,000/yr.
						</Select.Option>
					</Select>
				)}
				{!isExtendedUser &&
					// Hide filter if user doesn't use bonuses
					!hideBonusFilter &&
					bonuses &&
					bonuses.length > 0 && (
						<Select
							showArrow
							filterOption={(input, option) => {
								return option.props.children
									.toString()
									.toLowerCase()
									.includes(input.toString().toLowerCase());
							}}
							value={filteredBonuses}
							mode="multiple"
							placeholder={ml('Bonus', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={renderArrowIcon(filteredBonuses.length)}
							onSelect={handleFilterBonus}
							onDeselect={handleRemoveBonusFilter}
						>
							{bonusOptions}
						</Select>
					)}
				{!isExtendedUser && (
					<Select
						showArrow
						filterOption={(input, option) => {
							return option.props.children
								.toLowerCase()
								.includes(input.toLowerCase());
						}}
						value={filteredDepartments}
						mode="multiple"
						placeholder={ml(filterbyDeptText, currentUser, allMultiLingualData)}
						maxTagCount={1}
						maxTagTextLength={15}
						suffixIcon={renderArrowIcon(filteredDepartments.length)}
						dropdownMatchSelectWidth={false}
						onSelect={(department, { key }) =>
							handleFilterDepartment(department, key)
						}
						onDeselect={(department, { key }) =>
							handleRemoveDepartmentFilter(department, key)
						}
					>
						{options}
					</Select>
				)}
				{!isExtendedUser && (
					<Select
						showArrow
						filterOption={(input, option) => {
							return option.props.children
								.toString()
								.toLowerCase()
								.includes(input.toString().toLowerCase());
						}}
						value={filteredDate}
						mode="single"
						placeholder={ml('Created Date', currentUser, allMultiLingualData)}
						maxTagCount={1}
						maxTagTextLength={15}
						suffixIcon={renderArrowIcon(filteredBonuses.length)}
						onSelect={handleFilterDate}
					>
						<Select.Option key="All Time" value={0}>
							All Time
						</Select.Option>
						<Select.Option key="Yesterday" value={1}>
							Yesterday (1 Day Ago)
						</Select.Option>
						<Select.Option key="7 Days Ago" value={7}>
							7 Days Ago
						</Select.Option>
						<Select.Option key="14 Days Ago" value={14}>
							14 Days Ago
						</Select.Option>
						<Select.Option key="30 Days Ago" value={30}>
							30 Days Ago
						</Select.Option>
						<Select.Option key="60 Days Ago" value={60}>
							60 Days Ago
						</Select.Option>
						<Select.Option key="90 Days Ago" value={90}>
							90 Days Ago
						</Select.Option>
					</Select>
				)}
			</div>
		</div>
	);
}
