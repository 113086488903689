import { connect } from 'react-redux';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import { dashboardActions, userActions } from 'src/actions.js';
import { compose } from '../../_shared/services/utils';
import EmployeeDashboardSettingsComponent from './EmployeeDashboardSettingsComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
		},
		nextToken: null,
		limit: 10_000,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateCurrentUserCompany(company) {
			dispatch(userActions.updateUserCompany(company));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const EmployeeDashboardSettingsWithApi = compose(withGetCompany)(
	EmployeeDashboardSettingsComponent
);

export const EmployeeDashboardSettings = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(EmployeeDashboardSettingsWithApi);
