import { Button } from 'antd';
import { get } from 'lodash';
import { parse } from 'src/_shared/services/utils';
import { ONBOARDING_SCREENS } from '../constants';
import {
	bulletPointStyle,
	buttonContainerStyle,
	buttonStyle,
	containerStyle,
	subHeaderStyle,
	subtitleStyle,
	textBodyStyle,
	titleContainerStyle,
	titleStyle,
	videoWrap,
} from './onboardingStyles.js';

function WelcomeScreen(props) {
	const {
		company,
		completeOnboardingPage,
		currentUser,
		nextPage,
		navToScreen,
	} = props;

	const partnerHost = get(company, 'partnerHost');
	const policy = parse(get(company, 'partnerHost.partnerBillingPolicy'));

	const next = () => {
		completeOnboardingPage(ONBOARDING_SCREENS.WELCOME, {
			finished: new Date().toISOString(),
		});

		navToScreen(ONBOARDING_SCREENS.SAAS_AGREEMENT);
	};

	return (
		<div className={containerStyle}>
			<div className={titleContainerStyle(partnerHost)}>
				<h1 className={titleStyle}>Welcome To Your Referral Setup</h1>
				<div className={textBodyStyle}>
					<div className={subtitleStyle}>
						Engage your employees to recruit for you through referrals!
					</div>
					<div>
						Allow your employees to make and track referrals from any browser or
						mobile phone.
						<br />
						<br />
					</div>
					<div>
						<div>How it works:</div>
						<FlexDiv>
							<BulletPoint>1.</BulletPoint>
							<BulletPoint>
								Choose how much to pay employees and when.
							</BulletPoint>
						</FlexDiv>

						<FlexDiv>
							<BulletPoint>2.</BulletPoint>
							<BulletPoint>
								Fund your account so employees can automatically earn gift cards
								for successful referral hires.
							</BulletPoint>
						</FlexDiv>

						<FlexDiv>
							<BulletPoint>3.</BulletPoint>
							<BulletPoint>Invite your employees to get started.</BulletPoint>
						</FlexDiv>

						<FlexDiv>
							<BulletPoint>4.</BulletPoint>
							<BulletPoint>
								Manage candidates in your normal TalentReef portal - it will
								automatically update the referrals.
							</BulletPoint>
						</FlexDiv>

						<div
							className={subHeaderStyle}
							style={{ marginBottom: 20, textAlign: 'center' }}
						>
							Visit our knowledge base{' '}
							<a
								href="https://mitratech.my.site.com/talentreef/s/topic/0TO8Z000000ibG4WAI/talentreef-referrals"
								target="_blank"
								rel="noreferrer"
							>
								here
							</a>
						</div>
					</div>
					<p style={{ marginBottom: 30 }} />
					<div className={buttonContainerStyle}>
						<Button
							type="primary"
							shape="round"
							size="large"
							className={buttonStyle}
							onClick={next}
						>
							Next
						</Button>
					</div>
					<div style={{ height: 40 }} />
				</div>
			</div>
		</div>
	);
}

// *******************************
// *** SUB-COMPONENTS
// *******************************

function DemoVideo() {
	return (
		<>
			<div className={subtitleStyle}>
				Watch this video to visualize what your employees can expect
			</div>
			<div className={videoWrap}>
				<iframe
					allowFullScreen
					src="https://player.vimeo.com/video/747752974"
					width="800"
					height="500"
					frameBorder="0"
					allow="autoplay; fullscreen"
				/>
			</div>
		</>
	);
}

function FlexDiv({ children }) {
	return <div style={{ display: 'flex' }}>{children}</div>;
}

function BulletPoint({ children }) {
	return <span className={bulletPointStyle}>{children}</span>;
}

export default WelcomeScreen;
