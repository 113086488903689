import { connect } from 'react-redux';
import { compose } from 'src/_shared/services/utils';
import { JobDescriptionCard as JobDescriptionCardComponent } from './JobDescriptionCardComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	return {};
};

const mapDispatchToProperties = () => {
	return {};
};

export const JobDescriptionCard = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(compose()(JobDescriptionCardComponent));
