import { Button, Modal } from 'antd'
import { useState } from 'react';

import ManageAddressContainer from '../../container/manageAddressContainer';

import usePhysicalAward from '../../hooks/usePhysicalAward.js';

import fastTruck from '../../../assets/svg/fast-truck.svg';
import './style.scss'
import AllCaughtUp from '../../../RnRCommonComponent/allCaughtUp/AllCaughtUp.jsx';

export const confirmedStatus = {
  Confirmed: 'Confirmed',
  Declined: 'Declined',
}

const ActionItemCard = () => {
  const [showAddressModal, setShowAddress] = useState(false)
  const {
    isLoading,
    pendingPhysicalAward,
    managePhysicalAwardsConfirmationStatus
  } = usePhysicalAward()
  const [isDeclineRewardVisible, setIsDeclineRewardVisible] = useState(false);

  return (
    <>
      <Modal
        width={420}
        title="Decline Recognition Approval"
        open={isDeclineRewardVisible}
        footer={false}
        onOk={false}
        className='confirmation-modal'
        onCancel={() => {
          setIsDeclineRewardVisible(false);
        }}
        destroyOnClose={true}
      >
        <>
          <p className='text-center modal-detail'>Are you sure you want to decline Recognition?</p>
          <div className='text-center'>
            <button
              className='decline-btn'
              onClick={() => {
                managePhysicalAwardsConfirmationStatus(confirmedStatus.Declined, pendingPhysicalAward?.[0]?.id)
                setIsDeclineRewardVisible(false);
              }}
            >Decline Recognition</button>
          </div>
        </>
      </Modal>
      {pendingPhysicalAward?.length ?
        <div className='action-item'>
          <div className='send-points'>
            <div className='points-detail'>
              <div className='reward-point-img'>
                <img src={fastTruck} alt='img' />
              </div>
              <div className='about-points'>
                <div className='point-amount'>Shipping Alert!</div>
                <div>
                  <div className='point-info'>One of your physical rewards requires confirmation before it can be sent:</div>
                  <div className='point-info info-bold'>{pendingPhysicalAward?.[0]?.badgeName}</div>
                </div>
                <div className='button-container'>
                  <Button type="primary" onClick={() => {
                    setShowAddress(true)
                  }}>Confirm Address</Button>
                  <Button
                    onClick={() => { setIsDeclineRewardVisible(true) }}
                    className='decline-button'
                    ghost>Decline Reward </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <AllCaughtUp title={"You're all caught up!"} />
      }
      {
        showAddressModal ?
          <ManageAddressContainer
            handleConfirm={
              () => {
                managePhysicalAwardsConfirmationStatus(confirmedStatus.Confirmed, pendingPhysicalAward?.[0]?.id)
              }} showModal={showAddressModal} setShowModal={setShowAddress} />
          : null}
    </>
  )
}

export default ActionItemCard