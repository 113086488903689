import { connect } from 'react-redux';
import { userActions } from 'src/actions.js';
import { compose, mutation } from '../_shared/services/utils';
import NewInternalReferralPageComponent from './NewInternalReferralComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { params } = props.match;
	const id = params.jobId;
	return {
		id,
		currentUser: state?.user?.currentUser,
		lang: props.match.params?.lang,
		onUpdateContact,
		onCreateReferral,
		ImportedCreateContact,
		params,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
	};
};

const onUpdateContact = async (input) => {
	return await mutation(input, 'updateContact');
};

const onCreateReferral = async (input) => {
	return await mutation(input, 'createReferral');
};

const ImportedCreateContact = async (input) => {
	return await mutation(input, 'createContact');
};

const NewInternalReferralPageWithApi = compose()(NewInternalReferralPageComponent);

export const NewInternalReferralPage = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(NewInternalReferralPageWithApi);
