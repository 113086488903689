import { connect } from 'react-redux';
import { withUserByIdEditProfile } from 'src/_shared/api/components/edit-profile/';
import { compose } from '../_shared/services/utils';
import EditUserProfileComponent from './EditUserProfileComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { id } = props.match.params;
	const { currentUser } = state.user;
	return {
		currentUser,
		id,
		filter: { companyId: { eq: currentUser.companyId }, active: { eq: true } },
		limit: 200,
	};
};

const EditUserProfileWithAPI = compose(withUserByIdEditProfile)(
	EditUserProfileComponent
);

export const EditUserProfile = connect(mapStateToProperties)(
	EditUserProfileWithAPI
);
