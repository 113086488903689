import { Button, Input, Modal, Select } from 'antd';
import { Component } from 'react';
import { geolocated } from 'react-geolocated';
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { getLocation, placesAutocomplete } from 'src/_shared/services/utils';
import { USStates } from './copy.js';

class BrowseJobsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			city: '',
			state: '',
			country: '',
			submitting: false,
			zip: '',
			geolocationAllowed: false,
		};
	}

	async componentDidMount() {
		const permissions = await navigator.permissions.query({
			name: 'geolocation',
		});
		const geolocationAllowed = permissions?.state === 'granted';
		this.setState({ geolocationAllowed });
	}

	autofillLocation = async () => {
		const permissions = await navigator.permissions.query({
			name: 'geolocation',
		});
		const lat = this.props?.coords?.latitude;
		const lon = this.props?.coords?.longitude;
		if (permissions?.state === 'granted' && lat && lon) {
			const location = await getLocation({ lat, lon });
			this.setState(location);
		} else {
			console.log('Location access is denied.');
		}
	};

	errorHandler = (error) => {
		if (error.code == 1) {
			console.log('Error: Access is denied!');
		} else if (error.code == 2) {
			console.log('Error: Position is unavailable!');
		}
	};

	submit = async (e) => {
		e.preventDefault();
		try {
			const { address, lat, lon, city, state, country, zip } = this.state;
			let location = {
				address,
				lat,
				lng: lon,
				lon,
				city,
				state,
				country,
				zip,
			};
			if (address && (!lat || !lon)) {
				const autocomplete = await placesAutocomplete(address);
				location = await getLocation({
					place: autocomplete?.Results?.[0]?.PlaceId,
				});
			}

			this.props.updateFilteredJobsByLocation(
				this.props.filteredDistance,
				location
			);
			this.props.handleSubmit(location);
			this.props.handleCancel();
			this.setState({ submitting: false });
			this.setState({
				address: '',
				city: '',
				state: '',
				lat: null,
				lng: null,
				lon: null,
				country: '',
				zip: '',
			});
		} catch {
			this.props.handleCancel();
			this.setState({ submitting: false });
		}
	};

	handleChangeLocation = (address) => {
		this.setState({ address });
	};

	handleSelectLocation = (location) => {
		this.setState(location);
	};

	renderLocation = () => {
		const { autoFillText } = this.props;
		const { Option } = Select;
		const options = [];
		Object.keys(USStates).map((key) =>
			options.push(<Option key={key}>{USStates[key]}</Option>)
		);
		return (
			<>
				<div
					style={{
						display: 'flex',
						alignItems: 'space-between',
						marginTop: 10,
					}}
				>
					<PlacesAutocomplete
						address={this.state.address}
						setAddress={this.handleChangeLocation.bind(this)}
						style={{ width: '100%' }}
						onSelect={this.handleSelectLocation.bind(this)}
					/>
					{this.state?.geolocationAllowed && (
						<Button
							type="link"
							style={{ marginLeft: 5 }}
							onClick={this.autofillLocation}
						>
							<i className="icon-location" />
							<span>{autoFillText}</span>
						</Button>
					)}
				</div>
				<div className="modal-footer-btn">
					<Button
						type="primary"
						size="large"
						className="btn-min-width"
						onClick={async (e) =>
							this.setState({ submitting: true }, await this.submit(e))
						}
					>
						Update
					</Button>
				</div>
			</>
		);
	};

	render() {
		const { changeLocationtext, visible, handleCancel, success } = this.props;
		return (
			<Modal
				destroyOnClose
				open={visible}
				footer={null}
				success={success}
				title={changeLocationtext}
				onCancel={handleCancel}
			>
				{this.renderLocation()}
			</Modal>
		);
	}
}
export default geolocated({
	positionOptions: {
		enableHighAccuracy: false,
	},
	userDecisionTimeout: 5000,
})(BrowseJobsModal);
