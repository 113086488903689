import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Row, Select, message } from 'antd';
import { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import {
	FREQUENCY_MAP,
	FREQUENCY_TYPE,
	FREQUENCY_VALUE,
} from 'src/_shared/constants/';
import { initialCaps, parse } from '../../_shared/services/utils';
import { COLORS } from '../../_shared/styles/colors';
import { SaveButton } from '../editEmailsStyles.js';
import {
	FormItemContainer,
	FormStyles,
	LabelStyles,
	SelectContainer,
	checkBox,
	customSelectClass,
	regularButton,
	tableContainer,
	tableHeader,
	tableHeaderContainer,
} from './jobNotificationStyles.js';

class JobNotificationComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isExisting: false,
			isChanged: false,
			enableJobNotifications: props.company?.enableJobNotifications ?? false,
			enableClaimJobNotifications:
				props.company?.sendNewJobsToAccountClaims ?? false,
			notificationFrequency:
				props.company?.jobNotificationFrequency ?? FREQUENCY_VALUE.MONDAYS,
			notificationType:
				FREQUENCY_MAP?.[props.company?.jobNotificationFrequency]?.type ??
				FREQUENCY_TYPE.WEEKLY,
			jobNotificationSetting: props?.company?.jobNotificationSetting
				? parse(get(props, 'company.jobNotificationSetting'))
				: {
						departmentSetting: 'ALL',
						subCompanySetting: 'ALL',
					},
		};
	}

	componentDidMount() {
		if (
			!this.state.jobNotificationSetting.departmentSetting ||
			!this.state.jobNotificationSetting.subCompanySetting
		) {
			this.setState((prevState) => ({
				jobNotificationSetting: {
					...prevState.jobNotificationSetting,
					departmentSetting:
						prevState.jobNotificationSetting?.departmentSetting ?? 'ALL',
					subCompanySetting:
						prevState.jobNotificationSetting?.subCompanySetting ?? 'ALL',
				},
			}));
		}
	}

	handleDeliveryDateChange = (value) => {
		this.setState({ notificationFrequency: value, isChanged: true });
	};

	handleFrequencyTypeChange = (selection) => {
		const notificationFrequency =
			selection === FREQUENCY_TYPE.WEEKLY
				? FREQUENCY_VALUE.MONDAYS
				: selection === FREQUENCY_TYPE.MONTHLY
					? FREQUENCY_VALUE.FIRST
					: FREQUENCY_VALUE.DAILY;
		this.setState({
			notificationType: selection,
			isChanged: true,
			notificationFrequency,
		});
		this.props.form.setFields({
			deliveryTime: {
				value: notificationFrequency,
			},
		});
	};

	handleRecipientChange = (property, value) => {
		this.setState((prevState) => ({
			isChanged: true,
			jobNotificationSetting: {
				...prevState.jobNotificationSetting,
				[property]: value,
			},
		}));
	};

	toggleSendSummary = () => {
		this.setState((prevState) => ({
			enableJobNotifications: !prevState.enableJobNotifications,
			isChanged: true,
		}));
	};

	toggleSendClaimSummary = () => {
		this.setState((prevState) => ({
			enableClaimJobNotifications: !prevState.enableClaimJobNotifications,
			isChanged: true,
		}));
	};

	updateJobNotificationSettings = async () => {
		try {
			const {
				enableJobNotifications,
				enableClaimJobNotifications,
				notificationFrequency,
				jobNotificationSetting,
			} = this.state;
			const { company } = this.props.currentUser;
			const { onUpdateCompany } = this.props;
			const sendNewJobsToAccountClaims = enableClaimJobNotifications;
			await onUpdateCompany({
				input: {
					id: company.id,
					enableJobNotifications,
					sendNewJobsToAccountClaims,
					jobNotificationFrequency: notificationFrequency,
					jobNotificationSetting: JSON.stringify(jobNotificationSetting),
				},
			});
			message.success('Your settings have been saved.');
		} catch {
			message.error('There was a problem saving your settings.');
		} finally {
			this.setState({ isChanged: false });
		}
	};

	renderDelivery = () => {
		const { getFieldDecorator } = this.props.form;
		const { notificationType, notificationFrequency } = this.state;

		return (
			<div style={{ marginLeft: 24 }}>
				<h4 className="setting-card-title" style={{ marginBottm: 0 }}>
					Frequency
				</h4>
				<div className={FormItemContainer}>
					<label
						htmlFor="referralBonusReportingRecipients"
						style={{ width: 140 }}
					>
						Delivery Frequency
					</label>
					<Form.Item style={{ marginBottom: 0 }}>
						{getFieldDecorator(`deliveryFrequency`, {
							initialValue: initialCaps(notificationType) ?? '',
						})(
							<Select
								placeholder="Select Frequency "
								style={{
									width: 130,
									fontSize: '14px',
								}}
								className={(SelectContainer, customSelectClass)}
								onSelect={this.handleFrequencyTypeChange}
							>
								{Object.values(FREQUENCY_TYPE).map((frequency) => (
									<Select.Option key={frequency} value={frequency}>
										{initialCaps(frequency)}
									</Select.Option>
								))}
							</Select>
						)}
					</Form.Item>
				</div>
				{notificationType !== FREQUENCY_VALUE.DAILY && (
					<div
						className={FormItemContainer}
						style={{
							display: 'flex',
							flexDirection: 'row',
							fontSize: '14px',
							color: '#222222',
						}}
					>
						<label
							htmlFor="JobNotificationReportingRecipients"
							style={{ width: 140 }}
						>
							Delivery Date
						</label>

						<Form.Item style={{ marginBottom: 0 }}>
							{getFieldDecorator(`deliveryTime`, {
								initialValue: notificationFrequency
									? initialCaps(notificationFrequency)
									: '',
							})(
								<Select
									placeholder="Select "
									style={{
										width: 130,
										fontSize: '14px',
									}}
									className={(SelectContainer, customSelectClass)}
									onSelect={this.handleDeliveryDateChange}
								>
									{Object.values(FREQUENCY_MAP)
										.filter((frequency) => frequency.type === notificationType)
										.map((frequency) => (
											<Select.Option
												key={frequency.key}
												value={frequency.value}
											>
												{frequency.key}
											</Select.Option>
										))}
								</Select>
							)}
						</Form.Item>
					</div>
				)}
			</div>
		);
	};

	renderDepartmentNotifications = () => {
		const { departmentSetting } = this.state.jobNotificationSetting;
		const notificationTypes = {
			ALL: 'Notify All Employees',
			DEPARTMENT_ONLY: 'Notify Department Only',
		};
		return (
			<div style={{ marginLeft: 24 }}>
				<h4 className="setting-card-title" style={{ marginBottom: 0 }}>{`${
					this.props.currentUser.company.departmentLabel ?? 'Department'
				} Notifications`}</h4>
				<br />
				{Object.keys(notificationTypes).map((key) => {
					return (
						<Button
							key={key}
							className={regularButton(key === departmentSetting)}
							style={{ marginRight: 5, marginLeft: 0 }}
							onClick={() => {
								this.handleRecipientChange('departmentSetting', key);
							}}
						>
							{notificationTypes[key]}
						</Button>
					);
				})}
			</div>
		);
	};

	renderSubCompanyNotifications = () => {
		const { subCompanySetting } = this.state.jobNotificationSetting;
		const subCompanyNotificationTypes = {
			ALL: 'Notify All Employees',
			SUBCOMPANY_ONLY: 'Notify Subcompany Employees Only',
		};
		return (
			<div style={{ marginLeft: 24 }}>
				<h4 className="setting-card-title" style={{ marginBottom: 0 }}>{`${
					this.props.currentUser.company.subCompanyLabel ?? 'Subcompany'
				} Notifications`}</h4>
				<br />
				{Object.keys(subCompanyNotificationTypes).map((key) => {
					return (
						<Button
							key={key}
							className={regularButton(key === subCompanySetting)}
							style={{ marginRight: 5, marginLeft: 0 }}
							onClick={() => {
								this.handleRecipientChange('subCompanySetting', key);
							}}
						>
							{subCompanyNotificationTypes[key]}
						</Button>
					);
				})}
			</div>
		);
	};

	render() {
		const { isChanged, enableJobNotifications, enableClaimJobNotifications } =
			this.state;
		const { getFieldDecorator } = this.props.form;
		return (
			<div>
				<div className="page-title">
					<h2 className="page-heading">Job Notifications</h2>
				</div>
				<div className="setting-card">
					<section
						className={tableHeaderContainer}
						style={{ marginBottom: '10px' }}
					>
						{isChanged && (
							<Button
								className={SaveButton}
								style={{ fontWeight: 600, fontSize: 14, marginBottom: 0 }}
								onClick={this.updateJobNotificationSettings}
							>
								Save
							</Button>
						)}
					</section>
					<section className={tableContainer}>
						<Form>
							<Form.Item style={{ marginBottom: 5 }}>
								{getFieldDecorator(`enableNotifications`, {
									initialValue: enableJobNotifications,
								})(
									<div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<Checkbox
												checked={enableJobNotifications}
												className={checkBox}
												onChange={this.toggleSendSummary}
											/>
											<h4
												className="setting-card-title"
												style={{ margin: 0, paddingTop: 1 }}
											>
												New Job Alerts
											</h4>
										</div>
										<p style={{ marginLeft: 24 }}>
											Schedule automated new job summary emails to be sent to
											active users.
										</p>
									</div>
								)}
							</Form.Item>

							{enableJobNotifications && (
								<div>
									<Row>{this.renderDelivery()}</Row>

									<Row style={{ marginTop: 10, marginBottom: 30 }}>
										{this.renderDepartmentNotifications()}
									</Row>

									<Row style={{ marginTop: 10, marginBottom: 30 }}>
										{this.renderSubCompanyNotifications()}
									</Row>
								</div>
							)}
							<Row style={{ marginTop: 10 }}>
								<Form.Item>
									{getFieldDecorator(`enableClaimJobNotifications`, {
										initialValue: enableClaimJobNotifications,
									})(
										<div>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Checkbox
													checked={enableClaimJobNotifications}
													className={checkBox}
													onChange={this.toggleSendClaimSummary}
												/>
												<h4
													className="setting-card-title"
													style={{ margin: 0, paddingTop: 1 }}
												>
													Unclaimed Account New Job Alerts
												</h4>
											</div>
											<p style={{ marginLeft: 24 }}>
												Automated bi-weekly emails to users that have never
												signed in (requires Active Employee Feed with email
												addresses).
											</p>
										</div>
									)}
								</Form.Item>
							</Row>
						</Form>
					</section>
				</div>
			</div>
		);
	}
}
export default withApollo(Form.create()(JobNotificationComponent));
