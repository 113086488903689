import { Modal } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import EmployeeLinkComponent from '../EmployeeLinkComponent.jsx';
import { ReferralForm } from './referral-modal-form';
import * as ModalStyles from './referralModalStyles.js';

class ReferralModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autoCompleteResult: [],
			isSubmitting: false,
			newContact: true,
			error: false,
			buttonState: '',
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
		};
	}

	componentDidMount() {
		this.getCurrencyRate(get(this.props, 'currentUser.currency', 'USD'));
		if (this.props.matchFound) {
			this.setState({ autoCompleteResult: [this.props.matchFound] });
		}

		if (this.props.contact) {
			this.setState({ autoCompleteResult: [this.props.contact] });
		}
	}

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			this.setState({
				currencyRate: 1,
				currencySymbol: '$',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency);
			this.setState({
				currencyRate: rate,
				currencySymbol: symbol,
			});
		}
	}

	handleContactChange = (value) => {
		let autoCompleteResult = [];
		if (value) {
			for (const record of this.props.contacts) {
				const { firstName, lastName, emailAddress } = record;
				const isFirstNameMatch =
					firstName && firstName.toLowerCase().includes(value.toLowerCase());
				const isLastNameMatch =
					lastName && lastName.toLowerCase().includes(value.toLowerCase());
				const isEmailMatch =
					emailAddress &&
					emailAddress.toLowerCase().includes(value.toLowerCase());

				if (isFirstNameMatch || isLastNameMatch || isEmailMatch) {
					autoCompleteResult.push(record);
				}
			}
		} else {
			autoCompleteResult = [];
		}

		this.setState({ autoCompleteResult });
	};

	handleError = () => {
		this.setState({ error: true });
	};

	handleNewContact = () => {
		this.setState((prevState) => ({ newContact: !prevState.newContact }));
	};

	toggleIsSubmitting = () => {
		this.setState({ buttonState: 'loading' });
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
		setTimeout(() => {
			this.setState({ buttonState: 'success' });
		}, 5400);
	};

	render() {
		const {
			job,
			referrals,
			visible,
			handleCancel,
			handleCancelAll,
			onCreateReferral,
			currentUser,
			contact,
			onUpdateContactDeckStatus,
			onUpdateNotification,
			currentTieredBonus,
			client,
		} = this.props;
		const {
			isSubmitting,
			error,
			currencySymbol,
			currencyRate,
			buttonState,
			theme,
		} = this.state;
		const { location } = job;
		const referralBonus = get(job, 'job.referralBonus');
		let displayBonus = get(referralBonus, 'hasBonus')
			? `${currencySymbol}${
					get(referralBonus, 'amount', 0) * currencyRate
				}`.replaceAll(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
			: 'N/A';
		if (currentTieredBonus) {
			displayBonus = get(currentTieredBonus, 'name', 'N/A');
		}

		const toggleClass = ModalStyles.ModalStyles;
		return (
			<Modal
				open={visible}
				footer={null}
				title="Review Referral"
				onCancel={handleCancel}
			>
				<div className="modal-job-info-wrap">
					<div className="mj-info-wrap">
						<h3 className="mj-info-name">
							<a
								href={`/jobs/${get(job, ['id'], '')}`}
								target="_blank"
								rel="noreferrer"
							>
								{job.title}
							</a>
						</h3>
						<ul className="mj-info-bonus">
							{job.isHotJob ? (
								<li>
									<i className="icon-fire text-orange" />
								</li>
							) : (
								''
							)}

							<li>
								<span className="text-green">{displayBonus}</span>
							</li>
						</ul>
					</div>
					<ul className="mj-info" style={{ marginBottom: 8 }}>
						<li>
							<i className="icon-folder" />
							<span>{job.department ? job.department.name : ''}</span>
						</li>
						<li>
							<i className="icon-location" />
							<span>
								{location && location.city && location.state
									? `${location.city}, ${location.state}`
									: 'Remote'}
							</span>
						</li>
					</ul>
					<p style={{ marginBottom: 8 }}>
						{'Candidate: '}
						<a
							href={`/mycontacts/${get(this.props, ['data', 'id'], '')}`}
							target="_blank"
							rel="noreferrer"
						>
							{`${get(this.props, ['data', 'firstName'], '')} ${get(
								this.props,
								['data', 'lastName'],
								''
							)}`}
						</a>
					</p>
					<p style={{ marginBottom: 8 }}>
						{'Referred By: '}
						<EmployeeLinkComponent
							client={client}
							employee={get(this.props, 'data.user', null)}
							target="_blank"
						/>
					</p>
				</div>

				<ReferralForm
					client={client}
					data={this.props.data}
					toggleIsSubmitting={this.toggleIsSubmitting}
					handleCancel={handleCancel}
					handleCancelAll={handleCancelAll}
					error={error}
					currentUser={currentUser}
					job={job}
					notification={this.props.notification}
					referrals={referrals}
					buttonState={buttonState}
					handleContactChange={this.handleContactChange}
					isSubmitting={isSubmitting}
					handleError={this.handleError}
					currentTieredBonus={get(this.props, 'currentTieredBonus')}
					contact={contact}
					onUpdateNotification={onUpdateNotification}
					onUpdateContactDeckStatus={onUpdateContactDeckStatus}
					onUpdateContact={this.props.onUpdateContact}
					onCreateReferral={onCreateReferral}
				/>
			</Modal>
		);
	}
}

export default ReferralModal;
