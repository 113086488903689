import get from 'lodash/get';
import { connect } from 'react-redux';

// Global themes/styles can be built in this file which will be accessable throught the whole app
// How it works: You create someVariable for the common styles, return it in classNames at the bottom, then anywhere in the app
// on an element you can say type="someVariable" to apply those styles
// See examble below for type="addButton"...This is the colored round add buttons for add employee, add referral, add job etc.
// This eliminates the need to get and add the theme in the css file for every component that wants it and duplicating the styles.

export default connect((state) => ({
	themeJson: get(state, ['user', 'currentUser', 'company', 'theme']),
	themeEnabled: false,
}))(function ThemeProvider({ themeJson, themeEnabled, children }) {
	let theme;
	try {
		// Attempt to parse the theme json
		theme = JSON.parse(themeJson);
		themeEnabled = Boolean(theme.enabled);
	} catch {
		// If there was an error parsing the json or the theme is disabled
		// fallback to default theme
	}

	function brightenHexColor(hexColor, factor) {
		// Remove the '#' symbol if it exists
		hexColor = hexColor.replace(/^#/, '');

		// Parse the hex color into its RGB components
		const r = Number.parseInt(hexColor.slice(0, 2), 16);
		const g = Number.parseInt(hexColor.slice(2, 4), 16);
		const b = Number.parseInt(hexColor.slice(4, 6), 16);

		// Calculate the new RGB values
		const newR = Math.min(255, r + factor);
		const newG = Math.min(255, g + factor);
		const newB = Math.min(255, b + factor);

		// Convert the new RGB values back to hex
		const newHexColor = `#${((1 << 24) | (newR << 16) | (newG << 8) | newB).toString(16).slice(1)}`;

		return newHexColor;
	}

	if (themeEnabled) {
		const {
			buttonColor,
			addButtonColor,
			enabled,
			menuColor,
			menuHighlightColor,
		} = theme;

		addButtonColor &&
			document
				.querySelector(':root')
				.style.setProperty('--add-btn-color', addButtonColor);
		buttonColor &&
			document
				.querySelector(':root')
				.style.setProperty('--primary-btn-color', buttonColor);
		buttonColor &&
			document
				.querySelector(':root')
				.style.setProperty(
					'--primary-btn-highlight-color',
					brightenHexColor(buttonColor, 30)
				);
		menuColor &&
			document
				.querySelector(':root')
				.style.setProperty('--menu-color', menuColor);
		menuHighlightColor &&
			document
				.querySelector(':root')
				.style.setProperty('--menu-highlight-color', menuHighlightColor);
	} else {
		document.querySelector(':root').style.setProperty('--add-btn-color', null);
		document
			.querySelector(':root')
			.style.setProperty('--primary-btn-color', null);
		document.querySelector(':root').style.setProperty('--menu-color', null);
		document
			.querySelector(':root')
			.style.setProperty('--menu-highlight-color', null);
	}

	return children;
});
