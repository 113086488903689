import { useMemo } from "react";
import { Select, DatePicker, Form, Input, Button, Spin, Tooltip } from "antd"
import moment from 'moment';
import { FormikProvider } from 'formik'

import './style.scss'

const ManageNominatedRewardModal = ({
    isLoading,
    create,
    empLevel,
    badgeList,
    empLoginType,
    empPayType,
    formik,
    showLoader,
    loading,
    nominatedCount
}) => {

    const {
        values,
        handleSubmit,
        setFieldValue,
        errors,
        submitCount
    } = formik;

    const disabledStartDate = (current) => {
        return current < moment().startOf('day')
    };

    const disabledFormEdit = useMemo(() => {
        // Disable all dates before today
        return Boolean(nominatedCount);
    }, [nominatedCount]);

    return (
        <>
            <FormikProvider value={formik} className="manage-nominated-reward">
                {loading || isLoading || showLoader ?
                    <div className="d-flex align-items-center justify-content-center w-100 h-100 edit-modal-loader"> <Spin /> </div> :
                    <></>}
                <div className="row">
                    <div className="col-xl-10 col-lg-6 col-12 pr-0">
                        <label className="custom-label mb-2 font-weight-bold">
                            Nomination Period Setup:
                        </label>

                        <div className="custom-form-group">
                            <Form.Item>
                                <label className="custom-label sub-custom-label">Choose Reward : <span className="asterisk">*</span></label>
                                <Tooltip
                                    overlayClassName="error-tooltip"
                                    open={submitCount > 0 && errors && Boolean(errors?.badgeId)}
                                    placement="bottomLeft"

                                    getPopupContainer={trigger => trigger.parentElement}
                                    title={errors?.badgeId}
                                >
                                    <div className="col-sm-7 pl-0 reward-select-input select-height">

                                        <Select
                                            className="custom-input choose-reward-input"
                                            loading={isLoading}
                                            placeholder="Select Nomination Award"
                                            value={values?.badgeId}
                                            onChange={(event) => {
                                                setFieldValue("badgeId", event)
                                            }}
                                            disabled={disabledFormEdit}
                                        >
                                            <Select.Option disabled value={""}>Select Nomination Award</Select.Option>
                                            {badgeList?.map(({ name, id }) => {
                                                return <Select.Option key={Math.random()} value={id}>{name}</Select.Option>
                                            })}
                                        </Select>

                                    </div>
                                </Tooltip>
                            </Form.Item>
                        </div>
                        <div className="custom-form-group">
                            <Form.Item>
                                <label className="custom-label">Nomination Period : <span className="asterisk">*</span></label>
                                <div className="col-sm-7 pl-0 d-flex" style={{ gap: "10px" }}>

                                    <div className="d-flex flex-column" style={{ width: "100%" }}>
                                        <Tooltip
                                            overlayClassName="error-tooltip"
                                            open={submitCount > 0 && errors && Boolean(errors?.startDate)}
                                            placement="bottomLeft"

                                            getPopupContainer={trigger => trigger.parentElement}
                                            title={errors?.startDate}
                                        >
                                            <DatePicker
                                                value={values.startDate ? moment(values.startDate) : ""}
                                                format={"MM/DD/YYYY"}
                                                allowClear={false}
                                                disabled={Boolean(nominatedCount)}
                                                disabledDate={disabledStartDate}
                                                placeholder="Select Start Date"
                                                onChange={(event) => {
                                                    let startDate = new Date(event)
                                                    setFieldValue("startDate", startDate.toDateString())
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                    <div className="d-flex flex-column" style={{ width: "100%" }}>
                                        <Tooltip
                                            overlayClassName="error-tooltip"
                                            open={submitCount > 0 && errors && Boolean(errors?.endDate)}
                                            placement="bottomLeft"

                                            getPopupContainer={trigger => trigger.parentElement}
                                            title={errors?.endDate}
                                        >
                                            <DatePicker
                                                value={values.endDate ? moment(values.endDate) : ""}
                                                format={"MM/DD/YYYY"}
                                                allowClear={false}
                                                disabledDate={disabledStartDate}
                                                placeholder="Select End Date"
                                                onChange={(event) => {
                                                    let endDate = new Date(event)
                                                    setFieldValue("endDate", endDate.toDateString())
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                        <div className="custom-form-group">
                            <Form.Item>
                                <label className="custom-label">How Many Awardees : <span className="asterisk">*</span></label>
                                <div className="col-sm-7 pl-0 select-height">
                                    <Tooltip
                                        overlayClassName="error-tooltip"
                                        open={submitCount > 0 && errors && Boolean(errors?.nominationCriteria?.nominator?.awardedCount)}
                                        placement="bottomLeft"

                                        getPopupContainer={trigger => trigger.parentElement}
                                        title={errors?.nominationCriteria?.nominator?.awardedCount}
                                    >
                                        <Input
                                            type="number"
                                            min={1}
                                            max={999}
                                            value={values.nominationCriteria.nominator.awardedCount}
                                            placeholder="How Many Awardees..."
                                            onChange={(event) => {
                                                setFieldValue("nominationCriteria.nominator.awardedCount", event.target.value)
                                            }}
                                            className="custom-input py-0"
                                        />
                                    </Tooltip>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </div >

                <div className="row">
                    <div className="col-12">
                        <label className="custom-label mb-0 w-100 text-center font-weight-bolder sub-custom-label">
                            Nomination Settings
                        </label>
                    </div>
                    <div className="col-xl-6 col-lg-4 col-12 pr-0">
                        <div>
                            <label className="font-weight-bold">
                                Who Can Nominate?
                            </label>
                        </div>
                        <div className="custom-form-group">
                            <Form.Item>
                                <label className="custom-label">Employee Level(s) : </label>
                                <div className="col-sm-7 pl-0 reward-select-input select-height">
                                    <Select
                                        mode="multiple"
                                        disabled={disabledFormEdit}
                                        className="custom-input choose-reward-input"
                                        loading={isLoading}
                                        placeholder="Employee Level(s)"
                                        value={values.nominationCriteria.nominator.employeeLevelId || []}
                                        onChange={(event) => {
                                            setFieldValue("nominationCriteria.nominator.employeeLevelId", event)
                                        }}
                                    >
                                        {empLevel?.map(({ level, id }) => {
                                            return <Select.Option key={Math.random()} value={id}>{level}</Select.Option>
                                        })}
                                    </Select>
                                </div>
                            </Form.Item>
                        </div>
                        <div className="custom-form-group">
                            <Form.Item>
                                <label className="custom-label">Employee Pay Type : </label>
                                <div className="col-sm-7 pl-0 reward-select-input select-height">
                                    <Select
                                        disabled={disabledFormEdit}
                                        className="custom-input choose-reward-input"
                                        loading={isLoading}
                                        placeholder="Employee Pay Type"
                                        value={values.nominationCriteria.nominator.employeePayTypeId}
                                        onChange={(event) => {
                                            setFieldValue("nominationCriteria.nominator.employeePayTypeId", event)
                                        }}
                                    >
                                        <Select.Option disabled value={""}>Employee Pay Type</Select.Option>
                                        {empPayType?.map(({ payType, id }) => {
                                            return <Select.Option key={Math.random()} value={id}>{payType}</Select.Option>
                                        })}
                                    </Select>
                                </div>
                            </Form.Item>
                        </div>
                        <div className="custom-form-group">
                            <Form.Item>
                                <label className="custom-label">SSO Login Required : </label>
                                <div className="col-sm-7 pl-0 reward-select-input select-height">
                                    <Select
                                        disabled={disabledFormEdit}
                                        className="custom-input choose-reward-input"
                                        loading={isLoading}
                                        placeholder="Employee Login Type"
                                        value={values.nominationCriteria.nominator.employeelogInTypeId}
                                        onChange={(event) => {
                                            setFieldValue("nominationCriteria.nominator.employeelogInTypeId", event)
                                        }}
                                    >
                                        <Select.Option disabled value={""}>Employee Login Type</Select.Option>
                                        {empLoginType?.map(({ logInType, id }) => {
                                            return <Select.Option key={Math.random()} value={id}>{logInType}</Select.Option>
                                        })}
                                    </Select>
                                </div>
                            </Form.Item>
                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-4 col-12 pr-0">
                        <div>
                            <label className="font-weight-bold">
                                Who Can Be Nominated?
                            </label>
                        </div>
                        <div className="custom-form-group">
                            <Form.Item>
                                <label className="custom-label">Employee Level(s) : </label>
                                <div className="col-sm-7 pl-0 reward-select-input select-height">
                                    <Select
                                        disabled={disabledFormEdit}
                                        mode="multiple"
                                        className="custom-input choose-reward-input"
                                        loading={isLoading}
                                        placeholder="Employee Level(s)"
                                        value={values.nominationCriteria.nominee.employeeLevelId || []}
                                        onChange={(event) => {
                                            setFieldValue("nominationCriteria.nominee.employeeLevelId", event)
                                        }}
                                    >
                                        {empLevel?.map(({ level, id }) => {
                                            return <Select.Option key={Math.random()} value={id}>{level}</Select.Option>
                                        })}
                                    </Select>
                                </div>
                            </Form.Item>
                        </div>
                        <div className="custom-form-group">
                            <Form.Item>
                                <label className="custom-label">Employee Pay Type : </label>
                                <div className="col-sm-7 pl-0 reward-select-input select-height">
                                    <Select
                                        disabled={disabledFormEdit}
                                        className="custom-input choose-reward-input"
                                        loading={isLoading}
                                        placeholder="Employee Pay Type"
                                        value={values.nominationCriteria.nominee.employeePayTypeId}
                                        onChange={(event) => {
                                            setFieldValue("nominationCriteria.nominee.employeePayTypeId", event)
                                        }}
                                    >
                                        <Select.Option disabled value={""}>Employee Pay Type</Select.Option>
                                        {empPayType?.map(({ payType, id }) => {
                                            return <Select.Option key={Math.random()} value={id}>{payType}</Select.Option>
                                        })}
                                    </Select>
                                </div>
                            </Form.Item>
                        </div>
                        <div className="custom-form-group">
                            <Form.Item>
                                <label className="custom-label">SSO Login Required : </label>
                                <div className="col-sm-7 pl-0 reward-select-input select-height">
                                    <Select
                                        disabled={disabledFormEdit}
                                        className="custom-input choose-reward-input"
                                        loading={isLoading}
                                        placeholder="Employee Login Type"
                                        value={values.nominationCriteria.nominee.employeelogInTypeId}
                                        onChange={(event) => {
                                            setFieldValue("nominationCriteria.nominee.employeelogInTypeId", event)
                                        }}
                                    >
                                        <Select.Option disabled value={""}>Employee Login Type</Select.Option>
                                        {empLoginType?.map(({ logInType, id }) => {
                                            return <Select.Option key={Math.random()} value={id}>{logInType}</Select.Option>
                                        })}
                                    </Select>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row submit-button">
                    {showLoader ? <Spin /> : <Button onClick={() => { handleSubmit() }} type="primary">{create ? "Create" : "Update"}</Button>}
                </div>
            </FormikProvider >
        </>
    )
}

export default ManageNominatedRewardModal