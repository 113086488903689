import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import ProgressButton from 'react-progress-button';
import { ml } from 'src/_shared/services/utils';
import { JOB_STATUSES } from '../../../../_shared/constants';

const FormItem = Form.Item;

class CloseJobForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonState: '',
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { job, referrals } = this.props;
		this.props.form.validateFieldsAndScroll((error, values) => {
			if (error) {
				this.setState({ buttonState: 'error' });
			} else {
				this.setState({ buttonState: 'loading' });
				if (values.updateOtherReferrals) {
					for (const referral of referrals) {
						this.props.onUpdateReferral({
							input: {
								id: referral.id,
								status: 'notHired',
							},
						});
					}
				}

				this.props.onUpdateJob({
					id: job.id,
					status: JOB_STATUSES.CLOSED,
					jobType: job.jobType,
				});
				setTimeout(() => {
					this.setState({ buttonState: 'success' });
					this.props.onCancel();
				}, 2000);
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { theme } = this.state;
		const { allMultiLingualData, currentUser } = this.props;
		return (
			<Form>
				<div className="custom-gorm-group text-center">
					{getFieldDecorator('updateOtherReferrals', {
						valuePropName: 'checked',
					})(
						<Checkbox>
							{ml(
								'Update referrals for this job as',
								currentUser,
								allMultiLingualData
							)}{' '}
							<span style={{ fontWeight: 700 }}>
								{ml('Not Hired', currentUser, allMultiLingualData)}
							</span>
						</Checkbox>
					)}
				</div>

				<div className="modal-footer-btn">
					<ProgressButton
						durationSuccess={1}
						type="submit"
						state={this.state.buttonState}
						onClick={this.handleSubmit}
					>
						{ml('Close Job', currentUser, allMultiLingualData)}
					</ProgressButton>
				</div>
			</Form>
		);
	}
}

export default Form.create()(CloseJobForm);
