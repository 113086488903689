import get from 'lodash/get';
import { connect } from 'react-redux';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import { compose } from 'src/_shared/services/utils';
import MyReferralCardComponent from './MyReferralCardComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;

	return {
		companyId: currentUser.companyId,
		tieredBonusQuery: {
			companyId: currentUser.companyId,
			id: get(
				ownProperties,
				'referral.job.referralBonus.tieredBonusId',
				'skip'
			),
		},
	};
};

const mapDispatchToProperties = () => {
	return {};
};

export default connect(
	mapStateToProperties,
	mapDispatchToProperties
)(compose(withGetTieredBonus)(MyReferralCardComponent));
