import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { downloadFromS3, query } from '../_shared/services/utils';
import NewExtendedUserForm from './new-user-items/NewExtendedUserFormComponent.jsx';
import { LandingPageContainer, Logo } from './newUserLandingStyles.js';

class NewExtendedUserLandingPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accountClaim: get(props, 'location.state.accountClaim'),
			departments: null,
			company: get(
				props,
				'currentUser.company',
				get(
					props,
					'location.state.accountClaim.company',
					get(props, 'contact.company', get(props, 'company'))
				)
			),
			loaded: false,
		};
	}

	async componentDidMount() {
		const { id } = this.props.match.params;
		const contact = await query({ id }, 'getContact');
		if (contact) {
			const companyId = get(contact, 'companyId');
			const departments = await query(
				{ companyId, first: 500 },
				'queryDepartmentsByCompanyIdIndex'
			);
			const userGroups = await query(
				{ companyId },
				'queryUserGroupsByCompanyIdIndex'
			);
			const defaultUserGroupId = this.getUserGroupId(userGroups, 'Default');
			const noBonusUserGroupId = this.getUserGroupId(userGroups, 'Ineligible');

			const company = get(contact, 'company');
			const logo = get(company, 'logo.key', false);
			const presignedURL = await downloadFromS3(logo);
			const styles = await LandingPageContainer(company);

			this.setState({
				contact,
				defaultUserGroupId,
				departments,
				logoSrc: presignedURL,
				noBonusUserGroupId,
				styles,
				userGroups,
			});
		}
	}

	getUserGroupId = (userGroups, name) => {
		const userGroup = userGroups.find((group) => group.name === name);
		return get(userGroup, 'id');
	};

	render() {
		const { userInvite, setCurrentUser } = this.props;
		const {
			accountClaim,
			defaultUserGroupId,
			departments,
			logoSrc,
			noBonusUserGroupId,
			contact,
			styles,
		} = this.state;
		const company = get(contact, 'company');
		const whiteLabel = get(company, 'whiteLabel');

		if (userInvite && userInvite.userId) {
			this.props.history.push('/login');
		}

		return company ? (
			<div className={styles}>
				{whiteLabel ? (
					<img src={logoSrc} alt="logo" width="350px" />
				) : (
					<img className={Logo} src={ErinLogo} alt="Erin Logo" />
				)}
				<NewExtendedUserForm
					contact={contact}
					whiteLabel={whiteLabel}
					accountClaim={accountClaim}
					defaultUserGroupId={defaultUserGroupId}
					noBonusUserGroupId={noBonusUserGroupId}
					setCurrentUser={setCurrentUser}
					userInvite={userInvite}
					departments={get(
						departments,
						'data.queryDepartmentsByCompanyIdIndex.items',
						[]
					)}
					onCreate={this.props.onCreate}
					onUpdateContact={this.props.onUpdateContact}
				/>
			</div>
		) : null;
	}
}

export default withApollo(NewExtendedUserLandingPageComponent);
