import get from 'lodash/get';
import { connect } from 'react-redux';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import { compose } from '../../_shared/services/utils';
import BonusValueComponent from './BonusValueComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const bonus = get(ownProperties, 'referral.job.referralBonus', 'skip');

	const referralBonus =
		typeof bonus === 'object'
			? bonus.tieredBonusId
			: JSON.parse(bonus).tieredBonusId;

	return {
		tieredBonusQuery: {
			companyId: currentUser.companyId,
			id: referralBonus ? referralBonus : 'skip',
		},
	};
};

export default connect(mapStateToProperties)(
	compose(withGetTieredBonus)(BonusValueComponent)
);
