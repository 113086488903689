import { connect } from 'react-redux';
import { withJobMatchByCompanyId } from 'src/_shared/api/components/dashboard/';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withUserByIdEditProfile } from 'src/_shared/api/components/edit-profile/';
import { withUserById } from 'src/_shared/api/components/employee-details/';
import { withCreateReferral } from 'src/_shared/api/components/referrals/';
import { withListUserInvites } from 'src/_shared/api/components/userInvites/';
import { dashboardActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils';
import { OpenToNewRoleDetailsComponent } from './OpenToNewRolesDetailsComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { currentUser } = state.user;
	const { id } = props.match.params;
	return {
		currentUser,
		id,
		filter: { companyId: { eq: currentUser.companyId } },
		limit: 200,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const OpenToNewRoleDetailsWithApi = compose(
	withUserById,
	withListDepartment,
	withListUserInvites,
	withJobMatchByCompanyId,
	withCreateReferral,
	withUserByIdEditProfile
)(OpenToNewRoleDetailsComponent);

export const OpenToNewRoleDetails = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(OpenToNewRoleDetailsWithApi);
