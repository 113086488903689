import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CopyOutlined } from '@ant-design/icons';
import {
	Alert,
	AutoComplete,
	Button,
	Collapse,
	Input,
	InputNumber,
	Popconfirm,
	Select,
	message,
} from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import ReactGA from 'react-ga';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { REFERRAL_STATUS } from 'src/_shared/constants/';
import uuid from 'uuid/v4';
import fileIcon from '../../_shared/assets/erin_lightgray.png';
import {
	downloadFromS3,
	ml,
	parse,
	sanitize,
} from '../../_shared/services/utils.js';
import { BonusReportRecipientItem } from '../bonus-report-recipient-items';
import { RecipientItem } from '../recipient-items';
import { GroupCurrency } from './GroupCurrencyComponent.jsx';
import { GroupMeasurement } from './GroupMeasurementComponent.jsx';
import { TieredBonus } from './TieredBonusComponent.jsx';
import { builderKeys } from './builderKeys.js';
import { BonusLabel, FlexContainer } from './referralTiersSettingsStyles.js';
import { EligibilityCustomItem } from '../eligibility-custom-value-items/EligibilityCustomItemsComponent.jsx';
import { EligibilityCustomValueObject } from '../eligibility-custom-value-items/EligibilityCustomValueObjectComponent.jsx';
import * as SettingStyle from './settingsStyles.js';

export class ReferralTiersSettingsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(this.props, 'currentUser.company'),
			defaultActiveBonusKey: '',
			submitting: false,
			tieredBonuses: this.props.bonuses,
			theme: parse(get(this.props, 'currentUser.company.theme', '{}')),
			ml_Days: '',
			ml_AddBonus: '',
			ml_UpdateBonuses: '',
			ml_BonusBuilder: '',
			ml_BonusName: '',
			ml_Bonuses: '',
			ml_BonusPayments: '',
			ml_DeleteBonus: '',
			ml_After: '',
			ml_AddGroupBonus: '',
			ml_EmployeeGroup: '',
			ml_ChooseEmployeeGroup: '',
			ml_DaysFromHireDate: '',
			ml_BonusAmount: '',
			ml_Candidate: '',
			ml_Employee: '',
			ml_DeletePayment: '',
			ml_Group: '',
			referralBonusNotificationRecipients: get(
				this.props,
				'company.referralBonusNotificationRecipients',
				''
			),
			bonusReportSetting: parse(
				get(this.props, 'company.bonusReportSetting', '{}')
			),
			automationSettings: parse(
				get(this.props, 'company.automationSettings', '{}')
			),
			eligibilityChecks: parse(
				this.props?.company?.eligibiltyChecks ? this.props.company.eligibiltyChecks : '{}'
			),
			
			ownershipSettings: parse(
				get(this.props, 'company.ownershipSettings', '{}')
			),
			emailAddress: '',
			emailAddressLookupData: [],
			emailLookupChildren: [],
			recipientError: false,
			duplicateRecipientError: false,
			deliveryFrequency: [
				{ key: 'Weekly', val: 'Weekly' },
				{ key: 'Daliy', val: 'Daliy' },
				{ key: 'Monthly', val: 'Monthly' },
			],
			monthlyFrequncy: [
				{ key: '01', val: 1 },
				{ key: '02', val: 2 },
				{ key: '03', val: 3 },
				{ key: '04', val: 4 },
				{ key: '05', val: 5 },
				{ key: '06', val: 6 },
				{ key: '07', val: 7 },
				{ key: '08', val: 8 },
				{ key: '09', val: 9 },
				{ key: '10', val: 10 },
				{ key: '11', val: 11 },
				{ key: '12', val: 12 },
				{ key: '13', val: 13 },
				{ key: '14', val: 14 },
				{ key: '15', val: 15 },
				{ key: '17', val: 17 },
				{ key: '16', val: 16 },
				{ key: '18', val: 18 },
				{ key: '19', val: 19 },
				{ key: '20', val: 20 },
				{ key: '21', val: 21 },
				{ key: '22', val: 22 },
				{ key: '23', val: 23 },
				{ key: '24', val: 24 },
				{ key: '25', val: 25 },
				{ key: '26', val: 26 },
				{ key: '27', val: 27 },
				{ key: '28', val: 28 },
			],
			weeklyFrequency: [
				{ key: 'Monday', value: 1 },
				{ key: 'Tuesday', value: 2 },
				{ key: 'Wednesday', value: 3 },
				{ key: 'Thursday', value: 4 },
				{ key: 'Friday', value: 5 },
				{ key: 'Saturday', value: 6 },
				{ key: 'Sunday', value: 7 },
			],
			isEnableBonusReportSetting: false,
			referralBonusReportSettingrecipients: get(
				this.props,
				'company.referralBonusReportSettingrecipients',
				''
			),
			selectedDeliveryFrequency: 'Weekly',
			selectedScheduleTime: '',
			selectedDeliveryDateOption: [
				'Monday',
				'Tuesday',
				'Wednesday',
				'Thursday',
				'Friday',
				'Saturday',
			],
			bonusEmailAddress: '',
			customFieldNameValue: {},
			customFieldObjectInvalidOrValidValue: {},
			stages: [],
		};
	}

	async componentDidMount() {
		const { location } = this.props;
		const { state } = location;
		if (state && state.openModel) {
			this.handleAddNewBonusClick();
		}

		if (state && state.bonusReportSetting == null) {
			this.setState({
				bonusReportSetting: {
					isEnable: false,
					frequency: '',
					time: 1,
					emailAddresses: '',
				},
			});
		}

		const { stages } = get(this.props, 'currentUser.company');
		if (stages != null && stages.length > 0) {
			this.setState({
				stages: JSON.parse(stages),
			});
		}

		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		if (this.props.allMultiLingualData.length > 0) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const days = filteredData.filter((item) => item.key === builderKeys.Days);
			const addBonus = filteredData.filter(
				(item) => item.key === builderKeys.AddBonus
			);
			const updateBonuses = filteredData.filter(
				(item) => item.key === builderKeys.UpdateBonuses
			);
			const bonusBuilder = filteredData.filter(
				(item) => item.key === builderKeys.BonusBuilder
			);
			const bonusName = filteredData.filter(
				(item) => item.key === builderKeys.BonusName
			);
			const bonuses = filteredData.filter(
				(item) => item.key === builderKeys.Bonuses
			);
			const bonusPayments = filteredData.filter(
				(item) => item.key === builderKeys.BonusPayments
			);
			const deleteBonus = filteredData.filter(
				(item) => item.key === builderKeys.DeleteBonus
			);
			const after = filteredData.filter(
				(item) => item.key === builderKeys.After
			);
			const addGroupBonus = filteredData.filter(
				(item) => item.key === builderKeys.AddGroupBonus
			);
			const employeeGroup = filteredData.filter(
				(item) => item.key === builderKeys.EmployeeGroup
			);
			const chooseEmployeeGroup = filteredData.filter(
				(item) => item.key === builderKeys.ChooseEmployeeGroup
			);
			const daysFromHireDate = filteredData.filter(
				(item) => item.key === builderKeys.DaysFromHireDate
			);

			const bonusAmount = filteredData.filter(
				(item) => item.key === builderKeys.BonusAmount
			);
			const candidate = filteredData.filter(
				(item) => item.key === builderKeys.Candidate
			);
			const employee = filteredData.filter(
				(item) => item.key === builderKeys.Employee
			);
			const deletePayment = filteredData.filter(
				(item) => item.key === builderKeys.DeletePayment
			);
			const group = filteredData.filter(
				(item) => item.key === builderKeys.Group
			);
			this.setState({
				ml_Days: days.length > 0 ? days[0].text : 'days, payable to',
				ml_AddBonus: addBonus.length > 0 ? addBonus[0].text : 'Add Bonus',
				ml_UpdateBonuses:
					updateBonuses.length > 0 ? updateBonuses[0].text : 'Update Bonuses',
				ml_BonusBuilder:
					bonusBuilder.length > 0 ? bonusBuilder[0].text : 'Bonus Builder',
				ml_BonusName: bonusName.length > 0 ? bonusName[0].text : 'Bonus Name',
				ml_Bonuses: bonuses.length > 0 ? bonuses[0].text : 'Bonuses',
				ml_BonusPayments:
					bonusPayments.length > 0 ? bonusPayments[0].text : 'Bonus Payments',
				ml_DeleteBonus:
					deleteBonus.length > 0 ? deleteBonus[0].text : 'Delete Bonus',
				ml_After: after.length > 0 ? after[0].text : 'After',
				ml_AddGroupBonus:
					addGroupBonus.length > 0 ? addGroupBonus[0].text : 'Add Group Bonus',
				ml_EmployeeGroup:
					employeeGroup.length > 0 ? employeeGroup[0].text : 'Employee Group',
				ml_ChooseEmployeeGroup:
					chooseEmployeeGroup.length > 0
						? chooseEmployeeGroup[0].text
						: 'Choose An Employee Group',
				ml_DaysFromHireDate:
					daysFromHireDate.length > 0
						? daysFromHireDate[0].text
						: 'Days from hire date',
				ml_BonusAmount:
					bonusAmount.length > 0 ? bonusAmount[0].text : 'Bonus Amount',
				ml_Candidate: candidate.length > 0 ? candidate[0].text : 'Candidate',
				ml_Employee: employee.length > 0 ? employee[0].text : 'Employee',
				ml_DeletePayment:
					deletePayment.length > 0 ? deletePayment[0].text : 'Delete Payment',
				ml_Group: group.length > 0 ? group[0].text : 'Group',
			});
		} else {
			this.setState({
				ml_Days: 'days, payable to',
				ml_AddBonus: 'Add Bonus Tier ',
				ml_UpdateBonuses: 'Update Bonuses',
				ml_BonusBuilder: 'Bonus Builder',
				ml_BonusName: 'Bonus Name',
				ml_Bonuses: 'Bonuses',
				ml_BonusPayments: 'Bonus Payments',
				ml_DeleteBonus: 'Delete Bonus',
				ml_After: 'After',
				ml_AddGroupBonus: 'Add Group Bonus',
				ml_EmployeeGroup: 'Employee Group',
				ml_ChooseEmployeeGroup: 'Choose An Employee Group',
				ml_DaysFromHireDate: 'Days from hire date',
				ml_BonusAmount: 'Bonus Amount',
				ml_Candidate: 'Candidate',
				ml_Employee: 'Employee',
				ml_DeletePayment: 'Delete Payment',
				ml_Group: 'Group',
			});
		}

		let errorImageSource;
		const errorImage = get(this.state.company, 'errorImage.key', false);
		if (errorImage) {
			errorImageSource = await downloadFromS3(errorImage);
		}

		this.setState({ errorImageSrc: errorImageSource });
	}

	componentDidUpdate(prevProps) {
		const { bonuses } = this.props;
		if (prevProps.bonuses !== bonuses) {
			this.setState({ tieredBonuses: bonuses });
		}

		const { company } = this.props;
		if (prevProps.company !== company) {
		this.setState({
			company,
			referralBonusNotificationRecipients: get(
				company,
				'referralBonusNotificationRecipients',
				''
			),
			bonusReportSetting: parse(
				get(company, 'bonusReportSetting', '{}')
			),
			automationSettings: parse(
				get(company, 'automationSettings', '{}')
			),
			eligibilityChecks: parse(
			company?.eligibiltyChecks ? company.eligibiltyChecks : '{}'
			),
			ownershipSettings: parse(
				company?.ownershipSettings ? company.ownershipSettings : '{}'
			),
		});
		}

		if (
			prevProps.currentUser.languageCode !==
				this.props.currentUser.languageCode &&
			this.props.allMultiLingualData.length > 0
		) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const days = filteredData.filter((item) => item.key === builderKeys.Days);
			const addBonus = filteredData.filter(
				(item) => item.key === builderKeys.AddBonus
			);
			const updateBonuses = filteredData.filter(
				(item) => item.key === builderKeys.UpdateBonuses
			);
			const bonusBuilder = filteredData.filter(
				(item) => item.key === builderKeys.BonusBuilder
			);
			const bonusName = filteredData.filter(
				(item) => item.key === builderKeys.BonusName
			);
			const bonuses = filteredData.filter(
				(item) => item.key === builderKeys.Bonuses
			);
			const bonusPayments = filteredData.filter(
				(item) => item.key === builderKeys.BonusPayments
			);
			const deleteBonus = filteredData.filter(
				(item) => item.key === builderKeys.DeleteBonus
			);
			const after = filteredData.filter(
				(item) => item.key === builderKeys.After
			);
			const addGroupBonus = filteredData.filter(
				(item) => item.key === builderKeys.AddGroupBonus
			);
			const employeeGroup = filteredData.filter(
				(item) => item.key === builderKeys.EmployeeGroup
			);
			const chooseEmployeeGroup = filteredData.filter(
				(item) => item.key === builderKeys.ChooseEmployeeGroup
			);
			const daysFromHireDate = filteredData.filter(
				(item) => item.key === builderKeys.DaysFromHireDate
			);
			const bonusAmount = filteredData.filter(
				(item) => item.key === builderKeys.BonusAmount
			);
			const candidate = filteredData.filter(
				(item) => item.key === builderKeys.Candidate
			);
			const employee = filteredData.filter(
				(item) => item.key === builderKeys.Employee
			);
			const deletePayment = filteredData.filter(
				(item) => item.key === builderKeys.DeletePayment
			);
			const group = filteredData.filter(
				(item) => item.key === builderKeys.Group
			);
			this.setState({
				ml_Days: days.length > 0 ? days[0].text : 'days, payable to',
				ml_AddBonus: addBonus.length > 0 ? addBonus[0].text : 'Add Bonus Tier ',
				ml_UpdateBonuses:
					updateBonuses.length > 0 ? updateBonuses[0].text : 'Update Bonuses',
				ml_BonusBuilder:
					bonusBuilder.length > 0 ? bonusBuilder[0].text : 'Bonus Builder',
				ml_BonusName: bonusName.length > 0 ? bonusName[0].text : 'Bonus Name',
				ml_Bonuses: bonuses.length > 0 ? bonuses[0].text : 'Bonuses',
				ml_BonusPayments:
					bonusPayments.length > 0 ? bonusPayments[0].text : 'Bonus Payments',
				ml_DeleteBonus:
					deleteBonus.length > 0 ? deleteBonus[0].text : 'Delete Bonus',
				ml_After: after.length > 0 ? after[0].text : 'After',
				ml_AddGroupBonus:
					addGroupBonus.length > 0 ? addGroupBonus[0].text : 'Add Group Bonus',
				ml_EmployeeGroup:
					employeeGroup.length > 0 ? employeeGroup[0].text : 'Employee Group',
				ml_ChooseEmployeeGroup:
					chooseEmployeeGroup.length > 0
						? chooseEmployeeGroup[0].text
						: 'Choose An Employee Group',
				ml_DaysFromHireDate:
					daysFromHireDate.length > 0
						? daysFromHireDate[0].text
						: 'Days from hire date',
				ml_BonusAmount:
					bonusAmount.length > 0 ? bonusAmount[0].text : 'Bonus Amount',
				ml_Candidate: candidate.length > 0 ? candidate[0].text : 'Candidate',
				ml_Employee: employee.length > 0 ? employee[0].text : 'Employee',
				ml_DeletePayment:
					deletePayment.length > 0 ? deletePayment[0].text : 'Delete Payment',
				ml_Group: group.length > 0 ? group[0].text : 'Group',
			});
		}
	}

	addEmailToBonusReport = () => {
		const { bonusReportSetting, company, bonusEmailAddress } = this.state;
		const { onUpdateCompany } = this.props;
		const emailAddresses =
			bonusReportSetting && bonusReportSetting.emailAddresses
				? bonusReportSetting.emailAddresses.split(',')
				: [];

		if (bonusEmailAddress && !emailAddresses.includes(bonusEmailAddress)) {
			emailAddresses.push(bonusEmailAddress);
			bonusReportSetting.emailAddresses = emailAddresses.join(',');
			this.setState(
				{
					bonusReportSetting,
					bonusEmailAddress: '',
				},
				() => {
					onUpdateCompany({
						input: {
							id: company.id,
							bonusReportSetting: JSON.stringify(this.state.bonusReportSetting),
						},
					});
				}
			);
		}
	};

	addCustomValueFieldName = () => {
		const { eligibilityChecks, company, customFieldNameValue } = this.state;
		const { onUpdateCompany } = this.props;
		const fieldName = customFieldNameValue.name;
		const fieldValue = customFieldNameValue.value;
		const fieldType = customFieldNameValue.type;

		if(fieldType == 'object'){
			const customValuesToCheck =
			eligibilityChecks && eligibilityChecks.CustomValuesToCheck
					? eligibilityChecks.CustomValuesToCheck
					: [];
	
			if (fieldValue && !customValuesToCheck.find((element)=>element.name == fieldValue)) {
				let newElement = {name: fieldValue}
				customValuesToCheck.push(newElement);
				eligibilityChecks.CustomValuesToCheck = customValuesToCheck;
				this.setState(
					{
						eligibilityChecks,
						customFieldNameValue: '',
					},
					() => {
						onUpdateCompany({
							input: {
								id: company.id,
								eligibiltyChecks: JSON.stringify(this.state.eligibilityChecks),
							},
						});
					}
				);
			}
		}
		else if(fieldType == 'string'){
			const customValuesToCheck =
			eligibilityChecks && eligibilityChecks[fieldName]
					? eligibilityChecks[fieldName].split('|')
					: [];
	
			if (customFieldNameValue && !customValuesToCheck.indexOf(fieldValue) > -1) {
				customValuesToCheck.push(fieldValue)
				eligibilityChecks[fieldName] = customValuesToCheck.join('|');
				this.setState(
					{
						eligibilityChecks,
						customFieldNameValue: {},
					},
					() => {
						onUpdateCompany({
							input: {
								id: company.id,
								eligibiltyChecks: JSON.stringify(this.state.eligibilityChecks),
							},
						});
					}
				);
			}
		}
		
	};

	handleCreateCustomValueValid = () => {
		const { eligibilityChecks, company, selectedCustom, customFieldObjectInvalidOrValidValue } = this.state;
		const { onUpdateCompany } = this.props;
		const newValid = customFieldObjectInvalidOrValidValue.value
		if(selectedCustom.validValues){
			let currentValids = selectedCustom.validValues.split('|')
			if(newValid && !currentValids.includes(newValid) && newValid.trim() != ''){
				currentValids.push(newValid)
			}
			selectedCustom.validValues = currentValids.join('|')
		}
		else{
			if(newValid && newValid.trim() != ''){
				selectedCustom.validValues = newValid
			}
		}

		eligibilityChecks.CustomValuesToCheck.find((f)=>f.name == selectedCustom.name).validValues = selectedCustom.validValues;

		this.setState(
			{
				eligibilityChecks,
				customFieldObjectInvalidOrValidValue: {},
			},
			() => {
				onUpdateCompany({
					input: {
						id: company.id,
						eligibiltyChecks: JSON.stringify(this.state.eligibilityChecks),
					},
				});
			}
		);

	}

	handleCreateCustomValueInvalid = () => {
		const { eligibilityChecks, company, selectedCustom, customFieldObjectInvalidOrValidValue } = this.state;
		const { onUpdateCompany } = this.props;
		const newInvalid = customFieldObjectInvalidOrValidValue.value
		if(selectedCustom.invalidValues){
			let currentInvalids = selectedCustom.invalidValues.split('|')
			if(newInvalid && !currentInvalids.includes(newInvalid) && newInvalid.trim() != ''){
				currentInvalids.push(newInvalid)
			}
			selectedCustom.invalidValues = currentInvalids.join('|')
		}
		else{
			if(newInvalid && newInvalid.trim() != ''){
				selectedCustom.invalidValues = newInvalid
			}
		}

		eligibilityChecks.CustomValuesToCheck.find((f)=>f.name == selectedCustom.name).invalidValues = selectedCustom.invalidValues;

		this.setState(
			{
				eligibilityChecks,
				customFieldObjectInvalidOrValidValue: {},
			},
			() => {
				onUpdateCompany({
					input: {
						id: company.id,
						eligibiltyChecks: JSON.stringify(this.state.eligibilityChecks),
					},
				});
			}
		);

	}

	handleAddNewBonusClick = () => {
		const { tieredBonuses } = this.state;
		const index = tieredBonuses.findIndex((x) => x.id.length < 32);
		if (index >= 0) return;

		const newId = 'new' + Date.now();
		if (tieredBonuses) {
			this.setState({
				defaultActiveBonusKey: newId,
				tieredBonuses: [
					{
						id: newId,
						companyId: this.props.companyId,
						name: '',
						tiers: [],
					},
					...tieredBonuses,
				],
			});
		} else {
			this.setState({
				defaultActiveBonusKey: newId,
				tieredBonuses: [
					{
						id: newId,
						companyId: this.props.companyId,
						name: '',
						tiers: [],
					},
				],
			});
		}
	};

	handleAddStage = (index) => {
		const { stages } = this.state;
		const nextStage = {};
		nextStage['stage' + index] = '';
		this.setState({
			stages: [...stages, nextStage],
		});
	};

	handleAutomationSettings = (value, setting) => {
		const { onUpdateCompany } = this.props;

		this.setState(
			(state, props) => ({
				automationSettings: {
					...state.automationSettings,
					[setting]: value,
				},
			}),
			() => {
				onUpdateCompany({
					input: {
						id: this.state.company.id,
						automationSettings: JSON.stringify(this.state.automationSettings),
					},
				});
			}
		);
	};

	handleBonusFrequency = (value) => {
		const { bonusReportSetting, company } = this.state;
		const { onUpdateCompany } = this.props;
		bonusReportSetting.frequency = value;
		bonusReportSetting.time = 1;
		this.setState(
			{
				bonusReportSetting,
			},
			() => {
				onUpdateCompany({
					input: {
						id: company.id,
						bonusReportSetting: JSON.stringify(this.state.bonusReportSetting),
					},
				});
			}
		);
		let selectedDeliveryDateOption = false;
		if (value === 'Weekly') {
			selectedDeliveryDateOption = true;
		} else if (value === 'Monthly') {
			selectedDeliveryDateOption = true;
		}

		this.setState({
			selectedDeliveryDateOption,
		});
	};

	handleBonusReportStatusonChange = (value) => {
		let { bonusReportSetting, company } = this.state;
		const { onUpdateCompany } = this.props;

		bonusReportSetting = {
			isEnable:
				bonusReportSetting && bonusReportSetting.isEnable
					? !bonusReportSetting.isEnable
					: true,
			frequency:
				bonusReportSetting && bonusReportSetting.frequency
					? bonusReportSetting.frequency
					: 'Monthly',
			time:
				bonusReportSetting && bonusReportSetting.time
					? bonusReportSetting.time
					: '1',
			emailAddresses:
				bonusReportSetting && bonusReportSetting.emailAddresses
					? bonusReportSetting.emailAddresses
					: '',
		};
		this.setState(
			{
				bonusReportSetting,
			},
			() => {
				onUpdateCompany({
					input: {
						id: company.id,
						bonusReportSetting: JSON.stringify(this.state.bonusReportSetting),
					},
				});
			}
		);
	};

	handleEligibilityGranularityCheckOnChange = (value) => {
		let { eligibilityChecks, company } = this.state;
		const { onUpdateCompany } = this.props;
		if(value.target.type == 'checkbox'){
			const typeValues = value.target.id.split('-');
			const field = typeValues[0];
			const granularity = typeValues[1];
			const currentValues = eligibilityChecks[field] ? eligibilityChecks[field].split('|') : [];
			if(value.target.checked){
				currentValues.push(granularity)
			}
			else{
				let index = currentValues.indexOf(granularity); 
				if (index > -1) {
					currentValues.splice(index, 1); 
				}
			}
			eligibilityChecks[field]=currentValues.join('|')

			this.setState(
				{
					eligibilityChecks,
				},
				() => {
					onUpdateCompany({
						input: {
							id: company.id,
							eligibiltyChecks: JSON.stringify(this.state.eligibilityChecks),
						},
					});
				}
			);
		}

	}

	handleEligibilityCheckOnChange = (value) => {
		let { eligibilityChecks, company } = this.state;
		const { onUpdateCompany } = this.props;
		let newValue;
		if(value.target.type == 'checkbox'){
			newValue = !eligibilityChecks[value.target.id];
		}
		if(value.target.type == 'text' && value.target.value && value.target.value.trim() != ''){
			newValue = value.target.value.trim()
		}
		if(newValue != null){
			eligibilityChecks[value.target.id] = newValue
		}
		this.setState(
			{
				eligibilityChecks,
			},
			() => {
				onUpdateCompany({
					input: {
						id: company.id,
						eligibiltyChecks: JSON.stringify(this.state.eligibilityChecks),
					},
				});
			}
		);
	};

	handleFilterCustomValue = (name) => {
		const { eligibilityChecks } = this.state;
		const customValue = eligibilityChecks.CustomValuesToCheck.find((x) => {
			return x.name == name;
		});
		this.setState({
			selectedCustom: customValue,
		});
	};

	handleBonusTime = (value) => {
		const { bonusReportSetting, company } = this.state;
		const { onUpdateCompany } = this.props;
		bonusReportSetting.time = value;
		this.setState(
			{
				bonusReportSetting,
			},
			() => {
				onUpdateCompany({
					input: {
						id: company.id,
						bonusReportSetting: JSON.stringify(this.state.bonusReportSetting),
					},
				});
			}
		);
	};

	handleDeleteTieredBonus = (bonusId) => {
		this.setState({ submitting: true });
		const { onUpdateTieredBonus } = this.props;
		const { tieredBonuses } = this.state;

		const copy = [...tieredBonuses];
		const index = copy.findIndex((x) => x.id.length < 32);
		if (index >= 0) copy.splice(index, 1);
		const index_ = copy.findIndex((x) => x.id === bonusId);
		if (index_ >= 0) copy.splice(index_, 1);
		if (index_ >= 0) {
			tieredBonuses[index_].archived = true;
			delete tieredBonuses[index_].__typename;
			onUpdateTieredBonus(tieredBonuses[index_]).then(() => {
				this.setState({
					tieredBonuses: copy,
					defaultActiveBonusKey: undefined,
					submitting: false,
				});
				message.success('Bonus deleted');
			});
		} else {
			this.setState({
				tieredBonuses: copy,
				defaultActiveBonusKey: undefined,
				submitting: false,
			});
		}
	};

	handleDuplicateRecipientError = () => {
		this.setState({ duplicateRecipientError: true });
	};

	handleEmailChange = (value) => {
		value &&= sanitize(value);
		const recipient = this.state.referralBonusNotificationRecipients
			? this.state.referralBonusNotificationRecipients.split(',')
			: [];

		const companyDefaultEmail = recipient[0]
			? recipient[0].slice(recipient[0].indexOf('@'), recipient[0].length)
			: '';

		const emailAddressList =
			!value || value.includes('@')
				? []
				: [
						`${value}@gmail.com`,
						`${value}@hotmail.com`,
						`${value}@outlook.com`,
						`${value}@yahoo.com`,
					];

		if (
			!emailAddressList.includes(`${value}${companyDefaultEmail}`) &&
			emailAddressList.length > 0
		) {
			emailAddressList.unshift(`${value}${companyDefaultEmail}`);
		}

		const children = this.state.emailAddressLookupData.map((email) => (
			<AutoComplete.Option key={email}>{email}</AutoComplete.Option>
		));

		this.setState({
			emailAddressLookupData: emailAddressList,
			emailLookupChildren: children,
			emailAddress: value.toLowerCase(),
		});
	};

	handleNewBonusCustomValidator = (event) => {
		let value = event.target.value ? event.target.value.toLowerCase() : '';
		value &&= sanitize(value);
		this.setState({ bonusEmailAddress: value });
	};

	handleNewEligibilityCustomValidator = (event) => {
		let value = event.target.value ? event.target.value : '';
		let name = event.target.id ? event.target.id : '';
		let type = event.target.name ? event.target.name : '';
		value &&= sanitize(value);
		this.setState({ customFieldNameValue: {name, value, type} });
	};

	handleNewEligibilityCustomValueVluesValidator = (event) => {
		let value = event.target.value ? event.target.value : '';
		let name = event.target.id ? event.target.id : '';
		let type = event.target.name ? event.target.name : '';
		value &&= sanitize(value);
		this.setState({ customFieldObjectInvalidOrValidValue: {name, value, type} });
	};

	handleOnSubmit = (e) => {
		e.preventDefault();
		if (this.state.submitting) return;

		const { form } = this.props;
		form.validateFields((error, values) => {
			if (error) {
				return;
			}

			const bonusMap = new Map();
			const fields = Object.keys(values);
			const newId = uuid();
			this.setState({ newId });
			const handleBonus = (field) => {
				const token = 'tieredBonus-';
				if (!field.startsWith(token)) {
					return;
				}

				const bonusKey = field.slice(token.length);
				let name = values[field];
				name &&= sanitize(name);
				bonusMap.set(bonusKey, {
					id: newId,
					name,
					companyId: this.props.companyId,
					...(bonusKey.startsWith('new') ? {} : { id: bonusKey }),
				});
			};

			const getExclusions = (tField) => {
				const filteredFields = [];
				const trim = tField.split('-');
				const targetField = trim.slice(0, -2).join('-');
				for (const f of fields) {
					if (
						targetField !== '' &&
						f.startsWith(targetField) &&
						f.endsWith('-exclusions')
					)
						filteredFields.push(f);
				}

				const results = [];
				for (const field of filteredFields) {
					if (results.length === 0) results.push(...values[field]);
				}

				return results;
			};

			const getInclusions = (tField) => {
				const filteredFields = [];
				const trim = tField.split('-');
				const targetField = trim.slice(0, -2).join('-');
				for (const f of fields) {
					if (
						targetField !== '' &&
						f.startsWith(targetField) &&
						f.endsWith('-inclusions')
					)
						filteredFields.push(f);
				}

				const results = [];
				for (const field of filteredFields) {
					if (results.length === 0) results.push(...values[field]);
				}

				return results;
			};

			const handleTier = (field, exclusions, inclusions) => {
				const token = 'tier-';
				const payOutDaysToken = '-payoutdays';
				const recipientTypeToken = '-recipienttype';
				const userGroupToken = '-usergroup';
				const diversityIncentiveToken = '-diversityincentive';
				const exclusionsToken = '-exclusions';
				const exclusionsCategoryToken = '-exclusionscategory';
				const inclusionsToken = '-inclusions';
				const inclusionsCategoryToken = '-inclusionscategory';
				const fieldsToSkip =
					!field.startsWith(token) ||
					field.endsWith(payOutDaysToken) ||
					field.endsWith(recipientTypeToken) ||
					field.endsWith(userGroupToken) ||
					field.endsWith(diversityIncentiveToken) ||
					field.endsWith(exclusionsToken) ||
					field.endsWith(exclusionsCategoryToken) ||
					field.endsWith(inclusionsToken) ||
					field.endsWith(inclusionsCategoryToken);
				if (fieldsToSkip) return;

				const end = field.lastIndexOf('-');
				const bonusKey = field.substring(token.length, end);
				const bonus = bonusMap.get(bonusKey);
				const payOutDaysKey = fields.find(
					(f) => f.startsWith(field) && f.endsWith(payOutDaysToken)
				);
				const recipientTypeKey = fields.find(
					(f) => f.startsWith(field) && f.endsWith(recipientTypeToken)
				);
				const userGroupKey = fields.find(
					(f) => f.startsWith(field) && f.endsWith(userGroupToken)
				);
				const diversityGroupKey = fields.find(
					(f) => f.startsWith(field) && f.endsWith(diversityIncentiveToken)
				);
				const userGroup = values[userGroupKey];

				const tierValues = {
					amount: values[field],
					payOutDays: values[payOutDaysKey],
					recipientType: values[recipientTypeKey],
					userGroup,
					rules: {},
				};
				if (diversityGroupKey)
					tierValues.rules.diversityHire = {
						active: values[diversityGroupKey] >= 0,
						amount: values[diversityGroupKey],
					};
				if (exclusions) {
					tierValues.rules.exclusions = exclusions;
				}

				if (inclusions) {
					tierValues.rules.inclusions = inclusions;
				}

				const tier = JSON.stringify(tierValues);
				if (bonus.tiers) {
					bonus.tiers.push(tier);
				} else {
					bonus.tiers = [tier];
				}
			};

			for (const key of fields) {
				const exclusions = getExclusions(key);
				const inclusions = getInclusions(key);
				handleBonus(key);
				handleTier(key, exclusions, inclusions);
			}

			const addPromises = [...bonusMap].map(async (bonus) => {
				bonus = bonus[1];
				if (bonus.id && bonus.id.startsWith('new')) {
					delete bonus.id;
				}

				if (bonus.id) {
					return this.props.onUpdateTieredBonus(bonus);
				}

				return this.props.onAddTieredBonus(bonus);
			});

			return Promise.all(addPromises).then((resp) => {
				const { tieredBonuses } = this.state;
				const bonus = resp[0].data.updateTieredBonus;
				const copy = [...tieredBonuses];
				const index = copy.findIndex((x) => x.id.length < 32);
				if (index >= 0) copy.splice(index, 1);
				const duplicateIndex = copy.findIndex((x) => x.id === bonus.id);
				if (duplicateIndex >= 0) copy.splice(duplicateIndex, 1);

				const newBonuses = [bonus, ...copy];
				this.setState({ tieredBonuses: newBonuses });
				this.setState({ submitting: false });
				message.success('Your data was saved');
				return resp;
			});
		});
	};

	handleOwnershipSettings = (value, type) => {
		const { onUpdateCompany } = this.props;

		const { company } = this.state;
		let ownershipSettings = get(this.state, 'ownershipSettings', {});
		ownershipSettings = ownershipSettings ? ownershipSettings : {};
		switch (type) {
			case 'enabled': {
				ownershipSettings.enabled = get(value, 'target.checked');

				break;
			}

			case 'days': {
				ownershipSettings.days = value;

				break;
			}

			case 'rollingOwnershipDates': {
				ownershipSettings.rollingOwnershipDates = value.target ? value.target.checked : value;

				break;
			}
			// No default
		}

		this.setState(
			{
				ownershipSettings,
			},
			() => {
				onUpdateCompany({
					input: {
						id: company.id,
						ownershipSettings: JSON.stringify(this.state.ownershipSettings),
					},
				});
			}
		);
	};

	handleRecipientError = () => {
		this.setState({ recipientError: true });
	};

	handleRemoveBonusClick = (itemIndex) => {
		const { tieredBonuses } = this.state;
		const copy = [...tieredBonuses];
		copy.splice(itemIndex, 1);
		this.setState({
			tieredBonuses: copy,
		});
	};

	handleRemoveStageClick = (itemIndex) => {
		const { stages } = this.state;
		const copy = [...stages];
		let newValue = copy[itemIndex + 1];
		if (newValue) {
			copy.splice(itemIndex, 1);
			for (let i = itemIndex + 1; i <= copy.length; i++) {
				const nextStage = {};
				nextStage[`stage${i}`] = newValue[`stage${i + 1}`];
				copy[i - 1] = nextStage;
				newValue = copy[i];
			}
		} else {
			copy.splice(itemIndex, 1);
		}

		this.setState({
			stages: copy,
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const { onUpdateCompany, form } = this.props;

		const { themeError } = this.state;
		const { referralBonusNotificationRecipients, emailAddress, company } =
			this.state;

		const isDuplicateRecipient = referralBonusNotificationRecipients
			? referralBonusNotificationRecipients.includes(emailAddress)
			: false;

		if (emailAddress && !isDuplicateRecipient) {
			let recipients = referralBonusNotificationRecipients
				? referralBonusNotificationRecipients.split(',')
				: referralBonusNotificationRecipients.split(',');

			if (
				!recipients.includes(emailAddress) &&
				this.validateEmail(emailAddress)
			) {
				recipients.push(emailAddress);
			}

			const spaceIndex = recipients.indexOf(' ');
			if (spaceIndex > -1) {
				recipients.splice(spaceIndex, 1);
			}

			recipients = recipients.join(',');
			if (recipients === '') {
				recipients = ' ';
			}

			this.setState(
				{
					referralBonusNotificationRecipients: recipients,
					emailAddress: '',
				},
				() => {
					onUpdateCompany({
						input: {
							id: company.id,
							referralBonusNotificationRecipients: recipients,
						},
					});

					this.handleEmailChange('');
				}
			);
		} else if (this.state.recipientAddress && isDuplicateRecipient) {
			this.handleDuplicateRecipientError();
			return;
		}

		form.resetFields();
	};

	handleSubmitClick = (e) => {
		this.setState({ submitting: true }, this.handleOnSubmit(e));
		this.setState({ submitting: false });
	};

	handleSubmitStages = async (e) => {
		e.preventDefault();
		const { onUpdateCompany } = this.props;
		const { company, stages } = this.state;
		const existingStages = [];
		this.props.form.validateFields(async (error, values) => {
			if (error) {
				return;
			}

			let referralStatus = values.interviewing;
			referralStatus &&= sanitize(referralStatus);
			let stageData = {};
			for (let i = 1; i <= stages.length; i++) {
				let value = values[`stage-${i}`];
				value &&= sanitize(value);

				stageData = {
					[`stage${i}`]: value,
				};
				existingStages.push(stageData);
			}

			const referralCustomStatuses = { ...REFERRAL_STATUS };
			const currentMapping = get(this.props, 'company.referralCustomStatuses')
				? {
						...REFERRAL_STATUS,
						...parse(get(this.props, 'company.referralCustomStatuses')),
					}
				: { ...REFERRAL_STATUS };
			const referralCustomStatusesMap = {
				...referralCustomStatuses,
				...currentMapping,
			};
			Object.entries(referralCustomStatusesMap).map((status) => {
				const key = status[0];
				referralCustomStatuses[key] = values[key];
			});
			this.setState({
				stages: existingStages,
			});
			const updatedCompany = await onUpdateCompany({
				input: {
					id: company.id,
					referralStatus,
					referralCustomStatuses: JSON.stringify(referralCustomStatuses),
					stages: JSON.stringify(existingStages),
				},
			}).then((response) => response.data.updateCompany);

			this.props.updateCurrentUserCompany(updatedCompany);
			message.success('Stages saved successfully.', 5);
			this.props.form.resetFields();
		});
	};

	updateExclusions = (exclusions) => {
		this.setState({ exclusions });
	};

	updateInclusions = (inclusions) => {
		this.setState({ inclusions });
	};

	updateRecipientListState = (newRecipients) => {
		this.setState({ referralBonusNotificationRecipients: newRecipients });
	};

	validateEmail(email) {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	renderBonusNotification = () => {
		const { company, duplicateRecipientError } = this.state;
		const { getFieldDecorator } = this.props.form;

		return (
			<div className="bs-form">
				<h6 className="bs-form-label">Individual Referral Eligible Alerts</h6>
				<div className="bs-form-grp">
					<div className="custom-form-group">
						<label className="bs-label">
							Send bonus eligible notifications to:
						</label>
						<AutoComplete
							dataSource={this.state.emailAddressLookupData}
							layout="inline"
							onChange={this.handleEmailChange}
							onSelect={this.handleEmailChange}
						>
							{getFieldDecorator('recipientAddress', {
								rules: [
									{
										type: 'email',
										message: 'The input is not a valid email address!',
									},
								],
							})(<Input className="custom-input" placeholder="Enter Email " />)}
						</AutoComplete>
					</div>

					<Button
						type="link"
						className="add-btn"
						size="small"
						onClick={(e) => this.handleSubmit(e)}
					>
						<span className="icon-circle">
							<i className="icon-plus" />
						</span>
						Add Email
					</Button>
				</div>

				{duplicateRecipientError ? (
					<Alert message="This email address already exists." type="error" />
				) : null}

				<h6 className="bs-form-label">View or Delete Email Recipients</h6>
				<RecipientItem
					company={company}
					referralBonusNotificationRecipients={get(
						this.props,
						'company.referralBonusNotificationRecipients'
					)}
					handleRecipientError={this.handleRecipientError}
					updateRecipientListState={this.updateRecipientListState}
					onUpdateCompany={this.props.onUpdateCompany}
				/>
			</div>
		);
	};

	renderBonusReporting = () => {
		const {
			company,
			deliveryFrequency,
			monthlyFrequncy,
			weeklyFrequency,
			bonusReportSetting,
		} = this.state;

		const { getFieldDecorator } = this.props.form;

		const { Option } = Select;
		const options = [];
		deliveryFrequency.map((x, i) => {
			options.push(
				<Option key={x.val} value={x.val}>
					{x.val}
				</Option>
			);
		});

		const monthlyFrequncyOption = [];
		monthlyFrequncy.map((w) => {
			monthlyFrequncyOption.push(
				<Option key={w.key} value={w.val}>
					{w.key}
				</Option>
			);
		});

		const weeklyFrequencyOption = [];
		weeklyFrequency.map((w) => {
			weeklyFrequencyOption.push(
				<Option key={w.key} value={w.value}>
					{w.key}
				</Option>
			);
		});

		return (
			<div className="bs-form">
				<h6 className="bs-form-label">Automated Bonus Eligible Reports</h6>
				<div className="bs-form-grp">
					<div className="custom-form-group custom-checkbox">
						<input
							type="checkbox"
							id="referralBonusEligibleReports"
							checked={
								this.state.bonusReportSetting &&
								this.state.bonusReportSetting.isEnable
							}
							onChange={this.handleBonusReportStatusonChange}
						/>
						<label className="bs-label" htmlFor="referralBonusEligibleReports">
							Enable Automated Bonus Report Export:
						</label>
					</div>
				</div>
				{this.state.bonusReportSetting &&
					this.state.bonusReportSetting.isEnable && (
						<div className="bs-form">
							<div className="bs-form-grp">
								<div className="custom-form-group">
									<label className="bs-label">
										Send Automated Bonus Report to:
									</label>
									{getFieldDecorator('bonusReceipentAddress', {
										initialValue: this.state.bonusEmailAddress
											? this.state.bonusEmailAddress
											: '',
										rules: [
											{
												type: 'email',
												message: 'The input is not a valid email address!',
											},
										],
									})(
										<div>
											<Input
												className="custom-input"
												placeholder="Enter Email "
												onChange={this.handleNewBonusCustomValidator}
											/>
										</div>
									)}
								</div>
								<Button
									type="link"
									className="add-btn"
									size="small"
									onClick={(e) => this.addEmailToBonusReport(e)}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									Add Email
								</Button>
							</div>
							<div className="bs-form-grp">
								<BonusReportRecipientItem
									company={company}
									bonusReportSetting={this.state.bonusReportSetting}
									onUpdateCompany={this.props.onUpdateCompany}
								/>
							</div>

							<div className="bs-form-grp">
								<div className="custom-form-group">
									<label className="bs-label">Delivery Frequency</label>
									{getFieldDecorator('bonusDeliveryFrequency', {
										initialValue:
											bonusReportSetting && bonusReportSetting.frequency
												? bonusReportSetting.frequency
												: '',
									})(
										<Select
											placeholder="Select Frequency "
											style={{ width: 180 }}
											className="custom-input"
											disabled={
												bonusReportSetting &&
												bonusReportSetting.emailAddresses == ''
											}
											onSelect={this.handleBonusFrequency}
										>
											<Select.Option value="Weekly">Weekly</Select.Option>
											<Select.Option value="Monthly">Monthly</Select.Option>
											<Select.Option value="Daily">Daily</Select.Option>
										</Select>
									)}
								</div>
							</div>
							{this.state.selectedDeliveryDateOption && (
								<div className="bs-form-grp">
									<div className="custom-form-group">
										<label className="bs-label">Delivery date</label>
										{getFieldDecorator('bonusDeliveryTime', {
											initialValue:
												bonusReportSetting && bonusReportSetting.time
													? bonusReportSetting.time
													: '',
										})(
											<Select
												placeholder="Select "
												style={{ width: 180 }}
												className="custom-input"
												disabled={
													bonusReportSetting &&
													bonusReportSetting.emailAddresses == ''
												}
												onSelect={this.handleBonusTime}
											>
												{this.state.bonusReportSetting.frequency == 'Weekly'
													? weeklyFrequencyOption
													: monthlyFrequncyOption}
											</Select>
										)}
									</div>
								</div>
							)}

							<label className="custom-label">
								Note: Report will be sent at 8:00 AM EST on the scheduled day.
								Only{' '}
								<strong>
									<em>Eligible</em>{' '}
								</strong>
								bonuses in the select time frame will be sent. If there are no
								bonuses available, a report will not be sent.
							</label>
						</div>
					)}
			</div>
		);
	};


	renderEligibilitySettings = () => {
		const {
			company,
			eligibilityChecks,
		} = this.state;
		const { currentUser, allMultiLingualData } = this.props;

		const selectedCustomInvalidValues = get(
			this.state,
			'selectedCustom.invalidValues',
			[]
		);
		const { getFieldDecorator } = this.props.form;
		return (			
			<div className="bs-form">
				<div><h6 className="bs-form-label-eligibility">Inactive Employee/New Hire Cancellations:</h6></div>
					
					<div className="bs-form-grp-eligibility">
						<div className="custom-form-group">
							<input
								type="checkbox"
								id="referralBonusEmployee"
								checked={get(
									this.state,
									'automationSettings.employeeInactiveIneligibility'
								)}
								onChange={(e) =>
									this.handleAutomationSettings(
										e.target.checked,
										'employeeInactiveIneligibility'
									)
								}
							/>
							<label className="bs-label" htmlFor="referralBonusEmployee">
								<strong>
									Waiting Period - Active Employee Check (Bonus Cancelation)
								</strong>
							</label>
						</div>
					</div>
					<div className="eligibility-explanation">
						If the employee's account is disabled at any time during the bonus waiting period, the employee bonus will be canceled.
					</div>
					
					<div className="bs-form-grp-eligibility">
						<div div className="custom-form-group">
							<input
								type="checkbox"
								id="referralBonusCandidate"
								checked={get(
									this.state,
									'automationSettings.candidateInactiveIneligibility'
								)}
								onChange={(e) =>
									this.handleAutomationSettings(
										e.target.checked,
										'candidateInactiveIneligibility'
									)
								}
							/>
							<label className="bs-label" htmlFor="referralBonusCandidate">
								<strong >
									Waiting Period - New Hire Check  (Bonus Cancelation)
								</strong>
							</label>
						</div>
					</div>
					<div className="eligibility-explanation">
						If the hired candidate is no longer active before the bonus waiting period is complete, the employee and candidate bonus will be canceled.
					</div>
				<div><h6 className="bs-form-label-eligibility">Candidate Eligibility:</h6></div>
				<div>
					
				</div>
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckForIneligibleCustomStatuses"
							checked={
								eligibilityChecks.CheckForIneligibleCustomStatuses
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="CheckForIneligibleCustomStatuses">
							<strong >
								Ineligible Candidate Application Statuses 
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					Candidates that have active applications with the following statuses will update the referral to be Ineligible.
				</div>
				{this.state.eligibilityChecks &&
					this.state.eligibilityChecks.CheckForIneligibleCustomStatuses && (
						<div className="bs-form">
							<div className="bs-form-grp-eligibility">
								<div className="custom-form-group">
									<label className="bs-label-stage">
										Invalid Custom Statuses:
									</label>
									{getFieldDecorator('customValueFieldNames', {
										initialValue:  '',
									})(
										<div>
											<Input
												className="custom-input"
												placeholder="Enter Status "
												onChange={this.handleNewEligibilityCustomValidator}
												id='IneligibleCustomStatuses'
												name='string'
											/>
										</div>
									)}
								</div>
								<Button
									type="link"
									className="add-btn"
									size="small"
									onClick={(e) => this.addCustomValueFieldName(e)}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									Add Custom Status
								</Button>
							</div>
							<div className="bs-form-grp">
								<EligibilityCustomItem
									company={company}
									eligbilityChecks={this.state.eligibilityChecks}
									onUpdateCompany={this.props.onUpdateCompany}
									fieldName='IneligibleCustomStatuses'
								/>
							</div>
						</div>
								
				)}
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckCandidateDomain"
							checked={
								eligibilityChecks.CheckCandidateDomain
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="CheckCandidateDomain">
							<strong >
								Ineligible Candidate Domains 
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					If a referred candidate has the following domain in their email address their referral will be updated to be Ineligible. Must include “@” before the domain.
				</div>
				{this.state.eligibilityChecks &&
					this.state.eligibilityChecks.CheckCandidateDomain && (
						<div className="bs-form">
							<div className="bs-form-grp-eligibility">
								<div className="custom-form-group">
									<label className="bs-label-stage">
										Invalid Domain (Include @):
									</label>
									{getFieldDecorator('customValueFieldNames', {
										initialValue:  '',
									})(
										<div>
											<Input
												className="custom-input"
												placeholder="Enter Domain "
												onChange={this.handleNewEligibilityCustomValidator}
												id='InvalidDomains'
												name='string'
											/>
										</div>
									)}
								</div>
								<Button
									type="link"
									className="add-btn"
									size="small"
									onClick={(e) => this.addCustomValueFieldName(e)}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									Add Invalid Domain
								</Button>
							</div>
							<div className="bs-form-grp">
								<EligibilityCustomItem
									company={company}
									eligbilityChecks={this.state.eligibilityChecks}
									onUpdateCompany={this.props.onUpdateCompany}
									fieldName='InvalidDomains'
								/>
							</div>
						</div>
								
				)}
				<div><h6 className="bs-form-label-eligibility">Employee Eligibility:</h6></div>
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="checkCurrentActiveEmployee"
							checked={
								eligibilityChecks.checkCurrentActiveEmployee
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="checkCurrentActiveEmployee">
							<strong >
								Active Employee Check (Referral Ineligible) 
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					If the employee's account is disabled at any time during the referral process, the referral will become ineligible:
				</div>
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckCandidateInternalApplicant"
							checked={
								eligibilityChecks.CheckCandidateInternalApplicant
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="CheckCandidateInternalApplicant">
							<strong >
								Existing Employee Referral Check 
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					If the referred candidate is an existing employee, the referral will be updated to be Ineligible and Bonuses will be updated to be Ineligible. Requires an internal indicator on the candidate feed.
				</div>
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckClaimUserTitle"
							checked={
								eligibilityChecks.CheckClaimUserTitle
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="CheckClaimUserTitle">
							<strong >
								Ineligible Employee Titles 
							</strong>
						
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					Employees who have these keywords in their titles are not eligible for bonuses. Referrals will be updated to be Ineligible.
				</div>
				{this.state.eligibilityChecks &&
					this.state.eligibilityChecks.CheckClaimUserTitle && (
						<div className="bs-form">
							<div className="bs-form-grp-eligibility">
								<div className="custom-form-group">
									<label className="bs-label-stage">
										Invalid User Titles:
									</label>
									{getFieldDecorator('customValueFieldNames', {
										initialValue:  '',
									})(
										<div>
											<Input
												className="custom-input"
												placeholder="Enter Title "
												onChange={this.handleNewEligibilityCustomValidator}
												id='CheckClaimUserTitleValuesToCheck'
												name='string'
											/>
										</div>
									)}
								</div>
								<Button
									type="link"
									className="add-btn"
									size="small"
									onClick={(e) => this.addCustomValueFieldName(e)}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									Add Invalid Title
								</Button>
							</div>
							<div className="bs-form-grp">
								<EligibilityCustomItem
									company={company}
									eligbilityChecks={this.state.eligibilityChecks}
									onUpdateCompany={this.props.onUpdateCompany}
									fieldName='CheckClaimUserTitleValuesToCheck'
								/>
							</div>
						</div>
				)}
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckCustomValues"
							checked={
								eligibilityChecks.CheckCustomValues
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label-stage" htmlFor="CheckCustomValues">
							<strong >
								Custom User Values 
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					If a user matches these values, the referral will be updated to be Ineligible or Eligible based on the rules set for each custom value.
				</div>
				{this.state.eligibilityChecks &&
					this.state.eligibilityChecks.CheckCustomValues && (
						<div className="bs-form">
							<div className="bs-form-grp-eligibility">
								<div className="custom-form-group">
									<input
										type="checkbox"
										id="StagesToCheckCustomValues-created"
										checked={
											eligibilityChecks.StagesToCheckCustomValues?.includes('created')
										}
										onChange={this.handleEligibilityGranularityCheckOnChange}
									/>
									<label className="bs-label-stage" htmlFor="StagesToCheckCustomValues-created">
										New Referral
									</label>
									<input
										type="checkbox"
										id="StagesToCheckCustomValues-accepted"
										checked={
											eligibilityChecks.StagesToCheckCustomValues?.includes('accepted')
										}
										onChange={this.handleEligibilityGranularityCheckOnChange}
									/>
									<label className="bs-label-stage" htmlFor="StagesToCheckCustomValues-accepted">
										Accepted Referral
									</label>
									<input
										type="checkbox"
										id="StagesToCheckCustomValues-interviewing"
										checked={
											eligibilityChecks.StagesToCheckCustomValues?.includes('interviewing')
										}
										onChange={this.handleEligibilityGranularityCheckOnChange}
									/>
									<label className="bs-label-stage" htmlFor="StagesToCheckCustomValues-interviewing">
										Application Created
									</label>
									<input
										type="checkbox"
										id="StagesToCheckCustomValues-hired"
										checked={
											eligibilityChecks.StagesToCheckCustomValues?.includes('hired')
										}
										onChange={this.handleEligibilityGranularityCheckOnChange}
									/>
									<label className="bs-label-stage" htmlFor="StagesToCheckCustomValues-hired">
										Referral Hired
									</label>
								</div>
							</div>
							<div className="eligibility-explanation">Stages to Run Each Check</div>
							<div className="bs-form-grp-eligibility">
								<div className="custom-form-group">
									<label className="bs-label-stage">
										Custom Value Field Names:
									</label>
									{getFieldDecorator('customValueFieldNames', {
										initialValue: this.state.customFieldNameValue 
											? this.state.customFieldNameValue
											: '',
									})(
										<div>
											<Input
												className="custom-input"
												placeholder="Enter Field Name "
												onChange={this.handleNewEligibilityCustomValidator}
												id='CustomValuesToCheck'
												name='object'
											/>
										</div>
									)}
								</div>
								<Button
									type="link"
									className="add-btn"
									size="small"
									onClick={(e) => this.addCustomValueFieldName(e)}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									Add Custom Field
								</Button>
							</div>
							<div className="bs-form-grp">
								<EligibilityCustomItem
									company={company}
									eligbilityChecks={this.state.eligibilityChecks}
									onUpdateCompany={this.props.onUpdateCompany}
									fieldName='CustomValuesToCheck'
								/>
							</div>
							<div className="custom-form-group">
								<label className="custom-label">Select Custom Field: </label>
								<Select
									showSearch
									className="custom-input"
									placeholder="None"
									onSelect={(custom) =>
										this.handleFilterCustomValue(custom)
									}
								>
									{(this.state.eligibilityChecks.CustomValuesToCheck || [])
										.map((custom) => {
											return (
												<Option key={custom.name} value={custom.name}>
													{custom.name}
												</Option>
											);
										})}
								</Select>
							</div>
							{this.state.selectedCustom && (
								<>
									<div className="custom-label">
										{get(this.state, 'selectedCustom.name', '')}
									</div>
									<>
										<>
											<div className="custom-form-group">
												<div className="bs-form-grp">
													<div className="custom-form-group">
														<label className="bs-label">
														Enter Invalid Values:
														</label>
														{getFieldDecorator('customInvalidValues', {
																			initialValue:  '',
														})(
															<div>
																<Input
																	className="custom-input"
																	placeholder="Enter Value"
																	onChange={this.handleNewEligibilityCustomValueVluesValidator}
																/>
															</div>
														)}
													</div>
													<Button
														type="link"
														className="add-btn"
														size="small"
														onClick={(e) => this.handleCreateCustomValueInvalid(e)} 
													>
														<span className="icon-circle">
															<i className="icon-plus" />
														</span>
														Add Invalid
													</Button>
												</div>
											</div>
										</>
										<div className={"bs-form-grp"}>
											<EligibilityCustomValueObject
												company={company}
												eligbilityChecks={this.state.eligibilityChecks}
												onUpdateCompany={this.props.onUpdateCompany}
												fieldObject={this.state.selectedCustom}
												fieldType='invalidValues'
											/>
										</div>
										<div className="custom-form-group">
												<div className="bs-form-grp">
													<div className="custom-form-group">
														<label className="bs-label">
														Enter Valid Values:
														</label>
														{getFieldDecorator('customValidValues', {
																			validValue:  '',
														})(
															<div>
																<Input
																	className="custom-input"
																	placeholder="Enter Value"
																	onChange={this.handleNewEligibilityCustomValueVluesValidator}
																/>
															</div>
														)}
													</div>
													<Button
														type="link"
														className="add-btn"
														size="small"
														onClick={(e) => this.handleCreateCustomValueValid(e)} 
													>
														<span className="icon-circle">
															<i className="icon-plus" />
														</span>
														Add Valid
													</Button>
												</div>
											</div>
										</>
										<div className={"bs-form-grp"}>
											<EligibilityCustomValueObject
												company={company}
												eligbilityChecks={this.state.eligibilityChecks}
												onUpdateCompany={this.props.onUpdateCompany}
												fieldObject={this.state.selectedCustom}
												fieldType='validValues'
											/>
										</div>	
									</>
							)}
						</div>
								
					)}
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckCandidateLastHiredDate"
							checked={
								eligibilityChecks.CheckCandidateLastHiredDate
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="CheckCandidateLastHiredDate">
							<strong >
								Previously Employed Check 
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					If the candidate was previously employed within the timeframe set below (days), the referral will be updated to be Ineligible. Requires last hired date on candidate feed.
				</div>
				{this.state.eligibilityChecks &&
					this.state.eligibilityChecks.CheckCandidateLastHiredDate && (
						<div className="bs-form">
							<div className="bs-form-grp-eligibility">
								<div className="custom-form-group">
									<label className="bs-label-stage">
										Enter allowable number of days since last hired:
									</label>
									{getFieldDecorator('customValueFieldNames', {
										initialValue: this.state.eligibilityChecks.LastHiredMaxDays ?
										this.state.eligibilityChecks.LastHiredMaxDays :
										''
									})(
										<div>
											<Input
												className="custom-input"
												placeholder="Enter Max Days "
												onChange={this.handleEligibilityCheckOnChange}
												type="text"
												value={this.state.eligibilityChecks.LastHiredMaxDays ?
													this.state.eligibilityChecks.LastHiredMaxDays :
													''}
												id='LastHiredMaxDays'
											/>
										</div>
									)}
								</div>
							</div>
						</div>
				)}
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckHiringManagerIds"
							checked={
								eligibilityChecks.CheckHiringManagerIds
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="CheckHiringManagerIds">
							<strong >
								Hiring Manager Check 
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					If the hiring manager is the referring user the referral will be updated to be ineligible for a bonus. Requires Hiring Manager Employee ID on Job Feed.
				</div>
				{this.state.eligibilityChecks &&
					this.state.eligibilityChecks.CheckHiringManagerIds && (
						<div className="bs-form">
							<div className="bs-form-grp-eligibility">
								<div className="custom-form-group">
								<input
									type="checkbox"
									id="StagesToCheckHiringManagers-created"
									checked={
										eligibilityChecks.StagesToCheckHiringManagers?.includes('created')
									}
									onChange={this.handleEligibilityGranularityCheckOnChange}
								/>
								<label className="bs-label-stage" htmlFor="StagesToCheckHiringManagers-created">
									New Referral
								</label>
								<input
									type="checkbox"
									id="StagesToCheckHiringManagers-accepted"
									checked={
										eligibilityChecks.StagesToCheckHiringManagers?.includes('accepted')
									}
									onChange={this.handleEligibilityGranularityCheckOnChange}
								/>
								<label className="bs-label-stage" htmlFor="StagesToCheckHiringManagers-accepted">
									Accepted Referral
								</label>
								<input
									type="checkbox"
									id="StagesToCheckHiringManagers-interviewing"
									checked={
										eligibilityChecks.StagesToCheckHiringManagers?.includes('interviewing')
									}
									onChange={this.handleEligibilityGranularityCheckOnChange}
								/>
								<label className="bs-label-stage" htmlFor="StagesToCheckHiringManagers-interviewing">
									Application Created
								</label>
								<input
									type="checkbox"
									id="StagesToCheckHiringManagers-hired"
									checked={
										eligibilityChecks.StagesToCheckHiringManagers?.includes('hired')
									}
									onChange={this.handleEligibilityGranularityCheckOnChange}
								/>
								<label className="bs-label-stage" htmlFor="StagesToCheckHiringManagers-hired">
									Referral Hired
								</label>
								</div>
							</div>
						</div>		
				)}
				<div className="eligibility-explanation">Stages to Run Each Check</div>
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckCandidateHiringManagerVsReferringEmployee"
							checked={
								eligibilityChecks.CheckCandidateHiringManagerVsReferringEmployee
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="CheckCandidateHiringManagerVsReferringEmployee">
							<strong >
								Hiring Manager Verification (Job vs AEF) 
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					If the hiring manager on the job is the hiring manager listed for the employee in the active employee feed, then the bonus will be Ineligible. Requires Manager ID on AEF.
				</div>
				<div><h6 className="bs-form-label-eligibility">Referral Ownership and Grace Periods:</h6></div>
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="referralBonusOwnership"
							checked={get(this.state, 'ownershipSettings.enabled')}
							onChange={(e) => this.handleOwnershipSettings(e, 'enabled')}
						/>
						<label className="bs-label" htmlFor="referralBonusOwnership">
							<strong>
								Ownership Period for Referral Ownership
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">If a candidate has been referred previously by an employee and is now being referred by another employee, the original referrer will receive credit for the candidate if it falls within the ownership period. 
						Otherwise, there is no ownership period, and the last referrer will receive credit for the candidate.</div>
				{get(this.state, 'ownershipSettings.enabled') && (
					<div className="bs-form-grp-eligibility">
						<div className="custom-form-group">
							<label className="bs-label-stage">
							Ownership Period (Days):
							</label>
							<InputNumber
								className="custom-input"
								defaultValue={get(this.state, 'ownershipSettings.days', 0)}
								style={{ width: 100 }}
								placeholder="Days"
								onChange={(e) => this.handleOwnershipSettings(e, 'days')}
							/>
						</div>
					</div>
				)}
				{get(this.state, 'ownershipSettings.enabled') && (
					<div>
						<div className="bs-form-grp-eligibility">
							<div className="custom-form-group">
								<input
									type="checkbox"
									id="referralBonusPeriod"
									checked={get(
										this.state,
										'ownershipSettings.rollingOwnershipDates'
									)}
									onChange={(e) =>
										this.handleOwnershipSettings(
											e.target.checked,
											'rollingOwnershipDates'
										)
									}
								/>
								<label className="bs-label" htmlFor="referralBonusPeriod">
									<strong>
										Referral Ownership Refresh
									</strong>
								</label>
							</div>
						</div>
						<div className="eligibility-explanation">
							The ownership period can be retained by the original referrer if the candidate is referred again within the ownership period:{' '}
						</div>
					</div>
				)}
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckCandidateGraceDays"
							checked={
								eligibilityChecks.CheckCandidateGraceDays
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="CheckCandidateGraceDays">
							<strong>
								Grace Period for Applications Before Referrals
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					If the candidate has applied for the job before the referral was made, it will be allowed as long as it falls within the grace period window below. Otherwise, it will be updated to be Ineligible.
				</div>
				
				{this.state.eligibilityChecks &&
					this.state.eligibilityChecks.CheckCandidateGraceDays && (
						<div className="bs-form">
							<div className="bs-form-grp-eligibility">
								<div className="custom-form-group">
									<label className="bs-label-stage">
										Grace Period (Days):
									</label>
									{getFieldDecorator('customValueFieldNames', {
										initialValue: this.state.eligibilityChecks.NumberOfGraceDays ?
										this.state.eligibilityChecks.NumberOfGraceDays :
										''
									})(
										<div>
											<Input
												className="custom-input"
												placeholder="Enter Number of Grace Days "
												onChange={this.handleEligibilityCheckOnChange}
												type="text"
												value={this.state.eligibilityChecks.NumberOfGraceDays ?
													this.state.eligibilityChecks.NumberOfGraceDays :
													''}
												id='NumberOfGraceDays'
											/>
										</div>
									)}
								</div>
							</div>
						</div>
				)}
				
				<div><h6 className="bs-form-label-eligibility">Missing Data Safeguards:</h6></div>
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="EnableNeedsReview"
							checked={
								eligibilityChecks.EnableNeedsReview
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="EnableNeedsReview">
							<strong>
								Missing Data Fail-Safe
							</strong>
						
						</label>
					</div>
				</div>
			    <div className="eligibility-explanation">
					When data for enabled checks is missing, set bonus status to 'Review'.
				</div>
				<div className="bs-form-grp-eligibility">
					<div className="custom-form-group">
						<input
							type="checkbox"
							id="CheckCandidateActive"
							checked={
								eligibilityChecks.CheckCandidateActive
							}
							 onChange={this.handleEligibilityCheckOnChange}
						/>
						<label className="bs-label" htmlFor="CheckCandidateActive">
							<strong>
								Missing Candidate ID Fail-Safe
							</strong>
						</label>
					</div>
				</div>
				<div className="eligibility-explanation">
					If a candidate never shows in active employee feed and cannot be verified, bonus status will be set to “Review” rather than being canceled. Requires matching Candidate ID on Active Employee Feed.
				</div>
			</div>
		);
	};

	renderList = () => {
		const { company, defaultActiveBonusKey, tieredBonuses = [] } = this.state;
		const whiteLabel = get(company, 'whiteLabel');
		if (tieredBonuses.length === 0) {
			return (
				<div className="no-content">
					{whiteLabel ? (
						<img
							className="no-content-icon"
							src={this.state.errorImageSrc}
							alt="error image"
						/>
					) : (
						<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
					)}
					<p className="no-content-text">No bonuses have been created. </p>
				</div>
			);
		}

		if (tieredBonuses && tieredBonuses.length > 0 && defaultActiveBonusKey) {
			return (
				<Collapse
					bordered={false}
					activeKey={defaultActiveBonusKey}
					className="bonus-collapse"
					expandIconPosition="end"
				>
					{this.renderTieredBonuses()}
				</Collapse>
			);
		}

		if (tieredBonuses && tieredBonuses.length > 0 && !defaultActiveBonusKey) {
			return (
				<Collapse
					bordered={false}
					className="bonus-collapse"
					expandIconPosition="end"
				>
					{this.renderTieredBonuses()}
				</Collapse>
			);
		}
	};

	renderReferralMiddleStage = () => {
		const { form, currentUser } = this.props;
		const { getFieldDecorator } = form;
		const { stages } = this.state;
		const middleStage = get(
			this.props,
			'company.referralStatus',
			'Interviewing'
		);

		return (
			<div className={FlexContainer}>
				<div className={BonusLabel} style={{ paddingBottom: 10 }}>
					Customize the default middle stage and add custom stages between
					"Accepted" and "Hired".
				</div>
				<div className={SettingStyle.groupRow}>
					<div
						className={SettingStyle.stageLabel}
						style={{ color: 'black', paddingBottom: 10, width: 190 }}
					>
						Default Middle Stage:
					</div>
					<Form.Item>
						{getFieldDecorator('interviewing', {
							initialValue: middleStage,
							rules: [
								{
									required: true,
									message: 'Please input referral status.',
								},
							],
						})(<Input className={SettingStyle.StageInputStyles} />)}
					</Form.Item>
				</div>
			</div>
		);
	};

	renderReferralStatusMappings = () => {
		const { form, currentUser } = this.props;
		const { getFieldDecorator } = form;
		const currentMapping = get(this.props, 'company.referralCustomStatuses')
			? {
					...REFERRAL_STATUS,
					...parse(get(this.props, 'company.referralCustomStatuses')),
				}
			: { ...REFERRAL_STATUS };
		return (
			<>
				{Object.entries(currentMapping).map((entry, index) => {
					const key = entry[0];
					const value = entry[1];
					return (
						<div className="bs-form-grp">
							<div className="custom-form-group">
								<label className="bs-label bs-label-nobold bs-label-width">
									{REFERRAL_STATUS[key]}:
								</label>
								{getFieldDecorator(key, {
									initialValue: value,
									rules: [
										{
											required: true,
											message: 'Please input referral status.',
										},
										{
											max: 15,
											message:
												'Referral status can not be more than 15 characters.',
										},
									],
								})(<Input className="custom-input" />)}
							</div>
						</div>
					);
				})}
			</>
		);
	};

	renderStageInputs = (group) => {
		const { currentUser, theme, form } = this.props;
		const { getFieldDecorator } = form;
		const { stages } = this.state;

		if (stages.length > 0) {
			return stages.map((stage, index = 0) => {
				return (
					<>
						<div key={index} className="bs-form-grp">
							<div className="custom-form-group">
								<label className="bs-label bs-label-nobold bs-label-width">
									{`Custom Stage ${index + 1}`}:
								</label>
								{getFieldDecorator(`stage-${index + 1}`, {
									initialValue: stage[`stage${index + 1}`],
									rules: [
										{
											message: 'Please input referral status.',
										},
									],
								})(
									<Input
										className="custom-input"
										placeholder="Enter new stage"
									/>
								)}
							</div>
							{index >= 0 && (
								<Button
									type="link"
									className="btn-link danger"
									onClick={() => this.handleRemoveStageClick(index)}
								>
									<i className="icon-bin" />
									Delete this stage & Save
								</Button>
							)}
						</div>
						<div className="mb-4">
							{index === stages.length - 1 && index < 5 && (
								<Button
									type="link"
									className="add-btn"
									size="small"
									onClick={() => this.handleAddStage(index + 1)}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									Add Stage
								</Button>
							)}
						</div>
					</>
				);
			});
		}

		return (
			<div className="mb-4">
				<Button
					type="link"
					className="add-btn"
					size="small"
					onClick={() => this.handleAddStage(1)}
				>
					<span className="icon-circle">
						<i className="icon-plus" />
					</span>
					Add Stage
				</Button>
			</div>
		);
	};

	renderTieredBonuses = () => {
		const { form, currentUser } = this.props;

		const { getFieldDecorator } = form;
		const {
			theme,
			tieredBonuses,
			ml_Days,
			ml_UpdateBonuses,
			ml_BonusBuilder,
			ml_BonusName,
			ml_BonusPayments,
			ml_DeleteBonus,
			ml_After,
			ml_AddGroupBonus,
			ml_EmployeeGroup,
			ml_ChooseEmployeeGroup,
			ml_DaysFromHireDate,
			ml_BonusAmount,
			ml_Candidate,
			ml_Employee,
			ml_DeletePayment,
			ml_Group,
		} = this.state;
		if (!tieredBonuses) return;
		const sortedTieredBonuses = tieredBonuses.sort((a, b) => {
			const aName = a.name.toUpperCase();
			const bName = b.name.toUpperCase();
			if (aName < bName) {
				return -1;
			}

			if (aName > bName) {
				return 1;
			}

			return 0;
		});
		return sortedTieredBonuses.map((bonus) => {
			const { tiers } = bonus;
			if (!tiers) return;
			const bonusAmount = tiers.reduce((total, tier) => {
				tier = parse(tier);
				return (total += Number.parseInt(tier.amount));
			}, 0);
			return (
				<Collapse.Panel
					key={bonus.id}
					extra={
						<div className="bonus-collapse-icon-wrap">
							<i className="icon-arrow-down bonus-collapse-icon" />
						</div>
					}
					className="bonus-collapse-card"
					header={<h4>{bonus.name}</h4>}
					onChange={() =>
						this.setState({
							defaultActiveBonusKey: bonus.id,
						})
					}
				>
					<div className="bonus-rule-actions">
						<Button type="primary" onClick={(e) => this.handleSubmitClick(e)}>
							{/* preiously this was a prop passed in ml_updateBonus */}
							Save Bonus
						</Button>
						{/* If bonus exists in the data table, allow copy, otherwise user must be currently creating a new bonus */}
						{this.props?.bonuses.some(
							(storedBonus) => storedBonus.id === bonus.id
						) && (
							<Popconfirm
								title="Duplicate Bonus?"
								placement="bottom"
								okText="Yes"
								cancelText="No"
								onConfirm={() => {
									// Make a copy of the bonus object we want to duplicate
									const duplicateBonus = { ...bonus };
									// Give the duplicate bonus a unique id
									duplicateBonus.id = uuid();
									// Add "(Copy)" to name
									duplicateBonus.name = `${duplicateBonus.name} (Copy)`;
									// Remove .__typename because mutation will error
									delete duplicateBonus.__typename;
									return (
										this.props
											.onUpdateTieredBonus(duplicateBonus)
											// Then change state, so that duplicate bonus displays instantly
											.then(() =>
												this.setState({
													tieredBonuses: [...tieredBonuses, duplicateBonus],
												})
											)
									);
								}}
							>
								<Button ghost type="primary">
									<CopyOutlined />
									Duplicate Bonus
								</Button>
							</Popconfirm>
						)}

						<Popconfirm
							title="Delete Bonus?"
							placement="bottom"
							okText="Yes"
							cancelText="No"
							onConfirm={() => this.handleDeleteTieredBonus(bonus.id)}
						>
							<Button ghost className="ant-btn-danger">
								<i className="icon-bin" />
								{ml_DeleteBonus}
							</Button>
						</Popconfirm>
					</div>

					<TieredBonus
						key={'bonus' + bonus.id}
						getFieldDecorator={getFieldDecorator}
						bonusId={bonus.id}
						companyId={bonus.companyId}
						name={bonus.name}
						tiers={bonus.tiers}
						submitting={this.state.submitting}
						userGroups={this.props.userGroups}
						updateExclusions={this.updateExclusions}
						updateInclusions={this.updateInclusions}
						daysText={ml_Days}
						addBonusText="Add Bonus Tier"
						updateBonusesText={ml_UpdateBonuses}
						bonusBuilderText={ml_BonusBuilder}
						bonusNameText={ml_BonusName}
						bonusPaymentsText={ml_BonusPayments}
						deleteBonusText={ml_DeleteBonus}
						afterText={ml_After}
						addGroupBonusText={ml_AddGroupBonus}
						employeeGroupText={ml_EmployeeGroup}
						chooseEmployeeGroupText={ml_ChooseEmployeeGroup}
						daysFromHireDateText={ml_DaysFromHireDate}
						bonusAmountText={ml_BonusAmount}
						candidateText={ml_Candidate}
						employeeText={ml_Employee}
						deletePaymentText={ml_DeletePayment}
						groupText={ml_Group}
						theme={theme}
						onChange={() => this.setState({ defaultActiveBonusKey: undefined })}
						onDelete={() => this.handleDeleteTieredBonus(bonus.id)}
					/>
				</Collapse.Panel>
			);
		});
	};

	render() {
		const { company, ml_AddBonus, theme } = this.state;
		const { onUpdateUserGroup, currentUser, allMultiLingualData } = this.props;
		return get(currentUser, 'company') ? (
			<main>
				<div className="page-title">
					<h2 className="page-heading">Bonus Rules</h2>
					<ul className="info-action">
						<li>
							<Button
								type="link"
								className="add-btn btn-lg"
								onClick={this.handleAddNewBonusClick}
							>
								<span className="icon-circle">
									<i className="icon-plus" />
								</span>
								{ml_AddBonus}
							</Button>
						</li>
					</ul>
				</div>
				{this.state.submitting ? (
					<Spinner company={company} />
				) : (
					this.renderList()
				)}
				<h3 className="bonus-heading">
					{ml('Settings', currentUser, allMultiLingualData)}
				</h3>
				<Form>
					<Collapse
						className="bonus-collapse"
						bordered={false}
						expandIconPosition="end"
					>
						<Collapse.Panel
							key="1"
							className="bonus-collapse-card"
							extra={
								<div className="bonus-collapse-icon-wrap">
									<i className="icon-arrow-down bonus-collapse-icon" />
								</div>
							}
							header={<h4>Universal Referral Policy Automations</h4>}
						>
							{this.renderEligibilitySettings()}
						</Collapse.Panel>
					</Collapse>
				</Form>
				<GroupCurrency
					currentUser={this.props.currentUser}
					userGroups={this.props.userGroups}
					setCurrentUserGroup={this.props.setCurrentUserGroup}
					onUpdateUserGroup={onUpdateUserGroup}
				/>
				<GroupMeasurement
					allMultiLingualData={allMultiLingualData}
					currentUser={this.props.currentUser}
					userGroups={this.props.userGroups}
					setCurrentUserGroup={this.props.setCurrentUserGroup}
					onUpdateUserGroup={onUpdateUserGroup}
				/>
				<Form>
					<Collapse
						className="bonus-collapse"
						bordered={false}
						expandIconPosition="end"
					>
						<Collapse.Panel
							key="101"
							className="bonus-collapse-card"
							extra={
								<div className="bonus-collapse-icon-wrap">
									<i className="icon-arrow-down bonus-collapse-icon" />
								</div>
							}
							header={<h4>Referral Stages</h4>}
						>
							<div className="bs-form">
								<h6 className="bs-form-label">
									Add custom stages between "Accepted" and "Hired".
								</h6>
								{this.renderStageInputs()}
								<Button
									type="primary"
									size="large"
									onClick={(e) => this.handleSubmitStages(e)}
								>
									Save Referral Stages
								</Button>
							</div>
							<div className="bs-form">
								<h6 className="bs-form-label">
									Customize the default statuses.
								</h6>
								{this.renderReferralStatusMappings()}
							</div>
						</Collapse.Panel>
					</Collapse>
				</Form>
				<Form>
					<Collapse
						className="bonus-collapse"
						bordered={false}
						expandIconPosition="end"
					>
						<Collapse.Panel
							key="1"
							className="bonus-collapse-card"
							extra={
								<div className="bonus-collapse-icon-wrap">
									<i className="icon-arrow-down bonus-collapse-icon" />
								</div>
							}
							header={<h4>Bonus Reporting</h4>}
						>
							{this.renderBonusReporting()}
							{this.renderBonusNotification()}
						</Collapse.Panel>
					</Collapse>
				</Form>
			</main>
		) : (
			<Spinner />
		);
	}
}

export const ReferralTiersSettings = Form.create()(
	ReferralTiersSettingsComponent
);
