import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { GetJobMatchesById } from '../../graphql/custom/jobs';

export const withJobMatchesById = (Component, variables) => {
	return compose(
		graphql(GetJobMatchesById, {
			options(props) {
				return {
					context: {
						headers: {
							'x-frame-options': 'deny', // This header will reach the server
						},
					},
					variables: { id: get(props, 'jobId', 'skip') },
					fetchPolicy: 'network-only',
				};
			},
			props(response, props) {
				const matches = get(response, 'data.getJob');
				return {
					matches,
				};
			},
		})
	)(Component);
};
