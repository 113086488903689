import { AutoComplete, Button, Spin, message } from 'antd';
import { useState } from 'react';
import SendRewardSlider from './SendRewardSlider.jsx';
import { BADGE_TYPE_SEARCH } from '../../constant/homeApi.constant.js';
import { addCommas } from '../../../rewardsAndRecognitionHelper/utils.js';

const SendRewardModal = (props) => {
	const {
		sendrewardObject,
		setSendRewardObject,
		setIsOpenRewardModel,
		badgeData,
		isBadgeLoading,
		errorObject,
		setErrorObject,
		getAllBadgeList,
		rewardsAvailable,
	} = props;
	const [selectedReward, setSelectedReward] = useState(null);

	const rewardList = badgeData?.map((badge, index) => {
		return (
			<Option value={badge.id} key={index}>
				<span>
					{badge?.name}{' '}
					<>
						<span className="badge-count">{`(${addCommas(badge?.points)} points)`}</span>
					</>
				</span>
			</Option>
		);
	});
	const handleSearch = (value) => {
		getAllBadgeList(
			BADGE_TYPE_SEARCH.PEER_TO_PEER,
			undefined,
			undefined,
			value
		);
	};
	return (
		<div className="send-reward-modal">
			<div className="select-recipient-text">
				Select a badge to send. Sending a badge will deduct points from your
				available points balance
			</div>

			<div>
				{isBadgeLoading ? (
					<Spin className="spinner" />
				) : (
					<div>
						<div className="custom-form-group mt-4 ">
							<AutoComplete
								value={
									badgeData?.find(
										(badge) => badge.id === sendrewardObject?.rewardtype
									)?.name || undefined
								}
								className="w-100 custom-input"
								dataSource={rewardList}
								onSearch={handleSearch}
								onChange={(value) => {
									const selectedReward = badgeData?.find(
										(badge) => badge.id === value
									);
									setSelectedReward(selectedReward);
									setSendRewardObject({
										...sendrewardObject,
										rewardtype: value,
										selectedBadge: selectedReward,
									});
									setErrorObject({
										...errorObject,
										rewardtype: false,
									});
								}}
								placeholder="Select Reward"
							/>
							{errorObject?.rewardtype === true ? (
								<div class="ant-form-explain mt-1">Please add badge</div>
							) : (
								<></>
							)}
						</div>

						{rewardsAvailable ? (
							<SendRewardSlider
								badgeData={badgeData}
								setSendRewardObject={setSendRewardObject}
								sendrewardObject={sendrewardObject}
								onRewardSelect={(selectedReward) => {
									setSelectedReward(selectedReward);
								}}
							/>
						) : (
							<div className="d-flex alig-items-center justify-items-center">
								No badges available
							</div>
						)}
					</div>
				)}
			</div>

			<div className="d-flex justify-content-center pt-4">
				<Button
					className="blue-bg-btn"
					onClick={() => {
						if (
							sendrewardObject?.rewardtype !== undefined &&
							sendrewardObject?.rewardtype !== null
						) {
							message.success('Your reward has been saved');
						}
						setIsOpenRewardModel(false);
					}}
					disabled={sendrewardObject?.rewardtype === undefined ||
						sendrewardObject?.rewardtype === null}
				>
					Select
				</Button>
			</div>
		</div>
	);
};
export default SendRewardModal;
