import { Button, Form, Input, InputNumber } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import { get } from 'lodash';
import { parse } from 'src/_shared/services/utils';
import { COLORS } from 'src/_shared/styles/colors';
import { ONBOARDING_SCREENS } from '../constants';
import {
	bulletPointStyle,
	buttonContainerStyle,
	buttonStyle,
	containerStyle,
	inputStyle,
	noteStyle,
	paymentLabel,
	subtitleStyle,
	textBodyStyle,
	tierInputClass,
	titleContainerStyle,
	titleStyle,
} from './onboardingStyles.js';

function CreateYourBonus(props) {
	const {
		nextPage,
		company,
		completeOnboardingPage,
		uncompleteOnboardingPage,
		currentUser,
		onAddTieredBonus,
		onUpdateTieredBonus,
		onUpdateCompany,
		onboarding,
		navToScreen,
	} = props;
	const [form] = Form.useForm();
	const partnerHost = get(company, 'partnerHost');
	const bonusName = get(onboarding, 'createBonuses.data.tieredBonus.name');
	const tieredBonusId = get(onboarding, 'createBonuses.data.tieredBonusId');
	const t = get(onboarding, 'createBonuses.data.tieredBonus.tiers');
	const tiers = parse(t);
	const tier = get(tiers, '[0]');

	const back = () => {
		uncompleteOnboardingPage(ONBOARDING_SCREENS.CREATE_BONUSES);
		navToScreen(ONBOARDING_SCREENS.HOW_TO_PAY);
	};

	const onFinishFailed = (error) => {
		console.error(error);
	};

	const next = async (values) => {
		let userGroup = get(currentUser, 'company.userGroups', []).find(
			(userGroup) => userGroup.name === 'Default'
		);
		userGroup = userGroup
			? userGroup.id
			: get(currentUser, 'company.userGroups[0].id');
		const tieredBonus = {
			companyId: get(currentUser, 'companyId'),
			name: get(values, 'name', 'Default'),
			tiers: JSON.stringify({
				amount: Number.parseInt(get(values, 'amount', 0)),
				payOutDays: Number.parseInt(get(values, 'payoutdays')),
				recipientType: 'employee',
				userGroup,
			}),
		};
		if (tieredBonusId) {
			tieredBonus.id = tieredBonusId;
			await onUpdateTieredBonus(tieredBonus);
			completeOnboardingPage(ONBOARDING_SCREENS.CREATE_BONUSES, {
				tieredBonusId,
				tieredBonus,
			});
		} else {
			const tBonus = await onAddTieredBonus(tieredBonus);
			// Set default bonus on atsIntegration
			if (company.atsIntegration) {
				const atsIntegration = JSON.parse(company.atsIntegration);
				atsIntegration[0].ATSBonusBuilder = new Object({
					tieredBonusId: tBonus.id,
					name: 'Default',
				});
				await onUpdateCompany({
					input: {
						id: company?.id,
						atsIntegration: JSON.stringify(atsIntegration),
					},
				});
			}

			completeOnboardingPage(ONBOARDING_SCREENS.CREATE_BONUSES, {
				tieredBonusId: get(tBonus, 'id'),
				tieredBonus,
			});
		}

		navToScreen(ONBOARDING_SCREENS.BONUS_REPORTING);
	};

	const currencyCode = get(currentUser, 'userGroup.currency', 'USD');
	return (
		<div className={containerStyle}>
			<Form
				form={form}
				style={{ width: '100%' }}
				initialValues={{
					name: get(onboarding, 'createBonuses.data.tieredBonus.name'),
					amount: get(tier, 'amount')
						? Number.parseInt(get(tier, 'amount'))
						: null,
					payoutdays: get(tier, 'payOutDays')
						? Number.parseInt(get(tier, 'payOutDays'))
						: null,
				}}
				onFinish={next}
				onFinishFailed={onFinishFailed}
			>
				<div className={titleContainerStyle(partnerHost)}>
					<h1 className={titleStyle}>Create Your First Bonus</h1>
					<div className={textBodyStyle}>
						<div className={subtitleStyle} style={{ marginBottom: 20 }}>
							Determine the bonus structure for your employees when one of their
							referrals are hired.
							<span className={noteStyle}>(Example: $1 = 1 point)</span>
						</div>
						<div style={{ display: 'none ' }}>
							<div style={{ display: 'none' }}>
								<span className={bulletPointStyle}>1.</span>
								<span className={bulletPointStyle}>
									First, enter a name for your bonus. This is a way for admins
									to keep track of bonuses and will not be seen by your
									employees.
								</span>
							</div>
							<Form.Item
								label={
									<span style={{ fontSize: 18, color: COLORS.black }}>
										Bonus Name
									</span>
								}
								className={inputStyle}
								name="name"
								rules={[{ required: false, message: 'Enter a bonus name.' }]}
							>
								<Input className={tierInputClass} placeholder="ex. Level One" />
							</Form.Item>
						</div>
						<div style={{ display: 'flex' }}>
							<span className={bulletPointStyle}>1.</span>
							<span className={bulletPointStyle}>
								How much would you like to pay your employees when a referral is
								hired?
							</span>
						</div>
						<Form.Item
							label={
								<span style={{ fontSize: 18, color: COLORS.black }}>
									Bonus Amount
								</span>
							}
							className={inputStyle}
							name="amount"
							rules={[{ required: true, message: 'Enter a bonus amount.' }]}
						>
							<div>
								<span className={paymentLabel}>
									{getSymbolFromCurrency(currencyCode)}
								</span>
								<InputNumber
									className={tierInputClass}
									placeholder="Bonus Amount"
									style={{ width: 150 }}
								/>
							</div>
						</Form.Item>
						<div style={{ display: 'flex' }}>
							<span className={bulletPointStyle}>2.</span>
							<span className={bulletPointStyle}>
								How many days after a candidate is hired should the bonus be
								awarded to the employee?
							</span>
						</div>

						<Form.Item
							label={
								<span style={{ fontSize: 18, color: COLORS.black }}>
									Payout Days
								</span>
							}
							className={inputStyle}
							name="payoutdays"
							rules={[{ required: true, message: 'Enter payout days.' }]}
						>
							<InputNumber
								className={tierInputClass}
								placeholder="Days"
								style={{ width: 150 }}
							/>
						</Form.Item>
						<div className={buttonContainerStyle} style={{ marginTop: 30 }}>
							<Button
								disabled={false}
								type="primary"
								shape="round"
								size="large"
								className={buttonStyle}
								onClick={back}
							>
								Back
							</Button>
							<div className="mx-4" />
							<Button
								disabled={false}
								type="primary"
								shape="round"
								size="large"
								className={buttonStyle}
								htmlType="submit"
							>
								Next
							</Button>
						</div>
						<div style={{ height: 40 }} />
					</div>
				</div>
			</Form>
		</div>
	);
}

export default CreateYourBonus;
