import { connect } from 'react-redux';
import {
	withContactDetails,
	withUpdateContact,
} from 'src/_shared/api/components/contacts/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { dashboardActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils';
import { ContactDetailsComponent } from './ContactDetailsComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { currentUser } = state.user;
	const { id } = props.match.params;
	const { allMultiLingualData } = state.dashboard;
	return {
		id,
		currentUser,
		allMultiLingualData,
		companyId: currentUser.companyId,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const ContactDetailsWithAPI = compose(
	withContactDetails,
	withUpdateContact,
	withGetCompanyData
)(ContactDetailsComponent);

export const ContactDetails = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(ContactDetailsWithAPI);
