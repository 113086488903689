import get from 'lodash/get';
import { connect } from 'react-redux';
import { withQueryAnnouncementByCompanyId } from 'src/_shared/api/components/announcements/';
import { withQueryBonusByUserId } from 'src/_shared/api/components/bonuses/';
import {
	withListContactsByAtsId,
	withListMyReferralsContacts,
	withMyContacts,
} from 'src/_shared/api/components/contacts/';
import {
	withBusinessNetwork,
	withDashboardJobs,
	withDashboardJobsManager,
	withDashboardReferrals,
	withJobMatchByCompanyId,
	withListContacts,
	withTopReferrers,
	withUserDashboard,
	withUsersByPoints,
} from 'src/_shared/api/components/dashboard/';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withCreateWebNotification } from 'src/_shared/api/components/network/';
import { withCreateReferral } from 'src/_shared/api/components/referrals/';
import {
	withGetCompany,
	withGetCompanyData,
} from 'src/_shared/api/components/settings/';
import { withModifyTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import { withListUserInvites } from 'src/_shared/api/components/userInvites/';
import { withMyJobMatches } from 'src/_shared/index.js';
import { dashboardActions, userActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils';
import DashboardComponent from './DashboardComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;

	const extendedContactsData = get(currentUser, 'extendedContactData', []);
	let referrals = [];
	for (const contact of extendedContactsData) {
		referrals = [...referrals, ...get(contact, 'referrals', [])];
	}

	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		extendedReferrals: referrals,
		first: 1000,
		after: '',
		companyId: currentUser.companyId,
		filter: { companyId: { eq: currentUser.companyId } },
		limit: 1000,
		languageCode:
			currentUser.languageCode === null ? 'US' : currentUser.languageCode,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		inviteEmployeesSubmit(
			users,
			fileData,
			role,
			createdById,
			companyId,
			languageCode,
			callback
		) {
			dispatch(
				dashboardActions.createInviteEmployeesAction(
					users,
					fileData,
					role,
					createdById,
					companyId,
					languageCode,
					callback
				)
			);
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
		updateCurrentUser(values) {
			dispatch(userActions.updateCurrentUser(values));
		},
	};
};

const DashboardContainer = compose(
	withDashboardJobs,
	withListDepartment,
	withListContacts,
	withListUserInvites,
	withDashboardJobsManager,
	withDashboardReferrals,
	withBusinessNetwork,
	withTopReferrers,
	withJobMatchByCompanyId,
	withUserDashboard,
	withMyJobMatches,
	withGetCompanyData,
	withListContactsByAtsId,
	withModifyTieredBonus,
	withQueryBonusByUserId,
	withGetCompany,
	withCreateReferral,
	withUsersByPoints,
	withQueryAnnouncementByCompanyId,
	withMyContacts,
	withListMyReferralsContacts,
	withCreateReferral,
	withCreateWebNotification
)(DashboardComponent);

export const Dashboard = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(DashboardContainer);
