import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import get from 'lodash/get';
import queryString from 'query-string';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { getEnvironment } from 'src/_shared/services/utils.js';

dayjs.extend(customParseFormat);

export class ClaimAccountModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cognitoError: false,
			errorMessage: null,
			date: '',
			passedDOB: null,
			dontUseDateFormat: false,
		};

		const userSignupSettings = get(props, 'userSignupSettings');
		if (userSignupSettings) {
			const formattedUSS = JSON.parse(userSignupSettings);
			if (formattedUSS.dobLabel) {
				this.state.dobLabel = formattedUSS.dobLabel;
			}

			if (
				formattedUSS.disableDOBDateFormat &&
				formattedUSS.disableDOBDateFormat == true
			) {
				this.state.dontUseDateFormat = true;
			}
		}

		// Allow passing in DOB via query string. If it is passed in, hide the form field and set the value
		const queryParameters = queryString.parse(props.location.search);
		if (
			queryParameters &&
			queryParameters.claimDate &&
			this.state.dontUseDateFormat == false
		) {
			this.state.passedDOB = queryParameters.claimDate;
		}
	}

	onChangeDate = (e) => {
		const input = e.target.value ? e.target.value : '';
		if (input.length > 10) return this.state.date;
		if (input.length <= this.state.date.length) {
			let res = input;
			const lastChar = input.slice(-1, input.length);
			if (!this.containsSpecialChars(lastChar)) {
				res = res.slice(0, -1);
			}

			this.setState({ date: res });
			return res;
		}

		this.setState({ date: input });
		let result = '';
		const allDates = input.split(/\D/g);
		let month = allDates[0] ? allDates[0] : '';
		if (month && this.clean(month).length < 2 && input.length >= 2) {
			month = '0' + month;
		}

		if (month) {
			result = month;
		}

		if (month.length === 2) {
			result = month + '/';
		}

		let day = allDates[1] ? allDates[1] : '';
		if (day) {
			result = month + '/' + day;
		}

		if (day && this.clean(day).length < 2 && input.length > 4) {
			day = '0' + day;
		}

		if (day.length === 2) {
			result = month + '/' + day + '/';
		}

		const year = allDates[2] ? allDates[2] : '';
		if (year) {
			result = month + '/' + day + '/' + year;
		}

		return result ? result : input;
	};

	clean(value) {
		const value_ = value;
		const result = value_.replaceAll(/\D/g, '');
		return result;
	}

	containsSpecialChars(value) {
		return /[~`!#$%^&*+=\-[\]\\';,/{}|":<>?]/g.test(value);
	}

	formatDate = (input) => {
		const allDates = input.split(/\D/g);
		let month = allDates[0] ? allDates[0] : '';
		if (month.length < 2) {
			month = '0' + month;
		}

		let day = allDates[1] ? allDates[1] : '';
		if (day.length < 2) {
			day = '0' + day;
		}

		const year = allDates[2] ? allDates[2] : '';
		return `${month}/${day}/${year}`;
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields(async (error, values) => {
			const { client } = this.props;
			if (error) {
				console.error(error);
				return;
			}

			const { labelEmployeeID } = this.props;
			const configMode = getEnvironment() === 'dev' ? 'DEV' : 'PROD';
			try {
				let valuesDateOfBirth = this.formatDate(
					get(values, 'dateOfBirth', '00/00/0000')
				);

				if (this.state.passedDOB && !this.state.dontUseDateFormat) {
					valuesDateOfBirth = this.formatDate(this.state.passedDOB);
				}

				let formattedDOB = dayjs(valuesDateOfBirth).format('MM-DD-YYYY');
				if (this.state.dontUseDateFormat == true) {
					formattedDOB = get(values, 'dateOfBirth', '');
				}

				const employeeId = values.employeeId.toLowerCase();

				// START getting ACCOUNT CLAIM DATA
				let claim = '';
				try {
					const url = `https://3l5htgybh3.execute-api.us-east-2.amazonaws.com/default/getAccountClaimByEmployeeIdDOB`;

					const response = await fetch(url, {
						method: 'POST',
						body: JSON.stringify({
							mode: configMode,
							employeeId,
							dateOfBirth: formattedDOB,
						}),
					});
					claim = await response.json();
				} catch (error) {
					console.log(error);
				}
				// END getting ACCOUNT CLAIM DATA

				const claimDateOfBirth = this.formatDate(
					get(claim, 'dateOfBirth', '00/00/0000')
				);

				if (
					claim &&
					claimDateOfBirth === valuesDateOfBirth &&
					get(claim, 'active') &&
					!get(claim, 'claimed')
				) {
					this.props.history.push({
						pathname: `/newuser/${values.employeeId}`,
						state: {
							accountClaim: claim,
							employeeId: values.employeeId,
						},
					});
					return 'Sucess!';
				}

				if (!claim) {
					this.props.form.setFields({
						employeeId: {
							errors: [
								new Error(
									`No ${
										labelEmployeeID ? labelEmployeeID : 'Employee ID'
									} Found.`
								),
							],
						},
					});

					return Promise.reject(
						`No ${labelEmployeeID ? labelEmployeeID : 'Employee ID'} Found.`
					);
				}

				if (get(claim, 'claimed')) {
					this.props.form.setFields({
						employeeId: {
							errors: [new Error('Account Already Claimed.')],
						},
					});
					return Promise.reject('Account Already Claimed.');
				}

				if (!get(claim, 'active')) {
					this.props.form.setFields({
						employeeId: {
							errors: [new Error('This Employee ID is disabled.')],
						},
					});
					return Promise.reject('This Employee ID is disabled.');
				}

				this.props.form.setFields({
					dateOfBirth: {
						errors: [new Error('Invalid Date of Birth.')],
					},
				});
				return Promise.reject('Invalid Date of Birth.');
			} catch {
				this.props.form.setFields({
					employeeId: {
						errors: [
							new Error(
								`No ${labelEmployeeID ? labelEmployeeID : 'Employee ID'} Found`
							),
						],
					},
				});

				return Promise.reject(
					`No ${labelEmployeeID ? labelEmployeeID : 'Employee ID'} Found`
				);
			}
		});
	};

	render() {
		const FormItem = Form.Item;
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { cognitoError, errorMessage } = this.state;
		const { theme, labelEmployeeID } = this.props;
		const dontUseDateFormat = Boolean(this.state.dontUseDateFormat);
		const dobLabel = this.state.dobLabel
			? this.state.dobLabel
			: 'Date of Birth';
		return (
			<Modal
				height={350}
				title={'Claim Your Account'}
				destroyOnClose={true}
				footer={null}
				open={this.props.visible}
				width={500}
				onCancel={this.props.closeModal}
				onOk={null}
			>
				<Form>
					<FormItem>
						<div className="custom-form-group">
							<label className="custom-label">
								{labelEmployeeID ? labelEmployeeID : 'Employee ID'}:
							</label>
							{getFieldDecorator('employeeId', {
								rules: [
									{
										type: 'string',
									},
									{
										required: true,
										message: `${
											labelEmployeeID ? labelEmployeeID : 'Employee ID'
										} is required`,
									},
									{
										validator: async (_, value) => {
											{
											}

											const { client } = this.props;
										},
									},
								],
							})(<Input className="custom-input" />)}
						</div>
					</FormItem>

					<FormItem>
						{dontUseDateFormat == false && !this.state.passedDOB && (
							<div className="custom-form-group">
								<label className="custom-label">{dobLabel}:</label>
								{getFieldDecorator('dateOfBirth', {
									initialValue: this.state.passedDOB
										? this.state.passedDOB
										: '',
									getValueFromEvent: this.onChangeDate,
									rules: [
										{
											type: 'string',
										},
										{
											validator(_, value) {
												return dayjs(value, 'MM/DD/YYYY', true).isValid()
													? Promise.resolve()
													: Promise.reject(
															'Enter a valid Date of Birth. Use format MM/DD/YYYY.'
														);
											},
										},
									],
								})(<Input placeholder="MM/DD/YYYY" className="custom-input" />)}
							</div>
						)}
						{dontUseDateFormat == true && (
							<div className="custom-form-group">
								<label className="custom-label">{dobLabel}:</label>
								{getFieldDecorator('dateOfBirth')(
									<Input className="custom-input" />
								)}
							</div>
						)}
					</FormItem>
					<div className="modal-footer-btn">
						<Button
							type="primary"
							size="large"
							className="btn-min-width"
							htmlType="submit"
							onClick={this.handleSubmit}
						>
							Continue
						</Button>
					</div>
				</Form>
			</Modal>
		);
	}
}

export default withApollo(withRouter(Form.create()(ClaimAccountModal)));
