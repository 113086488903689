import { Button } from 'antd';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { SocialMedia } from '../../_shared/components/social-media';
import { calculateReferralBonus } from '../../_shared/services/utils';
import { ReferralModal } from './ReferralModalContainer.jsx';
import {
	BonusAmount,
	BtnContainer as ButtonContainer,
	CancelBtn as CancelButton,
	ContactName,
	JobTitle,
	MiddleContainer,
	MiscText,
	ReferralBonus,
	ReferralContainer,
	Title,
	TitleName,
} from './referralNotificationStyles.js';

function ReferralNotification(props) {
	const { referrals, notification, onUpdateNotification, removeRefer } = props;
	const { requestingUser, contact, job } = notification;
	const contacts = get(props, 'currentUser.contacts');
	const contactIncentiveBonus = get(
		props,
		'currentUser.company.contactIncentiveBonus'
	);

	const handleDontRefer = (e) => {
		e.preventDefault();
		onUpdateNotification({
			input: {
				id: notification.id,
				type: notification.type,
				referralRequestedStatus: 'declined',
			},
		});
		removeRefer(notification.id);
	};

	return (
		<div className={ReferralContainer}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					justifyContent: 'center',
					maxWidth: 400,
					marginLeft: 30,
				}}
			>
				<h3 className={Title}>
					<span className={TitleName}>
						{requestingUser.firstName} {requestingUser.lastName}
					</span>{' '}
					has requested a referral!
				</h3>
				<span />
				{/* inline style is the only way I could figure out how to change line height */}
				<span className={MiscText} style={{ lineHeight: 1 }}>
					{requestingUser.firstName} doesn&#39;t know who your contact is but
					our system matched them as a candidate for an open position!
				</span>
			</div>
			<div
				style={{
					display: 'flex',

					height: '100%',
					marginLeft: 20,
					marginRight: 20,
				}}
			>
				<div className={MiddleContainer}>
					{contact && (
						<div style={{ marginRight: 10 }}>
							<h3 className={ContactName}>
								{contact.firstName} {contact.lastName}
							</h3>
							<SocialMedia
								email={contact.emailaddress}
								socialMedia={contact.socialMediaAccounts}
								size={20}
							/>
						</div>
					)}
					<p
						style={{
							fontSize: 16,
							textAlign: 'center',
							marginBottom: 0,
							marginTop: 2,
						}}
					>
						for
					</p>
					<div style={{ marginLeft: 10 }}>
						<Link to={`/browsejobs/${get(job, 'id')}`} className={JobTitle}>
							{get(job, 'title')}
						</Link>
						{get(job, 'referralBonus') && get(job, 'referralBonus.hasBonus') ? (
							<span className={ReferralBonus}>
								Referral Bonus:{' '}
								<span className={BonusAmount}>
									{`$${calculateReferralBonus(
										contactIncentiveBonus,
										get(job, 'referralBonus.amount'),
										contacts
									)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')}
								</span>
							</span>
						) : null}
					</div>
				</div>
			</div>

			<div className={ButtonContainer}>
				<ReferralModal
					notification={notification}
					jobData={job}
					job={job}
					contact={contact}
					referrals={referrals}
					onUpdateNotification={onUpdateNotification}
					onCreateReferral={props.onCreateReferral}
				/>
				<Button className={CancelButton} onClick={handleDontRefer}>
					Don&#39;t Refer
				</Button>
			</div>
		</div>
	);
}

export default ReferralNotification;
