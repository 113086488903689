import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { USER_ROLES } from '../../../_shared/constants';
import {
	calculateTotalBonuses,
	calculateTotalBonusesDashboard,
	ml,
} from '../../../_shared/services/utils';
import { COLORS } from '../../../_shared/styles/colors';

class BaseReferenceCard extends Component {
	getReferralAcceptedColor() {
		if (
			this.props.acceptedReferralCounts &&
			this.props.acceptedReferralCounts.current >
				this.props.acceptedReferralCounts.previous
		) {
			return COLORS.dashboardGreen;
		}

		if (
			this.props.acceptedReferralCounts &&
			this.props.acceptedReferralCounts.current <
				this.props.acceptedReferralCounts.previous
		) {
			return COLORS.red;
		}

		if (
			this.props.acceptedReferralCounts &&
			(this.props.acceptedReferralCounts.current === 0 ||
				this.props.acceptedReferralCounts.current ===
					this.props.acceptedReferralCounts.previous)
		) {
			return COLORS.dashboardLightOrange;
		}
	}

	getReferralAcceptedStatus() {
		if (
			this.props.acceptedReferralCounts &&
			this.props.acceptedReferralCounts.current >
				this.props.acceptedReferralCounts.previous
		) {
			return 'arrow-up';
		}

		if (
			this.props.acceptedReferralCounts &&
			this.props.acceptedReferralCounts.current <
				this.props.acceptedReferralCounts.previous
		) {
			return 'arrow-down';
		}

		if (
			this.props.acceptedReferralCounts &&
			(this.props.acceptedReferralCounts.current === 0 ||
				this.props.acceptedReferralCounts.current ===
					this.props.acceptedReferralCounts.previous)
		) {
			return 'minus';
		}
	}

	getReferralColor() {
		if (
			this.props.referralCounts &&
			this.props.referralCounts.current > this.props.referralCounts.previous
		) {
			return COLORS.dashboardGreen;
		}

		if (
			this.props.referralCounts &&
			this.props.referralCounts.current < this.props.referralCounts.previous
		) {
			return COLORS.red;
		}

		if (
			this.props.referralCounts &&
			(this.props.referralCounts.current === 0 ||
				this.props.referralCounts.current ===
					this.props.referralCounts.previous)
		) {
			return COLORS.dashboardLightOrange;
		}
	}

	getReferralStatus() {
		if (
			this.props.referralCounts &&
			this.props.referralCounts.current > this.props.referralCounts.previous
		) {
			return 'arrow-up';
		}

		if (
			this.props.referralCounts &&
			this.props.referralCounts.current < this.props.referralCounts.previous
		) {
			return 'arrow-down';
		}

		if (
			this.props.referralCounts &&
			(this.props.referralCounts.current === 0 ||
				this.props.referralCounts.current ===
					this.props.referralCounts.previous)
		) {
			return 'minus';
		}
	}

	render() {
		const {
			title,
			userDashboard,
			referralTitle,
			acceptedReferralsTitle,
			currentUser,
			referralBonuses,
			allMultiLingualData,
			referralsThisYear,
			referralsThisMonth,
			referralsLastMonth,
			acceptedThisMonth,
			acceptedLastMonth,
			height,
		} = this.props;
		const currencyCode = get(currentUser, 'userGroup.currency', 'USD');
		const hideBonus = get(currentUser, 'company.hideBonus', false);

		return (
			<div className={`d-card ${height === 'height-equal' && 'height-equal'}`}>
				{(currentUser.displayAs === USER_ROLES.EMPLOYEE ||
					currentUser.displayAs === USER_ROLES.MANAGER) && (
					<div className="d-card-head">
						<h4 className="d-title">
							{title}
						</h4>
					</div>
				)}
				{title === referralTitle && (
					<div className="d-card-head">
						<h4 className="d-title">
							{title}
						</h4>
					</div>
				)}
				{title === acceptedReferralsTitle && (
					<div className="d-card-head">
						<h4 className="d-title">
							{title}
						</h4>
					</div>
				)}
				{currentUser.displayAs !== USER_ROLES.EMPLOYEE &&
					currentUser.displayAs !== USER_ROLES.MANAGER && (
						<div className="d-card-body vh-center">
							<div style={{ width: '100%' }}>
								{title ===
									ml('Referrals Made', currentUser, allMultiLingualData) && (
									<div
										className="data-card light-text bg-6294FF"
										style={{ maxWidth: 'none', marginBottom: 20 }}
									>
										<h5>
											{referralsThisYear
												? Number.parseInt(referralsThisYear).toLocaleString(
														'en-US'
													)
												: '0'}
										</h5>
										<p>
											{ml('Made This Year', currentUser, allMultiLingualData)}
										</p>
									</div>
								)}
								<div className="justify-data-box">
									{title === referralTitle && (
										<div className="data-card dark-text bg-ffffff">
											<h5>
												{referralsThisMonth
													? Number.parseInt(referralsThisMonth).toLocaleString(
															'en-US'
														)
													: '0'}
											</h5>
											<p>
												{ml(
													'Made This Month',
													currentUser,
													allMultiLingualData
												)}
											</p>
										</div>
									)}
									{title === acceptedReferralsTitle && (
										<div className="data-card dark-text bg-ffffff">
											<h5>
												{acceptedThisMonth
													? Number.parseInt(acceptedThisMonth).toLocaleString(
															'en-US'
														)
													: '0'}
											</h5>
											<p>
												{ml(
													'Accepted This Month',
													currentUser,
													allMultiLingualData
												)}
											</p>
										</div>
									)}

									{title === referralTitle && (
										<div className="data-card dark-text bg-ffffff">
											<h5>
												{referralsLastMonth
													? Number.parseInt(referralsLastMonth).toLocaleString(
															'en-US'
														)
													: '0'}
											</h5>
											<p>
												{ml(
													'Made Last Month',
													currentUser,
													allMultiLingualData
												)}
											</p>
										</div>
									)}
									{title === acceptedReferralsTitle && (
										<div className="data-card dark-text bg-ffffff">
											<h5>
												{acceptedLastMonth
													? Number.parseInt(acceptedLastMonth).toLocaleString(
															'en-US'
														)
													: '0'}
											</h5>
											<p>
												{ml(
													'Accepted Last Month',
													currentUser,
													allMultiLingualData
												)}
											</p>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				{(currentUser.displayAs === USER_ROLES.EMPLOYEE ||
					currentUser.displayAs === USER_ROLES.MANAGER) && (
					<div className="d-card-body vh-center aaa">
						<div className="justify-data-box">
							{title ===
								ml('Your Referrals', currentUser, allMultiLingualData) && (
								<div
									className="data-card dark-text bg-ffffff"
									style={
										currentUser.displayAs === USER_ROLES.EMPLOYEE &&
										hideBonus &&
										title ===
											ml('Your Referrals', currentUser, allMultiLingualData)
											? { maxWidth: 'none' }
											: {}
									}
								>
									{userDashboard && userDashboard?.totalReferrals >= 1 ? (
										<>
											<h5>
												<Link to="/myreferrals" className="font-weight-bold">
													{userDashboard.totalReferrals}
												</Link>
											</h5>
											<p>{ml('Referrals', currentUser, allMultiLingualData)}</p>
										</>
									) : (
										<>
											<h5>0</h5>
											<p>
												<Link to="/browsejobs">{ml('Click Here', currentUser, allMultiLingualData)}</Link> {ml('to make your first referral', currentUser, allMultiLingualData)}
											</p>
										</>
									)}
								</div>
							)}
							{currentUser.displayAs !== USER_ROLES.EMPLOYEE &&
							title ===
								ml('Your Referrals', currentUser, allMultiLingualData) ? (
								<div className="data-card light-text bg-6CB761">
									<h5>
										{getSymbolFromCurrency(currencyCode)}
										{calculateTotalBonuses(referralBonuses, true, 1)}
									</h5>
									<p> {ml('Earned', currentUser, allMultiLingualData)} </p>
								</div>
							) : currentUser.displayAs === USER_ROLES.EMPLOYEE &&
							  !hideBonus &&
							  title ===
									ml('Your Referrals', currentUser, allMultiLingualData) ? (
								<div className="data-card light-text bg-6CB761">
									<h5>
										{getSymbolFromCurrency(currencyCode)}
										{calculateTotalBonusesDashboard(referralBonuses, true, 1)}
									</h5>
									<p>
										{ml('Total Bonuses', currentUser, allMultiLingualData)}
									</p>
								</div>
							) : null}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export const ReferralCard = BaseReferenceCard;
