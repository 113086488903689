import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import NomintaionRewardsList from '../../presentation/NomintaionRewardsList';
import { useAllRewardsService } from '../../service/AllRewards.service.jsx';

const NomintaionRewardsListContainer = (props) => {
    const {
        title,
        openNomination,
        nominatedAwardWinner
    } = props;
    const [badgeList, setBadgeList] = useState([])
    const allRewardsAPIService = useAllRewardsService();

    useEffect(() => {
        getAllBadgeList()
    }, [])

    const getAllBadgeList = async () => {
        if (openNomination) {
            try {
                const response = await allRewardsAPIService.getAllOpenNominations()
                if (response?.code === 200) {
                    if (response?.data) {
                        setBadgeList(response.data)
                    }
                }
            } catch {
                message.error('Failed to fetch badges list! Try again later.');
            }
        } else {
            try {
                const response = await allRewardsAPIService.getAllSubmittedNominations()
                if (response?.code === 200) {
                    if (response?.data) {
                        setBadgeList(response.data)
                    }
                }
            } catch {
                message.error('Failed to fetch badges list! Try again later.');
            };
        }
    };

    return (
        <NomintaionRewardsList
            title={title}
            openNomination={openNomination}
            badgeList={badgeList}
            nominatedAwardWinner={nominatedAwardWinner}
            emptyString={props.emptyString}
        />
    );
};

export default NomintaionRewardsListContainer;
