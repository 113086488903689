import gql from 'graphql-tag';

export const GetJobMatchesById = gql`
	query GetJob($id: ID!) {
		getJob(id: $id) {
			id
			jobMatches {
				id
				contactId
				userId
				jobId
				matchStatus
				relevance
				dateCreated
			}
		}
	}
`;
