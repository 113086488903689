import { List } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { getSetErrorImageURL, ml } from 'src/_shared/services/utils.js';
import fileIcon from 'src/_shared/assets/erin_lightgray.png';
import { JobMatchCard } from 'src/dashboard/tiles/job-match-card/index.js';
import { Link } from 'react-router-dom';

class JobForYouCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isExtendedUser: get(props, 'currentUser.role') === 'extendedUser',
			contactsNextToken: true,
			currencyRate: 0,
			currencySymbol: '$',
			companyData: props.companyData,
			errorImageURL: '',
		};
	}

	async componentDidMount() {
		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(currentUser?.company?.errorImage?.key);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	render() {
		const {
			currentUser,
			allMultiLingualData,
			onCreateReferral,
			handleCareerProfile,
			allJobMatches,
			notInterested,
			allowSelfReferrals,
			allowSelfReferralsInternalLink,
			theme,
			height,
		} = this.props;
		const { errorImageURL } = this.state;

		const whiteLabel = get(currentUser, 'company.whiteLabel');
		return (
			<div
				className={`d-card dashboard-pagination ${
					height === 'height-equal' && 'height-equal'
				}`}
			>
				{allJobMatches.length > 0 ? (
					<>
						<div className="d-card-head">
							<h4 className="d-title">JOBS FOR YOU</h4>
						</div>
						<List
							dataSource={allJobMatches}
							grid={{ gutter: 1, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }}
							className="job-card-wrap"
							itemLayout="horizontal"
							pagination={
								allJobMatches.length > 1
									? {
											pageSize: 1,
											position: 'top',
											pagination: false,
										}
									: true
							}
							renderItem={(jobMatch) => (
								<List.Item>
									<JobMatchCard
										handleCareerProfile={handleCareerProfile}
										allMultiLingualData={allMultiLingualData}
										currentUser={currentUser}
										jobMatch={jobMatch}
										matchedJobs={allJobMatches}
										notInterested={notInterested}
										allowSelfReferrals={allowSelfReferrals}
										allowSelfReferralsInternalLink={
											allowSelfReferralsInternalLink
										}
										theme={theme}
										onCreateReferral={onCreateReferral}
									/>
								</List.Item>
							)}
						/>
					</>
				) : (
					<div className="no-content">
						{whiteLabel ? (
							<img
								src={errorImageURL}
								alt="error image"
								className="no-content-icon"
							/>
						) : (
							<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
						)}
						{currentUser?.careerProfile ? (
							<p className="no-content-text">
								{ml(
									`We don’t have any recommended positions at this time.`,
									currentUser,
									allMultiLingualData
								)}
								<br />
								{ml('Check back soon!', currentUser, allMultiLingualData)}
							</p>
						) : (
							<p className="no-content-text">
								<Link to="/career">
									{ml('Import your resume', currentUser, allMultiLingualData)}{' '}
								</Link>
								{ml(
									`to be automatically matched with open positions.`,
									currentUser,
									allMultiLingualData
								)}
							</p>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default JobForYouCard;
