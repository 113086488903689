import { Button, List, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import dompurify from 'dompurify';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { queryDepartmentsByCompanyIdIndex } from 'src/_shared/api/graphql/custom/departments/';
import { GetJob, createJob } from 'src/_shared/api/graphql/custom/jobs/';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { JOB_HOTNESS } from 'src/_shared/constants/';
import Logo from '../_shared/assets/erin_lightgray.png';
import { SearchComponent } from '../_shared/components/search';
import { ToggleOpenClosedNew } from 'src/_shared/components/toggleOpenClosedNew/ToggleOpenClosedNewComponent.jsx';
import { JOB_STATUSES, USER_ROLES } from 'src/_shared/constants/index.js';
import {
	getSetErrorImageURL,
	getLocation,
	logout,
	ml,
	parse,
	searchOpenSearchNetwork,
} from 'src/_shared/services/utils.js';
import { AddJob } from './addJobModal';
import {
	miscFieldErrors,
	referralBonusErrors,
	requiredFields,
} from './errorHandlers.js';
import JobCard from './jobCard/jobCardContainer.js';
import {
	SelectContainer,
	SelectStylesNew,
	SelectStylesNewActive,
	noJobs,
} from './manageJobsStyles.js';

const sanitizer = dompurify.sanitize;
const filterOpenKey = JOB_STATUSES.OPEN;
const filterClosedKey = JOB_STATUSES.CLOSED;
const filterHotKey = JOB_HOTNESS.HOT;

const { Option } = Select;

class ManageJobsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			loading: true,
			visible: false,
			filteredDepartments: [],
			filteredBonuses: [],
			filteredRecruiters: [],
			filteredSubCompanies: [],
			filteredJobLevels: [],
			filteredAvailables: [],
			loadingSpinner: false,
			searchQuery: '',
			page: 0,
			errors: {},
			filterOpenStatus: filterOpenKey,
			filterHotStatus: filterHotKey,
			text: 'Open Jobs',
			hotText: 'All Jobs',
			buttonState: '',
			theme: parse(get(props, 'currentUser.company.theme', '{}')),
			managers: [],
			location: {
				city: '',
				state: '',
				lat: '',
				lng: '',
				address: '',
			},
			subCompanyLabel: get(
				props,
				'currentUser.company.subCompanyLabel',
				'Company'
			)
				? get(props, 'currentUser.company.subCompanyLabel', 'Company')
				: 'Company',
			errorImageURL: '',
		};
		this.filterJobs = this.filterJobs.bind(this);
	}

	async componentDidMount() {
		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}
		await this.setQueryToState(get(this.state, 'searchQuery', ''));
		await this.setQueryAdminsAndManagersToState('');
		await this.getDepartment();
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		if (this.props.location.pathname === '/jobs/add') this.showModal();

		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(currentUser?.company?.errorImage?.key);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	setQueryAdminsAndManagersToState = async (
		searchQueryUsers = '',
		timeout = 0
	) => {
		this.setState({ searchQueryUsers });
		clearTimeout(this.userTimer);
		this.userTimer = setTimeout(async () => {
			const parameters = {
				query: '',
				size: 300,
				from: 0,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					active: true,
					companies: [get(this.props, 'currentUser.companyId')],
					roles: ['admin', 'manager'],
				},
			};
			const response = await searchOpenSearchNetwork(parameters, 'erin-users');
			if (get(response, 'query') === get(this.state, 'searchQueryUsers')) {
				this.setState({
					recruiters: get(response, 'data', []),
				});
			}
		}, timeout);
	};

	async getDepartment() {
		const { client, currentUser } = this.props;
		try {
			client
				.query({
					query: gql(queryDepartmentsByCompanyIdIndex),
					variables: {
						companyId: get(currentUser, 'companyId'),
						first: 1000,
					},
				})
				.then((data) => {
					const departments = get(
						data,
						'data.queryDepartmentsByCompanyIdIndex.items'
					);
					const filterDep = departments.filter((x) => {
						if (x.active) {
							return x;
						}
					});
					this.setState({ departments: filterDep });
				});
		} catch (error) {
			console.log(error);
		}
	}

	async getJob(jobId) {
		const { client } = this.props;
		try {
			const { data } = await client.query({
				query: gql(GetJob),
				variables: {
					id: jobId,
				},
				fetchPolicy: 'network-only',
			});
			const job = get(data, 'getJob');
			return job;
		} catch (error) {
			console.log(error);
		}
	}

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setQueryToState = async (searchQuery = '') => {
		const {
			company,
			filteredDepartments = [],
			filteredDistance = 0,
			filteredBonuses = [],
			filteredRecruiters = [],
			filterOpenStatus,
			lat,
			lng,
			filteredSubCompanies = [],
			filteredJobLevels = [],
		} = this.state;
		clearTimeout(this.timer);
		this.setState({ searchQuery });

		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			const parameters = {
				query: searchQuery,
				size: 300,
				coordinates: [{ lat, lng }],
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					bonuses: filteredBonuses,
					companies: [get(company, 'id')],
					departments: filteredDepartments,
					distance: filteredDistance,
					recruiters: filteredRecruiters,
					status: filterOpenStatus,
					subCompanies: filteredSubCompanies,
					jobLevels: filteredJobLevels,
				},
			};
			const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				const searchedJobs = get(response, 'data', []);
				this.setState({ loading: false, searchedJobs });
			}
		}, 700);
	};

	addNewJob = async (job) => {
		const { client } = this.props;
		const { allJobs = [], searchQuery } = this.state;
		try {
			await client
				.mutate({
					mutation: gql(createJob),
					variables: {
						input: {
							...job,
						},
					},
				})
				.then((res) => {
					const job = get(res, 'data.createJob');
					const newJobs = allJobs;
					newJobs.unshift(job);
					this.setState(
						{
							allJobs: newJobs,
							filteredJobs: newJobs,
							searchedJobs: newJobs,
						},
						async () => await this.setQueryToState(searchQuery)
					);
				});
		} catch (error) {
			console.log(error);
		}
	};

	filterJobs = (property, match, jobSet) => {
		return jobSet.filter((job) => job[property] === match);
	};

	handleBack = () => {
		this.setState({ page: this.state.page > 0 ? this.state.page - 1 : 0 });
	};

	handleCancel = () => {
		this.setState({ visible: false });
		this.resetForm();
	};

	handleFilterBonus = (bonus) => {
		let { filteredBonuses = [], searchQuery = '' } = this.state;
		if (filteredBonuses.map((b) => b).includes(bonus)) return;
		filteredBonuses = [...filteredBonuses, bonus];
		this.setState(
			{
				filteredBonuses,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterDepartment = (department) => {
		let { filteredDepartments = [], searchQuery = '' } = this.state;
		if (filteredDepartments.indexOf(department) > -1) return;
		filteredDepartments = [...filteredDepartments, department];
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterJobLevel = (jobLevel, data) => {
		let { filteredJobLevels = [], searchQuery = '' } = this.state;
		const jobLvl = get(data, 'props.jobLevel');
		if (filteredJobLevels.map((b) => b.id).includes(jobLevel)) return;
		filteredJobLevels = [...filteredJobLevels, jobLvl];
		this.setState(
			{
				filteredJobLevels,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterRecruiter = (recruiter) => {
		let { filteredRecruiters = [], searchQuery = '' } = this.state;
		if (filteredRecruiters.map((r) => r).includes(recruiter)) return;
		filteredRecruiters = [...filteredRecruiters, recruiter];
		this.setState(
			{
				filteredRecruiters,
			},
			async () => await this.setQueryToState(searchQuery)
		);
		async () => await this.setQueryToState(searchQuery);
	};

	handleFilterSubCompany = (subCompany) => {
		let { filteredSubCompanies = [], searchQuery = '' } = this.state;
		if (filteredSubCompanies.map((b) => b).includes(subCompany)) return;
		filteredSubCompanies = [...filteredSubCompanies, subCompany];
		this.setState(
			{
				filteredSubCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleHotJobs = (value) => {
		this.setState({ hotText: value });
	};

	handleNext = (page, values) => {
		if (this.pageIsValid(this.state.page, values)) {
			this.setState(
				{ page: this.state.page < 3 ? this.state.page + 1 : 3 },
				() => {
					this.props.updateAddJobForm(values);
				}
			);
		}
	};

	handleOk = () => {
		this.setState({ page: 0 });
	};

	handleRemoveBonusFilter = (bonus) => {
		const { filteredBonuses = [], searchQuery = '' } = this.state;
		const index = filteredBonuses.map((b) => b).indexOf(bonus);
		filteredBonuses.splice(index, 1);
		this.setState(
			{
				filteredBonuses,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveDepartmentFilter = (department) => {
		let { filteredDepartments, searchQuery } = this.state;
		const index = filteredDepartments.indexOf(department);
		filteredDepartments.splice(index, 1);
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveJobLevelFilter = (jobLevel) => {
		const { filteredJobLevels = [], searchQuery = '' } = this.state;
		const index = filteredJobLevels.map((b) => b.id).indexOf(jobLevel);
		filteredJobLevels.splice(index, 1);
		this.setState(
			{
				filteredJobLevels,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveRecruiterFilter = (recruiter) => {
		const { filteredRecruiters = [], searchQuery = '' } = this.state;
		const index = filteredRecruiters.map((r) => r).indexOf(recruiter);
		filteredRecruiters.splice(index, 1);
		this.setState(
			{
				filteredRecruiters,
			},
			async () => await this.setQueryToState(searchQuery)
		);
		async () => await this.setQueryToState(searchQuery);
	};

	handleRemoveSubCompanyFilter = (subCompany) => {
		const { filteredSubCompanies = [], searchQuery = '' } = this.state;
		const index = filteredSubCompanies.map((b) => b).indexOf(subCompany);
		filteredSubCompanies.splice(index, 1);
		this.setState(
			{
				filteredSubCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleSelectJob = (job) => {
		this.props.setCurrentJob(job);
		this.props.history.push({
			pathname: `/jobs/${job.id}`,
			state: { job },
		});
	};

	handleSubmit = async () => {
		const { currentUser, currentJob } = this.props;
		let location = get(currentJob, 'location', {});
		if (!get(currentJob, 'location.isRemote')) {
			location = await getLocation({ city: location?.city, state: location?.state });
		}

		let { jobType } = currentJob;
		if (jobType) {
			jobType = sanitizer(jobType);
			if (jobType == 'others') {
				jobType = currentJob.otherJobType;
			}
		}

		let { title } = currentJob;
		title &&= sanitizer(title);
		let { description } = currentJob;
		description &&= sanitizer(description);
		let { publicLink } = currentJob;
		publicLink &&= sanitizer(publicLink);
		let { externalJobId } = currentJob;
		externalJobId &&= sanitizer(externalJobId);

		let job = {
			companyId: currentUser.companyId,
			departmentId: currentJob.department.id,
			jobType,
			title,
			description,
			publicLink: currentJob.publicLink === '' ? null : publicLink,
			salary: JSON.stringify({
				from: currentJob.salary.from,
				to: currentJob.salary.to,
				interval:
					currentJob.salary.to || currentJob.salary.from
						? currentJob.salary.interval
						: null,
			}),
			location: JSON.stringify({
				...location,
				city: currentJob.location.isRemote ? null : currentJob.location.city,
				state: currentJob.location.isRemote ? null : currentJob.location.state,
				isRemote: currentJob.location.isRemote,
			}),
			hiringManagerId: currentJob.hiringManager
				? currentJob.hiringManager.id
				: null,
			createdById: currentUser.id,
			status: JOB_STATUSES.OPEN,
			referralBonus: JSON.stringify({
				hasBonus: currentJob.referralBonus.hasBonus,
				amount: currentJob.referralBonus.hasBonus
					? currentJob.referralBonus.amount
					: null,
				...(currentJob.referralBonus.tieredBonusId
					? { tieredBonusId: currentJob.referralBonus.tieredBonusId }
					: {}),
			}),
			notificationType: currentJob.notificationType
				? currentJob.notificationType
				: 'NONE',
			shares: 0,
			views: 0,
			lat: currentJob.location.isRemote ? null : this.state.lat,
			lng: currentJob.location.isRemote ? null : this.state.lng,
			isGeneralReferral: Boolean(currentJob.isGeneralReferral),
		};

		if (currentJob.subCompanyId) {
			job.subCompanyId = currentJob.subCompanyId;
		}

		if (currentJob.externalJobId && currentJob.externalJobId !== '') {
			job.externalJobId = currentJob.externalJobId;
		}

		await this.addNewJob(job);
		this.setState(
			{
				buttonState: 'loading',
			},
			() => {
				setTimeout(() => {
					this.setState({
						buttonState: 'success',
					});
				}, 5400);
			}
		);
		setTimeout(() => {
			this.setState({
				visible: false,
			});
		}, 6000);
	};

	pageIsValid = (currentPage, currentJob) => {
		let isValid = true;
		const errors = {};
		for (const { key, message, page } of requiredFields) {
			if (key === 'location' && page == currentPage) {
				const city = get(currentJob, `location.city`, '');
				const isRemote = get(currentJob, `location.isRemote`, false);
				if (isRemote == false && (city == '' || city == null)) {
					errors[key] = message;
					isValid = false;
				}
			} else if (!currentJob[key] && page === currentPage) {
				errors[key] = message;
				isValid = false;
			}
		}

		for (const { key, message, page } of referralBonusErrors) {
			const { referralBonus } = currentJob;
			if (
				referralBonus &&
				!referralBonus.amount &&
				!referralBonus.tieredBonusId &&
				referralBonus.hasBonus === true &&
				page === currentPage
			) {
				errors[key] = message;
				isValid = false;
			}
		}

		for (const { key, message, page } of miscFieldErrors) {
			const { salary } = currentJob;
			if (page === currentPage && salary && (!salary.to || !salary.from)) {
				continue;
			}

			if (page === currentPage && salary && salary.from > salary.to) {
				errors[key] = message;
				isValid = false;
			}
		}

		this.setState({
			errors,
		});

		return isValid;
	};

	resetForm = () => {
		this.props.resetFormFields();
		this.setState({
			page: 0,
			errors: {},
		});
	};

	showCancelScreen = () => {
		this.setState({ page: 4 });
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	sortByAlph = (a, b) => {
		if (!a) return -1;
		if (!b) return 1;
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	sortByDateCreated(data) {
		const sortByDate = data.sort((a, b) =>
			this.sortByAlph(dayjs(b.dateCreated), dayjs(a.dateCreated))
		);
		const sortedData = sortByDate.sort((a, b) => b.isHotJob - a.isHotJob);
		return sortedData;
	}

	updateJob(job) {
		const searchedJobs = get(this.state, 'searchedJobs', []);
		const sJobs = searchedJobs ? searchedJobs : [];
		const removeIndex = sJobs
			.map(function (item) {
				return get(item, 'id');
			})
			.indexOf(get(job, 'id'));
		if (removeIndex >= 0) {
			~removeIndex && sJobs.splice(removeIndex, 1);
		} else {
			sJobs.push(job);
		}

		if (removeIndex >= 0) sJobs.splice(removeIndex, 0, job);
		this.setState({ searchedJobs: sJobs });
	}

	renderArrowIcon = (count) => {
		return (
			<div>
				<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
			</div>
		);
	};

	render() {
		const {
			company,
			visible,
			recruiters = [],
			page,
			errors,
			loading,
			filteredDistance = '',
			filteredDepartments = [],
			searchQuery = '',
			buttonState,
			filteredRecruiters,
			filterOpenStatus,
			text,
			theme,
			departments,
			filteredBonuses,
			filteredSubCompanies,
			filteredJobLevels,
			errorImageURL,
		} = this.state;
		const {
			allMultiLingualData,
			subCompanies,
			currentUser,
			currentJob,
			bonuses,
			jobLevels = [],
		} = this.props;
		if (!get(currentUser, 'company')) return <Spinner />;
		const contactIncentiveBonus = get(
			this.props,
			'currentUser.company.contactIncentiveBonus'
		);
		const contacts = get(this.props, 'currentUser.contacts');
		const whiteLabel = get(company, 'whiteLabel');
		let isManagerPermissionDisabled = get(
			currentUser,
			'company.disableManagerPermissions',
			false
		);
		// Preserves backwards compatibility and future compatibility with disableManagerPermissions
		const managerJobs = currentUser.company?.disableManagerPermissionsByType
			? JSON.parse(currentUser.company.disableManagerPermissionsByType)
					.managerJobs
			: isManagerPermissionDisabled
				? 'readOnly'
				: 'edit';
		isManagerPermissionDisabled = managerJobs !== 'edit';

		let sortedAdminAndManagers = _.orderBy(
			recruiters,
			['firstName', 'lastName'],
			['asc', 'asc']
		);

		let recruiterOptions = sortedAdminAndManagers.map((recruiter) => {
			return (
				<Option key={get(recruiter, 'id')} value={get(recruiter, 'id')}>
					{`${get(recruiter, 'firstName')} ${get(recruiter, 'lastName')}`}
				</Option>
			);
		});

		const role = get(currentUser, 'role');

		const filterOn =
			(filteredDistance.length > 0 && filteredDistance != '0') || // Do not reorder if location is set but 'all locations' are displaying
			searchQuery.length > 0;

		const searchedJobs = filterOn
			? get(this.state, 'searchedJobs', [])
			: this.sortByDateCreated(get(this.state, 'searchedJobs', []));

		const bonusOptions = bonuses?.map((bonus) => {
			return (
				<Option key={get(bonus, 'id')} value={get(bonus, 'id')}>
					{get(bonus, 'name')}
				</Option>
			);
		});

		let sortedDepartments = _.sortBy(this.state.departments, ['name']);
		sortedDepartments = sortedDepartments.filter(
			(item) => item.active === true
		);
		const options = sortedDepartments.map((department) => {
			return (
				<Option key={department.id} value={department.id}>
					{department.name}
				</Option>
			);
		});
		const jobLevs = _.sortBy(jobLevels, ['name']);
		const jobLevelOptions = jobLevs.map((jobLevel) => {
			return (
				<Option
					key={get(jobLevel, 'id')}
					value={get(jobLevel, 'id')}
					jobLevel={jobLevel}
				>
					{get(jobLevel, 'name')}
				</Option>
			);
		});
		const isPartner =
			get(this.props, 'currentUser.company.accountType') === 'partner';
		return (
			<main>
				<div className="page-title">
					<SearchComponent
						searchQuery={searchQuery}
						setQueryToState={this.setQueryToState}
						placeholder={ml('Search Jobs', currentUser, allMultiLingualData)}
					/>
					{(isManagerPermissionDisabled &&
						currentUser.displayAs === USER_ROLES.MANAGER) ||
					isPartner ? (
						<div />
					) : (
						<ul className="info-action">
							<li>
								<Button
									type="link"
									className="add-btn btn-lg"
									onClick={this.showModal}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									<span>{ml('Add Job', currentUser, allMultiLingualData)}</span>
								</Button>
							</li>
						</ul>
					)}
				</div>
				<div className="custom-filter">
					<div className="filter-wrap">
						<Select
							showArrow
							filterOption={(input, option) => {
								return option.props.children
									.toLowerCase()
									.includes(input.toLowerCase());
							}}
							placeholder={ml(
								this.state.hotText,
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
							maxTagCount={1}
							maxTagTextLength={10}
							suffixIcon={this.renderArrowIcon(null)}
							onChange={this.handleHotJobs}
						>
							<Option value="All Jobs">
								{ml('All Jobs', currentUser, allMultiLingualData)}
							</Option>
							<Option value="Hot Jobs">
								{ml('Hot Jobs', currentUser, allMultiLingualData)}
							</Option>
						</Select>
						{get(this.props, 'subCompanies', []).length > 0 && (
							<Select
								showArrow
								filterOption={(input, option) => {
									return option.props.children
										.toLowerCase()
										.includes(input.toLowerCase());
								}}
								mode="multiple"
								placeholder={ml(
									`${this.state.subCompanyLabel}`,
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
								maxTagCount={1}
								maxTagTextLength={15}
								suffixIcon={this.renderArrowIcon(filteredSubCompanies.length)}
								onSelect={(subCompany) =>
									this.handleFilterSubCompany(subCompany)
								}
								onDeselect={(subCompany) =>
									this.handleRemoveSubCompanyFilter(subCompany)
								}
							>
								{subCompanies.map((subCompany) => {
									return <Option key={subCompany.id}>{subCompany.name}</Option>;
								})}
							</Select>
						)}
						<Select
							showArrow
							filterOption={(input, option) => {
								return option.props.children
									.toLowerCase()
									.includes(input.toLowerCase());
							}}
							mode="multiple"
							placeholder={ml(
								'Recruiter',
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredRecruiters.length)}
							onSelect={(recruiter) => this.handleFilterRecruiter(recruiter)}
							onDeselect={(recruiter) =>
								this.handleRemoveRecruiterFilter(recruiter)
							}
						>
							{recruiterOptions}
						</Select>
						<Select
							showArrow
							filterOption={(input, option) => {
								return option.props.children
									.toLowerCase()
									.includes(input.toLowerCase());
							}}
							mode="multiple"
							placeholder={ml(
								'Bonus',
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredBonuses.length)}
							onSelect={(bonus) => this.handleFilterBonus(bonus)}
							onDeselect={(bonus) => this.handleRemoveBonusFilter(bonus)}
						>
							{bonusOptions}
						</Select>
						<Select
							showArrow
							filterOption={(input, option) => {
								return option.props.children
									.toLowerCase()
									.includes(input.toLowerCase());
							}}
							mode="multiple"
							placeholder={ml('Department', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredDepartments.length)}
							onSelect={(department, { key }) =>
								this.handleFilterDepartment(department, key)
							}
							onDeselect={(department) =>
								this.handleRemoveDepartmentFilter(department)
							}
						>
							{options}
						</Select>
						{jobLevelOptions && jobLevelOptions.length > 0 && (
							<div className={SelectContainer}>
								<Select
									showArrow
									filterOption={(input, option) => {
										return option.props.children
											.toLowerCase()
											.includes(input.toLowerCase());
									}}
									className={
										filteredJobLevels.length > 0
											? SelectStylesNewActive(filteredJobLevels.length)
											: SelectStylesNew
									}
									mode="multiple"
									placeholder={ml(
										'Job Level',
										this.props.currentUser,
										this.props.allMultiLingualData
									)}
									maxTagCount={1}
									maxTagTextLength={15}
									suffixIcon={this.renderArrowIcon(filteredJobLevels.length)}
									onSelect={(jobLevel, data) =>
										this.handleFilterJobLevel(jobLevel, data)
									}
									onDeselect={(jobLevel) =>
										this.handleRemoveJobLevelFilter(jobLevel)
									}
								>
									{jobLevelOptions}
								</Select>
							</div>
						)}
						<ToggleOpenClosedNew
							filterOpenStatus={filterOpenStatus}
							openJobsKey={filterOpenKey}
							closedJobsKey={filterClosedKey}
							text={text}
							openJobs={ml('Open Jobs', currentUser, allMultiLingualData)}
							closedJobs={ml('Closed Jobs', currentUser, allMultiLingualData)}
							currentUser={currentUser}
							allMultiLingualData={allMultiLingualData}
							onClick={(filterOpenStatus) => {
								this.setState(
									{
										filterOpenStatus,
										text:
											text === 'Open Jobs'
												? ml('Closed Jobs', currentUser, allMultiLingualData)
												: ml('Open Jobs', currentUser, allMultiLingualData),
									},
									async () => await this.setQueryToState(searchQuery)
								);
							}}
						/>
					</div>
				</div>
				<>
					{!loading || (searchedJobs && searchedJobs.length > 0) ? (
						<List
							className="job-card-wrap"
							grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 4 }}
							pagination={{ pageSize: 24, showSizeChanger: false }}
							dataSource={
								this.state.hotText === 'Hot Jobs'
									? searchedJobs.filter((item) => item.isHotJob === true)
									: searchedJobs.filter((item) => item.isHotJob === false)
							}
							renderItem={(job) => (
								<List.Item style={{ height: '100%' }}>
									<JobCard
										theme={theme}
										currentUser={currentUser}
										allMultiLingualData={allMultiLingualData}
										key={get(job, 'id', 'skip')}
										jobId={get(job, 'id', 'skip')}
										job={job}
										onClick={() => {
											this.handleSelectJob(job);
										}}
										contactIncentiveBonus={contactIncentiveBonus}
										contacts={contacts}
										bonus={ml('Bonus', currentUser, allMultiLingualData)}
									/>
								</List.Item>
							)}
						/>
					) : (!searchedJobs || searchedJobs.length === 0) && !loading ? (
						<div>
							{whiteLabel ? (
								<p className={noJobs}>
									<img src={errorImageURL} alt="error image" width="150px" />
								</p>
							) : (
								<p className={noJobs}>
									<img alt="erin-logo" width={150} src={Logo} />
								</p>
							)}
							<p className={noJobs}>There are no open jobs at this time.</p>
						</div>
					) : !loading ? (
						<div>
							{whiteLabel ? (
								<p className={noJobs}>
									<img src={errorImageURL} alt="error image" width="150px" />
								</p>
							) : (
								<p className={noJobs}>
									<img alt="erin-logo" width={150} src={Logo} />
								</p>
							)}
							<p className={noJobs}>There are no closed jobs.</p>
						</div>
					) : (
						<Spinner company={company} />
					)}
				</>

				<Modal
					destroyOnClose
					open={visible}
					width={620}
					title={ml('Create a New Job', currentUser, allMultiLingualData)}
					afterClose={this.resetForm.bind(this)}
					maskClosable={false}
					footer={null}
					closable={page !== 4}
					onOk={null}
					onCancel={this.showCancelScreen}
				>
					{!loading && (
						<AddJob
							isNew
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							handleNext={this.handleNext}
							handleBack={this.handleBack}
							page={page}
							managers={sortedAdminAndManagers}
							theme={theme}
							departments={departments}
							bonuses={bonuses}
							currentJob={currentJob}
							handleSubmit={this.handleSubmit}
							subCompanies={this.props.subCompanies}
							handleOk={this.handleOk}
							handleCancel={this.handleCancel}
							errors={errors}
							resetFormFields={this.props.resetFormFields}
							buttonState={buttonState}
							jobInfo={ml('Job Info', currentUser, allMultiLingualData)}
							description={ml('Description', currentUser, allMultiLingualData)}
							referralInfo={ml(
								'Referral Info',
								currentUser,
								allMultiLingualData
							)}
							review={ml('Review', currentUser, allMultiLingualData)}
						/>
					)}
				</Modal>
			</main>
		);
	}
}

export default withApollo(ManageJobsComponent);
