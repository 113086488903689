import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { parse } from 'src/_shared/services/utils';
import { findWithAttr as findWithAttribute } from '../_shared/services/utils';
import DetailsView from '../referral-details/ReferralDetailsContactsViewComponent.jsx';
import { myContactsKeys } from './myContactsKeys.js';

export class ContactDetailsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(this.props, 'currentUser.company'),
			contact: this.props.contact,
			selectedUser: props.currentUser,
			edit: props.history.location.state
				? props.history.location.state.edit
				: false,
			currencyRate: 1,
			currencySymbol: '$',
			ml_CancelAdd: '',
			ml_ConfirmNewPosition: '',
			ml_TieredBonus: '',
			ml_Mobile: '',
			ml_ReferralComments: '',
			ml_Status: '',
			ml_Resume: '',
			ml_AddGeneralReferral: '',
			ml_AddJobHistory: '',
			ml_ShowJobHistory: '',
			ml_ReferredOn: '',
			ml_ReferralReady: '',
			ml_Description: '',
			ml_Company: '',
			ml_DownloadResume: '',
			ml_ReferredBy: '',
			ml_EditProfile: '',
			ml_Organization: '',
			ml_StartDate: '',
			ml_EndDates: '',
			ml_MyContacts: '',
			ml_Referrals: '',
			ml_Position: '',
			ml_BonusDetails: '',
			ml_Job: '',
			ml_ReferredCandidate: '',
			ml_TotalBonus: '',
			ml_HiredDate: '',
			ml_BonusPayments: '',
			ml_BonusNotes: '',
			ml_Close: '',
			ml_ViewBonusDetails: '',

			ml_Referred: '',
			ml_Accepted: '',
			ml_Hired: '',
			ml_Interviewing: '',
			ml_NotHired: '',
		};
	}

	componentDidMount() {
		const { contact } = this.props;
		const saveContact = contact
			? contact
			: this.state.contact
				? this.state.contact
				: undefined;
		this.setState({ contact: saveContact });
		if (this.props.allMultiLingualData.length > 0) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const cancelAdd = filteredData.filter(
				(item) => item.key === myContactsKeys.CancelAdd
			);
			const confirmNewPosition = filteredData.filter(
				(item) => item.key === myContactsKeys.ConfirmNewPosition
			);
			const tieredBonus = filteredData.filter(
				(item) => item.key === myContactsKeys.TieredBonus
			);
			const mobile = filteredData.filter(
				(item) => item.key === myContactsKeys.Mobile
			);
			const referralComments = filteredData.filter(
				(item) => item.key === myContactsKeys.ReferralComments
			);
			const status = filteredData.filter(
				(item) => item.key === myContactsKeys.Status
			);
			const resume = filteredData.filter(
				(item) => item.key === myContactsKeys.Resume
			);
			const addGeneralReferral = filteredData.filter(
				(item) => item.key === myContactsKeys.AddGeneralReferral
			);
			const addJobHistory = filteredData.filter(
				(item) => item.key === myContactsKeys.AddJobHistory
			);
			const showJobHistory = filteredData.filter(
				(item) => item.key === myContactsKeys.ShowJobHistory
			);
			const referredOn = filteredData.filter(
				(item) => item.key === myContactsKeys.ReferredOn
			);
			const referralReady = filteredData.filter(
				(item) => item.key === myContactsKeys.ReferralReady
			);
			const description = filteredData.filter(
				(item) => item.key === myContactsKeys.Description
			);
			const company = filteredData.filter(
				(item) => item.key === myContactsKeys.Company
			);
			const emailPhoneNumber = filteredData.filter(
				(item) => item.key === myContactsKeys.EmailPhoneNumber
			);
			const downloadResume = filteredData.filter(
				(item) => item.key === myContactsKeys.DownloadResume
			);
			const referredBy = filteredData.filter(
				(item) => item.key === myContactsKeys.ReferredBy
			);
			const editProfile = filteredData.filter(
				(item) => item.key === myContactsKeys.EditProfile
			);
			const organization = filteredData.filter(
				(item) => item.key === myContactsKeys.Organization
			);
			const startDate = filteredData.filter(
				(item) => item.key === myContactsKeys.StartDate
			);
			const endDates = filteredData.filter(
				(item) => item.key === myContactsKeys.EndDates
			);
			const myContacts = filteredData.filter(
				(item) => item.key === myContactsKeys.MyContacts
			);
			const referrals = filteredData.filter(
				(item) => item.key === myContactsKeys.Referrals
			);
			const position = filteredData.filter(
				(item) => item.key === myContactsKeys.Position
			);
			const bonusDetail = filteredData.find(
				(item) => item.key === myContactsKeys.BonusDetails
			);
			const job = filteredData.find((item) => item.key === myContactsKeys.Job);
			const referredCandidate = filteredData.find(
				(item) => item.key === myContactsKeys.ReferredCandidate
			);
			const totalBonus = filteredData.find(
				(item) => item.key === myContactsKeys.TotalBonus
			);
			const hiredDate = filteredData.find(
				(item) => item.key === myContactsKeys.HiredDate
			);
			const bonusPayment = filteredData.find(
				(item) => item.key === myContactsKeys.BonusPayments
			);
			const bonusNote = filteredData.find(
				(item) => item.key === myContactsKeys.BonusNotes
			);
			const close = filteredData.find(
				(item) => item.key === myContactsKeys.Close
			);
			const viewBonusDetail = filteredData.find(
				(item) => item.key === myContactsKeys.ViewBonusDetails
			);
			const referred = filteredData.find(
				(item) => item.key === myContactsKeys.Referred
			);
			const accepted = filteredData.find(
				(item) => item.key === myContactsKeys.Accepted
			);
			const hired = filteredData.find(
				(item) => item.key === myContactsKeys.Hired
			);
			const interviewing = filteredData.find(
				(item) => item.key === myContactsKeys.Interviewing
			);
			const earned = filteredData.find(
				(item) => item.key === myContactsKeys.Earned
			);
			const notHired = filteredData.find(
				(item) => item.key === myContactsKeys.NotHired
			);
			const inActive = filteredData.find(
				(item) => item.key === myContactsKeys.InActive
			);

			const noResponse = filteredData.find(
				(item) => item.key === myContactsKeys.NoResponse
			);
			this.setState({
				ml_CancelAdd: cancelAdd.length > 0 ? cancelAdd[0].text : 'Cancel Add',
				ml_ConfirmNewPosition:
					confirmNewPosition.length > 0
						? confirmNewPosition[0].text
						: 'Confirm new position',
				ml_TieredBonus:
					tieredBonus.length > 0 ? tieredBonus[0].text : 'Tiered Bonus',
				ml_Mobile: mobile.length > 0 ? mobile[0].text : 'Mobile',
				ml_ReferralComments:
					referralComments.length > 0
						? referralComments[0].text
						: 'Referral Comments',
				ml_Status: status.length > 0 ? status[0].text : 'Status',
				ml_Resume: resume.length > 0 ? resume[0].text : 'Resume',
				ml_AddGeneralReferral:
					addGeneralReferral.length > 0
						? addGeneralReferral[0].text
						: 'Add General Referral',
				ml_AddJobHistory:
					addJobHistory.length > 0 ? addJobHistory[0].text : 'Add Job History',
				ml_ShowJobHistory:
					showJobHistory.length > 0
						? showJobHistory[0].text
						: 'Show Job History',
				ml_ReferredOn:
					referredOn.length > 0 ? referredOn[0].text : 'Referred on',
				ml_ReferralReady:
					referralReady.length > 0 ? referralReady[0].text : 'Referral Ready ',
				ml_Description:
					description.length > 0 ? description[0].text : 'Description',
				ml_Company: company.length > 0 ? company[0].text : 'Company',
				ml_EmailPhoneNumber:
					emailPhoneNumber.length > 0
						? emailPhoneNumber[0].text
						: 'Email / Phone Number',
				ml_DownloadResume:
					downloadResume.length > 0
						? downloadResume[0].text
						: 'Download Resume',
				ml_ReferredBy:
					referredBy.length > 0 ? referredBy[0].text : 'Referred By',
				ml_EditProfile:
					editProfile.length > 0 ? editProfile[0].text : 'Edit Profile',
				ml_Organization:
					organization.length > 0 ? organization[0].text : 'Organization',
				ml_StartDate: startDate.length > 0 ? startDate[0].text : 'Start Date',
				ml_EndDates: endDates.length > 0 ? endDates[0].text : 'End Dates',
				ml_MyContacts:
					myContacts.length > 0 ? myContacts[0].text : 'My Contacts',
				ml_Referrals: referrals.length > 0 ? referrals[0].text : 'Referrals',
				ml_Position: position.length > 0 ? position[0].text : 'Position',
				ml_BonusDetails: get(bonusDetail, 'text', 'Bonus Details'),
				ml_Job: get(job, 'text', 'Job'),
				ml_ReferredCandidate: get(
					referredCandidate,
					'text',
					'Referred Candidate'
				),
				ml_TotalBonus: get(totalBonus, 'text', 'Total Bonus'),
				ml_HiredDate: get(hiredDate, 'text', 'Hired Date'),
				ml_BonusPayments: get(bonusPayment, 'text', 'Bonus Payments'),
				ml_BonusNotes: get(bonusNote, 'text', 'Bonus Notes'),
				ml_Close: get(close, 'text', 'Close'),
				ml_ViewBonusDetails: get(viewBonusDetail, 'text', 'View Bonus Details'),
				ml_Referred: get(referred, 'text', 'Referred'),
				ml_Accepted: get(accepted, 'text', 'Accepted'),
				ml_Hired: get(hired, 'text', 'Hired'),
				ml_Interviewing: get(interviewing, 'text', 'Interviewing'),
				ml_Earned: get(earned, 'text', 'Earned'),
				ml_NotHired: get(notHired, 'text', 'Not Hired'),
				ml_NoResponse: get(noResponse, 'text', 'No Response'),
				ml_InActive: get(inActive, 'text', 'Inactive'),
			});
		} else {
			this.setState({
				ml_CancelAdd: 'Cancel Add',
				ml_ConfirmNewPosition: 'Confirm new position',
				ml_TieredBonus: 'Tiered Bonus',
				ml_Mobile: 'Mobile',
				ml_ReferralComments: 'Referral Comments',
				ml_Status: 'Status',
				ml_Resume: 'Resume',
				ml_AddGeneralReferral: 'Add General Referral',
				ml_AddJobHistory: 'Add Job History',
				ml_ShowJobHistory: 'Show Job History',
				ml_ReferredOn: 'Referred on',
				ml_ReferralReady: 'Referral Ready ',
				ml_Description: 'Description',
				ml_Company: 'Company',
				ml_EmailPhoneNumber: 'Email / Phone Number',
				ml_DownloadResume: 'Download Resume',
				ml_ReferredBy: 'Referred By',
				ml_EditProfile: 'Edit Profile',
				ml_Organization: 'Organization',
				ml_StartDate: 'Start Date',
				ml_EndDates: 'End Dates',
				ml_MyContacts: 'My Contacts',
				ml_Referrals: 'Referrals',
				ml_Position: 'Position',
				ml_BonusDetails: 'Bonus Details',
				ml_Job: 'Job',
				ml_ReferredCandidate: 'Referred Candidate',
				ml_TotalBonus: 'Total Bonus',
				ml_HiredDate: 'Hired Date',
				ml_BonusPayments: 'Bonus Payments',
				ml_BonusNotes: 'Bonus Notes',
				ml_Close: 'Close',
				ml_ViewBonusDetails: 'View Bonus Details',
				ml_Referred: 'Referred',
				ml_Accepted: 'Accepted',
				ml_Hired: 'Hired',
				ml_Interviewing: 'Interviewing',
				ml_NotHired: 'Not Hired',
				ml_NoResponse: 'No Response',
				ml_InActive: 'Inactive',
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.contact !== this.props.contact &&
			this.props.contact !== undefined
		) {
			this.setState({
				contact: get(this.props, 'contact', []),
			});
		}

		if (
			prevProps.currentUser.languageCode !==
				this.props.currentUser.languageCode &&
			this.props.allMultiLingualData.length > 0
		) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			const languageCode =
				this.props.currentUser.languageCode === null
					? 'US'
					: this.props.currentUser.languageCode;
			const filteredData = this.props.allMultiLingualData.filter(
				(item) => item.languageCode === languageCode
			);
			const cancelAdd = filteredData.filter(
				(item) => item.key === myContactsKeys.CancelAdd
			);
			const confirmNewPosition = filteredData.filter(
				(item) => item.key === myContactsKeys.ConfirmNewPosition
			);
			const tieredBonus = filteredData.filter(
				(item) => item.key === myContactsKeys.TieredBonus
			);
			const mobile = filteredData.filter(
				(item) => item.key === myContactsKeys.Mobile
			);
			const referralComments = filteredData.filter(
				(item) => item.key === myContactsKeys.ReferralComments
			);
			const status = filteredData.filter(
				(item) => item.key === myContactsKeys.Status
			);
			const resume = filteredData.filter(
				(item) => item.key === myContactsKeys.Resume
			);
			const addGeneralReferral = filteredData.filter(
				(item) => item.key === myContactsKeys.AddGeneralReferral
			);
			const addJobHistory = filteredData.filter(
				(item) => item.key === myContactsKeys.AddJobHistory
			);
			const showJobHistory = filteredData.filter(
				(item) => item.key === myContactsKeys.ShowJobHistory
			);
			const referredOn = filteredData.filter(
				(item) => item.key === myContactsKeys.ReferredOn
			);
			const referralReady = filteredData.filter(
				(item) => item.key === myContactsKeys.ReferralReady
			);
			const description = filteredData.filter(
				(item) => item.key === myContactsKeys.Description
			);
			const company = filteredData.filter(
				(item) => item.key === myContactsKeys.Company
			);
			const emailPhoneNumber = filteredData.filter(
				(item) => item.key === myContactsKeys.EmailPhoneNumber
			);
			const downloadResume = filteredData.filter(
				(item) => item.key === myContactsKeys.DownloadResume
			);
			const referredBy = filteredData.filter(
				(item) => item.key === myContactsKeys.ReferredBy
			);
			const editProfile = filteredData.filter(
				(item) => item.key === myContactsKeys.EditProfile
			);
			const organization = filteredData.filter(
				(item) => item.key === myContactsKeys.Organization
			);
			const startDate = filteredData.filter(
				(item) => item.key === myContactsKeys.StartDate
			);
			const endDates = filteredData.filter(
				(item) => item.key === myContactsKeys.EndDates
			);
			const myContacts = filteredData.filter(
				(item) => item.key === myContactsKeys.MyContacts
			);
			const referrals = filteredData.filter(
				(item) => item.key === myContactsKeys.Referrals
			);
			const position = filteredData.filter(
				(item) => item.key === myContactsKeys.Position
			);
			const bonusDetail = filteredData.find(
				(item) => item.key === myContactsKeys.BonusDetails
			);
			const job = filteredData.find((item) => item.key === myContactsKeys.Job);
			const referredCandidate = filteredData.find(
				(item) => item.key === myContactsKeys.ReferredCandidate
			);
			const totalBonus = filteredData.find(
				(item) => item.key === myContactsKeys.TotalBonus
			);
			const hiredDate = filteredData.find(
				(item) => item.key === myContactsKeys.HiredDate
			);
			const bonusPayment = filteredData.find(
				(item) => item.key === myContactsKeys.BonusPayments
			);
			const bonusNote = filteredData.find(
				(item) => item.key === myContactsKeys.BonusNotes
			);
			const close = filteredData.find(
				(item) => item.key === myContactsKeys.Close
			);
			const viewBonusDetail = filteredData.find(
				(item) => item.key === myContactsKeys.ViewBonusDetails
			);
			const inActive = filteredData.find(
				(item) => item.key === myContactsKeys.InActive
			);

			const noResponse = filteredData.find(
				(item) => item.key === myContactsKeys.NoResponse
			);
			this.setState({
				ml_CancelAdd: cancelAdd.length > 0 ? cancelAdd[0].text : 'Cancel Add',
				ml_ConfirmNewPosition:
					confirmNewPosition.length > 0
						? confirmNewPosition[0].text
						: 'Confirm new position',
				ml_TieredBonus:
					tieredBonus.length > 0 ? tieredBonus[0].text : 'Tiered Bonus',
				ml_Mobile: mobile.length > 0 ? mobile[0].text : 'Mobile',
				ml_ReferralComments:
					referralComments.length > 0
						? referralComments[0].text
						: 'Referral Comments',
				ml_Status: status.length > 0 ? status[0].text : 'Status',
				ml_Resume: resume.length > 0 ? resume[0].text : 'Resume',
				ml_AddGeneralReferral:
					addGeneralReferral.length > 0
						? addGeneralReferral[0].text
						: 'Add General Referral',
				ml_AddJobHistory:
					addJobHistory.length > 0 ? addJobHistory[0].text : 'Add Job History',
				ml_ShowJobHistory:
					showJobHistory.length > 0
						? showJobHistory[0].text
						: 'Show Job History',
				ml_ReferredOn:
					referredOn.length > 0 ? referredOn[0].text : 'Referred on',
				ml_ReferralReady:
					referralReady.length > 0 ? referralReady[0].text : 'Referral Ready ',
				ml_Description:
					description.length > 0 ? description[0].text : 'Description',
				ml_Company: company.length > 0 ? company[0].text : 'Company',
				ml_EmailPhoneNumber:
					emailPhoneNumber.length > 0
						? emailPhoneNumber[0].text
						: 'Email / Phone Number',
				ml_DownloadResume:
					downloadResume.length > 0
						? downloadResume[0].text
						: 'Download Resume',
				ml_ReferredBy:
					referredBy.length > 0 ? referredBy[0].text : 'Referred By',
				ml_EditProfile:
					editProfile.length > 0 ? editProfile[0].text : 'Edit Profile',
				ml_Organization:
					organization.length > 0 ? organization[0].text : 'Organization',
				ml_StartDate: startDate.length > 0 ? startDate[0].text : 'Start Date',
				ml_EndDates: endDates.length > 0 ? endDates[0].text : 'End Dates',
				ml_MyContacts:
					myContacts.length > 0 ? myContacts[0].text : 'My Contacts',
				ml_Referrals: referrals.length > 0 ? referrals[0].text : 'Referrals',
				ml_Position: position.length > 0 ? position[0].text : 'Position',
				ml_BonusDetails: get(bonusDetail, 'text', 'Bonus Details'),
				ml_Job: get(job, 'text', 'Job'),
				ml_ReferredCandidate: get(
					referredCandidate,
					'text',
					'Referred Candidate'
				),
				ml_TotalBonus: get(totalBonus, 'text', 'Total Bonus'),
				ml_HiredDate: get(hiredDate, 'text', 'Hired Date'),
				ml_BonusPayments: get(bonusPayment, 'text', 'Bonus Payments'),
				ml_BonusNotes: get(bonusNote, 'text', 'Bonus Notes'),
				ml_Close: get(close, 'text', 'Close'),
				ml_ViewBonusDetails: get(viewBonusDetail, 'text', 'View Bonus Details'),
				ml_NoResponse: get(noResponse, 'text', 'No Response'),
				ml_InActive: get(inActive, 'text', 'Inactive'),
			});
		}
	}

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			// Make this USD
			this.setState({
				currencyRate: 1,
				currencySymbol: '$',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency);
			this.setState({
				currencyRate: rate,
				currencySymbol: symbol,
			});
		}
	}

	render() {
		const {
			company,
			contact,
			selectedUser,
			edit,
			currencyRate,
			currencySymbol,
			ml_CancelAdd,
			ml_ConfirmNewPosition,
			ml_TieredBonus,
			ml_Mobile,
			ml_ReferralComments,
			ml_Status,
			ml_Resume,
			ml_AddGeneralReferral,
			ml_AddJobHistory,
			ml_ShowJobHistory,
			ml_ReferredOn,
			ml_ReferralReady,
			ml_Description,
			ml_Company,
			ml_DownloadResume,
			ml_ReferredBy,
			ml_EditProfile,
			ml_Organization,
			ml_StartDate,
			ml_EndDates,
			ml_MyContacts,
			ml_Referrals,
			ml_Position,
			ml_BonusDetails,
			ml_Job,
			ml_ReferredCandidate,
			ml_TotalBonus,
			ml_HiredDate,
			ml_BonusPayments,
			ml_BonusNotes,
			ml_Close,
			ml_ViewBonusDetails,
			ml_Referred,
			ml_Accepted,
			ml_Hired,
			ml_Interviewing,
			ml_NotHired,
			ml_NoResponse,
			ml_InActive,
		} = this.state;

		const fullContactData =
			contact !== undefined &&
			get(contact, 'fullContactData') !== null &&
			typeof get(contact, 'fullContactData') === 'string'
				? parse(get(contact, 'fullContactData', '{}'))
				: null;
		let referralData;
		const contactEmailAddress = get(contact, 'emailAddress', '');
		const socials =
			typeof get(contact, 'socialMediaAccounts') === 'string'
				? parse(get(contact, 'socialMediaAccounts', '{}'))
				: null;
		if (contact) {
			referralData = {
				contact,
				firstName: get(contact, 'firstName'),
				lastName: get(contact, 'lastName'),
				emailAddress:
					contactEmailAddress !== null && contactEmailAddress !== ''
						? get(contact, 'emailAddress').toLowerCase()
						: contactEmailAddress,
				position:
					fullContactData !== null && fullContactData.details !== undefined
						? get(fullContactData, 'title')
						: null,
				organization:
					fullContactData !== null && fullContactData.details !== undefined
						? get(fullContactData, 'organization')
						: null,
				bio:
					fullContactData !== null && fullContactData.details !== undefined
						? get(fullContactData, 'bio')
						: null,
				phone: get(contact, 'phoneNumber'),
				id: get(contact, 'id'),
				department: null,
				linkedin:
					socials !== null &&
					findWithAttribute(socials, 'network', 'linkedin') >= 0
						? socials[findWithAttribute(socials, 'network', 'linkedin')].profile
						: null,
				facebook:
					socials !== null &&
					findWithAttribute(socials, 'network', 'facebook') >= 0
						? socials[findWithAttribute(socials, 'network', 'facebook')].profile
						: null,
				Twitter:
					socials !== null &&
					findWithAttribute(socials, 'network', 'twitter') >= 0
						? socials[findWithAttribute(socials, 'network', 'twitter')].profile
						: null,
				employment:
					fullContactData !== null && fullContactData.details !== undefined
						? get(fullContactData, 'details.employment')
						: [],
				referrals: contact.referrals,
				fullContact: fullContactData,
				socialMediaAccounts: socials,
				onDeckStatus: get(contact, 'onDeckStatus'),
				onDeckDate: get(contact, 'onDeckDate'),
				contactResume: get(contact, 'contactResume'),
				userId: get(contact, 'userId'),
				questionsData: get(contact, 'questionsData'),
			};
		}

		const sa = parse(get(this.props, 'currentUser.admin', '{}'));
		const supportAdmin = get(sa, 'permissions') === '*';
		if (
			contact &&
			contact?.companyId != this.props?.currentUser?.companyId &&
			!supportAdmin
		) {
			return <Spinner company={company} />;
		}

		const { companyData } = this.props;

		const parsedOwnershipSettings = parse(
			get(companyData, 'ownershipSettings', '{}')
		);

		return (
			<div>
				{contact === undefined ? (
					<Spinner company={company} />
				) : (
					<DetailsView
						ownershipSettings={parsedOwnershipSettings}
						referralData={referralData}
						referral={referralData}
						edit={edit}
						employeeData={selectedUser}
						history={this.props.history}
						currentCurrencyRate={currencyRate}
						currentCurrencySymbol={currencySymbol}
						cancelAddText={ml_CancelAdd}
						confirmNewPositionText={ml_ConfirmNewPosition}
						tieredBonusText={ml_TieredBonus}
						mobileText={ml_Mobile}
						referralCommentsText={ml_ReferralComments}
						statusText={ml_Status}
						resumeText={ml_Resume}
						addGeneralReferralText={ml_AddGeneralReferral}
						addJobHistoryText={ml_AddJobHistory}
						showJobHistoryText={ml_ShowJobHistory}
						referredOnText={ml_ReferredOn}
						referralReadyText={ml_ReferralReady}
						descriptionText={ml_Description}
						companyText={ml_Company}
						downloadResumeText={ml_DownloadResume}
						referredByText={ml_ReferredBy}
						editProfileText={ml_EditProfile}
						organizationText={ml_Organization}
						startDateText={ml_StartDate}
						endDatesText={ml_EndDates}
						myContactsText={ml_MyContacts}
						referralsText={ml_Referrals}
						positionText={ml_Position}
						bonusDetailsText={ml_BonusDetails}
						jobText={ml_Job}
						referredCandidateText={ml_ReferredCandidate}
						totalBonusText={ml_TotalBonus}
						hiredDateText={ml_HiredDate}
						bonusPaymentsText={ml_BonusPayments}
						bonusNotesText={ml_BonusNotes}
						closeText={ml_Close}
						viewBonusDetailsText={ml_ViewBonusDetails}
						currentUser={this.props.currentUser}
						referredText={ml_Referred}
						acceptedText={ml_Accepted}
						interviewingText={ml_Interviewing}
						hiredText={ml_Hired}
						notHiredText={ml_NotHired}
						noResponseText={ml_NoResponse}
						inActiveText={ml_InActive}
						onUpdate={this.props.onUpdate}
					/>
				)}
			</div>
		);
	}
}

export default ContactDetailsComponent;
