export const updateUser = `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      avatar {
        bucket
        region
        key
        __typename
      }
      accountClaimId
      active
      admin
      authMethod
      careerProfile
      companyId
      connectedApps
      createdById
      currency
      dateFormat
      defaultDistance
      departmentId
      emailAddress
      enableJobNotifications
      employeeId
      firstName
      id
      incentiveEligible
      isOptoutAnalytics
      isOptoutReferralNotification
      jobClassId
      jobClassName
      jobFamilyGroupId
      jobFamilyGroupName
      jobFamilyId
      jobFamilyName
      jobNotificationFrequency
      jobNotificationSetting
      jobProfileId
      jobProfileName
      languageCode 
      lastName
      lastLogin
      lastNotificationCheck
      location      
      managementLevel
      points
      role
      search
      subCompanyId
      title
      totalReferrals
      userGroupId
      openToNewRole
    }
  }
`;
