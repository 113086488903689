import React from 'react';
import allCaughtUpIcon from './../../assets/svg/checkbox.svg';
import '../noDataState/noDataState.scss';

const AllCaughtUp = ({ title }) => {
	return (
		<div className="d-flex w-100 h-100 justify-content-center align-items-center flex-column no-data-found">
			<img src={allCaughtUpIcon} alt="img" />
			<p className="mt-2">{title}</p>
		</div>
	);
};

export default AllCaughtUp;
