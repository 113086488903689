import { Button, Input, Modal, Tooltip, message } from 'antd';
import gql from 'graphql-tag';
import { get } from 'lodash';
import QRCode from 'qrcode';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
	XIcon,
} from 'react-share';
import { queryUserJobShareByUserIdIndex } from 'src/_shared/api/graphql/custom/users/';
import { ml } from 'src/_shared/services/utils';

class ShareSendButton extends Component {
	state = {
		visible: false,
		isShared: false,
		userAllJobShares: [],
		extendedNetworkUrl: `${this.props.currentUser?.company?.host
			? 'https://' + this.props.currentUser.company.host
			: window.location.origin}/share?referredBy=${
			this.props.currentUser.id
		}&jobId=${this.props.jobId}&languageCode=${
			this.props.languageCode || 'EN'
		}`,
		generatedQRCode: null,
	};

	async componentDidMount() {
		try {
			QRCode.toDataURL(this.state.extendedNetworkUrl).then((result) => {
				this.setState({
					generatedQRCode: result,
				});
			});
		} catch (error) {
			console.error(error);
		}
	}

	async getUserJobShares(
		policy = 'network-only',
		nextToken = null,
		userAllJobShares = []
	) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryUserJobShareByUserIdIndex),
				variables: {
					userId: get(currentUser, 'id'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const userJobShares = [...data.queryUserJobShareByUserIdIndex.items];
			const token = get(data, 'queryUserJobShareByUserIdIndex.nextToken', null);
			userAllJobShares = [...userAllJobShares, ...userJobShares];
			this.setState({
				userAllJobShares,
			});
			if (token) {
				this.getUserJobShares(policy, token, userAllJobShares);
			}
		} catch (error) {
			console.log(error);
		}
	}

	handleCancel = () => {
		this.setState({
			visible: false,
			shareButtonsDisabled: false,
			shareButtonClicked: null,
		});
	};

	handleClicked(shareButton) {
		this.setState({ shareButtonsDisabled: true });
		this.setState({ shareButtonClicked: shareButton });

		setTimeout(() => {
			this.setState({
				shareButtonsDisabled: false,
			});
		}, 5500);
	}

	showModal = () => {
	this.getUserJobShares();
		this.setState({
			visible: true,
		});
	};

	updateShareCount = async () => {
		const {
			onUpdateJob,
			jobId,
			shares,
			onCreateUserJobShare,
			currentUser,
			onUpdateUserJobShare,
		} = this.props;
		const { shareButtonClicked } = this.state;

		await this.getUserJobShares();
		const { userAllJobShares } = this.state;
		const userJobSharesLength = userAllJobShares.length;
		const currentUserId = get(currentUser, 'id');
		const companyId = get(currentUser, 'companyId');
		let facebookSharesCount = 0;
		let facebookShareLastDate = '';
		let twitterSharesCount = 0;
		let twitterShareLastDate = '';
		let linkedinSharesCount = 0;
		let linkedinShareLastDate = '';
		let whatsAppSharesCount = 0;
		let whatsAppShareLastDate = '';

		await onUpdateJob({
			id: jobId,
			shares: shares + 1,
		}).catch((error) => console.error('Update Job Share Count Error:', error));
		switch (shareButtonClicked) {
			case 'facebook': {
				facebookSharesCount = 1;
				facebookShareLastDate = new Date().toISOString();

				break;
			}

			case 'twitter': {
				twitterSharesCount = 1;
				twitterShareLastDate = new Date().toISOString();

				break;
			}

			case 'linkedin': {
				linkedinSharesCount = 1;
				linkedinShareLastDate = new Date().toISOString();

				break;
			}

			case 'whatsapp': {
				whatsAppSharesCount = 1;
				whatsAppShareLastDate = new Date().toISOString();

				break;
			}
			// No default
		}

		if (userJobSharesLength > 0) {
			const currentUserJobShareDetails = userAllJobShares.filter(
				(item) => item.jobId === jobId
			);
			if (currentUserJobShareDetails.length > 0) {
				facebookSharesCount = currentUserJobShareDetails[0].facebookSharesCount;
				facebookShareLastDate =
					currentUserJobShareDetails[0].facebookShareLastDate;
				twitterSharesCount = currentUserJobShareDetails[0].twitterSharesCount;
				twitterShareLastDate =
					currentUserJobShareDetails[0].twitterShareLastDate;
				linkedinSharesCount = currentUserJobShareDetails[0].linkedinSharesCount;
				linkedinShareLastDate =
					currentUserJobShareDetails[0].linkedinShareLastDate;
				whatsAppSharesCount = currentUserJobShareDetails[0].whatsAppSharesCount;
				whatsAppShareLastDate =
					currentUserJobShareDetails[0].whatsAppShareLastDate;
				switch (shareButtonClicked) {
					case 'facebook': {
						facebookSharesCount += 1;
						facebookShareLastDate = new Date().toISOString();

						break;
					}

					case 'twitter': {
						twitterSharesCount += 1;
						twitterShareLastDate = new Date().toISOString();

						break;
					}

					case 'linkedin': {
						linkedinSharesCount += 1;
						linkedinShareLastDate = new Date().toISOString();

						break;
					}

					case 'whatsapp': {
						whatsAppSharesCount += 1;
						whatsAppShareLastDate = new Date().toISOString();

						break;
					}
					// No default
				}

				await onUpdateUserJobShare({
					id: currentUserJobShareDetails[0].id,
					facebookSharesCount,
					twitterSharesCount,
					linkedinSharesCount,
					whatsAppSharesCount,
					companyId,
					facebookShareLastDate,
					twitterShareLastDate,
					linkedinShareLastDate,
					whatsAppShareLastDate,
				}).catch((error) =>
					console.error('Update User Job Share Count Error:', error)
				);
			} else {
				await onCreateUserJobShare({
					userId: currentUserId,
					jobId,
					facebookSharesCount,
					twitterSharesCount,
					linkedinSharesCount,
					whatsAppSharesCount,
					companyId,
					facebookShareLastDate,
					twitterShareLastDate,
					linkedinShareLastDate,
					whatsAppShareLastDate,
				}).catch((error) =>
					console.error('Create User Job Share Count Error:', error)
				);
			}
		} else {
			await onCreateUserJobShare({
				userId: currentUserId,
				jobId,
				facebookSharesCount,
				twitterSharesCount,
				linkedinSharesCount,
				whatsAppSharesCount,
				companyId,
				facebookShareLastDate,
				twitterShareLastDate,
				linkedinShareLastDate,
				whatsAppShareLastDate,
			}).catch((error) =>
				console.error('Create User Job Share Count Error:', error)
			);
		}
	};

	render() {
		const { Search } = Input;
		const { allMultiLingualData, currentUser, languageCode, jobId } =
			this.props;
		const hideWhatsApp = get(currentUser, 'company.hideWhatsApp');
		// Build url that respects dev/prod environment, and uses appropriate share-preview function
		const socialMediaSharePreviewUrl = `${
			this.props.currentUser?.company?.host
				? 'https://' + this.props.currentUser.company.host
				: window.location.origin
		}/share?referredBy=${
			currentUser.id
		}&jobId=${jobId}&languageCode=${languageCode || 'EN'}`;
		const socialMediaSharePreviewUrlLinkedIn = `${
			this.props.currentUser?.company?.host
				? 'https://' + this.props.currentUser.company.host
				: window.location.origin
		}/.netlify/functions/share-preview?referredBy=${
			currentUser.id
		}&jobId=${jobId}&languageCode=${languageCode || 'EN'}`;
		const labelSocialShare = get(currentUser, 'company.labelSocialShare', '');
		// NOTE: To use link locally from sharesendcomponent, must have netlify dev in erin-web workspace running which
		// proxies localhost:3000 to localhost:8888

		return (
			<>
				{this.props.iconOnly ? (
					<Tooltip
						arrowPointAtCenter
						placement="topRight"
						title="Share My Referral Link"
					>
						<i className="icon-share" onClick={this.showModal} />
					</Tooltip>
				) : this.props.link ? (
					<a
						className="share-link"
						href="javascript:void(0);"
						onClick={this.showModal}
					>
						<i className="icon-share" />{' '}
						{ml('Share', currentUser, allMultiLingualData)}
					</a>
				) : (
					<Button type="primary" onClick={this.showModal}>
						<div />
						<span> {ml('Share', currentUser, allMultiLingualData)}</span>
					</Button>
				)}
				<Modal
					title="Share My Referral Link"
					open={this.state.visible}
					footer={null}
					onCancel={this.handleCancel}
				>
					<p className="small text-center">
						Your unique referral link for this job can be shared and will create
						a referral for anyone that uses it.
					</p>
					<div className="share-qr-code">
						<img src={this.state.generatedQRCode} alt="QR Code" />
					</div>
					<Search
						className="custom-search-grp"
						enterButton="Copy"
						defaultValue={socialMediaSharePreviewUrl}
						onSearch={() => {
							navigator.clipboard
								.writeText(socialMediaSharePreviewUrl)
								.then(() => {
									message.success('Copied to clipboard');
								})
								.catch((error) => {
									message.error('Failed to copy to clipboard');
								});
						}}
					/>

					<p className="text-center">Or</p>
					<ul className="share-social-icons">
						<li
							className={
								this.state.shareButtonsDisabled ? 'ss-icon-disable' : null
							}
							onClick={() => this.handleClicked('facebook')}
						>
							<FacebookShareButton
								style={{ cursor: 'pointer', textAlign: 'center' }}
								url={socialMediaSharePreviewUrl}
								onShareWindowClose={this.updateShareCount}
							>
								<FacebookIcon round alt="Facebook" size={32} />
								<div className="ss-icon-text">Facebook</div>
							</FacebookShareButton>
						</li>
						<li
							className={
								this.state.shareButtonsDisabled ? 'ss-icon-disable' : null
							}
							onClick={() => this.handleClicked('twitter')}
						>
							<TwitterShareButton
								style={{ cursor: 'pointer', textAlign: 'center' }}
								url={socialMediaSharePreviewUrl}
								onShareWindowClose={this.updateShareCount}
							>
								<XIcon round alt="X" size={32} />
								<div className="ss-icon-text">X</div>
							</TwitterShareButton>
						</li>
						<li
							className={
								this.state.shareButtonsDisabled ? 'ss-icon-disable' : null
							}
							onClick={() => this.handleClicked('linkedin')}
						>
							<LinkedinShareButton
								style={{ cursor: 'pointer', textAlign: 'center' }}
								url={socialMediaSharePreviewUrlLinkedIn}
								windowWidth={750}
								windowHeight={600}
								onShareWindowClose={this.updateShareCount}
							>
								<LinkedinIcon round alt="LinkedIn" size={32} />
								<div className="ss-icon-text">LinkedIn</div>
							</LinkedinShareButton>
						</li>
						{!hideWhatsApp && (
							<li
								className={
									this.state.shareButtonsDisabled ? 'ss-icon-disable' : null
								}
								onClick={() => this.handleClicked('whatsapp')}
							>
								<WhatsappShareButton
									style={{ cursor: 'pointer', textAlign: 'center' }}
									url={socialMediaSharePreviewUrl}
									onShareWindowClose={this.updateShareCount}
								>
									<WhatsappIcon round alt="Whatsapp" size={32} />
									<div className="ss-icon-text">WhatsApp</div>
								</WhatsappShareButton>
							</li>
						)}
					</ul>
					<div className="text-center text-danger">{labelSocialShare}</div>
				</Modal>
			</>
		);
	}
}
export default withApollo(ShareSendButton);
