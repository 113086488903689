import { connect } from 'react-redux';
import { withCreateCampaign } from 'src/_shared/api/components/bonus-campaign/';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withQuerySubCompanyByCompanyIdIndex } from 'src/_shared/api/components/sub-company/';
import { withListUserGroup } from 'src/_shared/api/components/userGroups/';
import { withQueryUsersByCompanyIdRoleIndex } from 'src/_shared/api/components/users/';
import { dashboardActions } from 'src/actions.js';
import { USER_ROLES } from '../_shared/constants';
import { compose } from '../_shared/services/utils';
import MessageCenterComponent from './MessagecenterComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
			role: { eq: USER_ROLES.EMPLOYEE },
		},
		nextToken: null,
		limit: 10_000,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const MessageCenterWithApi = compose(
	withListUserGroup,
	withListDepartment,
	withQuerySubCompanyByCompanyIdIndex,
	withQueryUsersByCompanyIdRoleIndex,
	withCreateCampaign
)(MessageCenterComponent);

export const MessageCenter = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(MessageCenterWithApi);
