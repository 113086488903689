import { Component } from 'react';
import get from 'lodash/get';

class ReferralCountComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			color: null,
		};
	}

	render() {
		return (
			<div
				style={{
					color: this.state.color,
					textAlign: 'center',
					width: 40,
				}}
			>
				{get(this.props, 'referralCount')}
			</div>
		);
	}
}

export default ReferralCountComponent;
