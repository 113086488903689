import { connect } from 'react-redux';
import { withQueryBonusByUserId } from 'src/_shared/api/components/bonuses/';
import { withListMyReferralsContacts } from 'src/_shared/api/components/contacts/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { withListReferralRequestedByUseridStatusMyReferralsNotifications } from 'src/_shared/api/components/users/';
import { withQueryReferralsAdminNoteOwnershipEndDateByUserIdProvider } from 'src/_shared/api/components/referrals/';
import { dashboardActions, manageJobsActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils.js';
import MyReferralsComponent from './MyReferralsComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		id: currentUser.id,
		currentUser,
		allMultiLingualData,
		companyId: currentUser.companyId,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const MyReferralsWithApi = compose(
	withQueryBonusByUserId,
	withListMyReferralsContacts,
	withListReferralRequestedByUseridStatusMyReferralsNotifications,
	withQueryReferralsAdminNoteOwnershipEndDateByUserIdProvider,
	withGetCompanyData
)(MyReferralsComponent);

export const MyReferrals = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(MyReferralsWithApi);
