import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { getEnvironment, lambda, parse } from 'src/_shared/services/utils.js';
import { JobMatchesByUserId } from '../_shared/api/graphql/custom/index.js';
import { USER_ROLES } from '../_shared/index.js';
import { CareerProfileCard } from './career-profile-components';

class CareerProfileComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			updateCurrentUser: props.updateCurrentUser,
		};
	}

	componentDidMount() {
		if (this.props.location.pathname === '/mycontacts/add/form') {
			this.setState({
				contactsModal: true,
			});
		}
	}

	onUpdateUser = async (values) => {
		const { onUpdate } = this.props;
		onUpdate(values);
		let endpoint = '';
		const configMode = getEnvironment();
		endpoint = `open-search-job-matching-api-${configMode}`;
		await lambda({
			endpoint,
			variables: { id: values.input.id, configMode },
		});

		try {
			const { client, currentUser, updateCurrentUser } = this.props;
			const fetchJobMatches = async (nextToken, results = []) => {
				const {
					data: {
						queryJobMatchesByUserIdIndex: { items, nextToken: moreResults },
					},
				} = await client.query({
					query: JobMatchesByUserId,
					variables: {
						userId: currentUser.id,
						after: nextToken,
						limit: 75,
					},
					fetchPolicy: 'network-only',
				});
				results.push(...items);
				if (moreResults) {
					return await fetchJobMatches(moreResults, results);
				}

				return results;
			};

			const results = await fetchJobMatches(null);
			let jobMatches = results
				.sort((a, b) => b.relevance - a.relevance)
				.filter(
					(match) =>
						!match?.job?.hideImInterested &&
						currentUser?.companyId === match?.job?.companyId
				)
				.map((match) => {
					match.job.location = parse(match?.job?.location);
					return match;
				});

			if (currentUser.role !== USER_ROLES.EXTENDED_USER) {
				// Remove job matches that base users can't apply to
				jobMatches = jobMatches.filter(
					(match) =>
						!(
							(currentUser?.company?.allowSelfReferralsInternalLink || false) &&
							!(match?.job?.internalJobLink || null)
						)
				);
			}

			updateCurrentUser({ jobMatches });
		} catch (error) {
			console.error(error);
		}
	};

	render() {
		const { updateCurrentUser } = this.state;
		const { allMultiLingualData, departments, currentUser } = this.props;
		return get(currentUser, 'company') ? (
			<main>
				<CareerProfileCard
					allMultiLingualData={allMultiLingualData}
					client={this.props.client}
					currentUser={currentUser}
					departments={departments}
					updateCurrentUser={updateCurrentUser}
					onUpdate={this.onUpdateUser}
				/>
			</main>
		) : (
			<Spinner />
		);
	}
}

export default withApollo(CareerProfileComponent);
