import { ml } from 'src/_shared/services/utils';

export function ProgressIndicator(props) {
	const { allMultiLingualData, currentUser, page } = props;
	return (
		<div className="my-progressbar-wrap">
			<ul className="my-progressbar-inner job-prgressbar">
				{[0, 1, 2, 3].map((i) => {
					return (
						<li key={i} className={i <= page ? 'active' : ''}>
							{i != 3 && (
								<div
									className="progress-line"
									style={i === page ? { borderColor: '#B1B1B1' } : {}}
								/>
							)}
							<div className="progress-circle">
								{page === i && <i className="icon-check progress-icon" />}
							</div>
						</li>
					);
				})}
			</ul>

			<ul className="my-progressbar-text">
				<li>{ml('Job Info', currentUser, allMultiLingualData)}</li>
				<li>{ml('Description', currentUser, allMultiLingualData)}</li>
				<li>{ml('Referral Info', currentUser, allMultiLingualData)}</li>
				<li>{ml('Review', currentUser, allMultiLingualData)}</li>
			</ul>
		</div>
	);
}
