import { connect } from 'react-redux';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import {
	dashboardActions,
	manageJobsActions,
	userActions,
} from 'src/actions.js';
import {
	withCreateContactReferral,
	withListMyReferralsContacts,
	withMyContacts,
} from 'src/_shared/api/components/contacts/';
import { withUpdateJob } from 'src/_shared/api/components/jobs/';
import { withCreateUserJobShare } from 'src/_shared/api/components/users/';
import { compose } from '../../../_shared/services/utils';
import HotJobCard from './HotJobCardComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		filter: {
			companyId: { eq: currentUser.companyId },
			userId: { eq: currentUser.id },
		},
		modalState: state.dashboard.modalState,
		companyId: currentUser.companyId,
		allMultiLingualData,
		jobSearchCriteria: state.user.jobSearchCriteria,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		updateCurrentUserSearchCriteria(searchCriteria) {
			dispatch(userActions.updateUserSearch(searchCriteria));
		},
	};
};

const HotJobCardContainer = compose(
	withCreateContactReferral,
	withGetCompanyData,
	withUpdateJob,
	withCreateUserJobShare,
	withMyContacts,
	withListMyReferralsContacts
)(HotJobCard);

export const HotJobs = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(HotJobCardContainer);
