import { connect } from 'react-redux';
import { withCreateGDPRReferral } from 'src/_shared/api/components/referrals/';
import { withUserById } from 'src/_shared/api/components/users/';
import { compose } from '../_shared/services/utils';
import GeneralReferralPageComponent from './GeneralReferralCardComponent.jsx';

const mapStateToProperties = (state, props) => {
	return {
		id: props.match.params.id,
		userId: props.match.params.referredBy,
		currentUser: state.user.currentUser,
	};
};

const GeneralReferralPageWithApi = compose(
	withUserById,
	withCreateGDPRReferral
)(GeneralReferralPageComponent);

export const GeneralReferralPage = connect(mapStateToProperties)(
	GeneralReferralPageWithApi
);
