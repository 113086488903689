/** Packages */
import { Button, message } from 'antd';
import mixpanel from 'mixpanel-browser';

/** Components & Styles */
import { configMode } from 'src/_shared/api/';

/** Shared Utils */
import { lambda, ml } from 'src/_shared/services/utils';

export function PointsLog({ allMultiLingualData, currentUser }) {
	const getReport = () => {
		let endpoint = '';
		endpoint =
			configMode === 'DEV'
				? 'export-individual-points-log-dev-sendEmail'
				: 'export-individual-points-log-prod-sendEmail';
		message.success(
			ml(
				'Export processing. You will receive and email when ready.',
				currentUser,
				allMultiLingualData
			)
		);
		mixpanel.track('Points Log Exported');
		lambda({
			endpoint,
			variables: {
				companyId: currentUser.companyId,
				cognitoId: currentUser.cognitoId,
			},
		});
	};

	return (
		<div className="link-wrap">
			<h4 className="profile-heading">
				{ml('Points Log', currentUser, allMultiLingualData)}
			</h4>
			<Button type="link" onClick={getReport}>
				{ml('Download', currentUser, allMultiLingualData)}
			</Button>
		</div>
	);
}
