import { css } from 'emotion';
import { COLORS } from '../../_shared/styles/colors';

export const cardMainStyles = css({
	minHeight: 250,
	borderRadius: 10,
});

export const candidateNameStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
	fontSize: 18,
	marginBottom: 0,
	lineHeight: 1,
	paddingBottom: 5,
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
});

export const pendingAcceptanceStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.lightGray,
	fontSize: 18,
	marginBottom: 0,
	lineHeight: 1,
	paddingBottom: 5,
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
});

export const referrerNameStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '600 !important',
	color: COLORS.blue,
	fontSize: 14,
});

export const miscTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.heading,
	fontSize: 16,
	margin: 'auto',
	textAlign: 'right',
});

export const miscTextColStyles = css({
	textAlign: 'right',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
});

export const statusTextStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.heading,
	fontSize: '16px',
});

export const statusStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.lightGray,
	fontSize: 16,
});

export const dropdownMenuItem = css({
	borderColor: 'transparent',
	color: COLORS.blue,
	'&:hover': {
		backgroundColor: COLORS.white,
		color: COLORS.blue,
		borderColor: 'transparent',
	},
});

export const dropdownButton = (width) =>
	css({
		marginLeft: 8,
		borderColor: 'transparent',
		color: COLORS.blue,
		'&:hover': {
			backgroundColor: COLORS.white,
			color: COLORS.blue,
			borderColor: 'transparent',
		},
		'&:focus': {
			backgroundColor: COLORS.white,
			color: COLORS.blue,
			borderColor: COLORS.white,
		},
		width,
	});

export const BonusAmount = css({
	color: COLORS.green,
	textAlign: 'right',
});

export const linkStyles = css({
	fontWeight: '600 !important',
	color: COLORS.hyperLink,
});

export const ModalStyles = css({
	'& .ant-modal-content': {
		borderRadius: 10,
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: COLORS.blue,
	},
	'& .ant-modal-close': {
		display: 'none',
	},
	fontFamily: 'Open Sans',
	width: 620,
});

export const tableHeader = css({
	fontFamily: '"Open Sans", sans-serif !important',
	fontWeight: '600 !important',
});

export const successStyles = css({
	width: 250,
	position: 'fixed',
	top: 300,
	left: 480,
});

export const noteClass = css({
	paddingLeft: 7,
});
