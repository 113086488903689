import { Cache } from 'aws-amplify';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { decryptUsingAES256 } from 'src/_shared/api/settings';
import { parse } from 'src/_shared/services/utils';

export class Redirect extends Component {
	UNSAFE_componentWillMount() {
		const urlHostname = (data) => {
			const a = document.createElement('a');
			a.href = data;
			return a.hostname;
		};

		const auth = async (code, settings) => {
			const request = {
				code,
				requestType: 'saml',
			};
			const provider = get(settings, 'provider');
			if (provider) request.provider = provider;
			const lambdaUrl =
				'https://7nprg3etl2.execute-api.us-east-2.amazonaws.com/default/auth-prod-api';
			const authRes = await fetch(lambdaUrl, {
				method: 'POST',
				body: JSON.stringify(request),
			})
				.then((res) => res.json())
				.then((body) => body);
			return parse(decryptUsingAES256(get(authRes, 'token')));
		};

		const handleSubmitGoogle = async () => {
			localStorage.setItem('authType', 'saml');
			const saml = await auth('google');
			const clientId = get(saml, 'clientId');
			Cache.setItem('federatedOrgCode', 'google');
			Cache.setItem('federatedClientId', clientId);
			Cache.setItem('federatedDomain', get(saml, 'domain'));
			Cache.setItem('federatedRegion', get(saml, 'region', 'us-east-2'));
			const { origin } = window.location;
			const url = `https://erinapp.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${origin}/saml-auth/login&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&client_id=${clientId}`;
			window.location.href = url;
		};

		const pathname = window.location.pathname.split('/');

		const lastElement = pathname[pathname.length - 1];
		const secondLastElement = pathname[pathname.length - 2];

		if (lastElement === 'login' && pathname.length > 2) {
			const code = secondLastElement;
			Cache.setItem('federatedOrgCode', code);
			localStorage.setItem('authType', 'saml');
		}

		if (this.props.google) {
			handleSubmitGoogle();
		} else if (urlHostname(this.props.loc) === window.location.hostname) {
			this.props.nav.history.push(this.props.loc);
		} else {
			window.location = this.props.loc;
		}
	}

	render() {
		return <div />;
	}
}

export default withApollo(Redirect);
