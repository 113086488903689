import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Select, Upload, message } from 'antd';
import dompurify from 'dompurify';
import get from 'lodash/get';
import { Component, createRef } from 'react';
import { colorRegex, urlRegex } from '../../_shared/constants/regex';
import {
	downloadFromS3,
	uploadToS3Multipart,
} from '../../_shared/services/utils.js';
import { ListItems } from './ListItemsComponent.jsx';
import { SubCompaniesListItem } from './SubCompaniesListComponent.jsx';

const sanitizer = dompurify.sanitize;

function beforeUpload(file) {
	const correctType = file.type === 'image/png' || file.type === 'image/jpeg';
	if (!correctType) {
		message.error('You can only upload png or jpeg files!');
	}

	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}

	return correctType && isLt2M;
}

class BrandingSettingsForm extends Component {
	constructor(props) {
		super(props);
		let theme = get(props, 'company.theme');
		if (typeof theme === 'string') {
			theme = JSON.parse(theme);
		} else {
			theme = {
				enabled: false,
				menuColor: '#242e3f',
				menuHighlightColor: '#ef3c3f',
				buttonColor: '#038dd3',
				addButtonColor: '#ef3c3f',
			};
		}

		this.state = {
			shareLogo: get(props, 'company.shareLogo'),
			websiteUrl: this.props.company.websiteUrl,
			brandColor: this.props.company.brandColor,
			companyLogo: get(props, 'company.logo'),
			companyLogoUrl: '',
			selectedSubCompany: null,
			subCompanyLogoUrl: '',
			companyFavicon: get(props, 'company.favicon'),
			companyFaviconChanged: false,
		};
		this.formRef = createRef();
	}

	async componentDidMount() {
		const companyLogoKey = this.state.companyLogo?.key;
		const subCompanyLogoKey = this.state.subCompanyLogo?.key;

		const companyLogoUrl = companyLogoKey
			? await downloadFromS3(companyLogoKey)
			: '';
		const subCompanyLogoUrl = subCompanyLogoKey
			? await downloadFromS3(subCompanyLogoKey)
			: '';

		this.setState({
			companyLogoUrl,
			subCompanyLogoUrl,
		});
	}

	handleCreateSubCompany = async (name) => {
		const { companyId } = this.props.currentUser;
		const currentSubCompanies = this.props.subCompanies;
		const logo = null;
		const keywords = null;
		if (name.trim() === '') {
			message.error('Subcompany must not be blank');
			return;
		}

		if (
			currentSubCompanies.some(
				(subCompany) => subCompany.name.toLowerCase() === name.toLowerCase()
			)
		) {
			message.error('Subcompany already exists');
			return;
		}

		await this.props.onCreateSubCompany({
			input: { name, companyId, logo, keywords },
		});
		message.success('Successfully Added Sub Company');
	};

	handleCreateSubCompanyKeyword = async (key) => {
		const { selectedSubCompany } = this.state;
		let keywords = get(selectedSubCompany, 'keywords');
		keywords = keywords ? keywords : [];
		keywords.push(key);
		const updatedSubCompany = {
			id: selectedSubCompany.id,
			companyId: selectedSubCompany.companyId,
			keywords: JSON.stringify(keywords),
			name: selectedSubCompany.name,
			logo: selectedSubCompany.logo,
		};
		selectedSubCompany.keywords = keywords;
		this.setState(selectedSubCompany);
		await this.props.onUpdateSubCompany(updatedSubCompany);
		message.success('Successfully Added Keyword');
	};

	handleDeleteSubCompanyKeyword = async (key) => {
		const { selectedSubCompany } = this.state;
		const keywords = get(selectedSubCompany, 'keywords', []);
		const index = keywords.indexOf(key);
		if (index > -1) {
			keywords.splice(index, 1);
			const updatedSubCompany = {
				id: selectedSubCompany.id,
				companyId: selectedSubCompany.companyId,
				keywords: JSON.stringify(keywords),
				name: selectedSubCompany.name,
				logo: selectedSubCompany.logo,
			};
			await this.props.onUpdateSubCompany(updatedSubCompany);
			message.success('Successfully Deleted Keyword');
		}
	};

	handleFilterBrands = async (id) => {
		const { subCompanies } = this.props;
		const brand = subCompanies.filter((x) => {
			return x.id == id;
		});

		this.setState({
			selectedSubCompany: brand.length > 0 ? brand[0] : null,
			subCompanyLogo: brand.length > 0 ? get(brand[0], 'logo') : null,
		});
	};

	handleSendReferralColorChange = (e) => {
		const { value } = e.target;
		const color = new RegExp(colorRegex, 'i');
		if (value === '' || color.test(value)) {
			this.setState({ colorError: false });
		} else {
			this.setState({ colorError: true });
		}

		if (value !== '') {
			this.setState({ sendReferralColor: value });
		}
	};

	handleSubmit = async (values) => {
		const { company, onUpdateCompany, updateCurrentUserCompany } = this.props;

		const {
			companyLogo,
			companyLogoChanged,
			shareLogo,
			shareLogoChanged,
			companyFavicon,
			companyFaviconChanged,
		} = this.state;
		try {
			// Update Company with any changes
			let { companyName } = values;
			companyName &&= sanitizer(companyName);
			let { brandColor } = values;
			brandColor &&= sanitizer(brandColor);
			let { websiteUrl } = values;
			websiteUrl &&= websiteUrl;
			let { faviconTitle } = values;
			faviconTitle &&= sanitizer(faviconTitle);

			const updatedCompany = {
				id: company.id,
				name: companyName,
				brandColor: brandColor || null,
				websiteUrl: websiteUrl || null,
				faviconTitle: faviconTitle || null,
			};
			if (companyLogoChanged) {
				updatedCompany.logo = companyLogo;
			}

			updatedCompany.shareLogo = shareLogoChanged
				? shareLogo
				: this.props.company.shareLogo;
			updatedCompany.favicon = companyFaviconChanged
				? companyFavicon
				: this.props.company.companyFavicon;
			const responseForStoreUpdate = await onUpdateCompany({
				input: updatedCompany,
			});
			updateCurrentUserCompany(responseForStoreUpdate.data.updateCompany);

			message.success('Changes Saved');
		} catch (error) {
			console.error(error);
			message.error(
				'Some of your changes failed to save, please refresh the page and review'
			);
		}
	};

	handleUpdateSubCompany = async (subCompanyLogo) => {
		const { selectedSubCompany } = this.state;
		let keywords = get(selectedSubCompany, 'keywords', []);
		keywords = keywords ? keywords : [];

		const updatedSubCompany = {
			id: selectedSubCompany.id,
			companyId: selectedSubCompany.companyId,
			name: selectedSubCompany.name,
			keywords: JSON.stringify(keywords),
			logo: subCompanyLogo,
		};
		await this.props.onUpdateSubCompany(updatedSubCompany);
		message.success('Successfully Updated Logo');
	};

	render() {
		const { Option } = Select;
		const {
			company,
			companyBrandText,
			companyNameText,
			companyWebsiteText,
			logoText,
			onDeleteSubCompany,
			onUpdateCompany,
			subCompanies,
		} = this.props;

		const {
			websiteUrl,
			brandColor,
			companyLogo,
			companyLogoUrl,
			subCompanyLogo,
			subCompanyLogoUrl,
			shareLogo,
		} = this.state;
		const fileListCompanyLogo = companyLogo
			? [
					{
						uid: '-1',
						name: companyLogo.key,
						status: 'done',
						url: companyLogoUrl,
					},
				]
			: [];

		const fileListSubCompanyLogo = subCompanyLogo
			? [
					{
						uid: '-1',
						name: subCompanyLogo.key,
						status: 'done',
						url: subCompanyLogoUrl,
					},
				]
			: [];
		const shareFileList = shareLogo
			? [
					{
						uid: '-1',
						name: shareLogo.key,
						status: 'done',
						url: `https://${shareLogo.bucket}.s3.us-east-2.amazonaws.com/${shareLogo.key}`,
					},
				]
			: [];

		const selectedSubCompanyKeywords = get(
			this.state,
			'selectedSubCompany.keywords',
			[]
		);
		return (
			<Form
				ref={this.formRef}
				initialValues={{
					companyName: company.name,
					websiteUrl,
					brandColor,
					subCompanyField: this.state.subCompanyField || '',
					subCompanyKeywordField: this.state.subCompanyKeywordField || '',
				}}
				onFinish={this.handleSubmit}
			>
				<div className="page-title">
					<h2 className="page-heading">Companies &#38; Brands</h2>
					<Button type="primary" size="large" htmlType="submit">
						Update
					</Button>
				</div>
				<div className="setting-card">
					<h4 className="setting-card-title">{companyBrandText}</h4>
					<div className="custom-form-group">
						<label className="custom-label">{companyNameText}:</label>
						<Form.Item
							name="companyName"
							rules={[
								{ required: true, message: 'Please input company name.' },
							]}
						>
							<Input className="custom-input" />
						</Form.Item>
					</div>
					<div className="custom-form-group">
						<label className="custom-label">{companyWebsiteText}:</label>
						<Form.Item
							name="websiteUrl"
							rules={[
								{
									validator(rule, value, callback) {
										const url = new RegExp(urlRegex, 'i');
										if (value && !url.test(value)) {
											callback('Invalid code');
										}

										callback();
									},
								},
							]}
						>
							<Input
								placeholder="ex: http://www.bestco.com"
								className="custom-input text-blue"
							/>
						</Form.Item>
					</div>
					<div className="custom-form-group">
						<label className="custom-label">Brand Color:</label>
						<Form.Item
							name="brandColor"
							rules={[
								{
									validator(rule, value, callback) {
										const color = new RegExp(colorRegex, 'i');
										if (value && !color.test(value)) {
											callback('Invalid code');
										}

										callback();
									},
								},
							]}
						>
							<Input
								placeholder="ex: #fffffff"
								className="custom-input"
								onChange={this.handleColorChange}
							/>
						</Form.Item>
					</div>
					<h4 className="setting-card-title">
						{logoText}
						<span>(Optimal image size: 1920x600)</span>
					</h4>
					<div className="custom-upload-picture">
						<Upload
							showUploadList
							name="logo"
							listType="picture-card"
							className={
								// Hides upload button
								fileListCompanyLogo.length > 0 && 'hide-upload-btn'
							}
							customRequest={async ({ file, onSuccess, onError }) => {
								try {
									const newCompanyLogo = {
										bucket: 'erin-avatars',
										key: `logo/${company.id}/${file.name}`,
										region: 'us-east-2',
									};

									await uploadToS3Multipart(
										file,
										newCompanyLogo.key,
										newCompanyLogo.bucket
									);

									const url = await downloadFromS3(newCompanyLogo.key);
									onSuccess(newCompanyLogo);
									this.setState({
										companyLogo: newCompanyLogo,
										companyLogoChanged: true,
										companyLogoUrl: url,
									});
								} catch (error) {
									console.error(error);
									onError(error);
								}
							}}
							beforeUpload={beforeUpload}
							multiple={false}
							disabled={fileListCompanyLogo.length > 0}
							fileList={fileListCompanyLogo}
						>
							<div className="upload-picture-btn">
								<i className="icon-plus" />
								<p className="upload-picture-text">Upload</p>
							</div>
						</Upload>
						{companyLogo && (
							<Popconfirm
								title="Are you sure？"
								placement="right"
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								onConfirm={() =>
									this.setState({
										companyLogo: null,
										companyLogoChanged: true,
									})
								}
							>
								<span className="cancel-picture-btn">
									<i className="icon-bin" />
									Remove
								</span>
							</Popconfirm>
						)}
					</div>
					<h4 className="setting-card-title">
						Social Media Share Logo
						<span>(Optimal image size: 1200x628 (aspect ratio: 1.91:1))</span>
					</h4>
					<div className="custom-upload-picture">
						<Upload
							showUploadList
							name="share-logo"
							listType="picture-card"
							className={shareFileList.length > 0 && 'hide-upload-btn'}
							customRequest={async ({ file, onSuccess, onError }) => {
								try {
									const newShareLogo = {
										bucket: 'erin-misc',
										key: `share-logo/${company.id}/${file.name}`,
										region: 'us-east-2',
									};
									await uploadToS3Multipart(
										file,
										newShareLogo.key,
										newShareLogo.bucket
									);

									onSuccess(newShareLogo);
									this.setState({
										shareLogo: newShareLogo,
										shareLogoChanged: true,
									});
								} catch (error) {
									onError(error);
								}
							}}
							beforeUpload={beforeUpload}
							multiple={false}
							disabled={shareFileList.length > 0}
							fileList={shareFileList}
						>
							<div className="upload-picture-btn">
								<i className="icon-plus" />
								<p className="upload-picture-text">Upload</p>
							</div>
						</Upload>
						{shareLogo && (
							<Popconfirm
								title="Are you sure？"
								placement="right"
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								onConfirm={() =>
									this.setState({
										shareLogo: null,
										shareLogoChanged: true,
									})
								}
							>
								<span className="cancel-picture-btn">
									<i className="icon-bin" />
									Remove
								</span>
							</Popconfirm>
						)}
					</div>
					<h4 className="setting-card-title">Manage Subcompanies</h4>
					<div className="custom-form-group">
						<br />
						<label className="custom-label">Enter Sub Company Name:</label>
						<Form.Item name="subCompanyField">
							<Input
								className="custom-input"
								placeholder="Enter Sub Company Name "
							/>
						</Form.Item>
						<Button
							size="small"
							className="add-btn"
							type="link"
							onClick={() => {
								this.handleCreateSubCompany(
									this.formRef.current.getFieldValue('subCompanyField')
								);
								this.formRef.current.resetFields();
							}}
						>
							<span className="icon-circle">
								<i className="icon-plus" />
							</span>
							Add Company
						</Button>
					</div>
					<SubCompaniesListItem
						company={company}
						subCompanies={subCompanies}
						onDeleteSubCompany={onDeleteSubCompany}
						onUpdateCompany={onUpdateCompany}
					/>
					<h4 className="setting-card-title">Subcompany Settings</h4>
					<div className="custom-form-group">
						<label className="custom-label">Select Subcompany:</label>
						<Select
							showSearch
							className="custom-input"
							placeholder="None"
							onSelect={(job) => this.handleFilterBrands(job)}
						>
							{(subCompanies || []).map((brand) => {
								return (
									<Option key={brand.id} value={brand.id}>
										{brand.name}
									</Option>
								);
							})}
						</Select>
					</div>

					{this.state.selectedSubCompany && (
						<>
							<h4 className="setting-card-title">
								{get(this.state, 'selectedSubCompany.name', '')}
							</h4>
							<h4 className="setting-card-title">Subcompany Keywords</h4>
							<div className="custom-form-group">
								<label className="custom-label">
									Enter Sub Company Keyword:
								</label>
								<Form.Item name="subCompanyKeywordField">
									<Input className="custom-input" placeholder="Enter Keyword" />
								</Form.Item>
								<Button
									size="small"
									className="add-btn"
									type="link"
									onClick={() => {
										this.handleCreateSubCompanyKeyword(
											this.formRef.current.getFieldValue(
												'subCompanyKeywordField'
											)
										);
										this.formRef.current.resetFields();
									}}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									Add Keyword
								</Button>
							</div>
							<div className="custom-form-group">
								<ListItems
									data={selectedSubCompanyKeywords}
									deleteItem={this.handleDeleteSubCompanyKeyword}
								/>
							</div>
							<h4 className="setting-card-title">Subcompany Logo</h4>
							<div className="custom-upload-picture">
								<Upload
									showUploadList
									name="logo"
									listType="picture-card"
									customRequest={async ({ file, onSuccess, onError }) => {
										try {
											const newSubCompanyLogo = {
												bucket: 'erin-avatars',
												key: `logo/${this.state.selectedSubCompany.id}/${file.name}`,
												region: 'us-east-2',
											};

											await uploadToS3Multipart(
												file,
												newSubCompanyLogo.key,
												newSubCompanyLogo.bucket
											);

											onSuccess(newSubCompanyLogo);
											this.setState({
												subCompanyLogo: newSubCompanyLogo,
											});
											this.handleUpdateSubCompany(newSubCompanyLogo);
										} catch (error) {
											onError(error);
										}
									}}
									beforeUpload={beforeUpload}
									multiple={false}
									disabled={fileListSubCompanyLogo.length > 0}
									fileList={fileListSubCompanyLogo}
								>
									<div className="upload-picture-btn">
										<i className="icon-plus" />
										<p className="upload-picture-text">Upload</p>
									</div>
								</Upload>
								{subCompanyLogo && this.state.selectedSubCompany && (
									<Popconfirm
										title="Are you sure？"
										placement="right"
										icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
										onConfirm={() => {
											this.setState({
												subCompanyLogo: null,
											});
											this.handleUpdateSubCompany(null);
										}}
									>
										<span className="cancel-picture-btn">
											<i className="icon-bin" />
											Remove
										</span>
									</Popconfirm>
								)}
							</div>
						</>
					)}
				</div>
			</Form>
		);
	}
}

export default BrandingSettingsForm;
