import { Cache } from 'aws-amplify';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { GetSAMLAuth } from 'src/_shared/api/graphql/custom/company/';

export const withListSAMLAuth = (Component, variables) => {
	return compose(
		graphql(GetSAMLAuth, {
			options: {
				context: {
					headers: {
						'x-frame-options': 'deny', // This header will reach the server
					},
				},
				variables: {
					id: Cache.getItem('federatedOrgCode'),
				},
				fetchPolicy: 'network-only',
			},
			props: (props) => ({
				samlData: get(props, 'data.getSAMLAuth'),
			}),
		})
	)(Component);
};
