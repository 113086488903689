import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Form, Input, Result, Row } from 'antd';
import { get, mapValues, upperFirst } from 'lodash';
import { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import LanguageSelect from 'src/_shared/components/language/LanguageSelectComponent.jsx';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import FormElements from 'src/form-builder/FormElements.jsx';
import uuid from 'uuid/v4';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { StringParserAlt } from '../_shared/services';
import {
	downloadFromS3,
	filterPhoneNumber,
	ml,
	parse,
	query,
	translation,
	uploadToS3Multipart,
} from '../_shared/services/utils';
import {
	contactButton,
	declineButton,
	applyButton,
	buttonSpacing,
} from '../new-referral-landing-page/newReferralLandingStyles.js';
import {
	CardStyles,
	Department,
	DetailContainer,
	FormItemStyles,
	InfoStyle,
	JobInfoContainer,
	NoteStyle,
	RemoveBottomMargin,
	cancelIconContainer,
	candidateNameStyles,
	cardContainer,
	detailsTitle,
	fileWrap,
	headerBackground,
	logoContainer,
	menuColor,
	outerCardContainer,
	refererName,
	sectionTitle,
	summaryValue,
	title,
	topSpacing,
} from './newReferralGdprStyles.js';

// GDPR file

class NewReferralCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonState:
				get(props, 'webNotification.status') === 'accepted' ? 'success' : '',
			referralStatus: get(props, 'webNotification.status'),
			isMore: false,
			text: [],
			theme: parse(
				get(
					props,
					'currentUser.company.theme',
					get(props, 'jobData.theme', '{}')
				)
			),
			isError: false,
			isResumeRequired: false,
			isResumeOptional: false,
			filename: null,
			filetype: null,
			errors: [],
			originalFile: [],
			translatedTitle: get(this.props, 'jobData.jobTitle', ''),
			translatedDescription: get(this.props, 'jobData.jobOverview', ''),
			allMultiLingualData: [],
			user: {},
			accountClaim: get(
				props,
				'webNotification.user.accountClaim',
				get(props, 'referredUser.accountClaim')
			),
			atsIntegration: get(
				props,
				'webNotification.user.company.atsIntegration',
				get(props, 'jobData.atsIntegration')
			),
			loading: false,
			disabled: false,
			isSubmitting: false,
			countryCode: (props.jobData?.countryCode ?? 'US').toLowerCase(), // TODO: stop casting to lower case after removing react-phone-input-2
			uniqueId: uuid(),
		};
	}

	formRef = createRef();

	async componentDidMount() {
		this.setResumeRequiredOptional();
		let subCompanyLogoSource = '';
		let companyLogoSource = '';
		if (this.props.jobData?.subCompanyLogo?.key) {
			subCompanyLogoSource = await downloadFromS3(
				this.props.jobData.subCompanyLogo.key
			);
		}

		if (this.props.jobData?.companyLogo?.key) {
			companyLogoSource = await downloadFromS3(
				this.props.jobData.companyLogo.key
			);
		}

		this.setState({
			subCompanyLogoSrc: subCompanyLogoSource,
			companyLogoSrc: companyLogoSource,
		});

		await this.getTranslatedData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.resumeAttachData !== this.props.resumeAttachData) {
			this.setResumeRequiredOptional();
		}
	}

	setResumeRequiredOptional() {
		const { resumeAttachData } = this.props;
		const resumeData = {};

		if (resumeAttachData && resumeAttachData.length > 0) {
			const acceptanceFormResumeData = resumeAttachData.filter(
				(item) => item.isCandidate === true
			);
			if (acceptanceFormResumeData.length > 0) {
				Object.assign(resumeData, ...acceptanceFormResumeData);
				const resumeAttachmentData = JSON.parse(resumeData.questions);

				for (const key in resumeAttachmentData) {
					if (resumeAttachmentData.hasOwnProperty(key)) {
						if (key === 'Resume required') {
							this.setState({ isResumeRequired: resumeAttachmentData[key] });
						} else {
							this.setState({ isResumeOptional: resumeAttachmentData[key] });
						}
					}
				}
			}
		}
	}

	renderAcceptButton() {
		const { allMultiLingualData, isError, user } = this.state;
		if (this.state.loading === true) {
			return <Spinner forceDefault />;
		}

		return (
			<div>
				{isError && (
					<>
						<div style={{ margin: '0 auto', width: '55%' }}>
							<Alert
								message={ml(
									'Please check all the required fields',
									user,
									allMultiLingualData
								)}
								type="error"
							/>
						</div>{' '}
					</>
				)}
				<div className={buttonSpacing}>
					<Button className={applyButton} size="large" htmlType="submit">
						<div
							style={{
								display: 'flex',
								margin: 'auto',
								textAlign: 'center',
							}}
						>
							<div style={{ paddingTop: 8 }}>
								<WebIcon color="white" name="checkmark" size={30} />
							</div>
							<div style={{ paddingTop: 6 }}>
								{ml('Accept & Apply', user, allMultiLingualData, 'NewReferral')}
							</div>
						</div>
					</Button>

					<div>
						<Button
							className={declineButton}
							size="large"
							onClick={() => this.handleUpdateStatus('declined')}
						>
							<div
								style={{
									display: 'flex',
									margin: 'auto',
									textAlign: 'center',
								}}
							>
								<div className={cancelIconContainer}>
									<WebIcon name="plus-symbol" size={18} />
								</div>
								<div>
									{ml(
										'Decline Referral',
										user,
										allMultiLingualData,
										'NewReferral'
									)}
								</div>
							</div>
						</Button>
					</div>
				</div>
			</div>
		);
	}

	onDelete = () => {
		// Delete file from input element
		const fileList = document.getElementById(
			`file-${this.state.uniqueId}`
		).files;
		fileList[0].value = null;

		this.setState({
			originalFile: [],
			filename: null,
			filetype: null,
			errors: [],
		});
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const errors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files! ');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must smaller than 2MB!');
			}

			if (errors.length > 0) {
				this.setState({ filename: escape(e.target.files[0].name) });
				this.setState({ errors });
				return errors;
			}

			this.setState({
				filename: escape(e.target.files[0].name),
				filetype: e.target.files[0].type,
				originalFile: e.target.files[0],
			});

			this.setState({ errors: [] });
			return isDocOrDocsOrPdf && isLt2M;
		}
	};

	getInputElement(item) {
		const Input = FormElements[item.element];
		return (
			item.text != 'Phone Number' && (
				<div key={`${item.id}`}>
					<Input
						ref={(c) => (this.inputs[item.field_name] = c)}
						key={`from_${item.id}`}
						mutable
						handleChange={this.handleChange}
						data={item}
						read_only={this.props.read_only}
					/>
				</div>
			)
		);
	}

	getSimpleElement(item) {
		const Element = FormElements[item.element];
		return <Element key={`${item.id}`} mutable data={item} />;
	}

	async getTranslatedData() {
		const { jobData } = this.props;
		let languageCode = get(jobData, 'languageCode');
		if (languageCode === 'EN') {
			languageCode = 'US';
		}

		try {
			const allMultiLingualData = await query(
				{ module: 'NewReferral' },
				'queryMultiLingualByModuleIndex'
			);
			this.setState({
				allMultiLingualData,
				user: { languageCode },
			});
		} catch (error) {
			console.log(error);
		}
	}

	GetICIMSRedirectLink(
		publicLink,
		firstName,
		lastName,
		emailAddress,
		source,
		sourceName
	) {
		let redirectLink =
			publicLink +
			`?mode=prepopulate&iifn=${firstName}&iiln=${lastName}&iiem=${emailAddress}&iis=${source}&iisn=${sourceName}`;
		try {
			const atsIntegration = get(this.state, 'atsIntegration');
			if (atsIntegration) {
				const atsIntegrationJSON = JSON.parse(atsIntegration);
				const UseATSIdToSubmit = get(
					atsIntegrationJSON[0],
					'UseATSIdToSubmit',
					false
				);
				if (UseATSIdToSubmit) {
					const accountClaim = get(this.state, 'accountClaim');
					// Make sure we have an account claim and an ATS Id
					if (accountClaim && accountClaim != null && accountClaim.atsId) {
						redirectLink =
							publicLink +
							`?mode=prepopulate&iis=Referral&iispid=${accountClaim.atsId}`;
					}
				}
			}
		} catch {
			redirectLink =
				publicLink +
				`?mode=prepopulate&iifn=${firstName}&iiln=${lastName}&iiem=${emailAddress}&iis=${source}&iisn=${sourceName}`;
		}

		return redirectLink;
	}

	_collect(item) {
		const errors = [];
		const itemData = { name: item.field_name };
		const ref = this.inputs[item.field_name];
		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			for (const option of item.options) {
				const $option = ReactDOM.findDOMNode(
					ref.options[`child_ref_${option.key}`]
				);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			}

			itemData.value = checked_options;
		} else {
			if (!ref) return null;
			itemData.value = this._getItemValue(item, ref).value;
			itemData.question = item.label;
			itemData.text =
				this._getItemValue(item, ref).text === undefined
					? ''
					: this._getItemValue(item, ref).text;
			if (itemData.value === '' && itemData.text === '') {
				errors.push(`${item.label} is required.`);
			}
		}

		const object = {
			itemData,
			errors,
		};
		return object;
	}

	_collectFormData(data) {
		const formData = [];
		let errors = '';
		for (const item of data) {
			const item_data =
				this._collect(item) === null ? null : this._collect(item).itemData;
			errors += this._collect(item) === null ? '' : this._collect(item).errors;
			if (item_data) {
				formData.push(item_data);
			}
		}

		const object = {
			formData,
			errors,
		};
		return object;
	}

	_getItemValue(item, ref) {
		let $item = {
			element: item.element,
			value: '',
			text: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;

				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;

				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;

				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';

				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
						if (item.element === 'Dropdown') {
							$item.text = $item.selectedOptions[0].innerText;
						}
					}
				}
			}
		}

		return $item;
	}

	handleSubmit = async (values) => {
		if (this.state.isSubmitting || this.state.loading) return;
		this.toggleIsSubmitting();
		const {
			jobData,
			onUpdateContact,
			onCreateContact,
			onCreateReferral,
			onUpdateWebNotification,
			webNotification,
		} = this.props;
		const { filename, isResumeRequired } = this.state;
		try {
			const { firstName, emailAddress, phoneNumber, lastName } = values;

			const quesErrors = this._collectFormData(
				this.props.referralQuestions
			).errors;

			if (quesErrors.length > 0) {
				this.setState({
					buttonState: 'error',
					loading: false,
					isError: true,
				});
				this.toggleIsSubmitting();
				return;
			}

			if (isResumeRequired && !filename) {
				this.setState({ isError: true });
				this.toggleIsSubmitting();
				return;
			}

			const duplicate = false;

			// Find duplicate referrals here with new api and set to true if exists. Original query exposing too much data.

			if (duplicate) {
				this.formRef.current.setFields({
					emailAddress: {
						value: emailAddress,
						errors: [
							new Error(
								'This person has already been referred for this position'
							),
						],
					},
				});
				this.setState({
					buttonState: 'error',
					loading: false,
				});
				this.toggleIsSubmitting();
				return;
			}

			// If pass all validation and errors, proceed to create contact, and referral, and redirect if necessary
			this.setState({ buttonState: 'loading', loading: true });

			const d = new Date();
			const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
			const companyId = get(
				this.props,
				'jobData.companyId',
				get(this.props, 'webNotification.companyId')
			);
			const userId = get(
				this.props,
				'jobData.userId',
				get(this.props, 'webNotification.userId')
			);
			const jobId = get(
				this.props,
				'jobData.jobId',
				get(this.props, 'webNotification.jobId')
			);
			const referralSource = get(this.props, 'webNotification.companyId')
				? 'direct'
				: 'share';

			const contactInput = {
				input: {
					firstName,
					lastName,
					socialMediaAccounts: null,
					userId,
					companyId,
					jobHistory: null,
					importMethod: emailAddress ? 'email' : null,
					disableSmartReferrals: false,
					emailAddress,
					confirmedEmail: emailAddress,
					phoneNumber,
				},
			};

			const createdContact = await onCreateContact(contactInput);

			const resume = {
				bucket: 'erin-documents',
				key: `resumes/${get(createdContact, 'id')}/${dformat + '-' + filename}`,
				region: 'us-east-2',
			};

			if (filename) {
				onUpdateContact({
					id: get(createdContact, 'id'),
					contactResume: resume,
				});
				await this.updateS3ContactResume(resume);
			}

			const candidateQuestionsData = JSON.stringify(
				this._collectFormData(get(this.props, 'referralQuestions')).formData
			);
			const questionsData = get(this.props, 'webNotification.questionsData');
			const referralType = get(this.props, 'webNotification.referralType');
			const referralDevice = get(this.props, 'webNotification.referralDevice');

			const referralInput = {
				companyId,
				contactId: get(createdContact, 'id'),
				referralType: 'email',
				referralSource,
				referralDevice: 'web',
				userId,
				jobId,
				status: 'accepted',
				note: null,
				message: null,
			};

			if (questionsData) referralInput.questionsData = questionsData;
			if (candidateQuestionsData)
				referralInput.candidateQuestionsData = candidateQuestionsData;
			if (filename) referralInput.contactResume = resume;
			if (referralType) referralInput.referralType = referralType;
			if (referralDevice) referralInput.referralDevice = referralDevice;

			const createdReferral = await onCreateReferral(referralInput);

			if (webNotification) {
				const input = {
					id: webNotification.id,
					status: 'accepted',
				};
				await onUpdateWebNotification(input);
			}

			this.setState({ buttonState: 'success' });

			await new Promise((resolve) => {
				setTimeout(() => resolve(), 2000);
			});
			this.setState({ referralStatus: 'accepted', loading: false });
			setTimeout(() => {
				const publicLink = get(jobData, 'jobUrl');
				const source = 'erin';
				const sourceName = get(createdReferral, 'user.emailAddress');
				const externalSource = get(jobData, 'externalSource');

				if (publicLink && publicLink !== 'true') {
					switch (externalSource) {
						case 'iCIMS': {
							let link = this.GetICIMSRedirectLink(
								publicLink,
								firstName,
								lastName,
								emailAddress,
								source,
								sourceName
							);
							if (!link || link == null) {
								link =
									publicLink +
									`?mode=prepopulate&iifn=${firstName}&iiln=${lastName}&iiem=${emailAddress}&iis=${source}&iisn=${sourceName}`;
							}

							window.location.href = link;

							break;
						}

						case 'Ultipro': {
							if (getEnironment === 'dev') {
								window.location.href =
									publicLink + `&sourceId=1e178a97-dc98-41fa-b783-f411cccaf83b`;
							} else {
								window.location.href =
									publicLink + `&sourceId=53cacc08-3c63-4525-b15d-d4d901e61dc6`;
							}

							break;
						}

						case 'Crelate': {
							window.location.href = publicLink + '?r=91';

							break;
						}

						case 'Greenhouse': {
							let sourceCode = '';
							let atsGreenhouseSettings = '';

							if (get(jobData, 'atsIntegration')) {
								atsGreenhouseSettings = JSON.parse(jobData.atsIntegration).find(
									(item) => item.ATSName === 'Greenhouse'
								);
								if (
									atsGreenhouseSettings !== undefined &&
									atsGreenhouseSettings !== ''
								) {
									sourceCode = atsGreenhouseSettings.sourceCode;
								}

								window.location.href =
									sourceCode !== undefined && sourceCode !== ''
										? publicLink + `?t=${sourceCode}`
										: publicLink;
							}

							break;
						}

						default: {
							window.location.href = publicLink;
						}
					}
				}

				this.setState({ isSubmitting: false });
			}, 2200);
			this.setState({ buttonState: '' });
		} catch (error) {
			this.setState({
				buttonState: 'error',
				loading: false,
				isError: true,
			});
			this.toggleIsSubmitting();
			console.log(error);
		}
	};

	handleUpdateStatus = async (updatedStatus) => {
		this.setState({ loading: true });
		const {
			onCreateContact,
			onCreateReferral,
			onUpdateWebNotification,
			webNotification,
		} = this.props;
		try {
			const { firstName, emailAddress, phoneNumber, lastName } =
				this.formRef.current.getFieldsValue();

			let companyId = get(
				this.props,
				'jobData.companyId',
				get(this.props, 'webNotification.companyId')
			);

			companyId ||= get(this.props, 'webNotification.job.companyId');

			const userId = get(
				this.props,
				'jobData.userId',
				get(this.props, 'webNotification.userId')
			);
			const jobId = get(
				this.props,
				'jobData.jobId',
				get(this.props, 'webNotification.jobId')
			);
			const referralSource = get(this.props, 'webNotification.companyId')
				? 'direct'
				: 'share';

			const contactInput = {
				input: {
					firstName,
					lastName,
					socialMediaAccounts: null,
					userId,
					companyId,
					jobHistory: null,
					importMethod: emailAddress ? 'email' : null,
					disableSmartReferrals: false,
					emailAddress,
					confirmedEmail: emailAddress,
					phoneNumber,
				},
			};

			const createdContact = await onCreateContact(contactInput);

			const referralInput = {
				companyId,
				contactId: get(createdContact, 'id'),
				referralType: 'email',
				referralSource,
				referralDevice: 'web',
				userId,
				jobId,
				status: updatedStatus,
				note: null,
				message: null,
			};

			const questionsData = get(this.props, 'webNotification.questionsData');
			const referralType = get(this.props, 'webNotification.referralType');
			const referralDevice = get(this.props, 'webNotification.referralDevice');
			const candidateQuestionsData = JSON.stringify(
				this._collectFormData(get(this.props, 'referralQuestions')).formData
			);

			if (questionsData) referralInput.questionsData = questionsData;
			if (referralType) referralInput.referralType = referralType;
			if (referralDevice) referralInput.referralDevice = referralDevice;
			if (candidateQuestionsData)
				referralInput.candidateQuestionsData = candidateQuestionsData;

			await onCreateReferral(referralInput);

			if (get(webNotification, 'status') === 'referred') {
				const input = {
					id: webNotification.id,
					status: updatedStatus,
				};
				await onUpdateWebNotification(input);
			}

			this.setState({ referralStatus: updatedStatus, loading: false });
		} catch (error) {
			console.log('error=============>>>>>>>>', error);
		}
	};

	inputs = {};

	async selectLanguage(languageCode) {
		const user = get(this.props, 'currentUser');
		let translatedTitle = get(this.props, 'jobData.jobTitle');
		let desc = get(this.props, 'jobData.jobOverview').toString('html');
		let translatedDescription = desc;
		if (languageCode !== 'US') {
			desc = `<h1>${translatedTitle}</h1>${desc}`;
			desc = desc.replaceAll('&nbsp;', '');
			desc = desc.replaceAll('&amp;', 'and');
			desc = desc.replaceAll('&', 'and');
			translatedDescription = await translation(desc, { languageCode });
			translatedTitle = translatedDescription.substring(
				translatedDescription.indexOf('<h1>') + 4,
				translatedDescription.lastIndexOf('</h1>')
			);
			translatedDescription = translatedDescription.slice(
				Math.max(0, translatedTitle.length + 9)
			);
		}

		this.props.setCurrentUser({ ...this.props.currentUser, languageCode });
		this.setState({
			translatedTitle,
			translatedDescription,
			user: { ...user, languageCode },
		});
	}

	toggleIsSubmitting = () => {
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
	};

	updateS3ContactResume = async (resume) => {
		const { filename, originalFile } = this.state;
		if (filename) {
			try {
				await uploadToS3Multipart(originalFile, resume.key, resume.bucket);
			} catch (error) {
				console.error(error);
			}
		}
	};

	render() {
		const { jobData, currentUser } = this.props;
		const {
			filename,
			allMultiLingualData,
			user,
			referralStatus,
			countryCode,
			isResumeOptional,
			isResumeRequired,
			uniqueId,
			companyLogoSrc,
			subCompanyLogoSrc,
		} = this.state;
		const company = get(currentUser, 'company');
		const whiteLabel = get(company, 'whiteLabel', get(jobData, 'whiteLabel'));
		const hideRecruiterInfo = get(jobData, 'hideRecruiterInfo');
		const mail = `mailto:${jobData.hiringEmail}?subject=${jobData.position} referred by ${jobData.referrer}`;
		const referrerMail = `mailto:${jobData.referrerEmail}`;
		const data_items = this.props.referralQuestions;

		let customItems = '';
		if (data_items.length > 0) {
			customItems = data_items.map((item) => {
				if (!item) return null;
				switch (item.element) {
					case 'TextInput':
					case 'NumberInput':
					case 'TextArea':
					case 'Dropdown':
					case 'DatePicker':
					case 'RadioButtons':
					case 'Rating':
					case 'Tags':
					case 'Range': {
						return this.getInputElement(item);
					}

					default: {
						return this.getSimpleElement(item);
					}
				}
			});
		}

		let isPhoneNumberEnable = false;
		this.props.referralQuestions.some((question) => {
			if (question.text == 'Phone Number') {
				isPhoneNumberEnable = true;
			}
		});
		// Regex removes converts all string '$null' and 'null' values to falsey null so that they don't show on screen
		const validJobData = mapValues(jobData, (o) => {
			if (/^\$?null/.test(o)) {
				return null;
			}

			return o;
		});

		return (
			<section className="landing-background">
				<div
					className={headerBackground}
					style={{
						paddingTop: 10,
						backgroundColor: this.props.jobData.brandColor,
						paddingBottom: 10,
					}}
				>
					<Row type="flex" justify="center">
						<Col className="heading-col" xs={24}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginBottom: 10,
								}}
							>
								<LanguageSelect
									topRight
									referralLanguage
									onSelect={this.selectLanguage.bind(this)}
								/>
							</div>
						</Col>
						<Col className="heading-col" xs={24}>
							{validJobData.subCompanyLogo ? (
								<div className={logoContainer}>
									<img src={subCompanyLogoSrc} alt="subcompany logo" />
								</div>
							) : validJobData.companyLogo ? (
								<div className={logoContainer}>
									<img src={companyLogoSrc} alt="company logo" />
								</div>
							) : (
								<div className={logoContainer}>
									<img src={ErinLogo} alt="Erin Logo" />
								</div>
							)}
							<div className={title} style={{ paddingTop: 10 }}>
								{ml(
									`You've been referred for a position at`,
									user,
									allMultiLingualData,
									'NewReferral'
								)}
								{validJobData.subCompany
									? ` ${validJobData.subCompany}`
									: validJobData.company
										? ` ${validJobData.company}`
										: ''}
							</div>
						</Col>
					</Row>
				</div>
				<div className={outerCardContainer}>
					<div className={cardContainer}>
						<Card
							headStyle={{ height: 100 }}
							className={CardStyles}
							bordered={false}
							style={{ boxShadow: 'none' }}
						>
							<div className={JobInfoContainer}>
								<div style={{ display: 'inline-block' }}>
									{validJobData.position ? (
										<h3 className={candidateNameStyles}>
											{get(this.state, 'translatedTitle')}
										</h3>
									) : null}

									<div className={detailsTitle}>
										{validJobData.department ? (
											<>
												<WebIcon name="folder" size={18} />
												<span className={Department}>
													{validJobData.department}
												</span>
											</>
										) : null}
										{validJobData.location ? (
											<>
												<WebIcon name="placeholder" size={18} />
												<span className={Department}>
													{validJobData.location}
												</span>
											</>
										) : null}
									</div>
								</div>
							</div>

							<div style={{ textAlign: 'center', paddingTop: '15px' }}>
								<Row type="flex" justify="center">
									<Col xs={23} m={24}>
										{validJobData.referrer ? (
											<p>
												{ml(
													'Referred By',
													user,
													allMultiLingualData,
													'NewReferral'
												)}
												{': '}

												<a href={referrerMail} className={refererName}>
													{validJobData.referrer}
												</a>
											</p>
										) : null}
									</Col>
								</Row>
							</div>
							<div className={topSpacing}>
								{validJobData.jobType ? (
									<Row className={detailsTitle}>
										<span>
											<b>
												{ml(
													'Job Type',
													user,
													allMultiLingualData,
													'NewReferral'
												)}
												:{' '}
											</b>
										</span>
										<span style={{ marginLeft: 5 }}>
											{validJobData.jobType === 'fulltime'
												? 'Full-Time'
												: validJobData.jobType === 'parttime'
													? 'Part-Time'
													: upperFirst(validJobData.jobType)}
										</span>
									</Row>
								) : null}
								{validJobData.salary && !validJobData.salary.includes('N/A') ? (
									<Row className={detailsTitle}>
										<span>
											<b>
												{ml(
													'Salary Range',
													user,
													allMultiLingualData,
													'NewReferral'
												)}
												:{' '}
											</b>
										</span>
										<span>{validJobData.salary}</span>
									</Row>
								) : null}
							</div>
							{/* THIS IS WHERE THE LEFT ALIGNED TEXT STARTS */}

							<Row style={{ marginBottom: '15px', marginTop: '15px' }}>
								<span className={sectionTitle}>
									<b>
										{ml(
											'Job Responsibilities',
											user,
											allMultiLingualData,
											'NewReferral'
										)}
										:{' '}
									</b>
								</span>
								<span className={summaryValue}>
									<StringParserAlt
										showMoreText={ml(
											'Show More',
											user,
											allMultiLingualData,
											'NewReferral'
										)}
										showLessText={ml(
											'Show Less',
											user,
											allMultiLingualData,
											'NewReferral'
										)}
										text={get(this.state, 'translatedDescription')}
									/>
								</span>
							</Row>
							{this.props.jobData?.status === 'closed' ? (
								validJobData.isGeneralReferral ? null : (
									<div className="text-3xl pt-4 text-red-500 text-center mx-auto min-w-[280px] max-w-[60%] ">
										{ml(
											'Thank you for your interest, but this job is no longer accepting new applications.',
											user,
											allMultiLingualData,
											'NewReferral'
										)}
									</div>
								)
							) : referralStatus === 'declined' ? (
								<Result
									status="error"
									title={ml(
										'Referral Declined',
										user,
										allMultiLingualData,
										'NewReferral'
									)}
									subTitle={ml(
										'Thank you for letting us know that you are not interested in this position.',
										user,
										allMultiLingualData,
										'NewReferral'
									)}
								/>
							) : referralStatus === 'accepted' ? (
								<div>
									<Result
										status="success"
										title={ml(
											`Referral Accepted`,
											user,
											allMultiLingualData,
											'NewReferral'
										)}
										subTitle={ml(
											`Your information has been successfully submitted.`,
											user,
											allMultiLingualData,
											'NewReferral'
										)}
										extra={
											<div className={buttonSpacing}>
												{jobData && jobData.jobUrl ? (
													<Button
														className={applyButton}
														size="large"
														href={jobData.jobUrl}
														target="_blank"
														rel="noopener noreferrer"
													>
														<div
															style={{
																display: 'flex',
																margin: 'auto',
																textAlign: 'center',
																alignItems: 'center',
															}}
														>
															<div style={{ paddingTop: 6, marginRight: 10 }}>
																<WebIcon color="white" name="id" size={30} />
															</div>
															<p
																style={{
																	paddingTop: 6,
																	color: 'white',
																	marginBlockEnd: 0,
																}}
															>
																{ml(
																	`View Job and Apply`,
																	user,
																	allMultiLingualData,
																	'NewReferral'
																)}
															</p>
														</div>
													</Button>
												) : null}
												{!hideRecruiterInfo && (
													<Button
														className={contactButton}
														size="large"
														href={mail}
													>
														<div
															style={{
																display: 'flex',
																margin: 'auto',
																textAlign: 'center',
																alignItems: 'center',
															}}
														>
															<div style={{ paddingTop: 6, marginRight: 10 }}>
																<WebIcon
																	color="white"
																	name="email-outline"
																	size={28}
																/>
															</div>
															<p
																style={{
																	paddingTop: 6,
																	color: 'white',
																	marginBlockEnd: 0,
																}}
															>
																{ml(
																	`Contact by Email`,
																	user,
																	allMultiLingualData,
																	'NewReferral'
																)}
															</p>
														</div>
													</Button>
												)}
											</div>
										}
									/>
								</div>
							) : (
								<div>
									<Form ref={this.formRef} onFinish={this.handleSubmit}>
										<div style={{ height: 'auto', marginTop: '0px' }}>
											<p className={NoteStyle}>
												{ml(
													'To accept your referral and apply to the position, we need more information.',
													user,
													allMultiLingualData,
													'NewReferral'
												)}
											</p>
										</div>
										<div className={DetailContainer}>
											<Row type="flex" justify="space-between" gutter={12}>
												<Col className="form-item-col" span={12}>
													<Form.Item
														className={FormItemStyles}
														name="firstName"
														rules={[
															{
																transform: (value) =>
																	value == undefined ? value : value.trim(),
															},
															{
																required: true,
																message: ml(
																	'Please enter your first name.',
																	user,
																	allMultiLingualData,
																	'NewReferral'
																),
															},
														]}
													>
														<Input
															className={RemoveBottomMargin}
															placeholder={ml(
																'First Name',
																user,
																allMultiLingualData,
																'NewReferral'
															)}
														/>
													</Form.Item>
												</Col>
												<Col className="form-item-col" span={12}>
													<Form.Item
														className={FormItemStyles}
														name="lastName"
														rules={[
															{
																transform: (value) =>
																	value == undefined ? value : value.trim(),
															},
															{
																required: true,
																message: ml(
																	'Please enter your last name.',
																	user,
																	allMultiLingualData,
																	'NewReferral'
																),
															},
														]}
													>
														<Input
															className={RemoveBottomMargin}
															placeholder={ml(
																'Last Name',
																user,
																allMultiLingualData,
																'NewReferral'
															)}
														/>
													</Form.Item>
												</Col>
												<Col className="form-item-col" span={24}>
													<Form.Item
														className={FormItemStyles}
														name="emailAddress"
														rules={[
															{
																type: 'email',
																message: ml(
																	'Enter a valid email.',
																	user,
																	allMultiLingualData,
																	'NewReferral'
																),
															},
															{
																required: true,
																message: ml(
																	'Please enter an email address.',
																	user,
																	allMultiLingualData,
																	'NewReferral'
																),
															},
														]}
													>
														<Input
															className={RemoveBottomMargin}
															placeholder={ml(
																'Email',
																user,
																allMultiLingualData,
																'NewReferral'
															)}
														/>
													</Form.Item>
												</Col>
											</Row>

											{isPhoneNumberEnable && (
												<Row>
													<Col className="form-item-col" span={24}>
														<Form.Item
															className={FormItemStyles}
															name="phoneNumber"
															rules={[
																{
																	validator(rule, value) {
																		return new Promise((resolve, reject) => {
																			if (!value) {
																				return reject(
																					ml(
																						'Please enter a phone number.',
																						user,
																						allMultiLingualData,
																						'NewReferral'
																					)
																				);
																			}

																			if (
																				!/^\+?\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,7}$/im.test(
																					filterPhoneNumber(value)
																				)
																			) {
																				return reject(
																					ml(
																						'Please enter a valid phone number.',
																						user,
																						allMultiLingualData,
																						'NewReferral'
																					)
																				);
																			}

																			resolve();
																		});
																	},
																},
															]}
														>
															<PhoneInput
																inputStyle={{
																	border: '1px solid',
																	borderRadius: 4,
																	borderColor: 'rgba(217, 217, 217, 0.95)',
																	color: 'rgba(0, 0, 0, 0.65)',
																	font: '400 14px system-ui',
																	padding: '0px 0px 0px 50px',
																	height: 32,
																	width: '100%',
																}}
																dropdownStyle={{
																	lineHeight: 1,
																}}
																country={countryCode}
																masks={{ cn: '... .... ....' }}
															/>
														</Form.Item>
													</Col>
												</Row>
											)}

											<div style={{ marginTop: '10px', fontWeight: 'bolder' }}>
												{isResumeOptional ? (
													<div className="App" style={{ marginBottom: '10px' }}>
														<span className={fileWrap}>
															<label htmlFor={'file-' + uniqueId}>
																{ml(
																	'Click here',
																	user,
																	allMultiLingualData,
																	'NewReferral'
																)}{' '}
															</label>
															<input
																ref={(ref) => {
																	this.uploadInput = ref;
																}}
																hidden
																type="file"
																accept=".doc,.docx,application/msword,.pdf,application/pdf"
																id={'file-' + uniqueId}
																name="file"
																onClick={(e) => {
																	this.setState({
																		filename: null,
																		filetype: null,
																		originalFile: [],
																	});
																	this.uploadInput = null;
																	e.target.value = null;
																}}
																onChange={this.onFileInputChange}
															/>
														</span>{' '}
														{ml(
															'to attach a resume',
															user,
															allMultiLingualData,
															'NewReferral'
														)}
														<span style={{ fontWeight: 'normal' }}>
															{' (.doc, .docx, .pdf) '}
														</span>
														<span style={{ color: 'red' }}>
															{isResumeRequired
																? ml(
																		'(required)',
																		user,
																		allMultiLingualData,
																		'NewReferral'
																	)
																: ''}
														</span>
														<span className="label-optional">
															{isResumeRequired
																? ''
																: ml(
																		'(optional)',
																		user,
																		allMultiLingualData,
																		'NewReferral'
																	)}
														</span>
														{filename && (
															<p>
																{filename}
																<DeleteOutlined
																	style={{
																		margin: '0px 0px 4px 10px',
																		color: '#ef3c3e',
																	}}
																	onClick={this.onDelete}
																/>
															</p>
														)}
														<div style={{ marginTop: 10 }}>
															<div
																style={{
																	width: '100%',
																	marginLeft: 'auto',
																	marginRight: 'auto',
																	textAlign: 'center',
																}}
															/>
														</div>
														{this.state.errors && this.state.errors.length > 0
															? this.state.errors.map((error) => (
																	<p
																		key={`error-${uuid()}`}
																		className="text-danger text-center"
																	>
																		{error}
																	</p>
																))
															: null}
													</div>
												) : null}
											</div>

											<div style={{ marginTop: 10 }}>{customItems}</div>
										</div>
										<div style={{ height: 'auto', marginBottom: '30px' }}>
											<p className={InfoStyle}>
												{ml(
													'By submitting, you consent to ERIN storing, processing and sharing your name and contact information with',
													user,
													allMultiLingualData,
													'NewReferral'
												)}{' '}
												{ml(
													'hiring team. Your data will never be sold or redistributed by ERIN. Please read our',
													user,
													allMultiLingualData,
													'NewReferral'
												)}{' '}
												<a
													className={menuColor}
													href="https://erinapp.com/privacy-policy"
													target="_blank"
													rel="noreferrer"
												>
													{ml(
														'Privacy Policy',
														user,
														allMultiLingualData,
														'NewReferral'
													)}
												</a>{' '}
												{ml(
													'for more information.',
													user,
													allMultiLingualData,
													'NewReferral'
												)}
											</p>
										</div>
										<div style={{ textAlign: 'center' }}>
											{this.renderAcceptButton()}
										</div>
									</Form>
								</div>
							)}
						</Card>
					</div>
				</div>
				{!whiteLabel && (
					<div style={{ marginTop: 10 }} className={headerBackground}>
						<p className={sectionTitle}>
							{ml('powered by', user, allMultiLingualData, 'NewReferral')}
						</p>
						<a href="https://erinapp.com/">
							<img alt="logo" style={{ width: 200 }} src={ErinLogo} />
						</a>
					</div>
				)}
			</section>
		);
	}
}

export default NewReferralCard;
