import gql from 'graphql-tag';

export const GetJobByIdBrowseJobs = gql`
	query GetJob($id: ID!) {
		getJob(id: $id) {
			id
			jobType
			dateCreated
			departmentId
			department {
				id
				name
			}
			companyId
			company {
				id
				# referralQuestions {
				#   id
				#   companyId
				#   questions
				#   active
				#   isCandidate
				#   sortOrder
				#   isInterested
				# }
				name
				referralBonusWaitingPeriod
				logo {
					bucket
					region
					key
				}
				theme
				symbol {
					bucket
					region
					key
				}
				background {
					bucket
					region
					key
				}
				errorImage {
					bucket
					region
					key
				}
				socialImage {
					bucket
					region
					key
				}
				websiteUrl
				brandColor
				atsIntegration
				whiteLabel
			}
			title
			description
			publicLink
			internalJobLink
			hideImInterested
			isGeneralReferral
			salary
			jobLevelIds
			jobLevels {
				id
				active
				name
				companyId
			}
			location
			locations
			createdById
			createdBy {
				id
				firstName
				lastName
			}
			hiringManagerId
			hiringManager {
				id
				emailAddress
				firstName
				lastName
			}
			referralBonus
			notificationType
			status
			shares
			views
			externalJobId
			externalSource
			isHotJob
			subCompanyId
			subCompany {
				id
				name
				logo {
					bucket
					key
					region
				}
			}
			campaignId
			campaign {
				id
				name
				startDate
				endDate
				archived
				tieredBonusId
			}
		}
	}
`;
