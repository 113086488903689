import React from "react";
import { Modal, Spin } from 'antd';
import CoreBadge from '../CoreBadge/CoreBadge.jsx'
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterShareButton,
	XIcon,
} from 'react-share';
import './viewRewardModal.scss'
import { addCommas } from "../../rewardsAndRecognitionHelper/utils.js";
import { MAPPED_BADGE_TYPE } from "../CoreBadge/rewardsConstants.js";

const ViewRewardModal = (props) => {
  const { isViewRewardModalVisible, handleCloseViewReward, rewardDetails, isModalLoaderVisible, isSharingVisible } = props;
  const shareURL = rewardDetails?.image;

  return <>
    <Modal
      open={isViewRewardModalVisible}
      onCancel={handleCloseViewReward}
      footer={false}
      width={600}
    >
      {
        isModalLoaderVisible ?
          <div className="d-flex align-items-center justify-content-center w-100 h-100"> <Spin /> </div> :
          rewardDetails && <div className="physical-reward-modal">
            <div className='row w-100'>
              <div className='col-sm-5 col-12'>
                <div style={{
                  height: isSharingVisible ? "auto" : "100%"
                }} className="physical-reward-badge">
                  {rewardDetails?.badgeTypeCode ? <CoreBadge
                    badgeType={rewardDetails?.badgeTypeCode}
                    badgeIcon={rewardDetails?.iconCode}
                    label={rewardDetails?.label}
                    color={rewardDetails?.color}
                  /> : <></>}
                </div>
                {isSharingVisible ?
                  <>
                    <div className="d-flex justify-content-center">
                      <p className="physical-reward-details-semi-title">
                        Tell Others About Your Award
                      </p>
                    </div>

                    <div className="physical-social-icon d-flex align-items-center justify-content-center">
                      <div>
                        <LinkedinShareButton
                          style={{ cursor: 'pointer', textAlign: 'center', marginRight: '6px' }}
                          url={shareURL}
                          title='Check out this badge:'
                        >
                          <LinkedinIcon round alt="LinkedIn" size={32} />
                        </LinkedinShareButton>
                      </div>
                      <div className="">
                        <FacebookShareButton
                          style={{ cursor: 'pointer', textAlign: 'center', marginRight: '6px' }}
                          url={shareURL}
                          quote='Check out this badge:'
                        >
                          <FacebookIcon round alt="Facebook" size={32} />
                        </FacebookShareButton>
                      </div>
                      <div className="">
                        <TwitterShareButton
                          style={{ cursor: 'pointer', textAlign: 'center' }}
                          url={shareURL}
                          title='Check out this badge:'
                        >
                          <XIcon round alt="X" size={32} />
                        </TwitterShareButton>
                      </div>
                    </div>
                  </>
                  : null}
              </div>
              <div className='col-sm-7 col-12'>
                <div className="physical-reward-details">
                  <h4 className="physical-reward-text">
                  <>{rewardDetails?.badgeTypeCode === MAPPED_BADGE_TYPE.AUTOMATED ? `${rewardDetails?.companyName} ` : ""}</>{rewardDetails?.name}
                  </h4>
                  <p className="physical-reward-details line-clamp-three">
                    {rewardDetails?.description}
                  </p>
                  <div className="award-detalis">
                    <div className="award-detalis-info">
                      <div className="award-detalis-about pr-4">
                        <span className="award-detalis-name pr-2">Total issued:</span>
                        <span className="award-detalis-numbers">{rewardDetails?.issuedBadgeCount}</span>
                      </div>
                      <div className="award-detalis-about">
                        <span className="award-detalis-name pr-2">Points:</span>
                        <span className="award-detalis-numbers">{addCommas(rewardDetails?.points)}</span>
                      </div>
                    </div>
                    <p className="text-gray line-clamp-three">
                      Only {rewardDetails?.issuedBadgePercentage}% employess have earned this badge!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ div>
      }
    </Modal>
  </>;
};

export default ViewRewardModal;
