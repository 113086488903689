export const getUserById = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    cognitoId
    companyId
    accountClaimId
    search
    company {
      id
      name
      defaultBonusAmount
      contactIncentiveBonus
      dashboardReferralPolicyText
      bonusEarnedNote
      allowSelfReferrals
      allowInternalMobility
      internalMobilityImage {
        bucket
        region
        key
      }
      websiteUrl
      dateCreated
      confirmCompliance
      ssoGoogleDomain
      confirmContactEmails
      brandColor
      whiteLabel
      theme
      symbol {
        bucket
        region
        key
      }
      background {
        bucket
        region
        key
      }
      errorImage {
        bucket
        region
        key
      }
      logo {
        bucket
        region
        key
      }
      enableGeneralReferrals
      referralStatus
      referralCustomStatuses
      allowSelfReferralsInternalLink
    }
    emailAddress
    role
    userGroupId
    firstName
    lastName
    title
    avatar {
      bucket
      region
      key
    }
    departmentId
    department {
      id
      companyId
      name
      active
    }
    lastLogin
    lastNotificationCheck
    incentiveEligible
    totalReferrals
    connectedApps
    active
    createdById
    dateCreated
  }
}
`;
