import { Button } from 'antd';
import { ml } from 'src/_shared/services/utils';

export function CancelEditJob(props) {
	const { allMultiLingualData, currentUser, handleOk, handleCancel } = props;

	return (
		<>
			<h5 className="text-center">
				{ml(
					'Are you sure you want to discard these changes?',
					currentUser,
					allMultiLingualData
				)}
			</h5>
			<div className="modal-footer-btn">
				<Button size="large" className="btn-min-width" onClick={handleCancel}>
					{ml('Discard Changes', currentUser, allMultiLingualData)}
				</Button>
				<Button
					type="primary"
					size="large"
					className="btn-min-width"
					onClick={handleOk}
				>
					{ml('Continue Working', currentUser, allMultiLingualData)}
				</Button>
			</div>
		</>
	);
}
