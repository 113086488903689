import React, { useMemo } from 'react';
import BadgeIcon from '../BadgeIcon/BadgeIcon'

const PeerToPeerBadge = (props) => {
    const {
        badgeIcon,
        color,
        breakWords
    } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={130}
            height={106}
            fill="none"
            viewBox="0 0 119 106"
        >
            <rect width={64} height={100} x={27.301} y={2.713} fill={color} rx={8} />
            <rect
                width={64}
                height={100}
                x={27}
                y={3}
                fill="url(#paint0_linear_292_10)"
                fillOpacity={0.3}
                rx={8}
                style={{
                    mixBlendMode: "multiply",
                }}
            />
            <rect
                width={64}
                height={100}
                x={86.603}
                fill={color}
                rx={8}
                transform="rotate(60 86.603 0)"
            />
            <rect
                width={64}
                height={100}
                x={86.603}
                fill="url(#paint1_linear_292_10)"
                fillOpacity={0.3}
                rx={8}
                style={{
                    mixBlendMode: "multiply",
                }}
                transform="rotate(60 86.603 0)"
            />
            <rect
                width={64}
                height={100}
                y={55.426}
                fill={color}
                rx={8}
                transform="rotate(-60 0 55.426)"
            />
            <rect
                width={64}
                height={100}
                y={55.426}
                fill="url(#paint2_linear_292_10)"
                fillOpacity={0.3}
                rx={8}
                style={{
                    mixBlendMode: "multiply",
                }}
                transform="rotate(-60 0 55.426)"
            />
            <circle cx={59} cy={53} r={30} fill="white" />
            <BadgeIcon color={color} iconName={badgeIcon} />
            <rect
                width={108}
                height={breakWords.length > 1 ? 30 : 24}
                x={5.5} y={68.585}
                fill="url(#paint3_linear_292_10)"
                rx={4}
            />
            {breakWords?.map((labelText, index) => {
                return (
                    <text
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        x="50%"
                        y={index === 1 ? "88%" : breakWords?.length > 1 ? "76%" : "80%"}
                        fill="white"
                        fontSize={10}
                        textAnchor="middle"
                    >
                        {labelText}
                    </text>
                );
            })}
            <defs>
                <linearGradient
                    id="paint0_linear_292_10"
                    x1={26.699}
                    x2={100.199}
                    y1={3.287}
                    y2={48.287}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.339} stopOpacity={0} />
                    <stop offset={0.765} stopOpacity={0.75} />
                    <stop offset={1} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_292_10"
                    x1={86.301}
                    x2={159.801}
                    y1={0.287}
                    y2={45.287}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.339} stopOpacity={0} />
                    <stop offset={0.765} stopOpacity={0.75} />
                    <stop offset={1} />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_292_10"
                    x1={-0.301}
                    x2={73.199}
                    y1={55.713}
                    y2={100.713}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.339} stopOpacity={0} />
                    <stop offset={0.765} stopOpacity={0.75} />
                    <stop offset={1} />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_292_10"
                    x1={60}
                    x2={60}
                    y1={68.585}
                    y2={92.585}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#494949" />
                    <stop offset={1} stopColor="#343434" />
                </linearGradient>
            </defs>
        </svg >
    )
}

export default PeerToPeerBadge;
