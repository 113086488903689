import { InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, List, Tooltip } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { USER_ROLES } from 'src/_shared/constants/index.js';
import {
	calculateTotalBonuses,
	getSetErrorImageURL,
	logout,
	ml,
	searchReferralData,
} from 'src/_shared/services/utils.js';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import FilterByStatus from '../_shared/components/BonusOptionComponent.jsx';
import { SearchComponent } from '../_shared/components/search';
import { ColorCard } from '../_shared/index.js';
import BonusCard from './bonus-card/myBonusCardContainer.js';

dayjs.extend(isBetween);
const filterAcceptedKey = 'due';
const filterReferredKey = 'notYetDue';
class MyBonusesComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			filteredReferrals: [],
			notifications: null,
			searchQuery: '',
			onDeckReferralModal: false,
			height: 0,
			width: 0,
			loaded: false,
			referralBonuses: get(props, 'referralBonuses'),
			dateRangeFilterValue: [],
			filteredData: get(this.props, 'bonuses', []),
			filteredStatuses: [],
			text: 'You can see bonuses here after a referral is hired.',
		};
	}

	async componentDidMount() {
		const host = window.location.hostname;
		const { currentUser, allMultiLingualData } = this.props;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		const refBonuses = await this.getReferralBonuses();

		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		this.setState({
			filteredReferrals: refBonuses,
			text: ml(
				'You can see bonuses here after a referral is hired.',
				currentUser,
				allMultiLingualData,
				'MyBonuses'
			),
			errorImageSrc: errorImageURL,
		});
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	async componentDidUpdate(prevProps) {
		const {
			referralBonuses,
			onFetchMoreReferralBonuses,
			currentUser,
			allMultiLingualData,
		} = this.props;
		if (
			prevProps.referralBonuses !== referralBonuses &&
			prevProps.referralBonuses.length <= referralBonuses.length
		) {
			const refBonuses = await this.getReferralBonuses();
			this.setState({
				filteredReferrals: refBonuses,
			});
			if (onFetchMoreReferralBonuses) onFetchMoreReferralBonuses();
		}

		if (
			prevProps.currentUser.languageCode !==
				this.props.currentUser.languageCode &&
			this.props.allMultiLingualData.length > 0
		) {
			this.props.setMultiLingualData(this.props.allMultiLingualData);
			this.setState({
				text: ml(
					'You can see bonuses here after a referral is hired.',
					currentUser,
					allMultiLingualData,
					'MyBonuses'
				),
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	getReferralBonuses = () => {
		const refBonusData =
			get(this.props, 'referralBonuses') === null
				? []
				: get(this.props, 'referralBonuses').filter(
						(item) => item.recipientType === 'employee'
					);

		return refBonusData;
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setFilteredData = () => {
		const { dateFilterValue, dateRangeFilterValue } = this.state;
		const { referralBonuses, currentUser, allMultiLingualData } = this.props;
		const empBonuses = referralBonuses.filter(
			(item) => item.recipientType === 'employee'
		);
		const dateFilteredResults = [];
		if (dateRangeFilterValue.length > 0) {
			for (const bonus of empBonuses) {
				if (
					dayjs(bonus.earnedDate).isBetween(
						dateRangeFilterValue[0],
						dateRangeFilterValue[1],
						'day',
						'[]'
					)
				) {
					dateFilteredResults.push(bonus);
				}
			}
		}

		const earnedDateFilteredResults =
			dateRangeFilterValue.length > 0
				? dateFilteredResults
				: this.getReferralBonuses();
		const results = earnedDateFilteredResults.filter(
			({ earnedDate, bonusStatus }) => {
				let result = null;

				if (dateFilterValue === 'due') {
					result = bonusStatus === 'earned' || bonusStatus === 'paid';
				} else if (dateFilterValue === 'notYetDue') {
					result = bonusStatus !== 'earned' && bonusStatus !== 'paid';
				} else {
					result = earnedDateFilteredResults;
				}

				return result;
			}
		);
		if (results.length === 0 || results === null) {
			this.setState({
				text: ml(
					'There are not any bonuses that match these filters.',
					currentUser,
					allMultiLingualData,
					'MyBonuses'
				),
			});
		}

		this.setState({
			filteredReferrals: results,
		});
		return results;
	};

	setFilteredDataNew = () => {
		const { dateRangeFilterValue, filteredStatuses } = this.state;

		const { referralBonuses, currentUser, allMultiLingualData } = this.props;
		const dateFilteredResults = [];
		if (dateRangeFilterValue.length > 0) {
			for (const bonus of referralBonuses) {
				if (
					dayjs(bonus.earnedDate).isBetween(
						dateRangeFilterValue[0],
						dateRangeFilterValue[1],
						'day',
						'[]'
					)
				) {
					dateFilteredResults.push(bonus);
				}
			}
		}

		const earnedDateFilteredResults =
			dateRangeFilterValue.length > 0
				? dateFilteredResults
				: this.getReferralBonuses();
		let results = [];

		if (filteredStatuses.length > 0) {
			results = earnedDateFilteredResults.filter(({ bonusStatus }) => {
				if (filteredStatuses.includes(bonusStatus)) {
					return true;
				}

				if (filteredStatuses.includes('eligible')) {
					return bonusStatus == 'earned';
				}

				return false;
			});
		} else {
			results = earnedDateFilteredResults;
		}

		if (results.length === 0 || results === null) {
			this.setState({
				text: ml(
					'There are not any bonuses that match these filters.',
					currentUser,
					allMultiLingualData,
					'MyBonuses'
				),
			});
		}

		this.setState({
			filteredReferrals: results,
		});
		return results;
	};

	setQueryToState = (value) => {
		this.setState({ searchQuery: value });
	};

	clearStatusFilter = () => {
		this.setState(
			{
				dateFilterValue: '',
			},
			this.setFilteredData
		);
	};

	handleDateRangeFilter(value) {
		this.setState(
			{
				dateRangeFilterValue: value,
			},
			this.setFilteredData
		);
	}

	handleReferralStatusFilter = (value) => {
		if (value.length === 0) {
			this.setState({ filteredReferrals: this.props.referrals });
			return;
		}

		const results = [];
		for (const record of this.props.referrals) {
			if (value.includes(record.status)) {
				results.push(record);
			}

			if (value === 'accepted' && record.status !== 'hired') {
				results.push(record);
			}
		}

		this.setState({ filteredReferrals: results });
	};

	handleRemoveStatusFilter = (value) => {
		const { filteredStatuses = [] } = this.state;
		const index = filteredStatuses.map((s) => s).indexOf(value);
		filteredStatuses.splice(index, 1);
		this.setState(
			{
				filteredStatuses,
			},
			this.setFilteredDataNew
		);
	};

	handleStatusFilter = (value) => {
		this.setState(
			{
				dateFilterValue: value,
				filteredStatuses: [...this.state.filteredStatuses, value],
			},
			this.setFilteredDataNew
		);
	};

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};

	render() {
		const { currentUser, allMultiLingualData } = this.props;
		const startDate = ml(
			`Start date`,
			currentUser,
			allMultiLingualData,
			'Bonuses'
		);
		const endDate = ml(`End date`, currentUser, allMultiLingualData, 'Bonuses');
		const {
			company,
			contacts,
			searchQuery,
			loaded,
			filteredStatuses,
			text,
			errorImageSrc,
		} = this.state;

		const whiteLabel = get(this.state, 'company.whiteLabel');

		let filteredReferrals = [];
		if (this.state.filteredReferrals) {
			filteredReferrals = this.state.filteredReferrals;
		}

		if (!get(currentUser, 'company') || !filteredReferrals)
			return <Spinner company={company} />;

		const searchedReferrals = searchReferralData(
			['firstName', 'lastName'],
			searchQuery,
			filteredReferrals
		);
		const referralData = searchedReferrals;
		if (!searchedReferrals) {
			return <Spinner company={company} />;
		}

		if (this.state.loaded !== true) {
			setTimeout(() => {
				this.setState({ loaded: true });
			}, 2000);
		}

		let sortedReferrals = _.sortBy(referralData, [
			(referral) => referral.earnedDate,
			(referral) => referral.referralId,
		]);

		const sortedReferrals2 = _.groupBy(sortedReferrals, 'referralId');
		for (var key in sortedReferrals2) {
			const filterData = sortedReferrals.filter(
				(object) => object.referralId == key
			);
			for (const [i, filterDatum] of filterData.entries()) {
				filterDatum.payment = i + 1 + ' of ' + sortedReferrals2[key].length;
			}
		}

		sortedReferrals = _.sortBy(sortedReferrals, function (referral) {
			return referral.earnedDate;
		}).reverse();
		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		if (userGroupCurrency === null) userGroupCurrency = 'USD';
		const hideBonus = get(company, 'hideBonus');
		const { RangePicker } = DatePicker;
		return (
			<main>
				<div className="color-card-grid">
					{(currentUser.displayAs === USER_ROLES.EMPLOYEE && !hideBonus) ||
					currentUser.displayAs !== USER_ROLES.EMPLOYEE ? (
						<>
							<ColorCard
								isOneSided
								bgColor="var(--fern)"
								count={
									<>
										{getSymbolFromCurrency(userGroupCurrency)}
										{sortedReferrals
											? calculateTotalBonuses(sortedReferrals, false)
											: '0'}
									</>
								}
								isCardDataSet={Boolean(sortedReferrals)}
								title={
									<>
										<span>{ml(`Total`, currentUser, allMultiLingualData)}</span>
										<span className="mr-1" />
										<Tooltip title={ml('This includes all bonuses, including bonuses that are not yet eligible to be paid.', currentUser, allMultiLingualData)}>
											<InfoCircleOutlined />
										</Tooltip>
									</>
								}
							/>
							<ColorCard
								isOneSided
								bgColor="var(--forest-green)"
								count={
									<Tooltip
										title={ml(
											`Your Earned Total reflects referral bonuses that are past the waiting period.`,
											currentUser,
											allMultiLingualData
										)}
									>
										<h4>
											{getSymbolFromCurrency(userGroupCurrency)}
											{sortedReferrals
												? calculateTotalBonuses(sortedReferrals, true)
												: '0'}
										</h4>
									</Tooltip>
								}
								isCardDataSet={Boolean(sortedReferrals)}
								title={
									<>
										<span>
											{ml(`Earned`, currentUser, allMultiLingualData)}
										</span>
										<span className="mr-1" />
										<Tooltip title={ml('This includes all bonuses that have met the eligibility requirements.', currentUser, allMultiLingualData)}>
											<InfoCircleOutlined />
										</Tooltip>
									</>
								}
							/>
						</>
					) : null}
				</div>
				{currentUser.displayAs !== USER_ROLES.EMPLOYEE &&
				this.props.currentUser.company.bonusEarnedNote === ' ' ? (
					<p style={{ textAlign: 'end' }}>
						{ml(`Earned per our`, currentUser, allMultiLingualData)}{' '}
						{this.props.currentUser.company.referralBonusWaitingPeriod}{' '}
						{ml(`day policy`, currentUser, allMultiLingualData)}
					</p>
				) : null}

				<div className="page-top-filter">
					<div className="filter-wrap">
						<SearchComponent
							searchQuery={searchQuery}
							setQueryToState={this.setQueryToState}
							placeholder={ml(`Search`, currentUser, allMultiLingualData)}
						/>
						<FilterByStatus
							allMultiLingualData={allMultiLingualData}
							filteredStatuses={filteredStatuses}
							acceptedKey={filterAcceptedKey}
							referredKey={filterReferredKey}
							handleStatusFilter={this.handleStatusFilter}
							handleRemoveStatusFilter={this.handleRemoveStatusFilter}
							clearStatusFilter={this.clearStatusFilter}
							currentUser={currentUser}
						/>
						<RangePicker
							className="ant-picker-320w-br"
							separator={<span>~</span>}
							format="MM-DD-YYYY"
							placeholder={[startDate, endDate]}
							onChange={(value) => this.handleDateRangeFilter(value)}
						/>
					</div>
				</div>
				<div>
					{sortedReferrals && sortedReferrals.length > 0 ? (
						<List
							grid={{ gutter: 10, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
							dataSource={sortedReferrals}
							itemLayout="horizontal"
							pagination={{ pageSize: 20, showSizeChanger: false }}
							renderItem={(referral, index) => (
								<List.Item>
									<BonusCard
										key={referral.id}
										referral={referral}
										referralType={referral.referralType}
										currentUser={currentUser}
										contacts={contacts}
										jobText={ml(
											`Job`,
											currentUser,
											allMultiLingualData,
											'MyBonuses'
										)}
										referredCandidateText={ml(
											`Referred Candidate`,
											currentUser,
											allMultiLingualData
										)}
										hiredDateText={ml(
											`Hired Date`,
											currentUser,
											allMultiLingualData
										)}
										startDateText={ml(
											`Start Date`,
											currentUser,
											allMultiLingualData
										)}
										bonusPaymentsText={ml(
											`Bonus Payments`,
											currentUser,
											allMultiLingualData
										)}
										bonusNotesText={ml(
											`Bonus Notes`,
											currentUser,
											allMultiLingualData
										)}
										closeText={ml(`Close`, currentUser, allMultiLingualData)}
										allMultiLingualData={allMultiLingualData}
										paymentText={ml(
											`Payment`,
											currentUser,
											allMultiLingualData
										)}
										eligibleDateText={ml(
											`Eligible Date`,
											currentUser,
											allMultiLingualData
										)}
									/>
								</List.Item>
							)}
						/>
					) : loaded ? (
						<div className="no-content">
							{whiteLabel ? (
								<img
									className="no-content-icon"
									src={errorImageSrc}
									alt="error image"
								/>
							) : (
								<img
									className="no-content-icon"
									src={fileIcon}
									alt="Erin App"
								/>
							)}
							<p className="no-content-text">{text}</p>
						</div>
					) : (
						<Spinner company={company} />
					)}
				</div>
			</main>
		);
	}
}
export default withApollo(MyBonusesComponent);
