import {
	CloseCircleOutlined,
	ExclamationCircleOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Select, Tooltip, message } from 'antd';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { configMode } from 'src/_shared/api/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { USER_ROLES } from 'src/_shared/constants/index.js';
import {
	getSetErrorImageURL,
	lambda,
	logout,
	ml,
	searchOpenSearchNetwork,
} from 'src/_shared/services/utils.js';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import {
	BonusTable,
	ColorCard,
  CSVExporter,
	SearchComponent,
	WebIcon,
} from '../_shared/components/index.js';
import { ExportSelector } from '../_shared/components/export-selector/ExportSelector.jsx';
import { AddBonusModal } from './bonus-modal';
import { FilterByStatus } from './bonus-report-components/index.js';

class BonusReportComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allUsers: [],
			bonuses: this.props?.bonuses ?? [],
			bonusCounts: {
				earned: 0,
				ineligible: 0,
				needsReview: 0,
				paid: 0,
				pending: 0,
				total: undefined,
			},
			company: this.props?.currentUser?.company,
			dateFilterValue: '',
			departments: this.props.departments,
			exportTableData: [],
			filteredDates: [],
			filteredReferralId: get(this.props, 'location.state.targetReferral'),
			filteredStatuses: [],
			filteredUserGroups: [],
			isCardDataSet: false,
			loading: true,
			searchQueryBonuses: '',
			requests: [],
			searchQuery: '',
			searchQueryUser: '',
			theme: JSON.parse(this.props?.currentUser?.company?.theme ?? '{}'),
			userGroups: this.props?.currentUser?.company?.userGroups ?? [],
			managerBonuses: this.props?.currentUser?.company
				?.disableManagerPermissionsByType
				? JSON.parse(
						this.props?.currentUser?.company?.disableManagerPermissionsByType
					).managerBonuses
				: 'hidden',
			errorImageURL: '',
			totalBonusCount: undefined,
		};
	}

	async componentDidMount() {
		const jwt = Cookies.get('jwt');
		const { currentUser } = this.props;
		const {
			searchQueryBonuses = '',
			searchQueryJobs = '',
			searchQueryUsers = '',
			userGroups = [],
		} = this.state;
		const expirationDoneByToken = get(
			currentUser,
			'expirationDoneByToken',
			null
		);
		const expires = get(currentUser, 'expires');
		if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
			logout(this.props.client);
		}

		this.handleDateRangeFilter(this.state.filteredDates);
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.setQueryBonusesToState(searchQueryBonuses);
		await this.setQueryUserToState(searchQueryUsers);
		await this.setQueryJobsToState(searchQueryJobs);
		const sortedUserGroups = _.sortBy(userGroups, ['name']);
		const userGroupOptions = sortedUserGroups.map((userGroup) => {
			return (
				<Select.Option key={userGroup.id} value={userGroup.id}>
					{userGroup.name}
				</Select.Option>
			);
		});
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}

		this.setState({ userGroupOptions });
	}

	getReport = () => {
		const endpoint = 'export-bonus-data';
		const { companyId, cognitoId } = this.props.currentUser;
		message.success(`Export processing. You will receive an email when ready`);
		lambda({
			endpoint,
			variables: {
				companyId,
				cognitoId,
				configMode,
			},
		});
	};

	setExportData = (data) => {
		this.setState({ exportTableData: data });
	};

	setQueryJobsToState = async (searchQueryJobs = '', timeout = 0) => {
		return new Promise(async (resolve, reject) => {
			const {
				filteredDepartments = [],
				filteredBonuses = [],
				filteredDistance = 0,
				filterOpenStatus = 'open',
				lat,
				lng,
				filteredSubCompanies = [],
				filteredJobLevels = [],
			} = this.state;

			const filterCompanies = get(this.state, 'filteredCompanies', []).map(
				(company) => get(company, 'id')
			);

			const extendedCompaniesData = get(
				this.props,
				'currentUser.extendedCompaniesData',
				[]
			);

			const extendedCompanies = extendedCompaniesData.filter(
				(company) => get(company, 'disableExtendedUserJobsView') !== true
			);
			const isExtendedUser =
				get(this.props, 'currentUser.role') === 'extendedUser';

			const comps =
				extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
					? extendedCompanies
					: [get(this.props, 'currentUser.company')];

			const companies = comps.map((company) => get(company, 'id'));
			const filteredCompanies =
				filterCompanies.length > 0 ? filterCompanies : companies;

			const unit =
				get(this.props, 'currentUser.userGroup.measurement', 'imperial') ===
				'metric'
					? 'km'
					: 'mi';
			clearTimeout(this.jobsTimer);
			this.setState({ searchQueryJobs });
			this.jobsTimer = setTimeout(async () => {
				const parameters = {
					query: get(this.state, 'searchQueryJobs', ''),
					size: 300,
					from: 0,
					coordinates: [{ lat, lng }],
					role: get(this.props, 'currentUser.role'),
					currentUser: get(this.props, 'currentUser.id'),
					filters: {
						bonuses: filteredBonuses,
						companies: filteredCompanies,
						departments: filteredDepartments,
						distance: filteredDistance,
						status: filterOpenStatus,
						subCompanies: filteredSubCompanies,
						jobLevels: filteredJobLevels,
					},
					unit,
				};
				const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
				if (get(response, 'query') === get(this.state, 'searchQueryJobs')) {
					const searchedJobs = get(response, 'data', []);
					this.setState({ searchedJobs });
				}

				resolve();
			}, timeout);
		});
	};

	setQueryBonusesToState = async (searchQueryBonuses = '', timeout = 0) => {
		const {
			company,
			filteredDates,
			filteredReferralId,
			filteredStatuses = [],
			filteredUserGroups = [],
		} = this.state;
		this.setState({ searchQueryBonuses });
		clearTimeout(this.timer);
		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			const parameters = {
				query: searchQueryBonuses,
				size: 300,
				from: 0,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					companies: [get(company, 'id')],
					dateEarned: filteredDates,
					referralId: filteredReferralId,
					statuses: filteredStatuses,
					userGroups: filteredUserGroups,
				},
			};
			const response = await searchOpenSearchNetwork(
				parameters,
				'erin-bonuses'
			);
			if (get(response, 'query') === get(this.state, 'searchQueryBonuses')) {
				const bonuses = get(response, 'data', []);

				const statusCountResponse = await lambda({
					endpoint: 'calculateBonusStatusCounts',
					variables: {
						companyId: company.id,
					},
				});
				const bonusCounts = statusCountResponse.message;

				this.setState({
					isCardDataSet: true,
					loading: false,
					bonuses,
					bonusCounts,
					totalBonusCount: response?.metadata?.showing?.totalItems,
				});
			}
		}, timeout);
	};

	setQueryUserToState = async (searchQueryUsers = '', timeout = 0) => {
		const {
			company,
			filteredDepartments = [],
			filteredRoles = [],
		} = this.state;

		this.setState({ searchQueryUsers });
		clearTimeout(this.userTimer);
		this.userTimer = setTimeout(async () => {
			const parameters = {
				query: searchQueryUsers,
				size: 300,
				from: 0,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					active: true,
					companies: [get(company, 'id')],
					departments: filteredDepartments,
					roles: filteredRoles,
				},
			};
			const response = await searchOpenSearchNetwork(parameters, 'erin-users');
			if (get(response, 'query') === get(this.state, 'searchQueryUsers')) {
				this.setState({
					searchedEmployees: get(response, 'data', []),
				});
			}
		}, timeout);
	};

	addBonus = (input) => {
		const { onCreateBonus } = this.props;
		onCreateBonus(input);
	};

	filterByReferralId = (filteredReferralId) => {
		const { searchQueryBonuses = '' } = this.state;
		this.setState({
			filteredReferralId,
		});
		this.setQueryBonusesToState(searchQueryBonuses);
	};

	handleClose = () => {
		this.setState({
			visible: false,
		});
	};

	handleDateRangeFilter = (filteredDates = []) => {
		const { searchQueryBonuses = '' } = this.state;
		if (filteredDates && filteredDates.length > 0) {
			filteredDates = {
				from: filteredDates[0].toISOString(),
				to: filteredDates[1].toISOString(),
			};
		}

		this.setState(
			{
				filteredDates,
			},
			async () => await this.setQueryBonusesToState(searchQueryBonuses)
		);
	};

	handleFilterUserGroup = (userGroup) => {
		const { searchQueryBonuses } = this.state;
		const filteredUserGroups = [...this.state.filteredUserGroups, userGroup];

		this.setState(
			{
				filteredUserGroups,
			},
			async () => await this.setQueryBonusesToState(searchQueryBonuses)
		);
	};

	handleRemoveReferralIdFilter = (value) => {
		const { searchQueryBonuses = '' } = this.state;
		if (get(this.props, 'location.state.targetReferral'))
			this.props.location.state.targetReferral = null;
		this.setState(
			{ filteredReferralId: null },
			async () => await this.setQueryBonusesToState(searchQueryBonuses)
		);
	};

	handleRemoveStatusFilter = (value) => {
		const { filteredStatuses = [], searchQueryBonuses = '' } = this.state;
		if (value === 'eligible') value = 'earned';
		const index = filteredStatuses.map((s) => s).indexOf(value);
		filteredStatuses.splice(index, 1);
		this.setState(
			{
				filteredStatuses,
			},
			async () => await this.setQueryBonusesToState(searchQueryBonuses)
		);
	};

	handleRemoveUserGroupFilter = (userGroup) => {
		const { filteredUserGroups = [], searchQueryBonuses = '' } = this.state;

		const index = filteredUserGroups.map((group) => group).indexOf(userGroup);
		filteredUserGroups.splice(index, 1);

		this.setState(
			{
				filteredUserGroups,
			},
			async () => await this.setQueryBonusesToState(searchQueryBonuses)
		);
	};

	handleStatusFilter = (value) => {
		let { searchQueryBonuses, filteredStatuses = [] } = this.state;
		if (value === 'eligible') value = 'earned';
		filteredStatuses = [...filteredStatuses, value];
		this.setState(
			{ filteredStatuses },
			async () => await this.setQueryBonusesToState(searchQueryBonuses)
		);
	};

	setExportData = (data) => {
		this.setState({ exportTableData: data });
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	sortByAlph = (a, b) => {
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	renderArrowIcon = (count) => {
		return (
			<div>
				<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
			</div>
		);
	};

	render() {
		const { currentUser, allMultiLingualData } = this.props;
		if (!get(currentUser, 'company')) return <Spinner />;
		const { RangePicker } = DatePicker;
		const {
			bonuses = [],
			bonusCounts,
			company,
			filteredStatuses = [],
			filteredDates = [],
			filteredReferralId = '',
			filteredUserGroups = [],
			isCardDataSet,
			loading,
			managerBonuses,
			searchQueryBonuses = '',
			searchedEmployees,
			searchedJobs,
			theme,
			totalBonusCount,
			userGroupOptions,
			visible,
			errorImageURL,
		} = this.state;
		const isManagerPermissionDisabled = Boolean(
			managerBonuses !== 'edit' && currentUser?.role === USER_ROLES.MANAGER
		);
		const contacts = get(this.props, 'currentUser.contacts');
		const whiteLabel = get(company, 'whiteLabel');
		const filterOn =
			filteredUserGroups?.length > 0 ||
			filteredStatuses?.length > 0 ||
			filteredDates?.length > 0 ||
			filteredReferralId?.length > 0 ||
			searchQueryBonuses?.length > 0;

		return bonuses ? (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('All Bonuses', currentUser, allMultiLingualData)}
					</h2>
					{!isManagerPermissionDisabled && (
						<ul className="info-action">
							<li>
								<Button
									type="link"
									className="add-btn btn-lg"
									onClick={this.showModal}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									{ml('Add Bonus', currentUser, this.props.allMultiLingualData)}
								</Button>
							</li>
						</ul>
					)}
				</div>
				<div className="color-card-grid">
					<ColorCard
						bgColor="var(--bright-turquoise)"
						count={
							bonuses &&
							Number.parseInt(bonusCounts.total).toLocaleString('en-US')
						}
						description={ml(
							'Total number of bonuses that have been created.',
							currentUser,
							this.props.allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml(
							'Total Bonuses',
							currentUser,
							this.props.allMultiLingualData
						)}
					/>
					<ColorCard
						bgColor="var(--tan-hide)"
						count={
							bonusCounts.pending &&
							Number.parseInt(bonusCounts.pending).toLocaleString('en-US')
						}
						description={ml(
							'Total number of bonuses that are currently in a waiting period and not yet eligible to be paid.',
							currentUser,
							this.props.allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml('Pending', currentUser, this.props.allMultiLingualData)}
					/>
					<ColorCard
						bgColor="var(--fern)"
						count={
							bonusCounts.earned &&
							Number.parseInt(bonusCounts.earned).toLocaleString('en-US')
						}
						description={
							<>
								{ml(
									'Total number of bonuses that are currently eligible to be paid',
									currentUser,
									this.props.allMultiLingualData
								)}
								<span className="mr-1" />
								<Tooltip
									title={ml(
										`All eligible checks and waiting periods are complete`,
										currentUser,
										allMultiLingualData
									)}
								>
									<InfoCircleOutlined />
								</Tooltip>
							</>
						}
						isCardDataSet={isCardDataSet}
						title={ml('Eligible', currentUser, this.props.allMultiLingualData)}
					/>
					<ColorCard
						bgColor="var(--forest-green)"
						count={
							bonusCounts.paid &&
							Number.parseInt(bonusCounts.paid).toLocaleString('en-US')
						}
						description={ml(
							'Total number of bonuses that have been paid.',
							currentUser,
							this.props.allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml('Paid', currentUser, this.props.allMultiLingualData)}
					/>
					<ColorCard
						bgColor="var(--sunset-orange)"
						count={
							bonusCounts.ineligible &&
							Number.parseInt(bonusCounts.ineligible).toLocaleString('en-US')
						}
						description={ml(
							'Total number of bonuses that are currently ineligible for any reason.',
							currentUser,
							this.props.allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml(
							'Ineligible',
							currentUser,
							this.props.allMultiLingualData
						)}
					/>
					<ColorCard
						bgColor="var(--mellow-yellow)"
						count={
							bonusCounts.needsReview &&
							Number.parseInt(bonusCounts.needsReview).toLocaleString('en-US')
						}
						description={ml(
							'Total number of bonuses that are currently flagged for manual review.',
							currentUser,
							this.props.allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml(
							'Needs Review',
							currentUser,
							this.props.allMultiLingualData
						)}
					/>
				</div>
				<div className="page-top-filter">
					<div className="filter-wrap">

            <SearchComponent
						  searchQuery={searchQueryBonuses}
						  setQueryToState={(query) => this.setQueryBonusesToState(query, 800)}
						  placeholder={ml('Search', currentUser, allMultiLingualData)}
					  />

						<Select
							showArrow
							mode="multiple"
							placeholder={ml('User Group', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredUserGroups.length)}
							onSelect={(userGroup, { key }) =>
								this.handleFilterUserGroup(userGroup, key)
							}
							onDeselect={(userGroup, { key }) =>
								this.handleRemoveUserGroupFilter(userGroup, key)
							}
						>
							{userGroupOptions}
						</Select>
						<FilterByStatus
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							filteredStatuses={filteredStatuses}
							handleStatusFilter={this.handleStatusFilter}
							handleRemoveStatusFilter={this.handleRemoveStatusFilter}
						/>
						<RangePicker
							className="ant-picker-320w-br"
							separator={<span>~</span>}
							format="MM-DD-YYYY"
							startdateText={ml('Start date', currentUser, allMultiLingualData)}
							enddateText={ml('End date', currentUser, allMultiLingualData)}
							onChange={(value) => {
								this.handleDateRangeFilter(value ?? []);
							}}
						/>
						{get(this.state, 'filteredReferralId') && (
							<Button
								type="link"
								className="danger"
								onClick={this.handleRemoveReferralIdFilter}
							>
								<CloseCircleOutlined />
								<span>
									{ml('Clear Filters', currentUser, allMultiLingualData)}
								</span>
							</Button>
						)}

						{filterOn && totalBonusCount > 300 && (
							<Tooltip
								title={ml(
									`Your filter includes over 300 results. Only the first 300 are displayed.`,
									currentUser,
									allMultiLingualData
								)}
								placement="right"
							>
								<ExclamationCircleOutlined style={{ marginLeft: 10 }} />
							</Tooltip>
						)}
					</div>
					<ExportSelector
						title={ml('Export', currentUser, allMultiLingualData)}
						items={[
							{
								key: 1,
								label: (
									<Tooltip
										title={ml(
											`You will receive an export via email of all data.`,
											currentUser,
											allMultiLingualData
										)}
										placement="left"
									>
										{ml('Export All', currentUser, allMultiLingualData)}
									</Tooltip>
								),
								onClick: this.getReport,
							},
							{
								key: 2,
								label: (
									<Tooltip
										title={
											totalBonusCount > 300
												? ml(
														'Unavailable: More than 300 results. Please filter to enable.',
														currentUser,
														allMultiLingualData
													)
												: ml(
														`Instantly download an export of the filtered results.`,
														currentUser,
														allMultiLingualData
													)
										}
										placement="left"
									>
										{ml('Export View', currentUser, allMultiLingualData)}
									</Tooltip>
								),
								onClick: 'csv-export',
								data: this.state.exportTableData,
								disabled: totalBonusCount > 300,
								reportType: 'bonus',
								companyName: this.props?.currentUser?.company?.name,
							},
						]}
					/>

				</div>
				<AddBonusModal
					currentUser={this.props.currentUser}
					theme={theme}
					open={visible}
					jobs={searchedJobs}
					allMultiLingualData={allMultiLingualData}
					handleClose={this.handleClose}
					employees={searchedEmployees}
					client={this.props.client}
					onCreateBonus={this.addBonus}
				/>

				{bonuses.length > 0 && !loading ? (
					<div className="table-card">
						<BonusTable
							filterByReferralId={this.filterByReferralId}
							filteredReferralId={this.state.filteredReferralId}
							company={company}
							theme={theme}
							bonuses={bonuses}
							setExportData={this.setExportData}
							tieredBonusQuery={this.props.tieredBonusQuery}
							role={this.props.currentUser.role}
							sortByAlph={this.sortByAlph}
							rowKey={(record) => record.id}
							currentUser={this.props.currentUser}
							allMultiLingualData={this.props.allMultiLingualData}
							onUpdateBonus={this.props.onUpdateBonus}
							onUpdateReferral={this.props.onUpdateReferral}
						/>
					</div>
				) : loading ? (
					<Spinner company={company} />
				) : (
					<div className="no-content">
						{whiteLabel && errorImageURL ? (
							<img
								src={errorImageURL}
								alt="error image"
								className="no-content-icon"
							/>
						) : (
							<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
						)}
						{filterOn ? (
							<p className="no-content-text">
								{ml(
									'There are no bonuses that meet the search criteria.',
									currentUser,
									allMultiLingualData
								)}
							</p>
						) : (
							<p className="no-content-text">
								{ml(
									'There are no bonuses. Bonuses are created automatically when a referral is marked as',
									currentUser,
									allMultiLingualData
								)}{' '}
								<strong>{ml('Hired', currentUser, allMultiLingualData)}</strong>
								.
							</p>
						)}
					</div>
				)}
			</main>
		) : (
			<div />
		);
	}
}

export default withApollo(BonusReportComponent);
