import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import queryString from 'query-string';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import {
	GetCompanyByHost,
	GetCompanyBySSOGoogleDomain,
} from 'src/_shared/api/graphql/custom/company/';
import {
	GetUserByCognitoId,
	updateUser,
} from 'src/_shared/api/graphql/custom/users/';
import {
	downloadFromS3,
	findItemByNameAndKeywords,
	getEnvironment,
	parse,
} from 'src/_shared/services/utils';
import { GetAccountClaimByEmployeeIdCompanyId } from 'src/graphql/custom/account-claims/';
import LoginBackground from '../_shared/assets/Erin_login_background.jpg';
import { slackNotificationAuth } from '../_shared/services';

class SAMLMobileLoginComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundImage: null,
			user: null,
			page: 1,
			visible: !get(props, 'error'),
			forgotPasswordVisible: false,
			navigate: false,
			userEmail: null,
			token: queryString.parse(this.props.location.hash),
			code: queryString.parse(this.props.location.search),
			theme: parse(get(props, 'currentUser.company.theme', '{}')),
			company: get(props, 'currentUser.company'),
			backgroundImageURL: '',
		};
		this.onAuthentication = this.onAuthentication.bind(this);
	}

	async componentDidMount() {
		const { setCurrentUser } = this.props;
		let { host } = window.location;
		host = host.replace('www.', '');
		if (getEnvironment() !== 'prod') {
			try {
				const { data } = await this.props.client.query({
					query: GetCompanyByHost,
					variables: { host },
				});
				const company = {
					...data.getCompanyByHost,
				};
				const theme = parse(get(company, 'theme', '{}'));
				this.setState({ company, theme });
				// Set background image for whitelabel comapnies
				const key = get(company, 'background.key', false);
				if (key) {
					const presignedURL = await downloadFromS3(key);
					this.setState({ backgroundImageURL: presignedURL }, () =>
						this.setBackgroundImage(company)
					);
				} else {
					this.setBackgroundImage(company);
				}
			} catch (error) {
				console.log(error);
			}
		}
		// Redirect back to mobile app for SAML authentication

		const operatingSystem = this.getMobileOperatingSystem();
		if (
			operatingSystem &&
			operatingSystem !== 'Tablet' &&
			get(this.state, 'token.id_token')
		) {
			window.location.replace(`erinapp://token=${this.state.token.id_token}`);
			return;
		}

		const redirectURL = this.props.location.hash;
		const tokenData = this.parseJwt();
		if (tokenData === undefined) {
			this.setState({ visible: true });
		} else {
			const cognitoId = Object.getOwnPropertyDescriptor(
				tokenData,
				'cognito:username'
			).value;
			const email = get(tokenData, 'email', '');

			let employeeId;
			try {
				employeeId = Object.getOwnPropertyDescriptor(
					tokenData,
					'custom:employeeid'
				).value;
			} catch {
				employeeId = null;
			}

			const jobClassId = get(tokenData, 'custom:jobClassId');
			const jobClassName = get(tokenData, 'custom:jobClassName');
			const jobFamilyGroupId = get(tokenData, 'custom:jobFamilyGroupId');
			const jobFamilyGroupName = get(tokenData, 'custom:jobFamilyGroupName');
			const jobFamilyId = get(tokenData, 'custom:jobFamilyId');
			const jobFamilyName = get(tokenData, 'custom:jobFamilyName');
			const jobProfileId = get(tokenData, 'custom:jobProfileId');
			const jobProfileName = get(tokenData, 'custom:jobProfileName');
			const managementLevel = get(tokenData, 'custom:managementLevel');
			const emailDomain = email.split('@')[1];

			if (cognitoId) {
				try {
					const { data } = await this.props.client.query({
						query: GetUserByCognitoId,
						variables: {
							cognitoId,
						},
					});
					const currentUser = {
						...data.getUserByCognitoId,
						authMethod: 'saml',
						lastLogin: dayjs().toISOString(),
					};

					let accountClaim = null;
					const companyId = get(currentUser, 'companyId');
					if (
						get(currentUser, 'company.sftpFolderName') &&
						employeeId &&
						companyId
					) {
						accountClaim = await this.getAccountClaimByEmployeeCompanyId(
							employeeId,
							companyId
						);
					}

					const subCompanyName = get(tokenData, 'custom:subCompany');
					const subCompanies = get(currentUser, 'company.subCompanies', []);
					const subCompany = findItemByNameAndKeywords(
						subCompanyName,
						subCompanies
					);
					const subCompanyId = subCompany ? subCompany.id : null;

					const userGroupName = get(
						tokenData,
						'custom:country',
						get(tokenData, 'custom:userGroup', 'Default')
					);
					const userGroups = get(currentUser, 'company.userGroups', []);
					const userGroup = findItemByNameAndKeywords(
						userGroupName,
						userGroups
					);
					const userGroupId = userGroup ? userGroup.id : null;

					const departmentName = get(tokenData, 'custom:department');
					const departments = get(currentUser, 'company.departments', []);
					const department = findItemByNameAndKeywords(
						departmentName,
						departments
					);
					const departmentId = department ? department.id : null;
					if (accountClaim)
						currentUser.accountClaimId = get(accountClaim, 'id');
					if (departmentId) currentUser.departmentId = departmentId;
					if (employeeId) currentUser.employeeId = employeeId;
					if (jobClassId) currentUser.jobClassId = jobClassId;
					if (jobClassName) currentUser.jobClassName = jobClassName;
					if (jobFamilyGroupId) currentUser.jobFamilyGroupId = jobFamilyGroupId;
					if (jobFamilyGroupName)
						currentUser.jobFamilyGroupName = jobFamilyGroupName;
					if (jobFamilyId) currentUser.jobFamilyId = jobFamilyId;
					if (jobFamilyName) currentUser.jobFamilyName = jobFamilyName;
					if (jobProfileId) currentUser.jobProfileId = jobProfileId;
					if (jobProfileName) currentUser.jobProfileName = jobProfileName;
					if (managementLevel) currentUser.managementLevel = managementLevel;
					if (subCompanyId) currentUser.subCompanyId = subCompanyId;
					if (userGroupId) currentUser.userGroupId = userGroupId;

					if (data && currentUser && !currentUser.active) {
						const error = new Error('User Disabled');
						error.code = 'UserDisabledException';
						throw error;
					} else {
						setCurrentUser(currentUser);
						this.onAuthentication(
							this.props.location.hash,
							currentUser,
							tokenData
						);
						const input = {
							id: data.getUserByCognitoId.id,
							authMethod: 'saml',
							lastLogin: dayjs().toISOString(),
						};
						if (accountClaim) input.accountClaimId = get(accountClaim, 'id');
						if (employeeId) input.employeeId = employeeId;
						if (jobClassId) input.jobClassId = jobClassId;
						if (jobClassName) input.jobClassName = jobClassName;
						if (jobFamilyGroupId) input.jobFamilyGroupId = jobFamilyGroupId;
						if (jobFamilyGroupName)
							input.jobFamilyGroupName = jobFamilyGroupName;
						if (jobFamilyId) input.jobFamilyId = jobFamilyId;
						if (jobFamilyName) input.jobFamilyName = jobFamilyName;
						if (jobProfileId) input.jobProfileId = jobProfileId;
						if (jobProfileName) input.jobProfileName = jobProfileName;
						if (managementLevel) input.managementLevel = managementLevel;
						if (subCompanyId) input.subCompanyId = subCompanyId;

						this.props.client
							.mutate({
								mutation: gql(updateUser),
								variables: {
									input,
								},
							})
							.then(() => {
								const redirectURL =
									localStorage.getItem('redirectURL') ?? '/dashboard';
								localStorage.removeItem('redirectURL');
								window.location.href = redirectURL;
							});
					}
				} catch (error) {
					const errorMessage = `GraphQL error: Error invoking method 'get(java.lang.Integer)' in java.util.ArrayList at velocity[line 2, column 31]`;
					// Create a new user if one does not exist already.
					const provider = get(tokenData, 'identities[0].providerName');
					let companyId = this.getCompanyIdFromProvider(provider);
					if (!companyId && provider === 'Google') {
						const company =
							await this.getCompanyIdBySSOGoogleDomain(emailDomain);
						companyId = get(company, 'id', '');
					}

					if (!companyId) {
						const error_ =
							'The provided google account does not match a company on record. Please sign in using your company email address.';
						this.props.history.push({
							pathname: '/login',
							state: {
								err: error_,
							},
						});
					} else if (companyId && error.message === errorMessage) {
						this.props.history.push({
							pathname: `/newsamluser/${companyId}`,
							state: {
								tokenData,
								redirectURL,
							},
						});
					}
				}
			}
		}
	}

	onAuthentication = async (authToken, currentUser, token) => {
		Cookies.set('jwt', authToken);
		this.setState({
			auth: true,
		});
		if (token) {
			try {
				let message = '';
				for (const [key, value] of Object.entries(token)) {
					message += `${key}: ${value} \n`;
				}

				await slackNotificationAuth({
					username: 'errors',
					title: `SAML Mobile Login User: ${get(
						currentUser,
						'firstName'
					)} ${get(currentUser, 'lastName')}: ${get(currentUser, 'id')}`,
					message,
				});
			} catch (error) {
				console.log(error);
			}
		}
	};

	getAccountClaimByEmployeeCompanyId = async (employeeId, companyId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { client } = this.props;
				const { data } = await client.query({
					query: GetAccountClaimByEmployeeIdCompanyId,
					variables: { employeeId, companyId },
				});
				const claim = {
					...data.getAccountClaimByEmployeeIdCompanyId,
				};
				return resolve(claim);
			} catch {
				return resolve(null);
			}
		});
	};

	getCompanyIdBySSOGoogleDomain = async (ssoGoogleDomain) => {
		const { client } = this.props;
		try {
			const { data } = await client.query({
				query: GetCompanyBySSOGoogleDomain,
				variables: { ssoGoogleDomain },
			});
			const company = {
				...data.getCompanyBySSOGoogleDomain,
			};
			return company;
		} catch {
			return '';
		}
	};

	getCompanyIdFromProvider = (provider) => {
		const samlProvider = get(this.props, 'samlData', []).find(
			(saml) => get(saml, 'provider') === provider
		);
		return get(samlProvider, 'companyId');
	};

	getMobileOperatingSystem = () => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		if (/android/i.test(userAgent) && !window.MSStream) {
			const width = window.innerWidth || document.body.clientWidth;
			return width > 1000 ? 'Tablet' : 'Android';
		}

		// IOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			const width = window.innerWidth || document.body.clientWidth;
			return width > 1000 ? 'Tablet' : 'iOS';
		}
	};

	setBackgroundImage = (company) => {
		const env = getEnvironment();
		const { backgroundImageURL } = this.state;
		const whiteLabel = get(company, 'whiteLabel');
		let backgroundImage = null;
		if (env !== 'prod' && whiteLabel && backgroundImageURL) {
			const key = get(company, 'background.key', false);
			backgroundImage = `url(${backgroundImageURL})`;
			this.setState({ backgroundImage });
		} else {
			backgroundImage = `url(${LoginBackground})`;
			this.setState({ backgroundImage });
		}
	};

	parseJwt = () => {
		const token = get(this.state, 'token.id_token');
		if (!token) {
			return;
		}

		const base64Url = token.split('.')[1];
		const base64 = base64Url.replaceAll('-', '+').replaceAll('_', '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		return parse(jsonPayload);
	};

	render() {
		const { company } = this.state;
		return (
			<div className="custom-loader">
				<LoadingOutlined width={60} height={60} />
			</div>
		);
	}
}

export default withApollo(SAMLMobileLoginComponent);
