import React, { useMemo } from "react";
import { Empty, Spin } from "antd";
import moment from 'moment';
import NoDataState from "../../../RnRCommonComponent/noDataState/NoDataState.jsx";
import { addCommas } from "../../../rewardsAndRecognitionHelper/utils.js";

const RecentActivity = ({
  recentActivityData,
  hasShowMoreData,
  recentActivityTemplateData,
  fetchMoreData,
  isRecentActivityLoading,
  isShowMoreClicked
}) => {

  const activityDetails = useMemo(() => {
    if (!recentActivityData || recentActivityData.length === 0 || !recentActivityTemplateData || recentActivityTemplateData.length === 0) {
      return <NoDataState title={"No recent activity."} />;
    }
    return recentActivityData.map((activity) => {
      const { activityType, targetName, pts, badgeName, iconUrl, createdAt } = activity;
      const formattedDate = moment(createdAt).format('M/D/YYYY  h:mm A');
      const template = recentActivityTemplateData[activityType];
      if (!template) return null;
      const activityDetailMessage = template
        .replace("{targetName}", targetName)
        .replace("{pts}", addCommas(pts))
        .replace("{badgeName}", badgeName);
      return ({
        formattedDate,
        activityDetailMessage,
        iconUrl
      })
    })
  }, [recentActivityData, recentActivityTemplateData])

  return (
    <div className="card-scroll">
      <div className="d-card">
        <div className='recent-activity'>
          <h4 className='recent-activity-title'>
            RECENT ACTIVITY
          </h4>
          {isRecentActivityLoading ? (
            <div className="d-flex align-items-center justify-content-center w-100 h-100">
              <Spin />
            </div>
          ) : (
            <div className='activity-detail'>
              {activityDetails?.length ? (
                activityDetails?.map((activity, index) => {
                  const { formattedDate, activityDetailMessage, iconUrl } = activity
                  return (
                    <div className='about-activity-detail' key={index}>
                      <img className='activity-img' src={iconUrl} alt='img' style={{ height: 20, width: 20 }} />
                      <span className='activity-info'>
                        {activityDetailMessage}
                        <p className="total-earned">{formattedDate}</p>
                      </span>
                    </div>
                  );
                })
              ) : (
                <NoDataState title={"No recent activity."} />
              )}
            </div>
          )}
          {hasShowMoreData && (
            <div className='show-more-btn'>
              <button onClick={fetchMoreData}> {isShowMoreClicked ? <Spin /> : "Show More"}</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentActivity;
