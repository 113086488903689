import { forwardRef, memo, useEffect } from 'react';
import { Editable, Handle } from './components';

export const Item = memo(
	forwardRef(
		(
			{
				color,
				dragOverlay,
				dragging,
				disabled,
				fadeIn,
				handle,
				handleProps,
				height,
				index,
				listeners,
				onRemove,
				renderItem,
				sorting,
				style,
				transition,
				transform,
				value,
				wrapperStyle,
				handleEdit,
				...props
			},
			ref
		) => {
			useEffect(() => {
				if (!dragOverlay) {
					return;
				}

				document.body.style.cursor = 'grabbing';

				return () => {
					document.body.style.cursor = '';
				};
			}, [dragOverlay]);
			return renderItem ? (
				renderItem({
					dragOverlay: Boolean(dragOverlay),
					dragging: Boolean(dragging),
					sorting: Boolean(sorting),
					index,
					fadeIn: Boolean(fadeIn),
					listeners,
					ref,
					style,
					transform,
					transition,
					value,
				})
			) : (
				<li
					ref={ref}
					className={`report-builder-wrapper ${fadeIn ? 'fadeIn' : ''} ${
						sorting ? 'sorting' : ''
					} ${dragOverlay ? 'dragOverlay' : ''}`}
					style={{
						...wrapperStyle,
						transition: [transition, wrapperStyle?.transition]
							.filter(Boolean)
							.join(', '),
						'--translate-x': transform
							? `${Math.round(transform.x)}px`
							: undefined,
						'--translate-y': transform
							? `${Math.round(transform.y)}px`
							: undefined,
						'--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
						'--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
						'--index': index,
						'--color': color,
					}}
				>
					<div
						className={`report-builder-item ${dragging ? 'dragging' : ''} ${
							handle ? 'withHandle' : ''
						} ${dragOverlay ? 'dragOverlay' : ''} ${color ? 'color' : 'color'}`}
						style={{
							...style,
						}}
						{...(handle ? undefined : listeners)}
						{...props}
						tabIndex={handle ? undefined : 0}
					>
						<Editable
							value={value}
							handleEdit={handleEdit}
							dragging={dragging}
						/>
						<span className="report-builder-actions">
							{handle ? <Handle {...handleProps} {...listeners} /> : null}
						</span>
					</div>
				</li>
			);
		}
	)
);
