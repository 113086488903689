import { connect } from 'react-redux';
import { withListCompanies } from 'src/_shared/api/components/company/';
import { withCreateDepartment } from 'src/_shared/api/components/departments/';
import { withCreateUserGroup } from 'src/_shared/api/components/userGroups/';
import { USER_ROLES } from '../_shared/constants';
import { compose } from '../_shared/services/utils';
import { SuperUser as SuperUserComponent } from './SuperUserComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	return {
		currentUser,
		filter: {
			role: { eq: USER_ROLES.SUPER_ADMIN },
		},
		limit: 1000,
	};
};

const SuperUserComponentWithApi = compose(
	withListCompanies,
	withCreateDepartment,
	withCreateUserGroup
)(SuperUserComponent);

export const SuperUser = connect(mapStateToProperties)(
	SuperUserComponentWithApi
);
