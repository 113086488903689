import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Form, Input, Row } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import gql from 'graphql-tag';
import { get, mapValues, upperFirst } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactDOM from 'react-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { getTieredBonus } from 'src/_shared/api/graphql/custom/tiered-bonuses/';
import LanguageSelect from 'src/_shared/components/language/LanguageSelectComponent.jsx';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { WebIcon } from 'src/_shared/index.js';
import FormElements from 'src/form-builder/FormElements.jsx';
import uuid from 'uuid/v4';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { StringParserAlt } from '../_shared/services';
import {
	downloadFromS3,
	filterPhoneNumber,
	ml,
	uploadToS3Multipart,
} from '../_shared/services/utils';
import { COLORS } from '../_shared/styles/colors';
import {
	ContainerDiv,
	DetailContainer,
	FormItemStyles,
	NoteStyle,
	RemoveBottomMargin,
	fileWrap,
} from '../new-referral-gdpr-landing-page/newReferralGdprStyles.js';
import {
	CardStyles,
	Department,
	InfoStyle,
	JobInfoContainer,
	applyButton,
	buttonSpacing,
	cancelIconContainer,
	candidateNameStyles,
	cardContainer,
	contactButton,
	declineButton,
	detailsTitle,
	headerBackground,
	logoContainer,
	menuColor,
	outerCardContainer,
	refererName,
	sectionTitle,
	subtitle,
	summaryValue,
	title,
	topSpacing,
} from './newReferralLandingStyles.js';

// NON-GDPR CARD
class NewReferralCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			company: get(props, 'currentUser.company'),
			loading: false,
			referralData: props.jobData,
			retentionBonus: [],
			currencySymbol: '',
			candidateQuestionsData: '',
			jobStatus: get(props, 'jobData.jobStatus'),
			isError: false,
			isResumeRequired: false,
			isResumeOptional: false,
			isSubmitting: false,
			filename: null,
			filetype: null,
			errors: [],
			originalFile: [],
			contact: get(props, 'referral.contact'),
			phoneNumber: get(props, 'referral.contact.phoneNumber', ''),
			countryCode: (props.referral?.company?.countryCode ?? 'US').toLowerCase(), // TODO: stop casting to lower case after removing react-phone-input-2
			uniqueId: uuid(),
		};
	}

	async componentDidMount() {
		this.setResumeRequiredOptional();
		let subCompanyLogoSource = '';
		let companyLogoSource = '';
		if (this.props.jobData?.subCompanyLogo?.key) {
			subCompanyLogoSource = await downloadFromS3(
				this.props.jobData.subCompanyLogo.key
			);
		}

		if (this.props.jobData?.companyLogo?.key) {
			companyLogoSource = await downloadFromS3(
				this.props.jobData.companyLogo.key
			);
		}

		this.setState({
			subCompanyLogoSrc: subCompanyLogoSource,
			companyLogoSrc: companyLogoSource,
		});

		this.getTieredBonusData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.resumeAttachData !== this.props.resumeAttachData) {
			this.setResumeRequiredOptional();
		}
	}

	setResumeRequiredOptional() {
		const { resumeAttachData } = this.props;
		const resumeData = {};

		if (resumeAttachData && resumeAttachData.length > 0) {
			const acceptanceFormResumeData = resumeAttachData.filter(
				(item) => item.isCandidate === true
			);
			if (acceptanceFormResumeData.length > 0) {
				Object.assign(resumeData, ...acceptanceFormResumeData);
				const resumeAttachmentData = JSON.parse(resumeData.questions);

				for (const key in resumeAttachmentData) {
					if (resumeAttachmentData.hasOwnProperty(key)) {
						if (key === 'Resume required') {
							this.setState({ isResumeRequired: resumeAttachmentData[key] });
						} else {
							this.setState({ isResumeOptional: resumeAttachmentData[key] });
						}
					}
				}
			}
		}
	}

	renderAcceptButton() {
		const { isError } = this.state;
		if (this.state.loading === true) {
			return <Spinner forceDefault />;
		}

		return (
			<div>
				{isError && (
					<>
						<div style={{ margin: '0 auto', width: '55%' }}>
							<Alert
								message="Please check all the required fields"
								type="error"
							/>
						</div>{' '}
					</>
				)}
				<div className={buttonSpacing}>
					<Button className={applyButton} size="large" htmlType="submit">
						<div
							style={{
								display: 'flex',
								margin: 'auto',
								textAlign: 'center',
							}}
						>
							<div style={{ paddingTop: 8 }}>
								<WebIcon color="white" name="checkmark" size={30} />
							</div>
							<div style={{ paddingTop: 6 }}>
								{ml(
									'Accept & Apply',
									this.props.currentUser || this.props.referral.user,
									this.props.allMultiLingualData,
									'NewReferral'
								)}
							</div>
						</div>
					</Button>

					<div>
						<Button
							className={declineButton}
							size="large"
							onClick={() => this.handleUpdateStatus('declined')}
						>
							<div
								style={{
									display: 'flex',
									margin: 'auto',
									textAlign: 'center',
								}}
							>
								<div className={cancelIconContainer}>
									<WebIcon name="plus-symbol" size={18} />
								</div>
								<div>
									{ml(
										'Decline Referral',
										this.props.currentUser || this.props.referral.user,
										this.props.allMultiLingualData,
										'NewReferral'
									)}
								</div>
							</div>
						</Button>
					</div>
				</div>
			</div>
		);
	}

	renderApplyButton() {
		const { allMultiLingualData, currentUser, jobData } = this.props;
		let publicLink = get(jobData, 'jobUrl');
		const externalSource = get(jobData, 'externalSource');
		const erin_candidate_id = get(jobData, 'contactId');
		const erin_referrer_id = get(jobData, 'employeeId');
		const email = get(jobData, 'contactConfirmedEmail');
		const fname = get(jobData, 'contactFirstName');
		const lname = get(jobData, 'contactLastName');
		const source = 'erin';
		const sourceName = get(jobData, 'referrerEmail');
		const atsIntegration = get(jobData, 'atsIntegration');

		let sourceCode = '';
		let sourceId = '';
		let atsGreenhouseSettings = '';
		let atsUltiproSettings = '';
		if (atsIntegration !== undefined && atsIntegration !== null) {
			atsGreenhouseSettings = JSON.parse(atsIntegration).find(
				(item) => item.ATSName === 'Greenhouse'
			);
			if (atsGreenhouseSettings !== undefined && atsGreenhouseSettings !== '') {
				sourceCode = atsGreenhouseSettings.sourceCode;
			}

			atsUltiproSettings = JSON.parse(atsIntegration).find(
				(item) => item.ATSName === 'Ultipro'
			);
			if (atsUltiproSettings !== undefined && atsUltiproSettings !== '') {
				sourceId = atsUltiproSettings.SourceId;
			}
		}

		if (publicLink && publicLink !== 'true') {
			if (externalSource === 'iCIMS') {
				publicLink += `?mode=prepopulate&iifn=${fname}&iiln=${lname}&iiem=${email}&iis=${source}&iisn=${sourceName}`;
			}

			if (
				externalSource === 'Greenhouse' &&
				sourceCode !== undefined &&
				sourceCode !== ''
			) {
				publicLink += `?t=${sourceCode}`;
			}

			if (externalSource === 'Ultipro') {
				publicLink += `&sourceId=${sourceId}`;
			}

			if (externalSource === 'Crelate') {
				publicLink += '?r=91';
			}

			if (externalSource === 'SeaWorld') {
				publicLink += `?utm_source=erin&erin_candidate_id=${erin_candidate_id}&erin_referrer_id=${erin_referrer_id}`;
			}
		}

		return (
			<Button
				className={applyButton}
				size="large"
				href={publicLink}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div
					style={{
						display: 'flex',
						margin: 'auto',
						textAlign: 'center',
						lineHeight: '38px'
					}}
				>
					<div style={{ paddingTop: 6, marginRight: 10 }}>
						<WebIcon color="white" name="id" size={30} />
					</div>
					<p style={{ paddingTop: 6, color: 'white' }}>
						{ml(
							`View Job and Apply`,
							currentUser || this.props.referral.user,
							allMultiLingualData,
							'NewReferral'
						)}
					</p>
				</div>
			</Button>
		);
	}

	renderActionButtons() {
		const { allMultiLingualData, currentUser, jobData } = this.props;
		const mail = `mailto:${jobData?.hiringEmail}?subject=${jobData?.position} referred by ${jobData?.referrer}`;
		const hideRecruiterInfo = get(jobData, 'hideRecruiterInfo');
		if (this.state.loading === true) {
			return (
				<Spinner
					forceDefault
					message={`${ml(
						`Submitting Response`,
						currentUser || this.props.referral.user,
						allMultiLingualData,
						'NewReferral'
					)}...`}
				/>
			);
		}

		return (
			<div className={buttonSpacing}>
				{jobData.jobUrl && this.renderApplyButton()}
				{!hideRecruiterInfo && (
					<Button
						className={contactButton}
						style={{ color: 'white' }}
						size="large"
						href={mail}
					>
						<WebIcon color="white" name="email-outline" size={28} />
						&nbsp;{' '}
						{ml(
							`Contact by Email`,
							currentUser || this.props.referral.user,
							allMultiLingualData,
							'NewReferral'
						)}
					</Button>
				)}
			</div>
		);
	}

	onDelete = () => {
		// Delete file from input element
		const fileList = document.getElementById(
			`file-${this.state.uniqueId}`
		).files;
		fileList[0].value = null;

		this.setState({ originalFile: [] });
		this.setState({ filename: null });
		this.setState({ filetype: null });
		this.setState({ errors: [] });
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const errors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files!');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must smaller than 2MB!');
			}

			if (errors.length > 0) {
				this.setState({ filename: e.target.files[0].name });
				this.setState({ errors });
				return errors;
			}

			this.setState({
				filename: e.target.files[0].name,
				filetype: e.target.files[0].type,
				originalFile: e.target.files[0],
			});

			this.setState({ errors: [] });
			return isDocOrDocsOrPdf && isLt2M;
		}
	};

	getInputElement(item) {
		const Input = FormElements[item.element];
		return (
			item.text != 'Phone Number' && (
				<div key={`${item.id}`}>
					<Input
						ref={(c) => (this.inputs[item.field_name] = c)}
						key={`from_${item.id}`}
						mutable
						handleChange={this.handleChange}
						data={item}
						read_only={this.props.read_only}
					/>
				</div>
			)
		);
	}

	getSimpleElement(item) {
		const Element = FormElements[item.element];
		return <Element key={`${item.id}`} mutable data={item} />;
	}

	async getTieredBonusData(policy = 'network-only') {
		const { client } = this.props;
		const { referralData } = this.state;
		let id = 'skip';
		let companyId;
		let userGroupCurrency = 'USD';
		let userGroupId;
		if (referralData.tieredBonusId !== undefined) {
			id = referralData.tieredBonusId;
		}

		if (referralData.companyId !== undefined) {
			companyId = referralData.companyId;
		}

		if (referralData.userGroupCurrency !== undefined) {
			userGroupCurrency = referralData.userGroupCurrency;
		}

		if (referralData.userGroupId !== undefined) {
			userGroupId = referralData.userGroupId;
		}

		const symbol = getSymbolFromCurrency(userGroupCurrency);
		try {
			const { data } = await client.query({
				query: gql(getTieredBonus),
				variables: {
					companyId,
					id,
				},
				fetchPolicy: policy,
			});
			if (data !== null && data !== undefined) {
				const bonuses = data.getTieredBonus;
				if (bonuses && bonuses.tiers !== undefined) {
					const candidateBonus = bonuses.tiers.filter((object) => {
						return (
							JSON.parse(object).recipientType === 'candidate' &&
							JSON.parse(object).userGroup === userGroupId
						);
					});
					if (candidateBonus.length > 0) {
						this.setState({
							retentionBonus: candidateBonus,
							currencySymbol: symbol,
						});
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	setLoading = () => {
		const { loading } = this.state;
		this.setState({ loading: !loading });
	};

	setLoadingFalse() {
		this.setState({ loading: false });
	}

	setPhoneNumber = (value) => {
		this.setState({
			phoneNumber: value,
		});
	};

	_collect(item) {
		const errors = [];
		const itemData = { name: item.field_name };
		const ref = this.inputs[item.field_name];
		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			for (const option of item.options) {
				const $option = ReactDOM.findDOMNode(
					ref.options[`child_ref_${option.key}`]
				);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			}

			itemData.value = checked_options;
		} else {
			if (!ref) return null;
			itemData.value = this._getItemValue(item, ref).value;

			itemData.question = item.label;
			itemData.text =
				this._getItemValue(item, ref).text === undefined
					? ''
					: this._getItemValue(item, ref).text;
			if (itemData.value === '' && itemData.text === '') {
				errors.push(`${item.label} is required.`);
			}
		}

		const object = {
			itemData,
			errors,
		};
		return object;
	}

	_collectFormData(data) {
		const formData = [];
		let errors = '';
		for (const item of data) {
			const item_data =
				this._collect(item) === null ? null : this._collect(item).itemData;
			errors += this._collect(item) === null ? '' : this._collect(item).errors;
			if (item_data) {
				formData.push(item_data);
			}
		}

		const object = {
			formData,
			errors,
		};
		return object;
	}

	_getItemValue(item, ref) {
		let $item = {
			element: item.element,
			value: '',
			text: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;

				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;

				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;

				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';

				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
						if (item.element === 'Dropdown') {
							$item.text = $item.selectedOptions[0].innerText;
						}
					}
				}
			}
		}

		return $item;
	}

	handleCountryChange = (country) => {
		// TODO: stop casting to lower case after removing react-phone-input-2
		this.setState({
			countryCode: (country.countryCode ?? 'US').toLowerCase(),
		});
	};

	handleError = () => {
		this.setState({ error: true });
		this.toggleIsSubmitting();
	};

	handleNewContact = () => {
		this.setState((prevState) => ({ newContact: !prevState.newContact }));
	};

	handleSubmit = async (values) => {
		if (this.state.isSubmitting || this.state.loading) return;
		this.toggleIsSubmitting();
		try {
			const { firstName, emailAddress, phoneNumber, lastName } = values;
			const { filename, originalFile, isResumeRequired } = this.state;
			const { onUpdateContact, referral } = this.props;

			const candidateQuestionsData = this._collectFormData(
				this.props.referralQuestions
			).formData;
			const isEmptySelect = candidateQuestionsData.some(
				(item) =>
					item.name.includes('dropdown') &&
					item.text === 'Select' &&
					item.value === '0'
			);
			const quesErrors = this._collectFormData(
				this.props.referralQuestions
			).errors;

			if (quesErrors.length > 0 || isEmptySelect) {
				this.setState({
					isError: true,
				});
				this.toggleIsSubmitting();
				return;
			}

			if (isResumeRequired && !filename) {
				this.setState({ isError: true });
				this.toggleIsSubmitting();
				return;
			}

			const contactId = get(referral, 'contactId', null);
			const d = new Date();
			const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
			const resume = '';

			if (filename) {
				try {
					const resume = {
						bucket: 'erin-documents',
						key: `resumes/${contactId}/${dformat + '-' + filename}`,
						region: 'us-east-2',
					};

					await uploadToS3Multipart(originalFile, resume.key, resume.bucket);
				} catch (error) {
					console.error(error);
					this.toggleIsSubmitting();
				}
			}

			this.setState({
				loading: true,
			});
			const source = 'erin';
			const sourceName = get(referral, 'user.emailAddress');
			const externalSource = get(referral, 'job.externalSource');
			const publicLink = get(referral, 'job.publicLink');

			const input = {
				id: contactId,
				confirmedEmail: emailAddress,
				emailAddress,
				firstName,
				lastName,
				phoneNumber,
			};

			if (resume) {
				input.contactResume = resume;
			}

			onUpdateContact(input);

			this.handleUpdateStatus('accepted', candidateQuestionsData, resume);
			setTimeout(() => {
				if (publicLink && publicLink !== 'true') {
					switch (externalSource) {
						case 'iCIMS': {
							window.location.href =
								publicLink +
								`?mode=prepopulate&iifn=${firstName}&iiln=${lastName}&iiem=${emailAddress}&iis=${source}&iisn=${sourceName}`;

							break;
						}

						case 'Ultipro': {
							if (
								[
									'localhost',
									'erinapp-dev.netlify.app',
									'erinapp-load-test.netlify.app',
									'testing.erinapp.com',
									'referralstest.aus.com',
								].includes(window.location.hostname)
							) {
								window.location.href =
									publicLink + `&sourceId=1e178a97-dc98-41fa-b783-f411cccaf83b`;
							} else {
								window.location.href =
									publicLink + `&sourceId=53cacc08-3c63-4525-b15d-d4d901e61dc6`;
							}

							break;
						}

						case 'Crelate': {
							window.location.href = publicLink + '?r=91';

							break;
						}

						case 'SeaWorld': {
							const erin_candidate_id = get(referral, 'contactId');
							const erin_referrer_id = get(referral, 'user.employeeId');
							window.location.href =
								publicLink +
								`?utm_source=erin&erin_candidate_id=${erin_candidate_id}&erin_referrer_id=${erin_referrer_id}`;

							break;
						}

						default: {
							window.location.href = publicLink;
						}
					}
				}

				this.setState({
					loading: false,
					isError: false,
				});
				this.setState({ isSubmitting: false });
			}, 4500);
		} catch (error) {
			this.setState({ isError: true });
			this.toggleIsSubmitting();
			console.log(error);
		}
	};

	handleUpdateStatus = (updatedStatus, candidateQuestionsData, resume) => {
		const { referral, jobData, onUpdateReferral, updateReferralState } =
			this.props;
		if (jobData.status === 'referred') {
			let input = {};
			if (resume === '') {
				input = {
					input: {
						id: get(jobData, 'id'),
						contactId: get(jobData, 'contactId'),
						jobId: get(jobData, 'jobId'),
						userId: get(jobData, 'userId'),
						status: updatedStatus,
						candidateQuestionsData: JSON.stringify(candidateQuestionsData),
					},
				};
			} else {
				input = {
					input: {
						id: get(jobData, 'id'),
						contactId: get(jobData, 'contactId'),
						jobId: get(jobData, 'jobId'),
						userId: get(jobData, 'userId'),
						status: updatedStatus,
						candidateQuestionsData: JSON.stringify(candidateQuestionsData),
						contactResume: resume,
					},
				};
			}

			onUpdateReferral(input);
			updateReferralState(referral, input);
		}
	};

	inputs = {};

	toggleIsSubmitting = () => {
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
	};

	render() {
		const { jobData, currentUser, allMultiLingualData } = this.props;
		const {
			company,
			companyLogoSrc,
			subCompanyLogoSrc,
			retentionBonus,
			currencySymbol,
			filename,
			contact,
			countryCode,
			isResumeOptional,
			isResumeRequired,
			uniqueId,
		} = this.state;
		const whiteLabel = get(company, 'whiteLabel', get(jobData, 'whiteLabel'));
		const referrerMail = `mailto:${jobData.referrerEmail}`;
		let publicLink = get(jobData, 'jobUrl');
		const externalSource = get(jobData, 'externalSource');
		const erin_candidate_id = get(jobData, 'contactId');
		const erin_referrer_id = get(jobData, 'employeeId');
		const email = get(jobData, 'contactConfirmedEmail');
		const fname = get(jobData, 'contactFirstName');
		const lname = get(jobData, 'contactLastName');
		const source = 'erin';
		const sourceName = get(jobData, 'referrerEmail');
		const enableCandidateBonus = get(jobData, 'enableCandidateBonus');
		const atsIntegration = get(jobData, 'atsIntegration');

		let sourceCode = '';
		let sourceId = '';
		let atsGreenhouseSettings = '';
		let atsUltiproSettings = '';
		if (atsIntegration !== undefined && atsIntegration !== null) {
			atsGreenhouseSettings = JSON.parse(atsIntegration).find(
				(item) => item.ATSName === 'Greenhouse'
			);
			if (atsGreenhouseSettings !== undefined && atsGreenhouseSettings !== '') {
				sourceCode = atsGreenhouseSettings.sourceCode;
			}

			atsUltiproSettings = JSON.parse(atsIntegration).find(
				(item) => item.ATSName === 'Ultipro'
			);
			if (atsUltiproSettings !== undefined && atsUltiproSettings !== '') {
				sourceId = atsUltiproSettings.SourceId;
			}
		}

		if (publicLink && publicLink !== 'true') {
			if (externalSource === 'iCIMS') {
				publicLink += `?mode=prepopulate&iifn=${fname}&iiln=${lname}&iiem=${email}&iis=${source}&iisn=${sourceName}`;
			}

			if (
				externalSource === 'Greenhouse' &&
				sourceCode !== undefined &&
				sourceCode !== ''
			) {
				publicLink += `?t=${sourceCode}`;
			}

			if (externalSource === 'Ultipro') {
				publicLink += `&sourceId=${sourceId}`;
			}

			if (externalSource === 'Crelate') {
				publicLink += '?r=91';
			}

			if (externalSource === 'SeaWorld') {
				publicLink += `?utm_source=erin&erin_candidate_id=${erin_candidate_id}&erin_referrer_id=${erin_referrer_id}`;
			}
		}

		let items = '';
		if (retentionBonus.length > 0) {
			const length_ = retentionBonus.length - 1;
			items = retentionBonus.map((bonus, index) => {
				const bonusAmount = Number.parseInt(
					get(JSON.parse(bonus), 'amount', 0)
				);
				const days = Number.parseInt(get(JSON.parse(bonus), 'payOutDays', ''));
				const item = (
					<span key={index}>
						<span>{`${currencySymbol}`}</span>
						<span style={{ color: '#1d861d', fontWeight: 600 }}>
							{bonusAmount}
						</span>
						<span>{' after '}</span>
						<span>
							{days} {' days'}
						</span>
						<span>{index < length_ ? ', ' : ''}</span>
					</span>
				);
				return item;
			});
		}

		const renderSubtitle = () => {
			const { allMultiLingualData, currentUser } = this.props;
			switch (jobData.status) {
				case 'referred': {
					return '';
				}

				case 'declined': {
					return ml(
						'Thanks for letting us know!',
						currentUser || this.props.referral.user,
						allMultiLingualData,
						'NewReferral'
					);
				}

				default: {
					return ml(
						'Referral Accepted',
						currentUser || this.props.referral.user,
						allMultiLingualData,
						'NewReferral'
					);
				}
			}
		};

		const data_items = this.props.referralQuestions;

		let customItems = '';
		if (data_items.length > 0) {
			customItems = data_items.map((item) => {
				if (!item) return null;
				switch (item.element) {
					case 'TextInput':
					case 'NumberInput':
					case 'TextArea':
					case 'Dropdown':
					case 'DatePicker':
					case 'RadioButtons':
					case 'Rating':
					case 'Tags':
					case 'Range': {
						return this.getInputElement(item);
					}

					default: {
						return this.getSimpleElement(item);
					}
				}
			});
		}

		let isPhoneNumberEnable = false;
		this.props.referralQuestions.some((x) => {
			if (x.text == 'Phone Number') {
				isPhoneNumberEnable = true;
			}
		});

		// Regex removes converts all string '$null' and 'null' values to falsey null so that they don't show on screen
		const validJobData = mapValues(jobData, (o) => {
			if (/^\$?null/.test(o)) {
				return null;
			}

			return o;
		});
		return (
			<section className="landing-background">
				<div
					className={headerBackground}
					style={{
						paddingTop: 10,
						backgroundColor: validJobData.brandColor,
						paddingBottom: 10,
					}}
				>
					<Row type="flex" justify="center">
						<Col className="heading-col" xs={24}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginBottom: 10,
								}}
							>
								<LanguageSelect
									languageCode={currentUser?.languageCode || this.props.referral.user.languageCode}
									topRight
									referralLanguage
									onSelect={this.props.selectLanguage}
								/>
							</div>
						</Col>
						<Col className="heading-col" xs={24}>
							{validJobData.subCompanyLogo ? (
								<div className={logoContainer}>
									<img src={subCompanyLogoSrc} alt="subcompany logo" />
								</div>
							) : validJobData.companyLogo ? (
								<div className={logoContainer}>
									<img src={companyLogoSrc} alt="company logo" />
								</div>
							) : (
								<div className={logoContainer}>
									<img src={ErinLogo} alt="Erin Logo" />
								</div>
							)}

							<div className={title} style={{ paddingTop: 10 }}>
								{ml(
									`You've been referred for a position at`,
									currentUser || this.props.referral.user,
									allMultiLingualData,
									'NewReferral'
								)}
								{validJobData.subCompany
									? ` ${validJobData.subCompany}`
									: validJobData.company
										? ` ${validJobData.company}`
										: ''}
							</div>
						</Col>
					</Row>
				</div>
				<div className={outerCardContainer}>
					<div className={cardContainer}>
						<Card
							headStyle={{ height: 100 }}
							className={CardStyles}
							bordered={false}
							style={{ boxShadow: 'none' }}
						>
							<div className={JobInfoContainer}>
								<div style={{ display: 'inline-block' }}>
									{validJobData.position ? (
										<h3 className={candidateNameStyles}>
											{validJobData.position}
										</h3>
									) : null}
									<div className={detailsTitle}>
										{validJobData.department ? (
											<>
												<WebIcon name="folder" size={18} />
												<span className={Department}>
													{validJobData.department}
												</span>
											</>
										) : null}
										{validJobData.location ? (
											<>
												<WebIcon name="placeholder" size={18} />
												<span className={Department}>
													{validJobData.location}
												</span>
											</>
										) : null}
									</div>
								</div>
							</div>
							<div style={{ textAlign: 'center', paddingTop: '15px' }}>
								<Row type="flex" justify="center">
									<Col xs={23} m={24}>
										{validJobData.referrer && (
											<p>
												{ml(
													'Referred By',
													currentUser || this.props.referral.user,
													allMultiLingualData,
													'NewReferral'
												)}
												:{' '}
												<a href={referrerMail} className={refererName}>
													{validJobData.referrer}
												</a>
											</p>
										)}
									</Col>
								</Row>
							</div>
							<div className={topSpacing}>
								{enableCandidateBonus ? (
									<Row className={detailsTitle}>
										<span>
											<b>
												{' '}
												{ml(
													'Retention Bonus',
													currentUser || this.props.referral.user,
													allMultiLingualData,
													'NewReferral'
												)}
												:{' '}
											</b>
										</span>
										{items}
									</Row>
								) : null}
								{validJobData.jobType ? (
									<Row className={detailsTitle}>
										<span>
											<b>
												{ml(
													'Job Type',
													currentUser || this.props.referral.user,
													allMultiLingualData,
													'NewReferral'
												)}
												:{' '}
											</b>
										</span>
										<span>
											{validJobData.jobType === 'fulltime'
												? ml('Full-Time', currentUser || this.props.referral.user, allMultiLingualData)
												: validJobData.jobType === 'parttime'
													? ml('Part-Time', currentUser || this.props.referral.user, allMultiLingualData)
													: upperFirst(validJobData.jobType)}
										</span>
									</Row>
								) : null}
								<div>
									{validJobData.salary &&
									!validJobData.salary.includes('N/A') ? (
										<Row className={detailsTitle}>
											<span>
												<b>
													{ml(
														'Salary Range',
														currentUser || this.props.referral.user,
														allMultiLingualData,
														'NewReferral'
													)}
													:{' '}
												</b>
											</span>
											<span>{validJobData.salary}</span>
										</Row>
									) : null}
								</div>
							</div>

							<Row style={{ marginBottom: '15px', marginTop: '15px' }}>
								<span className={sectionTitle}>
									<b>
										{ml(
											'Job Responsibilities',
											currentUser || this.props.referral.user,
											allMultiLingualData,
											'NewReferral'
										)}
										:{' '}
									</b>
								</span>
								<span className={summaryValue}>
									<StringParserAlt
										showMoreText={ml("Show More", currentUser || this.props.referral.user, allMultiLingualData)}
										showLessText={ml("Show Less", currentUser || this.props.referral.user, allMultiLingualData)}
										text={
											validJobData.jobOverview
												? validJobData.jobOverview.toString('html')
												: ''
										}
									/>
								</span>
							</Row>

							<div className={topSpacing}>
								<Row>
									<h2 className={subtitle} style={{ color: COLORS.green }}>
										{renderSubtitle()}
									</h2>
								</Row>
							</div>
							{this.props.jobData?.jobStatus === 'closed' ? (
								validJobData.isGeneralReferral ? null : (
									<div className="text-3xl pt-4 text-red-500 text-center mx-auto min-w-[280px] max-w-[50%] ">
										{ml(
											'Thank you for your interest, but this job is no longer accepting new applications.',
											currentUser || this.props.referral.user,
											allMultiLingualData,
											'NewReferral'
										)}
									</div>
								)
							) : (
								<Form
									initialValues={{
										firstName: contact?.firstName ?? '',
										lastName: contact?.lastName ?? '',
										emailAddress: contact?.emailAddress ?? '',
									}}
									onFinish={this.handleSubmit}
								>
									{validJobData.status === 'referred' && (
										<div>
											<div className={ContainerDiv}>
												<p className={NoteStyle}>
													{ml(
														'To accept your referral and apply to the position, we need more information.',
														currentUser || this.props.referral.user,
														allMultiLingualData,
														'NewReferral'
													)}
												</p>
											</div>
											<div className={DetailContainer}>
												<Row type="flex" justify="space-between" gutter={12}>
													<Col className="form-item-col" span={12}>
														<Form.Item
															className={FormItemStyles}
															name="firstName"
															rules={[
																{
																	transform: (value) =>
																		value == undefined ? value : value.trim(),
																},
																{
																	required: true,
																	message: ml(
																		'Please enter your first name.',
																		currentUser || this.props.referral.user,
																		allMultiLingualData,
																		'NewReferral'
																	),
																},
															]}
														>
															<Input
																className={RemoveBottomMargin}
																placeholder={ml(
																	'First Name',
																	currentUser || this.props.referral.user,
																	allMultiLingualData,
																	'NewReferral'
																)}
															/>
														</Form.Item>
													</Col>
													<Col className="form-item-col" span={12}>
														<Form.Item
															className={FormItemStyles}
															name="lastName"
															rules={[
																{
																	transform: (value) =>
																		value == undefined ? value : value.trim(),
																},
																{
																	required: true,
																	message: ml(
																		'Please enter your last name.',
																		currentUser || this.props.referral.user,
																		allMultiLingualData,
																		'NewReferral'
																	),
																},
															]}
														>
															<Input
																className={RemoveBottomMargin}
																placeholder={ml(
																	'Last Name',
																	currentUser || this.props.referral.user,
																	allMultiLingualData,
																	'NewReferral'
																)}
															/>
														</Form.Item>
													</Col>
													<Col className="form-item-col" span={24}>
														<Form.Item
															className={FormItemStyles}
															name="emailAddress"
															rules={[
																{
																	type: 'email',
																	message: ml(
																		'Enter a valid email.',
																		currentUser || this.props.referral.user,
																		allMultiLingualData,
																		'NewReferral'
																	),
																},
																{
																	required: true,
																	message: ml(
																		'Please enter an email address.',
																		currentUser || this.props.referral.user,
																		allMultiLingualData,
																		'NewReferral'
																	),
																},
															]}
														>
															<Input
																className={RemoveBottomMargin}
																placeholder={ml(
																	'Email',
																	currentUser || this.props.referral.user,
																	allMultiLingualData,
																	'NewReferral'
																)}
															/>
														</Form.Item>
													</Col>
												</Row>
												{isPhoneNumberEnable && (
													<Row>
														<Col className="form-item-col" span={24}>
															<Form.Item
																className={FormItemStyles}
																name="phoneNumber"
																rules={[
																	{
																		validator(rule, value) {
																			return new Promise((resolve, reject) => {
																				if (!value) {
																					return reject(
																						ml(
																							'Please enter a phone number.',
																							currentUser || this.props.referral.user,
																							allMultiLingualData,
																							'NewReferral'
																						)
																					);
																				}

																				if (
																					!/^\+?\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,7}$/im.test(
																						filterPhoneNumber(value)
																					)
																				) {
																					return reject(
																						ml(
																							'Please enter a valid phone number.',
																							currentUser || this.props.referral.user,
																							allMultiLingualData,
																							'NewReferral'
																						)
																					);
																				}

																				resolve();
																			});
																		},
																	},
																]}
															>
																<PhoneInput
																	disableCountryGuess
																	inputStyle={{
																		border: '1px solid',
																		borderRadius: 4,
																		borderColor: 'rgba(217, 217, 217, 0.95)',
																		color: 'rgba(0, 0, 0, 0.65)',
																		font: '400 14px system-ui',
																		padding: '0px 0px 0px 50px',
																		height: 32,
																		width: '100%',
																	}}
																	dropdownStyle={{
																		lineHeight: 1,
																	}}
																	country={countryCode}
																	masks={{ cn: '... .... ....' }}
																	onChange={(country) => {
																		this.handleCountryChange(country);
																	}}
																/>
															</Form.Item>
														</Col>
													</Row>
												)}

												<div
													style={{ marginTop: '10px', fontWeight: 'bolder' }}
												>
													{isResumeOptional ? (
														<div
															className="App"
															style={{ marginBottom: '10px' }}
														>
															<span className={fileWrap}>
																<label htmlFor={'file-' + uniqueId}>
																	{ml(
																		'Click here',
																		currentUser || this.props.referral.user,
																		allMultiLingualData
																	)}{' '}
																</label>
																<input
																	ref={(ref) => {
																		this.uploadInput = ref;
																	}}
																	hidden
																	type="file"
																	accept=".doc,.docx,application/msword,.pdf,application/pdf"
																	id={'file-' + uniqueId}
																	name="file"
																	onClick={(e) => {
																		this.setState({
																			filename: null,
																			filetype: null,
																			originalFile: [],
																		});
																		this.uploadInput = null;
																		e.target.value = null;
																	}}
																	onChange={this.onFileInputChange}
																/>
															</span>{' '}
															{ml(
																'to attach a resume',
																currentUser || this.props.referral.user,
																allMultiLingualData
															)}
															<span style={{ fontWeight: 'normal' }}>
																{' (.doc, .docx, .pdf) '}
															</span>
															<span style={{ color: 'red' }}>
																{isResumeRequired
																	? ml(
																			'(required)',
																			currentUser || this.props.referral.user,
																			allMultiLingualData
																		)
																	: ''}
															</span>
															<span className="label-optional">
																{isResumeRequired
																	? ''
																	: ml(
																			'(optional)',
																			currentUser || this.props.referral.user,
																			allMultiLingualData
																		)}
															</span>{' '}
															{filename && (
																<p>
																	{filename}
																	<DeleteOutlined
																		style={{
																			margin: '0px 0px 4px 10px',
																			color: '#ef3c3e',
																		}}
																		onClick={this.onDelete}
																	/>
																</p>
															)}
															{this.state.errors && this.state.errors.length > 0
																? this.state.errors.map((error) => (
																		<p
																			key={`error-${uuid()}`}
																			className="text-danger text-center"
																		>
																			{error}
																		</p>
																	))
																: null}
														</div>
													) : null}
												</div>

												<div style={{ marginTop: 10 }}>{customItems}</div>
											</div>

											<div style={{ height: 'auto', marginBottom: '30px' }}>
												<p className={InfoStyle}>
													{ml(
														'By submitting, you consent to ERIN storing, processing and sharing your name and contact information with',
														currentUser || this.props.referral.user,
														allMultiLingualData,
														'NewReferral'
													)}{' '}
													{ml(
														'hiring team. Your data will never be sold or redistributed by ERIN. Please read our',
														currentUser || this.props.referral.user,
														allMultiLingualData,
														'NewReferral'
													)}{' '}
													<a
														className={menuColor}
														href="https://erinapp.com/privacy-policy"
														target="_blank"
														rel="noreferrer"
													>
														{ml(
															'Privacy Policy',
															currentUser || this.props.referral.user,
															allMultiLingualData,
															'NewReferral'
														)}
													</a>{' '}
													{ml(
														'for more information.',
														currentUser || this.props.referral.user,
														allMultiLingualData,
														'NewReferral'
													)}
												</p>
											</div>
										</div>
									)}
									<div style={{ textAlign: 'center' }}>
										{validJobData.status === 'referred'
											? this.renderAcceptButton()
											: validJobData.status === 'declined' ||
												validJobData.status === 'notHired'
													? ''
													: this.renderActionButtons()}
									</div>
								</Form>
							)}
						</Card>
					</div>
				</div>
				{!whiteLabel && (
					<div style={{ marginTop: 10 }} className={headerBackground}>
						<p className={sectionTitle}>
							{ml(
								'powered by',
								currentUser || this.props.referral.user,
								allMultiLingualData,
								'NewReferral'
							)}
						</p>
						<a href="https://erinapp.com/">
							<img alt="logo" style={{ width: 200 }} src={ErinLogo} />
						</a>
					</div>
				)}
			</section>
		);
	}
}

export default withApollo(NewReferralCard);
