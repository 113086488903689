import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { downloadFromS3, query } from 'src/_shared/services/utils';
import LoginBackground from '../_shared/assets/auth-bg-full.jpg';
import ErinLogo from '../_shared/assets/erinwhite.png';
import NewUserForm from './new-user-items/NewUserFormComponent.jsx';

class NewExternalUserLandingPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accountClaim: get(props, 'location.state.accountClaim'),
			departments: null,
			company: get(
				props,
				'currentUser.company',
				get(props, 'location.state.accountClaim.company')
			),
			loaded: false,
			externalCompanyId: '',
			externalCompanyName: '',
		};
	}

	async componentDidMount() {
		// If invite is already stored in cache
		const externalUserCompanyId = get(
			this.props,
			'location.state.company.id',
			'skip'
		);
		if (externalUserCompanyId) {
			this.setState({
				externalCompanyId: externalUserCompanyId,
				externalCompanyName: get(
					this.props,
					'location.state.company.name',
					'skip'
				),
			});
			const departments = await query(
				{ companyId: externalUserCompanyId, first: 500 },
				'queryDepartmentsByCompanyIdIndex'
			);
			const otherDepartmentId = this.getDepartmentId(departments, 'Other');
			this.setState({ otherDepartmentId });

			const userGroups = await query(
				{ companyId: externalUserCompanyId, first: 500 },
				'queryUserGroupsByCompanyIdIndex'
			);
			const defaultExternalUserGroupId = this.getUserGroupId(
				userGroups,
				'External'
			);
			this.setState({
				userGroups,
				defaultExternalUserGroupId,
				externalUserCompanyId,
			});
		}

		let backgroundSource;
		let logoSource;
		const { company } = get(this.props, 'location.state', {});
		const logo = get(company, 'logo.key', false);

		const backgroundUrl = get(company, 'background.key');

		if (company) {
			backgroundSource = await downloadFromS3(backgroundUrl);
		}

		if (logo) {
			logoSource = await downloadFromS3(logo);
		}

		this.setState({ backgroundSrc: backgroundSource, logoSrc: logoSource });
	}

	getDepartmentId = (departments, name) => {
		const department = departments.find((dept) => dept.name === name);
		return get(department, 'id');
	};

	getUserGroupId = (userGroups, name) => {
		const userGroup = userGroups.find((group) => group.name === name);
		return get(userGroup, 'id');
	};

	render() {
		const { userInvite } = this.props;
		const { company } = get(this.props, 'location.state', {});
		const {
			accountClaim,
			defaultExternalUserGroupId,
			departments,
			externalUserCompanyId,
			otherDepartmentId,
			externalCompanyName,
			externalCompanyId,
			userGroups,
			backgroundSrc,
			logoSrc,
		} = this.state;

		const whiteLabel = get(company, 'whiteLabel');

		return (
			<div
				className="auth"
				style={{
					backgroundImage: `url(${
						whiteLabel && backgroundSrc ? backgroundSrc : LoginBackground
					})`,
					color: 'red',
				}}
			>
				<div className="auth-left">
					<div className="auth-logo-wrap">
						{whiteLabel ? (
							<div className="auth-logo">
								<img src={logoSrc} />
							</div>
						) : (
							<div className="auth-logo">
								<img src={ErinLogo} alt="Erin Logo" />
							</div>
						)}
					</div>
				</div>

				<div className="auth-right">
					<div className="auth-card">
						<NewUserForm
							whiteLabel={whiteLabel}
							accountClaim={accountClaim}
							defaultExternalUserGroupId={defaultExternalUserGroupId}
							externalUserCompanyId={externalUserCompanyId}
							otherDepartmentId={otherDepartmentId}
							userInvite={userInvite}
							departments={get(
								departments,
								'data.queryDepartmentsByCompanyIdIndex.items'
							)}
							externalCompanyName={externalCompanyName}
							externalCompanyId={externalCompanyId}
							userGroups={userGroups}
							onCreate={this.props.onCreate}
							onUpdateInvite={this.props.onUpdateInvite}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default withApollo(NewExternalUserLandingPageComponent);
