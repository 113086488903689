import { Col, List, Modal, Row } from 'antd';
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ml } from '../_shared/services/utils';

function CareerProfileModal(props) {
	const { onClose, theme, currentUser, allMultiLingualData } = props;

	const [careerProfile, setCareerProfile] = useState(props.careerProfile);
	useEffect(() => {
		setCareerProfile(props.careerProfile);
	}, [props.careerProfile]);
	const languageCode = get(currentUser, 'languageCode', 'US');
	const role = get(currentUser, 'role');

	const history = useHistory();

	const navigateTo = (URL) => {
		history.push(URL);
	};

	const currentOrEndDate = (job) => {
		if (job.end !== undefined && job.end !== null) {
			if (job.end.current) {
				return 'Current';
			}

			if (
				isNaN(job.end.month ? job.end.month : Number.NaN) ||
				isNaN(job.end.year ? job.end.year : Number.NaN)
			) {
				return 'Presently Working';
			}

			return `${job.end.monthName} ${job.end.year}`;
		}
	};

	return (
		<Modal
			centered
			open
			cancelButtonProps={{ style: { display: 'none' } }}
			footer={null}
			width={600}
			title={ml('Career History', currentUser, allMultiLingualData)}
			onCancel={onClose}
		>
			<div className="career-history">
				<h3 className="ch-title">Skills</h3>
				<ul className="tag-wrap">
					{get(careerProfile, 'skills', []).length > 0
						? get(careerProfile, 'skills', []).map((s, index) => {
								const skill = s
									? s
											.split(' ')
											.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
											.join(' ')
									: '';

								return (
									<li
										key={index}
										className="tag green"
										onClick={(e) => e.stopPropagation()}
									>
										<span className="tag-name"> {skill} </span>
									</li>
								);
							})
						: null}
				</ul>
				<div className="divider" />
				<h3 className="ch-title">Work History</h3>
				{get(careerProfile, 'employment', []).length > 0 && (
					<div className="ch-history-wrap">
						<List
							itemLayout="horizontal"
							dataSource={careerProfile.employment}
							renderItem={(job, index) => (
								<div className="ch-history">
									<List.Item key={'job' + index}>
										<Row>
											<Col md={24} xs={24}>
												<p className="ch-text">
													<strong>{job.title}</strong>
												</p>
												<p className="ch-text">{get(job, 'name')}</p>
												<p className="ch-text">
													{job.start !== undefined && job.start !== null
														? `${job.start.monthName} ${job.start.year}`
														: null}{' '}
													{' - '}
													{currentOrEndDate(job)}
												</p>
												<h4 className="ch-subtitle">
													{ml('Description', currentUser, allMultiLingualData)}
												</h4>
												<p className="ch-text">{job.description}</p>
											</Col>
										</Row>
									</List.Item>
								</div>
							)}
						/>
					</div>
				)}
			</div>
		</Modal>
	);
}

export default CareerProfileModal;
