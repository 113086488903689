import { Button, List } from 'antd';
import _, { get, upperFirst } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { GetJobForMatch } from 'src/_shared/api/graphql/custom/jobMatch/';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import {
	downloadFromS3,
	formatDate,
	parse,
	searchOpenSearchNetwork,
} from '../_shared/services/utils';
import { JobMatchCard } from '../dashboard/tiles/job-match-card';
import CareerProfileModal from './CareerProfileModalComponent.jsx';

class DetailsView extends Component {
	constructor(props) {
		super(props);
		const extendedContactsData = get(
			props,
			'selectedUser.extendedContactData',
			[]
		);
		let allJobMatches = extendedContactsData.flatMap((contact) =>
			get(contact, 'jobMatches')
		);
		allJobMatches = allJobMatches.filter((match) => match !== undefined);
		if (get(props, 'selectedUser.role') !== 'extendedUser') {
			let userMatches = get(props, 'selectedUser.jobMatches', []);
			userMatches = userMatches.filter(
				(match) =>
					!get(match, 'job.hideImInterested') &&
					get(props, 'selectedUser.companyId') === get(match, 'job.companyId')
			);
			allJobMatches.push(...userMatches);
			if (allJobMatches.length <= 0) this.getTemporaryJobMatches();
		}

		allJobMatches = _.orderBy(allJobMatches, ['relevance'], ['desc', 'asc']);
		allJobMatches = allJobMatches.map((match) => {
			match.job.location = parse(get(match, 'job.location'));
			return match;
		});
		this.state = {
			allJobMatches,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			company: get(props, 'currentUser.company', {}),
			currencyRate: 1,
			currencySymbol: '$',
			jobs: get(props, 'jobs'),
			careerModal: null,
			selfReferrals: null,
			loading: true,
		};
	}

	async componentDidMount() {
		let errorImageSource;
		let avatarSource;
		const errorImage = get(this.state.company, 'errorImage.key', false);
		const avatar = get(this.props, 'employee.avatar', false);
		if (errorImage) {
			errorImageSource = await downloadFromS3(errorImage);
		}

		if (avatar?.key) {
			avatarSource = await downloadFromS3(avatar.key);
		}

		this.setState({ errorImageSrc: errorImageSource, avatarSrc: avatarSource });
	}

	componentDidUpdate(prevProps) {
		if (prevProps.referral !== this.props.referral) {
			this.setState({ jobs: get(this.props, 'jobs') });
		}
	}

	getTemporaryJobMatches = async (searchCriteria) => {
		const parameters = {
			query: searchCriteria
				? searchCriteria.trim()
				: get(this.props, 'currentUser.title'),
			size: 15,
			role: get(this.props, 'currentUser.role'),
			currentUser: get(this.props, 'currentUser.id'),
			filters: {
				companies: [get(this.props, 'currentUser.companyId')],
				status: 'open',
			},
		};
		const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
		const temporaryMatches = get(response, 'data', []);
		const top15 = temporaryMatches.slice(0, 15);
		const matches = [];
		await Promise.all(
			top15.map(async (item, index) => {
				const { data } = await this.props.client.query({
					query: GetJobForMatch,
					variables: { id: get(item, 'jobId') },
				});
				const job = get(data, 'getJob', {});
				const match = {
					active: true,
					contactId: null,
					dateCreated: new Date().toISOString(),
					id: `${index}`,
					jobId: get(job, 'id'),
					job,
					matchStatus: null,
					relevance: get(item, 'metaScore'),
					userId: get(this.props, 'currentUser.id'),
					temporary: true,
				};

				matches.push(match);
			})
		);
		this.setState({ allJobMatches: matches });
	};

	goBack = () => {
		this.props.history.goBack();
	};

	handleCareerProfile = () => {
		this.props.history.push('/career');
	};

	notInterested = (match) => {
		const input = {
			id: get(match, 'id'),
			companyId: get(match, 'job.companyId'),
			active: false,
		};
		if (!get(match, 'temporary')) this.props.onUpdateJobMatch(input);
		let allJobMatches = get(this.state, 'allJobMatches', []);
		const index = allJobMatches.findIndex((m) => m.id === match.id);
		const updatedMatch =
			index >= 0 ? { ...allJobMatches[index], ...input } : null;

		if (updatedMatch) {
			allJobMatches[index] = updatedMatch;
		}

		const temporaryMatches = [];
		if (get(match, 'temporary')) {
			allJobMatches = allJobMatches.filter(
				(match) => match.id !== 'update' && match.active !== false
			);
			allJobMatches.map((match, index) => {
				temporaryMatches.push(match);
				if ((index + 1) % 3 === 0 || index === allJobMatches.length - 1)
					temporaryMatches.push({
						id: 'update',
						active: true,
					});
			});
		}

		const currentUser = get(this.props, 'currentUser');
		currentUser.jobMatches = allJobMatches;
		if (get(match, 'temporary')) {
			this.setState({ allJobMatches: temporaryMatches });
		} else {
			this.props.setCurrentUser(currentUser);
		}
	};

	sortByAlph = (a, b) => {
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	render() {
		const { currencyRate, currencySymbol, careerModal } = this.state;
		const { employee, allMultiLingualData } = this.props;
		const {
			firstName,
			lastName,
			avatar,
			email,
			title,
			managedDepartments,
			role,
			id,
			department,
			location,
			currency,
			emailAddress,
			dateCreated,
			lastLogin,
			employeeId,
		} = employee;
		const { avatarSrc, company, errorImageSrc, theme } = this.state;
		const firstLogin = dateCreated;
		const loc = location ? JSON.parse(location) : null;
		let city = '';
		let state = '';
		let country = '';
		let completeAddress = '';
		if (loc !== null) {
			if (loc.city !== null) {
				city = loc.city;
				completeAddress = city;
			}

			if (loc.state !== null) {
				state = loc.state;
				completeAddress = city + ', ' + state;
			}

			if (loc.country !== null) {
				country = loc.country;
				completeAddress = city + ', ' + state + ', ' + country;
			}
		}

		const allJobMatches = get(this.state, 'allJobMatches', []).filter(
			(match) => get(match, 'active') === true
		);

		const whileLabel = get(this.state.company, 'whiteLabel', false);

		return (
			<main>
				<div className="page-title">
					<span className="custom-back-btn" onClick={this.goBack}>
						<i className="icon-arrow-left" /> Open to New Roles
					</span>
					<ul className="info-action">
						<li>
							<Button
								type="link"
								className="btn-link"
								onClick={() => this.setState({ careerModal: true })}
							>
								<span>View Career Profile</span>
							</Button>
						</li>
					</ul>
				</div>
				<section className="new-role-card">
					<div className="nr-card-left">
						{avatar === null || avatar === undefined ? (
							<div className="custom-image-wrap">
								{get(firstName, '[0]', '').toUpperCase()}{' '}
								{get(lastName, '[0]', '').toUpperCase()}
							</div>
						) : (
							<div className="custom-avatar-wrap">
								<img
									className="custom-img"
									src={avatarSrc}
									alt={`${get(firstName, '[0]', '').toUpperCase()} ${get(
										lastName,
										'[0]',
										''
									).toUpperCase()}`}
								/>
							</div>
						)}
						<div className="nrc-user-detail">
							<h2 className="nr-name">
								{firstName} {lastName}
							</h2>
							{emailAddress === null ? null : (
								<a className="nrc-user-link" href={`mailto:${emailAddress}`}>
									<i
										className="icon-envelope-outline"
										style={{ fontSize: 18 }}
									/>
									<span>{emailAddress}</span>
								</a>
							)}
							{firstLogin && (
								<p className="nrc-user-text">
									<span>First Login:</span>
									{formatDate(
										firstLogin,
										this.props.currentUser.languageCode,
										this.props.currentUser.dateFormat
									)}
								</p>
							)}
							{lastLogin ? (
								<p className="nrc-user-text">
									<span>Last Web Login:</span>
									{formatDate(
										lastLogin,
										this.props.currentUser.languageCode,
										this.props.currentUser.dateFormat
									)}
								</p>
							) : (
								<p className="nrc-user-text">
									<span>Last Web Login:</span>
									Never
								</p>
							)}
							{employee.lastMobileLogin ? (
								<p className="nrc-user-text">
									<span>Last Mobile Login:</span>
									{formatDate(
										employee.lastMobileLogin,
										this.props.currentUser.languageCode,
										this.props.currentUser.dateFormat
									)}
								</p>
							) : (
								<p className="nrc-user-text">
									<span>Last Mobile Login:</span>
									Never
								</p>
							)}
						</div>
					</div>
					<div className="nr-card-right">
						<h2 className="nrc-right-title">Employee Information</h2>
						<div className="nrc-right-wrap">
							<div className="nrc-right-inner">
								<p className="nrc-user-text">
									<span>Department: </span>
									{get(employee, 'department.name', null)}
								</p>
								<p className="nrc-user-text">
									<span>Sub Company: </span>
									{get(employee, 'subCompany.name', 'Main Company (Default)')}
								</p>
								<p className="nrc-user-text">
									<span>Job Title:</span>
									{title}
								</p>
							</div>
							<div className="nrc-right-inner">
								<p className="nrc-user-text">
									<span>Role: </span>
									{upperFirst(role)}
								</p>
								<p className="nrc-user-text">
									<span>Employee Group: </span>
									{get(employee, 'userGroup.name', '')}
								</p>
								{employeeId && (
									<p className="nrc-user-text">
										<span>Employee ID: </span>
										{employeeId ? employeeId : ''}
									</p>
								)}
								<p className="nrc-user-text">
									<span>Location: </span>
									{completeAddress}
								</p>
							</div>
						</div>
					</div>
				</section>

				<h4 className="new-role-title">Recommended Jobs</h4>
				{allJobMatches && allJobMatches.length > 0 ? (
					<List
						grid={{ gutter: 18, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
						dataSource={allJobMatches}
						className="job-card-wrap"
						renderItem={(jobMatch) => (
							<List.Item style={{ height: '100%' }}>
								<JobMatchCard
									hideImInterested
									handleCareerProfile={this.handleCareerProfile}
									allMultiLingualData={allMultiLingualData}
									currentUser={this.props.currentUser}
									jobMatch={jobMatch}
									matchedJobs={allJobMatches}
									notInterested={this.notInterested}
									allowSelfReferrals={get(
										this.props,
										'currentUser.company.allowSelfReferrals'
									)}
									allowSelfReferralsInternalLink={get(
										this.props,
										'currentUser.company.allowSelfReferralsInternalLink'
									)}
									theme={theme}
									fontWeight={300}
									fontSize={11}
									minHeight={225}
									selectedEmployeeId={id}
									selectedEmailAddress={emailAddress}
									onCreateReferral={this.props.onCreateReferral}
								/>
							</List.Item>
						)}
					/>
				) : whileLabel ? (
					<div className="no-content">
						<img alt="error-logo" width={150} src={errorImageSrc} />
					</div>
				) : (
					<div className="no-content">
						<img alt="erin-logo" width={150} src={fileIcon} />
					</div>
				)}
				{careerModal && (
					<CareerProfileModal
						theme={theme}
						currentUser={this.props.currentUser}
						allMultiLingualData={allMultiLingualData}
						careerProfile={this.props.careerProfile}
						onClose={() => {
							this.setState({ careerModal: null });
						}}
					/>
				)}
			</main>
		);
	}
}

export default withApollo(DetailsView);
