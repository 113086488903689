import { connect } from 'react-redux';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import { withModifyTieredBonusWithUpdate } from 'src/_shared/api/components/tiered-bonuses/';
import { withListUserGroup } from 'src/_shared/api/components/userGroups/';
import { compose } from 'src/_shared/services/utils';
import { dashboardActions, userActions } from 'src/actions.js';
import { ReferralTiersSettings as ReferralTiersSettingsComponent } from './ReferralTiersSettingsComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		companyId: currentUser.companyId,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		setCurrentUserGroup(data) {
			dispatch(userActions.updateCurrentUserGroup(data));
		},
		updateCurrentUserCompany(company) {
			dispatch(userActions.updateUserCompany(company));
		},
	};
};

export const ReferralTiersSettingsWithApi = compose(
	withModifyTieredBonusWithUpdate,
	withListUserGroup,
	withGetCompany
)(ReferralTiersSettingsComponent);

export const ReferralTiersSettings = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(ReferralTiersSettingsWithApi);
