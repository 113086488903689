/** Packages */
import { Button, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { geolocated } from 'react-geolocated';
/** Components & Styles */
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { getLocation, ml } from '../../_shared/services/utils';
import { DropDown } from '../myProfileStyles.js';
import DepartmentItem from './DepartmentItemComponent.jsx';
/** Shared Utils */
import { USStates } from './usStates.js';

function EmployeeJobInfo({
	allMultiLingualData,
	city,
	country,
	currentUser,
	dateFormat,
	defaultDistance,
	department,
	departmentOptions,
	handleChange,
	handleInput,
	isEditing,
	labelDepartment,
	managedDepartments,
	ml_Unit,
	role,
	title,
	state,
	userEditSettings,
}) {
	const [address, setAddress] = useState(
		[city, state, country].filter(Boolean).join(', ')
	);
	const [geolocationAllowed, setGeolocationAllowed] = useState(false);

	const notBold = { fontWeight: 400 };
	// Span tags for the selected options had vertical alignment issues with label tags so this can be applied
	// as a quick fix to label tags to match font-weight of span tags and correct the alignment problem
	const capitalizeFirstLetter = (string) => {
		const capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
		return capitalizedString;
	};

	useEffect(() => {
		async function getGeolocationPermissions() {
			const permissions = await navigator.permissions.query({
				name: 'geolocation',
			});
			const geolocationAllowed = permissions?.state === 'granted';
			setGeolocationAllowed(geolocationAllowed);
		}

		getGeolocationPermissions();
	}, []);

	useEffect(() => {
		setAddress([city, state, country].filter(Boolean).join(', '));
	}, [city, state, country]);

	const handleSelectLocation = (location) => {
		handleChange({ editedLocation: location });
		handleChange({ editedCity: location?.city ?? null });
		handleChange({ editedState: location?.state ?? null });
		handleChange({ editedCountry: location?.country ?? null });
		const address = location?.address;
		setAddress(address);
	};

	const handleChangeLocation = (address) => {
		setAddress(address);
	};

	const addLocation = () => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				const lat = position?.coords?.latitude;
				const lon = position?.coords?.longitude;
				const newLocation = await getLocation({ lat, lon });
				handleChange({ editedLocation: newLocation });
				handleChange({ editedCity: newLocation?.city ?? null });
				handleChange({ editedState: newLocation?.state ?? null });
				handleChange({ editedCountry: newLocation?.country ?? null });
				setAddress(newLocation?.address);
			},
			(error) => console.log(error)
		);
	};

	const renderEditDepartmentSelect = () => {
		const options = [];
		if (departmentOptions !== undefined && departmentOptions.length > 0) {
			for (const departmentOption of departmentOptions) {
				options.push(
					<Select.Option key={departmentOption.id}>
						{departmentOption.name}
					</Select.Option>
				);
			}
		}

		return (
			<Select
				labelInValue
				defaultValue={{
					key: department.key,
					label: department.label,
				}}
				className="custom-input"
				popupClassName={DropDown}
				value={{
					key: department.key,
					label: department.label,
				}}
				placeholder={
					<p style={{ fontSize: 16, margin: 0 }}>
						{ml('Add a Department', currentUser, allMultiLingualData)}
					</p>
				}
				onSelect={(value) => handleChange({ editedDepartment: value })}
			>
				{options}
			</Select>
		);
	};

	const renderEditPosition = () => {
		return (
			<Input
				key="editPosition"
				id="position"
				name="editedPosition"
				placeholder={ml('Position', currentUser, allMultiLingualData)}
				value={title}
				className="custom-input"
				onChange={handleInput('editedPosition')}
			/>
		);
	};

	const getEmployeeRole = (role) => {
		switch (role) {
			case 'employee': {
				return 'Employee';
			}

			case 'admin': {
				return 'Administrator';
			}

			case 'manager': {
				return 'Manager';
			}

			case 'superAdmin': {
				return 'Super Adminstrator';
			}

			default: {
				return null;
			}
		}
	};

	const renderLocation = () => {
		const options = [];
		Object.keys(USStates).map((key) =>
			options.push(<Select.Option key={key}>{USStates[key]}</Select.Option>)
		);
		return (
			<>
				<PlacesAutocomplete
					address={address}
					setAddress={handleChangeLocation.bind(this)}
					onSelect={handleSelectLocation.bind(this)}
				/>
				{geolocationAllowed && (
					<Button
						type="link"
						className="add-btn"
						size="small"
						style={{ marginLeft: 5 }}
						onClick={addLocation}
					>
						<span className="icon-circle">
							<i className="icon-plus" />
						</span>
						{ml('Add Location', currentUser, allMultiLingualData)}
					</Button>
				)}
			</>
		);
	};

	const renderEditDateFormat = () => {
		return (
			<Select
				className="custom-input"
				defaultValue={dateFormat === null ? 'US' : dateFormat}
				value={dateFormat}
				onChange={(value) => handleChange({ editedDateFormat: value })}
			>
				<Select.Option key={1} value="US">
					American
				</Select.Option>
				<Select.Option key={2} value="European">
					European
				</Select.Option>
			</Select>
		);
	};

	const renderDistanceSelect = () => {
		return (
			<Select
				className="custom-input"
				defaultValue={defaultDistance}
				value={defaultDistance}
				placeholder={ml('Location', currentUser, allMultiLingualData)}
				disabled={!isEditing}
				onSelect={(value) => handleChange({ defaultDistance: value })}
			>
				<Select.Option key={0} value="0">
					{ml('All Locations', currentUser, allMultiLingualData)}
				</Select.Option>
				<Select.Option key={1} value="US">
					{ml('My Country', currentUser, allMultiLingualData)}
				</Select.Option>
				<Select.Option key={2} value="-1">
					{ml('Remote', currentUser, allMultiLingualData)}
				</Select.Option>
				<Select.Option key={3} value="25">
					{capitalizeFirstLetter(
						ml('within', currentUser, allMultiLingualData)
					)}{' '}
					25 {capitalizeFirstLetter(ml_Unit)}
				</Select.Option>
				<Select.Option key={4} value="50">
					{capitalizeFirstLetter(
						ml('within', currentUser, allMultiLingualData)
					)}{' '}
					50 {capitalizeFirstLetter(ml_Unit)}
				</Select.Option>
				<Select.Option key={5} value="100">
					{capitalizeFirstLetter(
						ml('within', currentUser, allMultiLingualData)
					)}{' '}
					100 {capitalizeFirstLetter(ml_Unit)}
				</Select.Option>
				<Select.Option key={6} value="200">
					{capitalizeFirstLetter(
						ml('within', currentUser, allMultiLingualData)
					)}{' '}
					200 {capitalizeFirstLetter(ml_Unit)}
				</Select.Option>
			</Select>
		);
	};

	const renderSelectedDistance = () => {
		let selectedDistance = '';
		let isWithin = false;
		const distanceMap = {
			0: 'All Locations',
			US: 'My Country',
			'-1': 'Remote',
			25: '25',
			50: '50',
			100: '100',
			200: '200',
		};
		selectedDistance = distanceMap[defaultDistance];
		{
			selectedDistance == '25' ||
			selectedDistance == '50' ||
			selectedDistance == '100' ||
			selectedDistance == '200'
				? (isWithin = true)
				: (isWithin = false);
		}

		return (
			<>
				{!isWithin && (
					<label style={notBold}>
						{ml(selectedDistance, currentUser, allMultiLingualData)}
					</label>
				)}
				{isWithin && (
					<label style={notBold}>
						{capitalizeFirstLetter(
							ml('within', currentUser, allMultiLingualData)
						)}{' '}
						{selectedDistance} {capitalizeFirstLetter(ml_Unit)}
					</label>
				)}
			</>
		);
	};

	return (
		<>
			<div className="po-details-edit custom-form-group">
				<label>
					{labelDepartment
						? labelDepartment
						: ml('Department', currentUser, allMultiLingualData)}
					:
				</label>
				{isEditing &&
				!userEditSettings?.lockProfileFields.employeeDepartment ? (
					renderEditDepartmentSelect()
				) : (
					<label style={notBold}>{department.label}</label>
				)}
			</div>

			<div className="po-details-edit custom-form-group">
				<label>{ml('Job Title', currentUser, allMultiLingualData)}:</label>
				{isEditing && !userEditSettings?.lockProfileFields.employeeJobTitle ? (
					renderEditPosition()
				) : (
					<label style={notBold}>{title}</label>
				)}
			</div>

			<div className="po-details-edit custom-form-group">
				<label>{ml('Role', currentUser, allMultiLingualData)}:</label>
				<label style={notBold}>{getEmployeeRole(role)}</label>
			</div>

			<div className="po-details-edit custom-form-group">
				<label>{ml('Location', currentUser, allMultiLingualData)}:</label>
				{isEditing && !userEditSettings?.lockProfileFields?.employeeLocation ? (
					renderLocation()
				) : (
					<label style={notBold}>{address}</label>
				)}
			</div>

			<div className="po-details-edit custom-form-group">
				<label>{ml('Date Format', currentUser, allMultiLingualData)}:</label>
				{isEditing ? (
					renderEditDateFormat()
				) : dateFormat === 'US' ? (
					<label style={notBold}>
						{ml('American', currentUser, allMultiLingualData)}
					</label>
				) : (
					<label style={notBold}>{dateFormat}</label>
				)}
			</div>

			<div className="po-details-edit custom-form-group">
				<label>
					{ml('Select Initial Distance', currentUser, allMultiLingualData)}:
				</label>
				{isEditing ? renderDistanceSelect() : renderSelectedDistance()}
			</div>

			{role === 'manager' && managedDepartments ? (
				<div className="po-details-edit custom-form-group">
					<label>
						{ml('Manager Permissions', currentUser, allMultiLingualData)}:
					</label>
					<DepartmentItem departments={managedDepartments} />
				</div>
			) : null}
		</>
	);
}

export const JobInfo = geolocated({
	positionOptions: {
		enableHighAccuracy: false,
	},
	userDecisionTimeout: 5000,
})(EmployeeJobInfo);
