import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Popconfirm, Table, message } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import get from 'lodash/get';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import ContactInviteStatus from '../_shared/components/referral-detail/ContactInviteStatusComponent.jsx';
import { formatDate, formatPhoneNumber, ml } from '../_shared/services/utils';

class ContactsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			contacts: props.contacts,
			deletedContacts: [],
		};
	}

	componentDidUpdate(prevProps) {
		const { contacts } = this.props;

		if (prevProps.contacts !== contacts) {
			const newContacts = _.difference(contacts, this.state.deletedContacts);

			this.setState({
				contacts: newContacts,
			});
		}
	}

	confirmDelete = (contactId) => {
		try {
			const { onDeleteContact } = this.props;
			const { contacts, deletedContacts } = this.state;
			const input = {
				input: {
					id: contactId,
				},
			};
			const deletedIndex = contacts.findIndex(
				(contact) => contact.id === contactId
			);
			const deletedContact = contacts[deletedIndex];
			const newContacts = contacts;
			newContacts.splice(
				newContacts.findIndex((contact) => contact.id === contactId),
				1
			);
			deletedContacts.push(deletedContact);
			this.setState({ contacts: newContacts, deletedContacts });
			onDeleteContact(input);
			message.success('Contact Deleted');
		} catch (error) {
			console.error(error);
		}
	};

	sortByAlph = (a, b) => {
		if (typeof a === 'string') {
			a = a.toLowerCase();
		}

		if (typeof b === 'string') {
			b = b.toLowerCase();
		}

		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	render() {
		const { allMultiLingualData, currentUser, companyData } = this.props;
		const { contacts } = this.state;

		function IconToolTip({ color = '', message = '' }) {
			return (
				<InfoCircleOutlined
					className="ml-2"
					style={{ color }}
					title={message}
				/>
			);
		}

		const columns = [
			{
				key: 'avatar',
				width: 80,
				render: (record) =>
					record.avatar !== null && record.avatar !== undefined ? (
						<div className="custom-image-wrap">
							<Avatar
								style={{ borderRadius: 0 }}
								src={record.avatar}
								className="custom-img"
								shape="square"
							/>
						</div>
					) : (
						<div className="custom-image-wrap">
							{record.firstName === null ? '' : record.firstName[0]}
							{record.lastName === null ? '' : record.lastName[0]}
						</div>
					),
			},
			{
				title: ml('Name', currentUser, allMultiLingualData),
				key: 'name',
				render: (record) => (
					<Link className="table-link" to={`/mycontacts/${record.id}`}>
						{record.firstName} {record.lastName}
					</Link>
				),
				showSorterTooltip: false,
				sorter: (a, b) =>
					this.sortByAlph(
						(a.firstName ? a.firstName : 'zzz') +
							(a.lastName ? a.lastName : 'zzz'),
						(b.firstName ? b.firstName : 'zzz') +
							(b.lastName ? b.lastName : 'zzz')
					),
				defaultSortOrder: 'ascend',
			},
			{
				title: ml('Email / Phone Number', currentUser, allMultiLingualData),
				render: (record) => (
					<>
						{record.emailAddress
							? record.emailAddress
							: formatPhoneNumber(record.phoneNumber)}
					</>
				),
				key: 'email',
				showSorterTooltip: false,
				sorter: (a, b) => this.sortByAlph(a.emailAddress, b.emailAddress),
			},
			{
				title: ml('Invite Status', currentUser, allMultiLingualData),
				width: 150,
				key: 'inviteStatus',
				render: (record) => (
					<ContactInviteStatus
						inviteStatus={get(record, 'inviteStatus')}
						record={record}
					/>
				),
			},
			{
				title: ml('Added', currentUser, allMultiLingualData),
				key: 'dateAdded',
				width: 125,
				showSorterTooltip: false,
				sorter: (a, b) => dayjs(a.dateCreated) - dayjs(b.dateCreated),
				render: (record) =>
					record.dateCreated !== null && record.dateCreated !== undefined
						? formatDate(
								record.dateCreated,
								currentUser.languageCode,
								currentUser.dateFormat
							)
						: null,
			},
			{
				title: ml('Action', currentUser, allMultiLingualData),
				key: 'action',
				responsive: ['md'],
				render: (record) => {
					const title =
						get(record, 'inviteStatus') === 'pending'
							? 'Are you sure? This will cancel the pending invitation and this contact will not be able to join your referral network.'
							: 'Are you sure?';

					const hasActiveReferrals = record?.referrals?.length > 0;
					const acceptedInvite = record?.inviteStatus === 'accepted';

					const notRemoveableMessage =
						record?.referrals?.length > 0
							? 'Contact has active referrals and may not be deleted.'
							: record?.inviteStatus === 'accepted'
								? 'Contact has accepted your invitation and may not be deleted.'
								: '';

					const isRemoveable = !hasActiveReferrals && !acceptedInvite;

					return (
						<>
							{isRemoveable ? (
								<Popconfirm
									title={title}
									placement="left"
									icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
									onConfirm={() => this.confirmDelete(record.id)}
								>
									<Button type="link" className="danger">
										<i className="icon-bin" />
										<span>
											{ml(
												'Remove from Network',
												currentUser,
												allMultiLingualData
											)}
										</span>
									</Button>
								</Popconfirm>
							) : (
								<IconToolTip color="" message={notRemoveableMessage} />
							)}
						</>
					);
				},
			},
		];
		if (get(companyData, 'disableSmartReferrals')) columns.splice(3, 1);

		return (
			<Table
				pagination={{
					pageSize: 50,
					total: contacts && contacts.length,
					simple: true,
					showSizeChanger: false,
				}}
				dataSource={contacts}
				columns={columns}
				rowKey={(record) => record.id}
				scroll={{ x: 656 }}
				size="middle"
			/>
		);
	}
}

export default ContactsTable;
