import { connect } from 'react-redux';
import {
	withCreateEditEmails,
	withUpdateEditEmails,
} from 'src/_shared/api/components/edit-emails/';
import { withGetCompany } from 'src/_shared/api/components/settings/';
import { compose } from '../_shared/services/utils';
import EditEmailsComponent from './EditEmailsComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
		},
		nextToken: null,
		limit: 10_000,
	};
};

export const EditEmailsWithApi = compose(
	withCreateEditEmails,
	withUpdateEditEmails,
	withGetCompany
)(EditEmailsComponent);

export const EditEmails = connect(mapStateToProperties)(EditEmailsWithApi);
