import { object, string, number } from 'yup';

const createEmployeeAddressSchema = object().shape({
    // employeeId: string().required('Employee ID is required'),
    addressLine1: string().required('Address is required').test('len', 'Address line 1 must have more than 8 characters', val => val && val.toString().length >= 8),
    addressLine2: string(), // Not required
    state: object()
        .required('State is required'),
    // city: string()
    //     .required('City is required'),
    country: object()
        .required('Country is required'),
    landmark: string().required('Landmark is required'),
    pinCode: number()
        .required('Pincode is required')
        .test('len', 'Pincode must have exactly 6 digits', val => val && val.toString().length === 6),
});

export default createEmployeeAddressSchema;
