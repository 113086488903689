import React from "react";
import { ErrorMessage, FormikProvider } from "formik";
import { Button, Select, Input, Spin, Form, Typography } from "antd";

const ManageAddressPresentationModal = ({ formik, countryList, stateList, cityList, getState, getCity, manageAddressLoader }) => {
    const {
        values,
        setFieldValue,
        handleSubmit
    } = formik;

    const { Text } = Typography;

    return (
        <FormikProvider value={formik}>
            {manageAddressLoader ? (
                <div className="d-flex align-items-center justify-content-center w-100 h-100 edit-modal-loader">
                    <Spin />
                </div>
            ) : null}


            <div className="custom-form-group">
                <Form.Item>
                    <label className="custom-label sub-custom-label">Address Line 1 :</label>
                    <div className="col-sm-7 pl-0">
                        <Input
                            type="text"
                            value={values.addressLine1}
                            onChange={(event) => setFieldValue("addressLine1", event.target.value)}
                        />
                        <Text type="danger"><ErrorMessage name="addressLine1" component="div" className="error" /></Text>
                    </div>
                </Form.Item>
            </div>

            {/* Address Line 2 */}
            <div className="custom-form-group">
                <Form.Item>
                    <label className="custom-label sub-custom-label">Address Line 2 :</label>
                    <div className="col-sm-7 pl-0">
                        <Input
                            type="text"
                            value={values.addressLine2}
                            onChange={(event) => setFieldValue("addressLine2", event.target.value)}
                        />
                        <Text type="danger"><ErrorMessage name="addressLine2" component="div" className="error" /></Text>
                    </div>
                </Form.Item>
            </div>

            {/* Landmark */}
            <div className="custom-form-group">
                <Form.Item>
                    <label className="custom-label sub-custom-label">Landmark :</label>
                    <div className="col-sm-7 pl-0">
                        <Input
                            type="text"
                            value={values.landmark}
                            onChange={(event) => setFieldValue("landmark", event.target.value)}
                        />
                        <Text type="danger"><ErrorMessage name="landmark" component="div" className="error" /></Text>
                    </div>
                </Form.Item>
            </div>

            {/* Country */}
            <div className="custom-form-group">
                <Form.Item>
                    <label className="custom-label sub-custom-label">Country :</label>
                    <div className="col-sm-7 pl-0">
                        {/* Replace 'Select' with the appropriate component for your country selection */}
                        <Select
                            className="custom-input choose-reward-input"
                            value={values.country?.id}
                            disabled={!countryList?.length}
                            onChange={(event, data) => {
                                getState(event)
                                setFieldValue("country", { id: event, name: data.props.children })
                                setFieldValue("state", undefined)
                                setFieldValue("city", undefined)
                            }}
                            placeholder="Select Country"
                        >
                            {/* Map through your countryList to generate options */}
                            {countryList.map((country) => (
                                <Select.Option key={country.isoCode} value={country.isoCode}>
                                    {country.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <Text type="danger"><ErrorMessage name="country" component="div" className="error" /></Text>
                    </div>
                </Form.Item>
            </div>

            {/* State */}
            <div className="custom-form-group">
                <Form.Item>
                    <label className="custom-label sub-custom-label">State :</label>
                    <div className="col-sm-7 pl-0">
                        {/* Replace 'Select' with the appropriate component for your state selection */}
                        <Select
                            className="custom-input choose-reward-input"
                            disabled={!stateList?.length}
                            value={values.state?.id}
                            onChange={(event, data) => {
                                getCity(values.country?.id, event)
                                setFieldValue("state", { id: event, name: data.props.children })
                                setFieldValue("city", undefined)
                            }}
                            placeholder="Select State"
                        >
                            {/* Map through your stateList to generate options */}
                            {stateList.map((state) => (
                                <Select.Option key={state.isoCode} value={state.isoCode}>
                                    {state.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <Text type="danger"><ErrorMessage name="state" component="div" className="error" /></Text>
                    </div>
                </Form.Item>
            </div>

            {/* City */}
            <div className="custom-form-group">
                <Form.Item>
                    <label className="custom-label sub-custom-label">City :</label>
                    <div className="col-sm-7 pl-0">
                        {/* Replace 'Select' with the appropriate component for your city selection */}
                        <Select
                            className="custom-input choose-reward-input"
                            value={values.city}
                            disabled={!cityList?.length}
                            onChange={(event) => setFieldValue("city", event)}
                            placeholder="Select City"
                        >
                            {/* Map through your cityList to generate options */}
                            {cityList.map((city) => (
                                <Select.Option key={city.name} value={city.name}>
                                    {city.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <Text type="danger"><ErrorMessage name="city" component="div" className="error" /></Text>
                    </div>
                </Form.Item>
            </div>

            {/* Pin Code */}
            <div className="custom-form-group">
                <Form.Item>
                    <label className="custom-label sub-custom-label">Pincode :</label>
                    <div className="col-sm-7 pl-0">
                        <Input
                            type="number"
                            value={values.pinCode}
                            onChange={(event) => setFieldValue("pinCode", parseInt(event.target.value))}
                        />
                        <Text type="danger"><ErrorMessage name="pinCode" component="div" className="error" /></Text>
                    </div>
                </Form.Item>
            </div>

            {/* Submit button */}
            <div className="row submit-button">
                {manageAddressLoader ? (
                    <Spin />
                ) : (
                    <Button onClick={handleSubmit} type="primary">
                        Confirm
                    </Button>
                )}
            </div>
        </FormikProvider>

    );
};

export default ManageAddressPresentationModal;
