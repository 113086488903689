import _ from 'lodash';
import { Component } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { query } from 'src/_shared/services/utils';
import NewInternalReferralCard from './NewInternalReferralCardComponent.jsx';

// INTERNAL REFERRAL
class NewInternalReferralPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: [],
			contacts: [],
			currentJob: null,
			filteredQuestions: [],
			jobId: '',
			referralQuestions: [],
			referredByUser: null,
			referredUser: null,
			resumeAttachData: '',
		};
	}

	async componentDidMount() {
		const { params } = this.props.match;
		const id = params.jobId;
		const referredUserId = params.userId;
		const referredById = params.referredBy;
		let currentJob;
		let referredUser;
		let referredByUser;
		let contacts;

		if (referredUserId) {
			referredUser = await query({ userId: referredUserId }, 'getUserData');
		}

		if (referredUser) {
			const emailAddress = referredUser?.emailAddress?.toLowerCase();
			const data = await query({
				userId: referredUserId,
				emailAddress
			}, 'queryContactsByUserIdEmailAddressIndex');
			if (data) {
				contacts = data?.items ?? [];
			}
		}

		if (referredById) {
			referredByUser = await query({ userId: referredById }, 'getUserData');
		}

		if (id) {
			currentJob = await query({ id }, 'getJob');
		}

		const questions = currentJob?.company?.referralQuestions ?? [];

		let allQuestionsSort = _.sortBy(questions, ['sortOrder']);
		const resumeAttachData = allQuestionsSort.filter(
			(item) => item.isInterested === true && item.sortOrder === 0
		);
		allQuestionsSort = allQuestionsSort.filter((item) => item.sortOrder !== 0);
		const allQuestionsForCandidate = allQuestionsSort.filter(
			(item) => item.isInterested === true
		);
		const referralQuestions = [];
		if (allQuestionsForCandidate.length > 0) {
			allQuestionsForCandidate.map((element) => {
				referralQuestions.push(JSON.parse(element.questions));
			});
		}
		// Filtered Questions contain only form elements, not things like dividers
		const filteredQuestions = referralQuestions.filter(
			element => !element.hasOwnProperty('static')
		);

		this.setState({
			contacts,
			currentJob,
			filteredQuestions,
			jobId: id,
			referralQuestions,
			referredByUser,
			referredUser,
			resumeAttachData,
		});
	}

	render() {
		const {
			currentJob,
			contacts,
			filteredQuestions,
			jobId,
			referredUser,
			referredByUser,
			referralQuestions,
			resumeAttachData
		} = this.state;

		if (!currentJob) {
			return <Spinner forceDefault={true} />;
		}

		const jobData = {
			companyId: currentJob?.company?.id ?? null,
			company: currentJob?.company?.name ?? 'N/A',
			department: currentJob?.department?.name ?? 'N/A',
			location: currentJob?.location?.isRemote === true
				? 'Remote'
				: (currentJob?.location?.city ?? 'N/A') + ', ' +
					(currentJob?.location?.state ?? 'N/A'),
			salary: `$${currentJob?.salary?.from ?? 'N/A'} ${
				currentJob?.salary?.to
					? '- $' + ((currentJob?.salary?.to ?? 'N/A') ||
							(currentJob?.salary?.toNull ?? 'N/A'))
					: ''
			} `,
			jobOverview: currentJob?.description,
			referrer: `${referredByUser.firstName} ${referredByUser.lastName}`,
			referrerEmail: referredByUser.emailAddress,
			position: currentJob?.title,
			jobType: currentJob?.jobType,
			hiringEmail: currentJob?.hiringManager?.emailAddress ?? 'N/A',
			jobUrl: currentJob?.internalJobLink ?? null,
			jobId: jobId,
			userId: referredUser?.id,
			companyLogo: currentJob?.company?.logo,
			brandColor: currentJob?.company?.brandColor,
			jobTitle: currentJob?.title ?? '',
			atsIntegration: currentJob?.company?.atsIntegration ?? 'N/A',
			externalSource: currentJob?.externalSource ?? '',
			whiteLabel: currentJob?.company?.whiteLabel,
			subCompany: currentJob?.subCompany?.name,
			subCompanyLogo: currentJob?.subCompany?.logo,
			status: currentJob?.status ?? null,
			languageCode: this.props?.lang,
			theme: currentJob?.company?.theme,
			hideRecruiterInfo: currentJob?.company?.hideRecruiterInfo ?? false,
			referrals: currentJob?.referrals ?? [],
			isGeneralReferral: currentJob?.isGeneralReferral ?? false,
		};

		return (
			<NewInternalReferralCard
				currentUser={this.props?.currentUser}
				filteredQuestions={filteredQuestions}
				jobData={jobData}
				referredUser={referredUser}
				referralQuestions={referralQuestions}
				resumeAttachData={resumeAttachData}
				setCurrentUser={this.props.setCurrentUser}
				referredByUser={referredByUser}
				contacts={contacts}
				onCreateContact={this.props.ImportedCreateContact}
				onCreateReferral={this.props.onCreateReferral}
				onUpdateContact={this.props.onUpdateContact}
			/>
		);
	}
}

export default NewInternalReferralPageComponent;
