import { connect } from 'react-redux';
import { withJobByIdBrowseJobs } from 'src/_shared/api/components/jobs/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { withCreateUserJobShare } from 'src/_shared/api/components/users/';
import { browseJobsActions, dashboardActions } from 'src/actions.js';
import { compose } from '../../_shared/services/utils';
import JobDetails from './JobDetailComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { currentUser } = state.user;
	const { currentCurrencyRate, currentCurrencySymbol } = state.manageJobs;
	const { allMultiLingualData } = state.dashboard;
	return {
		id: props.match.params.id,
		currentUser,
		jobId: props.match.params.id,
		currentCurrencyRate,
		currentCurrencySymbol,
		companyId: currentUser.companyId,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		deselectCurrentJob() {
			dispatch(browseJobsActions.resetAddJobForm());
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const JobDetailsWithApi = compose(
	withJobByIdBrowseJobs,
	withGetCompanyData,
	withCreateUserJobShare
)(JobDetails);

export const JobDetailsComponent = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(JobDetailsWithApi);
