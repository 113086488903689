import { Alert, Checkbox, DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component, createRef } from 'react';
import ProgressButton from 'react-progress-button';
import { updateJob } from 'src/_shared/api/graphql/custom/jobs/';
import { queryReferralsByJobIdIndex } from 'src/_shared/api/graphql/custom/referrals/';
import { mapReferralStatus } from 'src/_shared/services/utils';
import { JOB_STATUSES } from '../../constants';
import { NotHired } from './referralHiredFormStyles.js';

class ReferralHiredForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: dayjs().toISOString(),
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			disableSubmit: false,
		};
		this.formRef = createRef();
	}

	handleSubmit = async (values) => {
		const {
			referral,
			onUpdateReferral,
			handleError,
			handleCancel,
			updateReferralState,
		} = this.props;
		const { startDate } = this.state;
		this.setState({ buttonState: 'loading', disableSubmit: true });
		if (this.props.handleHired) {
			this.props.handleHired(referral);
		}

		const hireDate = dayjs().toISOString();

		try {
			// After labeling a referral as hired, close the job to remove job from dashboard
			if (values.closeJob) {
				await this.updateJob({
					id: referral.jobId,
					status: JOB_STATUSES.CLOSED,
				});
			}

			// Get all other referrals for that job that was just filled
			if (values.updateOtherReferrals) {
				const otherReferralsOnJob = await this.props.client
					.query({
						query: gql(queryReferralsByJobIdIndex),
						variables: {
							jobId: referral.jobId,
						},
						fetchPolicy: 'network-only',
					})
					// Return only the items of array
					.then((response) => response.data.queryReferralsByJobIdIndex.items);

				// Update status for all referrals on that job that was just filled
				await Promise.all(
					otherReferralsOnJob.map((referral) =>
						onUpdateReferral({
							input: {
								id: referral.id,
								status: 'notHired',
								bonusStatus: 'ineligible',
							},
						})
					)
				);
			}

			const input = {
				id: referral.id,
				status: 'hired',
				bonusStatus: 'pending',
				hireDate,
				startDate,
			};
			onUpdateReferral({ input });
			this.props.updateReferralState ? updateReferralState(input) : null;
			setTimeout(() => {
				this.setState({ buttonState: 'success' });
				setTimeout(() => {
					handleCancel();
				}, 1500);
			}, 4000);
			setTimeout(() => {
				this.setState({
					buttonState: '',
					disableSubmit: false,
				});
			}, 6000);
		} catch (error) {
			console.error(error);
			this.setState({ disableSubmit: false });
			handleError();
		}
	};

	onFinishFailed = ({ errorFields }) => {
		this.formRef.current.scrollToField(errorFields[0].name);
		this.setState({ disableSubmit: false });
		handleError();
	};

	updateJob(job) {
		const { client } = this.props;

		return client.mutate({
			mutation: gql(updateJob),
			variables: {
				input: job,
			},
		});
	}

	render() {
		const { error } = this.props;
		const { startDate, buttonState, theme, disableSubmit } = this.state;
		return (
			<Form
				ref={this.formRef}
				onFinish={this.handleSubmit}
				onFinishFailed={this.onFinishFailed}
			>
				<div className="select-date">
					<h5 className="text-center" style={{ color: '#000' }}>
						Select a Start Date:
					</h5>
					<DatePicker
						format="MM-DD-YYYY"
						value={dayjs(startDate)}
						onChange={(value) =>
							this.setState({ startDate: value.toISOString() })
						}
					/>
				</div>
				<h5>Optional:</h5>

				<Form.Item
					style={{ margin: 0 }}
					name="updateOtherReferrals"
					valuePropName="checked"
				>
					<Checkbox>
						Update other referrals as{' '}
						<span className={NotHired}>
							{mapReferralStatus(
								'notHired',
								get(this.props, 'currentUser.company')
							)}
						</span>
					</Checkbox>
				</Form.Item>
				<Form.Item name="closeJob" valuePropName="checked">
					<Checkbox>
						Close this job{' '}
						<span style={{ fontStyle: 'italic' }}>(no more referrals)</span>
					</Checkbox>
				</Form.Item>

				{error ? (
					<Alert message="There was a problem submitting your changes. Please try again or contact an Administrator" />
				) : null}
				<div className="modal-footer-btn">
					<ProgressButton
						controlled
						durationSuccess={5000}
						state={buttonState}
						type="submit"
						onClick={() => {
							this.setState({ buttonState: 'loading', disableSubmit: true });
						}}
					>
						Submit As{' '}
						{mapReferralStatus('hired', get(this.props, 'currentUser.company'))}
					</ProgressButton>
				</div>
			</Form>
		);
	}
}

export default ReferralHiredForm;
