import { Button } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { formatDate, mapReferralStatus, ml } from '../services/utils';
import MyReferralsTieredBonus from './referral-card/MyReferralsTieredBonusComponent.jsx';

class MyReferralsBonusDetailsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editMode: false,
			error: false,
			visible: false,
		};
	}

	getBonusStatus(status) {
		if (status === 'paid') {
			return 'Paid';
		}

		if (status === 'pending') {
			return 'Pending';
		}

		if (status === 'earned') {
			return 'Earned';
		}

		return 'Ineligible';
	}

	getName = (recipientType) => {
		const { data } = this.props;
		const firstName = get(data, 'contact.firstName');

		const lastName = get(data, 'contact.lastName');

		const URL = `/referrals/${get(data, 'id')}`;

		return { firstName, lastName, URL };
	};

	closeModal = () => {
		this.setState({
			visible: false,
			editMode: false,
			amountDue: null,
			startDate: null,
			earnedDate: null,
			bonusStatus: null,
		});
	};

	handleError = () => {
		this.setState({ error: true });
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			data,
			status,
			currentCurrencyRate,
			currentCurrencySymbol,
		} = this.props;
		const { visible, editMode } = this.state;
		const languageCode = get(this.props.currentUser, 'languageCode');
		const dateFormat = get(this.props.currentUser, 'dateFormat');

		const hireDate = formatDate(get(data, 'hireDate', null), languageCode);
		const earnedDate = formatDate(get(data, 'earnedDate', null), languageCode);
		let recipientType = '';
		const type = get(data, 'recipientType');
		if (type && typeof type === 'string') {
			const value = type.split('');
			value.splice(0, 1, value[0].toUpperCase());
			recipientType = value.join('');
		}

		const referredType = type === 'employee' ? 'candidate' : 'employee';
		const recipient = this.getName(type);
		const referred = this.getName(referredType);
		let bonusStatus = get(this.state, 'bonusStatus', null);
		if (bonusStatus === null) {
			bonusStatus = get(data, 'bonusStatus', '');
		}

		const amountDue = get(this.state, 'amountDue', null);
		const bonuses = get(data, 'bonuses', []).sort(function (a, b) {
			return a.earnedDate < b.earnedDate
				? -1
				: a.earnedDate > b.earnedDate
					? 1
					: 0;
		});

		const symbol = getSymbolFromCurrency(
			get(this.props.currentUser, 'userGroup.currency')
		);
		const rate = currentCurrencyRate ? currentCurrencyRate : 1;
		let bonusAmount = 0;

		let startDate = '';
		let items = '';
		if (window.location.href.includes('/jobs/')) {
			items = bonuses.map((bonus) => {
				if (startDate === '') {
					startDate = formatDate(get(bonus, 'startDate'), languageCode);
				}

				bonusAmount += Number.parseInt(get(bonus, 'amountDue', 0), 10);
				const note = get(bonus, 'notes');
				const item = note !== '' && (
					<li key={get(bonus, 'id')} style={{ display: 'flex' }}>
						<span>{note}</span>
					</li>
				);

				return item;
			});
		} else {
			items = bonuses.map((bonus) => {
				const recipientType = get(bonus, 'recipientType');
				if (recipientType === 'employee') {
					if (startDate === '') {
						startDate = formatDate(get(bonus, 'startDate'), languageCode);
					}

					bonusAmount += Number.parseInt(get(bonus, 'amountDue', 0), 10);
				}

				const note = get(bonus, 'notes');
				const item = note !== '' && (
					<li key={get(bonus, 'id')} style={{ display: 'flex' }}>
						<span>{note}</span>
					</li>
				);

				return item;
			});
		}

		let notes = get(this.state, 'notes', null);
		notes ||= get(data, 'notes', '');
		return (
			<>
				{}
				<ul className="label-data">
					<li>
						<label htmlFor="">
							{ml('Job', currentUser, allMultiLingualData)}:
						</label>
						<a
							href={`/jobs/${get(data, 'job.id')}`}
							onClick={(event) => {
								event.preventDefault();
								this.props.history.push(`/jobs/${get(data, 'job.id')}`);
							}}
						>
							{this.props.translatedTitle}
						</a>
					</li>
					<li>
						<label htmlFor="">
							{ml(
								`${mapReferralStatus(
									'referred',
									currentUser.company
								)} Candidate`,
								currentUser,
								allMultiLingualData
							)}
							:
						</label>
						<a
							href={get(referred, 'URL')}
							onClick={async (event) => {
								this.props.history.push(get(referred, 'URL'));
								event.preventDefault();
							}}
						>
							{get(referred, 'firstName')} {get(referred, 'lastName')}
						</a>
					</li>
					<li>
						<label htmlFor="">{`${ml(
							`${mapReferralStatus('hired', currentUser.company)} Date`,
							currentUser,
							allMultiLingualData
						)}:`}</label>
						{hireDate}
					</li>
					<li>
						<label htmlFor="">
							{ml('Start Date', currentUser, allMultiLingualData)}:
						</label>
						{startDate}
					</li>
					<li>
						<label className="label-block">
							{ml('Bonus Payments', currentUser, allMultiLingualData)}:
						</label>
						{get(data, 'referralType') !== 'self' && (
							<MyReferralsTieredBonus
								currentTieredBonus={this.props.currentTieredBonus}
								bonusesData={bonuses}
								referral={data}
								status={status}
								userGroup={data?.user?.userGroupId}
								currentCurrencyRate={currentCurrencyRate}
								currentCurrencySymbol={currentCurrencySymbol}
								currentUser={this.props.currentUser}
							/>
						)}
					</li>
					<li>
						<label>
							{ml('Bonus Notes', currentUser, allMultiLingualData)}:
						</label>
						{items}
					</li>
				</ul>

				<div className="modal-footer-btn">
					<Button
						className="btn-min-width"
						type="primary"
						size="large"
						onClick={this.props.handleCancel}
					>
						{ml('Close', currentUser, allMultiLingualData)}
					</Button>
				</div>
			</>
		);
	}
}

export default MyReferralsBonusDetailsModal;
