import { connect } from 'react-redux';
import { withQueryBonusByUserId } from 'src/_shared/api/components/bonuses/';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withUserById } from 'src/_shared/api/components/employee-details/';
import { withQueryReferralsByUserId } from 'src/_shared/api/components/referrals/';
import { withQuerySubCompanyByCompanyIdIndex } from 'src/_shared/api/components/sub-company/';
import { withListUserGroup } from 'src/_shared/api/components/userGroups/';
import { withListReferralRequestedNotificationsByUserIdStatus } from 'src/_shared/api/components/users/';
import { manageJobsActions, userActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils';
import EmployeeDetailsComponent from './EmployeeDetailsComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { currentUser } = state.user;
	const { id } = props.match.params;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		id,
		filter: { companyId: { eq: currentUser.companyId } },
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
	};
};

const EmployeeDetailsWithAPI = compose(
	withUserById,
	withListDepartment,
	withListUserGroup,
	withQueryBonusByUserId,
	withQuerySubCompanyByCompanyIdIndex,
	withListReferralRequestedNotificationsByUserIdStatus,
	withQueryReferralsByUserId
)(EmployeeDetailsComponent);

export const EmployeeDetails = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(EmployeeDetailsWithAPI);
