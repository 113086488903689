import gql from 'graphql-tag';
import get from 'lodash/get';
import { compose, graphql } from 'react-apollo';
import { parse } from 'src/_shared/services/utils';
import uuid from 'uuid/v4';
import {
	createDepartment,
	queryDepartmentsByCompanyIdIndex,
	updateDepartment,
} from '../../graphql/custom/departments';

export const withListDepartment = (Component) => {
	return compose(
		graphql(gql(queryDepartmentsByCompanyIdIndex), {
			options: (props) => ({
				variables: {
					companyId: props.currentUser.companyId,
					first: 1000,
					after: null,
				},
			}),
			props(response, previous) {
				const departments = get(
					response,
					['data', 'queryDepartmentsByCompanyIdIndex', 'items'],
					get(previous, 'queryDepartmentsByCompanyIdIndex', [])
				);
				let activeDepartments = departments.filter((department) => {
					return department.active;
				});

				if (activeDepartments.length > 0) {
					activeDepartments = departments.map((department) => {
						if (get(department, 'keywords'))
							department.keywords = parse(department.keywords);
						return department;
					});
				}

				return { departments: activeDepartments };
			},
		}),
		graphql(gql(createDepartment), {
			props: (props) => ({
				onCreateDepartment(input) {
					const optimisticResponseData = {
						...input.input,
						id: uuid(),
						totalUsers: 0,
						__typename: 'Department',
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							createDepartment: {
								__typename: 'createDepartment',
								...optimisticResponseData,
							},
						},
						update(proxy, { data: { createDepartment } }) {
							const data = proxy.readQuery({
								query: gql(queryDepartmentsByCompanyIdIndex),
								variables: {
									companyId: props.ownProps.company.id,
									first: 1000,
									after: null,
								},
							});
							if (
								!data.queryDepartmentsByCompanyIdIndex.items.find(
									(dept) =>
										dept.id === createDepartment.id && dept.active == true
								)
							) {
								data.queryDepartmentsByCompanyIdIndex.items.push(
									createDepartment
								);
							}

							proxy.writeQuery({
								query: gql(queryDepartmentsByCompanyIdIndex),
								data,
								variables: {
									companyId: props.ownProps.company.id,
									first: 1000,
									after: null,
								},
							});
						},
					});
				},
			}),
		}),
		graphql(gql(updateDepartment), {
			props: (props) => ({
				onUpdateDepartment(input) {
					const optimisticResponseData = {
						...input.input,
					};
					props.mutate({
						variables: input,
						optimisticResponse: {
							__typeName: 'Mutation',
							updateDepartment: {
								...optimisticResponseData,
								__typename: 'updateDepartment',
							},
						},
						update(proxy, { data: { updateDepartment } }) {
							const data = proxy.readQuery({
								query: gql(queryDepartmentsByCompanyIdIndex),
								variables: {
									companyId: props.ownProps.currentUser.companyId,
									first: 1000,
									after: null,
								},
							});

							data.queryDepartmentsByCompanyIdIndex.items =
								data.queryDepartmentsByCompanyIdIndex.items.filter(
									(department) =>
										department.id !== updateDepartment.id &&
										department.active == true
								);
							proxy.writeQuery({
								query: gql(queryDepartmentsByCompanyIdIndex),
								data,
								variables: {
									companyId: props.ownProps.currentUser.companyId,
									first: 1000,
									after: null,
								},
							});
						},
					});
				},
			}),
		})
	)(Component);
};
