import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input, Select, message } from 'antd';
import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import _ from 'lodash';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { geolocated } from 'react-geolocated';
import PlacesAutocomplete from 'src/_shared/components/location/PlacesAutocomplete.jsx';
import { withRouter } from 'react-router-dom';
import { GetUserByCognitoId } from 'src/_shared/api/graphql/custom/users/';
import { CreateContact } from 'src/_shared/services/hubspot';
import {
	getLocation,
	lowerCase,
	parse,
	query,
} from 'src/_shared/services/utils.js';
import uuid from 'uuid/v4';
import validator from 'validator';
import { COMPANIES } from '../../_shared/constants';
import {
	requiredFields,
	requiredFieldsForExternalUser,
	requiredFieldsForReferVetsExternalUser,
} from '../errorHandlers';
import { USStates } from './copy.js';

class NewUserForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			geolocationAllowed: false,
			serverError: false,
			lengthError: false,
			department: '',
			editEmail: !(
				get(props, 'accountClaim') && !get(props, 'accountClaim.emailAddress')
			),
			requiredErrors: {},
			isSubCompanyIdOnClaim: false,
			currencyAbbrev: props?.userInvite?.userGroup?.currency ?? 'USD',
			loading: false,
			userGroupId: '',
			userGroup: '',
			userSignupSettings: parse(
				get(props, 'accountClaim.company.userSignupSettings')
			),
			claimLabelDepartment: '',
		};
	}

	async componentDidMount() {
		const permissions = await navigator.permissions.query({
			name: 'geolocation',
		});
		const geolocationAllowed = permissions?.state === 'granted';
		if (geolocationAllowed) this.getCurrentLocation();
		this.setState({ geolocationAllowed });
		let lblDepartment;
		let accountClaim = get(this.props, 'accountClaim');
		accountClaim ||= await query(
			{ id: get(this.props, 'accountClaim.id') },
			'getAccountClaim'
		);
		if (accountClaim) {
			lblDepartment = get(accountClaim, 'company.labelDepartment');
			this.setState({
				claimLabelDepartment: lblDepartment,
			});
		}

		if (get(accountClaim, 'subCompanyId'))
			this.setState({ isSubCompanyIdOnClaim: true });

		if (accountClaim && get(accountClaim, 'claimed')) {
			this.props.history.push({
				pathname: '/login',
				state: {
					accountClaim,
					accountClaimError:
						'This account has already been claimed. Please login using your credentials.',
				},
			});
		}

		const departments = get(this.props, 'departments', []);
		let departmentByName = '';
		const deptTitle = get(this.props, 'deptTitle');
		if (deptTitle !== '' && deptTitle !== undefined && deptTitle !== null) {
			departmentByName = departments.find(
				(department) => get(department, 'name') === deptTitle
			);
		}

		if (departmentByName) {
			departmentByName = get(departmentByName, 'id');
			this.setState({ department: departmentByName });
		} else {
			let otherDepartment = departments.find(
				(department) => get(department, 'name') === 'Other'
			);
			otherDepartment = get(otherDepartment, 'id');
			this.setState({ department: otherDepartment });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.departments !== this.props.departments) {
			const departments = get(this.props, 'departments', []);
			let departmentByName = '';
			const deptTitle = get(this.props, 'deptTitle');
			if (deptTitle !== '' && deptTitle !== undefined && deptTitle !== null) {
				departmentByName = departments.find(
					(department) => get(department, 'name') === deptTitle
				);
			}

			if (departmentByName) {
				departmentByName = get(departmentByName, 'id');
				this.setState({ department: departmentByName });
			} else {
				let otherDepartment = departments.find(
					(department) => get(department, 'name') === 'Other'
				);
				otherDepartment = get(otherDepartment, 'id');
				this.setState({ department: otherDepartment });
			}
		}
	}

	onAuthentication = (authToken, currentUser) => {
		try {
			Cookies.set('jwt', authToken);
			this.props.setCurrentUser(currentUser);
			window.location.href = '/dashboard';
		} catch (error) {
			console.log(error);
		}
	};

	onChange = (event_) => {
		const { value } = event_.target;
		this.setState({ currencyAbbrev: value });
	};

	getCurrentLocation = () => {
		try {
			if (navigator.geolocation) {
				// Timeout at 60000 milliseconds (60 seconds)
				const options = { timeout: 60_000 };
				navigator.geolocation.getCurrentPosition(
					this.showLocation,
					this.errorHandler,
					options
				);
			} else {
				console.log('Sorry, browser does not support geolocation!');
			}
		} catch (error) {
			console.log(error);
		}
	};

	addLocation = async () => {
		if (this.props.isGeolocationAvailable) {
			const lat = this.props?.coords?.latitude;
			const lon = this.props?.coords?.longitude;
			const location = await getLocation({ lat, lon }, 'apiKey');
			this.setState({ address: location?.address, location });
		} else {
			console.log('Location is not available');
		}
	};

	errorHandler = (error) => {
		console.log(error);
		if (error.code == 1) {
			console.log('Error: Access is denied!');
		} else if (error.code == 2) {
			console.log('Error: Position is unavailable!');
		}
	};

	externalUserSignup = async (
		values,
		emailAddress,
		defaultExternalUserGroupId,
		userGroupId,
		userGroup,
		externalUserCompanyId,
		otherDepartmentId
	) => {
		const { onCreate, externalCompanyId, externalCompanyName } = this.props;
		if (!defaultExternalUserGroupId) {
			this.setState({ externalUserGroupError: true, loading: false });
			return;
		}

		Auth.signUp({
			username: uuid(),
			password: values.password,
			attributes: {
				email: emailAddress,
			},
		})
			.then(async (data) => {
				const input = {
					cognitoId: get(data, 'user.username'),
					companyId: externalUserCompanyId,
					emailAddress,
					role: 'employee',
					firstName: get(values, 'firstName'),
					lastName: get(values, 'lastName'),
					title: get(values, 'title'),
					departmentId: otherDepartmentId,
					avatar: null,
					lastLogin: null,
					active: true,
					createdById: externalUserCompanyId,
					userGroupId:
						externalCompanyId === COMPANIES.ReferVets
							? userGroupId
							: defaultExternalUserGroupId,
					currency: get(this.state, 'currencyAbbrev', 'USD'),
					languageCode: null,
				};
				if (this.state?.location)
					input.location = JSON.stringify(this.state.location);
				onCreate({
					input,
				}).then(async (response) => {
					const userId = get(response, 'data.createUser.id');
					mixpanel.track('Account Created');
					// If company is ReferVets
					if (externalCompanyId === COMPANIES.ReferVets) {
						const hubspotData = {
							company: externalCompanyName,
							companyId: externalCompanyId,
							email: emailAddress,
							firstname: get(values, 'firstName'),
							lastname: get(values, 'lastName'),
							phone: '',
							website: '',
							contact_type: get(userGroup, 'label', ''),
						};
						const hubspotres = await CreateContact(hubspotData);
						if (hubspotres.status == 'error') {
							message.error(hubspotres.message);
							this.setState({ loading: false });
							return false;
						}
					}
				});
			})
			.then(() =>
				this.props.history.push({
					pathname: '/login',
					state: {
						externalUserSuccess: 'Your account has been created successfully!',
					},
				})
			)
			.catch((error) => {
				this.setState({ serverError: true });
				this.setState({ loading: false });
				console.error(error);
			});
	};

	handleGroup = (value) => {
		const { key, label } = value;
		this.setState({
			userGroupId: key,
			userGroup: value,
		});
	};

	handleSelectDepartment = (value) => {
		this.setState({ department: value });
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		const {
			onCreate,
			userInvite,
			onUpdateAccountClaim,
			onUpdateInvite,
			defaultUserGroupId,
			noBonusUserGroupId,
			userGroupIdByName,
			defaultExternalUserGroupId,
			externalUserCompanyId,
			otherDepartmentId,
			externalCompanyId,
		} = this.props;
		let lblDepartment;
		const { userGroupId, userGroup, userSignupSettings } = this.state;
		this.setState({ serverError: false, pageIsValid: true });
		this.props.form.validateFields(async (error, values) => {
			if (error) {
				this.setState({ serverError: true });
				this.setState({ loading: false });
			} else if (values.password) {
				if (
					!validator.isStrongPassword(values.password, {
						minLength: 10,
						minLowercase: 1,
						minUppercase: 1,
						minNumbers: 1,
						minSymbols: 1,
					})
				) {
					this.setState({ lengthError: true });
					this.setState({ loading: false });
					return;
				}

				if (this.pageIsValid(values)) {
					this.setState({ loading: true });
					const emailAddress = get(values, 'emailAddress', '')
						.split(' ')
						.join('')
						.toLowerCase();
					const emailExists = await query(
						{ emailAddress },
						'getUserByEmailAddress'
					);
					if (emailExists) {
						console.log('A user with this email address already exists.');
						this.setState({ emailError: true });
						this.setState({ loading: false });
					} else {
						let accountClaim = get(this.props, 'accountClaim');
						let accountClaimId;
						let employeeId;
						let companyId;
						if (!accountClaim && !get(this.props, 'userInvite'))
							accountClaim = await query(
								{ id: get(this.props, 'accountClaim.id') },
								'getAccountClaim'
							);
						if (accountClaim) {
							lblDepartment = get(accountClaim, 'company.labelDepartment');
							this.setState({
								claimLabelDepartment: lblDepartment,
							});
						}

						if (accountClaim) {
							accountClaimId = get(accountClaim, 'id');
							employeeId = get(accountClaim, 'employeeId');
							companyId = get(accountClaim, 'companyId');
						}

						if (
							window.location.href.includes('external-user') &&
							externalCompanyId === COMPANIES.ReferVets
						) {
							// We need to create an external user with department as "Other" and group as "External"
							values = Object.assign(values, { title: 'None' });
							this.externalUserSignup(
								values,
								emailAddress,
								defaultExternalUserGroupId,
								userGroupId,
								userGroup,
								externalUserCompanyId,
								otherDepartmentId
							);
						} else if (window.location.href.includes('external-user')) {
							// We need to create an external user with department as "Other" and group as "External"
							this.externalUserSignup(
								values,
								emailAddress,
								defaultExternalUserGroupId,
								userGroupId,
								userGroup,
								externalUserCompanyId,
								otherDepartmentId
							);
						} else if (
							(get(accountClaim, 'active') && !get(accountClaim, 'claimed')) ||
							!accountClaim
						) {
							Auth.signUp({
								username: uuid(),
								password: values.password.trim(),
								attributes: {
									email: emailAddress,
								},
							})
								.then(() => {
									Auth.signIn(
										emailAddress.toLowerCase().trim(),
										values.password.trim()
									).then(async (data) => {
										const token = get(
											data,
											'signInUserSession.accessToken.jwtToken'
										);
										let userGroupId = get(
											userInvite,
											'userGroupId',
											defaultUserGroupId
										);
										if (userGroupId === 'null' || userGroupId === null) {
											userGroupId = defaultUserGroupId;
										}

										if (!get(accountClaim, 'eligible') && noBonusUserGroupId) {
											userGroupId = noBonusUserGroupId;
										}

										// If Company is not Allied
										if (
											userGroupIdByName &&
											companyId !== '4cc1443d-4f67-463d-8ff5-3f6dc814f5e9'
										) {
											userGroupId = userGroupIdByName;
										}

										const departmentId = get(
											userSignupSettings,
											'department.hidden'
										)
											? get(this.state, 'department')
											: get(values, 'department.key');
										const title = get(userSignupSettings, 'title.hidden')
											? get(
													accountClaim,
													'title',
													get(userSignupSettings, 'title.default')
												)
											: get(values, 'title');
										const input = {
											cognitoId: get(data, 'username'),
											companyId: get(
												userInvite,
												'companyId',
												get(accountClaim, 'companyId')
											),
											emailAddress,
											role: get(userInvite, 'role', 'employee'),
											firstName: get(values, 'firstName'),
											lastName: get(values, 'lastName'),
											title,
											departmentId,
											avatar: null,
											lastLogin: null,
											active: get(accountClaim, 'active', true),
											createdById: get(
												userInvite,
												'createdById',
												get(accountClaim, 'companyId')
											),
											userGroupId,
											currency: get(this.state, 'currencyAbbrev', 'USD'),
										};
										if (this.state?.location)
											input.location = JSON.stringify(this.state.location);
										// LanguageCode depends on if submittion is an invite, or a claim
										const userGroupOnClaim = (
											get(accountClaim, 'company.userGroups') || []
										).find(
											(userGroup) => userGroup.name === accountClaim.group
										);

										const userGroupOnInvite = get(userInvite, 'userGroup');

										input.languageCode =
											get(userGroupOnClaim, 'languageCode') ||
											get(userGroupOnInvite, 'languageCode') ||
											'US';

										if (get(accountClaim, 'subCompanyId')) {
											input.subCompanyId = get(accountClaim, 'subCompanyId');
										} else if (get(values, 'subCompany.key')) {
											input.subCompanyId = get(values, 'subCompany.key');
										}

										if (accountClaimId) input.accountClaimId = accountClaimId;
										if (employeeId) input.employeeId = employeeId;

										onCreate({
											input,
										}).then(async (response) => {
											const user = get(response, 'data.createUser');
											const userId = get(user, 'id');
											if (accountClaimId) {
												onUpdateAccountClaim({
													input: {
														id: accountClaimId,
														claimed: true,
														userId,
													},
												});
											}

											if (userInvite) {
												onUpdateInvite({
													id: userInvite.id,
													userId,
												});
											}

											const { data } = await this.props.client.query({
												query: GetUserByCognitoId,
												variables: { cognitoId: user.cognitoId },
												fetchPolicy: 'network-only',
											});
											const currentUser = get(data, 'getUserByCognitoId');
											mixpanel.identify(currentUser.id);
											mixpanel.register({
												'Company Name': currentUser?.company?.name,
												'Company ID': currentUser?.company?.id,
											});
											mixpanel.track('Account Created');
											mixpanel.track('Logged In');
											localStorage.setItem('mixpanel_user_identified', 'true');
											this.onAuthentication(token, currentUser);
										});
									});
								})
								.catch((error_) => {
									this.setState({ serverError: true });
									this.setState({ loading: false });
									console.error(error_);
								});
						} else if (get(accountClaim, 'claimed')) {
							console.log('This account has already been claimed.');
							this.setState({ claimError: true });
							this.setState({ loading: false });
						}
					}
				}
			}
		});
	};

	pageIsValid = (values) => {
		const { externalCompanyId } = this.props;
		const { userSignupSettings } = this.state;
		const hideSubcompanyOnSignup = get(
			this.props,
			'userInvite.company.hideSubcompanyOnSignup'
		);
		let isValid = true;
		const requiredErrors = {};
		let subCompanies = get(this.props, 'subCompanies');
		subCompanies = subCompanies ? subCompanies : [];
		if (
			window.location.href.includes('external-user') &&
			externalCompanyId === COMPANIES.ReferVets
		) {
			for (const { key, message } of requiredFieldsForReferVetsExternalUser) {
				if (!values[key]) {
					requiredErrors[key] = message;
					isValid = false;
				}
			}
		} else if (window.location.href.includes('external-user')) {
			for (const { key, message } of requiredFieldsForExternalUser) {
				if (!values[key]) {
					requiredErrors[key] = message;
					isValid = false;
				}
			}
		} else {
			for (const { key, message } of requiredFields) {
				if (
					key === 'department' &&
					get(values[key], 'key') === 'Select a department' &&
					!get(userSignupSettings, 'department.hidden')
				) {
					requiredErrors[key] = message;
					isValid = false;
				}

				if (!values[key]) {
					if (
						key === 'subCompany' &&
						subCompanies.length > 0 &&
						!this.state.isSubCompanyIdOnClaim &&
						!hideSubcompanyOnSignup
					) {
						requiredErrors[key] = message;
						isValid = false;
					} else if (
						key !== 'subCompany' &&
						!get(userSignupSettings, `[${key}].hidden`)
					) {
						requiredErrors[key] = message;
						isValid = false;
					}
				}
			}
		}

		this.setState({
			requiredErrors,
		});
		return isValid;
	};

	showLocation = async (position) => {
		const lat = position?.coords?.latitude;
		const lon = position?.coords?.longitude;
		const location = await getLocation({ lat, lon }, 'apiKey');
		this.setState({ address: location?.address, location });
	};

	handleSelectLocation(location) {
		this.setState({ address: location?.address, location });
	}

	handleChangeLocation(address) {
		this.setState({ address });
	}

	renderLocation = () => {
		const { externalCompanyId } = this.props;
		const { Option } = Select;
		const options = [];
		Object.keys(USStates).map((key) =>
			options.push(<Option key={key}>{USStates[key]}</Option>)
		);
		return (
			<>
				{window.location.href.includes('external-user') &&
				externalCompanyId === COMPANIES.ReferVets ? null : (
					<div className="custom-form-group">
						<label className="custom-label">Location</label>
						<div className="d-flex">
							<PlacesAutocomplete
								address={this.state.address}
								auth="apiKey"
								setAddress={this.handleChangeLocation.bind(this)}
								onSelect={this.handleSelectLocation.bind(this)}
							/>
							{this.state.geolocationAllowed && (
								<Button
									type="primary"
									size="large"
									style={{ marginLeft: 8 }}
									onClick={this.addLocation}
								>
									<i className="icon-plus" />
									<span>Autofill Location</span>
								</Button>
							)}
						</div>
					</div>
				)}
			</>
		);
	};

	render() {
		const {
			editEmail,
			emailError,
			serverError,
			activeError,
			claimError,
			lengthError,
			requiredErrors,
			externalUserGroupError,
			claimLabelDepartment,
			userSignupSettings,
		} = this.state;
		const theme = get(this.props, 'userInvite.company.theme')
			? JSON.parse(get(this.props, 'userInvite.company.theme'))
			: null;
		const lblDepartment = get(this.props, 'userInvite.company.labelDepartment')
			? get(this.props, 'userInvite.company.labelDepartment')
			: claimLabelDepartment
				? claimLabelDepartment
				: 'Department';
		const hideSubcompanyOnSignup = get(
			this.props,
			'userInvite.company.hideSubcompanyOnSignup'
		);
		const { whiteLabel, userGroups, externalCompanyId } = this.props;
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const { Option } = Select;
		const firstName = get(
			this.props,
			'userInvite.firstName',
			get(this.props, 'accountClaim.firstName', '')
		);
		const lastName = get(
			this.props,
			'userInvite.lastName',
			get(this.props, 'accountClaim.lastName', '')
		);
		const emailAddress = get(
			this.props,
			'userInvite.emailAddress',
			get(this.props, 'accountClaim.emailAddress', '')
		);
		let title = get(
			this.props,
			'userInvite.title',
			get(this.props, 'accountClaim.title', '')
		);
		if (!title && get(userSignupSettings, 'title.hidden'))
			title = get(userSignupSettings, 'title.default')
				? get(userSignupSettings, 'title.default')
				: 'None';
		let departmentId = '';
		let departmentName = '';
		departmentId = get(
			this.props,
			'userInvite.departmentId',
			get(
				this.props,
				'accountClaim.departmentId',
				get(this.state, 'department')
			)
		);
		const display = '';
		const margBottom = this.props.accountClaim ? 3 : 10;

		const sortedDepartments = _.sortBy(this.props.departments, ['name']);
		const options = sortedDepartments
			.filter((department) => department.active === true)
			.map((department) => {
				if (departmentId === department.id) departmentName = department.name;
				return {
					label: department.name,
					value: department.id
				};
			});

		let sortedUserGroups = _.sortBy(userGroups, ['name']);
		sortedUserGroups = sortedUserGroups.filter(
			(element) => element.name !== 'Default' && element.name !== 'External'
		);
		const optionsUserGroups = sortedUserGroups
			.filter((group) => group.active === true)
			.map((group) => {
				return (
					<Option key={group.id} value={group.id}>
						{group.name}
					</Option>
				);
			});

		let optionsSubCompanies = [];
		const sortedSubCompanies = _.sortBy(this.props.subCompanies, ['name']);
		if (sortedSubCompanies !== null) {
			optionsSubCompanies = sortedSubCompanies.map((subCompany) => {
				return (
					<Option key={subCompany.id} value={subCompany.id}>
						{subCompany.name}
					</Option>
				);
			});
		}

		return (
			<>
				<h2 className="auth-title">Let&#39;s Get Started</h2>
				<h5 className="auth-sub-title">First complete your profile</h5>

				<Form onSubmit={this.handleSubmit}>
					<div className="custom-form-group">
						<label className="custom-label">First Name</label>
						{getFieldDecorator('firstName', {
							initialValue: firstName ? firstName : '',
						})(<Input className="custom-input" />)}
						{requiredErrors && requiredErrors.firstName ? (
							<div className="input-error">{requiredErrors.firstName}</div>
						) : null}
					</div>
					<div className="custom-form-group">
						<label className="custom-label">Last Name</label>
						{getFieldDecorator('lastName', {
							initialValue: lastName ? lastName : '',
						})(<Input className="custom-input" />)}
						{requiredErrors && requiredErrors.lastName ? (
							<div className="input-error">{requiredErrors.lastName}</div>
						) : null}
					</div>

					{window.location.href.includes('external-user') &&
					externalCompanyId === COMPANIES.ReferVets ? (
						<div className="custom-form-group">
							<label className="custom-label">Are you a:</label>
							{getFieldDecorator(
								'userGroup',
								{}
							)(
								<Select
									labelInValue
									showArrow
									placeholder="User Type"
									className="custom-input"
									onSelect={(value) => this.handleGroup(value)}
								>
									{optionsUserGroups}
								</Select>
							)}
							{requiredErrors && requiredErrors.userGroup ? (
								<div className="input-error">{requiredErrors.userGroup}</div>
							) : null}
						</div>
					) : null}

					{(window.location.href.includes('external-user') &&
						externalCompanyId === COMPANIES.ReferVets) ||
					get(userSignupSettings, 'title.hidden') ? null : (
						<div className="custom-form-group">
							<label className="custom-label">Job Title</label>
							{getFieldDecorator('title', {
								initialValue: title ? title : '',
							})(<Input className="custom-input" />)}
							{requiredErrors && requiredErrors.title ? (
								<div className="input-error">{requiredErrors.title}</div>
							) : null}
						</div>
					)}

					{window.location.href.includes('external-user') ||
					get(userSignupSettings, 'department.hidden') ? null : (
						<div className="custom-form-group">
							<label className="custom-label">{lblDepartment}</label>
							{getFieldDecorator('department', {
								initialValue: departmentId
									? {
											key: departmentId,
											label: departmentName,
										}
									: {
											key: 'Select a department',
											value: 'Select a department',
										},
							})(
								<Select
									showArrow
									labelInValue
									placeholder="Select a department"
									className="custom-input"
									onSelect={(value) => console.log(value)}
									options={options}
								/>
							)}
							{requiredErrors && requiredErrors.department ? (
								<div className="input-error">{requiredErrors.department}</div>
							) : null}
						</div>
					)}
					{!hideSubcompanyOnSignup &&
						optionsSubCompanies.length > 0 &&
						!this.state.isSubCompanyIdOnClaim && (
							<FormItem>
								<div className="custom-form-group">
									<label className="custom-label">Company</label>
									{getFieldDecorator(
										'subCompany',
										{}
									)(
										<Select
											labelInValue
											showArrow
											placeholder="Select a company"
											className="custom-input"
										>
											{optionsSubCompanies}
										</Select>
									)}
									{requiredErrors && requiredErrors.subCompany ? (
										<div className="input-error">
											{requiredErrors.subCompany}
										</div>
									) : null}
								</div>
							</FormItem>
						)}
					{!get(userSignupSettings, 'title.hidden') && this.renderLocation()}

					{window.location.href.includes('external-user') ? (
						<div className="custom-form-group">
							<label className="custom-label">Email Address</label>
							{getFieldDecorator('emailAddress', {
								initialValue: emailAddress ? lowerCase(emailAddress) : '',
								rules: [
									{
										type: 'email',
										message: 'Not a valid email Address.',
									},
								],
							})(<Input className="custom-input" />)}
						</div>
					) : (
						<div className="custom-form-group">
							<label className="custom-label">Email Address</label>
							{getFieldDecorator('emailAddress', {
								initialValue: emailAddress ? lowerCase(emailAddress) : '',
								rules: [
									{
										type: 'email',
										message: 'Not a valid email Address.',
									},
								],
							})(<Input className="custom-input" disabled={editEmail} />)}
						</div>
					)}
					<div className="custom-form-group">
						<label className="custom-label">Create Password</label>
						{getFieldDecorator('password', {
							rules: [
								{
									required: true,
									message: 'Password Required',
								},
							],
						})(<Input.Password className="custom-input" autoComplete="on" />)}

						{requiredErrors && requiredErrors.password ? (
							<div className="input-error">{requiredErrors.password}</div>
						) : null}
					</div>
					{lengthError ? (
						<Alert
							type="error"
							message="Your password must have minimum 10 characters, at least one upper case, one lower case letter, a number and a special character."
						/>
					) : null}
					{!whiteLabel && (
						<p>
							By creating a profile you agree to the ERIN{' '}
							<a
								href="http://erinapp.com/terms-of-use"
								rel="noopener noreferrer"
								target="_blank"
							>
								Terms of Use
							</a>{' '}
							and{' '}
							<a
								href="https://erinapp.com/privacy-policy/"
								rel="noopener noreferrer"
								target="_blank"
							>
								Privacy Policy,
							</a>{' '}
							and agree that we can send you information about jobs and
							referrals at your company. You can opt out at any time.
						</p>
					)}
					{serverError ? (
						<Alert
							type="error"
							message="There was a problem creating your account. Please try again."
						/>
					) : claimError ? (
						<Alert
							type="error"
							message="This account has already been claimed."
						/>
					) : activeError ? (
						<Alert type="error" message="This account has been disabled." />
					) : emailError ? (
						<Alert
							type="error"
							message="A user with this email address already exists."
						/>
					) : externalUserGroupError ? (
						<Alert
							type="error"
							message="External user group does not exists in this company."
						/>
					) : null}

					<Button
						htmlType="submit"
						loading={this.state.loading}
						type="primary"
						block="true"
						size="large"
					>
						Get Started
					</Button>
				</Form>
			</>
		);
	}
}

export default withApollo(
	withRouter(
		Form.create()(
			geolocated({
				positionOptions: {
					enableHighAccuracy: false,
				},
				userDecisionTimeout: 60_000,
			})(NewUserForm)
		)
	)
);
