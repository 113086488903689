import { mapReferralStatus, ml } from 'src/_shared/services/utils';

export function ReferralSummaryCard(props) {
	const {
		allMultiLingualData,
		currentUser,
		job = {},
		gdprReferrals = [],
	} = props;
	const { matches } = props;
	const { shares, views, referrals = [] } = job;
	const acceptedReferrals = referrals?.filter(
		(referral) => referral.status !== 'referred'
	);
	const referralsCount = referrals.length + gdprReferrals.length;
	return (
		<div className="mj-referral-card">
			<div className="mjr-card-head">
				<p className="mjr-summary-title">
					{ml('Referral Summary', currentUser, allMultiLingualData)}
				</p>
				<ul className="mjr-summary-count">
					<li>
						<h4>{referrals ? acceptedReferrals.length : 0}</h4>
						<span>
							{' '}
							{ml(
								`${mapReferralStatus('accepted', currentUser.company)}`,
								currentUser,
								allMultiLingualData
							)}{' '}
						</span>
					</li>
					<li>
						<h4>{referralsCount}</h4>
						<span>{ml('Referrals', currentUser, allMultiLingualData)}</span>
					</li>
					<li className="mjr-count-bg">
						<h4>{matches ? matches.length : 0}</h4>
						<span>{ml('Matches', currentUser, allMultiLingualData)}</span>
					</li>
				</ul>
			</div>
			<div className="mjr-card-body">
				<div className="mjr-view-share">
					<label>{ml('Job Shares', currentUser, allMultiLingualData)}</label>
					<p> {shares} </p>
				</div>
				<div className="mjr-view-share">
					<label>
						{ml('Total Job Views', currentUser, allMultiLingualData)}
					</label>
					<p> {views} </p>
				</div>
			</div>
		</div>
	);
}
