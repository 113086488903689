import { connect } from 'react-redux';
import { withMyContacts } from 'src/_shared/api/components/contacts/';
import { withUserByIdEditProfile } from 'src/_shared/api/components/edit-profile/';
import { withDeleteJobMatch } from 'src/_shared/api/components/jobMatch/';
import { withCreateReferralNoUpdate } from 'src/_shared/api/components/referrals/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { withListReferralRequestedNotifications } from 'src/_shared/api/components/users/';
import { dashboardActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils';
import MyContactsComponent from './MyContactsComponent.jsx';

const mapStateToProperties = (state) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser,
		id: currentUser.id,
		filter: { companyId: { eq: currentUser.companyId } },
		limit: 5000,
		companyId: currentUser.companyId,
		allMultiLingualData,
		user: currentUser,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const MyContactsWithApi = compose(
	withMyContacts,
	withDeleteJobMatch,
	withUserByIdEditProfile,
	withGetCompanyData,
	withListReferralRequestedNotifications,
	withCreateReferralNoUpdate
)(MyContactsComponent);

export const MyContacts = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(MyContactsWithApi);
