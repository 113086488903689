import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Divider, Input } from 'antd';
import { Cache } from 'aws-amplify';
import get from 'lodash/get';
import { Component } from 'react';
import GoogleButton from 'react-google-button';
import { auth, lowerCase, query } from 'src/_shared/services/utils';
import { SubmitBtnContainer as SubmitButtonContainer } from '../fogotten-password-modal/forgottenPasswordModalStyles.js';

export class OrganizationModalPageOne extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cognitoError: false,
			errorMessage: '',
			samlData: get(props, 'samlData'),
		};
	}

	async componentDidMount() {
		const samlData = get(this.state, 'samlData', []);
		if (samlData.length === 0) {
			try {
				const samlData = await query(null, 'listSAMLAuths');
				this.setState({ samlData });
			} catch (error) {
				console.log(error);
			}
		}
	}

	handleSubmit = async (e) => {
		e.preventDefault();
		localStorage.setItem('authType', 'saml');
		this.props.form.validateFields(async (error, values) => {
			if (error) {
				console.error(error);
				return;
			}

			const { origin } = window.location;
			let samlData = get(this.state, 'samlData', []);
			if (samlData.length === 0) {
				try {
					samlData = await query(null, 'listSAMLAuths');
				} catch (error) {
					console.log(error);
				}
			}

			for (const saml of samlData) {
				if (
					lowerCase(get(values, 'organizationcode', '')).trim() ===
					get(saml, 'id')
				) {
					Cache.setItem(
						'federatedOrgCode',
						lowerCase(get(values, 'organizationcode', '')).trim()
					);
					Cache.setItem('federatedClientId', get(saml, 'clientId'));
					Cache.setItem('federatedDomain', get(saml, 'domain'));
					Cache.setItem('federatedRegion', get(saml, 'region', 'us-east-2'));
					window.location.href = `https://${get(saml, 'domain')}.auth.${get(
						saml,
						'region',
						'us-east-2'
					)}.amazoncognito.com/oauth2/authorize?identity_provider=${get(
						saml,
						'provider'
					)}&redirect_uri=${origin}/saml-auth/login&response_type=code&client_id=${get(
						saml,
						'clientId'
					)}`;
				}
			}
		});
	};

	handleSubmitGoogle = async () => {
		localStorage.setItem('authType', 'saml');
		const saml = await auth('google');
		const clientId = get(saml, 'clientId');
		Cache.setItem('federatedOrgCode', 'google');
		Cache.setItem('federatedClientId', clientId);
		Cache.setItem('federatedDomain', get(saml, 'domain'));
		Cache.setItem('federatedRegion', get(saml, 'region', 'us-east-2'));
		const { origin } = window.location;
		const url = `https://erinapp.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${origin}/saml-auth/login&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&client_id=${clientId}`;
		window.location.href = url;
	};

	renderError = (error, message) => {
		return error ? <Alert type="error" message={message} /> : null;
	};

	render() {
		const FormItem = Form.Item;
		const { getFieldDecorator } = this.props.form;
		const { cognitoError, errorMessage } = this.state;
		const { theme, error } = this.props;
		const samlData = get(this.state, 'samlData', []);
		return (
			<div>
				<Form>
					<FormItem>
						{getFieldDecorator('organizationcode', {
							rules: [
								{
									required: true,
									message: 'Code Required',
								},
								{
									type: 'string',
								},

								{
									validator(rule, value, callback) {
										if (
											value &&
											!samlData.some(
												(saml) => saml.id === lowerCase(value).trim()
											)
										) {
											callback('Invalid code');
										}

										callback();
									},
								},
							],
						})(
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div
									className="custom-form-group"
									style={{ margin: '0 8px 0 0', flex: '1' }}
								>
									<Input className="custom-input" placeholder="enter code" />
								</div>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
									onClick={this.handleSubmit}
								>
									Continue
								</Button>
							</div>
						)}
					</FormItem>
					{cognitoError && <Alert type="error" message={errorMessage} />}
					<FormItem className={SubmitButtonContainer}>
						<Divider plain>or</Divider>
						<GoogleButton
							style={{ marginBottom: 16, marginTop: 22 }}
							onClick={async () => this.handleSubmitGoogle()}
						/>
					</FormItem>
					{this.renderError(error, error)}
				</Form>
			</div>
		);
	}
}

export default Form.create()(OrganizationModalPageOne);
