import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Input } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ProgressButton from 'react-progress-button';
import { Link } from 'react-router-dom';
import { queryReferralsByJobIdIndex } from 'src/_shared/api/graphql/custom/referrals/';
import {
	filterPhoneNumber,
	getEnvironment,
	lambda,
} from '../../services/utils';
import * as ModalStyles from '../referralRequestModalStyles.js';
import ReferralLinkAutoComplete from './ReferralLinkAutocompleteComponent.jsx';

class ReferralComplianceForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: [],
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
		};
	}

	showAnimation = () => {
		const { toggleIsSubmittingCompliance, handleCancelAll } = this.props;
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					toggleIsSubmittingCompliance();
					handleCancelAll();
					resolve();
				} catch (error) {
					reject(error);
				}
			}, 2700);
		});
	};

	render() {
		const { errors, theme } = this.state;

		if (!this.props.visible) {
			this.props.form.resetFields();
		}

		const {
			changeReferralTypeCompliance,
			referralType,
			toggleIsSubmittingCompliance,
			handleError,
			error,
			job,
			currentUser,
			contact,
			visible,
		} = this.props;
		const { getFieldDecorator } = this.props.form;
		const { TextArea } = Input;
		const FormItem = Form.Item;

		const handleSubmit = (e) => {
			e.preventDefault();
			const { client, form } = this.props;
			let isAlreadyReferred = false;

			form.validateFields(async (error_, values) => {
				const emailPattern =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				const phonePattern = /^\+?\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,6}$/im;
				let email = null;
				let phone = null;
				let importMethod = '';

				if (values.emailAddress && emailPattern.test(values.emailAddress)) {
					email = values.emailAddress;
					importMethod = 'email';
				}

				if (
					values.phoneNumber &&
					phonePattern.test(
						filterPhoneNumber(get(values, 'phoneNumber', false))
					)
				) {
					phone = filterPhoneNumber(values.phoneNumber);
					importMethod = 'mobile';
				}

				try {
					const { data } = await client.query({
						query: gql(queryReferralsByJobIdIndex),
						variables: { jobId: get(job, 'id') },
						fetchPolicy: 'network-only',
					});
					const jobReferrals = get(
						data,
						'queryReferralsByJobIdIndex.items',
						[]
					);
					for (const ref of jobReferrals) {
						const emailAdr =
							get(ref, 'contact.emailAddress', '') !== null &&
							get(ref, 'contact.emailAddress', '') !== ''
								? get(ref, 'contact.emailAddress').toLowerCase()
								: get(ref, 'contact.emailAddress', '');
						const phoneNo =
							get(ref, 'contact.phoneNumber', '') !== null &&
							get(ref, 'contact.phoneNumber', '') !== ''
								? get(ref, 'contact.phoneNumber')
								: get(ref, 'contact.phoneNumber', '');
						if (
							(email !== null && email === emailAdr) ||
							(phone !== null && phone === phoneNo)
						) {
							isAlreadyReferred = true;
							handleError(
								'This person has already been referred for this position.'
							);
							continue;
						}
					}
				} catch (error__) {
					console.log(error__);
				}

				if (!isAlreadyReferred) {
					const referral = {
						companyId: currentUser.companyId,
						referredBy: currentUser.id,
						jobId: job.id,
						referrerFirstName: currentUser.firstName,
						referrerLastName: currentUser.lastName,
						firstName: values.firstName,
						lastName: values.lastName,
						brandColor: currentUser.company.brandColor,
						brandLogo: currentUser.company.logo,
						note: values.note ? values.note : null,
						company: currentUser.company.name,
						title: job.title,
						location: job.location,
						avatar: currentUser.avatar,
						whiteLabel: get(currentUser, 'company.whiteLabel', false),
					};

					if (phone) {
						referral.phoneNumber = filterPhoneNumber(phone);
						referral.referralType = 'text';
					}

					if (email) {
						referral.emailAddress = email.toLowerCase();
						referral.referralType = 'email';
					}

					if (error_) {
						handleError();
					} else {
						toggleIsSubmittingCompliance();
						this.showAnimation().then(() => {
							let endpoint = '';
							if (getEnvironment() === 'dev') {
								console.log('running dev...', getEnvironment());
								endpoint = 'dev-gdpr-referral-created';
							} else {
								console.log('running prod....', getEnvironment());
								endpoint = 'gdpr-referral-created';
							}

							lambda({ endpoint, variables: { referral } });
						});
					}
				}
			});
		};

		return (
			<Form className={ModalStyles.ModalStyles} onSubmit={handleSubmit}>
				<div>
					<ReferralLinkAutoComplete
						visible={visible}
						contact={contact}
						referralType={referralType}
						changeReferralTypeCompliance={changeReferralTypeCompliance}
						form={this.props.form}
					/>
				</div>

				<FormItem className={ModalStyles.FormItemStyles}>
					{getFieldDecorator('note', {
						rules: [],
					})(
						<label className={ModalStyles.LabelStyles}>
							Include a message to your contact <span>(optional)</span>
							<TextArea
								className={ModalStyles.InputStyles}
								placeholder="Personalize the message to your referral"
								rows={4}
								style={{ fontSize: 14 }}
							/>
						</label>
					)}
				</FormItem>
				<div style={{ height: '60px', marginBottom: '20px' }}>
					<p className={ModalStyles.NoteStyle}>
						The referral will not show on your{' '}
						<Link className={ModalStyles.LinkStyles} to="/myreferrals">
							My Referrals
						</Link>{' '}
						page until it is accepted.
					</p>
				</div>

				{error || errors.length > 0 ? (
					<Alert
						className={ModalStyles.SubmitError}
						message={
							this.props.errorMessage
								? this.props.errorMessage
								: 'We are having trouble submitting the form. Please make sure the form is filled out correctly and try again.'
						}
						type="error"
					/>
				) : null}
				<FormItem>
					<ProgressButton state={this.props.buttonState} onClick={handleSubmit}>
						Submit Referral
					</ProgressButton>
				</FormItem>
			</Form>
		);
	}
}

export default withApollo(Form.create()(ReferralComplianceForm));
