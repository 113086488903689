import { connect } from 'react-redux';
import {
	withCreateAnnouncement,
	withDeleteAnnouncement,
	withQueryAnnouncementByCompanyId,
} from 'src/_shared/api/components/announcements';
import { withListUserGroup } from 'src/_shared/api/components/userGroups/';
import { dashboardActions } from 'src/actions.js';
import { compose } from '../_shared/services/utils';
import AnnouncementsComponent from './AnnouncementsComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
		},
		nextToken: null,
		limit: 10_000,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

// Both connect components to Redux store
export const AnnouncementsWithApi = compose(
	withDeleteAnnouncement,
	withCreateAnnouncement,
	withQueryAnnouncementByCompanyId,
	withListUserGroup
)(AnnouncementsComponent);

export const Announcements = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(AnnouncementsWithApi);
