// import { Card, Modal } from 'antd';
// import dayjs from 'dayjs';
// import dompurify from 'dompurify';
// import gql from 'graphql-tag';
// import Cookies from 'js-cookie';
// import _, { get } from 'lodash';
import { PureComponent, createRef } from 'react';
import { withApollo } from 'react-apollo';
// import ReactGA from 'react-ga';
// import { CandidatesComponent } from 'src/ChartComponent/CandidatesComponent.jsx';
// import ChartComponent from 'src/ChartComponent/ChartComponent';
// import { EmployeesComponent } from 'src/ChartComponent/EmployeesComponent';
// import { InternalComponent } from 'src/ChartComponent/InternalComponent';
// import { JobShareComponent } from 'src/ChartComponent/JobShareComponent';
// import { ReferralComponent } from 'src/ChartComponent/ReferralComponent';
// import { configMode } from 'src/_shared/api/';
// import { queryBonusByContactIdIndex } from 'src/_shared/api/graphql/custom/bonuses/';
// import { getContactById } from 'src/_shared/api/graphql/custom/contacts/';
// import { GetJobForMatch } from 'src/_shared/api/graphql/custom/jobMatch/';
// import { queryJobsCountByCompanyId } from 'src/_shared/api/graphql/custom/jobs/';
// import {
// 	GetUserByCognitoId,
// 	getUserById,
// } from 'src/_shared/api/graphql/custom/users/';
// import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
// import GraphComponent from '../GraphComponent/GraphComponent';
// import { EMPLOYEE_DASHBOARD } from '../_shared/constants';
// import {
// 	lambda,
// 	logout,
// 	ml,
// 	parse,
// 	searchOpenSearchNetwork,
// } from '../_shared/services/utils';
// import {
// 	btnLoginPopCenter as buttonLoginPopCenter,
// 	loginPopButton,
// 	loginPopLabelText,
// 	loginPopTitle,
// } from '../dashboard/dashboardStyles.js';
// import './style.css';
//
// const sanitizer = dompurify.sanitize;
// const { Meta } = Card;
class DashboardNew extends PureComponent {
	render() {
		return (
			<></>
		)
	}
	// constructor(props) {
	// 	super(props);
	// 	const lang = get(props, 'currentUser.languageCode', 'US');
	// 	const extendedContactsData = get(
	// 		props,
	// 		'currentUser.extendedContactData',
	// 		[]
	// 	);
	// 	let allJobMatches = extendedContactsData.flatMap((contact) =>
	// 		get(contact, 'jobMatches')
	// 	);
	// 	allJobMatches = allJobMatches.filter((match) => match !== undefined);
	// 	if (get(props, 'currentUser.role') !== 'extendedUser') {
	// 		let userMatches = get(props, 'currentUser.jobMatches', []);
	// 		userMatches = userMatches.filter(
	// 			(match) =>
	// 				!get(match, 'job.hideImInterested') &&
	// 				get(props, 'currentUser.companyId') === get(match, 'job.companyId')
	// 		);
	// 		allJobMatches.push(...userMatches);
	// 		if (allJobMatches.length <= 0) this.getTemporaryJobMatches();
	// 	}
	//
	// 	allJobMatches = _.orderBy(allJobMatches, ['relevance'], ['desc', 'asc']);
	// 	allJobMatches = allJobMatches.map((match) => {
	// 		match.job.location = parse(get(match, 'job.location'));
	// 		return match;
	// 	});
	//
	// 	this.state = {
	// 		allJobMatches,
	// 		company: get(props, 'currentUser.company'),
	// 		isLoading: true,
	// 		companyData: props.companyData,
	// 		referralBonuses: get(props, 'referralBonuses'),
	// 		openPositionsCount: props.openPositions,
	// 		WithReferralCount: props.bonusJobs ? props.bonusJobs.totalJobReferral : 0,
	// 		NoReferralCount: props.bonusJobs ? props.bonusJobs.totalJobNoReferral : 0,
	// 		TierWiseInfo: props.bonusJobs ? props.bonusJobs.tierWise : [],
	// 		totalReferralsCount: props.bonusReferral
	// 			? props.bonusReferral.totalReferral
	// 			: 0,
	// 		statusWiseReferrals: props.bonusReferral
	// 			? props.bonusReferral?.statusWise
	// 			: [],
	// 		tierWiseReferrals: props.bonusReferral
	// 			? props.bonusReferral.tierWise
	// 			: [],
	// 		bonuses: props.bonuses ? props.bonuses : {},
	// 		employeesInfo: props.employees ? props.employees : {},
	// 		dashboardLastUpdated: props.dashboardLastUpdated,
	// 		syncJobs: true,
	// 		isTutorailVisible: localStorage.getItem('isTutorailVisible')
	// 			? localStorage.getItem('isTutorailVisible') != 'hidden'
	// 			: true,
	// 		referralLeaderboard: false,
	// 		pointsLeaderboard: true,
	// 		isLoginPopupVisible: localStorage.getItem('firstLoginValue')
	// 			? localStorage.getItem('firstLoginValue') === 'false'
	// 			: false,
	// 		referralsThisYearCount: props.referralsThisYear,
	// 		searchQuery: '',
	// 		searchQuery2: '',
	// 		allReferrals: [],
	// 		allCandidates: [],
	// 		newDashData: null,
	// 		smallStats: [
	// 			{
	// 				label: 'Referred ',
	// 				value:
	// 					props.company && props.company.confirmCompliance
	// 						? props.countOfWebNotificationsWithStatusReferredForGDPR
	// 						: props.bonusReferral?.statusWise.referred
	// 							? props.bonusReferral?.statusWise.referred
	// 									.toString()
	// 									.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 							: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Accepted',
	// 				value: props.bonusReferral?.statusWise.accepted
	// 					? props.bonusReferral?.statusWise.accepted
	// 							.toString()
	// 							.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 					: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Applied',
	// 				value:
	// 					props.bonusReferral?.statusWise.interviewing +
	// 					props.currentUser.totalReferrals,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Hired',
	// 				value: props.bonusReferral?.statusWise.hired
	// 					? props.bonusReferral?.statusWise.hired
	// 							.toString()
	// 							.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 					: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Not Hired ',
	// 				value: props.bonusReferral?.statusWise.notHired
	// 					? props.bonusReferral?.statusWise.notHired
	// 							.toString()
	// 							.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 					: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Inactive',
	// 				value: props.bonusReferral?.statusWise.inactive
	// 					? props.bonusReferral?.statusWise.inactive
	// 							.toString()
	// 							.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 					: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'No Response',
	// 				value: props.bonusReferral?.statusWise.noresponse
	// 					? props.bonusReferral?.statusWise.noresponse
	// 							.toString()
	// 							.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 					: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Transferred',
	// 				value: props.bonusReferral?.statusWise.transferred
	// 					? props.bonusReferral?.statusWise.transferred
	// 							.toString()
	// 							.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 					: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Ineligible ',
	// 				value: props.bonusReferral?.statusWise.ineligible
	// 					? props.bonusReferral?.statusWise.ineligible
	// 							.toString()
	// 							.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 					: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Processing',
	// 				value: props.bonusReferral?.statusWise.Processing
	// 					? props.bonusReferral?.statusWise.Processing.toString().replaceAll(
	// 							/\B(?=(\d{3})+(?!\d))/g,
	// 							','
	// 						)
	// 					: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Offer Sent',
	// 				value: props.bonusReferral?.statusWise['Offer Sent']
	// 					? props.bonusReferral?.statusWise['Offer Sent']
	// 							.toString()
	// 							.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 					: 0,
	//
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 			{
	// 				label: 'Offer Accepted',
	// 				value: props.bonusReferral?.statusWise['Offer Accepted']
	// 					? props.bonusReferral?.statusWise['Offer Accepted']
	// 							.toString()
	// 							.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
	// 					: 0,
	// 				percentage: null,
	// 				increase: true,
	// 				chartLabels: [null, null, null, null, null, null, null],
	// 				attrs: { md: '3', sm: '4' },
	// 				datasets: [
	// 					{
	// 						label: 'Today',
	// 						fill: 'start',
	// 						borderWidth: 1.5,
	// 						backgroundColor: 'rgba(0, 184, 216, 0.1)',
	// 						borderColor: 'rgb(0, 184, 216)',
	// 						data: [],
	// 					},
	// 				],
	// 			},
	// 		],
	// 	};
	// }
	//
	// // Get contacts data code ends
	//
	// async componentDidMount() {
	// 	const host = window.location.hostname;
	//
	// 	if (host === 'referrals.aus.com') {
	// 		ReactGA.initialize('UA-128630809-2');
	// 		ReactGA.pageview(window.location.pathname + window.location.search);
	// 	} else if (host === 'app.erinapp.com') {
	// 		ReactGA.initialize('UA-128630809-3');
	// 		ReactGA.pageview(window.location.pathname + window.location.search);
	// 	}
	//
	// 	setTimeout(() => {
	// 		this.setState({
	// 			referralBonuses: get(this.props, 'referralBonuses'),
	// 		});
	// 	}, 3500);
	// 	await this.getUserDataById();
	// 	const jwt = Cookies.get('jwt');
	// 	const { resultData } = this.state;
	// 	if (resultData !== '' && resultData !== undefined) {
	// 		const expirationDoneByToken = get(
	// 			resultData,
	// 			'expirationDoneByToken',
	// 			null
	// 		);
	// 		const expires = get(resultData, 'expires');
	// 		if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
	// 			logout(this.props.client);
	// 		}
	// 	}
	//
	// 	const { bonuses = [] } = this.state;
	//
	// 	if (get(this.props, 'location.state.successMessage'))
	// 		this.successMessage(get(this.props, 'location.state.successMessage'));
	// 	get(this.props, 'matchingAtsIdContacts', []).forEach(async (contact) => {
	// 		const contactId = get(contact, 'id');
	// 		const { data } = await this.props.client.query({
	// 			query: gql(queryBonusByContactIdIndex),
	// 			variables: { contactId },
	// 		});
	// 		const allBonuses = get(data, 'queryBonusByContactIdIndex.items', []);
	// 		const candidateBonuses = allBonuses.filter(
	// 			(bonus) => get(bonus, 'recipientType') === 'candidate'
	// 		);
	// 		this.setState({ bonuses: [...bonuses, ...candidateBonuses] });
	// 	});
	// 	const companyId = get(this.props.currentUser, 'companyId');
	// 	const cognitoId = get(this.props.currentUser, 'cognitoId');
	// 	const { data } = await this.props.client.query({
	// 		query: GetUserByCognitoId,
	// 		variables: { cognitoId },
	// 		fetchPolicy: 'network-only',
	// 	});
	// 	const currentUser = get(data, 'getUserByCognitoId');
	// 	if (currentUser) this.props.setCurrentUser(currentUser);
	// 	// START Update hot jobs if campaign is expired
	// 	let endpoint = '';
	// 	endpoint =
	// 		configMode === 'DEV' ? 'dev-update-hot-jobs' : 'prod-update-hot-jobs';
	// 	lambda({ endpoint, variables: { companyId } });
	// 	// END Update hot jobs if campaign is expired
	// 	await this.setQueryToState(get(this.state, 'searchQuery', ''));
	// 	await this.setQueryToState2();
	// 	await fetch(
	// 		`https://4rc4ki2cm7.execute-api.us-east-1.amazonaws.com/dashboard?companyId=${companyId}`
	// 	)
	// 		.then((response) => response.json())
	// 		.then((data) => this.setState({ newDashData: data?.data }))
	// 		.catch((error) => console.log(error));
	// }
	//
	// UNSAFE_componentWillReceiveProps(props) {
	// 	const {
	// 		bonusJobs,
	// 		bonusReferral,
	// 		bonuses,
	// 		employees,
	// 		dashboardLastUpdated,
	// 	} = props;
	//
	// 	const WithReferralCount = bonusJobs ? bonusJobs.totalJobReferral : 0;
	// 	const NoReferralCount = bonusJobs ? bonusJobs.totalJobNoReferral : 0;
	// 	const TierWiseInfo = bonusJobs ? bonusJobs.tierWise : [];
	// 	const totalReferralsCount = bonusReferral ? bonusReferral.totalReferral : 0;
	// 	const statusWiseReferrals = bonusReferral ? bonusReferral?.statusWise : [];
	// 	const tierWiseReferrals = bonusReferral ? bonusReferral.tierWise : [];
	// 	const employeesInfo = employees ? employees : {};
	// 	const bonusesData = bonuses ? bonuses : {};
	// 	const lastUpdate = dashboardLastUpdated ? dashboardLastUpdated : {};
	//
	// 	this.setState({ syncJobs: false });
	// 	this.setState({
	// 		WithReferralCount,
	// 		NoReferralCount,
	// 		TierWiseInfo,
	// 		totalReferralsCount,
	// 		statusWiseReferrals,
	// 		tierWiseReferrals,
	// 		bonuses: bonusesData,
	// 		employeesInfo,
	// 		dashboardLastUpdated: lastUpdate,
	// 	});
	// }
	//
	// async componentDidUpdate(prevProps) {
	// 	const { bonuses = [] } = this.state;
	// 	if (prevProps.matchingAtsIdContacts !== this.props.matchingAtsIdContacts) {
	// 		get(this.props, 'matchingAtsIdContacts', []).forEach(async (contact) => {
	// 			const contactId = get(contact, 'id');
	// 			const { data } = await this.props.client.query({
	// 				query: gql(queryBonusByContactIdIndex),
	// 				variables: { contactId },
	// 			});
	// 			const allBonuses = get(data, 'queryBonusByContactIdIndex.items', []);
	// 			const candidateBonuses = allBonuses.filter(
	// 				(bonus) => get(bonus, 'recipientType') === 'candidate'
	// 			);
	// 			this.setState({ bonuses: [...bonuses, ...candidateBonuses] });
	// 		});
	// 	}
	//
	// 	if (prevProps.companyData !== this.props.companyData) {
	// 		this.setState({
	// 			companyData: this.props.companyData,
	// 		});
	// 	}
	// }
	//
	// // Referral all data code end
	//
	// // get contacts data code starts
	//
	// getDetails = async () => {
	// 	const { client } = this.props;
	// 	const { matchId } = this.props;
	// 	try {
	// 		const get = await client.query({
	// 			query: getContactById,
	// 			variables: {
	// 				id: matchId,
	// 			},
	// 		});
	// 		const referredMatch = await get.data.getContact;
	// 		if (typeof referredMatch.fullContactData === 'string') {
	// 			referredMatch.fullContactData = JSON.parse(
	// 				get.data.getContact.fullContactData
	// 			);
	// 			this.setState({
	// 				matchInfo: referredMatch,
	// 			});
	// 			return referredMatch;
	// 		}
	//
	// 		if (typeof referredMatch.fullContactData === 'object') {
	// 			this.setState({
	// 				matchInfo: referredMatch,
	// 			});
	// 			return referredMatch;
	// 		}
	//
	// 		return referredMatch;
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };
	//
	// async getJobsCount(policy = 'network-only') {
	// 	const { client, currentUser } = this.props;
	// 	try {
	// 		this.setState({ syncJobs: true });
	// 		const { data } = await client.query({
	// 			query: gql(queryJobsCountByCompanyId),
	// 			variables: {
	// 				companyId: get(currentUser, 'companyId'),
	// 			},
	// 			fetchPolicy: policy,
	// 		});
	// 		const jobsCount = data.listOpenJobs.openPositions;
	// 		const referralsCountThisYear = data.listOpenJobs.referralsThisYear;
	// 		let bonusJobJson = {};
	// 		if (data.listOpenJobs.bonusJobs) {
	// 			bonusJobJson = parse(data.listOpenJobs.bonusJobs);
	// 		}
	//
	// 		let bonusReferralJson = {};
	// 		if (data.listOpenJobs.bonusReferral) {
	// 			bonusReferralJson = parse(data.listOpenJobs.bonusReferral);
	// 		}
	//
	// 		let bonusesJson = {};
	//
	// 		if (data.listOpenJobs.bonuses) {
	// 			bonusesJson = parse(data.listOpenJobs.bonuses);
	// 		}
	//
	// 		let employeesInfoJson = {};
	// 		if (data.listOpenJobs.employees) {
	// 			employeesInfoJson = parse(data.listOpenJobs.employees);
	// 		}
	//
	// 		let dashboardLastUpdated = '';
	// 		if (data.listOpenJobs.dashboardLastUpdated) {
	// 			dashboardLastUpdated = data.listOpenJobs.dashboardLastUpdated;
	// 		}
	//
	// 		this.setState({
	// 			openPositionsCount: jobsCount,
	// 			WithReferralCount: bonusJobJson.totalJobReferral,
	// 			NoReferralCount: bonusJobJson.totalJobNoReferral,
	// 			TierWiseInfo: bonusJobJson.tierWise,
	// 			totalReferralsCount: bonusReferralJson.totalReferral,
	// 			statusWiseReferrals: bonusReferralJson?.statusWise,
	// 			tierWiseReferrals: bonusReferralJson.tierWise,
	// 			syncJobs: false,
	// 			bonuses: bonusesJson,
	// 			employeesInfo: employeesInfoJson,
	// 			dashboardLastUpdated,
	// 			referralsThisYearCount: referralsCountThisYear,
	// 		});
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }
	//
	// getTemporaryJobMatches = async () => {
	// 	const parameters = {
	// 		query: get(this.props, 'currentUser.title'),
	// 		size: 15,
	// 		role: get(this.props, 'currentUser.role'),
	// 		currentUser: get(this.props, 'currentUser.id'),
	// 		filters: {
	// 			companies: [get(this.props, 'currentUser.companyId')],
	// 			status: 'open',
	// 		},
	// 	};
	//
	// 	const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
	// 	const temporaryMatches = get(response, 'data', []);
	// 	const top15 = temporaryMatches.slice(0, 15);
	// 	const matches = [];
	// 	await Promise.all(
	// 		top15.map(async (item, index) => {
	// 			const { data } = await this.props.client.query({
	// 				query: GetJobForMatch,
	// 				variables: { id: get(item, 'jobId') },
	// 			});
	// 			const job = get(data, 'getJob', {});
	// 			const match = {
	// 				active: true,
	// 				contactId: null,
	// 				dateCreated: new Date().toISOString(),
	// 				id: `${index}`,
	// 				jobId: get(job, 'id'),
	// 				job,
	// 				matchStatus: null,
	// 				relevance: get(item, 'metaScore'),
	// 				userId: get(this.props, 'currentUser.id'),
	// 				temporary: true,
	// 			};
	//
	// 			matches.push(match);
	// 			if ((index + 1) % 3 === 0 || index === top15.length - 1)
	// 				matches.push({
	// 					id: 'update',
	// 					active: true,
	// 				});
	// 		})
	// 	);
	// 	this.setState({ allJobMatches: matches });
	// };
	//
	// async getUserDataById(policy = 'network-only') {
	// 	const { client, currentUser } = this.props;
	// 	try {
	// 		const userId = get(currentUser, 'id', null);
	// 		if (userId !== null) {
	// 			const { data } = await client.query({
	// 				query: gql(getUserById),
	// 				variables: {
	// 					id: userId,
	// 				},
	// 				fetchPolicy: policy,
	// 			});
	// 			const result = get(data, 'getUser', null);
	// 			this.setState({
	// 				resultData: result,
	// 			});
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }
	//
	// setQueryToState = async (searchQuery = '') => {
	// 	const {
	// 		filteredDepartments = [],
	// 		filteredBonuses = [],
	// 		filteredDistance = 0,
	// 		filterOpenStatus = 'open',
	// 		lat,
	// 		lng,
	// 		filteredSubCompanies = [],
	// 		filteredJobLevels = [],
	// 	} = this.state;
	//
	// 	const filterCompanies = get(this.state, 'filteredCompanies', []).map(
	// 		(company) => get(company, 'id')
	// 	);
	// 	const extendedCompaniesData = get(
	// 		this.props,
	// 		'currentUser.extendedCompaniesData',
	// 		[]
	// 	);
	// 	const extendedCompanies = extendedCompaniesData.filter(
	// 		(company) => get(company, 'disableExtendedUserJobsView') !== true
	// 	);
	// 	const isExtendedUser =
	// 		get(this.props, 'currentUser.role') === 'extendedUser';
	// 	const comps =
	// 		extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
	// 			? extendedCompanies
	// 			: [get(this.props, 'currentUser.company')];
	// 	const companies = comps.map((company) => get(company, 'id'));
	// 	const filteredCompanies =
	// 		filterCompanies.length > 0 ? filterCompanies : companies;
	//
	// 	const unit =
	// 		get(this.props, 'currentUser.userGroup.measurement', 'imperial') ===
	// 		'metric'
	// 			? 'km'
	// 			: 'mi';
	// 	clearTimeout(this.timer);
	// 	this.setState({ searchQuery });
	// 	this.timer = setTimeout(async () => {
	// 		this.setState({ loading: true });
	// 		const parameters = {
	// 			query: get(this.state, 'searchQuery', ''),
	// 			size: 300,
	// 			coordinates: [{ lat, lng }],
	// 			role: get(this.props, 'currentUser.role'),
	// 			currentUser: get(this.props, 'currentUser.id'),
	// 			filters: {
	// 				bonuses: filteredBonuses,
	// 				companies: filteredCompanies,
	// 				departments: filteredDepartments,
	// 				distance: filteredDistance,
	// 				status: filterOpenStatus,
	// 				subCompanies: filteredSubCompanies,
	// 				jobLevels: filteredJobLevels,
	// 			},
	// 			unit,
	// 		};
	// 		const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
	// 		if (get(response, 'query') === get(this.state, 'searchQuery')) {
	// 			let searchedJobs = get(response, 'data', []);
	// 			const filterOn =
	// 				(filteredDistance.length > 0 && filteredDistance != '0') ||
	// 				searchQuery.length > 0;
	//
	// 			searchedJobs = filterOn
	// 				? searchedJobs || []
	// 				: _.orderBy(
	// 						searchedJobs || [],
	// 						['isHotJob', 'dateCreated'],
	// 						['desc', 'desc']
	// 					);
	// 			if (searchedJobs) {
	// 				const filteredJobsGeneral = searchedJobs.filter(
	// 					(item) => item.isGeneralReferral === true
	// 				);
	// 				const hotSearchedJobs = searchedJobs.filter(
	// 					(item) => item.isHotJob === true
	// 				);
	// 				searchedJobs = searchedJobs.filter((item) => item.isHotJob !== true);
	// 				this.setState({
	// 					loading: false,
	// 					searchedJobs,
	// 					filteredJobsGeneral,
	// 					hotSearchedJobs,
	// 				});
	// 			}
	// 		}
	// 	}, 700);
	// };
	//
	// setQueryToState2 = async (searchQuery2 = '', delay = 300) => {
	// 	const { referralsData, gdprReferrals = [] } = this.props;
	// 	const {
	// 		company,
	// 		filteredBonuses = [],
	// 		filteredCompanyStages = [],
	// 		filteredDepartments = [],
	// 		filteredRecruiters = [],
	// 		filteredStatuses = [],
	// 		filteredSubCompanies = [],
	// 		filteredUserGroups = [],
	// 		filterOn,
	// 	} = this.state;
	//
	// 	this.setState({ query: searchQuery2, loading: true });
	// 	clearTimeout(this.timer);
	//
	// 	if (!filterOn) {
	// 		this.setState({
	// 			searchedReferrals: get(this.state, 'allReferrals', []),
	// 		});
	// 	}
	//
	// 	const totals = {
	// 		total: 0,
	// 		accepted: 0,
	// 		interviewing: 0,
	// 		hired: 0,
	// 		declined: 0,
	// 		noResponse: 0,
	// 		notHired: 0,
	// 		inactive: 0,
	// 	};
	//
	// 	this.timer = setTimeout(async () => {
	// 		this.setState({ loading: true });
	// 		const parameters = {
	// 			query: '',
	// 			size: 300,
	// 			filterOn: false,
	// 			currentUser: this.props?.currentUser?.id,
	// 			role: this.props?.currentUser?.role,
	// 			filters: {
	// 				bonuses: [],
	// 				companies: this.props.companyId,
	// 				customStatuses: [],
	// 				departments: [],
	// 				recruiters: [],
	// 				statuses: [],
	// 				subCompanies: [],
	// 				userGroups: [],
	// 			},
	// 		};
	// 		const response = await searchOpenSearchNetwork(parameters, 'erin-referrals');
	// 		console.log('response', response);
	// 		console.log('parameters to be passed', parameters);
	// 		this.setState({ allReferrals: response });
	// 		let searchedReferrals = get(response, 'data', []);
	// 		searchedReferrals =
	// 			searchQuery.length > 0
	// 				? searchedReferrals
	// 				: this.sortByDateReferred(searchedReferrals);
	// 	}, delay);
	// };
	//
	// arraymove(array, fromIndex, toIndex) {
	// 	const element = array[fromIndex];
	// 	array.splice(fromIndex, 1);
	// 	array.splice(toIndex, 0, element);
	// }
	//
	// capitalizeIt = (string = '') => {
	// 	return string.toUpperCase();
	// };
	//
	// findBestMatch(array) {
	// 	if (array) {
	// 		if (array.length === 0) {
	// 			return -1;
	// 		}
	//
	// 		let maxIndex = -1;
	// 		let maxRelevance = 9;
	// 		for (const [i, m] of array.entries()) {
	// 			if (m.relevance > maxRelevance && m.matchStatus !== false) {
	// 				maxIndex = i;
	// 				maxRelevance = m.relevance;
	// 			}
	// 		}
	//
	// 		return maxIndex;
	// 	}
	//
	// 	return null;
	// }
	//
	// formatDate(date, languageCode) {
	// 	if (!date) return;
	// 	const isoDate = new Date(date);
	// 	const month = isoDate.getMonth() + 1;
	// 	const day = isoDate.getDate();
	// 	const year = isoDate.getFullYear();
	// 	const hours = isoDate.getHours();
	// 	const mins = ('0' + isoDate.getMinutes()).slice(-2);
	// 	if (
	// 		(date !== null && languageCode === 'FR') ||
	// 		languageCode === 'DE' ||
	// 		languageCode === 'ES' ||
	// 		languageCode === 'RU'
	// 	) {
	// 		return `${day}/${month}/${year} ${hours}${':'}${mins}`;
	// 	}
	//
	// 	return `${month}/${day}/${year} ${hours}${':'}${mins}`;
	// }
	//
	// handleBonus = () => {
	// 	this.props.history.push('/bonuses');
	// };
	//
	// handleBrowseJobs = (id) => {
	// 	if (id) {
	// 		this.props.history.push(`/browsejobs/${id}`);
	// 	} else {
	// 		this.props.history.push('/browsejobs');
	// 	}
	// };
	//
	// handleCareerProfile = () => {
	// 	this.props.history.push('/career');
	// };
	//
	// handleConnect = () => {
	// 	this.props.history.push('/mycontacts');
	// };
	//
	// handleEmployees = () => {
	// 	this.props.history.push('/employees');
	// };
	//
	// handleFirstLoginPopup = () => {
	// 	localStorage.removeItem('firstLoginValue');
	// 	this.setState({ isLoginPopupVisible: false });
	// };
	//
	// handleInternalMobility = () => {
	// 	this.props.history.push('/internal');
	// };
	//
	// handleJobsDashboard = () => {
	// 	this.props.history.push('/jobs');
	// };
	//
	// handleMyNetwork = () => {
	// 	this.props.history.push('/finder');
	// };
	//
	// handleReferrals = () => {
	// 	this.props.history.push('/referrals');
	// };
	//
	// handleTopPoints = () => {
	// 	this.setState({
	// 		referralLeaderboard: false,
	// 		pointsLeaderboard: true,
	// 	});
	// };
	//
	// handleTopReferrals = () => {
	// 	this.setState({
	// 		pointsLeaderboard: false,
	// 		referralLeaderboard: true,
	// 	});
	// };
	//
	// handleTutorialPopup = () => {
	// 	const { handleCancel } = this.props;
	// 	this.setState({ isTutorailVisible: false });
	// 	localStorage.setItem('isTutorailVisible', 'hidden');
	// };
	//
	// notInterested = (match) => {
	// 	const input = {
	// 		id: get(match, 'id'),
	// 		companyId: get(match, 'job.companyId'),
	// 		active: false,
	// 	};
	// 	if (!get(match, 'temporary')) this.props.onUpdateJobMatch(input);
	// 	let allJobMatches = get(this.state, 'allJobMatches', []);
	// 	const index = allJobMatches.findIndex((m) => m.id === match.id);
	// 	const updatedMatch =
	// 		index >= 0 ? { ...allJobMatches[index], ...input } : null;
	//
	// 	if (updatedMatch) {
	// 		allJobMatches[index] = updatedMatch;
	// 	}
	//
	// 	const temporaryMatches = [];
	// 	if (get(match, 'temporary')) {
	// 		allJobMatches = allJobMatches.filter(
	// 			(match) => match.id !== 'update' && match.active !== false
	// 		);
	// 		allJobMatches.map((match, index) => {
	// 			temporaryMatches.push(match);
	// 			if ((index + 1) % 3 === 0 || index === allJobMatches.length - 1)
	// 				temporaryMatches.push({
	// 					id: 'update',
	// 					active: true,
	// 				});
	// 		});
	// 	}
	//
	// 	const currentUser = get(this.props, 'currentUser');
	// 	currentUser.jobMatches = allJobMatches;
	// 	if (get(match, 'temporary')) {
	// 		this.setState({ allJobMatches: temporaryMatches });
	// 	} else {
	// 		this.props.setCurrentUser(currentUser);
	// 	}
	// };
	//
	// // Referral all data code start
	// sortByAlph = (a, b) => {
	// 	if (!a) return -1;
	// 	if (!b) return 1;
	// 	if (a > b) return 1;
	// 	if (a < b) return -1;
	// 	return 0;
	// };
	//
	// sortByDateReferred(data = []) {
	// 	const sortedData = data.sort((a, b) =>
	// 		this.sortByAlph(
	// 			dayjs(get(b, 'referralDate')),
	// 			dayjs(get(a, 'referralDate'))
	// 		)
	// 	);
	// 	return sortedData;
	// }
	//
	// successMessage = (content) => {
	// 	Modal.success({
	// 		content,
	// 	});
	// };
	//
	// syncJobsCount = () => {
	// 	this.getJobsCount();
	// };
	//
	// renderFirstLoginPopupContent = () => {
	// 	const popupTitle = get(this.props, 'currentUser.company.popupTitle');
	// 	const popupTitleContent = get(
	// 		this.props,
	// 		'currentUser.company.popupTitleContent'
	// 	);
	// 	return (
	// 		<div
	// 			style={{
	// 				minHeight: 250,
	// 				position: 'relative',
	// 				top: 0,
	// 				width: '100%',
	// 				height: '100%',
	// 			}}
	// 		>
	// 			<div className={loginPopTitle}>
	// 				<h4 className={loginPopLabelText}>{popupTitle}</h4>
	// 			</div>
	// 			<div
	// 				dangerouslySetInnerHTML={{ __html: sanitizer(popupTitleContent) }}
	// 				style={{ margin: 20 }}
	// 			/>
	// 			<div className={buttonLoginPopCenter}>
	// 				<button
	// 					type="button"
	// 					className={loginPopButton}
	// 					onClick={this.handleFirstLoginPopup}
	// 				>
	// 					Got It!
	// 				</button>
	// 			</div>
	// 		</div>
	// 	);
	// };
	//
	// render() {
	// 	const pdfoptions = {
	// 		orientation: 'landscape',
	// 	};
	// 	const ref = createRef();
	// 	const {
	// 		currentUser,
	// 		topReferrers,
	// 		departments,
	// 		jobs = [],
	// 		userDashboard,
	// 		jobMatches,
	// 		bonusJobs,
	// 		bonusReferral,
	// 		bonuses,
	// 		employees,
	// 		dashboardLastUpdated,
	// 		allMultiLingualData,
	// 		referralContact,
	// 		announcements,
	// 	} = this.props;
	// 	const onboarding = parse(get(this.props, 'company.onboarding'));
	// 	const onboardingStatus = get(onboarding, 'status');
	// 	const announcement = announcements ? announcements[0] : [];
	// 	const customStages = get(this.props.currentUser, 'company.stages');
	// 	const sendReferralColor = get(currentUser, 'company.sendReferralColor')
	// 		? get(currentUser, 'company.sendReferralColor')
	// 		: '#34B3E9';
	// 	const applyInternallyColor = get(
	// 		currentUser,
	// 		'company.applyInternallyColor'
	// 	)
	// 		? get(currentUser, 'company.applyInternallyColor')
	// 		: '#00EBD0';
	// 	const stages = [];
	// 	if (customStages) {
	// 		const parsedData = parse(customStages);
	// 		if (parsedData.length > 0) {
	// 			stages.push(...parsedData);
	// 		}
	// 	}
	//
	// 	const publicLeaderboard = get(
	// 		currentUser,
	// 		'company.publicLeaderboard',
	// 		false
	// 	);
	// 	const hideReferralsRanking = get(
	// 		currentUser,
	// 		'company.hideReferralsRanking',
	// 		false
	// 	);
	// 	const {
	// 		company,
	// 		companyData,
	// 		referralBonuses,
	// 		openPositionsCount,
	// 		syncJobs,
	// 		isLoginPopupVisible,
	// 		referralsThisYearCount,
	// 		hotSearchedJobs,
	// 	} = this.state;
	// 	const topUsersByPoints = this.props.topUsersByPoints
	// 		? this.props.topUsersByPoints.filter((item) => item.points !== null)
	// 		: [];
	// 	const whiteLabel = get(company, 'whiteLabel');
	// 	const errorImage = get(company, 'errorImage.key', false);
	// 	const WithReferralCount = this.state.WithReferralCount
	// 		? this.state.WithReferralCount
	// 		: bonusJobs
	// 			? bonusJobs.totalJobReferral
	// 			: 0;
	// 	const NoReferralCount = this.state.totalJobNoReferral
	// 		? this.state.totalJobNoReferral
	// 		: bonusJobs
	// 			? bonusJobs.totalJobNoReferral
	// 			: 0;
	// 	const TierWiseInfo = this.state.tierWise
	// 		? this.state.tierWise
	// 		: bonusJobs
	// 			? bonusJobs.tierWise
	// 			: [];
	// 	const totalReferralsCount = this.state.totalReferral
	// 		? this.state.totalReferral
	// 		: bonusReferral
	// 			? bonusReferral.totalReferral
	// 			: 0;
	// 	const statusWiseReferrals = this.state?.statusWise
	// 		? this.state?.statusWise
	// 		: bonusReferral
	// 			? bonusReferral?.statusWise
	// 			: [];
	// 	const tierWiseReferrals = this.state.tierWise
	// 		? this.state.tierWise
	// 		: bonusReferral
	// 			? bonusReferral.tierWise
	// 			: [];
	// 	const employeesInfo = this.state.employeesInfo
	// 		? this.state.employeesInfo
	// 		: employees
	// 			? employees
	// 			: {};
	// 	const bonusesData = this.state.bonuses
	// 		? this.state.bonuses
	// 		: bonuses
	// 			? bonuses
	// 			: {};
	// 	const lastUpdate = this.state.dashboardLastUpdated
	// 		? this.state.dashboardLastUpdated
	// 		: dashboardLastUpdated
	// 			? dashboardLastUpdated
	// 			: {};
	// 	const dateCreated = get(currentUser, 'company.dateCreated');
	// 	const recommendedReferral = this.findBestMatch(jobMatches);
	// 	let recommendedJob;
	// 	let matchesRecommendedJob;
	// 	let matchNumber;
	// 	if (recommendedReferral && recommendedReferral >= 0) {
	// 		recommendedJob = jobs.filter(
	// 			(job) => job.id === jobMatches[recommendedReferral].jobId
	// 		);
	// 		if (recommendedJob.length > 0) {
	// 			matchesRecommendedJob = jobs.filter(
	// 				(job) => job.id === recommendedJob[0].jobId
	// 			);
	// 			matchNumber = matchesRecommendedJob.length + 1;
	// 		}
	// 	}
	//
	// 	const departmentsToUse = departments ? departments : [];
	// 	const theme = get(this.props, 'currentUser.company.theme');
	// 	const languageCode = get(this.props, 'currentUser.languageCode');
	// 	const currencyCode = get(
	// 		this.props,
	// 		'currentUser.userGroup.currency',
	// 		'USD'
	// 	);
	// 	const careerProfile = parse(get(this.props, 'currentUser.careerProfile'));
	// 	const hasUploadedResume = Boolean(
	// 		careerProfile && get(careerProfile, 'resumeFile.name')
	// 	);
	// 	// Take the first four job match instances assuming they're sorted by most recent
	// 	const allJobMatches = get(this.state, 'allJobMatches', []).filter(
	// 		(match) => get(match, 'active') === true
	// 	);
	// 	let isPointsInfoVisible = false;
	// 	const pointsSettings = get(this.state, 'companyData.pointsSettings');
	//
	// 	if (pointsSettings !== null && pointsSettings !== undefined) {
	// 		const pointsSettingsData = parse(pointsSettings);
	// 		isPointsInfoVisible = Boolean(pointsSettingsData.enabled);
	// 	}
	//
	// 	if (!allMultiLingualData || allMultiLingualData.length === 0) {
	// 		return null;
	// 	}
	//
	// 	const popupTitle = get(this.props, 'currentUser.company.popupTitle', '');
	// 	const popupTitleContent = get(
	// 		this.props,
	// 		'currentUser.company.popupTitleContent',
	// 		''
	// 	);
	// 	const referralStatus = get(currentUser, 'company.referralStatus');
	//
	// 	let employeeDashboard = get(currentUser, 'company.employeeDashboard', []);
	// 	employeeDashboard = employeeDashboard ? parse(employeeDashboard) : [];
	// 	let showReferSomeone = true;
	// 	let showInternal = true;
	// 	let showYourReferrals = true;
	// 	let showRanking = true;
	// 	let showPoints = true;
	// 	let showLeaderboards = true;
	// 	let showJobs = true;
	// 	let showAnnouncements = true;
	// 	let showPolicy = true;
	// 	let showJobsForYou = true;
	// 	let showHotJobs = false;
	// 	let showGeneralReferrals = false;
	// 	let referOrder;
	// 	let internalOrder;
	// 	let yourReferralsOrder;
	// 	let rankingOrder;
	// 	let pointsOrder;
	// 	let jobsOrder;
	// 	let leaderboardOrder;
	// 	let announcementOrder;
	// 	let policyOrder;
	// 	let jobsForYouOrder;
	// 	let hotJobsOrder;
	// 	let generalReferralsOrder;
	// 	if (employeeDashboard && employeeDashboard.length > 0) {
	// 		const referSomeoneData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.REFER_SOMEONE
	// 		);
	// 		showReferSomeone = Boolean(referSomeoneData);
	//
	// 		referOrder = get(referSomeoneData, 'order');
	// 		const internalMobilityData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.INTERNAL_MOBILITY
	// 		);
	// 		showInternal = Boolean(internalMobilityData);
	//
	// 		internalOrder = get(internalMobilityData, 'order');
	// 		const yourReferralsData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.YOUR_REFERRALS
	// 		);
	// 		showYourReferrals = Boolean(yourReferralsData);
	//
	// 		yourReferralsOrder = get(yourReferralsData, 'order');
	// 		const referralsMadeRankingData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.REFERRALS_MADE_RANKING
	// 		);
	// 		showRanking = Boolean(referralsMadeRankingData);
	//
	// 		rankingOrder = get(referralsMadeRankingData, 'order');
	// 		const pointsData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.POINTS
	// 		);
	// 		showPoints = Boolean(pointsData);
	//
	// 		pointsOrder = get(pointsData, 'order');
	// 		const jobsOverviewData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.JOBS_OVERVIEW
	// 		);
	// 		showJobs = Boolean(jobsOverviewData);
	//
	// 		jobsOrder = get(jobsOverviewData, 'order');
	// 		const leaderboardsData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.LEADERBOARDS
	// 		);
	// 		showLeaderboards = Boolean(leaderboardsData);
	//
	// 		leaderboardOrder = get(leaderboardsData, 'order');
	// 		const announcementsData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.ANNOUNCEMENTS
	// 		);
	// 		showAnnouncements = Boolean(announcementsData);
	//
	// 		announcementOrder = get(announcementsData, 'order');
	// 		const policyData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.POLICY
	// 		);
	// 		showPolicy = Boolean(policyData);
	//
	// 		policyOrder = get(policyData, 'order');
	// 		const jobsForYouData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.JOBS_FOR_YOU
	// 		);
	// 		showJobsForYou = Boolean(jobsForYouData);
	//
	// 		jobsForYouOrder = get(jobsForYouData, 'order');
	//
	// 		const hotJobsData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.HOT_JOBS
	// 		);
	// 		showHotJobs = Boolean(hotJobsData);
	// 		hotJobsOrder = get(hotJobsData, 'order');
	//
	// 		const generalReferralsData = employeeDashboard.find(
	// 			(x) => x.name === EMPLOYEE_DASHBOARD.GENERAL_REFERRAL
	// 		);
	// 		showGeneralReferrals = Boolean(generalReferralsData);
	// 		generalReferralsOrder = get(generalReferralsData, 'order');
	// 	}
	//
	// 	console.log('allReferrals', this.state.allReferrals);
	// 	console.log('new dash data', this.state.newDashData);
	//
	// 	return !get(currentUser, 'company') ||
	// 		!(this.props.activeEmployees >= 0) ||
	// 		!(this.props.inNetworkConnections >= 0) ? (
	// 		<Spinner />
	// 	) : (
	// 		<>
	// 			{this.state.newDashData === null ? null : (
	// 				<main>
	// 					<div className="page-title">
	// 						<h2 className="page-heading">
	// 							{ml(`Hello`, currentUser, allMultiLingualData)},{' '}
	// 							{get(currentUser, 'firstName', '')}
	// 						</h2>
	// 					</div>
	// 					<div className="d-five-col-data">
	// 						<div className="d-col">
	// 							<div className="nd-card">
	// 								<EmployeesComponent newDashData={this.state.newDashData} />
	// 							</div>
	// 						</div>
	// 						<div className="d-col">
	// 							<div className="nd-card">
	// 								<ReferralComponent newDashData={this.state.newDashData} />
	// 							</div>
	// 						</div>
	// 						<div className="d-col">
	// 							<div className="nd-card">
	// 								<CandidatesComponent newDashData={this.state.newDashData} />
	// 							</div>
	// 						</div>
	// 						<div className="d-col">
	// 							<div className="nd-card">
	// 								<JobShareComponent newDashData={this.state.newDashData} />
	// 							</div>
	// 						</div>
	// 						<div className="d-col">
	// 							<div className="nd-card">
	// 								<InternalComponent newDashData={this.state.newDashData} />
	// 							</div>
	// 						</div>
	// 					</div>
	//
	// 					<div className="d-two-col-data">
	// 						<div className="d-col-left">
	// 							<ChartComponent
	// 								{...this.props}
	// 								newDashData={this.state.newDashData}
	// 							/>
	// 						</div>
	// 						<div className="d-col-right">
	// 							<GraphComponent newDashData={this.state.newDashData} />
	// 						</div>
	// 					</div>
	// 				</main>
	// 			)}
	// 		</>
	// 	);
	// }
}

export default withApollo(DashboardNew);
