import { Cache } from 'aws-amplify';
import axios from 'axios';
import { createContext, useContext } from 'react';

const APIServiceContext = createContext(null);

export const APIServiceProvider = (props) => {
	const clientId = Cache.getItem('federatedClientId');
	const clientSecret = Cache.getItem('federatedClientSecret');

	// axios.interceptors.request.use(function (config) {
	// 	// config.headers = {
	// 	// 	'ngrok-skip-browser-warning': 'skip-browser-warning',
	// 	// };
	// 	if (clientId && clientSecret) {
	// 		config.headers = {
	// 			Authorization:
	// 				'Basic ' +
	// 				Buffer.from(clientId + ':' + clientSecret).toString('base64'),
	// 		};
	// 	}
	// 	return config;
	// });

	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			if (error.response) {
				return Promise.reject(error);
			}

			error.response = {
				data: {
					errorMessage: error.message,
					isError: true,
				},
			};
			return Promise.reject(error);
		}
	);

	const auth = async (url, body) => {
		try {
			const response = await axios.post(url, body);
			return response
				? response.data
					? response.data.result
					: response.data
				: response;
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const get = async (url, body) => {
		try {
			const response = await axios.get(url, { params: body });
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const post = async (url, body) => {
		try {
			const response = await axios.post(url, body);
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const put = async (url, body) => {
		try {
			const response = await axios.put(url, body);
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const remove = async (url, body) => {
		try {
			const response = await axios.delete(url, { data: body });
			return response;
		} catch (error) {
			return error.response;
		}
	};

	const postFormData = async (url, body) => {
		try {
			const response = await axios.post(url, body, {
				headers: {
					'content-type': 'multipart/form-data',
				},
			});
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const putFormData = async (url, body) => {
		try {
			const response = await axios.put(url, body, {
				headers: {
					'content-type': 'multipart/form-data',
				},
			});
			return await Promise.resolve(response ? response.data : response);
		} catch (error) {
			return error
				? error.response
					? error.response.data
					: error.response
				: error;
		}
	};

	const ApiOperations = {
		auth,
		get,
		post,
		put,
		remove,
		postFormData,
		putFormData,
	};

	return (
		<APIServiceContext.Provider value={ApiOperations}>
			{props.children}
		</APIServiceContext.Provider>
	);
};

export const useAPIService = () => {
	return useContext(APIServiceContext);
};
