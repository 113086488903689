import { Card } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { calculateReferralBonus } from '../../../services/utils';

class JobCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currencyRate: 1,
			currencySymbol: '$',
			job: props.job,
		};
	}

	componentDidMount() {
		const currency = get(this.props, 'data.user.currency', 'USD');
		this.getCurrencyRate(currency);
	}

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			// Make this USD
			this.setState({
				currencyRate: 1,
				currencySymbol: '$',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency);
			this.setState({
				currencyRate: rate ? rate : 1,
				currencySymbol: symbol ? symbol : '$',
			});
		}
	}

	selectJob = () => {
		const { job } = this.props;
		this.props.onSelectJob(job);
	};

	render() {
		const {
			currentTieredBonus,
			data,
			job,
			job: { department, title, isHotJob },
		} = this.props;
		const { currencyRate, currencySymbol } = this.state;
		const referralBonus = get(job, 'job.referralBonus');
		const location = get(job, 'job.location');
		let calculatedBonus = `${currencySymbol}${calculateReferralBonus(
			null,
			get(referralBonus, 'amount', 0),
			null,
			currentTieredBonus,
			'employee',
			get(data, 'user.userGroupId'),
			currencyRate
		)}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
		if (currentTieredBonus)
			calculatedBonus = get(currentTieredBonus, 'name', calculatedBonus);

		return (
			<Card
				hoverable
				bordered={false}
				bodyStyle={{ padding: 0, height: '100%' }}
				className="job-card"
				onClick={() => {
					this.selectJob();
				}}
			>
				<div className="job-card-inner">
					<div className="job-card-header" style={{ margin: 0 }}>
						<div className="job-title-wrap">
							<h4 className="job-title">{title}</h4>
							<ul className="actions">
								{isHotJob ? (
									<li>
										<i className="icon-fire text-orange" />
									</li>
								) : null}
								{currentTieredBonus ? (
									<li>
										<span className="amount">
											{get(currentTieredBonus, 'name')}
										</span>
									</li>
								) : (
									<li>
										<span className="amount">{calculatedBonus}</span>
									</li>
								)}
							</ul>
						</div>
						<div className="job-info-wrap">
							<ul className="job-info">
								<li>
									<i className="icon-folder" />
									<span>{department ? department.name : ''}</span>
								</li>
								<li>
									{location && (location.city || location.isRemote === true) ? (
										<i className="icon-location" />
									) : null}
									<span>
										{location && location.city && location.state
											? `${location.city}, ${location.state}`
											: location && location.isRemote === true
												? 'Remote'
												: null}
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</Card>
		);
	}
}

export default JobCard;
