import { Button, Form, Input, Modal, Select, Spin, Typography } from "antd"
import { ErrorMessage, FormikProvider } from "formik";
import { GoStopwatch } from "react-icons/go";

import { CoreBadge } from "../../../RnRCommonComponent";
import { datediff } from "../../../nominatedRewards/utils";
import formatDateInTable from "../../../rewardsAndRecognitionHelper/CommonDate.js";

import './style.scss'
import { useCallback, useEffect, useMemo } from "react";

const NominateEmployeePresentation = ({ nominateEmpList, formik, badgeDetails, endDate, nominationLoading }) => {
    const { setFieldValue, values, handleSubmit } = formik
    const { TextArea } = Input;
    const { Text } = Typography;

    return (
        <FormikProvider value={formik}>
            <div>
                <div className="physical-reward-modal col-12 p-0">
                    <div className='row physical-reward-container'>
                        <div className='col-lg-4 mt-4'>
                            <div className="physical-reward-badge">
                                {badgeDetails && <CoreBadge
                                    badgeType={badgeDetails?.badgeType?.code}
                                    badgeIcon={badgeDetails?.icon?.code}
                                    label={badgeDetails?.label}
                                    color={badgeDetails?.color}
                                />}
                                <div className="physical-reward-details">
                                    <div className="physical-reward-details-title">
                                        {badgeDetails?.name}
                                    </div>
                                    <p className="physical-reward-text">
                                        {badgeDetails?.description}
                                    </p>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        columnGap: "10px",
                                        marginTop: "20px"
                                    }}>
                                        <div>
                                            <GoStopwatch size={"30px"} color='#E75FA8' />
                                        </div>
                                        <div>
                                            <div>{datediff(new Date(), new Date(endDate))} Days Remaining</div>
                                            <div>Ends on {formatDateInTable(endDate)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 mt-4'>
                            <div className="custom-form-group">
                                <Form.Item>
                                    <label className="custom-label">Select Employee To Nominate : </label>
                                    <div className="col-sm-7 pl-0">
                                        <Select
                                            className="custom-input choose-reward-input"
                                            placeholder="Employee Pay Type"
                                            value={values.nomineeId}
                                            onChange={(event) => {
                                                setFieldValue("nomineeId", event)
                                            }}
                                        >
                                            <Select.Option value="" disabled>Select Employee To Nominate...</Select.Option>
                                            {nominateEmpList?.map(({ employee_firstName, employee_id, employee_lastName }) => {
                                                return <Select.Option value={employee_id}>{`${employee_firstName} ${employee_lastName || ""}`}</Select.Option>
                                            })}
                                        </Select>
                                        <Text type="danger"><ErrorMessage name="nomineeId" /></Text>
                                    </div>
                                </Form.Item>
                                <Form.Item className="mt-3">
                                    <label className="custom-label">Enter Reason To Nominate : </label>
                                    <div className="col-sm-7 pl-0">
                                        <TextArea
                                            className="custom-input"
                                            rows={5}
                                            onChange={(event) => {
                                                setFieldValue("nominationReason", event.target.value)
                                            }}
                                        />
                                        <Text type="danger"><ErrorMessage name="nominationReason" /></Text>
                                    </div>
                                </Form.Item>
                                <div className="d-flex justify-content-center w-100">
                                    <Form.Item className="mt-3 mb-0">
                                        {nominationLoading ? <Spin />
                                            : <Button type="primary" onClick={() => {
                                                handleSubmit()
                                            }}>Nominate</Button>}
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                </div>
            </div>
        </FormikProvider>
    )
}

export default NominateEmployeePresentation