import { Modal } from 'antd';
import { Component } from 'react';
import { ml } from 'src/_shared/services/utils';
import AddContactsChoice from './AddContactsChoiceComponent.jsx';
import AddContactsEmail from './AddContactsEmailComponent.jsx';
import AddContactsGoogle from './AddContactsGoogleComponent.jsx';
import AddContactsLinkedin from './AddContactsLinkedinComponent.jsx';
import AddContactsMicrosoft from './AddContactsMicrosoftComponent.jsx';
import AddContactsNavigation from './AddContactsNavigationComponent.jsx';

class AddContactsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: false,
			size: 'large',
			emailInput: '',
			contactsChoice: 0,
			modalContent: (
				<AddContactsChoice
					contactsChoice={this.contactsChoice}
					currentUser={props.currentUser}
					companyData={this.props.companyData}
					allMultiLingualData={this.props.allMultiLingualData}
				/>
			),
			modalProps: {
				title: false,
			},
		};
	}

	contactsChoice = (choice) => {
		switch (choice) {
			case 0: {
				this.setState({
					modalProps: {
						title: false,
					},
					modalContent: (
						<AddContactsChoice
							currentUser={this.props.currentUser}
							contactsChoice={this.contactsChoice}
							companyData={this.props.companyData}
						/>
					),
				});
				break;
			}

			case 1: {
				this.setState({
					modalProps: {
						title: (
							<AddContactsNavigation contactsChoice={this.contactsChoice} />
						),
					},
					modalContent: (
						<AddContactsLinkedin
							contactsChoice={this.contactsChoice}
							currentUser={this.props.currentUser}
							handleCancel={this.props.handleCancel}
							updateUserIncentiveEligibility={
								this.props.updateUserIncentiveEligibility
							}
							companyData={this.props.companyData}
							onCreateContact={this.props.onCreateContact}
						/>
					),
				});
				break;
			}

			case 2: {
				this.setState({
					modalProps: {
						title: (
							<AddContactsNavigation contactsChoice={this.contactsChoice} />
						),
					},
					modalContent: (
						<AddContactsGoogle
							key="addContactsGoogle"
							contactsChoice={this.contactsChoice}
							currentUser={this.props.currentUser}
							handleCancel={this.props.handleCancel}
							updateUserIncentiveEligibility={
								this.props.updateUserIncentiveEligibility
							}
							googleAuthClientID={this.props.googleAuthClientID}
							companyData={this.props.companyData}
							onCreateContact={this.props.onCreateContact}
						/>
					),
				});
				break;
			}

			case 3: {
				this.setState({
					modalProps: {
						title: (
							<AddContactsNavigation contactsChoice={this.contactsChoice} />
						),
					},
					modalContent: (
						<AddContactsMicrosoft
							key="addContactsMicrosoft"
							contactsChoice={this.contactsChoice}
							currentUser={this.props.currentUser}
							handleCancel={this.props.handleCancel}
							updateUserIncentiveEligibility={
								this.props.updateUserIncentiveEligibility
							}
							companyData={this.props.companyData}
							onCreateContact={this.props.onCreateContact}
						/>
					),
				});
				break;
			}

			case 4: {
				this.setState({
					modalProps: {
						title: 'Add Contacts',
					},
					modalContent: (
						<AddContactsEmail
							key="addContactsEmail"
							contactsChoice={this.contactsChoice}
							currentUser={this.props.currentUser}
							handleCancel={this.props.handleCancel}
							updateUserIncentiveEligibility={
								this.props.updateUserIncentiveEligibility
							}
							companyData={this.props.companyData}
							onCreateContact={this.props.onCreateContact}
						/>
					),
				});
				break;
			}

			default: {
				this.setState({
					modalProps: {
						title: false,
					},
					modalContent: (
						<AddContactsChoice contactsChoice={this.contactsChoice} />
					),
				});
				break;
			}
		}
	};

	handleError = () => {
		this.setState({ error: true });
	};

	render() {
		const { visible, handleCancel } = this.props;
		const { modalProps, modalContent } = this.state;

		return (
			<Modal
				open={visible}
				footer={null}
				onCancel={handleCancel}
				{...modalProps}
				cancelText={ml(
					'Cancel',
					this.props.currentUser,
					this.props.allMultilingualData
				)}
			>
				<>{modalContent}</>
			</Modal>
		);
	}
}

export default AddContactsModal;
