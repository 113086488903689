import { css } from 'emotion';
import { COLORS } from '../_shared/styles/colors';

export const EmployeeEmail = css({
	fontSize: 16,
	fontWeight: '600 !important',
});

export const ReferralDetailsContainer = css({
	width: '100%',
	height: '100%',
	padding: '20px 0 40px 40px',
	backgroundColor: COLORS.lightGray2,
	marginBottom: 0,
	maxWidth: 1300,
});

export const LandingBackground = css({
	color: 'white',
	width: '-webkit-fill-available',
	height: '100%',
	position: 'absolute',
	backgroundColor: COLORS.darkBlue,
	left: 0,
	top: 0,
});

export const FlexContainer = css({
	display: 'flex',
	marginBottom: 30,
	width: '100%',
	paddingTop: 20,
});

export const CardStyles = css({
	boxShadow: '1px 1px 15px rgba(0,0,0,0.25)',
	borderRadius: 2,
	padding: 20,
	backgroundColor: 'white',
	height: 'auto',
});

export const CardProfile = css({
	width: '100%',
	marginRight: 15,
	height: '100%',
});

export const Card2Styles = css({
	width: '30%',
	marginLeft: 15,
	height: 200,
	minWidth: 300,
});

export const link = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
});

export const BackLink = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.blue,
	border: 'none',
	backgroundColor: 'transparent',
	paddingLeft: 5,
});

export const BackIcon = css({
	fontSize: 20,
	verticalAlign: 'sub',
});

export const EmployeeName = css({
	marginTop: '-8px',
	marginBottom: '-8px',
	fontSize: 28,
	lineSpacing: 0,
});

export const ReferralSection = css({
	width: 1240,
	marginTop: 10,
});

export const Department = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 15,
	marginLeft: 5,
	marginRight: 5,
});
export const location = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 400,
	color: COLORS.darkGray,
	fontSize: 18,
	marginRight: 5,
});

export const candidateNameStyles = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.darkGray,
	fontSize: '1.75em',
	marginBottom: 0,
	lineHeight: 1,
	paddingBottom: 5,
});

export const refererName = css({
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: 600,
	color: COLORS.blue,
	marginBottom: 0,
	lineHeight: 1,
	paddingBottom: 5,
});
export const outerCardContainer = css({
	backgroundColor: COLORS.white,
	width: '100%',
});

export const cardContainer = css({
	width: '60%',
	margin: 'auto',
	'@media (max-width: 1024px)': {
		width: '80%',
	},
	'@media (max-width: 462px)': {
		width: '100%',
	},
});

export const headerBackground = css({
	backgroundColor: COLORS.darkBlue,
	textAlign: 'center',
});

export const subtitle = css({
	textAlign: 'center',
	color: `${COLORS.red}`,
	marginBottom: 0,
	fontFamily: '"Open Sans", sans-serif',
});

const applyHighlight = (color) =>
	css({
		background: `${color ? color : COLORS.green} !important`,
		color: '#ffffff !important',
	});

const contactHighlight = css({
	background: '#018dd3 !important',
});

export const applyButton = css({
    borderRadius: '10px !important',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: '200 !important',
    margin: 'auto',
    color: COLORS.white,
    backgroundColor: '#70b064',
    fontSize: 14,
    display: 'flex',
    border: 'none',
    height: '50px !important',
    width: '100%',
    minWidth: 'fit-content',
    textAlign: 'center',
    ':hover,:focus': applyHighlight(COLORS.green),
});

export const declineButton = css({
	borderRadius: '10px !important',
	fontFamily: '"Open Sans", sans-serif',
	fontWeight: '200 !important',
	margin: '8px auto 0px auto',
	color: COLORS.text,
	background: COLORS.lightGray3,
	fontSize: 14,
	display: 'flex',
	border: 'none',
	':hover,:focus': applyHighlight(COLORS.red),
	height: '50px !important',
	width: '100%',
	minWidth: 'fit-content',
	textAlign: 'center',
});

export const contactButton = css({
	fontFamily: '"Open Sans", sans-serif',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontWeight: '200 !important',
	color: COLORS.white,
	background: COLORS.blue,
	fontSize: 14,
	marginBottom: 5,
	minWidth: 'fit-content',
	margin: 'auto',
	':hover,:focus': contactHighlight,
	height: '50px !important',
	width: '100%',
	textAlign: 'center',
});

export const iconContainer = css({});

export const cancelIconContainer = css({
	marginRight: 10,
	transform: 'rotate(45deg)',
});

export const title = css({
	color: COLORS.white,
	fontSize: '24px',
	paddingLeft: 10,
	paddingRight: 10,
});

export const title2 = css({
	color: COLORS.white,
	fontSize: '36px',
});

export const divider = css({
	marginLeft: '10px',
	marginRight: '10px',
});

export const listStyle = css({
	listStyleType: 'disc',
});

export const topSpacing = css({
	marginTop: '15px',
});

export const sectionTitle = css({
	marginBottom: 0,
	fontSize: '16px',
	fontFamily: '"Open Sans", sans-serif',
	marginBottom: '5px',
});

export const spinContainer = css({
	width: '100%',
	height: '50vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});
export const summaryValue = css({
	fontFamily: '"Open Sans", sans-serif',
	color: COLORS.darkGray,
	fontSize: '16px',
});
export const detailsTitle = css({
	'&, &': {
		fontSize: '18px',
		fontFamily: '"Open Sans", sans-serif',
	},
	'b': {
		marginRight: '4px',
	}
});

export const buttonSpacing = css({
	justifyContent: 'center',
	width: '55%',
	minWidth: 'fit-content',
	marginTop: '15px',
	marginLeft: 'auto !important',
	marginRight: 'auto !important',
	'@media (max-width: 411px)': {
		display: 'inline-block',
		justifyContent: 'space-around',
	},
});

export const logoContainer = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	img: {
		width: 230,
	},
});

export const loaderHeight = css({
	'& div': {
		'& div': {
			height: '5vh',
			marginTop: '2rem',
		},
	},
	height: '10vh',
	overflow: 'hidden',
});

export const MiscText = css({
	textAlign: 'left',
	fontSize: '12px',
	fontStyle: 'italic',
});

export const menuColor = css({
	color: COLORS.blue,
	cursor: 'pointer',
});

export const JobInfoContainer = css({
	padding: 20,
	marginTop: 15,
	paddingBottom: 10,
	backgroundColor: COLORS.lightGreen,
	borderRadius: 8,
	marginBottom: 15,
	textAlign: 'center',
	width: '55%',
	height: 'auto',
	margin: '0 auto',
	'@media (max-width: 575px)': {
		width: '100%',
	},
	'@media (max-width: 991px) and (min-width: 768px)': {
		width: '85%',
	},
	'@media (max-width: 1199px) and (min-width: 992px)': {
		width: '65%',
	},
	'@media (max-width: 1580px) and (min-width: 1200px)': {
		width: '55%',
	},
});

export const InfoStyle = css({
	fontSize: 10,
	fontWeight: 200,
	textAlign: 'center',
	color: `${COLORS.gray}`,
	width: '55%',
	height: 'auto',
	margin: '0 auto',
	paddingTop: 30,
});

export const PopoverStyle = css({
	'& .ant-popover-inner-content': {
		borderColor: 'rgba(0, 0, 0, 0.75)',
		borderRadius: 4,
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		padding: 5,
	},
	'& .ant-popover-arrow': {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		borderColor: 'rgba(0, 0, 0, 0.75)',
		border: '0px solid',
		zIndex: -1,
	},
});

export const outerCardContainerError = css({
	backgroundColor: COLORS.white,
	width: '100%',
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, 50%)',
});
