import { connect } from 'react-redux';
import { withListCustomPage } from 'src/_shared/api/components/custom-page/';
import { dashboardActions } from 'src/actions.js';
import { USER_ROLES } from '../_shared/constants';
import { compose } from '../_shared/services/utils';
import CustomPageComponent from './CustomPageComponent.jsx';

const mapStateToProperties = (state) => {
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: state.user.currentUser.companyId,
		allMultiLingualData,
		filter: {
			companyId: { eq: state.user.currentUser.companyId },
			role: { eq: USER_ROLES.EMPLOYEE },
		},
		nextToken: null,
		limit: 10_000,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

export const CustomPageWithApi =
	compose(withListCustomPage)(CustomPageComponent);

export const CustomPage = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(CustomPageWithApi);
