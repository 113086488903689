import { useFormik } from 'formik';
import { Modal } from 'antd';

import ManageAddressPresentationModal from '../../presentation/manageAddressPresentationModal'

import useManageAddress from "../../hooks/useManageAddress";

import createEmployeeAddressSchema from './validation';

const ManageAddressContainer = ({ showModal, setShowModal, handleConfirm }) => {
    const handleCancel = () => {
        setShowModal && setShowModal(false);
    };

    const {
        employeeAddress,
        manageAddressLoader,
        countryList,
        stateList,
        cityList,
        getState,
        getCity,
        manageAdressByEmpId
    } = useManageAddress(handleCancel)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...employeeAddress
        },
        validationSchema: createEmployeeAddressSchema,
        onSubmit: (values) => {
            manageAdressByEmpId(values)
            handleConfirm()
        }
    })

    return <Modal
        open={showModal}
        title={"Confirm Address Details"}
        closable={true}
        onCancel={handleCancel}
        footer={null}
        className="manage-nominated-reward-modal reward-modal"
    >
        <ManageAddressPresentationModal
            formik={formik}
            countryList={countryList}
            stateList={stateList}
            cityList={cityList}
            getState={getState}
            getCity={getCity}
            manageAddressLoader={manageAddressLoader}
        />
    </Modal>
}

export default ManageAddressContainer