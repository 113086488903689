import { connect } from 'react-redux';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withUpdateUser } from 'src/_shared/api/components/dashboard/withUpdateUserProvider.js';
import { dashboardActions, userActions } from 'src/actions.js';
import { compose } from 'src/_shared/services/utils';
import JobAlertCard from './JobAlertComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		companyId: currentUser.companyId,
		allMultiLingualData,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const JobAlertContainer = compose(
	withListDepartment,
	withUpdateUser
)(JobAlertCard);

export const JobAlert = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(JobAlertContainer);
