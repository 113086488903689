import { connect } from 'react-redux';
import {
	withCreateContactReferral,
	withListMyReferralsContacts,
	withMyContacts,
} from 'src/_shared/api/components/contacts/';
import { dashboardActions, userActions } from 'src/actions.js';
import { compose } from '../../../_shared/services/utils';
import GeneralReferralCard from './GeneralReferralCardComponent.jsx';

const mapStateToProperties = (state, props) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		filter: {
			companyId: { eq: currentUser.companyId },
			userId: { eq: currentUser.id },
		},
		location: props.location.pathname,
		modalState: state.dashboard.modalState,
		companyId: currentUser.companyId,
		allMultiLingualData,
		jobSearchCriteria: state.user.jobSearchCriteria,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		updateCurrentUserSearchCriteria(searchCriteria) {
			dispatch(userActions.updateUserSearch(searchCriteria));
		},
	};
};

const GeneralReferralCardContainer = compose(
	withCreateContactReferral,
	withMyContacts,
	withListMyReferralsContacts
)(GeneralReferralCard);

export const GeneralReferral = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(GeneralReferralCardContainer);
