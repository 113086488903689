import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Tooltip,
  Tabs,
  Switch
} from 'antd';
import { useEffect, useState } from 'react';
import { PhotoshopPicker } from 'react-color';
import { BADGE_APPROVAL, BADGE_VISIBILITY, SSO_Required_CONSTANTS, MAPPED_BADGE_TYPE } from '../../../RnRCommonComponent/CoreBadge/rewardsConstants.js';
import { CoreBadge } from '../../../RnRCommonComponent'
import DropdownBadgeIcon from '../../../RnRCommonComponent/CoreBadge/DropdownBadgeIcon.jsx';

const EditReward = (props) => {
  const {
    iconsList,
    badgeTypeList,
    isModalOpen,
    handleCloseModal,
    handleUpdateBadge,
    badgeDetailsObject,
    setBadgeDetailsObject,
    badgeErrorObject,
    setBadgeErrorObject,
    isLoaderVisible,
    levelList,
    payTypeList,
    automationRules
  } = props;

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [tempColor, setTempColor] = useState(badgeDetailsObject.color)
  const { TextArea } = Input;

  useEffect(() => {
    setTempColor(badgeDetailsObject.color)
  }, [badgeDetailsObject])

  const badgeApproval = BADGE_APPROVAL?.map((item, index) => {
    return (
      <Select.Option value={item.value} key={index}>
        {item.label}
      </Select.Option>
    );
  });

  const automationData = automationRules?.map((item) => {
    return (
      <Select.Option value={item} key={item}>
        {item}
      </Select.Option>
    );
  })

  const badgeVisibility = BADGE_VISIBILITY?.map((item) => {
    return (
      <Select.Option value={item.value} key={item.label}>
        {item.label}
      </Select.Option>
    );
  });

  const iconsListData = iconsList?.map((icon, index) => {
    return (
      <Select.Option value={icon.code} key={index} >
        <div className='d-flex align-items-center justify-content-start list-img'>
          <DropdownBadgeIcon iconName={icon.code} />
          {icon.name}
        </div>
      </Select.Option>
    );
  });

  const badgeTypeListData = badgeTypeList?.length ? badgeTypeList?.map((badge, index) => {
    return (
      <Select.Option value={badge.code} key={index}>
        {badge.name}
      </Select.Option>
    );
  }) : [];

  const handleColorChange = (newColor) => {
    setTempColor(newColor.hex);
  };

  const getLevelList =
    levelList?.map((item, index) =>
      <Select.Option value={item.id} key={index}>
        {item.level}
      </Select.Option>
    );

  const getPayTypeList =
    payTypeList?.map((item, index) =>
      <Select.Option value={item.id} key={index}>
        {item.payType}
      </Select.Option>
    );


  const wrapStyles = {
    position: 'relative',
  };

  const tabItems = [
    {
      key: '1',
      label: 'Badge Details',
      children: (
        <div className='row'>
          <div className='col-lg-6'>
            <label className="custom-label mb-4 font-weight-bold">
              Badge Design:
            </label>
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">Badge Name:<span className="asterisk">*</span></label>
                <div className="col-sm-7 p-0">
                  <div className={wrapStyles} >
                    <Tooltip
                      overlayClassName="error-tooltip"
                      open={
                        badgeErrorObject &&
                        badgeErrorObject?.['name'] === true
                      }
                      placement="bottomLeft"
                      getPopupContainer={trigger => trigger.parentElement}
                      title="Please enter badge name"
                      trigger={[]}
                    >
                      <Input
                        className="custom-input"
                        placeholder="Badge name"
                        maxLength={32}
                        value={badgeDetailsObject?.name || ''}
                        onChange={(e) => {
                          setBadgeDetailsObject({
                            ...badgeDetailsObject,
                            name: e.target.value,
                          });
                          setBadgeErrorObject({
                            ...badgeErrorObject,
                            name: false,
                          });
                        }}
                        onBlur={() => {
                          if (badgeDetailsObject.name === '')
                            setBadgeErrorObject({
                              ...badgeErrorObject,
                              name: true,
                            });
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </Form.Item>
            </div>
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">Badge Color:<span className="asterisk">*</span></label>
                <div className="col-sm-7 p-0">
                  <div className={wrapStyles} >
                    <Tooltip
                      overlayClassName="error-tooltip"
                      open={
                        badgeErrorObject &&
                        badgeErrorObject?.['color'] === true
                      }
                      placement="bottomLeft"
                      getPopupContainer={trigger => trigger.parentElement}
                      title="Please enter valid color"
                      trigger={[]}
                    >
                      <Input
                        className="custom-input"
                        placeholder="Badge Color"
                        value={badgeDetailsObject?.color || ''}
                        onClick={(e) => {
                          setDisplayColorPicker(true);
                        }}
                      />
                    </Tooltip>
                  </div>
                  {displayColorPicker === true && (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 2,
                        left: '0',
                        right: 'auto',
                        top: '-100px',
                      }}
                    >
                      <PhotoshopPicker
                        color={tempColor}
                        onChange={handleColorChange}
                        onAccept={() => {
                          setBadgeDetailsObject({
                            ...badgeDetailsObject,
                            color: tempColor,
                          });
                          setDisplayColorPicker(false);
                        }}
                        onCancel={() => {
                          setTempColor(badgeDetailsObject.color)
                          setDisplayColorPicker(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Form.Item>
            </div>
            {
              badgeTypeList?.filter((b) => b.code == badgeDetailsObject?.badgeTypeCode)[0]?.code === MAPPED_BADGE_TYPE.AUTOMATED ?
                <></>
                :
                <div className="custom-form-group">
                  <Form.Item>
                    <label className="custom-label">Badge Icon:<span className="asterisk">*</span></label>
                    <div className="col-sm-7 p-0">
                      <Select
                        className="custom-input"
                        style={{
                          width: '100%',
                        }}
                        value={badgeDetailsObject?.iconCode || ''}
                        onChange={(value) => {
                          setBadgeDetailsObject({
                            ...badgeDetailsObject,
                            iconCode: value,
                          });
                        }}
                        placeholder="Select General Referral Category"
                      >
                        {iconsListData}
                      </Select>
                    </div>
                  </Form.Item>
                </div>
            }
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">Badge Label:<span className="asterisk">*</span></label>
                <div className="col-sm-7 p-0">
                  <div className={wrapStyles} >
                    <Tooltip
                      overlayClassName="error-tooltip"
                      open={
                        badgeErrorObject &&
                        badgeErrorObject?.['label'] === true
                      }
                      placement="bottomLeft"
                      getPopupContainer={trigger => trigger.parentElement}
                      title="Please enter badge label"
                      trigger={[]}
                    >
                      <span>
                        <Input
                          value={badgeDetailsObject?.label || ''}
                          className="custom-input"
                          onChange={(e) => {
                            setBadgeDetailsObject({
                              ...badgeDetailsObject,
                              label: e.target.value,
                            });
                            setBadgeErrorObject({
                              ...badgeErrorObject,
                              label: false,
                            });
                          }}
                          placeholder="Badge Label"
                          maxLength={30}
                        />
                        <p className='mb-0' style={{ fontSize: '10px' }}>Maximum 30 characters allowed</p>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </Form.Item>
            </div>
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">Badge Details:<span className="asterisk">*</span></label>
                <div className="col-sm-7 p-0">
                  <div className={wrapStyles} >
                    <Tooltip
                      overlayClassName="error-tooltip"
                      open={
                        badgeErrorObject &&
                        badgeErrorObject?.['description'] === true
                      }
                      placement="bottomLeft"
                      getPopupContainer={trigger => trigger.parentElement}
                      title="Please enter badge details"
                      trigger={[]}
                    >
                      <TextArea
                        maxLength={255}
                        placeholder="Badge Details"
                        value={badgeDetailsObject?.description || ''}
                        onChange={(e) => {
                          setBadgeDetailsObject({
                            ...badgeDetailsObject,
                            description: e.target.value,
                          });
                          setBadgeErrorObject({
                            ...badgeErrorObject,
                            description: false,
                          });
                        }}
                        className="custom-input"
                      />
                    </Tooltip>
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
          <div className='col-lg-6'>
            <label className="custom-label mb-4 font-weight-bold">
              Reward Settings:
            </label>
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">Badge Assignment:</label>
                <div className="col-sm-7 p-0">
                  <Select
                    className="custom-input"
                    style={{
                      width: '100%',
                    }}
                    value={badgeDetailsObject?.badgeTypeCode || ''}
                    onChange={(value) => {
                      setBadgeDetailsObject({
                        ...badgeDetailsObject,
                        badgeTypeCode: value,
                      });
                    }}
                    placeholder="Select General Referral Category"
                  >
                    {badgeTypeListData}
                  </Select>
                </div>
              </Form.Item>
            </div>
            {badgeTypeList?.filter((b) => b.code == badgeDetailsObject?.badgeTypeCode)[0]?.code === MAPPED_BADGE_TYPE.AUTOMATED ? (
              <div className="custom-form-group">
                <Form.Item>
                  <label className="custom-label"> Automation: </label>
                  <div className="col-sm-7 p-0">
                    <Select
                      style={{
                        width: '100%',
                      }}
                      value={badgeDetailsObject?.automationRule}
                      onChange={(value) => {
                        setBadgeDetailsObject({
                          ...badgeDetailsObject,
                          automationRule: value,
                        });
                      }}
                      placeholder="Select General Referral Category"
                    >
                      {automationData}
                    </Select>
                  </div>
                </Form.Item>
              </div>
            ) : (
              <></>
            )}
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">Bonus Amount:
                </label>
                <div className="col-sm-7 p-0">
                  <Input
                    type='number'
                    min={1}
                    max={9999}
                    placeholder="Bonus Points"
                    value={badgeDetailsObject?.rewardBonus}
                    onChange={(e) => {
                      setBadgeDetailsObject({
                        ...badgeDetailsObject,
                        rewardBonus: e.target.value,
                      });
                    }}
                    className="custom-input"
                  />
                </div>
              </Form.Item>
            </div>
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">Points:</label>
                <div className="col-sm-7 p-0">
                  <div className={wrapStyles} >
                    <Tooltip
                      overlayClassName="error-tooltip"
                      open={
                        badgeErrorObject &&
                        badgeErrorObject?.['points'] === true
                      }
                      placement="bottomLeft"
                      trigger={[]}
                      getPopupContainer={trigger => trigger.parentElement}
                      title="Please enter a value equal to or less than 9999"
                    >
                      <Input
                        type='number'
                        min={0}
                        max={9999}
                        placeholder="Points"
                        value={badgeDetailsObject?.points}
                        onChange={(e) => {
                          setBadgeDetailsObject({
                            ...badgeDetailsObject,
                            points: e.target.value,
                          });
                          setBadgeErrorObject({
                            ...badgeErrorObject,
                            points: false,
                          });
                        }}
                        className="custom-input"
                        onBlur={() => {
                          if (badgeDetailsObject.points === '')
                            setBadgeErrorObject({
                              ...badgeErrorObject,
                              points: true,
                            });
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </Form.Item>
            </div>
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">
                  Physical <br /> Award ID:
                </label>
                <div className="col-sm-7 p-0">

                  <Input
                    placeholder="Physical Award ID"
                    value={badgeDetailsObject?.physicalAwardId || ""}
                    onChange={(e) => {
                      setBadgeDetailsObject({
                        ...badgeDetailsObject,
                        physicalAwardId: e.target.value,
                      });
                    }}
                    className="custom-input"
                  />
                </div>
              </Form.Item>
            </div>
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">Badge Visibility:</label>
                <div className="col-sm-7 p-0">
                  <Select
                    className="custom-input"
                    style={{
                      width: '100%',
                    }}
                    value={badgeDetailsObject?.visibility}
                    onChange={(value) => {
                      setBadgeDetailsObject({
                        ...badgeDetailsObject,
                        visibility: value,
                      });
                    }}
                  >
                    {badgeVisibility}
                  </Select>
                </div>
              </Form.Item>
            </div>

            {badgeTypeList?.filter(
              (b) => b.code == badgeDetailsObject?.badgeTypeCode
            )[0]?.code != MAPPED_BADGE_TYPE.AUTOMATED && <div className="custom-form-group">
                <Form.Item>
                  <label className="custom-label">Approval Required?</label>
                  <div className="col-sm-7 p-0">
                    <Select
                      className="custom-input"
                      style={{
                        width: '100%',
                      }}
                      value={badgeDetailsObject?.isPreApproved}
                      onChange={(value) => {
                        setBadgeDetailsObject({
                          ...badgeDetailsObject,
                          isPreApproved: value,
                        });
                      }}
                    >
                      {badgeApproval}
                    </Select>
                  </div>
                </Form.Item>
              </div>}
            <div className="custom-form-group">
              <Form.Item>
                <label className="custom-label">
                  External <br /> Workflow ID:
                </label>
                <div className="col-sm-7 p-0">
                  <Input
                    placeholder="External Workflow ID"
                    maxLength={32}
                    value={badgeDetailsObject?.externalWorkFlowId || ""}
                    onChange={(e) => {
                      setBadgeDetailsObject({
                        ...badgeDetailsObject,
                        externalWorkFlowId: e.target.value,
                      });
                    }}
                    className="custom-input"
                  />
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
      )
    },
    {
      key: '2',
      label: 'Permission Details',
      children: (
        <>
          {badgeTypeList?.filter(
            (b) => b.code == badgeDetailsObject?.badgeTypeCode
          )[0]?.code === MAPPED_BADGE_TYPE.AUTOMATED ? <></> :
            <>
              <div className='row'>
                <div className='col-lg-6 col-12'>
                  <div className="custom-form-group">
                    <Form.Item>
                      <label className="custom-label">Reward max bonus:</label>
                      <div className="col-sm-7 p-0">
                        <Input
                          type="number"
                          min={1}
                          disabled
                          placeholder="Reward max bonus"
                          value={badgeDetailsObject?.rewardMaxBonus}
                          onChange={(e) => {
                            setBadgeDetailsObject({
                              ...badgeDetailsObject,
                              rewardMaxBonus: e.target.value,
                            });
                          }}
                          className="custom-input"
                        />
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className="custom-form-group">
                    <Form.Item>
                      <label className="custom-label">
                        Reward requires date?
                      </label>
                      <div className="col-sm-7 p-0">
                        <Switch disabled checked={badgeDetailsObject?.isRewardRequiresDate} />
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 col-12'>
                  <div className="custom-form-group">
                    <Form.Item>
                      <label className="custom-label">
                        Assignment level:
                      </label>
                      <div className="col-sm-7 p-0">
                        <Select
                          mode='multiple'
                          placeholder={'Select Assignment level'}
                          className="custom-input"
                          style={{
                            width: '100%',
                          }}

                          value={badgeDetailsObject?.assignmentLevelId || undefined}
                          onChange={(value) => {
                            setBadgeDetailsObject({
                              ...badgeDetailsObject,
                              assignmentLevelId: value,
                            });
                          }}
                        >
                          {getLevelList}
                        </Select>
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                  <div className="custom-form-group">
                    <Form.Item>
                      <label className="custom-label">
                        Assignment SSO required?
                      </label>
                      <div className="col-sm-7 p-0">
                        <Switch
                          checked={badgeDetailsObject?.isAssignmentSSORequired === SSO_Required_CONSTANTS.SSO}
                          onChange={
                            (value) => {
                              setBadgeDetailsObject({
                                ...badgeDetailsObject,
                                isAssignmentSSORequired: value ? SSO_Required_CONSTANTS.SSO : SSO_Required_CONSTANTS.STANDARD,
                              });
                            }
                          }
                        />
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          }
          <div className='row'>
            <div className='col-lg-6 col-12'>
              <div className="custom-form-group">
                <Form.Item>
                  <label className="custom-label">
                    Recipient level:
                  </label>
                  <div className="col-sm-7 p-0">
                    <Select
                      mode='multiple'
                      placeholder={'Select Recipient level'}
                      className="custom-input"
                      style={{
                        width: '100%',
                      }}
                      value={badgeDetailsObject?.recipientLevelId || undefined}
                      onChange={(value) => {
                        setBadgeDetailsObject({
                          ...badgeDetailsObject,
                          recipientLevelId: value,
                        });
                      }}
                    >
                      {getLevelList}
                    </Select>
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className="custom-form-group">
                <Form.Item>
                  <label className="custom-label">
                    Recipient SSO required?
                  </label>
                  <div className="col-sm-7 p-0">
                    <Switch
                      checked={badgeDetailsObject?.isRecipientSSORequired === SSO_Required_CONSTANTS.SSO}
                      onChange={
                        (value) => {
                          setBadgeDetailsObject({
                            ...badgeDetailsObject,
                            isRecipientSSORequired: value ? SSO_Required_CONSTANTS.SSO : SSO_Required_CONSTANTS.STANDARD,
                          });
                        }
                      }
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 col-12'>
              <div className="custom-form-group">
                <Form.Item>
                  <label className="custom-label">
                    Recipient <br /> pay type:
                  </label>
                  <div className="col-sm-7 p-0">
                    <Select
                      placeholder={'Select Recipient pay type'}
                      className="custom-input"
                      style={{
                        width: '100%',
                      }}
                      value={badgeDetailsObject?.recipientPayTypeId || undefined}
                      onChange={(value) => {
                        setBadgeDetailsObject({
                          ...badgeDetailsObject,
                          recipientPayTypeId: value,
                        });
                      }}
                    >
                      {getPayTypeList}
                    </Select>
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
        </>
      )
    }
  ];

  return (
    <>
      <div>
        <Modal
          width={950}
          className="reward-modal"
          open={isModalOpen}
          title="Update Badge"
          footer={false}
          onOk={false}
          onCancel={handleCloseModal}
          destroyOnClose={true}
        >
          <>
            <div className="row h-100">
              <div className="col-lg-8 col-12 pr-0">
                <Tabs defaultActiveKey="1" items={tabItems} />
              </div>
              <div className="col-lg-4 col-12">
                <label className="custom-label  font-weight-bold preview-label">
                  Preview:
                </label>
                <div className='add-reward-preview'>
                  <div className='reward-svg'>
                    {
                      badgeDetailsObject && badgeDetailsObject?.badgeTypeCode && badgeDetailsObject?.iconCode &&
                      <CoreBadge
                        badgeType={badgeDetailsObject?.badgeTypeCode}
                        badgeIcon={badgeDetailsObject?.iconCode}
                        label={badgeDetailsObject?.label}
                        color={tempColor}
                      />
                    }
                  </div>
                  <div className="modal-footer-btn flex justify-content-end mt-2">
                    <div className="pb-container">
                      <Form.Item>
                        <Button
                          onClick={handleUpdateBadge}
                          className="pb-button w-auto"
                        >
                          Edit Badge
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              {isLoaderVisible ? <div className="add-edit-reward-loader">
                <Spin />
              </div> : <></>}
            </div>
            <div className="row" />
          </>
        </Modal >
      </div >
    </>
  );
};

export default EditReward;
