import get from 'lodash/get';
import { connect } from 'react-redux';
import { withJobMatchByJobId } from 'src/_shared/api/components/jobMatch/';
import { withJobById } from 'src/_shared/api/components/jobs/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { withListTieredBonus } from 'src/_shared/api/components/tiered-bonuses/';
import { withListReferralRequestedNotificationsByJobIdStatus } from 'src/_shared/api/components/users';
import {
	dashboardActions,
	manageJobsActions,
	referralActions,
} from 'src/actions.js';
import { compose } from '../../_shared/services/utils';
import JobDetailComponent from './JobDetailComponent.jsx';

const mapStateToProperties = (state, props) => {
	let path = window.location.pathname.split('/');
	const urlId = path.pop();
	path = path.join('/');
	const id = get(props, ['match', 'params', 'id'], urlId);
	const { allMultiLingualData } = state.dashboard;
	const departments = get(
		state,
		'user.currentUser.company.departments',
		[]
	).filter((department) => {
		return department.active;
	});

	const m1 = get(state, 'user.currentUser.company.managers', [])
		? get(state, 'user.currentUser.company.managers', [])
		: [];
	const m2 = get(state, 'user.currentUser.company.admins', [])
		? get(state, 'user.currentUser.company.admins', [])
		: [];
	return {
		id,
		jobId: id,
		companyId: state.user.currentUser.companyId,
		currentUser: state.user.currentUser,
		departments,
		jobFormData: state.manageJobs.currentJob,
		path,
		filter: { companyId: { eq: state.user.currentUser.companyId } },
		first: 1000,
		after: '',
		allMultiLingualData,
		managers: [...m1, ...m2],
		subCompanies: get(state, 'user.currentUser.company.subCompanies', []),
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateAddJobForm(values) {
			dispatch(manageJobsActions.updateAddJobForm(values));
		},
		deselectCurrentJob() {
			dispatch(manageJobsActions.resetAddJobForm());
		},
		closeJob(jobId) {
			dispatch(manageJobsActions.closeJob(jobId));
		},
		updateReferral(values) {
			dispatch(referralActions.updateReferral(values));
		},
		fetchReferrals() {
			dispatch(referralActions.fetchReferrals());
		},
		updateReferralsNotHired() {
			dispatch(referralActions.updateReferralsNotHired());
		},
		resetFormFields() {
			dispatch(manageJobsActions.resetAddJobForm());
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
	};
};

const JobDetailWithApi = compose(
	withJobById,
	withJobMatchByJobId,
	withListTieredBonus,
	withListReferralRequestedNotificationsByJobIdStatus,
	withGetCompanyData
)(JobDetailComponent);

export const JobDetail = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(JobDetailWithApi);
