import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Col, Input, Row } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import dayjs from 'dayjs';
import ProgressButton from 'react-progress-button';
import { GetCompanySettings } from 'src/_shared/api/graphql/custom/company/';
import { queryContactsByUserIdIndex } from 'src/_shared/api/graphql/custom/contacts/';
import { downloadFromS3 } from 'src/_shared/services/utils';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { COLORS } from '../_shared/styles/colors';
import {
	CardStyles,
	Department,
	DetailContainer,
	FormItemStyles,
	FormTitle,
	InfoStyle,
	JobInfoContainer,
	NoteStyle,
	RemoveBottomMargin,
	candidateNameStyles,
	cardContainer,
	detailsTitle,
	headerBackground,
	logoContainer,
	menuColor,
	outerCardContainer,
	refererName,
	sectionTitle,
	subtitle,
	topSpacing,
} from './generalReferralStyles.js';

class GeneralReferralCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			isSubmitting: false,
			error: false,
			buttonState: '',
			isMore: false,
			text: [],
			company: '',
			isAccepted: '',
			contactData: [],
			isDuplicate: false,
			logoURL: '',
		};
	}

	async componentDidMount() {
		await this.getCompanyData();
		this.getContactData();
		const { company } = this.state;
		const companyLogo = get(company, 'logo');
		if (companyLogo) {
			const presignedURL = await downloadFromS3(get(companyLogo, 'key'));
			this.setState({ logoURL: presignedURL });
		}
	}

	async getCompanyData(policy = 'network-only') {
		const { client, companyId } = this.props;
		try {
			const { data } = await client.query({
				query: GetCompanySettings,
				variables: {
					id: companyId,
				},
				fetchPolicy: policy,
			});
			const companyData = data.getCompany;
			this.setState({
				company: companyData,
			});
		} catch (error) {
			console.log(error);
		}
	}

	async getContactData() {
		const { client } = this.props;

		const { generalReferralAcceptanceId } = this.props.referralData;
		console.log('generalReferralAcceptanceId', generalReferralAcceptanceId);
		try {
			const { data } = await client.query({
				query: gql(queryContactsByUserIdIndex),
				variables: { userId: get(this.props, 'referralData.userId') },
				fetchPolicy: 'network-only',
			});
			const contactData = get(data, 'queryContactsByUserIdIndex.items', []);
			this.setState({
				contactData,
			});
			const IsGeneralReferralAccepted = contactData.find((item) => {
				return item.generalReferralAcceptanceId === generalReferralAcceptanceId;
			});
			if (IsGeneralReferralAccepted) {
				this.setState({
					isAccepted: true,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setLoading = () => {
		const { loading } = this.state;
		this.setState({ loading: !loading });
	};

	handleCancel() {
		this.setState({ visible: false });
	}

	handleError = () => {
		this.setState({
			error: true,
			buttonState: 'error',
		});
	};

	showAnimation = () => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				try {
					resolve();
				} catch (error) {
					reject(error);
				}
			}, 5700);
		});
	};

	toggleIsSubmitting = () => {
		this.setState({ buttonState: 'loading' });
		this.setState((prevState) => ({ isSubmitting: !prevState.isSubmitting }));
	};

	render() {
		const { referralData, companyId, onCreateContact, companyName } =
			this.props;
		const { company, logoURL } = this.state;
		const whiteLabel = get(
			company,
			'whiteLabel',
			get(referralData, 'company.whiteLabel')
		);
		const brandColor = get(company, 'brandColor');
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const referrerMail = `mailto:${templateThis(referralData.referrerEmail)}`;

		function templateThis(value) {
			if (
				value !== undefined &&
				value.startsWith('null') === false &&
				value.startsWith('$null') === false
			) {
				return value;
			}

			return referralData.unavailable;
		}

		const handleSubmit = (e) => {
			e.preventDefault();
			const { client, form } = this.props;
			this.setState({ buttonState: '' });
			form.validateFields(async (error, values) => {
				const emailPattern =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				let email = null;
				let importMethod = '';

				if (values.emailAddress && emailPattern.test(values.emailAddress)) {
					email = get(values, 'emailAddress', '').toLowerCase();
					importMethod = 'email';
				}

				const contactInput = {
					input: {
						firstName: get(values, 'firstName'),
						lastName: get(values, 'lastName'),
						socialMediaAccounts: null,
						userId: this.props.referralData.userId,
						companyId,
						jobHistory: null,
						importMethod,
						onDeckDate: dayjs(),
						onDeckStatus: 'onDeck',
						generalReferralAcceptanceId:
							this.props.referralData.generalReferralAcceptanceId,
					},
				};
				if (email) contactInput.input.emailAddress = email.toLowerCase();
				if (error) {
					this.handleError();
				} else {
					try {
						const { data } = await client.query({
							query: gql(queryContactsByUserIdIndex),
							variables: { userId: get(this.props, 'referralData.userId') },
							fetchPolicy: 'network-only',
						});
						const contactData = get(
							data,
							'queryContactsByUserIdIndex.items',
							[]
						);
						let duplicate = false;
						let errorMessage = '';
						const isEmailAlreadyExists = contactData.filter((item) => {
							const emailAddress =
								item.emailAddress !== undefined && item.emailAddress !== null
									? item.emailAddress.toLowerCase()
									: null;
							return emailAddress === email;
						});
						if (
							isEmailAlreadyExists !== undefined &&
							isEmailAlreadyExists.length > 0
						) {
							duplicate = true;
							errorMessage =
								'This person has already been added as a General Referral.';
						}

						if (duplicate) {
							this.props.form.setFields({
								emailAddress: {
									errors: [new Error(errorMessage)],
								},
							});
							this.handleError();
						} else {
							this.toggleIsSubmitting();
							this.showAnimation().then(() => {
								const url = get(this.props, 'jobData.jobUrl', null);
								onCreateContact(contactInput)
									.then((response) => {
										console.log('Response', response);
									})
									.then((res) => {
										this.setState({
											buttonState: 'success',
											isAccepted: true,
										});
										setTimeout(() => {
											this.props.form.resetFields();
										}, 2200);
									});
							});
						}
					} catch (error) {
						console.log(error);
					}
				}
			});
		};

		return (
			<section className="landing-background">
				<div
					className={headerBackground}
					style={{
						paddingTop: 10,
						backgroundColor: brandColor,
					}}
				>
					<Row type="flex" justify="center">
						<Col xs={23} m={24}>
							{logoURL && (
								<div className={logoContainer}>
									<img src={logoURL} alt="company logo" />
								</div>
							)}
						</Col>
					</Row>
				</div>
				<div className={outerCardContainer}>
					<div className={cardContainer}>
						<Card
							headStyle={{ height: 100 }}
							className={CardStyles}
							bordered={false}
							style={{ boxShadow: 'none' }}
						>
							<h2 className={FormTitle}>
								Congratulations on your referral for:
							</h2>

							<div className={JobInfoContainer}>
								<div style={{ display: 'inline-block' }}>
									<h3 className={candidateNameStyles}>
										<span className={Department}>
											{templateThis('General Referral')}
										</span>
									</h3>
									<div className={detailsTitle} />
								</div>
							</div>

							<div style={{ textAlign: 'center', paddingTop: '15px' }}>
								<Row type="flex" justify="center">
									<Col xs={24} m={24}>
										<p>
											Referred By:{' '}
											<a href={referrerMail} className={refererName}>
												{templateThis(referralData.referrer)}
											</a>
										</p>
									</Col>
								</Row>
								<Row type="flex" justify="center">
									If you accept the General Referral the Hiring Team at{' '}
									{companyName} will consider you for future roles if there is a
									good fit.
								</Row>
							</div>
							<div style={{ height: 'auto', marginTop: '0px' }}>
								<p className={NoteStyle}>
									To accept your General Referral, we need more information.
								</p>
							</div>
							{this.state.isAccepted && (
								<div className={topSpacing}>
									<Row>
										<h2 className={subtitle} style={{ color: COLORS.green }}>
											Thank you for accepting the General Referral
										</h2>
									</Row>
								</div>
							)}
							{!this.state.isAccepted && (
								<div className={DetailContainer}>
									<Row type="flex" justify="space-between" gutter={12}>
										<Col span={12}>
											<FormItem className={FormItemStyles}>
												{getFieldDecorator('firstName', {
													rules: [
														{ transform: (value) => value.trim() },
														{
															required: true,
															message: 'Please input First Name',
														},
													],
												})(
													<Input
														className={RemoveBottomMargin}
														placeholder="First Name"
													/>
												)}
											</FormItem>
										</Col>
										<Col span={12}>
											<FormItem className={FormItemStyles}>
												{getFieldDecorator('lastName', {
													rules: [
														{ transform: (value) => value.trim() },
														{
															required: true,
															message: 'Please input Last Name',
														},
													],
												})(
													<Input
														className={RemoveBottomMargin}
														placeholder="Last Name"
													/>
												)}
											</FormItem>
										</Col>
										<Col span={24}>
											<FormItem className={FormItemStyles}>
												{getFieldDecorator('emailAddress', {
													rules: [
														{ type: 'email', message: 'Enter a valid email.' },
														{
															required: true,
															message: 'Please enter an email address.',
														},
													],
												})(
													<Input
														className={RemoveBottomMargin}
														placeholder="Email"
													/>
												)}
											</FormItem>
										</Col>
									</Row>
								</div>
							)}
							<div style={{ height: 'auto', marginBottom: '30px' }}>
								<p className={InfoStyle}>
									By submitting, you consent to ERIN storing, processing and
									sharing your name and contact information with{' '}
									{templateThis(referralData.company)} hiring team. Your data
									will never be sold or redistributed by ERIN. Please read our{' '}
									<a
										className={menuColor}
										href="https://erinapp.com/privacy-policy"
										target="_blank"
										rel="noreferrer"
									>
										Privacy Policy
									</a>{' '}
									for more information.
								</p>
							</div>
							{this.state.isAccepted ? null : (
								<FormItem className={DetailContainer}>
									<ProgressButton
										controlled
										state={this.state.buttonState}
										durationError={3000}
										durationSuccess={4500}
										onError={() => this.setState({ buttonState: '' })}
										onClick={(e) => {
											if (this.state.buttonState === '') {
												handleSubmit(e);
											}
										}}
									>
										Accept Your Referral
									</ProgressButton>
								</FormItem>
							)}
						</Card>
					</div>
				</div>
				{!whiteLabel && (
					<div style={{ marginTop: 10 }} className={headerBackground}>
						<p className={sectionTitle}>powered by</p>
						<a href="https://erinapp.com/">
							<img alt="logo" style={{ width: 200 }} src={ErinLogo} />
						</a>
					</div>
				)}
			</section>
		);
	}
}

export default withApollo(Form.create()(GeneralReferralCard));
