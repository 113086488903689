import _, { get } from 'lodash';
import { Component } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { query } from 'src/_shared/services/utils.js';
import { translation } from '../_shared/services/utils';
import NewReferralCard from './NewReferralCardComponent.jsx';
import {
	outerCardContainerError,
	subtitle,
} from './newReferralLandingStyles.js';
// NON GDPR
class NewReferralLandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: '',
			currentTieredBonus: '',
			id: props.id,
			languageCode: 'US',
			phoneNumberData: '',
			referral: '',
			referralQuestions: [],
			resumeAttachData: '',
			retentionBonus: '',
			translatedDescription: '',
			translatedTitle: '',
		};
	}

	componentDidMount() {
		this.getReferralData(this.props.id);
	}

	componentDidUpdate(prevProps) {
		const { referral } = this.props;

		if (referral && prevProps.referral !== referral) {
			this.setState({
				referral,
				company: get(referral, 'company'),
			});
		}
	}

	async getReferralData(id) {
		try {
			const referral = await query({ id }, 'queryReferralByIdIndex');
			const questions = get(referral, 'company.referralQuestions', []);
			let allQuestionsSort = _.sortBy(questions, ['sortOrder']);
			const attachmentResumeQuestion = allQuestionsSort.filter(
				(item) => item.isCandidate === true && item.sortOrder === 0
			);
			const phoneNumberQuestion = allQuestionsSort.filter(
				(item) => item.isCandidate === true && item.sortOrder === -1
			);
			allQuestionsSort = allQuestionsSort.filter(
				(item) => item.sortOrder !== 0
			);
			const allQuestionsForCandidate = allQuestionsSort.filter(
				(item) => item.isCandidate === true
			);
			const questionsData = [];
			if (allQuestionsForCandidate.length > 0) {
				allQuestionsForCandidate.map((element) => {
					questionsData.push(JSON.parse(element.questions));
				});
			}
			
			this.setState({
				referral,
				company: get(referral, 'company'),
				translatedTitle: get(referral, 'job.title'),
				translatedDescription: get(referral, 'job.description'),
				referralQuestions: questionsData,
				resumeAttachData: attachmentResumeQuestion,
				phoneNumberData: phoneNumberQuestion,
			}, () => {
				this.selectLanguage(referral.user.languageCode);
			});
		} catch (error) {
			console.error(error);
		}
	}

	async selectLanguage(languageCode) {
		let translatedTitle = get(this.state, 'referral.job.title');
		let desc = get(this.state, 'referral.job.description').toString('html');
		let translatedDescription = desc;
		if (languageCode !== 'US') {
			desc = `<h1>${translatedTitle}</h1>${desc}`;
			desc = desc.replaceAll('&nbsp;', '');
			desc = desc.replaceAll('&amp;', 'and');
			translatedDescription = await translation(desc, { languageCode });
			translatedTitle = translatedDescription.substring(
				translatedDescription.indexOf('<h1>') + 4,
				translatedDescription.lastIndexOf('</h1>')
			);
			translatedDescription = translatedDescription.slice(
				Math.max(0, translatedTitle.length + 9)
			);
		}
		const referralModifiedLang = {...this.state.referral, user: {...this.state.referral.user, languageCode}};
		this.setState({
			translatedTitle,
			translatedDescription,
			languageCode,
			referral: referralModifiedLang,
		}, () => {
			if (!this.props.currentUser?.id) {
				this.props.setCurrentUser({ languageCode });
			}
		});
	}

	updateReferralState = (referral, values) => {
		const newReferral = Object.assign(referral, values.input);
		this.setState({ referral: newReferral });
	};

	render() {
		const { onUpdate, onUpdateReferral } = this.props;
		const {
			company,
			languageCode,
			referralQuestions,
			resumeAttachData,
			phoneNumberData,
			translatedTitle,
			translatedDescription,
		} = this.state;

		const referral = get(this.state, 'referral');
		if (!referral) {
			return <Spinner forceDefault={true} />;
		}

		if (_.isEmpty(referral)) {
			return (
				<div className={outerCardContainerError}>
					<h2 className={subtitle}>Referral Link is not valid.</h2>
				</div>
			);
		}

		const location = JSON.parse(get(referral, 'job.location', '{}'));
		const salary = JSON.parse(
			referral.job?.salary ?? `{"from": "N/A", "to": "N/A"}`
		);
		const description = translatedDescription;
		const contactFirstName = get(referral, 'contact.firstName');
		const contactLastName = get(referral, 'contact.lastName');
		const contactConfirmedEmail = get(referral, 'contact.confirmedEmail');
		const externalSource = get(referral, 'job.externalSource');
		const atsIntegration = get(referral, 'job.company.atsIntegration', 'N/A');
		const id = get(
			JSON.parse(referral.job.referralBonus),
			'tieredBonusId',
			'skip'
		);
		const companyId = get(referral, 'companyId');
		const userGroupCurrency = get(referral, 'user.userGroup.currency', 'USD');
		const enableCandidateBonus = get(
			referral,
			'company.enableCandidateBonus',
			false
		);
		const userGroupId = get(referral, 'user.userGroupId');
		const jobStatus = get(referral, 'job.status');
		const employeeId = get(referral, 'user.employeeId', null);
		const jobData = {
			company: get(referral, 'company.name', 'N/A'),
			department: get(referral, 'job.department.name', 'N/A'),
			location:
				location?.isRemote === true
					? 'Remote'
					: get(location, 'city', 'N/A') + ', ' + get(location, 'state', 'N/A'),
			salary: `$${get(salary, 'from', 'N/A')} ${
				salary.to
					? '- $' + (get(salary, 'to', 'N/A') || get(salary, 'toNull', 'N/A'))
					: ''
			} `,
			jobOverview: description,
			referrer: `${referral.user.firstName} ${referral.user.lastName}`,
			referrerEmail: referral.user.emailAddress,
			position: translatedTitle,
			jobType: referral.job.jobType,
			hiringEmail: get(referral, 'job.hiringManager.emailAddress', 'N/A'),
			jobUrl: get(referral, 'job.publicLink', null),
			unavailable: 'unavailable',
			id: referral.id,
			contactId: referral.contactId,
			jobId: referral.jobId,
			userId: referral.user.id,
			status: referral.status,
			companyLogo: referral.company.logo,
			brandColor: referral.company.brandColor,
			contactFirstName,
			contactLastName,
			contactConfirmedEmail,
			externalSource,
			tieredBonusId: id,
			companyId,
			userGroupCurrency,
			enableCandidateBonus,
			userGroupId,
			atsIntegration: get(referral, 'company.atsIntegration'),
			languageCode,
			jobStatus: jobStatus || this.state?.referral?.job?.status,
			employeeId,
			whiteLabel: get(referral, 'company.whiteLabel', false),
			subCompany: get(referral, 'job.subCompany.name', null),
			subCompanyLogo: get(referral, 'job.subCompany.logo', null),
			hideRecruiterInfo: get(referral, 'company.hideRecruiterInfo', false),
			isGeneralReferral: get(referral, 'job.isGeneralReferral', false),
		};

		return (
			<NewReferralCard
				allMultiLingualData={get(this.props, 'allMultiLingualData', [])}
				currentUser={this.props.currentUser || null}
				jobData={jobData}
				phoneNumberData={phoneNumberData}
				referral={referral}
				referralQuestions={referralQuestions}
				resumeAttachData={resumeAttachData}
				selectLanguage={this.selectLanguage.bind(this)}
				updateReferralState={this.updateReferralState}
				onUpdateContact={onUpdate}
				onUpdateReferral={onUpdateReferral}
			/>
		);
	}
}

export default NewReferralLandingPage;
