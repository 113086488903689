import { DeleteOutlined } from '@ant-design/icons';
import { AutoComplete, Form, Input } from 'antd';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import _ from 'lodash';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { Component, createRef } from 'react';
import { withApollo } from 'react-apollo';
import ReactDOM from 'react-dom';
import ProgressButton from 'react-progress-button';
import { configMode } from 'src/_shared/api/';
import { queryReferralQuestionsByCompanyId } from 'src/_shared/api/graphql/custom/referral-questions/';
import { createReferral } from 'src/_shared/api/graphql/custom/referrals/';
import { lambda, ml, uploadToS3Multipart } from 'src/_shared/services/utils';
import FormElements from 'src/form-builder/FormElements.jsx';
import * as ModalStyles from '../referral-modal/referralModalStyles.js';
import ContactAutoComplete from './ContactAutocompleteComponent.jsx';

const FormItem = Form.Item;

const { TextArea } = Input;

const inputs = {};

class OnDeckReferralForm extends Component {
	constructor(props) {
		super(props);
		const result = get(props, ['autoCompleteResult'], []);
		const contact = result.length === 1 ? result[0] : null;
		const key = get(contact, ['contactResume', 'key'], null);
		const filename = key ? key.split('/')[1] : null;
		this.state = {
			filename,
			filetype: null,
			errors: [],
			originalFile: [],
			buttonState: '',
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			referralQuestions: [],
		};
		this.formRef = createRef();
	}

	async componentDidMount() {
		await this.fetchQuestion();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.autoCompleteResult !== this.props.autoCompleteResult) {
			const result = get(this.props, ['autoCompleteResult'], []);
			const contact = result.length === 1 ? result[0] : null;
			const key = get(contact, ['contactResume', 'key'], null);
			const filename = key ? key.split('/')[1] : null;
			this.setState({ filename });
		}
	}

	onDelete = () => {
		// * NOTE: I hate this hacky fix, but this is the quickest path towards a hotfic since this component isn't hadling its form state properly
		const { isResumeRequired } = this.state;
		const input = document.querySelector('input[name="file"]');
		input.value = '';
		this.setState({ originalFile: [] });
		this.setState({ filename: null });
		this.setState({ filetype: null });
		this.setState({
			errors: isResumeRequired ? ['A resume is required.'] : [],
		});
	};

	onFileInputChange = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const errors = [];
			const isDocOrDocsOrPdf =
				e.target.files[0].type === 'application/msword' ||
				e.target.files[0].type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				e.target.files[0].type === 'application/pdf';
			if (!isDocOrDocsOrPdf) {
				errors.push('You can only upload doc, docx, pdf files! ');
			}

			const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
			if (!isLt2M) {
				errors.push('File size must smaller than 2MB!');
			}

			if (errors.length > 0) {
				this.setState({ filename: e.target.files[0].name });
				this.setState({ errors });
				return errors;
			}

			this.setState({
				filename: e.target.files[0].name,
				filetype: e.target.files[0].type,
				originalFile: e.target.files[0],
			});

			this.setState({ errors: [] });
			return isDocOrDocsOrPdf && isLt2M;
		}
	};

	getInputElement = (item) => {
		const Input = FormElements[item.element];
		return (
			<Form.Item
				className={ModalStyles.FormItemStyles}
				name={item.field_name}
				rules={[{ required: true, message: 'Required' }]}
			>
				<div key={`form_${item.id}`}>
					<Input
						ref={(c) => (inputs[item.field_name] = c)}
						key={`form_${item.id}`}
						mutable
						data={item}
					/>
				</div>
			</Form.Item>
		);
	};

	getSimpleElement = (item) => {
		const Element = FormElements[item.element];
		return <Element key={`form_${item.id}`} mutable data={item} />;
	};

	_collect = (item) => {
		const errors = [];
		const itemData = { name: item.field_name };
		const ref = inputs[item.field_name];
		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			for (const option of item.options) {
				const $option = ReactDOM.findDOMNode(
					ref.options[`child_ref_${option.key}`]
				);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			}

			itemData.value = checked_options;
		} else {
			if (!ref) return null;
			itemData.value = this._getItemValue(item, ref).value;

			itemData.question = item.label;
			itemData.text =
				this._getItemValue(item, ref).text === undefined
					? ''
					: this._getItemValue(item, ref).text;
			if (itemData.value === '' && itemData.text === '') {
				errors.push(`${item.label} is required.`);
			}
		}

		const object = {
			itemData,
			errors,
		};
		return object;
	};

	_collectFormData = (data) => {
		const formData = [];
		let errors = '';
		for (const item of data) {
			const item_data =
				this._collect(item) === null ? null : this._collect(item).itemData;
			errors += this._collect(item) === null ? '' : this._collect(item).errors;
			if (item_data) {
				formData.push(item_data);
			}
		}

		const object = {
			formData,
			errors,
		};
		return object;
	};

	_getItemValue = (item, ref) => {
		let $item = {
			element: item.element,
			value: '',
			text: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;

				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;

				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;

				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';

				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
						if (item.element === 'Dropdown') {
							$item.text = $item.selectedOptions[0].innerText;
						}
					}
				}
			}
		}

		return $item;
	};

	createNewReferral = async (referral) => {
		const newReferral = await this.props.client.mutate({
			mutation: gql(createReferral),
			variables: { input: referral },
		});
		return newReferral.data.createReferral;
	};

	async fetchQuestion() {
		// Get array of all referral questions data objects and fetch next token if it is there
		try {
			const fetchQuestions = async (nextToken) => {
				const response = await this.props.client.query({
					query: gql(queryReferralQuestionsByCompanyId),
					fetchPolicy: 'network-only',
					variables: {
						companyId: this.props.currentUser.companyId,
						after: nextToken,
					},
				});
				const referralQuestionsData =
					response.data.queryReferralQuestionsByCompanyId.items;

				if (response.data.queryReferralQuestionsByCompanyId.nextToken) {
					const moreReferralQuestionsData = await fetchQuestions(
						response.data.queryReferralQuestionsByCompanyId.nextToken
					);
					return [referralQuestionsData, ...moreReferralQuestionsData];
				}

				return referralQuestionsData;
			};

			// Initiation of the recursive function
			let referralQuestionsData = await fetchQuestions();
			referralQuestionsData = referralQuestionsData.filter(
				(item) => item.isGeneral === true
			);

			// Pull and parse questions from data, excluding resume characteristics
			this.setState({
				referralQuestions: _.sortBy(referralQuestionsData, ['sortOrder'])
					.filter(
						(question) =>
							question.isGeneral === true && question.sortOrder !== 0
					)
					.map((questionItem) => questionItem.questions),
				isResumeRequired:
					referralQuestionsData.findIndex(
						(question) =>
							question.isGeneral &&
							question.questions['Resume required'] === true
					) > -1,
			});
		} catch (error) {
			console.log(error);
		}
	}

	handleSubmit = (values) => {
		this.setState({ buttonState: 'loading' });
		const { notification, onUpdateNotification } = this.props;
		const {
			newContact,
			toggleIsSubmitting,
			onCreateContact,
			onUpdateContact,
			currentUser,
			handleCancel,
		} = this.props;
		const { filename, errors } = this.state;
		let email = null;
		let importMethod = '';

		if (values.emailAddress) {
			email = values.emailAddress;
			importMethod = 'email';
		} else {
			return;
		}

		const isCompliance = get(currentUser.company, 'confirmCompliance', false);
		const questionsData = this._collectFormData(
			this.state.referralQuestions
		).formData;

		const quesErrors = this._collectFormData(
			this.state.referralQuestions
		).errors;
		const isEmptySelect = questionsData.some(
			(item) =>
				item.name.includes('dropdown') &&
				item.text === 'Select' &&
				item.value === '0'
		);

		if (quesErrors.length > 0 || isEmptySelect || errors.length > 0) {
			this.setState({
				buttonState: 'error',
			});
			setTimeout(() => {
				this.setState({
					buttonState: '',
				});
			}, 1500);
			return;
		}

		if (isCompliance) {
			const referral = {
				companyId: currentUser.companyId,
				referredBy: currentUser.id,
				referrerFirstName: currentUser.firstName,
				referrerLastName: currentUser.lastName,
				firstName: values.firstName,
				lastName: values.lastName,
				brandColor: currentUser.company.brandColor,
				brandLogo: currentUser.company.logo,
				note: values.onDeckNote ? values.onDeckNote : null,
				company: currentUser.company.name,
				avatar: currentUser.avatar,
				questionsData: JSON.stringify(questionsData),
			};
			if (email) {
				referral.emailAddress = email.toLowerCase();
				referral.referralType = 'email';
			}

			toggleIsSubmitting();

			/** IIFE to handle animation at the speed the data resolves */
			(async () => {
				toggleIsSubmitting();
				let endpoint = '';
				const host = window.location.hostname;
				endpoint =
					configMode === 'DEV'
						? 'gdpr-general-referral-dev-app'
						: 'gdpr-general-referral-prod-app';
				lambda({
					endpoint,
					variables: { referral },
				});
				this.setState({ buttonState: 'success' });
				mixpanel.track('Referral Lead Submitted');
				handleCancel();
			})();
		} else {
			const d = new Date();
			const dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
			const contactInput = {
				input: {
					firstName: get(values, 'firstName'),
					lastName: get(values, 'lastName'),
					socialMediaAccounts: null,
					userId: currentUser.id,
					companyId: currentUser.companyId,
					jobHistory: null,
					importMethod,
					onDeckDate: dayjs(),
					onDeckStatus: 'onDeck',
					questionsData: JSON.stringify(questionsData),
				},
			};
			if (email) contactInput.input.emailAddress = email.toLowerCase();
			if (get(values, 'onDeckNote'))
				contactInput.input.onDeckNote = get(values, 'onDeckNote');
			if (newContact) {
				toggleIsSubmitting();

				/** IIFE to handle animation at the speed the data resolves */
				(async () => {
					toggleIsSubmitting();
					onCreateContact(contactInput).then((response) => {
						const contactId = get(response, 'data.createContact.id');
						const resume = {
							bucket: 'erin-documents',
							key: `resumes/${contactId}/${dformat + '-' + filename}`,
							region: 'us-east-2',
						};
						if (filename) {
							const updateContactResumeInput = {
								id: contactId,
								contactResume: resume,
							};
							onUpdateContact(updateContactResumeInput);
							this.updateS3ContactResume(resume);
						}

						this.setState({ buttonState: 'success' });
						mixpanel.track('Referral Lead Submitted');
						handleCancel();
					});
				})();
			} else {
				toggleIsSubmitting();

				/** IIFE to handle animation at the speed the data resolves */
				(async () => {
					toggleIsSubmitting();
					if (get(values, 'userId', false)) {
						const contactId = values.userId;
						const input = {
							id: contactId,
							onDeckDate: dayjs(),
							onDeckStatus: 'onDeck',
						};
						if (get(values, 'onDeckNote'))
							input.onDeckNote = get(values, 'onDeckNote');
						const resume = {
							bucket: 'erin-documents',
							key: `resumes/${contactId}/${dformat + '-' + filename}`,
							region: 'us-east-2',
						};
						if (filename) input.contactResume = resume;
						onUpdateContact(input);
						if (get(notification, 'id', false)) {
							onUpdateNotification({
								input: {
									id: notification.id,
									type: notification.type,
									referralRequestedStatus: 'completed',
								},
							});
						}
					}

					this.setState({ buttonState: 'success' });
					mixpanel.track('Referral Lead Submitted');
					handleCancel();
				})();
			}
		}
	};

	onFinishFailed = (error) => {
		this.setState({ buttonState: 'error' });
		console.error(error);
	};

	isAlreadyOnDeck = (value) => {
		const { onDeckContacts } = this.props;
		const alreadyOnDeck = onDeckContacts.find((contact) => {
			return (
				get(contact, 'id') === value &&
				(get(contact, 'onDeckStatus') === 'onDeck' ||
					get(contact, 'offDeckStatus') === 'offDeck')
			);
		});
		if (alreadyOnDeck) {
			return new Error('This person has already been submitted.');
		}
	};

	updateS3ContactResume = async (resume) => {
		const { filename, filetype, originalFile } = this.state;
		if (filename) {
			resume = {
				bucket: resume.bucket,
				key: resume.key,
				region: 'us-east-2',
			};
			await uploadToS3Multipart(originalFile, resume.key, resume.bucket);
		}
	};

	render() {
		if (!this.props.visible) {
			this.formRef.current.resetFields();
		}

		const {
			contacts,
			onDeckContacts,
			newContact,
			handleNewContact,
			autoCompleteResult,
			handleContactChange,
			contact,
			enterReferralInformationText,
			firstNameText,
			lastNameText,
			emailText,
			textPlaceHolderText,
			orText,
			clickHereText,
			toAddExistingContactText,
			optionalText,
			requiredText,
			messageHiringContactText,
			howKnowThemText,
			clickHereResumeText,
			attachResumeText,
			currentUser,
			allMultiLingualData,
		} = this.props;

		const { filename, errors, isResumeRequired } = this.state;
		const FormItem = Form.Item;
		const AutoCompleteOption = AutoComplete.Option;
		const ContactOptions = autoCompleteResult
			.filter((person) => person.firstName && person.lastName)
			.map((person) => (
				<AutoCompleteOption
					key={person.id ? person.id : person.contactId}
					email={person.emailAddress}
				>
					{`${person.firstName} ${person.lastName}`}
				</AutoCompleteOption>
			));

		const data_items = this.state.referralQuestions;
		const items = data_items.map((item) => {
			if (!item) return null;
			let parsedItem = null;
			if (typeof item === 'string') {
				parsedItem = JSON.parse(item);
			}

			switch (parsedItem?.element ?? item.element) {
				case 'TextInput':
				case 'NumberInput':
				case 'TextArea':
				case 'Dropdown':
				case 'DatePicker':
				case 'RadioButtons':
				case 'Rating':
				case 'Tags':
				case 'Range': {
					return this.getInputElement(parsedItem ?? item);
				}

				default: {
					return this.getSimpleElement(parsedItem ?? item);
				}
			}
		});

		return (
			<Form
				ref={this.formRef}
				onFinish={this.handleSubmit}
				onFinishFailed={this.onFinishFailed}
			>
				<ContactAutoComplete
					contact={contact}
					contacts={contacts}
					ContactOptions={ContactOptions}
					form={this.formRef.current}
					newContact={newContact}
					handleContactChange={handleContactChange}
					handleNewContact={handleNewContact}
					customValidator={this.isAlreadyOnDeck}
					matchFound={this.props.matchFound}
					visible={this.props.visible}
					enterReferralInformationText={enterReferralInformationText}
					firstNameText={firstNameText}
					lastNameText={lastNameText}
					emailText={emailText}
					textPlaceHolderText={textPlaceHolderText}
					orText={orText}
					clickHereText={clickHereText}
					toAddExistingContactText={toAddExistingContactText}
					currentUser={get(this.props, 'currentUser')}
					allMultiLingualData={get(this.props, 'allMultiLingualData')}
					onDeckContacts={onDeckContacts}
				/>
				<Form.Item name="onDeckNote">
					<div className="custom-form-gorup">
						<label className="custom-label">
							{messageHiringContactText}{' '}
							<span className="label-optional">{optionalText}</span>
						</label>
						<TextArea
							className="custom-input"
							placeholder={howKnowThemText}
							rows={5}
						/>
					</div>
				</Form.Item>
				<>{items}</>
				<Form.Item
					className={ModalStyles.FormItemStyles}
					name="resume-input"
					rules={[
						{
							required: isResumeRequired,
							message: isResumeRequired ? 'Required' : '',
						},
					]}
				>
					<div key="form_resume-input">
						<p className="text-center">
							<span className="custom-input-file">
								<label htmlFor="file">{clickHereResumeText} </label>
								<input
									ref={(ref) => {
										this.uploadInput = ref;
									}}
									hidden
									type="file"
									accept=".doc,.docx,application/msword,.pdf,application/pdf"
									id="file"
									name="file"
									onClick={() => {
										this.setState({
											filename: null,
											filetype: null,
											originalFile: [],
										});
										this.uploadInput = null;
									}}
									onChange={this.onFileInputChange}
								/>
							</span>{' '}
							{attachResumeText}{' '}
							<span className="label-optional">
								{isResumeRequired ? requiredText : optionalText}
							</span>
						</p>
						{filename && (
							<p className="text-center">
								{filename}
								<DeleteOutlined
									style={{
										margin: '0px 0px 4px 10px',
										color: 'var(--flamingo)',
									}}
									onClick={this.onDelete}
								/>
							</p>
						)}
						{errors && errors.length > 0 ? (
							<p className="text-danger text-center">{errors}</p>
						) : null}
					</div>
				</Form.Item>

				<div className="modal-footer-btn">
					<ProgressButton
						controlled
						durationSuccess={10_000}
						state={this.state.buttonState}
						type="submit"
					>
						{ml('Submit Referral Lead', currentUser, allMultiLingualData)}
					</ProgressButton>
				</div>
			</Form>
		);
	}
}

export default withApollo(OnDeckReferralForm);
