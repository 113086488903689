import { useState, useEffect } from 'react';
import { message } from 'antd';
import { Country, State, City } from 'country-state-city';
import { useMyRewardsService } from '../service/MyRewards.service.jsx';

const useManageAddress = (handleCancel) => {
  const initialAddressValues = {
    id: undefined,
    addressLine1: '',
    addressLine2: '',
    state: undefined,
    country: undefined,
    city: undefined,
    landmark: '',
    pinCode: undefined,
    name: 'Work',
  };

  const [employeeAddress, setEmployeeAddress] = useState(initialAddressValues);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [addressLoader, setAddressLoader] = useState(false);
  const [manageAddressLoader, setManageAddressLoader] = useState(false);
  const {
    getEmployeeAddressById,
    createEmployeeAddress,
    updateEmployeeAddress
  } = useMyRewardsService();

  const getCountry = () => setCountryList(Country.getAllCountries());
  const getState = (countryCode) => countryCode && setStateList(State.getStatesOfCountry(countryCode));
  const getCity = (countryCode, stateCode) => countryCode && stateCode && setCityList(City.getCitiesOfState(countryCode, stateCode));

  const handleAddressError = (error, errorMessage = 'Failed to fetch address! Try again later.') => {
    message.error(error?.response?.data?.message || errorMessage);
    setEmployeeAddress({ ...initialAddressValues });
  };

  const getAdressByEmpId = async (empId) => {
    setAddressLoader(true);
    try {
      const response = await getEmployeeAddressById(empId);
      if (response?.code === 200) {
        if (response.data) {
          const { country, state } = response.data;
          const parsedCountry = JSON.parse(country || '{}');
          const parsedState = JSON.parse(state || '{}');
          getCountry();
          getState(parsedCountry.id);
          getCity(parsedCountry.id, parsedState.id);
          setEmployeeAddress({
            ...response.data,
            country: parsedCountry,
            state: parsedState,
          });
        }
      } else {
        handleAddressError(null, response?.message || 'Failed to fetch address! Try again later.');
      }
    } catch (error) {
      setEmployeeAddress({
        ...initialAddressValues
      })
      handleAddressError(error);
    } finally {
      setAddressLoader(false);
    }
  };

  const createAddress = async (addressObj) => {
    try {
      const response = await createEmployeeAddress({ ...addressObj, name: 'Work' });
      if (response?.code === 200 || response?.code === 201) {
        message.success('Address created successfully!');
      } else {
        message.error(response?.message || 'Failed to create address! Try again later.');
      }
      handleCancel()
    } catch (error) {
      message.error(error?.response?.message || 'Failed to create address! Try again later.');
      handleCancel()
    } finally {
      setManageAddressLoader(false);
      handleCancel()
    }
  };

  const updateAddress = async (addressObj) => {
    try {
      const response = await updateEmployeeAddress(addressObj.id, { ...addressObj, name: 'Work' });
      if (response?.code === 200 || response?.code === 201) {
        message.success('Address updated successfully!');
      } else {
        message.error(response?.message || 'Failed to update address! Try again later.');
      }
      handleCancel()
    } catch (error) {
      message.error(error?.response?.message || 'Failed to update address! Try again later.');
      handleCancel()
    } finally {
      setManageAddressLoader(false);
      handleCancel()
    }
  };

  const manageAdressByEmpId = async (addressObj) => {
    setManageAddressLoader(true);
    try {
      if (addressObj?.id) {
        await updateAddress(addressObj);
      } else {
        await createAddress(addressObj);
      }
    } catch (error) {
      message.error(error?.message || 'Failed to manage address! Try again later.');
    }
  };


  useEffect(() => {
    getAdressByEmpId();
    getCountry();
  }, []); // Call getCountry on component mount

  return {
    getAdressByEmpId,
    employeeAddress,
    addressLoader,
    getCountry,
    getState,
    getCity,
    countryList,
    stateList,
    cityList,
    manageAddressLoader,
    manageAdressByEmpId,
  };
};

export default useManageAddress;
