import { connect } from 'react-redux';
import { userActions } from 'src/actions.js';
import { compose, mutation } from '../_shared/services/utils';
import NewReferralLandingPageComponent from './NewReferralLandingComponent.jsx';

const onUpdate = async (input) => {
	return await mutation(input, 'updateContact');
};

const onUpdateReferral = async (input) => {
	return await mutation(input, 'updateReferral');
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentUser(user) {
			dispatch(userActions.createSetCurrentUserAction(user));
		},
	};
};

const mapStateToProperties = (state, props) => {
	const { id } = props.match.params;
	const { allMultiLingualData } = state.dashboard;

	return {
		allMultiLingualData,
		id,
		currentUser: state.user.currentUser,
		onUpdate,
		onUpdateReferral,
	};
};

const NewReferralLandingPageWithApi = compose()(
	NewReferralLandingPageComponent
);

export const NewReferralLandingPage = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(NewReferralLandingPageWithApi);
