import {
	CloseOutlined,
	InfoCircleOutlined,
	PushpinOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, List, Popover, Select, Tooltip } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component, createRef } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import fileIcon from 'src/_shared/assets/erin_lightgray.png';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import OnDeckReferralModal from 'src/_shared/components/on-deck/on-deck-modal/OnDeckModalComponent.jsx';
import { SearchComponent } from 'src/_shared/components/search';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { USER_ROLES } from 'src/_shared/constants';
import {
	capitalizeFirstLetter,
	getSetErrorImageURL,
	getDaysBetweenDates,
	getEnvironment,
	getLocation,
	logout,
	ml,
	parse,
	searchOpenSearchNetwork,
	subtractDays,
} from 'src/_shared/services/utils';
import { COLORS } from 'src/_shared/styles/colors';
import { AdvanceFilters } from './AdvanceFiltersComponent.jsx';
import BrowseJobModal from './BrowseJobsModalComponent.jsx';
import {
	SelectContainer,
	SelectStyles,
	iconContainer,
} from './browseJobsStyles.js';
import { JobCard } from './jobCard/index.js';

const filterOpenKey = 'open';

const { Option } = Select;
class BrowseJobsComponent extends Component {
	constructor(props) {
		super(props);
		const city = props?.currentUser?.location?.city;
		const state = props?.currentUser?.location?.state;
		const country = props?.currentUser?.location?.country;
		this.state = {
			someJobHasBonus: true,
			company: get(props, 'currentUser.company'),
			theme: parse(get(props, 'currentUser.company.theme', '{}')),
			visible: false,
			filteredCompanies: [],
			filteredDate: undefined,
			filteredDepartments: [],
			searchedJobs: [],
			filteredAvailables: [],
			filteredBonuses: [],
			filteredJobs: get(props, 'jobs', []),
			filteredSalary: undefined,
			allJobs: get(props, 'jobs', []),
			searchQuery: get(props, 'jobSearchCriteria'),
			loading: true,
			isExtendedUser: get(props, 'currentUser.role') === 'extendedUser',
			page: 0,
			errors: {},
			filterOpenStatus: filterOpenKey,
			contactsNextToken: true,
			currencyRate: 0,
			currencySymbol: '$',
			success: false,
			extendedNetworkUsers: get(
				this.props,
				'currentUser.extendedContactData',
				[]
			).map((contact) => get(contact, 'user')),
			allowSelfReferralsInternalLink: get(
				props,
				'currentUser.company.allowSelfReferralsInternalLink'
			),
			text: 'Open Jobs',
			filteredDistance: this.makeNewMenuOption(
				get(props, 'currentUser.defaultDistance', '0')
			),
			maxLoaded: 1,
			companyData: props.companyData,
			address: [
				parse(props?.currentUser?.location)?.city,
				parse(props?.currentUser?.location)?.state,
				parse(props?.currentUser?.location)?.country,
			]
				.filter(Boolean)
				.join(', '),
			city: parse(props?.currentUser?.location)?.city,
			state: parse(props?.currentUser?.location)?.state,
			country: parse(props?.currentUser?.location)?.country,
			subCompanyLabel: get(
				props,
				'currentUser.company.subCompanyLabel',
				'Company'
			)
				? get(props, 'currentUser.company.subCompanyLabel', 'Company')
				: 'Company',
			filteredSubCompanies:
				get(props, 'currentUser.company.defaultToSubcompany') &&
				get(props, 'currentUser.subCompanyId')
					? [get(props, 'currentUser.subCompanyId')]
					: [],
			filteredJobLevels: [],
			defaultLocation: get(props, 'currentUser.defaultDistance')
				? get(props, 'currentUser.defaultDistance')
				: get(props, 'currentUser.company.defaultLocation')
					? get(props, 'currentUser.company.defaultLocation')
					: '0',
			isLocationPopoverVisible: false,
			referPage: get(props, 'referPage', null),
			visibleGeneral: false,
			contacts: props.contacts,
			updatedContacts: props.contacts,
			onDeckContacts: props.onDeckContacts,
			contact: props.contact,
			filteredJobsGeneral: [],
			onlyShowHot: false,
			filterTitle: 'Show Filters',
			visibleAdvance: false,
			hideBonusFilter: get(
				props,
				'currentUser.company.hideBonusFilterOnBrowseJobs'
			),
			hideSubcompanyOnJobs: get(
				props,
				'companyData.hideSubcompanyOnJobs',
				false
			),
			errorImageURL: '',
			pageSizeHotJobs: null,
			totalResults: 0,
			totalHotJobResults: 0,
		};
		this.containerRef = createRef();
		this.intervalId = null;
		this.resizeAttempts = 0;
	}

	componentDidUpdate(prevProps) {
		const { contacts, onFetchMoreContacts, contactsNextToken } = this.props;

		if (prevProps.contacts !== contacts) {
			if (onFetchMoreContacts) onFetchMoreContacts();

			this.setState({
				contacts,
				contactsNextToken:
					contactsNextToken === undefined ? true : contactsNextToken,
			});
		}

		if (prevProps.companyData !== this.props.companyData) {
			if (this.props.onFetchMore) this.props.onFetchMore();
			this.setState({
				companyData: this.props.companyData,
			});
		}
	}

	async componentWillUnmount() {
		clearInterval(this.intervalId);
		window.removeEventListener('resize', this.handleResize);
		await this.props.updateCurrentUserSearchCriteria('');
	}

	selectSearch = async (name, type = 'jobs') => {
		const { currentUser } = this.props;
		let search = parse(get(currentUser, 'search'));
		search ||= {
			recent: { jobs: [], employees: [], referrals: [] },
			saved: { jobs: [], employees: [], referrals: [] },
		};
		let recent = get(search, `recent.${type}`);
		let saved = get(search, `saved.${type}`);
		saved ||= [];
		recent ||= [];
		let matchingQuery = null;
		matchingQuery = saved.find((item) => item?.name === name);
		matchingQuery ||= recent.find((item) => item?.name === name);
		if (!matchingQuery) return;
		const formattedSearch = this.formatSearchFilters(matchingQuery);
		const { state } = formattedSearch;
		state.searchQuery = name;
		if (
			state.filteredBonuses.length > 0 ||
			state.filteredDepartments.length > 0 ||
			state.filteredSubCompanies.length > 0 ||
			state.filteredSalary ||
			state.filteredDate
		) {
			this.setState({
				filterTitle: 'Hide Filters',
				visibleAdvance: true,
			});
		} else {
			this.setState({
				filterTitle: 'Show Filters',
				visibleAdvance: false,
			});
		}

		this.setState(state);
		await this.setQueryToState(name, null, state);
	};

	formatSearchFilters = (matchingQuery, state = {}) => {
		let filters = matchingQuery?.query?.filters;
		let searchType = 'opensearch';
		if (filters?.any || filters?.all) searchType = 'swiftype';
		if (searchType === 'opensearch') {
			filters = matchingQuery?.query?.request?.filters;
			state.filteredBonuses = filters?.bonuses ?? [];
			state.filteredCompanies = filters?.companies?.[0]
				? filters.companies
				: [this.props?.currentUser?.companyId];
			const matchingDate = filters?.dateCreated;
			state.filteredDate = matchingDate
				? getDaysBetweenDates(matchingDate?.from, matchingDate?.to)
				: 0;
			state.filteredDepartments = filters?.departments ?? [];
			state.filteredDistance = filters?.distance
				? this.makeNewMenuOption(filters?.distance)
				: 'allLocations';
			state.filteredDistance = Number.parseInt(state.filteredDistance);
			state.filterOpenStatus = filters?.status ?? 'open';
			state.filteredSubCompanies = filters?.subCompanies ?? [];
			state.filteredSalary = filters?.salary ?? undefined;
		} else if (searchType === 'swiftype') {
			const matchingBonuses = filters?.any.find(
				(filter) => filter.tiered_bonus_id
			);
			state.filteredBonuses = matchingBonuses
				? matchingBonuses.tiered_bonus_id
				: [];
			const matchingCompanies = filters?.all.find(
				(filter) => filter.company_id
			);
			state.filteredCompanies = matchingCompanies
				? matchingCompanies?.company_id
				: [currentUser?.companyId];
			const matchingDate = filters?.all.find((filter) => filter.date_created);
			state.filteredDate = matchingDate
				? getDaysBetweenDates(
						matchingDate?.date_created?.from,
						matchingDate?.date_created?.to
					)
				: 0;
			const matchingDepartments = filters?.all.find(
				(filter) => filter.department_id
			);
			state.filteredDepartments = matchingDepartments
				? matchingDepartments.department_id
				: [];
			const matchingDistance = filters?.any.find((filter) => filter.location);
			state.filteredDistance = matchingDistance
				? this.makeNewMenuOption(matchingDistance?.location?.distance)
				: 'allLocations';
			const matchingStatus = filters?.all.find((filter) => filter.status);
			state.filterOpenStatus = matchingStatus ? matchingStatus.status : 'open';
			const matchingSubCompanies = filters?.all.find(
				(filter) => filter.sub_company_id
			);
			state.filteredSubCompanies = matchingSubCompanies
				? matchingSubCompanies.sub_company_id
				: [];
			const matchingSalary = filters?.any.find(
				(filter) => filter.salary_range_start
			);
			state.filteredSalary = matchingSalary
				? matchingSalary?.salary_range_start?.from
				: undefined;
		}

		const unit =
			get(this.props, 'currentUser.userGroup.measurement', 'imperial') ===
			'metric'
				? 'km'
				: 'mi';
		const request = matchingQuery;
		if (searchType === 'swiftype') {
			const newFilters = {};
			if (state?.filteredBonuses) newFilters.bonuses = state.filteredBonuses;
			if (state?.filteredCompanies)
				newFilters.companies = state.filteredCompanies;
			if (state?.filteredDate) newFilters.dateCreated = state.filteredDate;
			if (state?.filteredDepartments)
				newFilters.departments = state.filteredDepartments;
			if (state?.filteredDistance)
				newFilters.distance = state?.filteredDistance;
			if (state?.filterOpenStatus) newFilters.status = state.filterOpenStatus;
			if (state?.filteredSubCompanies)
				newFilters.subCompanies = state.filteredSubCompanies;
			if (state?.filteredSalary) newFilters.salary = state.filteredSalary;
			filters = newFilters;
			const size = request?.query?.page?.size || 300;
			request.query = {
				configMode: getEnvironment(),
				currentUser: this.props?.currentUser?.id,
				query: request?.query?.query,
				request: { ...request.query, filters, size, unit },
			};
			delete request.query.filters;
			delete request.query.request.page;
		} else {
			request.query.request.filters = filters;
		}

		return { state, request };
	};

	saveSearch = async (searchItem, operation) => {
		const { currentUser, setCurrentUser } = this.props;
		const formattedSearch = this.formatSearchFilters(searchItem);
		searchItem.query.request.filters = formattedSearch.filters;
		searchItem.query.request.currentUser = currentUser?.id;
		const res = await searchOpenSearchNetwork(searchItem, operation);
		const search = res?.search;
		if (search) {
			currentUser.search = search;
			setCurrentUser(currentUser);
		}
	};

	displaySearchFilter(object, type = 'jobs') {
		const { currentUser } = this.props;
		const menuItems = [];
		const search = parse(get(currentUser, 'search'));
		if (!search) return object;
		const recent = get(search, `recent.${type}`, []);
		const saved = get(search, `saved.${type}`, []);

		if (recent.length > 0) {
			menuItems.push({
				label: (
					<div
						style={{
							fontWeight: 600,
							marginLeft: 0,
							fontSize: 14,
							color: '#262626',
						}}
					>
						Recent
					</div>
				),
				type: 'group',
				children: recent.map((item) => ({
					key: item.name,
					label: (
						<span style={{ display: 'flex', justifyContent: 'space-between' }}>
							<a
								style={{ width: '80%', paddingTop: 2 }}
								target="_blank"
								onClick={(item) =>
									this.selectSearch(Object.entries(item.target)[1][1].children)
								}
							>
								{item.name}
							</a>
							<div style={{ color: COLORS.lightGray, paddingBottom: 3 }}>
								<a onClick={() => this.saveSearch(item, 'erin-jobs-save')}>
									<Tooltip placement="top" title="Save Search">
										<PushpinOutlined />
									</Tooltip>
								</a>
								<a
									style={{ marginLeft: 7 }}
									onClick={() =>
										this.saveSearch(item, 'erin-jobs-delete-recent')
									}
								>
									<Tooltip placement="top" title="Delete Search">
										<CloseOutlined />
									</Tooltip>
								</a>
							</div>
						</span>
					),
				})),
			});
		}

		if (recent.length > 0 && saved.length > 0) {
			menuItems.push({ type: 'divider' });
		}

		if (saved.length > 0) {
			menuItems.push({
				label: (
					<div
						style={{
							fontWeight: 600,
							marginLeft: 0,
							fontSize: 14,
							color: '#262626',
						}}
					>
						Saved
					</div>
				),
				type: 'group',
				children: saved.map((item) => ({
					key: item.name,
					label: (
						<span style={{ display: 'flex', justifyContent: 'space-between' }}>
							<a
								style={{ width: '80%', paddingTop: 2 }}
								target="_blank"
								onClick={(item) =>
									this.selectSearch(Object.entries(item.target)[1][1].children)
								}
							>
								{item.name}
							</a>
							<div style={{ color: COLORS.lightGray, paddingBottom: 3 }}>
								<a
									onClick={() =>
										this.saveSearch(item, 'erin-jobs-delete-saved')
									}
								>
									<Tooltip placement="top" title="Delete Saved">
										<CloseOutlined />
									</Tooltip>
								</a>
							</div>
						</span>
					),
				})),
			});
		}

		const menuConfig = {
			items: menuItems,
		};

		return recent.length > 0 || saved.length > 0 ? (
			<Dropdown menu={menuConfig}>{object}</Dropdown>
		) : (
			object
		);
	}

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	async componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		const { currentUser, allMultiLingualData } = this.props;
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');

			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		const hideJobsPage = get(
			this.props,
			'currentUser.company.hideJobsPage',
			false
		);
		const displayAs = get(this.props, 'currentUser.displayAs');
		if (hideJobsPage && displayAs === 'employee') {
			window.location.href = '/dashboard';
		}

		this.getCurrencyRate(
			this.props.currentUser && this.props.currentUser.currency
				? this.props.currentUser.currency
				: 'USD'
		);
		const distance = window.localStorage.getItem('setWithInDistance');
		if (distance === null) {
			const localLocation = window.localStorage.getItem(
				'jobLocationCoordinate'
			);
			const currentUserLocation = parse(
				get(this.props, 'currentUser.location')
			);
			if (localLocation !== undefined && localLocation !== null) {
				const userLocalLocation = parse(localLocation);
				if (
					Object.keys(userLocalLocation).length > 0 &&
					userLocalLocation.city !== undefined &&
					userLocalLocation.state
				) {
					const location = await getLocation({
						city: userLocalLocation.city,
						state: userLocalLocation.state,
					});
					this.setState(location);
				}
			} else if (
				currentUserLocation &&
				Object.keys(currentUserLocation).length > 0 &&
				currentUserLocation.city !== undefined &&
				currentUserLocation.city !== null &&
				currentUserLocation.state !== undefined &&
				currentUserLocation.state !== null
			) {
				let lat = '';
				let lng = '';
				lat = get(currentUserLocation, 'lat');
				lng = get(currentUserLocation, 'lng');
				if (lat && lng) {
					this.setState({
						city: currentUserLocation.city,
						state: currentUserLocation.state,
						lat,
						lng,
					});
				} else {
					const location = await getLocation({
						city: currentUserLocation?.city,
						state: currentUserLocation?.state,
					});
					this.setState(location);
				}
			}
		} else {
			const localLocation = parse(
				window.localStorage.getItem('jobLocationCoordinate')
			);
			const city = get(localLocation, 'city');
			const state = get(localLocation, 'state');
			this.setState({
				...localLocation,
				filteredDistance: distance
					? this.makeNewMenuOption(distance)
					: this.makeNewMenuOption(),
			});
			if (city && state) {
				this.updateFilteredJobsByLocation(
					this.makeNewMenuOption(distance),
					localLocation
				);
			}
		}

		this.intervalId = setInterval(this.handleResize, 100);
		const searchQuery = get(this.props, 'jobSearchCriteria');
		// Handle new jobs search on browse/new route
		let searchParameters;
		let jobNotificationSetting = _.get(
			this.props,
			'currentUser.jobNotificationSetting'
		);
		jobNotificationSetting &&= parse(jobNotificationSetting);
		if (window.location.pathname === '/browsejobs/new') {
			const extendedCompaniesData = get(
				currentUser,
				'extendedCompaniesData',
				[]
			);
			const extendedCompanies = extendedCompaniesData.filter(
				(company) => get(company, 'disableExtendedUserJobsView') !== true
			);
			const isExtendedUser = get(currentUser, 'role') === 'extendedUser';
			const comps =
				extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
					? extendedCompanies
					: [get(this.props, 'currentUser.company')];
			const companies = comps.map((company) => get(company, 'id'));
			const departments = get(
				jobNotificationSetting,
				'departmentSelection',
				[]
			).map((item) => item.key);
			const subCompanies = get(
				jobNotificationSetting,
				'subcompanySelection',
				[]
			).map((item) => item.key);
			const salary = get(jobNotificationSetting, 'salarySelection');
			this.setState({
				filteredDate: 14,
				filteredDepartments: departments,
				filteredSubCompanies: subCompanies,
				...(salary && { filteredSalary: salary }),
				searchQuery: '',
			});
			this.setFilterTitle();
			searchParameters = {
				currentUser,
				filters: {
					companies,
					departments,
					status: 'open',
					subCompanies,
					dateCreated: {
						from: subtractDays(new Date(), 14),
						to: new Date().toISOString(),
					},
				},
				query: '',
				role: currentUser?.role,
				size: 300,
			};
			if (salary) searchParameters.filters.salary = salary;
		}

		await this.setQueryToState(
			get(this.state, 'searchQuery', ''),
			searchParameters
		);
		await this.setJobsGeneralQueryToState();

		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}

		if (window.localStorage.getItem('isLocationPopoverVisible') === null) {
			if (this.state.filteredDistance === 'allLocations') {
				window.localStorage.setItem('isLocationPopoverVisible', 'false');
			} else {
				this.setState({ isLocationPopoverVisible: true }, () => {
					window.localStorage.setItem('isLocationPopoverVisible', 'false');
				});
			}
		}
	}

	filterJobs = (property, match, jobSet = []) => {
		return jobSet.filter((job) => get(job, property) === match);
	};

	handleResize = () => {
		const containerWidth = this.containerRef.current
			? this.containerRef.current.offsetWidth
			: 0;
		if (containerWidth === 0 && this.resizeAttempts < 50) {
			this.resizeAttempts++;
			return;
		}

		if (containerWidth > 0 && this.intervalId) {
			clearInterval(this.intervalId);
		}

		if (this.state.onlyShowHot) {
			this.setState({ pageSizeHotJobs: 24 });
		} else if (
			containerWidth < 1310 &&
			containerWidth > 901 &&
			window.innerWidth > 1000
		) {
			// Don't ask me why this is where our breakpoint is, but it is
			this.setState({ pageSizeHotJobs: 3 });
		} else {
			this.setState({ pageSizeHotJobs: 4 });
		}
	};

	updateFilteredJobsByLocation = async (filteredDistance, newLocation) => {
		const { lat, lng, city, state, country, zip } = this.state;
		const location = newLocation || {
			lat,
			lng,
			city,
			state,
			country,
			zip,
		};
		const { searchQuery } = this.state;
		if (filteredDistance) {
			this.setState({ filteredDistance }, async () => {
				if (city && state && lat && lng && country) {
					const jobLocationCoordinate = location;
					window.localStorage.setItem(
						'jobLocationCoordinate',
						JSON.stringify(jobLocationCoordinate)
					);
					this.setState(
						{ ...jobLocationCoordinate },
						async () => await this.setQueryToState(searchQuery)
					);
				} else if (city && state) {
					const jobLocationCoordinate = await getLocation({ city, state });
					window.localStorage.setItem(
						'jobLocationCoordinate',
						JSON.stringify(jobLocationCoordinate)
					);
					this.setState(
						{ ...jobLocationCoordinate },
						async () => await this.setQueryToState(searchQuery)
					);
				} else if (zip) {
					const jobLocationCoordinate = await getLocation({ zip });
					window.localStorage.setItem(
						'jobLocationCoordinate',
						JSON.stringify(jobLocationCoordinate)
					);
					this.setState(
						{ ...jobLocationCoordinate },
						async () => await this.setQueryToState(searchQuery)
					);
				} else if (lat && lng) {
					await this.setQueryToState(searchQuery);
				}
			});
		}
	};

	handleFilterDepartment = (department) => {
		let { filteredDepartments, searchQuery } = this.state;
		if (filteredDepartments.includes(department)) return;
		filteredDepartments = [...filteredDepartments, department];
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveDepartmentFilter = (department) => {
		const { filteredDepartments, searchQuery = '' } = this.state;
		const index = filteredDepartments.indexOf(department);
		filteredDepartments.splice(index, 1);
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterOpenStatus = () => {
		const filterOpenStatus =
			this.state.filterOpenStatus === 'open' ? 'closed' : 'open';
		this.setState(
			{
				filterOpenStatus,
				text: this.state.text === 'Open Jobs' ? 'Closed Jobs' : 'Open Jobs',
			},
			async () => await this.setQueryToState(this.state.searchQuery)
		);
	};

	handleFilterAvailable = (option) => {
		const { searchQuery } = this.state;
		this.setState(
			{
				filteredAvailables: [option],
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveAvailableFilter = (option) => {
		const { filteredAvailables, searchQuery } = this.state;
		const index = filteredAvailables.indexOf(option);
		filteredAvailables.splice(index, 1);
		this.setState(
			{
				filteredAvailables,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterCompany = (company, key) => {
		let { filteredCompanies = [], searchQuery = '' } = this.state;
		if (filteredCompanies.includes(company)) return;
		filteredCompanies = [...filteredCompanies, company];
		this.setState(
			{
				filteredCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveCompanyFilter = (company) => {
		const { filteredCompanies = [], searchQuery } = this.state;
		const index = filteredCompanies
			.map((company) => company.name)
			.indexOf(company);
		filteredCompanies.splice(index, 1);
		this.setState(
			{
				filteredCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterSalary = (filteredSalary) => {
		const { searchQuery = '' } = this.state;
		this.setState(
			{
				filteredSalary,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterDate = (filteredDate) => {
		const { searchQuery = '' } = this.state;
		this.setState(
			{
				filteredDate,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterBonus = (bonus) => {
		let { filteredBonuses = [], searchQuery = '' } = this.state;
		if (filteredBonuses.includes(bonus)) return;
		filteredBonuses = [...filteredBonuses, bonus];
		this.setState(
			{
				filteredBonuses,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveBonusFilter = (bonus) => {
		const { filteredBonuses = [], searchQuery = '' } = this.state;
		const index = filteredBonuses.indexOf(bonus);
		filteredBonuses.splice(index, 1);
		this.setState(
			{
				filteredBonuses,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterSubCompany = (subCompany, a) => {
		let { filteredSubCompanies = [], searchQuery = '' } = this.state;
		if (filteredSubCompanies.includes(subCompany)) return;
		filteredSubCompanies = [...filteredSubCompanies, subCompany];
		this.setState(
			{
				filteredSubCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveSubCompanyFilter = (subCompany) => {
		const { filteredSubCompanies = [], searchQuery = '' } = this.state;
		const index = filteredSubCompanies.indexOf(subCompany);
		filteredSubCompanies.splice(index, 1);
		this.setState(
			{
				filteredSubCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterJobLevel = (jobLevel, data) => {
		let { filteredJobLevels = [], searchQuery = '' } = this.state;
		const jobLvl = get(data, 'props.jobLevel');
		if (filteredJobLevels.map((b) => b.id).includes(jobLevel)) return;
		filteredJobLevels = [...filteredJobLevels, jobLvl];
		this.setState(
			{
				filteredJobLevels,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveJobLevelFilter = (jobLevel) => {
		const { filteredJobLevels = [], searchQuery = '' } = this.state;
		const index = filteredJobLevels.map((b) => b.id).indexOf(jobLevel);
		filteredJobLevels.splice(index, 1);
		this.setState(
			{
				filteredJobLevels,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	hideModal = () => {
		this.setState({ isLocationPopoverVisible: false });
	};

	handleFilterLocation = (distance) => {
		window.localStorage.setItem(
			'setWithInDistance',
			this.makeOldMenuOption(distance)
		);
		if (distance === 'allLocations') {
			this.handleRemoveLocationFilter();
		} else {
			this.updateFilteredJobsByLocation(this.makeNewMenuOption(distance));
		}
	};

	handleRemoveLocationFilter = () => {
		const { searchQuery } = this.state;
		this.setState(
			{
				filteredDistance: 'allLocations',
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	makeNewMenuOption = () => {
		let distance = window.localStorage.getItem('setWithInDistance');
		if (distance === null) {
			distance =
				this.props.currentUser?.defaultDistance || null
					? this.props.currentUser.defaultDistance
					: this.props.currentUser?.company?.defaultLocation || null
						? this.props.currentUser.company.defaultLocation
						: 'allLocations';
		}

		if (distance === 'allLocations' || distance == '0') {
			return 'allLocations';
		}

		if (isNaN(distance / 1)) {
			return 'myCountry';
		}

		if (distance == '-1') {
			return 'remote';
		}

		if (distance == '25') {
			return 'within25';
		}

		if (distance == '50') {
			return 'within50';
		}

		if (distance == '100') {
			return 'within100';
		}

		if (distance == '200') {
			return 'within200';
		}
	};

	makeOldMenuOption = (value) => {
		if (value === 'myCountry') {
			return this.state?.country || 'US';
		}

		if (value === 'remote') {
			return '-1';
		}

		if (value === 'within25') {
			return '25';
		}

		if (value === 'within50') {
			return '50';
		}

		if (value === 'within100') {
			return '100';
		}

		if (value === 'within200') {
			return '200';
		}

		return '0';
	};

	renderArrowIcon = () => {
		return (
			<div>
				<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
			</div>
		);
	};

	renderFilters = () => {
		const { isExtendedUser, searchQuery, address } = this.state;
		const { jobLevels = [], subCompanies = [] } = this.props;
		const defaultValue =
			this.state.filteredDistance || this.makeNewMenuOption();

		const extendedCompaniesData = get(
			this.props,
			'currentUser.extendedCompaniesData',
			[]
		);
		const extendedCompanies = extendedCompaniesData.filter(
			(company) => get(company, 'disableExtendedUserJobsView') !== true
		);
		const companies =
			extendedCompanies &&
			get(this.props, 'currentUser.role') === 'extendedUser'
				? extendedCompanies
				: [get(this.props, 'currentUser.company')];

		const comps = _.sortBy(companies, ['name']);
		const companyOptions = comps.map((company) => {
			return (
				<Option key={get(company, 'id')} value={get(company, 'id')}>
					{get(company, 'name')}
				</Option>
			);
		});
		const subComps = _.sortBy(subCompanies, ['name']);
		const jobLevs = _.sortBy(jobLevels, ['name']);
		const unit =
			get(this.props, 'currentUser.userGroup.measurement', 'imperial') ===
			'metric'
				? 'km'
				: 'mi';
		const Unit =
			unit === 'km'
				? 'km'
				: ml('miles', this.props.currentUser, this.props.allMultiLingualData);
		const Within = capitalizeFirstLetter(
			ml('within', this.props.currentUser, this.props.allMultiLingualData)
		);

		const tooltipData = (
			<>
				<h4>Viewing Nearby Jobs</h4>

				<p>
					You're currently viewing near your set location. Change the filter to
					see all!
				</p>
				<Button block type="primary" onClick={this.hideModal}>
					Got It
				</Button>
			</>
		);

		return (
			<div className="filter-wrap">
				{this.displaySearchFilter(
					<SearchComponent
						loading={this.state.loading}
						searchQuery={searchQuery}
						setQueryToState={this.setQueryToState}
						placeholder={ml(
							'Search by location, job name and/or keywords',
							this.props.currentUser,
							this.props.allMultiLingualData
						)}
					/>
				)}
				<div />
				<Popover
					open={
						!this.state.loading &&
						this.state.isLocationPopoverVisible &&
						defaultValue !== 'allLocations'
					}
					placement="topLeft"
					content={tooltipData}
					overlayClassName="viewing-nearby"
				>
					<Select
						showArrow
						defaultValue={defaultValue}
						placeholder="Location"
						options={[
							{
								value: 'allLocations',
								label: ml(
									'All Locations',
									this.props.currentUser,
									this.props.allMultiLingualData
								),
							},
							{
								value: 'myCountry',
								label: ml(
									'My Country',
									this.props.currentUser,
									this.props.allMultiLingualData
								),
							},
							{
								value: 'remote',
								label: ml(
									'Remote',
									this.props.currentUser,
									this.props.allMultiLingualData
								),
							},
							{
								value: 'within25',
								label: `${Within} 25 ${Unit}`,
							},
							{
								value: 'within50',
								label: `${Within} 50 ${Unit}`,
							},
							{
								value: 'within100',
								label: `${Within} 100 ${Unit}`,
							},
							{
								value: 'within200',
								label: `${Within} 200 ${Unit}`,
							},
						]}
						onSelect={(distance) => this.handleFilterLocation(distance)}
						onDeselect={(distance) => this.handleRemoveLocationFilter(distance)}
					/>
				</Popover>
				<Button type="link" className="location-btn" onClick={this.showModal}>
					{address === '' ? (
						<>
							<span className="location-circle" />
							{ml(
								'Set location',
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
						</>
					) : (
						<div>
							<span className="location-circle" />
							{address}
						</div>
					)}
				</Button>

				{(!isExtendedUser ||
					(jobLevels && jobLevels.length > 0) ||
					(subCompanies && subCompanies.length > 0) ||
					(extendedCompanies && extendedCompanies.length > 1)) && (
					<Button type="link" onClick={() => this.setFilterTitle()}>
						{this.state.filterTitle}
					</Button>
				)}

				{isExtendedUser && companyOptions && companyOptions.length > 1 && (
					<div className={SelectContainer} style={{ marginLeft: -13 }}>
						<div
							className={iconContainer}
							style={{
								position: 'relative',
								left: '230px',
								top: '26px',
							}}
						>
							<WebIcon color={COLORS.darkGray} size={14} name="sort-down" />
						</div>
						<Select
							filterOption={(input, option) => {
								return option.props.children
									.toLowerCase()
									.includes(input.toLowerCase());
							}}
							className={SelectStyles}
							mode="multiple"
							placeholder={ml(
								`${this.state.subCompanyLabel}`,
								this.props.currentUser,
								this.props.allMultiLingualData
							)}
							onSelect={(company, { key }) =>
								this.handleFilterCompany(company, key)
							}
							onDeselect={(company, { key }) =>
								this.handleRemoveCompanyFilter(company, key)
							}
						>
							{companyOptions}
						</Select>
					</div>
				)}
			</div>
		);
	};

	setFilterTitle = () => {
		this.setState({
			filterTitle:
				this.state.filterTitle === 'Show Filters'
					? 'Hide Filters'
					: 'Show Filters',
			visibleAdvance: this.state.filterTitle === 'Show Filters',
		});
	};

	setSearch = (searchedJobs = []) => {
		let { allJobs = [] } = this.state;
		if (searchedJobs) {
			allJobs =
				searchedJobs.length > allJobs.length
					? [...searchedJobs, ...allJobs]
					: allJobs;
			this.setState({ searchedJobs, loading: false });
		}
	};

	setQueryToState = async (
		searchQuery = '',
		searchParameters = null,
		overrideState = null,
		searchedJobsPagination = null,
		searchedHotJobsPagination = null
	) => {
		const { updateCurrentUserSearchCriteria, currentUser, setCurrentUser } =
			this.props;
		const state = overrideState ? overrideState : this.state;
		let {
			filteredDistance = 'allLocations',
			filterOpenStatus = 'open',
			lat = null,
			lng = null,
			filteredAvailables = [],
			filteredBonuses = [],
			filteredCompanies = [],
			filteredDate = 0,
			filteredDepartments = [],
			filteredJobLevels = [],
			filteredSalary = undefined,
			filteredSubCompanies = [],
		} = state;
		const dateParameter = filteredDate
			? {
					dateCreated: {
						from: subtractDays(new Date(), filteredDate),
						to: new Date().toISOString(),
					},
				}
			: {};
		const filterCompanies = state?.filteredCompanies || [];
		const extendedCompaniesData =
			this.props?.currentUser?.extendedCompaniesData || [];
		const extendedCompanies = extendedCompaniesData.filter(
			(company) => company?.disableExtendedUserJobsView !== true
		);
		const isExtendedUser = this.props?.currentUser?.role === 'extendedUser';
		const comps =
			extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
				? extendedCompanies
				: [this.props?.currentUser?.company];
		const companies = comps.map((company) => get(company, 'id'));
		filteredCompanies =
			filterCompanies.length > 0 ? filterCompanies : companies;
		const unit =
			this.props?.currentUser?.userGroup?.measurement === 'metric'
				? 'km'
				: 'mi';
		clearTimeout(this.timer);
		this.setState({ searchQuery });
		updateCurrentUserSearchCriteria(searchQuery);
		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			let from = 0;
			let size = 300;
			if (searchedJobsPagination) {
				from = searchedJobsPagination?.next?.from;
				size = searchedJobsPagination?.next?.size;
				if (from + size > 9999) size = 9999 - from;
			}

			let parameters = {
				query: searchQuery,
				from,
				size,
				coordinates: [{ lat, lng }],
				role: this.props?.currentUser?.role,
				currentUser: this.props?.currentUser?.id,
				filters: {
					availables: filteredAvailables,
					bonuses: filteredBonuses,
					companies: filteredCompanies,
					departments: filteredDepartments,
					distance: this.makeOldMenuOption(filteredDistance),
					salary: filteredSalary,
					status: filterOpenStatus,
					subCompanies: filteredSubCompanies,
					jobLevels: filteredJobLevels,
					isHotJob: false,
					...(filteredDate && dateParameter),
				},
				unit,
			};
			if (searchParameters) parameters = searchParameters;
			let response = null;
			if (from + size < 10_000)
				response = await searchOpenSearchNetwork(parameters, 'erin-jobs');

			parameters.filters.isHotJob = true;
			if (searchedHotJobsPagination) {
				from = searchedHotJobsPagination?.next?.from;
				size = searchedHotJobsPagination?.next?.size;
				if (from + size > 9999) size = 9999 - from;
				parameters.size = size;
				parameters.from = from;
			} else {
				from = 0;
				size = 300;
				parameters.size = size;
				parameters.from = from;
			}

			let hotJobResponse = null;
			if (from + size < 10_000)
				hotJobResponse = await searchOpenSearchNetwork(parameters, 'erin-jobs');

			if (response?.search) {
				currentUser.search = response.search;
				setCurrentUser(currentUser);
			}

			if (
				get(response, 'query') === get(this.state, 'searchQuery') ||
				get(hotJobResponse, 'query') === get(this.state, 'searchQuery')
			) {
				let searchedJobs = searchedJobsPagination
					? [...this.state.searchedJobs, ...get(response, 'data', [])]
					: get(response, 'data', []);
				const page = response?.metadata?.page;
				const totalResults = get(response, 'metadata.showing.totalItems', 0);
				const totalHotJobResults = get(
					hotJobResponse,
					'metadata.showing.totalItems',
					0
				);
				const filterOn =
					filteredDistance !== 'allLocations' || searchQuery.length > 0;

				searchedJobs = filterOn
					? searchedJobs || []
					: _.orderBy(
							searchedJobs || [],
							['isHotJob', 'dateCreated'],
							['desc', 'desc']
						);

				const searchedHotJobs = searchedHotJobsPagination
					? [...this.state.searchedHotJobs, ...get(hotJobResponse, 'data', [])]
					: get(hotJobResponse, 'data', []);
				const hotJobPage = hotJobResponse?.metadata?.page;
				const input = {
					loading: false,
				};
				if (searchedJobs) input.searchedJobs = searchedJobs;
				if (page) input.searchedJobsPagination = page;
				if (searchedHotJobs) input.searchedHotJobs = searchedHotJobs;
				if (hotJobPage) input.searchedHotJobsPagination = hotJobPage;
				if (totalResults) input.totalResults = totalResults;
				if (totalHotJobResults) input.totalHotJobResults = totalHotJobResults;
				if (!searchedJobsPagination) input.currentJobsPage = 1;
				if (!searchedHotJobsPagination) input.currentHotJobsPage = 1;
				searchedHotJobsPagination = null;
				this.setState(input);
			} else {
				this.setState({ loading: false });
			}
		}, 700);
	};

	loadMoreResults = async () => {
		const {
			searchedJobs = [],
			searchedJobsPagination,
			searchedHotJobsPagination = null,
			searchQuery = '',
		} = this.state;
		if (searchedJobs.length < 10_000)
			await this.setQueryToState(
				searchQuery,
				null,
				null,
				searchedJobsPagination,
				searchedHotJobsPagination
			);
	};

	loadMoreHotJobsResults = async () => {
		const {
			searchedHotJobs = [],
			searchedJobsPagination,
			searchedHotJobsPagination,
			searchQuery = '',
		} = this.state;
		if (searchedHotJobs.length < 10_000)
			await this.setQueryToState(
				searchQuery,
				null,
				null,
				searchedJobsPagination,
				searchedHotJobsPagination
			);
	};

	setJobsGeneralQueryToState = async () => {
		const { lat, lng } = this.state;
		const filterCompanies = get(this.state, 'filteredCompanies', []);
		const extendedCompaniesData = get(
			this.props,
			'currentUser.extendedCompaniesData',
			[]
		);
		const extendedCompanies = extendedCompaniesData.filter(
			(company) => get(company, 'disableExtendedUserJobsView') !== true
		);
		const isExtendedUser =
			get(this.props, 'currentUser.role') === 'extendedUser';
		const comps =
			extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
				? extendedCompanies
				: [get(this.props, 'currentUser.company')];
		const companies = comps.map((company) => get(company, 'id'));
		const filteredCompanies =
			filterCompanies.length > 0 ? filterCompanies : companies;

		const unit =
			get(this.props, 'currentUser.userGroup.measurement', 'imperial') ===
			'metric'
				? 'km'
				: 'mi';
		this.timerGeneralJobs = setTimeout(async () => {
			const parameters = {
				query: '',
				size: 300,
				coordinates: [{ lat, lng }],
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					companies: filteredCompanies,
					status: 'open',
					isGeneralReferral: true,
				},
				unit,
			};
			const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
			if (get(response, 'query') === '') {
				const searchedJobs = get(response, 'data', []);
				if (searchedJobs) {
					const filteredJobsGeneral = searchedJobs;
					this.setState({
						filteredJobsGeneral,
					});
				}
			}
		}, 700);
	};

	async getCurrencyRate(currency) {
		const response = await fetch(
			`https://api.exchangeratesapi.io/latest?base=USD&symbols=${currency}`
		);
		const result = await response.json();
		if (result.error) {
			// Make this USD
			this.setState({
				currencyRate: 1,
				currencySymbol: '$',
			});
		} else {
			const rate = result.rates[currency].toFixed(5);
			const symbol = getSymbolFromCurrency(currency)
				? getSymbolFromCurrency(currency)
				: '$';
			this.props.setCurrentCurrencyRate(rate);
			this.props.setCurrentCurrencySymbol(symbol);
			this.setState({
				currencyRate: rate,
				currencySymbol: symbol,
			});
		}
	}

	showModal = () => {
		this.setState({ visible: true });
	};

	showModalGeneral = () => {
		this.setState({ visibleGeneral: true });
	};

	handleCancelGeneral = () => {
		this.setState({ visibleGeneral: false });
	};

	handleSubmit = async (jobLocationCoordinate) => {
		try {
			if (
				get(jobLocationCoordinate, 'zip') &&
				!get(jobLocationCoordinate, 'lat') &&
				!get(jobLocationCoordinate, 'lng')
			)
				jobLocationCoordinate = await getLocation({
					zip: jobLocationCoordinate.zip,
				});

			window.localStorage.setItem(
				'jobLocationCoordinate',
				JSON.stringify(jobLocationCoordinate)
			);
			this.setState(
				{
					...jobLocationCoordinate,
					success: true,
				},
				() => this.handleFilterLocation(this.state.filteredDistance)
			);
		} catch (error) {
			console.log(error);
		}
	};

	handleCancel = () => {
		this.setState({ visible: false });
	};

	ShowAllToggle = () => {
		const { onlyShowHot } = this.state;
		if (onlyShowHot) {
			this.setState({ onlyShowHot: !onlyShowHot }, () => {
				this.handleResize();
			});
		} else {
			this.setState({ onlyShowHot: !onlyShowHot, pageSizeHotJobs: 24 });
		}
	};

	setUpdatedContacts = (newContact) => {
		this.setState((prevState) => ({
			updatedContacts: [...prevState.updatedContacts, newContact], // Add the newContact to the updatedContacts array
		}));
	};

	render() {
		const {
			allMultiLingualData,
			currentUser,
			onUpdateJob,
			contacts,
			location,
			onCreateReferral,
			onCreateUserJobShare,
			userJobShares,
			onUpdateUserJobShare,
			button,
			referPage,
		} = this.props;
		const {
			success,
			visible,
			filteredDistance,
			company,
			currencyRate,
			currencySymbol,
			currentJobsPage = 1,
			currentHotJobsPage = 1,
			extendedNetworkUsers,
			filterOpenStatus,
			searchedHotJobs,
			isExtendedUser,
			loading,
			searchedJobs,
			contactsNextToken,
			companyData,
			visibleGeneral,
			contact,
			onDeckContacts,
			filteredJobsGeneral,
			onlyShowHot,
			hideSubcompanyOnJobs,
			subCompanyLabel,
			filteredAvailables,
			filteredJobLevels,
			filteredSubCompanies,
			filteredDate,
			filteredDepartments,
			filteredBonuses,
			filteredSalary,
			hideBonusFilter,
			text,
			errorImageURL,
			pageSizeHotJobs,
			totalResults,
			totalHotJobResults,
		} = this.state;
		const contactIncentiveBonus = get(
			this.props,
			'currentUser.company.contactIncentiveBonus'
		);
		const allowSelfReferrals = get(
			this.props,
			'currentUser.company.allowSelfReferrals'
		);
		const allowSelfReferralsInternalLink = get(
			this.props,
			'currentUser.company.allowSelfReferralsInternalLink'
		);
		const jobFilterSettings = this.props.currentUser?.company?.jobFilterSettings ? JSON.parse(this.props.currentUser.company.jobFilterSettings) : {}
		const whiteLabel = get(company, 'whiteLabel');
		const errorImage = get(company, 'errorImage.key', false);
		const enableGeneralReferrals = get(
			currentUser,
			'company.enableGeneralReferrals',
			true
		);
		if (
			(!searchedJobs || searchedJobs.length === 0) &&
			!loading &&
			window.location.pathname === '/browsejobs/new'
		)
			window.location.href = '/browsejobs';
		return get(currentUser, 'company') ? (
			<main>
				<div className="page-top-filter">
					{this.renderFilters()}

					{button ? (
						this.props.currentUser.displayAs === 'admin' &&
						enableGeneralReferrals ? (
							referPage == 'referralDetails' ? (
								<Button
									type="link"
									className="add-btn btn-lg"
									onClick={this.showModal}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									<span>Add General Referral</span>
								</Button>
							) : (
								<Button
									type="link"
									className="add-btn btn-lg"
									onClick={this.showModal}
								>
									<span className="icon-circle">
										<i className="icon-plus" />
									</span>
									<span>Add General Referral</span>
								</Button>
							)
						) : null
					) : enableGeneralReferrals &&
					  currentUser.role !== USER_ROLES.EXTENDED_USER ? (
						<Button
							type="link"
							className="add-btn btn-lg"
							onClick={this.showModalGeneral}
						>
							<span className="icon-circle">
								<i className="icon-plus" />
							</span>
							{ml('Submit General Referral', currentUser, allMultiLingualData)}
						</Button>
					) : null}

					{visibleGeneral && (
						<OnDeckReferralModal
							visible={visibleGeneral}
							currentUser={this.props.currentUser}
							contact={contact}
							contacts={contacts}
							handleCancel={this.handleCancelGeneral}
							referSomeoneText={ml(
								'Refer Someone',
								currentUser,
								allMultiLingualData
							)}
							doYouKnowText={ml(
								'Do you know someone who would thrive at',
								currentUser,
								allMultiLingualData
							)}
							submitThemText={ml(
								'Submit them to be considered for new opportunities.',
								currentUser,
								allMultiLingualData
							)}
							enterReferralText={ml(
								"Enter a referral and we'll send them information to apply.",
								currentUser,
								allMultiLingualData
							)}
							enterReferralInformationText={ml(
								'Enter Referral Information',
								currentUser,
								allMultiLingualData
							)}
							firstNameText={ml('First Name', currentUser, allMultiLingualData)}
							lastNameText={ml('Last Name', currentUser, allMultiLingualData)}
							emailText={ml('Email Address', currentUser, allMultiLingualData)}
							textPlaceHolderText={ml('Text', currentUser, allMultiLingualData)}
							orText={ml('or', currentUser, allMultiLingualData)}
							clickHereText={ml('click here', currentUser, allMultiLingualData)}
							toAddExistingContactText={ml(
								'to add an existing contact',
								currentUser,
								allMultiLingualData
							)}
							includeMessageToContactText={ml(
								'Include a message to your contact',
								currentUser,
								allMultiLingualData
							)}
							optionalText={ml('optional', currentUser, allMultiLingualData)}
							personalizeMessageText={ml(
								'Personalize the message to your referral',
								currentUser,
								allMultiLingualData
							)}
							messageHiringContactText={ml(
								'Message the Hiring Contact',
								currentUser,
								allMultiLingualData
							)}
							howKnowThemText={ml(
								'How do you know them, why are they a good fit, etc.',
								currentUser,
								allMultiLingualData
							)}
							clickHereResumeText={ml(
								'Click here',
								currentUser,
								allMultiLingualData
							)}
							attachResumeText={ml(
								'to attach a resume',
								currentUser,
								allMultiLingualData
							)}
							submitReferralText={ml(
								'Submit Referral',
								currentUser,
								allMultiLingualData
							)}
							companyData={companyData}
							allMultiLingualData={allMultiLingualData}
							filteredJobs={filteredJobsGeneral}
							onDeckContacts={onDeckContacts}
						/>
					)}
				</div>
				{this.state.visibleAdvance ? (
					<AdvanceFilters
						allMultiLingualData={allMultiLingualData}
						currentUser={currentUser}
						filterOpenStatus={filterOpenStatus}
						jobFilterSettings = {jobFilterSettings}
						handleFilterOpenStatus={this.handleFilterOpenStatus}
						handleFilterSubCompany={this.handleFilterSubCompany}
						handleRemoveSubCompanyFilter={this.handleRemoveSubCompanyFilter}
						handleFilterCompany={this.handleFilterCompany}
						handleRemoveCompanyFilter={this.handleRemoveCompanyFilter}
						handleFilterJobLevel={this.handleFilterJobLevel}
						handleRemoveJobLevelFilter={this.handleRemoveJobLevelFilter}
						handleFilterAvailable={this.handleFilterAvailable}
						handleRemoveAvailableFilter={this.handleRemoveAvailableFilter}
						handleFilterBonus={this.handleFilterBonus}
						handleRemoveBonusFilter={this.handleRemoveBonusFilter}
						handleFilterDepartment={this.handleFilterDepartment}
						handleRemoveDepartmentFilter={this.handleRemoveDepartmentFilter}
						handleFilterSalary={this.handleFilterSalary}
						handleFilterDate={this.handleFilterDate}
						renderArrowIcon={this.renderArrowIcon}
						isExtendedUser={isExtendedUser}
						subCompanyLabel={subCompanyLabel}
						filteredSubCompanies={filteredSubCompanies}
						allowSelfReferralsInternalLink={allowSelfReferralsInternalLink}
						filteredAvailables={filteredAvailables}
						filteredJobLevels={filteredJobLevels}
						filteredDate={filteredDate}
						filteredDepartments={filteredDepartments}
						filteredBonuses={filteredBonuses}
						filteredSalary={filteredSalary}
						hideBonusFilter={hideBonusFilter}
						jobLevels={this.props?.jobLevels}
						subCompanies={this.props?.subCompanies}
						bonuses={this.props?.bonuses}
						departments={this.props?.departments}
						text={text}
					/>
				) : null}

				{searchedHotJobs && searchedHotJobs.length > 0 ? (
					<>
						<div className="page-sub-title">
							<h4 className="sub-title">
								{ml('HOT JOBS', currentUser, allMultiLingualData)}
							</h4>
							<Button type="link" onClick={this.ShowAllToggle}>
								{searchedHotJobs.length >= 3 ? (
									onlyShowHot ? (
										<span style={{ color: COLORS.red }}>
											{ml('Collapse', currentUser, allMultiLingualData)}
										</span>
									) : (
										ml('Show All', currentUser, allMultiLingualData)
									)
								) : null}
							</Button>
						</div>
						<div ref={this.containerRef} style={{ marginBottom: '16px' }}>
							<List
								className="job-card-wrap"
								dataSource={searchedHotJobs}
								grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
								// pagination={
								// 	searchedHotJobs.length > 3
								// 		? {
								// 				pageSize: pageSizeHotJobs,
								// 				position: 'bottom',
								// 				pagination: false,
								// 				showSizeChanger: false,
								// 			}
								// 		: false
								// }
								pagination={
									searchedHotJobs.length > 3
										? {
												current: currentHotJobsPage,
												pageSize: pageSizeHotJobs,
												position: 'bottom',
												showSizeChanger: false,
												onChange: async (index, pageSize) => {
													if (
														index * pageSize >=
															searchedHotJobs.length - pageSize &&
														searchedHotJobs.length < totalHotJobResults
													)
														await this.loadMoreHotJobsResults();
													this.setState({ currentHotJobsPage: index });
												},
											}
										: false
								}
								renderItem={(job) => (
									<List.Item key={get(job, 'id')} style={{ height: '100%' }}>
										<JobCard
											key={get(job, 'id', 'skip')}
											allMultiLingualData={allMultiLingualData}
											excludeJob={this.excludeJob}
											isExtendedUser={isExtendedUser}
											extendedNetworkUsers={extendedNetworkUsers}
											navLocation={location}
											jobId={get(job, 'id', 'skip')}
											job={job}
											contactIncentiveBonus={contactIncentiveBonus}
											contacts={this.state.updatedContacts}
											setUpdatedContacts={this.setUpdatedContacts}
											contactsNextToken={contactsNextToken}
											currentUser={this.props.currentUser}
											allowSelfReferrals={allowSelfReferrals}
											currentCurrencyRate={currencyRate}
											currentCurrencySymbol={currencySymbol}
											currentUserCompany={companyData}
											referSomeoneText={ml(
												'Refer Someone',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											enterReferralText={ml(
												'We will send them information to apply.',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											enterReferralInformationText={ml(
												'Enter Referral Information',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											firstNameText={ml(
												'First Name',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											lastNameText={ml(
												'Last Name',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											emailText={ml(
												'Email Address',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											textPlaceHolderText={ml(
												'Text',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											orText={ml(
												'or',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											clickHereText={ml(
												'click here',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											toAddExistingContactText={ml(
												'to add an existing contact',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											includeMessageToContactText={ml(
												'Include a message to your contact',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											optionalText={ml(
												'optional',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											personalizeMessageText={ml(
												'Personalize the message to your referral',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											messageHiringContactText={ml(
												'Message the Recruiter',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											howKnowThemText={ml(
												'How do you know them, why are they a good fit, etc.',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											clickHereResumeText={ml(
												'Click here',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											attachResumeText={ml(
												'to attach a resume',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											submitReferralText={ml(
												'Submit Referral',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											referContactText={ml(
												'Refer Contact',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											MakeReferralText={ml(
												'Make Referral',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											referralBonusText={ml(
												'Referral Bonus',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											referAContactText={ml(
												'Refer a Contact',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											startTypingText={ml(
												"Start typing a contact's name...",
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											importContactsText={ml(
												'import contacts',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											toEnterNameAndEmailText={ml(
												'to enter name and email',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											interestedText={ml(
												'Interested?',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											letUsKnowText={ml(
												'Let us know that you are interested in this position and we will be in touch.',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											submitMeText={ml(
												'Submit Me For This Position',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											successText={ml(
												'Success. Your information has been submitted for this position.',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											closeThisWindowText={ml(
												'Close This Window',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											iAmInterestedText={ml(
												"I'm Interested",
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											shareText={ml(
												'Share',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											shareJobText={ml(
												'Share Job',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											shareOnText={ml(
												'Share on',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											referralLinkText={ml(
												'Referral link',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											contactEmailText={ml(
												'A contact with this',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											alreadyReferredText={ml(
												'already exists.',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											alreadyReferredReferralFormText={ml(
												'This person has already been referred for this position.',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											clickHereReferralFormText={ml(
												'Click here',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											toReferReferralFormText={ml(
												'to refer them or',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											viewReferralFormText={ml(
												'view',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											contactReferralFormText={ml(
												'the contact.',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											troubleReferralFormText={ml(
												'We are having trouble submitting the form. Please make sure the form is filled out correctly and try again.',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											allowSelfReferralsInternalLink={
												allowSelfReferralsInternalLink
											}
											userJobShares={userJobShares}
											hideSubcompanyOnJobs={hideSubcompanyOnJobs}
											onUpdateJob={onUpdateJob}
											onCreateReferral={onCreateReferral}
											onCreateUserJobShare={onCreateUserJobShare}
											onUpdateUserJobShare={onUpdateUserJobShare}
										/>
									</List.Item>
								)}
							/>
							<h4
								className="sub-title"
								style={{
									fontWight: 600,
									fontSize: 14,
									textAlign: 'center',
									margin: 'auto',
									marginTop: 4,
								}}
							>
								{`Showing ${searchedHotJobs.length} of ${totalHotJobResults} `}
								{totalHotJobResults === 0 || totalHotJobResults > 1
									? ml(
											'Hot Jobs',
											this.props.currentUser,
											this.props.allMultiLingualData
										)
									: ml(
											'Hot Job',
											this.props.currentUser,
											this.props.allMultiLingualData
										)}
								{totalHotJobResults > 10_000 && (
									<Tooltip
										arrowPointAtCenter
										placement="topRight"
										title={ml(
											'This search returns more than the limit of 10000 results. Please refine your search criteria in order to view all of the results.',
											this.props.currentUser,
											this.props.allMultiLingualData
										)}
										style={{ fontWeight: 1000 }}
									>
										<InfoCircleOutlined
											style={{
												padding: 5,
												cursor: 'pointer',
												color: '#1890ff',
											}}
										/>
									</Tooltip>
								)}
							</h4>
						</div>
					</>
				) : null}

				{onlyShowHot ? null : (
					<>
						{searchedJobs && searchedJobs.length > 0 ? (
							<>
								<div className="page-sub-title">
									{
										searchedHotJobs && searchedHotJobs.length > 0 ? (
											<h4 className="sub-title">
												{ml('ALL JOBS', currentUser, allMultiLingualData)}
											</h4>
										) : (
											<div />
										) // Helps keep jobs total on right when ALL JOBS isn't displayed
									}
								</div>
								<List
									className="job-card-wrap"
									grid={{
										gutter: 16,
										xs: 1,
										sm: 1,
										md: 2,
										lg: 2,
										xl: 3,
										xxl: 4,
									}}
									pagination={{
										current: currentJobsPage,
										pageSize: 24,
										showSizeChanger: false,
										onChange: async (index, pageSize) => {
											if (
												index * pageSize >= searchedJobs.length - pageSize &&
												searchedJobs.length < totalResults
											)
												await this.loadMoreResults();
											this.setState({ currentJobsPage: index });
										},
									}}
									dataSource={searchedJobs}
									renderItem={(job) => {
										const status = get(job, 'status', 'open');
										return status === filterOpenStatus && get(job, 'id') ? (
											<List.Item style={{ height: '100%' }}>
												<JobCard
													key={get(job, 'id', 'skip')}
													allMultiLingualData={allMultiLingualData}
													excludeJob={this.excludeJob}
													isExtendedUser={isExtendedUser}
													extendedNetworkUsers={extendedNetworkUsers}
													navLocation={location}
													jobId={get(job, 'id', 'skip')}
													job={job}
													contactIncentiveBonus={contactIncentiveBonus}
													contacts={this.state.updatedContacts}
													setUpdatedContacts={this.setUpdatedContacts}
													contactsNextToken={contactsNextToken}
													currentUser={this.props.currentUser}
													allowSelfReferrals={allowSelfReferrals}
													currentCurrencyRate={currencyRate}
													currentCurrencySymbol={currencySymbol}
													currentUserCompany={companyData}
													referSomeoneText={ml(
														'Refer Someone',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													enterReferralText={ml(
														'We will send them information to apply.',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													enterReferralInformationText={ml(
														'Enter Referral Information',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													firstNameText={ml(
														'First Name',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													lastNameText={ml(
														'Last Name',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													emailText={ml(
														'Email Address',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													textPlaceHolderText={ml(
														'Text',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													orText={ml(
														'or',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													clickHereText={ml(
														'click here',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													toAddExistingContactText={ml(
														'to add an existing contact',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													includeMessageToContactText={ml(
														'Include a message to your contact',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													optionalText={ml(
														'optional',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													personalizeMessageText={ml(
														'Personalize the message to your referral',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													messageHiringContactText={ml(
														'Message the Recruiter',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													howKnowThemText={ml(
														'How do you know them, why are they a good fit, etc.',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													clickHereResumeText={ml(
														'Click here',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													attachResumeText={ml(
														'to attach a resume',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													submitReferralText={ml(
														'Submit Referral',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													referContactText={ml(
														'Refer Contact',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													MakeReferralText={ml(
														'Make Referral',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													referralBonusText={ml(
														'Referral Bonus',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													referAContactText={ml(
														'Refer a Contact',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													startTypingText={ml(
														"Start typing a contact's name...",
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													importContactsText={ml(
														'import contacts',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													toEnterNameAndEmailText={ml(
														'to enter name and email',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													interestedText={ml(
														'Interested?',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													letUsKnowText={ml(
														'Let us know that you are interested in this position and we will be in touch.',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													submitMeText={ml(
														'Submit Me For This Position',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													successText={ml(
														'Success. Your information has been submitted for this position.',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													closeThisWindowText={ml(
														'Close This Window',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													iAmInterestedText={ml(
														"I'm Interested",
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													shareText={ml(
														'Share',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													shareJobText={ml(
														'Share Job',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													shareOnText={ml(
														'Share on',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													referralLinkText={ml(
														'Referral link',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													contactEmailText={ml(
														'A contact with this',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													alreadyReferredText={ml(
														'already exists.',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													alreadyReferredReferralFormText={ml(
														'This person has already been referred for this position.',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													clickHereReferralFormText={ml(
														'Click here',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													toReferReferralFormText={ml(
														'to refer them or',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													viewReferralFormText={ml(
														'view',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													contactReferralFormText={ml(
														'the contact.',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													troubleReferralFormText={ml(
														'We are having trouble submitting the form. Please make sure the form is filled out correctly and try again.',
														this.props.currentUser,
														this.props.allMultiLingualData
													)}
													allowSelfReferralsInternalLink={
														allowSelfReferralsInternalLink
													}
													userJobShares={userJobShares}
													hideSubcompanyOnJobs={hideSubcompanyOnJobs}
													onUpdateJob={onUpdateJob}
													onCreateReferral={onCreateReferral}
													onCreateUserJobShare={onCreateUserJobShare}
													onUpdateUserJobShare={onUpdateUserJobShare}
												/>
											</List.Item>
										) : (
											<div />
										);
									}}
								/>
								<h4
									className="sub-title"
									style={{
										fontWight: 600,
										fontSize: 14,
										textAlign: 'center',
										margin: 'auto',
										marginTop: 4,
									}}
								>
									{`Showing ${searchedJobs.length} of ${totalResults}`}
									{totalResults === 0 || totalResults > 1
										? ' ' +
											ml(
												'Jobs',
												this.props.currentUser,
												this.props.allMultiLingualData
											)
										: ' ' +
											ml(
												'Job',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
									{totalResults > 10_000 && (
										<Tooltip
											arrowPointAtCenter
											placement="topRight"
											title={ml(
												'This search returns more than the limit of 10000 results. Please refine your search criteria in order to view all of the results.',
												this.props.currentUser,
												this.props.allMultiLingualData
											)}
											style={{ fontWeight: 1000 }}
										>
											<InfoCircleOutlined
												style={{
													padding: 5,
													cursor: 'pointer',
													color: '#1890ff',
												}}
											/>
										</Tooltip>
									)}
								</h4>
							</>
						) : (!searchedJobs || searchedJobs.length === 0) && !loading ? (
							<div className="no-content">
								{whiteLabel ? (
									<img
										src={errorImageURL}
										alt="error image"
										className="no-content-icon"
									/>
								) : (
									<img
										alt="erin-logo"
										className="no-content-icon"
										src={fileIcon}
									/>
								)}

								<p className="no-content-text">No jobs found </p>
							</div>
						) : loading ? (
							<Spinner company={company} />
						) : (
							<div className="no-content">
								{whiteLabel ? (
									<img
										src={errorImageURL}
										alt="error image"
										className="no-content-icon"
									/>
								) : (
									<img
										alt="erin-logo"
										className="no-content-icon"
										src={fileIcon}
									/>
								)}
								<p className="no-content-text">No jobs found </p>
							</div>
						)}
					</>
				)}
				<BrowseJobModal
					{...this.state}
					currentUser={currentUser}
					open={visible}
					handleCancel={this.handleCancel}
					handleSubmit={this.handleSubmit}
					success={success}
					filteredDistance={filteredDistance}
					updateFilteredJobsByLocation={this.updateFilteredJobsByLocation}
					changeLocationtext={ml(
						'Change Job Search Location',
						this.props.currentUser,
						this.props.allMultiLingualData
					)}
					autoFillText={ml(
						'Autofill Location',
						this.props.currentUser,
						this.props.allMultiLingualData
					)}
				/>
			</main>
		) : (
			<Spinner />
		);
	}
}

export default withApollo(BrowseJobsComponent);
