import get from 'lodash/get';
import { connect } from 'react-redux';
import { withGetTieredBonus } from 'src/_shared/api/components/tiered-bonuses';
import { compose } from '../../_shared/services/utils';
import BonusValueComponent from './BonusValueComponent.jsx';

const mapStateToProperties = (state, ownProperties) => {
	const { currentUser } = state.user;
	const amount = get(ownProperties, 'referral.bonus.amount', null);
	const referralBonus = get(
		ownProperties,
		'referral.job.referralBonus.tieredBonusId',
		get(ownProperties, 'referral.bonus.tieredBonusId')
	);
	return {
		amount,
		tieredBonusQuery: {
			companyId: currentUser.companyId,
			id: referralBonus ? referralBonus : 'skip',
		},
		currentUser,
	};
};

export default connect(mapStateToProperties)(
	compose(withGetTieredBonus)(BonusValueComponent)
);
