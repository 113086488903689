import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Checkbox, Input, Select, message } from 'antd';
import dompurify from 'dompurify';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { queryEditEmailsByCompanyId } from 'src/_shared/api/graphql/custom/edit-emails/';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { logout, parse } from 'src/_shared/services/utils';
import { COLORS } from '../_shared/styles/colors';
import { JobNotification } from './job-notification';

const sanitizer = dompurify.sanitize;

const { Option } = Select;
class EditEmailsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			theme: parse(get(props, 'currentUser.company.theme', '{}')),
			error: false,
			errorMessage: '',
			emailSettings: parse(get(this.props, 'company.emailSettings')),
			emailsText: [],
			isExisting: false,
			success: false,
			isOptoutReferralUpdates: get(
				props,
				'company.isOptoutReferralUpdates',
				false
			),
		};
	}

	async componentDidMount() {
		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		await this.getAllEmailsText();
	}

	async getAllEmailsText(
		policy = 'network-only',
		nextToken = null,
		allEmailsText = []
	) {
		const { client, currentUser } = this.props;
		try {
			const { data } = await client.query({
				query: gql(queryEditEmailsByCompanyId),
				variables: {
					companyId: get(currentUser, 'companyId'),
					after: nextToken,
				},
				fetchPolicy: policy,
			});
			const result = [...data.queryEditEmailsByCompanyId.items];
			const token = get(data, 'queryEditEmailsByCompanyId.nextToken', null);
			allEmailsText = [...allEmailsText, ...result];
			this.setState({
				emailsText: allEmailsText,
			});
			if (token) {
				await this.getAllEmailsText(policy, token, allEmailsText);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	// New Job Alert Email
	handleAlertEditorChange = (content, editor) => {
		this.setState({
			alertEmailText: content,
		});
	};

	handleApplicationEditorChange = (content, editor) => {
		this.setState({
			applicationEmailText: content,
		});
	};

	handleEditorChange = (content, editor) => {
		this.setState({
			acceptedEmailText: content,
		});
	};

	handleError = (errorMessage) => {
		this.setState({
			error: true,
			success: false,
			errorMessage,
		});
	};

	handleFooterEditorChange = (content, editor) => {
		this.setState({
			footerEmailText: content,
		});
	};

	handleIneligibleEditorChange = (content, editor) => {
		this.setState({
			ineligibleEmailText: content,
		});
	};

	// New Account Invitation Email
	handleInvitedEditorChange = (content, editor) => {
		this.setState({
			inviteEmailText: content,
		});
	};

	handleOnboardingEditorChange = (content, index) => {
		const emailSettings = get(this.state, 'emailSettings');
		const onboardingEmails = get(emailSettings, 'onboarding.inviteEmails');
		onboardingEmails[index].bodyText = content;
		emailSettings.onboarding.inviteEmails = onboardingEmails;
		this.setState({
			emailSettings,
		});
	};

	handleReferralUpdates = (e) => {
		const { onUpdateCompany, company } = this.props;
		if (e.target.checked) {
			this.setState(
				{
					isOptoutReferralUpdates: true,
				},
				() => {
					onUpdateCompany({
						input: {
							id: company.id,
							isOptoutReferralUpdates: true,
						},
					});
				}
			);
		} else {
			this.setState(
				{
					isOptoutReferralUpdates: false,
				},
				() => {
					onUpdateCompany({
						input: {
							id: company.id,
							isOptoutReferralUpdates: false,
						},
					});
				}
			);
		}
	};

	// You've Been Referred Email
	handleReferredEditorChange = (content, editor) => {
		this.setState({
			referredEmailText: content,
		});
	};

	handleSignatureEditorChange = (content, editor) => {
		this.setState({
			signatureEmailText: content,
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const {
			form,
			currentUser,
			onCreateEditEmails,
			onUpdateEditEmails,
			onUpdateCompany,
		} = this.props;
		const {
			emailsText,
			acceptedEmailText,
			applicationEmailText,
			updatedEmailText,
			ineligibleEmailText,
			alertEmailText,
			inviteEmailText,
			referredEmailText,
			welcomeEmailText,
			footerEmailText,
			signatureEmailText,
			emailSettings,
		} = this.state;
		form.validateFields((error, values) => {
			if (error) {
				this.handleError();
			} else {
				if (emailsText.length === 0) {
					const editEmailInput = {
						input: {
							companyId: get(currentUser, 'companyId'),
							referredEmailText,
							alertEmailText,
							inviteEmailText,
							acceptedEmailText,
							applicationEmailText,
							updatedEmailText,
							ineligibleEmailText,
							welcomeEmailText,
							footerEmailText,
							signatureEmailText,
						},
					};
					onCreateEditEmails(editEmailInput).then((res) => {
						this.setState({
							success: true,
						});
					});
				} else {
					const id = get(emailsText[0], 'id');
					const editEmailInput = {
						input: {
							id,
							companyId: get(currentUser, 'companyId'),
							referredEmailText,
							alertEmailText,
							inviteEmailText,
							acceptedEmailText,
							applicationEmailText,
							updatedEmailText,
							ineligibleEmailText,
							welcomeEmailText,
							footerEmailText,
							signatureEmailText,
						},
					};
					onUpdateEditEmails(editEmailInput).then((res) => {
						this.setState(
							{
								success: true,
							},
							() => {
								message.success('Changes are successfully submitted.');
							}
						);
					});
				}

				if (get(emailSettings, 'onboarding.inviteEmails', []).length > 0) {
					onUpdateCompany({
						input: {
							id: get(currentUser, 'companyId'),
							emailSettings: JSON.stringify(emailSettings),
						},
					});
				}
			}
		});
	};

	handleUpdatedEditorChange = (content, editor) => {
		this.setState({
			updatedEmailText: content,
		});
	};

	handleWelcomeEditorChange = (content, editor) => {
		this.setState({
			welcomeEmailText: content,
		});
	};

	insert_contents = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].acceptedEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_alert = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].alertEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_application = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].applicationEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_footer = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].footerEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_ineligible = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].ineligibleEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_invited = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].inviteEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_onboarding = async (inst, index) => {
		const emailSettings = get(this.state, 'emailSettings');
		const onboardingEmails = get(emailSettings, 'onboarding.inviteEmails');
		const data = get(onboardingEmails, `[${index}].bodyText`);
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_referred = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].referredEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_signature = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].signatureEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_updated = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].updatedEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	insert_contents_welcome = async (inst) => {
		const { CompanyEmailData } = this.props;
		const data = get(CompanyEmailData, '[0].welcomeEmailText', '');
		inst.setContent(data === null ? [] : sanitizer(data));
	};

	newTinyMsc = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents,
					max_width: '100%',
				}}
				onEditorChange={this.handleEditorChange}
			/>
		);
	};

	newTinyMscAlert = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents_alert,
					max_width: '100%',
				}}
				onEditorChange={this.handleAlertEditorChange}
			/>
		);
	};

	newTinyMscApplication = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents_application,
					max_width: '100%',
				}}
				onEditorChange={this.handleApplicationEditorChange}
			/>
		);
	};

	newTinyMscFooter = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents_footer,
					max_width: '100%',
				}}
				onEditorChange={this.handleFooterEditorChange}
			/>
		);
	};

	newTinyMscIneligible = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents_ineligible,
					max_width: '100%',
				}}
				onEditorChange={this.handleIneligibleEditorChange}
			/>
		);
	};

	newTinyMscInvited = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents_invited,
					max_width: '100%',
				}}
				onEditorChange={this.handleInvitedEditorChange}
			/>
		);
	};

	newTinyMscOnboarding = (index) => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: (instance) =>
						this.insert_contents_onboarding(instance, index),
					max_width: '100%',
				}}
				onEditorChange={(content) =>
					this.handleOnboardingEditorChange(content, index)
				}
			/>
		);
	};

	newTinyMscReferred = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents_referred,
					max_width: '100%',
				}}
				onEditorChange={this.handleReferredEditorChange}
			/>
		);
	};

	newTinyMscSignature = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents_signature,
					max_width: '100%',
				}}
				onEditorChange={this.handleSignatureEditorChange}
			/>
		);
	};

	newTinyMscUpdated = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents_updated,
					max_width: '100%',
				}}
				onEditorChange={this.handleUpdatedEditorChange}
			/>
		);
	};

	newTinyMscWelcome = () => {
		return (
			<Editor
				apiKey="folpz5iy2cdn4oje0s8vqakhxncbe6voeqvmbjk88k9127te"
				init={{
					height: 220,
					menubar: false,
					branding: false,
					plugins: ['image code'],
					toolbar:
						'formatselect |bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent  ',
					automatic_uploads: true,
					file_picker_types: 'image',
					init_instance_callback: this.insert_contents_welcome,
					max_width: '100%',
				}}
				onEditorChange={this.handleWelcomeEditorChange}
			/>
		);
	};

	renderOnboardingEmailEditors = () => {
		const FormItem = Form.Item;
		const emailSettings = get(this.state, 'emailSettings');
		const onboardingEmails = get(emailSettings, 'onboarding.inviteEmails', []);
		const htmlEmails = [];
		let subGroup = [];
		onboardingEmails.map((email, index) => {
			subGroup.push(email);
			if ((index + 1) % 2 === 0) {
				htmlEmails.push(subGroup);
				subGroup = [];
			} else if (index === onboardingEmails.length - 1) {
				htmlEmails.push(subGroup);
				subGroup = [];
			}
		});
		return htmlEmails.map((subGroup, index) => {
			const subText = 'This text will appear above the Get Started button.';
			return (
				<>
					<div className="customizations-box">
						<FormItem>
							<h4>
								{`Onboarding Email Invitation #${index + index + 1}`}
								{index > 0 && (
									<span
										style={{
											marginLeft: 10,
											color: COLORS.lightGray5,
											fontWeight: 400,
											fontSize: 16,
										}}
									>{`(Sends after ${get(subGroup, '[0].days')} days)`}</span>
								)}
							</h4>
							<p>{subText}</p>
							{this.newTinyMscOnboarding(index + index)}
						</FormItem>
					</div>
					{subGroup.length > 1 && (
						<div className="customizations-box">
							<FormItem>
								<h4>
									{`Onboarding Email Invitation #${index + index + 2}`}
									<span
										style={{
											marginLeft: 10,
											color: COLORS.lightGray5,
											fontWeight: 400,
											fontSize: 16,
										}}
									>{`(Sends after ${get(subGroup, '[1].days')} days)`}</span>
								</h4>
								<p>{subText}</p>
								{this.newTinyMscOnboarding(index + index + 1)}
							</FormItem>
						</div>
					)}
				</>
			);
		});
	};

	render() {
		const { currentUser, CompanyEmailData } = this.props;
		const { theme } = this.state;
		const { getFieldDecorator } = this.props.form;
		const { TextArea } = Input;
		const FormItem = Form.Item;
		const referredEmailValue = get(
			CompanyEmailData,
			'[0].referredEmailText',
			''
		);
		const alertEmailValue = get(CompanyEmailData, '[0].alertEmailText', '');
		const inviteEmailValue = get(CompanyEmailData, '[0].inviteEmailText', '');
		return get(currentUser, 'company') ? (
			<main>
				<JobNotification />
				<div className="page-title">
					<h2 className="page-heading">Referral Notifications</h2>
				</div>
				<div className="setting-card">
					<Form style={{ marginLeft: 10 }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Checkbox
								checked={this.state.isOptoutReferralUpdates}
								style={{ marginRight: 8 }}
								onChange={this.handleReferralUpdates}
							/>
							<h4
								className="setting-card-title"
								style={{ margin: 0, paddingTop: 1 }}
							>
								Require Employee Opt-In
							</h4>
						</div>
						<p style={{ marginLeft: 24 }}>
							Requires Employees to Opt-In for Referral and Bonus Updates.
						</p>
					</Form>
				</div>
				<div className="page-title">
					<h2 className="page-heading">Email Customizations</h2>
					<ul className="info-action">
						<li>
							<Button
								type="primary"
								size="large"
								className="btn-min-width"
								onClick={this.handleSubmit}
							>
								Save
							</Button>
						</li>
					</ul>
				</div>
				<section className="msg-notification">
					<Form>
						<div className="mn-customizations">
							<div className="customizations-box">
								<FormItem>
									<h4>You've Been Referred Email</h4>
									<p>
										This text will appear below the information about the job.
									</p>
									{this.newTinyMscReferred()}
								</FormItem>
							</div>
							<div className="customizations-box">
								<FormItem>
									<h4>New Job Alert Email</h4>
									<p>
										This text will appear below the information about the job.
									</p>
									{this.newTinyMscAlert()}
								</FormItem>
							</div>
							<div className="customizations-box">
								<FormItem>
									<h4>New Account Invitation Email</h4>
									<p>This text will appear above the Accept Invite button.</p>
									{this.newTinyMscInvited()}
								</FormItem>
							</div>
							<div className="customizations-box">
								<FormItem>
									<h4>A Referral Has Accepted Email</h4>
									<p>This text will appear in the referral accepted email.</p>

									{this.newTinyMsc()}
								</FormItem>
							</div>
							<div className="customizations-box">
								<FormItem>
									<h4>Your Application Has been Started</h4>
									<p>
										This text will appear in the your application has been
										started email.
									</p>

									{this.newTinyMscApplication()}
								</FormItem>
							</div>
							<div className="customizations-box">
								<FormItem>
									<h4>Your Referral Has been Updated Email</h4>
									<p>
										This text will appear in the your referral has been updated
										email.
									</p>
									{this.newTinyMscUpdated()}
								</FormItem>
							</div>
							<div className="customizations-box">
								<FormItem>
									<h4>Your Referral is Ineligible</h4>
									<p>
										This text will appear in your referral ineligible email.
									</p>

									{this.newTinyMscIneligible()}
								</FormItem>
							</div>
							<div className="customizations-box">
								<FormItem>
									<h4>Welcome Email</h4>
									<p>This text will appear in the your welcome email.</p>
									{this.newTinyMscWelcome()}
								</FormItem>
							</div>
							<div className="customizations-box">
								<FormItem>
									<h4>Email Footer</h4>
									<p>This text will appear in your email footer.</p>

									{this.newTinyMscFooter()}
								</FormItem>
							</div>
							<div className="customizations-box">
								<FormItem>
									<h4>Email Signature</h4>
									<p>This text will appear in the your email signature.</p>
									{this.newTinyMscSignature()}
								</FormItem>
							</div>
							{this.renderOnboardingEmailEditors()}
						</div>
					</Form>
				</section>
			</main>
		) : (
			<Spinner />
		);
	}
}
export default withApollo(Form.create()(EditEmailsComponent));
