import { GlobalOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { css } from 'emotion';
import { useState } from 'react';
import { getTextColorFromElementId } from '../../services/utils.js';

const languages = [
	{ code: 'US', label: 'English' },
	{ code: 'CS', label: 'Čeština' },
	{ code: 'DE', label: 'Deutsch' },
	{ code: 'ES', label: 'Español' },
	{ code: 'FR', label: 'Français' },
	{ code: 'FR-CA', label: 'Français (Canada)' },
	{ code: 'IT', label: 'Italiano' },
	{ code: 'HU', label: 'Magyar' },
	{ code: 'NL', label: 'Nederlands' },
	{ code: 'PT', label: 'Português' },
	{ code: 'PT-BR', label: 'Português (Brasil)' },
	{ code: 'TL', label: 'Tagalog' },
	{ code: 'TR', label: 'Türkçe' },
	{ code: 'RU', label: 'Русский' },
	{ code: 'ZH-CN', label: '简体中文' },
	{ code: 'JA', label: '日本語' },
];

function LanguageSelect(props) {
	const {
		onSelect = () => {},
		languageCode,
		showSearch = false,
		referralLanguage,
	} = props;

	const [selectedLanguage, setSelectedLanguage] = useState(
		languageCode
			? languages.find((lang) => lang.code === languageCode)
			: { code: 'US', label: 'English' }
	);

	const setLanguage = (code, label) => {
		setSelectedLanguage(languages.find((lang) => lang.code === code));
		onSelect(code);
	};

	const DoughnutStyle = css({
		border: `1.5px solid`,
		borderRadius: '50%',
		display: 'flex',
		flexDirection: 'row',
		height: '1em',
		width: '1em',
		alignSelf: 'center',
	});

	const OptionLabel = ({ label, languageCode }) => {
		const isSelected = selectedLanguage.code === languageCode;
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{isSelected ? <GlobalOutlined /> : <div className={DoughnutStyle} />}
				<span style={{ marginLeft: 5 }}>{label}</span>
			</div>
		);
	};

	return (
		<div
			className={`custom-form-group select-language ${
				referralLanguage ? 'referral' : ''
			}`}
		>
			<Select
				id="language-select"
				showSearch={showSearch}
				popupClassName={`${referralLanguage ? 'referral-language' : ''}`}
				value={selectedLanguage.code}
				style={{
					color: getTextColorFromElementId('language-select'),
					minWidth: 165,
				}}
				onSelect={(value, option) => setLanguage(value)}
			>
				{languages.map((lang) => (
					<Select.Option key={lang.code} value={lang.code}>
						<OptionLabel label={lang.label} languageCode={lang.code} />
					</Select.Option>
				))}
			</Select>
		</div>
	);
}

export default LanguageSelect;
