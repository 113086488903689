import { useState } from 'react';
import { message } from 'antd';
import MyrewardsPresentation from '../../presentation/myrewardsPresentation'
import { useRnRCommonService } from '../../../RnRCommonComponent/service/RnRCommon.service.jsx';

const MyrewardsContainer = () => {

  const [isViewRewardModalVisible, setIsViewRewardModalVisible] = useState(false);
  const [rewardDetails, setRewardDetails] = useState();
  const [isModalLoaderVisible, setIsModalLoaderVisible] = useState(false);

  const commonServiceAPI = useRnRCommonService();

  const handleOpenViewReward = async (id) => {
    try {
      setIsViewRewardModalVisible(true)
      setIsModalLoaderVisible(true);
      const response = await commonServiceAPI.getAdminIssuedBadge(id);
      setIsModalLoaderVisible(false);
      if (response?.code === 200) {
        if (response?.data) {
          setRewardDetails(response.data);
        }
      } else {
        message.error('Failed to fetch badge details! Try again later.');
      }
    } catch (error) {
      setIsModalLoaderVisible(false);
      console.log(error);
      message.error('Failed to fetch badge details! Try again later.');
    }
  }

  const handleCloseViewReward = () => { setIsViewRewardModalVisible(false) }

  return <MyrewardsPresentation
    handleOpenViewReward={handleOpenViewReward}
    handleCloseViewReward={handleCloseViewReward}
    isViewRewardModalVisible={isViewRewardModalVisible}
    rewardDetails={rewardDetails}
    isModalLoaderVisible={isModalLoaderVisible}
  />;
};

export default MyrewardsContainer;