import { Route } from 'react-router-dom';
import { RouterContext } from '../contexts';

export function ByPassauthenticatedRoute(props) {
	const { component: Component } = props;
	const rest = { ...props };
	delete rest.component;
	return (
		<RouterContext.Consumer>
			{({ auth }) => {
				return (
					<Route
						{...rest}
						render={(props) => {
							return <Component {...props} />;
						}}
					/>
				);
			}}
		</RouterContext.Consumer>
	);
}
