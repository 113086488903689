import 'antd/dist/antd.less';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { RouterContext } from './_shared/contexts';
import { parseJwt, query } from 'src/_shared/services/utils.js';
import { dashboardActions, userActions } from './actions.js';
import AppRouter from './app-router.component';
import { configureApp } from './app.config';
import ErrorHandler from './error-handler.jsx';
import store from './state.js';
import { persistor } from './store.js';
import './style.scss';
import ThemeProvider from './theme-provider.jsx';
import { APIServiceProvider } from './API.service.jsx';
import { RnRAPIServiceProvider } from './rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { RnRCommonServiceProvider } from './RnRCommonComponent/service/RnRCommon.service.jsx';

// Run configuration scripts
configureApp();

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			auth: false,
		};
		this.onAuthentication = this.onAuthentication.bind(this);
	}

	componentDidMount() {
		// Subscribe to the store. listen for when language code is changed somewhere in the app. When it is, get fresh translations by that new language code
		//
		let currentDisplayAs;
		store.subscribe(() => {
			const state = store.getState();
			let languageCode = get(state, 'user.currentUser.languageCode');
			let currentAllMultiLingualDataCode = state?.dashboard?.allMultiLingualData[0]?.languageCode;
			languageCode = languageCode ? languageCode : 'US';
			// Get translations if language other than English is chosen
			if (languageCode !== currentAllMultiLingualDataCode) {
				if (languageCode === 'US') {
					store.dispatch(
							dashboardActions.createSetMultiLingualData(
							[{languageCode: 'US'}]
						)
					);
				} else {
					(async () => {
						const getTranslationsByLanguageCode = async (token = null) => {
							const allTranslationsByLanguageCodeResponse = await query(
								{
									languageCode: languageCode,
									nextToken: token,
								},
								'queryMultiLingualByLanguageCodeIndex'
							);
							const allTranslationsByLanguageCode = get(
								allTranslationsByLanguageCodeResponse,
								'data',
								[]
							);
							const nextToken = get(
								allTranslationsByLanguageCodeResponse,
								'nextToken'
							);
							if (nextToken) {
								const moreTranslationsByLanguageCode =
									await getTranslationsByLanguageCode(nextToken);
								return [
									...allTranslationsByLanguageCode,
									...moreTranslationsByLanguageCode,
								];
							}

							return allTranslationsByLanguageCode;
						};

						const allTranslationsByLanguageCode =
							await getTranslationsByLanguageCode();
						store.dispatch(
							dashboardActions.createSetMultiLingualData(
								allTranslationsByLanguageCode
							)
						);
					})();
				}
			}

			// Set displayAs based on user's role during initial login
			// Skip this if it was a failed login attempt
			if (get(state, 'user.currentUser')) {
				const {
					user: {
						currentUser: { role },
					},
				} = store.getState();

				if (currentDisplayAs !== role) {
					currentDisplayAs = role;
					store.dispatch(userActions.updateDisplayAs(role));
				}
			}
		});
	}

	onAuthentication = (authToken, currentUser) => {
		try {
			Cookies.set('jwt', authToken, {
				secure: process.env.REACT_APP_INSECURE_COOKIES !== 'true',
			});
			this.setState({
				auth: true,
			});
		} catch (error) {
			console.log(error);
		}
	};

	render() {
		const samlData = get(this.props, 'samlData');
		let jwt = null;
		let providerValue = null;
		const safeParse = (key) => {
			try {
				return JSON.parse(window.localStorage.getItem(key));
			} catch {
				return null;
			}
		};

		const forceLogoutBefore = (jwtToken, refreshTime = 1_677_801_678_833) => {
			const parsedToken = parseJwt(jwtToken);
			if (parsedToken) {
				const lastAuthTime = get(parsedToken, 'auth_time', 0) * 1000;
				if (
					lastAuthTime - refreshTime <= 0 &&
					window.location.pathname !== '/logout' &&
					window.location.pathname !== '/login'
				)
					window.location.href = `${window.location.origin}/logout`;
			}
		};

		const getCachedJwt = () => {
			try {
				jwt = Cookies.get('jwt');
				forceLogoutBefore(jwt, 1_677_801_678_833);
				return jwt;
			} catch (error) {
				console.log(error);
			}
		};

		try {
			let currentUser = store.getState();
			currentUser = get(currentUser, 'user.currentUser');
			providerValue = {
				auth: getCachedJwt(),
				onAuthentication: this.onAuthentication,
				currentUser,
				handleSignOut: this.props.handleSignOut,

			};
		} catch (error) {
			console.log(error);
		}

		return (
			<RnRAPIServiceProvider>
				<RnRCommonServiceProvider>
					<APIServiceProvider>
						<ErrorHandler>
							<Provider store={store}>
								<ThemeProvider>
									<PersistGate loading={null} persistor={persistor}>
										<RouterContext.Provider value={providerValue}>
											<Router>
												<AppRouter
													auth={this.state.auth}
													samlData={samlData}
													secrets={get(this.props, 'secrets')}
												/>
											</Router>
										</RouterContext.Provider>
									</PersistGate>
								</ThemeProvider>
							</Provider>
						</ErrorHandler>
					</APIServiceProvider>
				</RnRCommonServiceProvider>
			</RnRAPIServiceProvider>
		);
	}
}

export default withApollo(App);
