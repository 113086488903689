import _, { get } from 'lodash';
import { Component } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { parse, query } from 'src/_shared/services/utils';
import NewReferralCard from './NewReferralGdprCardComponent.jsx';

// GDPR
class NewReferralPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: [],
			currentJob: null,
			referralQuestions: [],
			referredUser: null,
			referredByUserData: get(
				props,
				'webNotification.user',
				get(props, 'referredUser')
			),
			resumeAttachData: '',
		};
	}

	async componentDidMount() {
		const { params } = this.props;
		const id = get(params, 'webNotificationId')
			? params.webNotificationId
			: params.jobId;
		let currentJob;
		let referredUser;
		let webNotification;
		const userId = get(params, 'referredBy');
		if (userId) {
			referredUser = await query({ userId }, 'getUserData');
		}

		if (get(params, 'webNotificationId')) {
			webNotification = await query({ id }, 'getWebNotification');
			currentJob = get(webNotification, 'job');
			referredUser = get(webNotification, 'user');
		} else {
			currentJob = await query({ id }, 'getJob');
		}

		const questions = get(currentJob, 'company.referralQuestions', []);

		let allQuestionsSort = _.sortBy(questions, ['sortOrder']);
		const resumeAttachData = allQuestionsSort.filter(
			(item) => item.isCandidate === true && item.sortOrder === 0
		);
		allQuestionsSort = allQuestionsSort.filter((item) => item.sortOrder !== 0);
		const allQuestionsForCandidate = allQuestionsSort.filter(
			(item) => item.isCandidate === true
		);
		const referralQuestions = [];
		if (allQuestionsForCandidate.length > 0) {
			allQuestionsForCandidate.map((element) => {
				referralQuestions.push(parse(element.questions));
			});
		}

		this.setState({
			currentJob,
			referralQuestions,
			referredUser,
			resumeAttachData,
			webNotification,
		});
	}

	templateThis(value) {
		if (
			value !== undefined &&
			value.startsWith('null') === false &&
			value.startsWith('$null') === false
		) {
			return value;
		}

		return 'unavailable';
	}

	render() {
		const { currentJob, webNotification } = this.state;
		const referredByUserData = get(this.state, 'referredUser');
		const { referralQuestions, resumeAttachData } = this.state;
		const company = get(currentJob, 'company');
		const { lang } = this.props.match.params;
		const firstName = get(referredByUserData, 'firstName', '');
		const lastName = get(referredByUserData, 'lastName', '');
		const emailAddress = get(referredByUserData, 'emailAddress', '');
		const externalSource = get(currentJob, 'externalSource', '');
		let location = get(currentJob, 'location', '{}');
		location = JSON.parse(location);
		if (!currentJob) {
			return <Spinner forceDefault={true} />;
		}

		const salary = JSON.parse(
			currentJob?.salary ?? `{"from": "N/A", "to": "N/A"}`
		);
		const jobData = {
			companyId:
				get(currentJob, 'company.id', null) ||
				get(this.props, 'webNotification.companyId'),
			company: get(currentJob, 'company.name', 'N/A'),
			countryCode: get(referredByUserData, 'company.countryCode', 'US'),
			department: get(currentJob, 'department.name', 'N/A'),
			location:
				location.isRemote === true
					? 'Remote'
					: get(location, 'city', 'N/A') + ', ' + get(location, 'state', 'N/A'),
			salary: `$${get(salary, 'from', 'N/A')} ${
				salary.to
					? '- $' + (get(salary, 'to', 'N/A') || get(salary, 'toNull', 'N/A'))
					: ''
			} `,
			jobOverview: currentJob.description,
			referrer: `${firstName} ${lastName}`,
			referrerEmail: emailAddress,
			position: currentJob.title,
			jobType: currentJob.jobType,
			hiringEmail: get(currentJob, 'hiringManager.emailAddress', 'N/A'),
			jobUrl: get(currentJob, 'publicLink', null),
			jobId: get(currentJob, 'id'),
			userId: get(referredByUserData, 'id'),
			companyLogo: get(currentJob, 'company.logo', ''),
			brandColor: get(currentJob, 'company.brandColor', ''),
			jobTitle: get(currentJob, 'title', ''),
			atsIntegration: get(currentJob, 'company.atsIntegration', 'N/A'),
			externalSource,
			whiteLabel: get(currentJob, 'company.whiteLabel'),
			subCompany: get(currentJob, 'subCompany.name'),
			subCompanyLogo: get(currentJob, 'subCompany.logo'),
			status: currentJob?.status || null,
			languageCode: lang,
			theme: get(currentJob, 'company.theme'),
			hideRecruiterInfo: get(currentJob, 'company.hideRecruiterInfo', false),
			isGeneralReferral: get(currentJob, 'isGeneralReferral', false),
		};

		return (
			<NewReferralCard
				currentUser={get(this.props, 'currentUser', referredByUserData)}
				jobData={jobData}
				referredUser={get(this.props, 'referredUser', referredByUserData)}
				referralQuestions={referralQuestions}
				resumeAttachData={resumeAttachData}
				setCurrentUser={get(this.props, 'setCurrentUser')}
				webNotification={webNotification}
				onCreateContact={this.props.ImportedCreateContact}
				onCreateReferral={this.props.onCreateReferral}
				onUpdateContact={this.props.onUpdateContact}
				onUpdateWebNotification={this.props.onUpdateWebNotification}
			/>
		);
	}
}

export default NewReferralPage;
