import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { parse } from 'src/_shared/services/utils';
import { ONBOARDING_SCREENS, ONBOARDING_STATUS } from './constants.js';
import {
	BonusReporting,
	CreateYourBonus,
	GiftCardPayouts,
	HowToPayScreen,
	InviteYourEmployees,
	LearnMoreAdmin,
	SaasAgreementScreen,
	WelcomeScreen,
} from './screens';

function Onboarding(props) {
	const { company, onUpdateCompany } = props;

	let ob = parse(get(company, 'onboarding'));
	ob = ob
		? ob
		: {
				welcome: {
					page: 1,
					complete: false,
					data: null,
				},
				saasAgreement: {
					page: 2,
					complete: false,
					data: null,
				},
				howToPay: {
					page: 3,
					complete: false,
					data: null,
				},
				createBonuses: {
					page: 4,
					complete: false,
					data: null,
				},
				giftCardPayouts: {
					page: 5,
					complete: false,
					data: null,
				},
				bonusReporting: {
					page: 6,
					complete: false,
					data: null,
				},
				inviteEmployees: {
					page: 7,
					complete: false,
					data: null,
				},
				learnMoreAdmin: {
					page: 8,
					complete: false,
					data: null,
				},
				status: ONBOARDING_STATUS.INCOMPLETE,
			};

	const [activeScreen, setActiveScreen] = useState();
	const [onboarding, setOnboarding] = useState(ob);

	useEffect(() => {
		// Update the document title using the browser API
		setOnboardingScreen();
	}, []);

	const setOnboardingScreen = (screenName) => {
		let _nextScreen;

		_nextScreen = screenName ? screenName : getNextScreenToComplete(onboarding);

		setActiveScreen(_nextScreen);
	};

	const completeOnboardingPage = (screen, data, completeOnboarding) => {
		const newOnboarding = onboarding;
		newOnboarding[screen].complete = true;

		if (data) newOnboarding[screen].data = data;
		if (completeOnboarding) newOnboarding.status = 'complete';

		setOnboarding(newOnboarding);
		onUpdateCompany({
			input: {
				id: company?.id,
				onboarding: JSON.stringify(newOnboarding),
			},
		});
	};

	const uncompleteOnboardingPage = (currentScreen, data) => {
		const newOnboarding = onboarding;
		let currentPage = null;

		for (const [screen, vals] of Object.entries(newOnboarding)) {
			if (currentScreen === screen) currentPage = vals.page;
		}

		const previousPage_ = currentPage - 1;
		let previousScreen = '';

		for (const [screen, vals] of Object.entries(newOnboarding)) {
			if (vals.page === previousPage_) previousScreen = screen;
		}

		newOnboarding[previousScreen].complete = false;

		if (data) newOnboarding[previousScreen].data = data;

		setOnboarding(newOnboarding);

		onUpdateCompany({
			input: {
				id: company?.id,
				onboarding: JSON.stringify(newOnboarding),
			},
		});
	};

	const nextPage = (nextScreenName) => {
		const nextPageNumber = nextScreenName
			? onboarding[activeScreen].page + 1
			: onboarding[nextScreenName].page;

		if (nextPageNumber <= Object.keys(onboarding).length)
			setOnboardingScreen(nextPageNumber);
	};

	const previousPage = (previousScreenName) => {
		const previousPageNumber =
			previousScreenName === ''
				? onboarding[screen].page - 1
				: onboarding[nextScreenName].page;
		if (previousPageNumber > 0) setOnboardingScreen(previousPageNumber);
	};

	const navToScreen = (screenName) => {
		if (Object.keys(onboarding).includes(screenName)) {
			setOnboardingScreen(screenName);
		} else {
			console.log(
				`ERROR: ${screenName} is not a valid onboarding screen name >> ${Object.keys(
					onboarding
				)}`
			);
		}
	};

	const getNextScreenToComplete = (onboarding) => {
		if (onboarding[ONBOARDING_SCREENS.WELCOME].complete === false)
			return ONBOARDING_SCREENS.WELCOME;
		if (onboarding[ONBOARDING_SCREENS.SAAS_AGREEMENT].complete === false)
			return ONBOARDING_SCREENS.SAAS_AGREEMENT;
		if (onboarding[ONBOARDING_SCREENS.HOW_TO_PAY].complete === false)
			return ONBOARDING_SCREENS.HOW_TO_PAY;
		if (onboarding[ONBOARDING_SCREENS.CREATE_BONUSES].complete === false) {
			if (onboarding.howToPay.data.payBonusesWithPoints === true) {
				return ONBOARDING_SCREENS.GIFT_CARD_PAYOUTS;
			}

			return ONBOARDING_SCREENS.CREATE_BONUSES;
		}

		if (onboarding[ONBOARDING_SCREENS.BONUS_REPORTING].complete === false)
			return ONBOARDING_SCREENS.BONUS_REPORTING;
		if (onboarding[ONBOARDING_SCREENS.INVITE_EMPLOYEES].complete === false)
			return ONBOARDING_SCREENS.INVITE_EMPLOYEES;
		if (onboarding[ONBOARDING_SCREENS.LEARN_MORE_ADMIN].complete === false)
			return ONBOARDING_SCREENS.LEARN_MORE_ADMIN;
	};

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
			}}
		>
			{activeScreen === ONBOARDING_SCREENS.WELCOME && (
				<WelcomeScreen
					onboarding={onboarding}
					completeOnboardingPage={completeOnboardingPage}
					navToScreen={navToScreen}
					{...props}
				/>
			)}
			{activeScreen === ONBOARDING_SCREENS.SAAS_AGREEMENT && (
				<SaasAgreementScreen
					onboarding={onboarding}
					completeOnboardingPage={completeOnboardingPage}
					uncompleteOnboardingPage={uncompleteOnboardingPage}
					nextPage={nextPage}
					prevPage={previousPage}
					navToScreen={navToScreen}
					{...props}
				/>
			)}
			{activeScreen === ONBOARDING_SCREENS.HOW_TO_PAY && (
				<HowToPayScreen
					onboarding={onboarding}
					completeOnboardingPage={completeOnboardingPage}
					uncompleteOnboardingPage={uncompleteOnboardingPage}
					nextPage={nextPage}
					prevPage={previousPage}
					navToScreen={navToScreen}
					{...props}
				/>
			)}
			{activeScreen === ONBOARDING_SCREENS.GIFT_CARD_PAYOUTS && (
				<GiftCardPayouts
					onboarding={onboarding}
					completeOnboardingPage={completeOnboardingPage}
					uncompleteOnboardingPage={uncompleteOnboardingPage}
					nextPage={nextPage}
					prevPage={previousPage}
					navToScreen={navToScreen}
					{...props}
				/>
			)}
			{activeScreen === ONBOARDING_SCREENS.CREATE_BONUSES && (
				<CreateYourBonus
					onboarding={onboarding}
					completeOnboardingPage={completeOnboardingPage}
					uncompleteOnboardingPage={uncompleteOnboardingPage}
					nextPage={nextPage}
					prevPage={previousPage}
					navToScreen={navToScreen}
					{...props}
				/>
			)}
			{activeScreen === ONBOARDING_SCREENS.BONUS_REPORTING && (
				<BonusReporting
					onboarding={onboarding}
					completeOnboardingPage={completeOnboardingPage}
					uncompleteOnboardingPage={uncompleteOnboardingPage}
					nextPage={nextPage}
					prevPage={previousPage}
					navToScreen={navToScreen}
					{...props}
				/>
			)}
			{activeScreen === ONBOARDING_SCREENS.INVITE_EMPLOYEES && (
				<InviteYourEmployees
					onboarding={onboarding}
					completeOnboardingPage={completeOnboardingPage}
					uncompleteOnboardingPage={uncompleteOnboardingPage}
					nextPage={nextPage}
					prevPage={previousPage}
					navToScreen={navToScreen}
					{...props}
				/>
			)}

			{activeScreen === ONBOARDING_SCREENS.LEARN_MORE_ADMIN && (
				<LearnMoreAdmin
					onboarding={onboarding}
					completeOnboardingPage={completeOnboardingPage}
					uncompleteOnboardingPage={uncompleteOnboardingPage}
					nextPage={nextPage}
					prevPage={previousPage}
					{...props}
				/>
			)}
		</div>
	);
}

export default Onboarding;
