import React, { useEffect, useState } from 'react';
import ReactDOMServer from "react-dom/server";
import { message } from 'antd';
import AddReward from '../../presentation/addReward/AddReward';
import { CoreBadge } from '../../../RnRCommonComponent'
import { useManageRewardsService } from '../../services/manageRewards.service.jsx';

const AddRewardContainer = (props) => {

  const { isModalOpen, handleCloseModal, setShouldRefresh } = props;

  const [badgeDetailsObject, setBadgeDetailsObject] = useState({});
  const [badgeErrorObject, setBadgeErrorObject] = useState();
  const [iconsList, setIconsList] = useState([]);
  const [automationRules, setAutomationRule] = useState([]);
  const [badgeTypeList, setBadgeTypeList] = useState();
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [levelList, setLevelList] = useState();
  const [payTypeList, setPayTypeList] = useState();
  const [automation, setAutomation] = useState();
  const manageRewardService = useManageRewardsService();

  useEffect(() => {
    getBadgeTypesList();
    getIconsList();
    getLevelList();
    getPayTypeList();
    setBadgeErrorObject(null);
    getAutomationRules()
  }, []);

  const getLevelList = () => {
    manageRewardService.getEmployeeLevels().then((response) => {
      if (response?.code === 200) {
        if (response?.data) {
          setLevelList(response?.data);
        }
      } else {
        message.error('Failed to fetch badge type list! Try again later.');
      }
    }).catch((error) => {
      message.error('Failed to fetch badge type list! Try again later.');
    });
  };

  const getPayTypeList = () => {
    manageRewardService.getEmployeePayType().then((response) => {
      if (response?.code === 200) {
        if (response?.data) {
          setPayTypeList(response?.data);
        }
      } else {
        message.error('Failed to fetch badge type list! Try again later.');
      }
    }).catch((error) => {
      message.error('Failed to fetch badge type list! Try again later.');
    });
  };

  const getBadgeTypesList = () => {
    manageRewardService.getBadgeTypes().then((getBadgeTypesListRes) => {
      if (getBadgeTypesListRes?.code === 200) {
        if (getBadgeTypesListRes?.data) {
          setBadgeTypeList(getBadgeTypesListRes?.data);
        }
      } else {
        message.error('Failed to fetch badge type list! Try again later.');
      }
    }).catch((error) => {
      message.error('Failed to fetch badge type list! Try again later.');
    });
  };

  const getIconsList = () => {
    manageRewardService.getIcons().then((iconsListResponse) => {
      if (iconsListResponse?.code === 200) {
        if (iconsListResponse?.data) {
          setIconsList(iconsListResponse?.data);
        }
      } else {
        message.error('Failed to fetch icons list! Try again later.');
      }
    }).catch((error) => {
      message.error('Failed to fetch icons list! Try again later.');
    });
  };

  const getAutomationRules = () => {
    manageRewardService.getAutomationRule().then((automationRuleListResponse) => {
      if (automationRuleListResponse?.code === 200) {
        if (automationRuleListResponse?.data) {
          setAutomationRule(automationRuleListResponse?.data);
        }
      } else {
        message.error('Failed to fetch Automation Rules list! Try again later.');
      }
    }).catch(() => {
      message.error('Failed to fetch Automation Rules list! Try again later.');
    });
  };

  const handleCreateBadge = async () => {
    const requiredFields = [
      'name',
      'label',
      'description',
      'color',
    ];
    let hasEmptyField = false;
    let errorObject = badgeErrorObject && badgeErrorObject;

    for (const field of requiredFields) {
      if (
        !badgeDetailsObject[field] ||
        badgeDetailsObject[field]?.trim() === ''
      ) {
        errorObject = {
          ...errorObject,
          [field]: true,
        };

        hasEmptyField = true;
      }

    }
    //Validation of points 
    const pointsValue = parseInt(badgeDetailsObject.points);
    if (badgeDetailsObject.points === '' || isNaN(badgeDetailsObject.points) || pointsValue < 0 || pointsValue > 9999) {
      errorObject = {
        ...errorObject,
        points: true,
      };
      hasEmptyField = true;
    }

    setBadgeErrorObject(errorObject);

    if (hasEmptyField) {
      return;
    }

    const stringBadge = ReactDOMServer.renderToString(<CoreBadge
      badgeType={badgeDetailsObject.badgeTypeCode}
      badgeIcon={badgeDetailsObject.iconCode}
      label={badgeDetailsObject.label}
      color={badgeDetailsObject.color}
    />);

    // console.log(stringBadge);
    // return;
    setIsLoaderVisible(true);

    const base64Encoded = btoa(stringBadge);
    manageRewardService.createBadge({ ...badgeDetailsObject, points: badgeDetailsObject?.points || 0, automationRule: automation, image: base64Encoded }).then((badgesTypeCreateRes) => {
      if (badgesTypeCreateRes?.code === 201) {
        message.success('Badge created successfully');
        setShouldRefresh(true)
        handleCloseModal();
      } else {
        message.error(badgesTypeCreateRes?.message || 'Failed to create badge! Try again later.');
      }
      setIsLoaderVisible(false);
    }).catch((error) => {
      setIsLoaderVisible(false);
      message.error(badgesTypeCreateRes?.message || 'Failed to create badge! Try again later.');
    });
  };

  return <div>
    <AddReward
      iconsList={iconsList}
      badgeTypeList={badgeTypeList}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      handleCreateBadge={handleCreateBadge}
      badgeDetailsObject={badgeDetailsObject}
      setBadgeDetailsObject={setBadgeDetailsObject}
      badgeErrorObject={badgeErrorObject}
      setBadgeErrorObject={setBadgeErrorObject}
      isLoaderVisible={isLoaderVisible}
      levelList={levelList}
      payTypeList={payTypeList}
      automation={automation}
      setAutomation={setAutomation}
      automationRules={automationRules}
    />
  </div>;
};

export default AddRewardContainer;
