import { Alert, Card, Col, Modal, Row } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import get from 'lodash/get';
import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import dollorGreen from '../../_shared/assets/dollar-currency-green.png';
import dollorOrange from '../../_shared/assets/dollar-currency-orange.png';
import dollorRed from '../../_shared/assets/dollar-currency-red.png';
import MyBonusDetailsModal from '../../_shared/components/MyBonusDetailsModalComponent.jsx';
import { formatDate, ml } from '../../_shared/services/utils';
import { COLORS } from '../../_shared/styles/colors';
import {
	BonusAmount,
	BonusStatus,
	ModalStyles,
	candidateNameStyles,
	cardBefore,
	cardMainStyles,
	filterPayment,
	green,
	linkStyles,
	miscTextColStyles,
	miscTextStyles,
	orange,
	red,
	statusStyles,
	successStyles,
} from './myBonusCardStyles.js';

class BonusCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			resendSuccess: false,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			translatedTitle: '',
		};
	}

	async componentDidMount() {
		const title = get(this.props, 'referral.job.title');
		const newTitle = title;
		this.setState({
			translatedTitle: newTitle,
		});
	}

	async componentDidUpdate(prevProps) {
		if (
			prevProps.currentUser.languageCode !== this.props.currentUser.languageCode
		) {
			const title = get(this.props, 'referral.job.title');
			const newTitle = title;
			this.setState({
				translatedTitle: newTitle,
			});
		}
	}

	getPayment = (payment) => {
		const payString = payment === null ? '' : payment;
		const splitLink = payString.split('');
		const lastChar = splitLink.pop();
		const label = splitLink.join('');
		return (
			<span>
				{label} <span className={filterPayment}>{lastChar}</span>
			</span>
		);
	};

	formatBonusStatus = (status) => {
		const { currentUser, allMultiLingualData } = this.props;
		switch (status) {
			case 'pending': {
				return ml(`Pending`, currentUser, allMultiLingualData);
			}

			case 'earned': {
				return ml(`Eligible`, currentUser, allMultiLingualData);
			}

			case 'paid': {
				return ml(`Paid`, currentUser, allMultiLingualData);
			}

			case 'needsReview': {
				return ml(`Under Review`, currentUser, allMultiLingualData);
			}

			default: {
				return ml(`Ineligible`, currentUser, allMultiLingualData);
			}
		}
	};

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	navigateTo = (URL) => {
		this.props.history.push(URL);
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	render() {
		const {
			currentUser,
			referral,
			jobText,
			referredCandidateText,
			paymentText,
			totalBonusText,
			hiredDateText,
			startDateText,
			bonusPaymentsText,
			bonusNotesText,
			closeText,
			noResponseText,
			inActiveText,
			allMultiLingualData,
			eligibleDateText,
		} = this.props;
		const { bonusStatus, payment, amountDue, contact } = referral;

		const { theme } = this.state;
		const languageCode = get(this.props, 'currentUser.languageCode');
		const dateFormat = get(this.props, 'currentUser.dateFormat');
		const eligibleDate = get(this.props, 'referral.earnedDate');
		const { resendSuccess } = this.state;
		let userGroupCurrency = get(currentUser, 'userGroup.currency');
		if (userGroupCurrency === null) userGroupCurrency = 'USD';

		let formattedBonus = '';
		const bonus = amountDue;
		let currencySymbol = '';
		currencySymbol = getSymbolFromCurrency(userGroupCurrency);
		if (bonus > 0) {
			formattedBonus = `${bonus}`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
		}

		let colorClass = '';
		let symbol = '';
		let color = '';
		if (bonusStatus === 'pending') {
			colorClass = orange;
			symbol = dollorOrange;
			color = '#feb361';
		} else if (bonusStatus === 'earned' || bonusStatus === 'paid') {
			colorClass = green;
			symbol = dollorGreen;
			color = '#1f861d';
		} else {
			colorClass = red;
			symbol = dollorRed;
			color = '#ed3d3f';
		}

		return (
			<div>
				{resendSuccess && (
					<Alert
						className={successStyles}
						type="success"
						message="The referral was successfully sent"
					/>
				)}
				<Card
					hoverable
					bodyStyle={{ paddingBottom: 16 }}
					className={[cardMainStyles, cardBefore(symbol), colorClass].join(' ')}
					title={
						<Row type="flex" justify="space-between">
							<Col className={miscTextColStyles} style={{ textAlign: 'left' }}>
								{contact ? (
									<Link to={`/mycontacts/${contact.id}`}>
										<span className={candidateNameStyles}>
											{`${get(contact, 'firstName', '')} ${get(
												contact,
												'lastName',
												''
											)}`}
										</span>
										<span className={statusStyles}>
											{ml(`Referral`, currentUser, allMultiLingualData)}
										</span>
									</Link>
								) : null}
							</Col>
						</Row>
					}
				>
					<Row>
						<Col span={12}>
							<div>
								<h3
									className={BonusStatus(color)}
									style={{ fontSize: '1.25rem', fontWeight: '500' }}
								>
									{this.formatBonusStatus(bonusStatus)}
								</h3>
								{ml(`Payment`, currentUser, allMultiLingualData)}{' '}
								{this.getPayment(payment)}
							</div>
							<div>
								<a className={linkStyles} onClick={this.showModal}>
									{ml(`View Details`, currentUser, allMultiLingualData)}
								</a>
							</div>
						</Col>
						<Col span={12} style={{ textAlign: 'right' }}>
							<div>
								<h3 className={BonusAmount} style={{ fontSize: '1.25rem' }}>
									<div>
										{bonus > 0 ? `${currencySymbol}${formattedBonus}` : ''}
									</div>
								</h3>
								{bonusStatus !== 'ineligible' &&
									bonusStatus !== 'needsReview' && (
										<p className={miscTextStyles}>
											<span style={{ color: COLORS.lightGray }}>
												{ml(`Eligible on`, currentUser, allMultiLingualData)}
											</span>
											:{' '}
											<span style={{ color: COLORS.black }}>
												{formatDate(
													eligibleDate,
													languageCode,
													this.props.currentUser.dateFormat
												)}
											</span>
										</p>
									)}
							</div>
						</Col>
					</Row>
				</Card>
				<Modal
					navigateTo={this.navigateTo}
					className={ModalStyles}
					open={this.state.visible}
					footer={null}
					onCancel={this.handleCancel}
				>
					<MyBonusDetailsModal
						currentTieredBonus={this.props.currentTieredBonus}
						status={bonusStatus}
						userGroup={referral.user.userGroupId}
						data={referral}
						handleCancel={this.handleCancel}
						bonusDetailsText={ml(
							`Bonus Details`,
							currentUser,
							allMultiLingualData
						)}
						jobText={jobText}
						referredCandidateText={referredCandidateText}
						totalBonusText={totalBonusText}
						hiredDateText={hiredDateText}
						startDateText={startDateText}
						bonusPaymentsText={bonusPaymentsText}
						bonusNotesText={bonusNotesText}
						closeText={closeText}
						currentUser={currentUser}
						noResponseText={noResponseText}
						inActiveText={inActiveText}
						translatedTitle={this.state.translatedTitle}
						paymentText={paymentText}
						eligibleDateText={eligibleDateText}
					/>
				</Modal>
			</div>
		);
	}
}

export default withRouter(BonusCard);
