/** Packages */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'antd';

/** Shared Utils */
import { formatDate, ml } from '../../_shared/services/utils';

export function EmployeeInfo(props) {
	const {
		allMultilingualData,
		currentUser,
		edit,
		emailAddress,
		firstName,
		handleInput,
		lastLogin,
		lastLoginText,
		lastName,
		userEditSettings,
	} = props;
	const [currentMLData, setCurrentMlData] = useState(allMultilingualData);
	useEffect(() => {
		setCurrentMlData(allMultilingualData);
	}, [allMultilingualData]);

	const editFirstName = (
		<Input
			key="editName"
			id="name"
			name="editedName"
			placeholder="Name"
			value={firstName}
			className="custom-input"
			onChange={handleInput('editedFirstName')}
		/>
	);

	const editLastName = (
		<Input
			key="editLastName"
			id="lastname"
			name="editedLastName"
			placeholder="Last Name"
			value={lastName}
			className="custom-input"
			onChange={handleInput('editedLastName')}
		/>
	);

	return (
		<>
			{edit === true && !userEditSettings?.lockProfileFields.employeeName ? (
				<div className="edit-profile-name">
					<div className="po-details-edit custom-form-group block-details">
						<label>{ml('First Name', currentUser, currentMLData)}</label>
						{editFirstName}
					</div>
					<div className="po-details-edit custom-form-group block-details">
						<label>{ml('Last Name', currentUser, currentMLData)}</label>
						{editLastName}
					</div>
				</div>
			) : (
				<h4 className="profile-name">
					{firstName} {lastName}
				</h4>
			)}
			<div className="profile-email">
				<i className="icon-envelope-outline" style={{ fontSize: 18 }} />
				<Link to="#">{emailAddress}</Link>
			</div>
			{lastLogin ? (
				<p className="profile-other-details">
					<span className="label">{lastLoginText}: </span>
					{formatDate(
						lastLogin,
						currentUser?.languageCode,
						currentUser?.dateFormat
					)}
				</p>
			) : (
				<p>Last Login: Never</p>
			)}
		</>
	);
}
