import React, { useState, useEffect, useRef } from 'react';
import { AutoComplete } from 'antd';
import { placesAutocomplete, getLocation } from '../../services/utils.js';
import { timer } from 'd3';

 function PlacesAutocomplete(props) {
    const [address, setAddress] = useState(props?.address || '');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const placeholder = props?.placeholder || 'City, state, or zip code';
    const auth = props?.auth || 'cognito';
    const timerRef = useRef(null);

    const fetchLocations = async () => {
        setLoading(true);
        const data = await placesAutocomplete(address, auth);
        let options = data?.Results.map(result => { return {value: result?.Text, PlaceId: result?.PlaceId}});
        if (data?.Summary?.Text === address) setOptions(options);
        setLoading(false);
    }

    useEffect(
        () => {
            if(address.length > 0){
                timerRef.current = setTimeout(() => fetchLocations(address), 300);
                return () => {
                  clearTimeout(timerRef.current);
                };
            }
        },
        [address]
      );

    const onSelect = async (option, place) => {
        const loc = await getLocation({ place: place.PlaceId }, auth);
        if (props?.onSelect) props.onSelect(loc);
        setAddress(option);
        props.setAddress(option);
    }

    const onSearch = (item) => {
        if (props?.setAddress) setAddress(item);
        props.setAddress(item);
    }

	return (
             <AutoComplete
                className="custom-input"
                loading={loading}
                options={options}
                style={{ width: 400, ...props?.style }}
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder={placeholder}
                value={props?.address ? props.address : address}
            />
	);
}

export default PlacesAutocomplete;