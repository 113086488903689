import { List } from 'antd';
import _, { get, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
	formatDate,
	getSetErrorImageURL,
	ml,
} from 'src/_shared/services/utils';
import { queryReferralsByContactIdIndex } from '../_shared/api/graphql/custom/index.js';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import ErinFilterSelect from '../_shared/components/ErinFilterSelectComponent.jsx';
import { SearchComponent } from '../_shared/components/search';
import { USER_ROLES } from '../_shared/index.js';
import ProgressIndicator from './ProgressIndicatorComponent.jsx';
import queryReferralsByUserIdReferralTypeIndex from './listReferralsByUserIdReferralTypeGraphql.js';

function MyApplications({
	currentUser,
	client,
	allMultiLingualData,
	...props
}) {
	if (!get(currentUser, 'company')) return <Spinner />;
	const [selfReferrals, setSelfReferrals] = useState(null);
	const [searchedValue, setSearchedValue] = useState('');
	const [loading, setLoading] = useState(true);
	const [appliedFilters, setAppliedFilters] = useState([]);
	const [errorImageSource, setErrorImageSource] = useState('');

	// TODO:
	// Provide My Applications it's translations
	// Put in checks so that it doesn't break if accessing nested properties

	// Get all self referrals recursively upon first component load
	useEffect(() => {
		(async () => {
			const fetchReferrals = (nextToken) => {
				let query;
				const variables = {
					nextToken,
				};
				if (currentUser.role === USER_ROLES.EXTENDED_USER) {
					query = queryReferralsByContactIdIndex;
					variables.contactId =
						currentUser.extendedContactData?.find(
							(record) => (record.extendedUserId = currentUser.id)
						)?.id ?? currentUser.id;
				} else {
					query = queryReferralsByUserIdReferralTypeIndex;
					variables.userId = currentUser.id;
					variables.referralType = 'self';
				}

				return client
					.query({
						query,
						fetchPolicy: 'network-only',
						variables,
					})
					.then(
						(response) =>
							response.data?.queryReferralsByUserIdReferralTypeIndex ??
							response.data?.queryReferralsByContactIdIndex
					);
			};

			let nextToken;
			let allSelfReferrals = [];
			do {
				const response = await fetchReferrals(nextToken);
				allSelfReferrals = [...allSelfReferrals, ...response.items];
				nextToken = response.nextToken;
			} while (nextToken);

			allSelfReferrals = _.sortBy(allSelfReferrals, ['referralDate']).reverse();
			setSelfReferrals(allSelfReferrals.filter((referral) => referral.job));
			setLoading(false);

			const errorImageURL = await getSetErrorImageURL(
				currentUser?.company?.errorImage?.key
			);

			if (errorImageURL) {
				setErrorImageSource(errorImageURL);
			}
		})();
	}, []);

	// If fetching referrals upon initial render, show spinner
	if (loading) {
		return <Spinner />;
	}

	// If there are no self referrals in the database component returns with indicator and logo
	if (!selfReferrals || selfReferrals.length === 0) {
		return (
			<div className="no-content">
				{get(currentUser, 'company.whiteLabel') ? (
					<img
						className="no-content-icon"
						src={errorImageSource}
						alt="error image"
					/>
				) : (
					<img
						className="no-content-icon"
						alt="erin-logo"
						width={150}
						src={fileIcon}
					/>
				)}
				<p className="no-content-text">
					You haven’t submitted any internal applications yet.
				</p>
			</div>
		);
	}

	if (!currentUser.company) {
		console.log('company missing first render');
		return null;
	}

	return (
		<>
			<div className="page-top-filter">
				<div className="filter-wrap">
					<SearchComponent
						searchQuery={searchedValue}
						setQueryToState={(value) => setSearchedValue(value)}
						placeholder={ml('Search', currentUser, allMultiLingualData)}
					/>
					<ErinFilterSelect
						options={_.sortBy([
							{
								label: ml('Started', currentUser, allMultiLingualData),
								value: 'accepted',
							},
							{
								label: ml('Hired', currentUser, allMultiLingualData),
								value: 'hired',
							},
							{ label: 'Ineligible', value: 'ineligible' },
							{
								label: ml('Not Hired', currentUser, allMultiLingualData),
								value: 'notHired',
							},
							{
								label: ml('Declined', currentUser, allMultiLingualData),
								value: 'declined',
							},
							{
								label: ml('Inactive', currentUser, allMultiLingualData),
								value: 'inactive',
							},
							{
								label: `${
									currentUser.company.referralStatus
										? currentUser.company.referralStatus
										: currentUser.company.stages &&
											  JSON.parse(currentUser.company.stages).length > 0
											? 'In Progress'
											: 'Interviewing'
								}`,
								value: 'interviewing',
							},
							{
								label: ml('No Response', currentUser, allMultiLingualData),
								value: 'noresponse',
							},
						])}
						placeholder={ml('Status', currentUser, allMultiLingualData)}
						appliedFilters={appliedFilters}
						onChange={(filters) => setAppliedFilters(filters)}
					/>
				</div>
			</div>

			{/* if nothing matches the searched or filtered criteria, return logo and note */}
			{!selfReferrals.some((referral) =>
				referral.job.title.toLowerCase().includes(searchedValue.toLowerCase())
			) ||
			(appliedFilters.length > 0 &&
				!selfReferrals.some((referral) =>
					appliedFilters.includes(referral.status)
				)) ? (
				<div className="no-content">
					<img
						className="no-content-icon"
						alt="erin-logo"
						width={150}
						src={fileIcon}
					/>
					<p className="no-content-text">
						There are no matches for your search criteria.
					</p>
				</div>
			) : (
				// Else return all cards within search/filter criteria

				<List
					className="my-progressbar"
					grid={{ gutter: 14, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 3 }}
					dataSource={selfReferrals.filter((referral) => {
						// Provide all referrals by default, filter out values that don't match search/filter criteria
						if (
							(appliedFilters.length > 0 &&
								!appliedFilters.includes(referral.status)) ||
							!referral.job.title
								.toLowerCase()
								.includes(searchedValue.toLowerCase())
						) {
							return false;
						}

						return true;
					})}
					itemLayout="horizontal"
					pagination={{ pageSize: 12, showSizeChanger: false }}
					renderItem={(referral) => (
						<List.Item key={referral.id}>
							<div
								className="my-progressbar-card"
								style={{ display: 'flex', flexDirection: 'column' }}
							>
								<div className="my-progressbar-top">
									<div className="mp-left">
										<h4 className="my-progressbar-name">
											<Link to={`/browsejobs/${referral.job.id}`}>
												{referral.job.title}
											</Link>
										</h4>
									</div>
									<div className="mp-right">
										<span className="my-progressbar-date">
											{ml('Applied On', currentUser, allMultiLingualData)}
											:&nbsp;
											{/* Format date according to user's profile settings */}
											{formatDate(
												referral.referralDate,
												currentUser?.languageCode,
												currentUser?.dateFormat
											)}
										</span>
									</div>
								</div>
								{referral.job.internalJobLink &&
								['accepted', 'referred'].includes(referral.status) ? (
									<div className="text-erinBlue font-bold flex h-fit items-center">
										<a
											href={referral.job.internalJobLink}
											target="_blank"
											rel="noreferrer"
										>
											Apply&nbsp;Link
										</a>
										<BsBoxArrowUpRight style={{marginBottom: '4px', marginLeft: '4px'}}/>
									</div>
								) : null}
								<div className="my-progressbar-status">
									<div className="mps-left">
										<p>
											{ml('Status', currentUser, allMultiLingualData)}:{' '}
											<span className="status-name">
												{startCase(
													referral.status === 'accepted'
														? 'started'
														: referral.status === 'noresponse'
															? 'No Response'
															: referral.customStatus &&
																  referral.status === 'interviewing'
																? referral.customStatus
																: referral.status
												)}
											</span>
											{/* Format referral status from camel case, to sentence and capitalizes first */}
											{/* Display started in place of accepted. And if status is "interviewing" but customStatus exists, display custom status in its place  */}
										</p>
									</div>
								</div>
								<div className="my-progressbar-wrap">
									<ProgressIndicator referral={referral} />
								</div>
							</div>
						</List.Item>
					)}
				/>
			)}
		</>
	);
}

export default withApollo(MyApplications);
