import {
	ExclamationCircleOutlined,
	InfoCircleOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Select, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { configMode } from 'src/_shared/api/';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import { withQueryParams } from 'src/_shared/services/index.js';
import { SearchComponent } from 'src/_shared/components/search';
import { ColorCard, Spinner, WebIcon } from 'src/_shared/index.js';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import ReferralsTable from '../_shared/components/ReferralsTableComponent.jsx';
import {
	downloadFromS3,
	lambda,
	logout,
	mapReferralStatus,
	ml,
	parse,
	parseReferralStatus,
	searchOpenSearchNetwork,
} from '../_shared/services/utils.js';
import { ExportSelector } from '../_shared/components/export-selector/ExportSelector.jsx';
import AddReferralJobModal from './referral-modal/AddReferralModalComponent.jsx';

const { Option } = Select;

class ReferralsDashboardComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			company: get(props, 'currentUser.company'),
			currencyRate: 1,
			currencySymbol: '$',
			currentTableData: null,
			departments: get(props, 'departments', []),
			exportTableData: [],
			filteredBonuses: [],
			filteredCompanyStages: [],
			filteredData: get(props, 'referrals', []),
			filteredDates: [],
			filteredDepartments: [],
			filteredRecruiters: [],
			filteredSubCompanies: [],
			filteredStatuses: [],
			filteredUserGroups: [],
			filterOn: false,
			filterReferralStatus: '',
			isCardDataSet: false,
			isFilteredByInterviewing: false,
			isNewReferralCreated: false,
			loading: true,
			referralLogs: get(props, 'referralLogs', []),
			referralCounts: {
				accepted: 0,
				declined: 0,
				hired: 0,
				ineligible: 0,
				interviewing: 0,
				notHired: 0,
				referred: 0,
				total: 0,
				transferred: 0,
				uniqueCandidateCount: this.props.referralContact,
			},
			searchedReferrals: [],
			searchQuery: '',
			statuses: [
				'referred',
				'accepted',
				'interviewing',
				'hired',
				'notHired',
				'declined',
				'noresponse',
				'transferred',
				'inactive',
				'ineligible',
			],
			statusFilterValue: '',
			subCompanyLabel: get(
				props,
				'currentUser.company.subCompanyLabel',
				'Company'
			)
				? get(props, 'currentUser.company.subCompanyLabel', 'Company')
				: 'Company',
			totalReferralCount: undefined,
			theme: parse(get(props, 'currentUser.company.theme', '{}')),
			userGroups: get(props, 'currentUser.company.userGroups', []),
			visible: false,
		};
	}

	async componentDidMount() {
		await this.getUserDataById();
		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		let defaultQuery;
		if (this.props?.queryParams?.size > 0) {
			defaultQuery = this.props.queryParams.get('search') || '';
		}

		await this.setQueryToState(defaultQuery, 0);
		await this.getAdminAndManagers();

		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		const customStages = get(this.props, 'currentUser.company.stages');
		if (customStages) {
			const stages = parse(customStages).map(
				(stage) => Object.values(stage)[0]
			);
			this.setState({ stages });
		}

		let errorImageSource;
		const errorImage = get(this.state, 'company.errorImage.key', false);
		if (errorImage) {
			errorImageSource = await downloadFromS3(errorImage);
		}

		this.setState({ errorImageSource });
		this.setFilterOptions();
	}

	componentDidUpdate(prevProps) {
		const {
			filteredBonuses = [],
			filteredDepartments = [],
			filteredRecruiters = [],
			filteredStatuses = [],
			filteredUserGroups = [],
			searchQuery = '',
		} = this.state;
		const { departments, referrals } = this.props;
		if (prevProps.referrals !== referrals) {
			this.setState({ filteredData: referrals });
		}

		if (prevProps.departments !== departments) {
			this.setState({ departments });
			this.setFilterOptions();
		}

		const filterOn =
			filteredBonuses.length > 0 ||
			filteredDepartments.length > 0 ||
			filteredStatuses.length > 0 ||
			filteredRecruiters.length > 0 ||
			filteredUserGroups.length > 0 ||
			searchQuery.length > 0;
		if (get(this.state, 'filterOn') !== filterOn) {
			this.setState({ filterOn });
		}
	}
	async getAdminAndManagers() {
		const company = get(this.props, 'currentUser.company');
		const filteredRoles = ['admin', 'manager'];
		const parameters = {
			query: '',
			size: 300,
			currentUser: get(this.props, 'currentUser.id'),
			filters: {
				companies: get(company, 'id'),
				roles: filteredRoles,
			},
		};
		const response = await searchOpenSearchNetwork(parameters, 'erin-users');
		if (get(response, 'data')) {
			const sortedRecruiters = _.sortBy(get(response, 'data', []), [
				'firstName',
			]);
			this.setState({
				recruiters: sortedRecruiters,
			});
		}
	}

	getReport = () => {
		const endpoint = 'export-referral-data';
		const { companyId, cognitoId } = this.props.currentUser;
		message.success(`Export processing. You will receive an email when ready`);
		lambda({
			endpoint,
			variables: { companyId, cognitoId, configMode },
			async: false,
		});
	};

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	setQueryToState = async (searchQuery = '', delay = 300) => {
		const {
			company,
			filteredBonuses = [],
			filteredCompanyStages = [],
			filteredDates = [],
			filteredDepartments = [],
			filteredRecruiters = [],
			filteredStatuses = [],
			filteredSubCompanies = [],
			filteredUserGroups = [],
			filterOn,
		} = this.state;

		this.setState({ searchQuery });
		clearTimeout(this.timer);

		if (!filterOn) {
			this.setState({
				searchedReferrals: get(this.state, 'allReferrals', []),
			});
		}

		this.timer = setTimeout(async () => {
			this.setState({ loading: true });
			const parameters = {
				query: searchQuery,
				size: 300,
				filterOn,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					bonuses: filteredBonuses,
					companies: get(company, 'id'),
					customStatuses: filteredCompanyStages,
					dateReferred: filteredDates,
					departments: filteredDepartments,
					recruiters: filteredRecruiters,
					statuses: filteredStatuses,
					subCompanies: filteredSubCompanies,
					userGroups: filteredUserGroups,
				},
			};
			const response = await searchOpenSearchNetwork(
				parameters,
				'erin-referrals'
			);
			let searchedReferrals = get(response, 'data', []);
			searchedReferrals =
				searchQuery.length > 0
					? searchedReferrals
					: this.sortByDateReferred(searchedReferrals);

			if (get(response, 'query') === get(this.state, 'searchQuery')) {
				this.setState({
					loading: false,
					searchedReferrals,
				});
				this.setTotals(response, searchedReferrals);
			}
		}, delay);
	};

	capitalizedReferralStatus = () => {
		try {
			get(this.props, 'currentUser.company.referralStatus', 'Interviewing');

			const capitalizedReferralStatus =
				capitalizedReferralStatus.charAt(0).toUpperCase() +
				capitalizedReferralStatus.slice(1);
			return capitalizedReferralStatus;
		} catch {
			return 'Interviewing';
		}
	};

	download = (e) => {
		const { referralLogs } = this.props;
		const result = referralLogs
			.map((value) => {
				return (
					this.formatDate(value.date) +
					' : ' +
					value.firstName +
					' ' +
					value.lastName +
					' ' +
					value.user.emailAddress +
					' ' +
					'made a referral for' +
					' ' +
					value.job.title
				);
			})
			.join('\r\n');
		e.preventDefault();
		const a = document.body.appendChild(document.createElement('a'));
		let textToWrite = result;
		a.download = 'ReferralsLog.txt';
		textToWrite = textToWrite.replaceAll('\n', '%0D%0A');
		a.href = 'data:text/plain,' + textToWrite;
		a.click();
	};

	formatDate(date) {
		if (!date) return;
		const isoDate = new Date(date);
		const month = isoDate.getMonth() + 1;
		const day = isoDate.getDate();
		const year = isoDate.getFullYear();
		const hours = isoDate.getHours();
		const mins = isoDate.getMinutes();
		return `${month}/${day}/${year} ${hours}${':'}${mins}`;
	}

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	handleDateRangeFilter = (filteredDates = []) => {
		const { searchQuery = '' } = this.state;
		if (filteredDates && filteredDates.length > 0) {
			filteredDates = {
				from: filteredDates[0].toISOString(),
				to: filteredDates[1].toISOString(),
			};
		}

		this.setState(
			{
				filteredDates,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterBonus = (bonus) => {
		let { filteredBonuses = [], searchQuery = '' } = this.state;
		if (filteredBonuses.map((b) => b).includes(bonus)) return;
		filteredBonuses = [...filteredBonuses, bonus];
		this.setState(
			{
				filteredBonuses,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterDepartment = (department, key) => {
		let { filteredDepartments = [], searchQuery = '' } = this.state;
		if (filteredDepartments.map((dep) => dep.name).includes(department)) return;
		filteredDepartments = [
			...filteredDepartments,
			{ name: department, id: key },
		];
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterRecruiter = (recruiter) => {
		let { filteredRecruiters = [], searchQuery = '' } = this.state;
		if (filteredRecruiters.map((r) => r).includes(recruiter)) return;
		filteredRecruiters = [...filteredRecruiters, recruiter];
		this.setState(
			{
				filteredRecruiters,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterStatus = (status, key) => {
		let {
			isFilteredByInterviewing,
			filteredCompanyStages = [],
			filteredStatuses = [],
			statuses = [],
			searchQuery = '',
		} = this.state;

		const selectedFilter = status;
		const INTERVIEWING = 'interviewing';

		function filterIsActive(selectedFilter) {
			return (
				filteredCompanyStages.includes(selectedFilter) ||
				filteredStatuses.includes(selectedFilter)
			);
		}

		function convertStagesToStringArray(companyStages) {
			const parsedStages = parse(companyStages) ? parse(companyStages) : [];
			return parsedStages.map((stage) => Object.values(stage)[0]);
		}

		if (filterIsActive(selectedFilter)) return;
		const companyStages = get(this.props, 'currentUser.company.stages');
		const customStages = convertStagesToStringArray(companyStages);

		if (customStages.includes(selectedFilter)) {
			this.setState(
				{
					filteredCompanyStages: [...filteredCompanyStages, selectedFilter],
					loading: true,
				},
				async () => await this.setQueryToState(searchQuery)
			);
		}

		if (statuses.includes(selectedFilter)) {
			if (filteredStatuses.includes(selectedFilter)) return;
			if (selectedFilter === INTERVIEWING) isFilteredByInterviewing = true;

			this.setState(
				{
					filteredStatuses: [...filteredStatuses, selectedFilter],
					isFilteredByInterviewing,
					loading: true,
				},
				async () => await this.setQueryToState(searchQuery)
			);
		}
	};

	handleFilterSubCompany = (subCompany) => {
		let { filteredSubCompanies = [], searchQuery = '' } = this.state;
		if (filteredSubCompanies.map((b) => b).includes(subCompany)) return;
		filteredSubCompanies = [...filteredSubCompanies, subCompany];
		this.setState(
			{
				filteredSubCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterUserGroup = (userGroup, key) => {
		let { filteredUserGroups = [], searchQuery = '' } = this.state;

		if (filteredUserGroups.map((group) => group.name).includes(userGroup))
			return;
		filteredUserGroups = [...filteredUserGroups, userGroup];
		this.setState(
			{
				filteredUserGroups,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleFilterDepartment = (department, key) => {
		let { filteredDepartments = [], searchQuery = '' } = this.state;
		if (filteredDepartments.map((dep) => dep.name).includes(department)) return;
		filteredDepartments = [...filteredDepartments, department];
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveBonusFilter = (bonus) => {
		const { filteredBonuses = [], searchQuery = '' } = this.state;
		const index = filteredBonuses.map((b) => b).indexOf(bonus);
		filteredBonuses.splice(index, 1);
		this.setState(
			{
				filteredBonuses,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveDepartmentFilter = (department) => {
		const { filteredDepartments = [], searchQuery = '' } = this.state;
		const index = filteredDepartments
			.map((dep) => dep.name)
			.indexOf(department);
		filteredDepartments.splice(index, 1);
		this.setState(
			{
				filteredDepartments,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveRecruiterFilter = (recruiter) => {
		const { filteredRecruiters = [], searchQuery = '' } = this.state;
		const index = filteredRecruiters.map((r) => r).indexOf(recruiter);
		filteredRecruiters.splice(index, 1);
		this.setState(
			{
				filteredRecruiters,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveStatusFilter = (status) => {
		let {
			isFilteredByInterviewing,
			filteredCompanyStages = [],
			filteredStatuses = [],
			searchQuery = '',
		} = this.state;
		const filterToRemove = status;
		const INTERVIEWING = 'interviewing';

		function notFilteredByInterviewing() {
			return (
				filteredCompanyStages.length === 1 && isFilteredByInterviewing === false
			);
		}

		function removeFilter(filters, filterToRemove) {
			return filters.filter((statusFilter) => statusFilter !== filterToRemove);
		}

		function hasActiveCustomFilters() {
			return filteredCompanyStages.length > 0;
		}

		if (filteredCompanyStages.includes(filterToRemove)) {
			if (notFilteredByInterviewing()) {
				filteredStatuses = removeFilter(filteredStatuses, INTERVIEWING);
			}

			filteredCompanyStages = removeFilter(
				filteredCompanyStages,
				filterToRemove
			);
		}

		if (filteredStatuses.includes(filterToRemove)) {
			if (filterToRemove === INTERVIEWING) {
				this.setState({ isFilteredByInterviewing: false, loading: true });
				if (hasActiveCustomFilters()) return;
			}

			filteredStatuses = removeFilter(filteredStatuses, filterToRemove);
		}

		this.setState(
			{
				filteredCompanyStages,
				filteredStatuses,
				loading: true,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveSubCompanyFilter = (subCompany) => {
		const { filteredSubCompanies = [], searchQuery = '' } = this.state;
		const index = filteredSubCompanies.map((b) => b).indexOf(subCompany);
		filteredSubCompanies.splice(index, 1);
		this.setState(
			{
				filteredSubCompanies,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	handleRemoveUserGroupFilter = (userGroup) => {
		const { filteredUserGroups = [], searchQuery = '' } = this.state;
		const index = filteredUserGroups
			.map((group) => group.name)
			.indexOf(userGroup);

		filteredUserGroups.splice(index, 1);

		this.setState(
			{
				filteredUserGroups,
			},
			async () => await this.setQueryToState(searchQuery)
		);
	};

	setExportData = (data) => {
		this.setState({ exportTableData: data });
	};

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	sortByAlph = (a, b) => {
		if (!a) return -1;
		if (!b) return 1;
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	};

	sortByDateReferred(data = []) {
		const sortedData = data.sort((a, b) =>
			this.sortByAlph(
				dayjs(get(b, 'referralDate')),
				dayjs(get(a, 'referralDate'))
			)
		);
		return sortedData;
	}

	toggleButtonStyles = (filterReferralStatus) => {
		this.setState({
			filterReferralStatus,
		});
	};

	toggleLoading = () => {
		this.setState({ loading: !this.state.loading });
	};

	updateRef = async () => {
		this.setState({ isNewReferralCreated: true }, async () => {
			await this.setQueryToState(get(this.state, 'searchQuery', ''), 8000);
		});
	};

	updateReferralState = (referral) => {
		const searchedReferrals = this.state?.searchedReferrals ?? [];
		// Immutable pattern to update array or else React won't update table props
		const updatedSearchedReferrals = searchedReferrals.map(
			(previousReferral) => {
				if (previousReferral?.id === referral?.id) {
					return {
						...previousReferral,
						...referral,
					};
				}

				return previousReferral;
			}
		);

		this.setState({ searchedReferrals: updatedSearchedReferrals });
	};

	updateReferrals = (referral = []) => {
		window.location.reload();
	};

	renderArrowIcon = (count) => {
		return (
			<div>
				<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
			</div>
		);
	};

	setFilterOptions = () => {
		const {
			bonuses = [],
			currentUser: { company: { userGroups = [] } = {} },
			subCompanies = [],
		} = this.props;
		const {
			company,
			recruiters = [],
			stages = [],
			departments = [],
		} = this.state;
		const sortedDepartments = _.sortBy(departments, ['name']);
		const departmentOptions = sortedDepartments.map((department) => {
			return (
				<Option key={department.id} value={department.id}>
					{department.name}
				</Option>
			);
		});
		const statusesData = ['referred', 'accepted', 'interviewing'];
		if (stages.length > 0) {
			for (const status of stages) statusesData.push(status.trim());
		}

		statusesData.push(
			'hired',
			'notHired',
			'declined',
			'noresponse',
			'transferred',
			'inactive',
			'ineligible'
		);

		const statusOptions = statusesData.map((status) => {
			return (
				<Option key={status} value={status}>
					{mapReferralStatus(status, company)}
				</Option>
			);
		});

		const bonusOptions = bonuses.map((bonus) => {
			return (
				<Option key={get(bonus, 'id')} value={get(bonus, 'id')}>
					{get(bonus, 'name')}
				</Option>
			);
		});

		const recruiterOptions = recruiters.map((recruiter) => {
			return (
				<Option key={get(recruiter, 'id')} value={get(recruiter, 'id')}>
					{`${get(recruiter, 'firstName')} ${get(recruiter, 'lastName')}`}
				</Option>
			);
		});
		const subComps = _.sortBy(subCompanies, ['name']);
		const subCompanyOptions = subComps.map((subCompany) => {
			return (
				<Option key={get(subCompany, 'id')} value={get(subCompany, 'id')}>
					{get(subCompany, 'name')}
				</Option>
			);
		});

		const sortedUserGroups = _.sortBy(userGroups, ['name']);
		const userGroupOptions = sortedUserGroups.map((userGroup) => {
			return (
				<Option key={userGroup.id} value={userGroup.id}>
					{userGroup.name}
				</Option>
			);
		});
		this.setState({
			bonusOptions,
			departmentOptions,
			recruiterOptions,
			statusOptions,
			subCompanyOptions,
			userGroupOptions,
		});
	};

	setTotals = (response, searchedReferrals = this.state.searchedReferrals) => {
		const {
			referralsData,
			currentUser: { company },
			referralContact,
		} = this.props;
		let { referralCounts } = this.state;

		if (referralsData.statusWise && company) {
			referralCounts = Object.keys(referralsData.statusWise).reduce(
				(accumulator, key) => {
					const status = parseReferralStatus(key, company);
					if (accumulator[status]) {
						accumulator[status] += referralsData.statusWise[key];
					} else {
						accumulator[status] = referralsData.statusWise[key];
					}

					return accumulator;
				},
				{ total: 0 }
			);
			referralCounts.total = referralsData.totalReferral;
			referralCounts.uniqueCandidateCount = Number.parseInt(referralContact);
		}

		const totalReferralCount = response?.metadata?.showing?.totalItems
			? Number.parseInt(response?.metadata?.showing?.totalItems)
			: undefined;

		this.setState((prevState) => ({
			...prevState,
			isCardDataSet: true,
			referralCounts,
			totalReferralCount,
		}));
	};

	render() {
		const { allMultiLingualData, bonuses = [], currentUser } = this.props;
		const {
			bonusOptions,
			departmentOptions,
			recruiterOptions,
			statusOptions,
			subCompanyOptions,
			userGroupOptions,
			company,
			currencyRate,
			currencySymbol,
			errorImageSrc,
			exportTableData,
			filteredBonuses,
			filteredDepartments,
			filteredRecruiters,
			filteredStatuses,
			filteredSubCompanies,
			filteredUserGroups,
			filterOn,
			isCardDataSet,
			loading,
			recruiters = [],
			referralCounts,
			searchedReferrals,
			searchQuery = '',
			theme,
			totalReferralCount,
			visible,
		} = this.state;
		const { RangePicker } = DatePicker;
		if (!get(currentUser, 'company')) return <Spinner />;
		const languageCode = get(currentUser, 'languageCode', 'US');
		const dateFormat = get(currentUser, 'dateFormat', 'US');
		const whiteLabel = get(company, 'whiteLabel');
		const showBonusFilter = bonuses.length > 0;
		const showRecruiterFilter = recruiters.length > 0;
		const contacts = get(this.props, 'currentUser.contacts');

		return (
			<main>
				<div className="page-title">
					<h2 className="page-heading">
						{ml('Referrals', currentUser, allMultiLingualData)}
					</h2>

					<ul className="info-action">
						<li>
							<Button
								type="link"
								className="add-btn btn-lg"
								onClick={this.showModal}
							>
								<span className="icon-circle">
									<i className="icon-plus" />
								</span>
								<span>
									{ml('Add Referral', currentUser, allMultiLingualData)}
								</span>
							</Button>
						</li>
					</ul>
				</div>

				<div className="color-card-grid">
					<ColorCard
						bgColor="var(--bright-turquoise)"
						count={
							referralCounts.uniqueCandidateCount &&
							Number.parseInt(
								referralCounts.uniqueCandidateCount
							).toLocaleString('en-US')
						}
						description={ml(
							'The all-time total number of unique candidates that have been referred.',
							currentUser,
							this.props.allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml(
							'Candidates',
							currentUser,
							this.props.allMultiLingualData
						)}
					/>
					<ColorCard
						bgColor="var(--deep-blush)"
						count={
							referralCounts.total &&
							Number.parseInt(referralCounts.total).toLocaleString('en-US')
						}
						description={ml(
							'The all-time total number of referrals that have been submitted. Includes all statuses.',
							currentUser,
							this.props.allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml('Referrals', currentUser, this.props.allMultiLingualData)}
					/>
					<ColorCard
						bgColor="var(--tan-hide)"
						count={
							referralCounts.accepted &&
							Number.parseInt(referralCounts.accepted).toLocaleString('en-US')
						}
						description={ml(
							`The total number of referrals currently in the ${mapReferralStatus('accepted', currentUser.company)} stage.`,
							currentUser,
							allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml(
							`${mapReferralStatus('accepted', currentUser.company)}`,
							currentUser,
							allMultiLingualData
						)}
					/>
					<ColorCard
						bgColor="var(--royal-blue)"
						count={
							referralCounts.interviewing &&
							Number.parseInt(referralCounts.interviewing).toLocaleString(
								'en-US'
							)
						}
						description={ml(
							`The total number of referrals currently in the ${mapReferralStatus('interviewing', currentUser.company)} stage. This also includes all custom stages.`,
							currentUser,
							allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml(
							`${mapReferralStatus('interviewing', currentUser.company)}`,
							currentUser,
							allMultiLingualData
						)}
					/>
					<ColorCard
						bgColor="var(--forest-green)"
						count={
							referralCounts.hired &&
							Number.parseInt(referralCounts.hired).toLocaleString('en-US')
						}
						description={ml(
							'The all-time total number of unique candidates that have been hired.',
							currentUser,
							this.props.allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml(
							`${mapReferralStatus('hired', currentUser.company)}`,
							currentUser,
							allMultiLingualData
						)}
					/>
					<ColorCard
						bgColor="var(--old-mandarin)"
						count={
							referralCounts.declined &&
							Number.parseInt(referralCounts.declined).toLocaleString('en-US')
						}
						description={ml(
							'The all-time total number of referrals that have declined their referral.',
							currentUser,
							this.props.allMultiLingualData
						)}
						isCardDataSet={isCardDataSet}
						title={ml(
							`${mapReferralStatus('declined', currentUser.company)}`,
							currentUser,
							allMultiLingualData
						)}
					/>
					<ColorCard
						bgColor="var(--sunset-orange)"
						count={
							referralCounts.notHired &&
							Number.parseInt(referralCounts.notHired).toLocaleString('en-US')
						}
						description={
							<>
								<span>
									{ml(
										`The all-time total number of referrals that have been moved
								to ${mapReferralStatus('notHired', currentUser.company)}.`,
										currentUser,
										allMultiLingualData
									)}
								</span>
								<span className="mr-1" />
								<Tooltip
									title={ml(
										`Note this does not include Inactive, No Response, or Declined referrals.`,
										currentUser,
										allMultiLingualData
									)}
								>
									<InfoCircleOutlined />
								</Tooltip>
							</>
						}
						isCardDataSet={isCardDataSet}
						title={ml(
							`${mapReferralStatus('notHired', currentUser.company)}`,
							currentUser,
							allMultiLingualData
						)}
					/>
				</div>

				<div className="referrals-actions">
					<SearchComponent
						loading={loading}
						searchQuery={searchQuery}
						setQueryToState={this.setQueryToState}
						placeholder={ml('Search', currentUser, allMultiLingualData)}
					/>

					<ExportSelector
						title={ml('Export', currentUser, allMultiLingualData)}
						items={[
							{
								key: 1,
								label: (
									<Tooltip
										title={ml(
											`You will receive an export via email of all data.`,
											currentUser,
											allMultiLingualData
										)}
										placement="left"
									>
										{ml('Export All', currentUser, allMultiLingualData)}
									</Tooltip>
								),
								onClick: this.getReport,
							},
							{
								key: 2,
								label: (
									<Tooltip
										title={
											totalReferralCount > 300
												? ml(
														'Unavailable: More than 300 results. Please filter to enable.',
														currentUser,
														allMultiLingualData
													)
												: ml(
														`Instantly download an export of the filtered results.`,
														currentUser,
														allMultiLingualData
													)
										}
										placement="left"
									>
										{ml('Export View', currentUser, allMultiLingualData)}
									</Tooltip>
								),
								disabled: totalReferralCount > 300,
								onClick: 'csv-export',
								data: this.state.exportTableData,
								reportType: 'referral',
								companyName: this.props?.currentUser?.company?.name,
							},
						]}
					/>

				</div>
				<div className="custom-filter">
					<div className="filter-wrap">
						<Select
							showArrow
							mode="multiple"
							placeholder={ml('Status', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredStatuses.length)}
							dropdownMatchSelectWidth={false}
							onSelect={(status, { key }) =>
								this.handleFilterStatus(status, key)
							}
							onDeselect={(status, { key }) =>
								this.handleRemoveStatusFilter(status, key)
							}
						>
							{statusOptions}
						</Select>
						{showRecruiterFilter ? (
							<Select
								showArrow
								mode="multiple"
								placeholder={ml(
									'Recruiter',
									currentUser,
									this.props.allMultiLingualData
								)}
								maxTagCount={1}
								maxTagTextLength={15}
								suffixIcon={this.renderArrowIcon(filteredRecruiters.length)}
								dropdownMatchSelectWidth={false}
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								onSelect={(recruiter) => this.handleFilterRecruiter(recruiter)}
								onDeselect={(recruiter) =>
									this.handleRemoveRecruiterFilter(recruiter)
								}
							>
								{recruiterOptions}
							</Select>
						) : null}
						{showBonusFilter ? (
							<Select
								showArrow
								mode="multiple"
								placeholder={ml(
									'Bonus',
									currentUser,
									this.props.allMultiLingualData
								)}
								maxTagCount={1}
								maxTagTextLength={15}
								suffixIcon={this.renderArrowIcon(filteredBonuses.length)}
								dropdownMatchSelectWidth={false}
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								onSelect={(bonus) => this.handleFilterBonus(bonus)}
								onDeselect={(bonus) => this.handleRemoveBonusFilter(bonus)}
							>
								{bonusOptions}
							</Select>
						) : null}
						<Select
							showArrow
							mode="multiple"
							placeholder={ml('Department', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredDepartments.length)}
							dropdownMatchSelectWidth={false}
							onSelect={(department, { key }) =>
								this.handleFilterDepartment(department, key)
							}
							onDeselect={(department, { key }) =>
								this.handleRemoveDepartmentFilter(department, key)
							}
						>
							{departmentOptions}
						</Select>
						{subCompanyOptions && subCompanyOptions.length > 0 && (
							<Select
								showArrow
								filterOption={(input, option) => {
									return option.props.children
										.toLowerCase()
										.includes(input.toLowerCase());
								}}
								mode="multiple"
								placeholder={ml(
									`${this.state.subCompanyLabel}`,
									this.props.currentUser,
									this.props.allMultiLingualData
								)}
								maxTagCount={1}
								maxTagTextLength={15}
								suffixIcon={this.renderArrowIcon(filteredSubCompanies.length)}
								dropdownMatchSelectWidth={false}
								onSelect={(subCompany) =>
									this.handleFilterSubCompany(subCompany)
								}
								onDeselect={(subCompany) =>
									this.handleRemoveSubCompanyFilter(subCompany)
								}
							>
								{subCompanyOptions}
							</Select>
						)}
						<Select
							showArrow
							mode="multiple"
							placeholder={ml('User Group', currentUser, allMultiLingualData)}
							maxTagCount={1}
							maxTagTextLength={15}
							suffixIcon={this.renderArrowIcon(filteredUserGroups.length)}
							onSelect={(userGroup, { key }) =>
								this.handleFilterUserGroup(userGroup, key)
							}
							onDeselect={(userGroup, { key }) =>
								this.handleRemoveUserGroupFilter(userGroup, key)
							}
						>
							{userGroupOptions}
						</Select>
						<RangePicker
							className="ant-picker-320w-br"
							separator={<span>~</span>}
							format="MM-DD-YYYY"
							startdateText={ml('Start date', currentUser, allMultiLingualData)}
							enddateText={ml('End date', currentUser, allMultiLingualData)}
							onChange={(value) => {
								this.handleDateRangeFilter(value ?? []);
							}}
						/>
						{filterOn && totalReferralCount > 300 && (
							<Tooltip
								title={ml(
									`Your filter includes over 300 results. Only the first 300 are displayed.`,
									currentUser,
									allMultiLingualData
								)}
								placement="right"
							>
								<ExclamationCircleOutlined style={{ marginLeft: 10 }} />
							</Tooltip>
						)}
					</div>
				</div>

				<AddReferralJobModal
					currentUser={this.props.currentUser}
					updateReferrals={this.updateRef}
					theme={theme}
					visible={visible}
					toggleLoading={this.toggleLoading}
					handleCancel={this.handleCancel}
					jobs={this.props.jobs}
					selectJobText={ml('Select a Job', currentUser, allMultiLingualData)}
					selectJobCreateReferralText={ml(
						'Select a job to create referral',
						currentUser,
						allMultiLingualData
					)}
					searchJobsText={ml('Search Jobs', currentUser, allMultiLingualData)}
					referralInformationText={ml(
						'Referral Information',
						currentUser,
						allMultiLingualData
					)}
					createReferralForText={ml(
						'Create a referral for the',
						currentUser,
						allMultiLingualData
					)}
					positiontext={ml('position', currentUser, allMultiLingualData)}
					selectEmployeeText={ml(
						'Select an Employee',
						currentUser,
						allMultiLingualData
					)}
					enterReferralInformationText={ml(
						'Enter Referral Information',
						currentUser,
						allMultiLingualData
					)}
					firstNameText={ml('First Name', currentUser, allMultiLingualData)}
					lastNameText={ml('Last Name', currentUser, allMultiLingualData)}
					emailText={ml('Email Address', currentUser, allMultiLingualData)}
					clickHereText={ml('Click here', currentUser, allMultiLingualData)}
					attachResumeText={ml(
						'to attach a resume',
						currentUser,
						allMultiLingualData
					)}
					noteText={ml('NOTE', currentUser, allMultiLingualData)}
					referralAutomaticallyAcceptedText={ml(
						'The referral will automatically be set to "Accepted" status. No notification will be sent to the employee or candidate',
						currentUser,
						allMultiLingualData
					)}
					submitReferralText={ml(
						'Submit Referral',
						currentUser,
						allMultiLingualData
					)}
					bonusText={ml('Bonus', currentUser, allMultiLingualData)}
					allMultiLingualData={this.props.allMultiLingualData}
					contacts={contacts}
				/>
				{searchedReferrals.length > 0 && !loading ? (
					<div className="table-card">
						<ReferralsTable
							allMultiLingualData={allMultiLingualData}
							currentUser={currentUser}
							company={company}
							setExportData={this.setExportData}
							tieredBonusQuery={this.props.tieredBonusQuery}
							updateReferralState={this.updateReferralState}
							displayAs={this.props.currentUser.displayAs}
							filteredData={searchedReferrals}
							sortByAlph={this.sortByAlph}
							rowKey={(record) => record.id}
							contactIncentiveBonus={
								this.props.currentUser.company.contactIncentiveBonus
							}
							currentCurrencyRate={currencyRate}
							currentCurrencySymbol={currencySymbol}
							languageCode={languageCode}
							dateFormat={dateFormat}
							updateCurrentUserCompany={this.props.updateCurrentUserCompany}
							onUpdateReferral={this.props.onUpdateReferral}
							onUpdateJob={this.props.onUpdateJob}
							onUpdateCompany={this.props.onUpdateCompany}
						/>
					</div>
				) : loading ? (
					<Spinner />
				) : (
					<div className="no-content">
						{whiteLabel ? (
							<img
								className="no-content-icon"
								src={errorImageSrc}
								alt="error image"
							/>
						) : (
							<img className="no-content-icon" alt="erin-logo" src={fileIcon} />
						)}
						<p className="no-content-text">
							You do not have any referrals. Click <strong>Add Referral</strong>{' '}
							to create your first referral.
						</p>
					</div>
				)}
			</main>
		);
	}
}

export default withApollo(withQueryParams(ReferralsDashboardComponent));
