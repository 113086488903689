import get from 'lodash/get';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { downloadFromS3, query } from '../_shared/services/utils';
import NewPartnerUserForm from './new-user-items/NewPartnerUserFormComponent.jsx';
import { Card, LandingPageContainer, Logo } from './newUserLandingStyles.js';

class NewPartnerUserLandingPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			departments: get(props, 'company.departments'),
			company: get(props, 'company'),
			loaded: false,
			subCompanies: get(props, 'company.subCompanies'),
		};
	}

	async componentDidMount() {
		const { id } = this.props.match.params;
		const company = await query(
			{ partnerClientId: id },
			'getCompanyByPartnerClientId'
		);

		const logo = get(company, 'logo.key', false);
		const logoSource = await downloadFromS3(logo);
		const styles = await LandingPageContainer(company);

		this.setState({
			company,
			departments: get(company, 'departments'),
			logoSrc: logoSource,
			styles,
			subCompanies: get(company, 'subCompanies'),
		});
	}

	render() {
		const { company, departments, deptTitle, logoSrc, subCompanies, styles } =
			this.state;
		const whiteLabel = get(company, 'whiteLabel');

		if (!company || !departments) {
			return <Spinner forceDefault={true} />;
		}

		return (
			<div className={styles}>
				{whiteLabel ? (
					<img src={logoSrc} alt="logo" width="350px" />
				) : (
					<img className={Logo} src={ErinLogo} alt="Erin Logo" />
				)}
				<div className={Card}>
					<NewPartnerUserForm
						company={company}
						whiteLabel={whiteLabel}
						deptTitle={deptTitle}
						subCompanies={subCompanies}
						departments={departments}
						onCreateUser={this.props._onCreateUser}
					/>
				</div>
			</div>
		);
	}
}

export default withApollo(NewPartnerUserLandingPageComponent);
