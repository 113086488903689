import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { ml } from '../../_shared/services/utils';

export function ActionButtons({
	allMultiLingualData,
	currentUser,
	handleSubmit,
	handleCancel,
	handleChange,
	isEditing,
}) {
	return isEditing ? (
		<div className="profile-confirmation-btn">
			<Popconfirm
				title={ml('Confirm Updates?', currentUser, allMultiLingualData)}
				placement="left"
				okText="Yes"
				cancelText="No"
				onConfirm={handleSubmit}
			>
				<Button type="primary" icon={<CheckCircleOutlined />} size="large">
					Save
				</Button>
			</Popconfirm>
			<Popconfirm
				title={ml('Discard edits?', currentUser, allMultiLingualData)}
				placement="left"
				okText="Yes"
				cancelText="No"
				onConfirm={handleCancel}
			>
				<Button type="default" icon={<CloseCircleOutlined />} size="large">
					Cancel
				</Button>
			</Popconfirm>
		</div>
	) : (
		<Button
			type="primary"
			size="large"
			onClick={() => handleChange({ isEditing: true })}
		>
			{ml('Edit Profile', currentUser, allMultiLingualData)}
		</Button>
	);
}
