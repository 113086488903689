import get from 'lodash/get';
import { Component } from 'react';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import LoginBackground from '../_shared/assets/auth-bg-full.jpg';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { downloadFromS3, query } from '../_shared/services/utils';
import NewUserForm from './new-user-items/NewUserFormComponent.jsx';
let loadingImages = true;

class NewUserLandingPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accountClaim: get(props, 'location.state.accountClaim'),
			departments: get(props, 'departments'),
			company: get(props, 'userInvite.company')
				? get(props, 'userInvite.company')
				: get(props, 'location.state.accountClaim.company'),
			loaded: false,
			subCompanies: get(props, 'subCompanies'),
		};
	}

	async componentDidMount() {
		let company;
		// If invite is already stored in cache

		const userInvite = await query(
			{ id: get(this.props, 'match.params.id') },
			'getUserInvite'
		);
		if (get(userInvite, 'companyId')) {
			const companyId = get(userInvite, 'companyId');
			company = get(userInvite, 'company');
			const departments = await query(
				{ companyId },
				'queryDepartmentsByCompanyIdIndex'
			);
			const subCompanies = await query(
				{ companyId },
				'querySubCompanyByCompanyIdIndex'
			);
			const userGroups = await query(
				{ companyId, first: 1000 },
				'queryUserGroupsByCompanyIdIndex'
			);
			const defaultUserGroupId = this.getUserGroupId(userGroups, 'Default');
			const noBonusUserGroupId = this.getUserGroupId(userGroups, 'Ineligible');
			this.setState({
				company,
				departments,
				defaultUserGroupId,
				noBonusUserGroupId,
				subCompanies,
				userGroups,
				userInvite,
			});
		}

		let accountClaim = get(this.props, 'history.location.state.accountClaim');
		if (!accountClaim && !userInvite)
			accountClaim = await query(
				{ id: window.location.pathname.split('/')[2] },
				'getAccountClaim'
			);
		const companyId = get(accountClaim, 'companyId');

		if (accountClaim && companyId === '4cc1443d-4f67-463d-8ff5-3f6dc814f5e9') {
			// For Allied only
			this.setState({ accountClaim });
			const departments = await query(
				{ companyId: get(accountClaim, 'companyId') },
				'queryDepartmentsByCompanyIdIndex'
			);
			const userGroups = await query(
				{ companyId: get(accountClaim, 'companyId'), first: 1000 },
				'queryUserGroupsByCompanyIdIndex'
			);
			const defaultUserGroupId = this.getUserGroupId(userGroups, 'Default');
			const noBonusUserGroupId = this.getUserGroupId(userGroups, 'Ineligible');
			this.setState({
				userGroups,
				defaultUserGroupId,
				departments,
				noBonusUserGroupId,
			});
		} else if (accountClaim) {
			// For Non Allied companies
			this.setState({ accountClaim });
			const deptTitle = get(accountClaim, 'department');
			const grpTitle = get(accountClaim, 'group');
			const departments = await query(
				{ companyId: get(accountClaim, 'companyId'), first: 1000 },
				'queryDepartmentsByCompanyIdIndex'
			);
			const userGroups = await query(
				{ companyId: get(accountClaim, 'companyId'), first: 1000 },
				'queryUserGroupsByCompanyIdIndex'
			);
			const defaultUserGroupId = this.getUserGroupId(userGroups, 'Default');
			let userGroupIdByName = '';
			if (grpTitle !== '' && grpTitle !== undefined && grpTitle !== null) {
				userGroupIdByName = this.getUserGroupId(userGroups, grpTitle);
			}

			company = get(accountClaim, 'company');
			this.setState({
				company,
				deptTitle,
				userGroups,
				defaultUserGroupId,
				departments,
				userGroupIdByName,
			});
			await this.getAllSubCompanies(companyId);
		}
		if (!company) company = this.state.company;
		const backgroundUrl = get(company, 'background.key');
		const logo = get(company, 'logo.key', false);

		let backgroundSource;
		let logoSource;
		if (backgroundUrl && company?.whiteLabel) {
			backgroundSource = await downloadFromS3(backgroundUrl);
		} else {
			backgroundSource = LoginBackground;
		}

		if (logo && company?.whiteLabel) {
			logoSource = await downloadFromS3(logo);
		} else {
			logoSource = ErinLogo;
		}
		let backgroundImage = `url(${backgroundSource})`;
		this.setState({ backgroundSrc: backgroundSource, backgroundImage: backgroundImage, logoSrc: logoSource });
	}

	async getAllSubCompanies(companyId) {
		try {
			const subCompanies = await query(
				{ companyId },
				'querySubCompanyByCompanyIdIndex'
			);
			this.setState({
				subCompanies,
			});
		} catch (error) {
			console.log(error);
		}
	}

	getUserGroupId = (userGroups, name) => {
		const userGroup = userGroups.find((group) => group.name === name);
		return get(userGroup, 'id');
	};

	render() {
		const {
			accountClaim,
			backgroundImage,
			company,
			defaultUserGroupId,
			departments,
			logoSrc,
			noBonusUserGroupId,
			userGroupIdByName,
			deptTitle,
			subCompanies,
			userInvite,
		} = this.state;

		const whiteLabel = get(company, 'whiteLabel');

		let brandColor = '#f26d6e';
		if (get(company, 'whiteLabel')) {
			brandColor = get(company, 'brandColor', brandColor);
		}

		if (get(userInvite, 'userId')) {
			this.props.history.push({
				pathname: '/login',
				state: {
					warningMessage:
						'This account has already been registered. Please login using your credentials.',
				},
			});
		}

		if ((!userInvite && !accountClaim) || !departments || !backgroundImage || !logoSrc) {
			return <Spinner forceDefault={true} />;
		}

		return (
			<div className="auth" style={{ backgroundImage }}>
				<div className="auth-left">
					<div className="auth-logo-wrap" style={{ maxHeight: '100vh' }}>
						<div className="auth-logo">
								<img src={logoSrc} alt="logo" />
						</div>
					</div>
				</div>
				<div className="auth-right">
					<div className="auth-card">
						<NewUserForm
							whiteLabel={whiteLabel}
							accountClaim={accountClaim}
							defaultUserGroupId={defaultUserGroupId}
							noBonusUserGroupId={noBonusUserGroupId}
							setCurrentUser={this.props.setCurrentUser}
							userInvite={userInvite}
							userGroupIdByName={userGroupIdByName}
							deptTitle={deptTitle}
							subCompanies={subCompanies}
							departments={departments}
							onCreate={this.props.onCreate}
							onUpdateAccountClaim={this.props.onUpdateAccountClaim}
							onUpdateInvite={this.props.onUpdateInvite}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default NewUserLandingPageComponent;
