import { List } from 'antd';
import get from 'lodash/get';
import { Component } from 'react';
import { USER_ROLES } from 'src/_shared/constants/index.js';
import { ml, getSetErrorImageURL } from 'src/_shared/services/utils.js';
import fileIcon from 'src/_shared/assets/erin_lightgray.png';
import { JobCard } from '../../../browseJobs/jobCard';
import { JobCardSkeleton } from 'src/_shared/components';

class HotJobCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isExtendedUser: get(props, 'currentUser.role') === 'extendedUser',
			contactsNextToken: true,
			currencyRate: 0,
			currencySymbol: '$',
			companyData: props.companyData,
			errorImageURL: '',
		};
	}

	async componentDidMount() {
		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(currentUser?.company?.errorImage?.key);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}
	}

	componentDidUpdate(prevProps) {
		const { contacts, onFetchMoreContacts, contactsNextToken } = this.props;

		if (prevProps.contacts !== contacts) {
			if (onFetchMoreContacts) onFetchMoreContacts();

			this.setState({
				contacts,
				contactsNextToken:
					contactsNextToken === undefined ? true : contactsNextToken,
			});
		}

		if (prevProps.companyData !== this.props.companyData) {
			if (this.props.onFetchMore) this.props.onFetchMore();
			this.setState({
				companyData: this.props.companyData,
			});
		}
	}

	render() {
		const {
			currentUser,
			allMultiLingualData,
			hotSearchedJobs,
			onUpdateJob,
			contacts,
			onCreateReferral,
		} = this.props;
		const {
			isExtendedUser,
			contactsNextToken,
			currencyRate,
			currencySymbol,
			companyData,
			errorImageURL,
		} = this.state;
		const contactIncentiveBonus = get(
			this.props,
			'currentUser.company.contactIncentiveBonus'
		);
		const allowSelfReferrals = get(
			this.props,
			'currentUser.company.allowSelfReferrals'
		);
		const whiteLabel = get(currentUser, 'company.whiteLabel');

		return (
			<>
				{currentUser.displayAs === USER_ROLES.EMPLOYEE && (
					<>
						{hotSearchedJobs && hotSearchedJobs.length > 0 ? (
							<div className="d-card dashboard-pagination height-equal">
								<div className="d-card-head">
									<h4 className="d-title">HOT JOBS</h4>
								</div>
								<List
									dataSource={hotSearchedJobs}
									grid={{
										gutter: 1,
										xs: 1,
										sm: 1,
										md: 1,
										lg: 1,
										xl: 1,
										xxl: 1,
									}}
									className="job-card-wrap"
									itemLayout="horizontal"
									pagination={
										hotSearchedJobs.length > 1
											? {
													pageSize: 1,
													position: 'top',
													pagination: false,
													showSizeChanger: false,
												}
											: false
									}
									renderItem={(job) => (
										<>
											<JobCard
												key={get(job, 'id', 'skip')}
												allMultiLingualData={allMultiLingualData}
												isExtendedUser={isExtendedUser}
												jobId={get(job, 'id', 'skip')}
												job={job}
												contactIncentiveBonus={contactIncentiveBonus}
												contacts={contacts}
												contactsNextToken={contactsNextToken}
												currentUser={this.props.currentUser}
												allowSelfReferrals={allowSelfReferrals}
												currentCurrencyRate={currencyRate}
												currentCurrencySymbol={currencySymbol}
												currentUserCompany={companyData}
												referSomeoneText={ml(
													'Refer Someone',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												enterReferralText={ml(
													'We will send them information to apply.',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												enterReferralInformationText={ml(
													'Enter Referral Information',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												firstNameText={ml(
													'First Name',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												lastNameText={ml(
													'Last Name',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												emailText={ml(
													'Email Address',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												textPlaceHolderText={ml(
													'Text',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												orText={ml(
													'or',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												clickHereText={ml(
													'click here',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												toAddExistingContactText={ml(
													'to add an existing contact',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												includeMessageToContactText={ml(
													'Include a message to your contact',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												optionalText={ml(
													'optional',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												personalizeMessageText={ml(
													'Personalize the message to your referral',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												messageHiringContactText={ml(
													'Message the Recruiter',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												howKnowThemText={ml(
													'How do you know them, why are they a good fit, etc.',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												clickHereResumeText={ml(
													'Click here',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												attachResumeText={ml(
													'to attach a resume',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												submitReferralText={ml(
													'Submit Referral',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												referContactText={ml(
													'Refer Contact',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												MakeReferralText={ml(
													'Make Referral',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												referralBonusText={ml(
													'Referral Bonus',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												referAContactText={ml(
													'Refer a Contact',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												startTypingText={ml(
													"Start typing a contact's name...",
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												importContactsText={ml(
													'import contacts',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												toEnterNameAndEmailText={ml(
													'to enter name and email',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												interestedText={ml(
													'Interested?',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												letUsKnowText={ml(
													'Let us know that you are interested in this position and we will be in touch.',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												submitMeText={ml(
													'Submit Me For This Position',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												successText={ml(
													'Success. Your information has been submitted for this position.',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												closeThisWindowText={ml(
													'Close This Window',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												iAmInterestedText={ml(
													"I'm Interested",
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												shareText={ml(
													'Share',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												shareJobText={ml(
													'Share Job',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												shareOnText={ml(
													'Share on',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												referralLinkText={ml(
													'Referral link',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												contactEmailText={ml(
													'A contact with this',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												alreadyReferredText={ml(
													'already exists.',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												alreadyReferredReferralFormText={ml(
													'This person has already been referred for this position.',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												clickHereReferralFormText={ml(
													'Click here',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												toReferReferralFormText={ml(
													'to refer them or',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												viewReferralFormText={ml(
													'view',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												contactReferralFormText={ml(
													'the contact.',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												troubleReferralFormText={ml(
													'We are having trouble submitting the form. Please make sure the form is filled out correctly and try again.',
													this.props.currentUser,
													this.props.allMultiLingualData
												)}
												onUpdateJob={onUpdateJob}
												onCreateReferral={onCreateReferral}
											/>
										</>
									)}
								/>
							</div>
						) : (
							<div className="d-card dashboard-pagination height-equal">
								<div className="d-card-head">
									<h4 className="d-title">HOT JOBS</h4>
								</div>
								<List
									dataSource={[
										{name: 'job-card-skeleton'},
									]}
									grid={{
										gutter: 1,
										xs: 1,
										sm: 1,
										md: 1,
										lg: 1,
										xl: 1,
										xxl: 1,
									}}
									className="job-card-wrap"
									itemLayout="horizontal"
									pagination={
										{
											pageSize: 1,
											position: 'top',
											disabled: true,
											pagination: false,
											showSizeChanger: false,
											total: 500,

										}
									}
									renderItem={() => (
										<>
											{this.props.isLoading ? (
												<JobCardSkeleton isHotJob />
											) : (
												<div className="no-content">
													{whiteLabel ? (
														<img
															src={errorImageURL}
															alt="error image"
															className="no-content-icon"
														/>
													) : (
														<img className="no-content-icon" alt="erin-logo" src={fileIcon} />
													)}
													<p className="no-content-text">
														{ml('No Hot Jobs Found', currentUser, allMultiLingualData)}
													</p>
												</div>
											)}
										</>
									)}
								/>
							</div>
						)}
					</>
				)}
			</>
		);
	}
}

export default HotJobCard;
