export const getATSSettingsByCompany = `query GetATSSettingsByCompany(
    $id: ID!
  ) {
    getATSSettingsByCompany(
      id: $id
    ) {
        atsIntegration
      }
    }
  `;
