import { Select } from 'antd';
import WebIcon from 'src/_shared/components/WebIconComponent.jsx';
import { ml } from '../../_shared/services/utils';

const { Option } = Select;
function FilterByStatus(props) {
	const {
		allMultiLingualData,
		currentUser,
		filteredStatuses,
		handleRemoveStatusFilter,
		handleStatusFilter,
		toggleButtonStyles,
	} = props;

	const statuses = [
		{
			name: ml('Pending', currentUser, allMultiLingualData),
			value: 'pending',
		},
		{
			name: ml('Ineligible', currentUser, allMultiLingualData),
			value: 'ineligible',
		},
		{
			name: ml('Needs Review', currentUser, allMultiLingualData),
			value: 'needsReview',
		},
		{
			name: ml('Eligible', currentUser, allMultiLingualData),
			value: 'eligible',
		},
		{
			name: ml('Paid', currentUser, allMultiLingualData),
			value: 'paid',
		},
	];
	const statusOptions = statuses.map((status) => {
		return (
			<Option key={status.value} value={status.value}>
				{status.name}
			</Option>
		);
	});

	const handleClick = (style, status) => {
		handleStatusFilter(status);
	};

	const handleRemove = (status) => {
		handleRemoveStatusFilter(status);
	};

	const renderArrowIcon = (count) => {
		return (
			<div>
				<WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
			</div>
		);
	};

	return (
		<Select
			showArrow
			mode="multiple"
			placeholder={ml('Status', currentUser, allMultiLingualData)}
			maxTagCount={1}
			maxTagTextLength={15}
			suffixIcon={renderArrowIcon(filteredStatuses.length)}
			onSelect={(referredKey, { key }) => handleClick(referredKey, key)}
			onDeselect={(referredKey, { key }) => handleRemove(referredKey, key)}
		>
			{statusOptions}
		</Select>
	);
}

export default FilterByStatus;
