import { Select } from 'antd';
import { useEffect } from 'react';

export function LanguageSelectOptions({ currentUser, onSelectLanguage }) {
	const { languageCode } = currentUser;

	return (
		<Select
			showArrow
			className="custom-input"
			style={{ width: '100%' }}
			defaultValue={languageCode === null ? 'US' : languageCode}
			virtual={false}
			onSelect={onSelectLanguage}
		>
			<Select.Option key={1} value="US">
				English
			</Select.Option>
			<Select.Option key={2} value="CS">
				Čeština
			</Select.Option>
			<Select.Option key={3} value="DE">
				Deutsch
			</Select.Option>
			<Select.Option key={4} value="ES">
				Español
			</Select.Option>
			<Select.Option key={5} value="FR">
				Français
			</Select.Option>
			<Select.Option key={6} value="FR-CA">
				Français (Canada)
			</Select.Option>
			<Select.Option key={7} value="IT">
				Italiano
			</Select.Option>
			<Select.Option key={8} value="HU">
				Magyar
			</Select.Option>
			<Select.Option key={9} value="NL">
				Nederlands
			</Select.Option>
			<Select.Option key={10} value="PT">
				Português
			</Select.Option>
			<Select.Option key={11} value="PT-BR">
				Português (Brasil)
			</Select.Option>
			<Select.Option key={12} value="TL">
				Tagalog
			</Select.Option>
			<Select.Option key={13} value="TR">
				Türkçe
			</Select.Option>
			<Select.Option key={14} value="RU">
				Русский
			</Select.Option>
			<Select.Option key={15} value="ZH-CN">
				简体中文
			</Select.Option>
			<Select.Option key={16} value="JA">
				日本語
			</Select.Option>
		</Select>
	);
}
