import _, { get } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { GetCompanyByPartnerClientId } from 'src/_shared/api/graphql/custom/company/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { GetAccountClaimByEmployeeIdCompanyId } from 'src/graphql/custom/account-claims/';
import ErinLogo from '../_shared/assets/erinwhite.png';
import { GetCompanySettings } from '../settings-dashboard/custom/company';
import PoweredBy from '../_shared/assets/poweredbyERIN.png';
import {
	downloadFromS3,
	findItemByNameAndKeywords,
	parse,
} from '../_shared/services/utils';
import NewSamlUserForm from './new-user-items/NewSamlUserFormComponent.jsx';
import {
	Card,
	LandingPageContainer,
	Logo,
	NavBar,
	TitleStyles,
	UnauthTextStyles,
} from './newUserLandingStyles.js';

class NewSamlUserLandingPageComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authorized: true,
			companyId: get(
				this.props,
				'samlData.companyId',
				get(this.props, 'match.params.id')
			),
			departments: get(this.props, 'departments', []),
			tokenData: get(this.props, 'location.state.tokenData'),
			redirectURL: get(this.props, 'location.state.redirectURL'),
			loading: true,
		};
	}

	async componentDidMount() {
		const token = get(this.state, 'tokenData');
		const roles = get(token, 'custom:role', '').split(',');
		const samlProvider = get(token, 'identities[0].providerName');
		let company;
		if (samlProvider === 'TalentReef') {
			const partnerClientId = get(token, 'custom:client_id', 'skip');
			try {
				const getCompany = await this.props.client.query({
					query: GetCompanyByPartnerClientId,
					variables: {
						partnerClientId,
					},
				});
				company = get(getCompany, 'data.getCompanyByPartnerClientId');
				if (company) {
					this.setCompany(company, token);
				}
			} catch (error) {
				console.log(error);
			}

			if (!company && this.hasPermissions(roles)) {
				// Create new Company if roles match
				company = await this.createPartnerCompany(token);
			} else if (!company && !this.hasPermissions(roles)) {
				this.setState({
					authorized: false,
				});
				if (get(this.props, 'partnerHostCompany'))
					this.setCompany(get(this.props, 'partnerHostCompany'));
			}
		} else if (get(this.state, 'companyId')) {
			try {
				const companySettings = await this.props.client.query({
					query: GetCompanySettings,
					variables: {
						id: company ? company.id : this.state.companyId,
					},
				});
				company = get(companySettings, 'data.getCompany');
				this.setCompany(company, token);
			} catch (error) {
				console.log(error);
			}
		}

		let logoSource;
		const logo = get(company, 'logo.key', false);
		if (logo) {
			logoSource = await downloadFromS3(logo);
		}

		const styles = await LandingPageContainer(company);

		this.setState({ logoSrc: logoSource, styles });

		if (get(this.props, 'samlData.autoCreateUsers')) {
			this.autoSubmit();
		} else {
			this.setState({ loading: false });
		}
	}

	getAccountClaimByEmployeeCompanyId = async (employeeId, companyId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { client } = this.props;
				const { data } = await client.query({
					query: GetAccountClaimByEmployeeIdCompanyId,
					variables: { employeeId, companyId },
				});
				const claim = {
					...data.getAccountClaimByEmployeeIdCompanyId,
				};
				return resolve(claim);
			} catch {
				return resolve(null);
			}
		});
	};

	setCompany(company, token) {
		let departments = get(company, 'departments');
		departments = departments.filter(
			(department) => department.active === true
		);
		departments = _.sortBy(departments, ['name']);
		const departmentName = get(token, 'custom:department', 'Other');
		let defaultDepartment = findItemByNameAndKeywords(
			departmentName,
			departments
		);
		if(!defaultDepartment){
			defaultDepartment = departments.find((dep)=>dep.name === 'Other')
		}
		const userGroups = get(company, 'userGroups');
		const userGroupName = get(
			token,
			'custom:country',
			get(token, 'custom:userGroup', 'Default')
		);
		let defaultUserGroup = findItemByNameAndKeywords(userGroupName, userGroups);
		defaultUserGroup = defaultUserGroup
			? defaultUserGroup
			: findItemByNameAndKeywords('Default', userGroups);
		const defaultUserGroupId = defaultUserGroup ? defaultUserGroup.id : null;
		this.setState({
			company,
			departments,
			defaultDepartment,
			defaultUserGroupId,
			defaultUserGroup,
			userGroups,
		});
	}

	autoSubmit = async () => {
		const { onCreate, onCreateDepartment, setCurrentUser } = this.props;
		const { company, defaultUserGroupId, defaultUserGroup } = this.state;
		const token = get(this.state, 'tokenData');
		let employeeId = get(token, 'custom:employeeid', '');
		employeeId =
			!employeeId && get(token, 'custom:userid', '')
				? get(token, 'custom:userid')
				: '';
		const jobClassId = get(token, 'custom:jobClassId');
		const jobClassName = get(token, 'custom:jobClassName');
		const jobFamilyGroupId = get(token, 'custom:jobFamilyGroupId');
		const jobFamilyGroupName = get(token, 'custom:jobFamilyGroupName');
		const jobFamilyId = get(token, 'custom:jobFamilyId');
		const jobFamilyName = get(token, 'custom:jobFamilyName');
		const jobProfileId = get(token, 'custom:jobProfileId');
		const jobProfileName = get(token, 'custom:jobProfileName');
		const managementLevel = get(token, 'custom:managementLevel');
		const subCompanyName = get(token, 'custom:subCompany');
		const subCompanies = get(this.props, 'subCompanies', []);
		const subCompany = findItemByNameAndKeywords(subCompanyName, subCompanies);
		const subCompanyId = subCompany ? subCompany.id : null;
		const companyId = get(this.props, 'samlData.companyId');
		const emailAddress = get(token, 'email', '').toLowerCase();
		const firstName = get(token, 'given_name', '');
		const lastName = get(token, 'family_name', '');
		const title = get(token, 'custom:jobtitle', '');
		const departments = get(this.props, 'departments', []);
		const departmentName = get(token, 'custom:department', '');
		let department = findItemByNameAndKeywords(departmentName, departments);
		if (!department) {
			const input = {
				active: true,
				companyId,
				name: departmentName,
			};
			await onCreateDepartment({
				input,
			}).then((res) => (department = get(res, 'data.createDepartment')));
		}

		const cognitoId = Object.getOwnPropertyDescriptor(
			token,
			'cognito:username'
		).value;
		if (emailAddress && firstName && lastName && title && department) {
			this.setState({ submitting: true });
			const languageCode = 'US';

			try {
				const newUser = {
					input: {
						cognitoId,
						companyId: get(this.props, 'match.params.id'),
						emailAddress: emailAddress.toLowerCase(),
						role: 'employee',
						firstName,
						lastName,
						title,
						departmentId: get(department, 'id'),
						avatar: null,
						lastLogin: null,
						active: true,
						createdById: get(this.props, 'match.params.id'),
						userGroupId: defaultUserGroupId,
						userGroup: defaultUserGroup,
						currency: get(this.state, 'currencyAbbrev', 'USD'),
						languageCode,
					},
				};
				if (employeeId) newUser.input.employeeId = employeeId;
				if (jobClassId) newUser.input.jobClassId = jobClassId;
				if (jobClassName) newUser.input.jobClassName = jobClassName;
				if (jobFamilyGroupId) newUser.input.jobFamilyGroupId = jobFamilyGroupId;
				if (jobFamilyGroupName)
					newUser.input.jobFamilyGroupName = jobFamilyGroupName;
				if (jobFamilyId) newUser.input.jobFamilyId = jobFamilyId;
				if (jobFamilyName) newUser.input.jobFamilyName = jobFamilyName;
				if (jobProfileId) newUser.input.jobProfileId = jobProfileId;
				if (jobProfileName) newUser.input.jobProfileName = jobProfileName;
				if (managementLevel) newUser.input.managementLevel = managementLevel;
				if (subCompanyId) newUser.input.subCompanyId = subCompanyId;

				let accountClaim = null;
				const companyId = get(this.props, 'match.params.id');
				if (get(company, 'sftpFolderName') && employeeId && companyId) {
					accountClaim = await this.getAccountClaimByEmployeeCompanyId(
						employeeId,
						companyId
					);
				}

				if (accountClaim) newUser.input.accountClaimId = accountClaim.id;

				onCreate(newUser).then((user) => {
					const currentUser = get(user, 'data.createUser');
					const redirectURL = get(this.state, 'redirectURL');
					setCurrentUser(currentUser);
					this.props.history.push(
						{
							pathname: '/login',
							state: {
								currentUser,
								redirectURL,
							},
						},
						'/dashboard'
					);
				});
			} catch (error) {
				this.setState({ serverError: true, submitting: false });
				console.error(error);
			}
		} else {
			this.setState({ loading: false });
		}
	};

	async createPartnerCompany(token) {
		return new Promise(async (resolve, reject) => {
			try {
				const { id, onCreateCompany, onCreateDepartment, onCreateUserGroup } =
					this.props;
				let partnerHostCompany = get(this.props, 'partnerHostCompany');
				if (!partnerHostCompany) {
					const partnerHostCompanyData = await this.props.client.query({
						query: GetCompanySettings,
						variables: {
							id,
						},
					});
					partnerHostCompany = get(partnerHostCompanyData, 'data.getCompany');
				}

				if (!partnerHostCompany) return;
				const name = get(token, 'custom:client_name');
				const partnerClientId = get(token, 'custom:client_id');
				const variables = {
					input: {
						name,
						partnerClientId,
						partnerHostId: partnerHostCompany.id,
						whiteLabel: true,
						accountType: 'partner',
						pointsSettings: JSON.stringify({
							enabled: true,
							isStoreEnabled: true,
						}),
					},
				};
				if (get(partnerHostCompany, 'logo'))
					variables.input.logo = partnerHostCompany.logo;
				if (get(partnerHostCompany, 'background'))
					variables.input.background = partnerHostCompany.background;
				if (get(partnerHostCompany, 'symbol'))
					variables.input.symbol = partnerHostCompany.symbol;
				if (get(partnerHostCompany, 'errorImage'))
					variables.input.errorImage = partnerHostCompany.errorImage;
				if (get(partnerHostCompany, 'theme'))
					variables.input.theme = partnerHostCompany.theme;
				if (get(partnerHostCompany, 'brandColor'))
					variables.input.brandColor = partnerHostCompany.brandColor;
				if (get(partnerHostCompany, 'giftCardStoreAPIKeys'))
					variables.input.giftCardStoreAPIKeys =
						partnerHostCompany.giftCardStoreAPIKeys;
				if (get(partnerHostCompany, 'atsIntegration')) {
					const atsIntegration = JSON.parse(partnerHostCompany.atsIntegration);
					atsIntegration[0].ATSClientId = partnerClientId;
					variables.input.atsIntegration = JSON.stringify(atsIntegration);
				}

				if (get(partnerHostCompany, 'senderEmailAddress')) {
					variables.input.senderEmailAddress =
						partnerHostCompany.senderEmailAddress;
				}

				const companyData = await onCreateCompany(variables);
				let company = get(companyData, 'data.createCompany');
				await onCreateDepartment({
					input: {
						companyId: company.id,
						name: 'Other',
						active: true,
					},
				});
				await onCreateUserGroup({
					input: {
						companyId: company.id,
						name: 'Default',
						active: true,
						currency: 'USD',
					},
				});
				await onCreateUserGroup({
					input: {
						companyId: company.id,
						name: 'External',
						active: true,
					},
				});
				const getCompany = await this.props.client.query({
					query: GetCompanyByPartnerClientId,
					variables: {
						partnerClientId,
					},
				});
				if (get(getCompany, 'data.getCompanyByPartnerClientId')) {
					company = get(getCompany, 'data.getCompanyByPartnerClientId');
					this.setCompany(company);
				}

				resolve(company);
			} catch (error) {
				this.setState({ serverError: true });
				console.error(error);
			}
		});
	}

	hasPermissions(roles = []) {
		const result = Boolean(roles.find((role) => role === 'ATSEdit'));
		return result;
	}

	render() {
		const { allMultiLingualData, userInvite } = this.props;
		const {
			authorized,
			defaultDepartment,
			departments,
			companyId,
			defaultUserGroupId,
			defaultUserGroup,
			loading,
			logoSrc,
			styles,
		} = this.state;
		if (!companyId || !departments) {
			return (
				<div style={{ height: '100vh', paddingTop: '45%' }}>
					<Spinner forceDefault={true} />
				</div>
			);
		}

		const whiteLabel = get(this.state, 'company.whiteLabel');
		const theme = get(this.state, 'company.theme')
			? parse(get(this.state, 'company.theme'))
			: null;
		const lblDepartment = get(this.state, 'company.labelDepartment')
			? get(this.state, 'company.labelDepartment')
			: 'Department';
		const lblCompany = get(this.state, 'company.subCompanyLabel')
			? get(this.state, 'company.subCompanyLabel')
			: 'Company';
		return loading ? (
			<div style={{ height: '100vh', paddingTop: '45%' }}>
				<Spinner forceDefault={true} />
			</div>
		) : (
			<div className={styles}>
				<div style={{ textAlign: 'center' }}>
					{whiteLabel ? (
						<img className={Logo} src={logoSrc} alt="logo" width="350px" />
					) : !whiteLabel && logoSrc !== false ? (
						<div style={{ textAlign: 'center' }}>
							<img className={Logo} src={logoSrc} alt="logo" />
							<div>
								<img alt="logo" style={{ width: 200 }} src={PoweredBy} />
							</div>
						</div>
					) : (
						<img className={Logo} src={ErinLogo} alt="Erin Logo" />
					)}
				</div>

				{authorized ? (
					<div className={Card}>
						<NewSamlUserForm
							allMultiLingualData={allMultiLingualData}
							hasPermissions={this.hasPermissions}
							company={this.state.company}
							samlData={this.props.samlData}
							defaultUserGroupId={defaultUserGroupId}
							defaultUserGroup={defaultUserGroup}
							tokenData={this.state.tokenData}
							redirectURL={this.state.redirectURL}
							setCurrentUser={this.props.setCurrentUser}
							userInvite={userInvite}
							departments={departments}
							defaultDepartment={defaultDepartment}
							whiteLabel={whiteLabel}
							theme={theme}
							lblDepartment={lblDepartment}
							lblCompany={lblCompany}
							onCreate={this.props.onCreate}
							onUpdateInvite={this.props.onUpdateInvite}
						/>
					</div>
				) : (
					<div className={Card} style={{ flexDirection: 'column' }}>
						<h1 className={TitleStyles} style={{ marginBottom: 10 }}>
							We're Sorry!
						</h1>
						<h1 className={UnauthTextStyles}>
							This client has not been created yet.
						</h1>
						<h1 className={UnauthTextStyles}>Please check back later!</h1>
					</div>
				)}

				<span className={NavBar}>
					Need Assistance?{' '}
					<a
						href="https://erinapp.com/support"
						target="_blank"
						rel="noopener noreferrer"
					>
						Contact Erin Support
					</a>
				</span>
			</div>
		);
	}
}

export default withApollo(NewSamlUserLandingPageComponent);
