import { connect } from 'react-redux';
import {
	withCreateContactReferral,
	withListMyReferralsContacts,
	withMyContacts,
} from 'src/_shared/api/components/contacts/';
import { withListDepartment } from 'src/_shared/api/components/departments/';
import { withListJobLevels } from 'src/_shared/api/components/job-level/';
import { withUpdateJob } from 'src/_shared/api/components/jobs/';
import { withGetCompanyData } from 'src/_shared/api/components/settings/';
import { withQuerySubCompanyByCompanyIdIndex } from 'src/_shared/api/components/sub-company/';
import { withListTieredBonus } from 'src/_shared/api/components/tiered-bonuses/';
import { withCreateUserJobShare } from 'src/_shared/api/components/users/';
import {
	dashboardActions,
	manageJobsActions,
	userActions,
} from 'src/actions.js';
import { compose } from '../_shared/services/utils';
import BrowseJobsComponent from './BrowseJobsComponent.jsx';
import withUserLoaded from './withUserLoaded';

const mapStateToProperties = (state, props) => {
	const { currentUser } = state.user;
	const { allMultiLingualData } = state.dashboard;
	return {
		currentUser: state.user.currentUser,
		filter: {
			companyId: { eq: currentUser.companyId },
			userId: { eq: currentUser.id },
		},
		location: props.location.pathname,
		modalState: state.dashboard.modalState,
		companyId: currentUser.companyId,
		allMultiLingualData,
		jobSearchCriteria: state.user.jobSearchCriteria,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		setCurrentUser(currentUser) {
			dispatch(userActions.updateCurrentUser(currentUser));
		},
		setCurrentCurrencyRate(currencyRate) {
			dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate));
		},
		setCurrentCurrencySymbol(currencySymbol) {
			dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol));
		},
		setMultiLingualData(data) {
			dispatch(dashboardActions.createSetMultiLingualData(data));
		},
		updateCurrentUserSearchCriteria(searchCriteria) {
			dispatch(userActions.updateUserSearch(searchCriteria));
		},
	};
};

const BrowseJobsContainer = compose(
  	withUserLoaded,
	withListTieredBonus,
	withCreateContactReferral,
	withListDepartment,
	withGetCompanyData,
	withQuerySubCompanyByCompanyIdIndex,
	withListJobLevels,
	withUpdateJob,
	withCreateUserJobShare,
	withMyContacts,
	withListMyReferralsContacts
)(BrowseJobsComponent);

export const BrowseJobs = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(BrowseJobsContainer);
